import { Observable, Subject } from 'rxjs';
import { IState, Sub } from '../../../../shared/state/state.abstract';

export function artcileTabsSub<T extends IState<T>>() {
  abstract class Common extends Sub<T> {
    protected articleIdNotExist$ = new Subject<boolean>();

    articleIdNotExist(): Observable<boolean> {
      return this.articleIdNotExist$.asObservable();
    }
  }
  return Common;
}
