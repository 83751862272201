import { t } from 'i18next';
import { map, take, zipWith } from 'rxjs';

import {
  CustomerParams,
  ICustomerGlobalSearchParams,
  globalSearchParams,
} from '../../../shared/components/globalSearch/globalSearchParams.util';
import { dataHelper } from '../../../shared/helpers/data/data.helper';
import { storageHelper } from '../../../shared/helpers/storage';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';
import {
  configsData,
  DictCustomerGroupsRes,
  DictCustomerListsRes,
  DictTourplansRes,
} from '../../../shared/services/configsData/configsData.service';
import { customerService, ListCustomersRes } from '../services/customer.service';
import { ICustomersListState, initState } from '../states/customersList.state';

export const customerListResolver = async (
  params: CustomerParams,
): Promise<
  Pick<
    ICustomersListState,
    | 'list'
    | 'fullListCount'
    | 'selectedRecord'
    | 'filter'
    | 'sortModel'
    | 'customerTitle'
    | 'search'
    | 'action'
  >
> => {
  const sessionSortModel: ICustomersListState['sortModel'] | null = storageHelper.local.getItem(
    'customer.customersListSort',
  );
  const initListState = structuredClone(initState);
  const sortModel: ICustomersListState['sortModel'] = sessionSortModel || initListState.sortModel;
  const filter: ICustomersListState['filter'] = initListState.filter;
  let param: ICustomerGlobalSearchParams | undefined;
  let globalSearchSelectedRecord: { id: string } | undefined;
  let takeListFromCache = true;

  if ((param = globalSearchParams('customer', params) as ICustomerGlobalSearchParams)) {
    globalSearchSelectedRecord = { id: param.entityId };
    if (filter.isActive !== param.filter.active) {
      takeListFromCache = false;
    }
    filter.isActive = param.filter.active;
  }

  const list = await customerService.globHelpers.streamToPromise(
    customerService.sub.listGridCustomers().pipe(
      responseHandler<ListCustomersRes>({ errorReturnType: [] }),
      take(1),
      map(
        (list) =>
          dataHelper
            .data(list as [])
            .sort({ sortModel })
            .result() as ICustomersListState['list'],
      ),
    ),
    () => customerService.pub.listGridCustomers({ filter, cache: takeListFromCache }),
  );

  const selectedCustomerStorage =
    globalSearchSelectedRecord || storageHelper.session.getItem('customer.selectedRecord');
  const foundSelectedRecord =
    selectedCustomerStorage && list.find((item) => item?.id === selectedCustomerStorage?.id);
  const selectedRecord: ICustomersListState['selectedRecord'] = foundSelectedRecord || list[0];
  const customerNo = selectedRecord?.customerNo;
  const internalOrFullName = selectedRecord?.internalOrFullName;
  const customerTitle: string =
    customerNo && internalOrFullName
      ? `${customerNo} ${internalOrFullName}`
      : customerNo || internalOrFullName || t('common.new_record');

  return {
    list,
    fullListCount: list.length,
    selectedRecord,
    filter,
    sortModel,
    customerTitle,
    search: '',
    action: 'loader',
  };
};

export const listFilterDataResolver = async (): Promise<ICustomersListState['filterData']> => {
  return customerService.globHelpers.streamToPromise(
    configsData.sub.dictCustomerLists().pipe(
      zipWith(configsData.sub.dictCustomerGroups(), configsData.sub.dictTourplans()),
      responseHandler<FilterDataRes>({ errorReturnType: [[], [], []] }),
      map(([customerLists, customerGroups, tourPlans]) => ({
        customerLists,
        customerGroups,
        tourPlans,
      })),
    ),
    () => configsData.pub.common(['dictCustomerGroups', 'dictCustomerLists', 'dictTourplans']),
  );
};

type FilterDataRes = [DictCustomerListsRes, DictCustomerGroupsRes, DictTourplansRes];
