import { FC, useEffect, useMemo, useState } from 'react';
import { IPopupWrapper } from '../../../../../../../../../shared/components/popup/components/popup.component.tsx';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { take } from 'rxjs';
import { fontHelper } from '../../../../../../../../../shared/helpers/font/font.helper.ts';
import {
  dictionaryAdditionalService,
  GetDictTextBlockRes,
} from '../../../../../../../../../shared/components/dictionary/services/dictionaryAdditional.service.ts';
import { Content as Wrapper } from '../../../../../../../../../shared/components/content/content.component.tsx';
import { FormProvider, useForm } from 'react-hook-form';
import { FormTextEditor } from '../../../../../../../../../shared/components/form';
import { dictionaryState } from '../../../../../../../../../shared/components/dictionary/states/dictionary.state.ts';
import { responseHandler } from '../../../../../../../../../shared/responseHandler/responseHandler.ts';
import { useBeforeClosePopup } from '../../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';

export const ContentPopup: FC<IContent> = ({ stream }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState<string>('');
  const defaultFontSize = useMemo(() => fontHelper.getDefaultFont(['fontSize']), []);
  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      textBlock: '',
    },
  });

  const {
    formState: { isDirty },
  } = formMethods;

  useEffect(() => {
    const dictionaryAdditionalSub = dictionaryAdditionalService.sub
      .getDictTextBlock()
      .pipe(
        responseHandler<GetDictTextBlockRes>({
          errorReturnType: {},
        }),
      )
      .subscribe((data) => {
        formMethods.reset({ textBlock: data?.textBlock || '' });
        setLoading(false);
      });
    stream
      .actionListener('open')
      .pipe(take(1))
      .subscribe(({ id }) => {
        dictionaryAdditionalService.pub.getDictTextBlock({ id });
        setId(id);
      });
    return () => dictionaryAdditionalSub.unsubscribe();
  }, []);

  useBeforeClosePopup({
    stream,
    isDirty,
  });

  const onSubmit = ({ textBlock }: { textBlock: string | null }) => {
    const defaultValue = formMethods.formState.defaultValues?.textBlock || '';
    if (defaultValue !== textBlock) {
      dictionaryState.pub.updateCell({ id, field: 'textBlock', value: textBlock });
    }
    stream.emit('close');
  };

  return (
    <Wrapper type='box' {...{ loading }} boxProps={{ sx: { minHeight: '235px' } }}>
      <FormProvider {...formMethods}>
        <form
          style={{ opacity: loading ? '0' : '1', transition: '0.3s' }}
          onSubmit={formMethods.handleSubmit(onSubmit)}
          noValidate
        >
          {!loading && (
            <>
              <FormTextEditor name='textBlock' defaultFormat={defaultFontSize} />
              <Stack mt={3} direction='row' justifyContent='flex-end' spacing={2}>
                <Button type='submit' variant='contained'>
                  {t('common.save')}
                </Button>
              </Stack>
            </>
          )}
        </form>
      </FormProvider>
    </Wrapper>
  );
};

interface IContent {
  stream: IPopupWrapper['stream'];
}
