import React, { FC, SyntheticEvent, useMemo, useState } from 'react';
import { TableSelect } from '../../../../../../../../../../shared/components/table/styles/styleFields.ts';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { ISelectOption } from '../../../../../../../../../invoice/create/states/customerList.state.ts';
import { defaultOrderState } from '../../../../../defaultOrder.state.ts';
import { DictArticlesRes } from '../../../../../../../../../../shared/services/configsData/configsData.service.ts';
import { clearRowFromVirtualKeys } from '../additionalFunctions.ts';

export const ProductEditCell: FC<IProductEditCell> = ({ params, productOptions }) => {
  const { api, id, field, row } = params;
  const filteredProductList = useMemo(() => {
    return productOptions.reduce((acc: IFullOptions[], item) => {
      if (
        item?.id === row.articleId ||
        (!item.isDough && !api.getSortedRows().some((el) => el.articleId === item.id))
      ) {
        const { description, ...rest } = item;
        acc.push({ label: description, ...rest } as IFullOptions);
      }
      return acc;
    }, []);
  }, [productOptions, row]);
  const { setEditCellValue, stopCellEditMode } = api;
  const defaultValue = useMemo(() => {
    return filteredProductList?.find((el) => el?.id === row.articleId);
  }, []);
  const [inputValue, setInputValue] = useState(defaultValue?.label || undefined);
  const handleChange = (_: SyntheticEvent, v: ISelectOption | null) => {
    setEditCellValue({ id, field, value: v?.label });
    const { label, articleNo, id: articleId, isActive } = v as IFullOptions;
    defaultOrderState.pub.updateCell(
      {
        ...clearRowFromVirtualKeys(row),
        articleNo,
        articleId,
        isActive,
        [field]: label,
      },
      'descriptionCell',
    );
    stopCellEditMode({ id, field });
  };

  const filterOptions = (
    options: ExtendedSelectOption[],
    { inputValue }: { inputValue: string },
  ): ExtendedSelectOption[] =>
    options.filter(
      (option) =>
        (option.articleNo?.toLowerCase().includes(inputValue?.toLowerCase()) ||
          option.label?.toLowerCase().includes(inputValue?.toLowerCase())) as boolean,
    );

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.target instanceof HTMLInputElement && e.code === 'Enter') {
      stopCellEditMode({ id, field });
    }
  };
  return (
    <TableSelect
      autoFocus
      datasetattribute='productEditSelect'
      openOnFocus
      size='small'
      width='100%'
      fontSize='14px'
      disableClearable
      options={filteredProductList as []}
      filterOptions={filterOptions}
      defaultValue={defaultValue as ISelectOption}
      inputValue={inputValue}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === 'input') setInputValue(newInputValue);
      }}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
};

interface IProductEditCell {
  params: GridRenderCellParams;
  productOptions: Record<string, any>[];
}

interface ExtendedSelectOption extends ISelectOption {
  articleNo?: string;
}
interface IFullOptions extends Omit<DictArticlesRes[number], 'description'> {
  label: DictArticlesRes[number]['description'];
}
