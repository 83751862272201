import { FC } from 'react';
import { IPopupModel, PopupService } from './services/popup.service';
import { IPopupWrapper, PopupWrapper } from './components/popup.component';

export abstract class Popup<T extends IPopupModel> {
  protected innerStream: PopupService<T>;
  protected Wrapper: FC<IPopupWrapper>;
  abstract Component: FC<TPopupComponent>;
  stream: Omit<PopupService<T>, 'actionListener'>;
  protected constructor() {
    this.innerStream = new PopupService<T>();
    this.stream = this.innerStream;
    this.Wrapper = PopupWrapper;
  }
}
export type TPopupComponent = Omit<IPopupWrapper, 'stream'>;
