import {
  DictTourplansRes,
  DictCustomersRes,
  DictCustomerListsRes,
  DictProductionPrintListsRes,
  DictArticleListsRes,
} from '../../../../shared/services/configsData/configsData.service';

export enum EditPrintListTabs {
  general = 'general',
}
export interface ITabState {
  printId: string;
  defaultValues: any;
  tourTabOptions: DictTourplansRes;
  customersOptions: DictCustomersRes;
  customerListsOptions: DictCustomerListsRes;
  productionPrintListsOptions: DictProductionPrintListsRes;
  articleListsOptions: DictArticleListsRes;
}
export const tabStateModel: ITabState = {
  printId: '',
  defaultValues: {},
  tourTabOptions: [],
  customersOptions: [],
  customerListsOptions: [],
  productionPrintListsOptions: [],
  articleListsOptions: [],
};
