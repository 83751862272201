import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { C_Vat_Code } from '../../../../../../graphql/generatedModel';
import { ISelectProps } from '../../../../../../shared/components/form/fields/select.component';

export const getTaxesOptions = (t: TFunction) => {
  return useMemo<ISelectProps['options']>(
    () => [
      {
        id: C_Vat_Code.VT1_NORMAL,
        label: t(`enums.${C_Vat_Code.VT1_NORMAL}`),
      },
      {
        id: C_Vat_Code.VT2_REDUCED,
        label: t(`enums.${C_Vat_Code.VT2_REDUCED}`),
      },
      {
        id: C_Vat_Code.VT3_SPECIAL1,
        label: t(`enums.${C_Vat_Code.VT3_SPECIAL1}`),
      },
      {
        id: C_Vat_Code.VT4_SPECIAL2,
        label: t(`enums.${C_Vat_Code.VT4_SPECIAL2}`),
      },
      {
        id: C_Vat_Code.VT5_ZERO,
        label: t(`enums.${C_Vat_Code.VT5_ZERO}`),
      },
    ],
    [C_Vat_Code, t],
  );
};
