import * as Sentry from '@sentry/react';
import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import ArticleDetails from '../pages/article/components/tabs/articleDetails.component.tsx';
import CustomerDetails from '../pages/customer/components/tabs/customerDetails.component.tsx';
import EditPrintListDetails from '../pages/production/editPrintList/components/tabs/editPrintListDetails.component.tsx';
import PrintJobsDetails from '../pages/production/printJobs/components/printJobsDetails.component';
import CompanyDetails from '../pages/settings/company/components/companyDetails.component.tsx';
import ConfigurationDetails from '../pages/settings/configuration/components/configurationDetails.tsx';
import PrintFormsDetails from '../pages/settings/printForms/components/printFormsDetails.tsx';

import RootLayout from '../layouts/root.layout';
import { GlobalErrorBoundary } from '../shared/components/errorBoundaries/globalErrorBoundary.component';
import { lazyUtil } from './lazy.util';
import { ProtectedRoute } from './protected.route';

import { articleListLoader, articleTabLoader } from '../pages/article/loaders/article.loader';
import {
  customerListLoader,
  customerTabLoader,
} from '../pages/customer/loaders/customer.loader.ts';
import { openDebtsLoader } from '../pages/debitor/loaders/openDeps.loader.ts';
import GlobalSearchPage from '../pages/globalSearch/globalSearch.page.tsx';
import { globalSearchLoader } from '../pages/globalSearch/loaders/globalSearch.loader.ts';
import { createInvoiceLoader } from '../pages/invoice/loaders/createInvoice.loader.ts';
import { invoiceListLoader } from '../pages/invoice/loaders/invoiceList.loader.ts';
import { createOrderLoader } from '../pages/order/loaders/createOrder.loader.ts';
import { editOrderLoader } from '../pages/order/loaders/editOrder.loader.ts';
import { orderOverviewLoader } from '../pages/order/loaders/orderOverview.loader.ts';
import OrderOverviewDetails from '../pages/order/overview/components/overviewDetails.component.tsx';
import {
  editPrintListLoader,
  editPrintListTabLoader,
} from '../pages/production/loaders/editPrintList/editPrintList.loader.ts';
import { printJobsLoader } from '../pages/production/loaders/printJobs/printJobs.loader.ts';
import { stockOfFreezerLoader } from '../pages/production/loaders/stockOfFreezer/stockOfFreezer.loader.ts';
import { companyTabLoader } from '../pages/settings/company/loaders/company.loader.ts';
import { configurationTabLoader } from '../pages/settings/configuration/loaders/configuration.loader.ts';
import { printFormsTabLoader } from '../pages/settings/printForms/loaders/printForms.loader.ts';
import { paymentsLoader } from '../pages/debitor/loaders/payments.loader.ts';
import { appEnv } from '../shared/components/guard/guard.component.tsx';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const prodRoutes = [
  {
    path: '/outdated-browsers',
    lazy: lazyUtil.component(React.lazy(() => import('../pages/outdatedBrowsers.page'))).result(),
  },
  {
    path: '*',
    element: <Navigate to='/404' />,
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <RootLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Navigate to='/customer/general' />,
      },
      {
        path: '404',
        lazy: lazyUtil.component(React.lazy(() => import('../pages/404.page'))).result(),
      },
      {
        errorElement: <GlobalErrorBoundary />,
        children: [
          {
            path: '/globalSearch/:searchString?',
            element: <GlobalSearchPage />,
            loader: globalSearchLoader,
          },
          {
            path: '/article',
            lazy: lazyUtil
              .component(React.lazy(() => import('../pages/article/article.page')))
              .protected()
              .loader(articleListLoader)
              .result(),
            children: [
              {
                index: true,
                element: <Navigate to='/article/general' />,
              },
              {
                path: ':tab',
                element: <ArticleDetails />,
                lazy: lazyUtil.loader(articleTabLoader, { order: 2 }).result(),
              },
              {
                path: ':tab/:entityId/:filter',
                element: <ArticleDetails />,
                lazy: lazyUtil.loader(articleTabLoader, { order: 2 }).result(),
              },
            ],
          },
          {
            path: '/customer',
            lazy: lazyUtil
              .component(React.lazy(() => import('../pages/customer/customer.page')))
              .protected()
              .loader(customerListLoader)
              .result(),
            children: [
              {
                index: true,
                element: <Navigate to='/customer/general' />,
              },
              {
                path: ':tab',
                element: <CustomerDetails />,
                lazy: lazyUtil.loader(customerTabLoader, { order: 2 }).result(),
              },
              {
                path: ':tab/:entityId/:filter',
                element: <CustomerDetails />,
                lazy: lazyUtil.loader(customerTabLoader, { order: 2 }).result(),
              },
            ],
          },
          {
            path: '/order',
            children: [
              {
                index: true,
                element: <Navigate to='/order/create' />,
              },
              {
                path: '/order/create',
                lazy: lazyUtil
                  .component(React.lazy(() => import('../pages/order/create/createOrder.page')))
                  .protected()
                  .loader(createOrderLoader)
                  .result(),
              },
              {
                path: '/order/edit',
                lazy: lazyUtil
                  .component(React.lazy(() => import('../pages/order/edit/editOrder.page')))
                  .protected()
                  .loader(editOrderLoader)
                  .result(),
              },
              {
                path: '/order/edit/:entityId/:withDeleted/:dateRange',
                lazy: lazyUtil
                  .component(React.lazy(() => import('../pages/order/edit/editOrder.page')))
                  .protected()
                  .loader(editOrderLoader)
                  .result(),
              },
              {
                path: '/order/edit/:entityNum',
                lazy: lazyUtil
                  .component(React.lazy(() => import('../pages/order/edit/editOrder.page')))
                  .protected()
                  .loader(editOrderLoader)
                  .result(),
              },
              {
                path: '/order/overview',
                lazy: lazyUtil
                  .component(React.lazy(() => import('../pages/order/overview/orderOverview.page')))
                  .protected()
                  .result(),
                children: [
                  {
                    index: true,
                    element: <Navigate to='/order/overview/delivery-note-overview' />,
                  },
                  {
                    path: ':tab',
                    element: <OrderOverviewDetails />,
                    lazy: lazyUtil.protected().loader(orderOverviewLoader).result(),
                  },
                ],
              },
            ],
          },
          {
            path: '/invoice',
            children: [
              {
                index: true,
                element: <Navigate to='/invoice/create-invoice' />,
              },
              {
                path: '/invoice/create-invoice',
                lazy: lazyUtil
                  .component(React.lazy(() => import('../pages/invoice/create/createInvoice.page')))
                  .protected()
                  .loader(createInvoiceLoader)
                  .result(),
              },
              {
                path: '/invoice/invoices-list/:entityId/:withDeleted/:dateRange',
                lazy: lazyUtil
                  .component(React.lazy(() => import('../pages/invoice/list/invoicesList.page')))
                  .protected()
                  .loader(invoiceListLoader)
                  .result(),
              },
              {
                path: '/invoice/invoices-list',
                lazy: lazyUtil
                  .component(React.lazy(() => import('../pages/invoice/list/invoicesList.page')))
                  .protected()
                  .loader(invoiceListLoader)
                  .result(),
              },
              {
                path: '/invoice/invoices-list/:entityNumber?',
                lazy: lazyUtil
                  .component(React.lazy(() => import('../pages/invoice/list/invoicesList.page')))
                  .protected()
                  .loader(invoiceListLoader)
                  .result(),
              },
            ],
          },
          {
            path: '/production',
            children: [
              {
                index: true,
                element: <Navigate to='/production/print-jobs' />,
              },
              {
                path: '/production/print-jobs',
                lazy: lazyUtil
                  .component(
                    React.lazy(() => import('../pages/production/printJobs/printJobs.page')),
                  )
                  .protected()
                  .loader(printJobsLoader)
                  .result(),
                children: [
                  {
                    index: true,
                    element: <Navigate to='/production/print-jobs/production' />,
                  },
                  {
                    path: ':tab',
                    element: <PrintJobsDetails />,
                  },
                ],
              },
              {
                path: '/production/edit-print-list',
                lazy: lazyUtil
                  .component(
                    React.lazy(
                      () => import('../pages/production/editPrintList/editPrintList.page'),
                    ),
                  )
                  .protected()
                  .loader(editPrintListLoader)
                  .result(),
                children: [
                  {
                    index: true,
                    element: <Navigate to='/production/edit-print-list/general' />,
                  },
                  {
                    path: ':tab',
                    element: <EditPrintListDetails />,
                    lazy: lazyUtil.loader(editPrintListTabLoader, { order: 2 }).result(),
                  },
                ],
              },
              {
                path: '/production/stock-of-freezer',
                lazy: lazyUtil
                  .component(
                    React.lazy(
                      () => import('../pages/production/stockOfFreezer/stockOfFreezer.page'),
                    ),
                  )
                  .protected()
                  .loader(stockOfFreezerLoader)
                  .result(),
              },
            ],
          },

          {
            path: '/debitor',
            children: [
              {
                index: true,
                element: <Navigate to='/debitor/open-debts' />,
              },
              {
                path: '/debitor/open-debts',
                lazy: lazyUtil
                  .component(
                    React.lazy(() => import('../pages/debitor/openDebts/openDebts.page.tsx')),
                  )
                  .protected()
                  .loader(openDebtsLoader)
                  .result(),
              },
              {
                path: '/debitor/import-iso',
                lazy: lazyUtil
                  .component(
                    React.lazy(() => import('../pages/debitor/importIso/importIso.page.tsx')),
                  )
                  .protected()
                  .result(),
              },
              {
                path: '/debitor/payments',
                lazy: lazyUtil
                  .component(
                    React.lazy(() => import('../pages/debitor/payments/payments.page.tsx')),
                  )
                  .protected()
                  .loader(paymentsLoader)
                  .result(),
              },
            ],
          },

          {
            path: '/settings',
            lazy: lazyUtil
              .component(React.lazy(() => import('../pages/settings/settings.page')))
              .protected()
              .result(),
            children: [
              { index: true, element: <Navigate to='/settings/company' /> },
              {
                path: '/settings/company',
                lazy: lazyUtil
                  .component(React.lazy(() => import('../pages/settings/company/company.page')))
                  .protected()
                  .result(),
                children: [
                  { index: true, element: <Navigate to='/settings/company/company' /> },
                  {
                    path: ':tab',
                    element: <CompanyDetails />,
                    lazy: lazyUtil.protected().loader(companyTabLoader).result(),
                  },
                ],
              },
              {
                path: '/settings/configuration',
                lazy: lazyUtil
                  .component(
                    React.lazy(() => import('../pages/settings/configuration/configuration.page')),
                  )
                  .protected()
                  .result(),
                children: [
                  { index: true, element: <Navigate to='/settings/configuration/customer' /> },
                  {
                    path: ':tab',
                    element: <ConfigurationDetails />,
                    lazy: lazyUtil.protected().loader(configurationTabLoader).result(),
                  },
                ],
              },
              {
                path: '/settings/print-forms',
                lazy: lazyUtil
                  .component(
                    React.lazy(() => import('../pages/settings/printForms/printForms.page')),
                  )
                  .protected()
                  .result(),
                children: [
                  { index: true, element: <Navigate to='/settings/print-forms/general' /> },
                  {
                    path: ':tab',
                    element: <PrintFormsDetails />,
                    lazy: lazyUtil.protected().loader(printFormsTabLoader).result(),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const devRoutes = [
  {
    path: '/login',
    lazy: lazyUtil.component(React.lazy(() => import('../pages/login/login.page'))).result(),
  },
  ...prodRoutes,
];

export const routes = sentryCreateBrowserRouter(appEnv === 'production' ? prodRoutes : devRoutes);

export const rootSectionsPaths = ['order', 'debitor']; // paths that have subsections. Required to correctly display the first loading when redirected
