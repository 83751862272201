import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { delay } from 'rxjs';
import { Stack, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import {
  generalTabState,
  IGeneralTabState,
  initSpecialPricePosition,
} from '../../generalTab.state';
import { FieldsRow } from '../../../../../../../shared/components/form';
import { IconButton } from '../../../../../../order/common/iconButton/iconButton.component';
import { Table } from '../../../../../../../shared/components/table/table.component';
import { storageHelper } from '../../../../../../../shared/helpers/storage';
import { Column } from '../../../../../../../shared/components/table/components/common.components';
import { localeFormatterHelper } from '../../../../../../../shared/helpers/formatter/localeFormatter.helper';
import { SpecialPricesPopup } from './popups/specialPrices.popup';
import { viewRows } from './views/viewRows';
import { TMode } from '../../../../../../../shared/services/mode/mode.service';
import { ArticleGeneralTabDataRes } from '../../../../../services/article.service';
import { ITabState } from '../../../../../states/tabState.model';

export const SpecialPrices: FC<ISpecialPrices> = ({
  articleSpecialPrices,
  specialPriceCategoriesColumns,
  createSpecialPriceCategoriesInputs,
  mode,
}) => {
  const isEditMode = mode === 'edit';
  const { t } = useTranslation();
  const [specialPricesData, setSpecialPricesData] =
    useState<ITabState['articleSpecialPrices']>(articleSpecialPrices);
  const [selectPos, setSelectPos] = useState<IGeneralTabState['selectedSpecialPricesPos']>(
    specialPricesData?.[0],
  );
  const { getValues, setValue } = useFormContext();
  const specialPricesPopup = useMemo(
    () =>
      new SpecialPricesPopup({
        createSpecialPriceCategoriesInputs:
          createSpecialPriceCategoriesInputs as ITabState['createSpecialPriceCategoriesInputs'],
      }),
    [],
  );
  const saveSpecialPricesGrid = useRef((e: MouseEvent) => {
    if (!e.target || !isEditMode) return;
    const target = e.target as HTMLElement;
    if (!target.closest('.special_price')) {
      generalTabState.pub.saveSpecialPricesGrid(getValues('id'));
    }
  });

  useEffect(() => {
    // on create/copy mode here we provide data to form
    if (!isEditMode) {
      const specialPricesForSave = specialPricesData
        ?.filter(({ fromDate, toDate }) => fromDate || toDate)
        ?.map(({ id: _id, isActual: _, ...rest }) => ({
          ...rest,
        })) as ArticleGeneralTabDataRes['articleSpecialPrices'];
      setValue('articleSpecialPrices', specialPricesForSave);
    }
  }, [specialPricesData, isEditMode]);

  useEffect(() => {
    generalTabState.pub.initArticleSpecialPrices(specialPricesData);
    const unsubState = generalTabState.sub
      .state()
      .subscribe(({ articleSpecialPrices, selectedSpecialPricesPos }) => {
        setSpecialPricesData(articleSpecialPrices);
        setSelectPos(selectedSpecialPricesPos);
      });
    specialPricesPopup.stream
      .state()
      .pipe(delay(0))
      .subscribe(({ action }) => {
        action === 'open' && document.removeEventListener('click', saveSpecialPricesGrid.current);
        action === 'close' && document.addEventListener('click', saveSpecialPricesGrid.current);
      });
    document.addEventListener('click', saveSpecialPricesGrid.current);
    return () => {
      unsubState.unsubscribe();
      specialPricesPopup.stream.unsubscribe();
      document.removeEventListener('click', saveSpecialPricesGrid.current);
    };
  }, []);

  const buttonList: IButtonList[] = useMemo(
    () => [
      {
        title: t('article.addNewRecord'),
        icon: <AddIcon />,
        onClick: () =>
          specialPricesPopup.stream.emit('open', {
            values: {
              row: {
                ...initSpecialPricePosition,
                id: `new_${Date.now()}`,
              },
              field: 'fromDate',
              allPricesData: specialPricesData,
            },
          }),
      },
      {
        title: t('article.deleteRecord'),
        icon: <RemoveIcon />,
        onClick: () => generalTabState.pub.deleteSpecialPricesPos(),
        disabled: !specialPricesData.length || !selectPos,
      },
    ],
    [t, specialPricesData, selectPos],
  );
  const priceArray = generatePriceArray();

  return (
    <>
      <FieldsRow marginBottom={1} alignItems='center' spacing={0}>
        <Stack direction='row' spacing={2}>
          {buttonList.map(({ icon, ...props }, i) => (
            <IconButton key={i} color='primary' size='large' {...props}>
              {icon}
            </IconButton>
          ))}
        </Stack>
      </FieldsRow>
      <STable
        data={specialPricesData}
        initialState={{
          columns: storageHelper.local.getItem('article.specialPricesColumnModel'),
        }}
        onChangeColumnModel={(v) =>
          storageHelper.local.setItem('article.specialPricesColumnModel', v)
        }
        rowSelectionModel={selectPos?.id || undefined}
        onRowClick={(v, e) => {
          generalTabState.pub.selectSpecialPricesPos(v.row);
          specialPricesPopup.stream.emit('open', {
            values: {
              row: v.row,
              field: (e.target as any).parentElement?.dataset?.field,
              allPricesData: specialPricesData,
            },
          });
        }}
        focusTabProps={{
          enabled: true,
          enabledFields: ['description', 'fromDate', 'isActual', 'toDate', ...priceArray],
        }}
        onCellKeyDown={({ row, field }, { code }) => {
          if (code === 'Enter') {
            generalTabState.pub.selectSpecialPricesPos(row);
            specialPricesPopup.stream.emit('open', {
              values: { row, field, allPricesData: specialPricesData },
            });
          }
        }}
        height={400}
        viewRows={viewRows}
      >
        <Column
          field='fromDate'
          headerName={t('common.from_date')}
          valueGetter={({ value }) => (value ? localeFormatterHelper.formatDate(value) : '')}
          sortable={false}
          width={105}
        />
        <Column
          field='toDate'
          headerName={t('common.to_date')}
          valueGetter={({ value }) => (value ? localeFormatterHelper.formatDate(value) : '')}
          sortable={false}
          width={105}
        />
        {specialPriceCategoriesColumns}
        <Column field='description' headerName={t('common.note')} sortable={false} width={200} />
      </STable>
      <specialPricesPopup.Component />
    </>
  );
};

function generatePriceArray() {
  const prices = [];
  for (let i = 1; i <= 10; i++) {
    prices.push(`price${i}`);
  }
  return prices;
}

const STable = styled(Table)({
  '& .MuiDataGrid-cell': {
    padding: 0,
  },
  '& .MuiDataGrid-cellContent': {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'inherit',
  },
});

export type TArticleSpecialPrices = ITabState['articleSpecialPrices'];

interface ISpecialPrices {
  articleSpecialPrices: TArticleSpecialPrices;
  specialPriceCategoriesColumns: ITabState['specialPriceCategoriesColumns'];
  createSpecialPriceCategoriesInputs?: ITabState['createSpecialPriceCategoriesInputs'];
  mode: TMode;
}

interface IButtonList {
  title: string;
  icon: ReactNode;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
}
