import { Player } from '@lottiefiles/react-lottie-player';
import { Stack, styled } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { take } from 'rxjs';
import { fadeIn, fadeOut } from '../../styles/animations';
import pageAnimation from './page-loader.json';
import { splashScreenService } from './service/splashScreen.service';

export const SplashScreen: FC<ISplashScreen> = ({ w = '300px', h = '300px' }) => {
  const [show, setShow] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const playerRef = useRef<any>(null);

  useEffect(() => {
    const unsub = splashScreenService.sub
      .off()
      .pipe(take(1))
      .subscribe((v) => {
        setShow(v);
      });
    return () => {
      unsub.unsubscribe();
    };
  }, []);

  const animationHandle = () => {
    if (show === false) {
      if (playerRef.current) {
        const p = playerRef.current as Player;
        p.stop();
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading && (
        <SWrapper data-testid='splashscreen'>
          <SAnimation animated={show} onAnimationEnd={animationHandle}>
            <Stack justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
              <Player
                ref={playerRef}
                autoplay={true}
                loop={true}
                src={pageAnimation}
                style={{ width: w, height: h }}
              />
            </Stack>
          </SAnimation>
        </SWrapper>
      )}
    </>
  );
};
interface ISplashScreen {
  w?: string;
  h?: string;
}
export const SWrapper = styled('div')(() => ({
  position: 'relative',
  left: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
}));
export const SAnimation = styled('div')<{ animated: boolean }>(({ animated }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  zIndex: 999,
  opacity: 0,
  backgroundColor: 'white',
  transform: `translate3d(0, 0, 0)`,
  animation: `${animated ? fadeOut : fadeIn} 700ms ease forwards`,
}));
