import { Box, Button } from '@mui/material';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { take } from 'rxjs';

import { Wa_CreateArticleInput } from '../../../../../graphql/generatedModel.ts';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler.ts';
import { modeService } from '../../../../../shared/services/mode/mode.service.ts';
import { articleService, CreateOrCopyArticleRes } from '../../../services/article.service.ts';
import { articleTabLoadingService } from '../articleTababLoading.service.ts';
import { IGeneralTabProps } from './general.tab.tsx';
import { GeneralForm } from './components/generalForm.component.tsx';
import { ITabState } from '../../../states/tabState.model.ts';

export const GeneralCopyTab: FC<IGeneralCopyTabProps> = ({
  mode,
  isDough,
  loading,
  initSpecialPrices,
}) => {
  const { t } = useTranslation();
  const {
    formState: { dirtyFields },
    getValues,
    handleSubmit,
    setValue,
    reset,
  } = useFormContext();

  const onSubmitForm = () => {
    const { id, ...data } = getValues();
    if (data.articleNo && dirtyFields['articleNo']) {
      articleTabLoadingService.pub.loading(true);
      const d = data as Wa_CreateArticleInput;
      articleService.pub.copyArticle({ originalId: id, data: d });
    }
  };

  const onCancelCopyMode = () => {
    // keep this reset() for server validation handler
    // this keepDirty blocks pub.save() in handleSubmitValue from being called again after copying or creating a new entry
    reset(undefined, { keepDirty: false });
    modeService.pub.mode('edit');
  };

  const validValueHandle = (v: Record<string, any>) => {
    setValue('articleNo', v['articleNo']);
  };

  useEffect(() => {
    const copyArticleSub = articleService.sub
      .copyArticle()
      .pipe(
        take(1),
        responseHandler<CreateOrCopyArticleRes | undefined>({
          success: () => 'article.article_saved',
          customErrorHandler: () => {
            articleTabLoadingService.pub.loading(false);
            return 'article.article_not_saved';
          },
        }),
      )
      .subscribe(() => articleTabLoadingService.pub.loading(false));

    return () => {
      copyArticleSub.unsubscribe();
    };
  }, []);

  return (
    <>
      <GeneralForm
        loading={loading}
        handleSubmitValue={validValueHandle}
        mode={mode}
        isDough={isDough}
        articleSpecialPrices={initSpecialPrices || []}
      />
      <Box mt={2} display='flex' gap={2}>
        <Button variant='outlined' onClick={onCancelCopyMode}>
          {t('common.cancel')}
        </Button>
        <Button type='submit' variant='contained' onClick={handleSubmit(onSubmitForm)}>
          {t('article.copy_article')}
        </Button>
      </Box>
    </>
  );
};

interface IGeneralCopyTabProps extends Pick<IGeneralTabProps, 'mode' | 'isDough' | 'loading'> {
  initSpecialPrices: ITabState['articleSpecialPrices'] | null;
}
