import { IconButton, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme as theme } from '../../../shared/styles/theme/theme';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import { navigateService } from '../../../shared/services/navigate/navigate.service';
import { searchState } from '../../../shared/components/globalSearch/states/search.state';

export const Header: FC<IHeader> = ({ searchString }) => {
  const navigateFrom = useLocation()?.state?.navigateFrom;
  const [t] = useTranslation();

  const handleCloseIconClick = () => {
    searchState.pub.resetSearch();
    const isNavigateFromGlobalSearch = navigateFrom?.includes('globalSearch');
    if (isNavigateFromGlobalSearch || !navigateFrom) {
      navigateService.pub.navigateTo('customer/general');
    } else {
      navigateService.pub.navigateTo(navigateFrom);
    }
  };

  return (
    <Stack
      width='100%'
      alignItems='center'
      justifyContent='center'
      sx={{
        position: 'relative',
        marginBottom: '24px',
      }}
    >
      <Typography
        variant='h4'
        fontSize='14px'
        fontWeight='500'
        color={theme.palette.grey['600']}
        lineHeight='21.98px'
      >
        {t('common.search_result_for')}:
      </Typography>
      <Typography variant='h4' fontSize='20px' fontWeight='500' lineHeight='32px'>
        {searchString}
      </Typography>
      <IconButton
        onClick={handleCloseIconClick}
        sx={{ position: 'absolute', top: '-9px', right: '-26px' }}
      >
        <CloseIcon fontSize='large' />
      </IconButton>
    </Stack>
  );
};

interface IHeader {
  searchString?: string;
}
