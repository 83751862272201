import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ITabState } from '../../../states/tabState.model.ts';
import { useLoaderData } from 'react-router-dom';
import { CustomerTourDataRes } from '../../../services/customer.service.ts';
import { tourTabState } from './tour.state.ts';
import { Content } from '../../../../../shared/components/content/content.component.tsx';
import { TourForm } from './components/tourForm.component.tsx';
import { formatDeliveryTime } from '../../../../../shared/helpers/utils/utils.helper.ts';

export const Tour: FC = () => {
  const { defaultValues, tourTabOptions } = useLoaderData() as ITabState;
  const { t } = useTranslation();

  const formMethods = useForm<CustomerTourDataRes>({
    mode: 'onChange',
    defaultValues,
  });

  const { reset, resetField, getValues } = formMethods;

  useEffect(() => {
    const unsubState = tourTabState.sub
      .state()
      .subscribe(({ action, dataToSave, defaultValues }) => {
        if (action === 'save') {
          for (const key in dataToSave?.data) {
            const fieldName = key as keyof CustomerTourDataRes;
            let value;

            if (
              fieldName === 'tourDepartureTime' ||
              fieldName === 'tourSatDepartureTime' ||
              fieldName === 'tourSunDepartureTime'
            ) {
              value = formatDeliveryTime(dataToSave.data[key as keyof typeof dataToSave.data]);
            } else {
              value = dataToSave.data[key as keyof typeof dataToSave.data];
            }

            resetField(fieldName, { defaultValue: value, keepDirty: false });
          }
        }
        if (action === 'list.selectedRecord') {
          reset(defaultValues);
        }
      });
    return () => {
      unsubState.unsubscribe();
    };
  }, []);

  return (
    <>
      {Object.keys(getValues()).length ? (
        <TourForm formMethods={formMethods} tourTabOptions={tourTabOptions} />
      ) : (
        <Content
          type='paper'
          paperProps={{
            variant: 'elevation',
            sx: {
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              height: '100%',
            },
          }}
          emptyData={{
            value: true,
            title: t('common.no_data'),
          }}
        />
      )}
    </>
  );
};
