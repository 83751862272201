import { map, Observable, switchMap, finalize } from 'rxjs';

import { Pub, Service, Sub } from '../../../shared/services/service.abstract.ts';
import {
  GetExportLabelAssistMutation,
  GetExportLabelAssistMutationVariables,
  GetExportSmartScaleMutation,
  GetExportSmartScaleMutationVariables,
} from '../../../graphql/generatedModel.ts';
import { getExportLabelAssist, getExportSmartScale } from './gql/productionReports.gql.ts';
import { gqlClient } from '../../../graphql/graphqlRequest.ts';

type Actions = 'exportLabelAssist' | 'exportSmartScale' | undefined;

class PubImpl extends Pub<Actions> {
  exportLabelAssist(params: GetExportLabelAssistMutationVariables): void {
    this.emit('exportLabelAssist', params);
  }
  exportSmartScale(params: GetExportSmartScaleMutationVariables): void {
    this.emit('exportSmartScale', params);
  }
  clearStream() {
    this.emit(undefined, {});
  }
}

class SubImpl extends Sub<Actions> {
  exportSmartScale(): Observable<ExportSmartScaleRes> {
    return this.actionListener('exportSmartScale').pipe(
      switchMap(({ params }) => {
        return gqlClient(getExportSmartScale, params) as Observable<GetExportSmartScaleMutation>;
      }),
      map((data: GetExportSmartScaleMutation) => {
        return data?.wawiAssist?.prepareSmartScaleExport as ExportSmartScaleRes;
      }),
      finalize(() => productionReportService.pub.clearStream()),
    );
  }

  exportLabelAssist(): Observable<ExportLabelAssistRes> {
    return this.actionListener('exportLabelAssist').pipe(
      switchMap(({ params }) => {
        return gqlClient(getExportLabelAssist, params) as Observable<GetExportLabelAssistMutation>;
      }),
      map((data: GetExportLabelAssistMutation) => {
        return data?.wawiAssist?.prepareLabelAssistExport as ExportLabelAssistRes;
      }),
      finalize(() => productionReportService.pub.clearStream()),
    );
  }
}

class ProductionReportService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const productionReportService = new ProductionReportService();

export type ExportSmartScaleRes = NonNullable<
  NonNullable<GetExportSmartScaleMutation['wawiAssist']>['prepareSmartScaleExport']
>;
export type ExportLabelAssistRes = NonNullable<
  NonNullable<GetExportLabelAssistMutation['wawiAssist']>['prepareLabelAssistExport']
>;
