import { gql } from 'graphql-request';

export const r10 = gql`
  mutation create_r10_CustomersDebtNotifier($params: R10CustomersDebtNotifierInput!) {
    wawiAssist {
      create_r10_CustomersDebtNotifier(params: $params) {
        taskId
        url
        s3Key
      }
    }
  }
`;
export const r24 = gql`
  mutation create_r24_PaymentJournalA4($paymentsId: [ID!]!) {
    wawiAssist {
      create_r24_PaymentJournalA4(paymentsId: $paymentsId) {
        taskId
        url
        s3Key
      }
    }
  }
`;
export const r35 = gql`
  mutation create_r35_ExtendedPaymentJournal($paymentsId: [ID!]!) {
    wawiAssist {
      create_r35_ExtendedPaymentJournal(paymentsId: $paymentsId) {
        taskId
        url
        s3Key
      }
    }
  }
`;
