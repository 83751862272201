import { Params } from 'react-router-dom';
import { Observable, map, merge } from 'rxjs';
import { Pub, State, Sub } from '../../state/state.abstract';

const initNavigateState: INavigateState = {
  action: undefined,
  navigateTo: location.pathname,
  params: {},
};

class PubImpl extends Pub<INavigateState> {
  navigateTo(navigateTo: INavigateState['navigateTo'], replace: INavigateState['replace'] = false) {
    this.emit('navigate', { navigateTo, replace });
  }
  params({ navigateTo, params }: Pick<INavigateState, 'navigateTo' | 'params'>) {
    this.emit('params', { navigateTo, params });
  }
}
class SubImpl extends Sub<INavigateState> {
  protected actionHandlers(): Observable<INavigateState> {
    return merge(this.navigateTo(), this.params());
  }
  private navigateTo(): Observable<INavigateState> {
    return this.actionListener('navigate').pipe(
      map((state) => {
        const data = { ...state };
        state.replace = false;
        return data;
      }),
    );
  }
  params(): Observable<INavigateState> {
    return this.actionListener('params');
  }
}

class NavigateService extends State<INavigateState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export interface INavigateState {
  action: 'navigate' | 'params' | undefined;
  navigateTo: string;
  replace?: boolean;
  params: Readonly<Params<string>>;
}

export const navigateService = new NavigateService(initNavigateState);
