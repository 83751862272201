/* eslint-disable react-hooks/rules-of-hooks */

import { FC, useMemo } from 'react';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../shared/components/popup/popup.abstract';
import { TPopupDefaultActions } from '../../../../../../../shared/components/popup/services/popup.service';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { Content } from './content.popup';
import { PeriodConditions } from '../../../../../services/customer.service';

export class DeliveryPeriods extends Popup<IDeliveryPeriods> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const { t } = useTranslation();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 1102 },
          '& > div > div > div': { padding: 0 },
        }));
      }, []);

      return (
        <SWrapper stream={stream} popupTitle={t('article.delivery_periods')}>
          <Content stream={stream} />
        </SWrapper>
      );
    };
  }
}

export interface IDeliveryPeriods {
  action: 'save' | TPopupDefaultActions;
  values: {
    periodConditions: PeriodConditions;
  };
}
