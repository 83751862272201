import { LoaderFunction } from 'react-router-dom';
import {
  ICustomerFilterOptionsRes,
  ICustomerListState,
  initCustomerListState,
} from '../create/states/customerList.state';
import { storageHelper } from '../../../shared/helpers/storage';
import { format } from 'date-fns';
import {
  C_Invoice_Interval,
  GetCustomerListQueryVariables as listCustomerForNewInvoicesVariables,
} from '../../../graphql/generatedModel';
import { QueryDataRes, createInvoiceService } from '../services/createInvoice.service';
import { map, take, zipWith } from 'rxjs';
import { dataHelper } from '../../../shared/helpers/data/data.helper';
import {
  IInvoiceDetailsState,
  initInvoiceDetailsState,
} from '../create/states/invoiceDetails.state';
import {
  configsData,
  DictDeliveryNoteGroupsRes,
  DictTextBlocksRes,
} from '../../../shared/services/configsData/configsData.service';
import { IInitData } from '../create/components/invoiceDetails/invoiceDetails.component';
import { GridSortModel } from '@mui/x-data-grid-premium';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';

export const createInvoiceLoader: LoaderFunction = async (): Promise<ICreateInvoiceLoader> => {
  let initCustomersList = { ...initCustomerListState };
  initCustomersList = await resolveCustomerListData(initCustomersList);
  const initInvoiceData = await resolveInvoiceData({
    initData: { ...initInvoiceDetailsState },
    datePeriod: initCustomersList.datePeriod,
    id: initCustomersList.selectedCustomer?.customerId,
  });
  return {
    initCustomersList,
    initInvoiceData,
  };
};

const resolveCustomerListData = async (data: ICustomerListState): Promise<ICustomerListState> => {
  const datePeriod = storageHelper.session.getItem('createInvoice.datePeriod') || data.datePeriod;
  let selectedCustomer = storageHelper.session.getItem('createInvoice.selectedCustomer');
  const sortModel = storageHelper.local.getItem('createInvoice.sortModel') || data.sortModel;
  const filterValues = {
    showEmailService: true,
    showMailDelivery: true,
    customersActiveState: true,
  };
  if (!datePeriod?.fromDate) {
    const { fromDate, toDate } = getPeriodDateBySpecialLogic(new Date());
    datePeriod.fromDate = fromDate;
    datePeriod.toDate = toDate;
  }
  const filter: listCustomerForNewInvoicesVariables['filter'] = {
    ordersStartDate: datePeriod?.fromDate,
    ordersEndDate: datePeriod?.toDate,
    ...filterValues,
  };

  let [customerList, groupOptions] = await createInvoiceService.globHelpers.streamToPromise(
    createInvoiceService.sub.customerList().pipe(
      zipWith(configsData.sub.dictDeliveryNoteGroups()),
      responseHandler<[ICustomerListState['customerList'], DictDeliveryNoteGroupsRes]>({
        errorReturnType: [[], []],
      }),
      take(1),
    ),
    () => {
      createInvoiceService.pub.customerList({ filter });
      configsData.pub.common(['dictDeliveryNoteGroups']);
    },
  );

  if (customerList.length) {
    customerList = dataHelper
      .data(customerList as [])
      .sort({ sortModel })
      .result() as ICustomerListState['customerList'];
  }

  const isSelectedRecordInList =
    selectedCustomer &&
    !!customerList.find((item) => item?.customerId === selectedCustomer?.customerId);

  selectedCustomer = isSelectedRecordInList ? selectedCustomer : customerList[0];
  const allCustomerListLength = customerList.length;

  const filterOptionsData = {
    groupOptions,
    invoiceIntervalOptions: {
      active: [],
      inactive: [],
    },
  } as ICustomerFilterOptionsRes;

  Object.values(C_Invoice_Interval).forEach((item) => {
    if (item === C_Invoice_Interval.II1_MONTHLY || item === C_Invoice_Interval.II7_MANUAL) {
      filterOptionsData.invoiceIntervalOptions.inactive.push({ id: item, label: `enums.${item}` });
    }
    if (
      item === C_Invoice_Interval.II1_MONTHLY ||
      item === C_Invoice_Interval.II2_MONTHLY2 ||
      item === C_Invoice_Interval.II3_MONTHLY3 ||
      item === C_Invoice_Interval.II4_MONTHLY4 ||
      item === C_Invoice_Interval.II5_HALF_MONTHLY ||
      item === C_Invoice_Interval.II6_HALF_WEEKLY ||
      item === C_Invoice_Interval.II7_MANUAL
    ) {
      filterOptionsData.invoiceIntervalOptions.active.push({ id: item, label: `enums.${item}` });
    }
  });

  return {
    ...data,
    action: 'loader',
    customerList,
    datePeriod,
    selectedCustomer,
    allCustomerListLength,
    sortModel,
    filter: filterValues,
    filterOptionsData,
  };
};

export const getPeriodDateBySpecialLogic = (
  currentDate: Date,
): ICustomerListState['datePeriod'] => {
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  return {
    fromDate:
      currentDate.getDate() < 7
        ? format(currentDate.setFullYear(currentYear, currentMonth - 1, 1), 'yyyy-MM-dd')
        : format(currentDate.setDate(1), 'yyyy-MM-dd'),
    toDate: format(currentDate.setFullYear(currentYear, currentMonth + 1, 0), 'yyyy-MM-dd'),
  };
};

const resolveInvoiceData = async ({
  initData,
  datePeriod,
  id,
}: resolveInvoiceDataProps): Promise<IInitData> => {
  if (!id) {
    const textList =
      (await createInvoiceService.globHelpers.streamToPromise(
        configsData.sub
          .dictTextBlocks()
          .pipe(responseHandler<DictTextBlocksRes>({ errorReturnType: [] }), take(1)),
        () => {
          configsData.pub.common(['dictTextBlocks']);
        },
      )) || [];
    const linkText = textList.find((v) => v?.isDefaultForInvoice)?.id as string;

    return { ...initData, linkText, textList };
  }

  const orderSort = (storageHelper.local.getItem('createInvoice.orderSort') ||
    initData?.orderSort) as GridSortModel;

  const data = await createInvoiceService.globHelpers.streamToPromise(
    configsData.sub.dictTextBlocks().pipe(
      zipWith(createInvoiceService.sub.queryData()),
      responseHandler<[DictTextBlocksRes, QueryDataRes]>({
        errorReturnType: [
          [],
          {
            customerInfo: { isEmailServiceForInvoice: false },
            orders: [],
            selectedOrder: {
              orderId: 'errorId',
              orderDate: new Date(),
              orderNo: '',
              customerId: '',
            },
            linkText: '',
            orderPositions: [],
            isMultipayCustomer: false,
          },
        ],
      }),
      take(1),
      map(([textList, restData]) => {
        const linkText = textList.find((v) => v?.isDefaultForInvoice)?.id;
        return {
          ...restData,
          textList,
          linkText,
          orderSort,
          defaultLinkText: linkText,
        };
      }),
    ),
    () => {
      createInvoiceService.pub.queryData({ id, datePeriod, orderSort });
      configsData.pub.common(['dictTextBlocks']);
    },
  );

  return {
    ...initData,
    ...data,
    customerId: id,
    datePeriod,
    action: 'loader',
  } as IInitData;
};

interface resolveInvoiceDataProps {
  initData: IInvoiceDetailsState;
  datePeriod: ICustomerListState['datePeriod'];
  id?: NonNullable<ICustomerListState['selectedCustomer']>['customerId'];
}

export interface ICreateInvoiceLoader {
  initCustomersList: ICustomerListState;
  initInvoiceData: IInitData;
}
