import { FC, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { ITabState } from '../../../states/tabState.model';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ArticleLabelTabDataRes } from '../../../services/article.service';
import { Stack } from '@mui/material';
import { Content } from '../../../../../shared/components/content/content.component';
import { LabelForm } from './components/labelForm.component';
import { ILabelTabState, labelTabState } from './label.state';
import { createValidationSchema } from './validation/label.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { LabelTabOptionsRes } from '../../../loaders/labelTab.resolver';

const validationSchema = createValidationSchema();

export const LabelTab: FC = () => {
  const { defaultValues, labelTabOptions } = useLoaderData() as ILableTab;
  const [isArticleIdExist, setIsArticleIdExist] = useState<boolean>(Boolean(defaultValues?.id));
  const [articleId, setArticleId] = useState<string>(defaultValues?.id as string);
  const { t } = useTranslation();
  const formMetods = useForm<ArticleLabelTabDataRes>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const {
    formState: { isValid, isDirty },
  } = formMetods;

  useEffect(() => {
    labelTabState.pub.form(formMetods);
  }, [isDirty, isValid]);

  useEffect(() => {
    const unsubarticleIdNotExist = labelTabState.sub.articleIdNotExist().subscribe(() => {
      setIsArticleIdExist(false);
      formMetods.reset({});
    });
    const unsubState = labelTabState.sub.state().subscribe((v: IState) => {
      setIsArticleIdExist(true);
      const { defaultValues } = v;
      setArticleId(defaultValues?.id as string);
      formMetods.reset(defaultValues);
    });
    return () => {
      unsubState.unsubscribe();
      unsubarticleIdNotExist.unsubscribe();
    };
  }, []);

  return (
    <>
      {isArticleIdExist ? (
        <Stack marginTop={'10px'}>
          <LabelForm
            formMethods={formMetods}
            recipes={(labelTabOptions as LabelTabOptionsRes)?.recipes}
            articleId={articleId}
          />
        </Stack>
      ) : (
        <Content
          type='paper'
          paperProps={{
            variant: 'elevation',
            sx: {
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              height: '100%',
            },
          }}
          emptyData={{
            value: true,
            title: t('common.no_data'),
          }}
        />
      )}
    </>
  );
};
interface IState extends ILabelTabState {
  defaultValues: ArticleLabelTabDataRes;
}
interface ILableTab extends ITabState {
  defaultValues: ArticleLabelTabDataRes;
}
