import { FC } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { DatePicker, IDatePicker } from '../../datePicker/datePicker.component.tsx';

export const FormDatePicker: FC<IFormDatePicker> = ({
  name,
  onChange,
  onBlur,
  controllerProps,
  ...props
}) => {
  return (
    <Controller
      {...controllerProps}
      name={name}
      render={({
        field: { value, onChange: onFieldChange, onBlur: onFieldBlur, ...restFields },
      }) => {
        return (
          <DatePicker
            {...props}
            value={value}
            onChange={(e) => {
              onFieldChange(e);
              onChange?.(e);
            }}
            onBlur={(e) => {
              onFieldBlur();
              onBlur?.(e);
            }}
            {...restFields}
          />
        );
      }}
    />
  );
};

interface IFormDatePicker extends Omit<IDatePicker, 'defaultValue' | 'value'> {
  name: ControllerProps['name'];
  controllerProps?: Omit<ControllerProps, 'name' | 'render'>;
}
