import { ChangeEvent, FC } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { Checkbox, ICheckboxProps } from '../fields/checkbox.component.tsx';
import { CheckboxProps } from '@mui/material';

export const FormCheckbox: FC<IFormCheckboxProps> = ({
  label,
  onChange,
  labelProps,
  name,
  controllerProps = {},
  ...props
}) => {
  return (
    <Controller
      name={name}
      {...controllerProps}
      render={({ field: { onChange: onFieldChange, ...fieldProps } }) => (
        <Checkbox
          {...fieldProps}
          checked={!!fieldProps.value}
          label={label}
          onChange={(e, checked) => {
            onFieldChange(e);
            onChange?.(checked, e);
          }}
          {...{ labelProps }}
          {...props}
        />
      )}
    />
  );
};

interface IFormCheckboxProps extends Omit<CheckboxProps, 'onChange'> {
  label?: string;
  name: ControllerProps['name'];
  onChange?: (v: boolean, e: ChangeEvent<HTMLInputElement>) => void;
  labelProps?: ICheckboxProps['labelProps'];
  controllerProps?: Omit<ControllerProps, 'render' | 'name'>;
}
