import { FC, ReactNode, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  IInfoBlockCommon,
  InfoBlock,
} from '../../../../shared/components/infoBlock/infoBlock.components.tsx';
import { useTranslation } from 'react-i18next';
/* Icons */
import PhoneIcon from '@mui/icons-material/Phone';
import MobileIcon from '@mui/icons-material/PhoneIphone';
import FaxIcon from '@mui/icons-material/Fax';
import EmailIcon from '@mui/icons-material/AlternateEmail';
import { List, ListItem, ListItemText } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { ICustomerInfo } from '../../create/states/orderDetails/orderDetails.state.ts';

export const CustomerInfo: FC<ICustomerInfoExpanded> = ({
  fullName = '',
  customerNo = '',
  emailTip,
  informationTip,
  contacts = {},
  ...rest
}) => {
  const { t } = useTranslation();
  const { register } = useFormContext();
  const {
    deliveryAddress,
    discountKindId,
    isDeliverySplitting,
    deliverySplittingPartsCount,
    transportSector,
    transportSectorId,
    ...props
  } = rest;
  const getIcon = (field: string): ReactNode => {
    switch (field) {
      case 'phoneMobile':
        return <MobileIcon />;
      case 'fax':
        return <FaxIcon />;
      case 'email':
        return <EmailIcon />;
      default:
        return <PhoneIcon />;
    }
  };
  const Contacts = useMemo(() => {
    const contactArr = Object.entries(contacts).reduce((acc, item, i) => {
      if (item[1] && !(typeof item[1] === 'object')) {
        acc.push(
          <SListItem
            title={t(`order.${item[0].replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`)}`)}
            key={i}
          >
            {getIcon(item[0])}
            <SContactValue primary={item[1]} />
          </SListItem>,
        );
      }
      return acc;
    }, [] as ReactNode[]);
    return <SList sx={{ columns: contactArr.length > 3 ? 2 : 'initial' }}>{contactArr}</SList>;
  }, [contacts, t]);

  const infoTip = useMemo(() => {
    if (informationTip) return informationTip.replace(/<head>[\s\S]*<\/head>|<[^>]+>/g, '').trim();
    return null;
  }, [informationTip]);

  return (
    <InfoBlock
      customerName={`${customerNo} ${fullName}`}
      rightColumn={Contacts}
      {...{ register, infoTip, emailTip, ...props }}
    />
  );
};

const SList = styled(List)(({ theme }) => ({
  padding: 0,
  '& .MuiSvgIcon-root': {
    width: 20,
    height: 20,
    color: theme.palette.grey['600'],
  },
}));

const SListItem = styled(ListItem)(() => ({
  padding: 0,
  marginBottom: 3,
  minWidth: 120,
}));

const SContactValue = styled(ListItemText)(() => ({
  marginLeft: 8,
  '& > span': {
    fontSize: 14,
  },
}));

interface ICustomerInfoExpanded extends ICustomerInfo {
  emailTip?: IInfoBlockCommon['emailTip'];
}
