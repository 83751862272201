import { FC, useEffect, useMemo } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';

import { DiscountSection } from './sections/discount.component.tsx';
import { useLoaderData } from 'react-router-dom';
import { ITabState } from '../../../../states/tabState.model.ts';
import { InvoiceSection } from './sections/invoice.component.tsx';
import { conditionsTabState } from '../conditions.state.ts';
import { PaymentSection } from './sections/payment.component.tsx';
import { IndividualPopup } from './popup/individual.popup.tsx';
import { CustomerConditionsRes } from '../../../../services/customer.service.ts';
import { IndividualPrices } from './sections/individualPrices/individualPrices.component.tsx';

export const ConditionsForm: FC<IConditionsForm> = ({
  formMethods,
  bank1MandatLoading,
  bank2MandatLoading,
}) => {
  const { conditionsTabOptions, configData, customerSpecialPrices } = useLoaderData() as ITabState;

  const {
    getValues,
    formState: { errors, dirtyFields },
  } = formMethods;
  const individualPopup = useMemo(() => new IndividualPopup(), []);

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    const fieldName = e.target.name as keyof CustomerConditionsRes;
    if (
      !errors[fieldName] &&
      !!dirtyFields[fieldName] &&
      fieldName !== 'bank1MandatNo' &&
      fieldName !== 'bank2MandatNo' &&
      fieldName !== 'bank1MandatSignatureDate' &&
      fieldName !== 'bank2MandatSignatureDate'
    ) {
      conditionsTabState.pub.save();
    }
  };

  const handleOpenPopup = () => {
    const individualPaymentTerms = getValues('individualPaymentTerms');
    individualPopup.stream.emit('open', {
      values: {
        individualPaymentTerms,
      },
    });
  };

  useEffect(() => {
    individualPopup.stream.state().subscribe(({ action }) => {
      if (action === 'save') {
        conditionsTabState.pub.save();
      }
    });
    return () => individualPopup.stream.unsubscribe();
  }, []);

  return (
    <FormProvider {...formMethods}>
      <form noValidate onBlur={onSubmit}>
        <DiscountSection
          priceCategoriesOptions={conditionsTabOptions.priceCategories}
          globalTaxSystemId={configData?.globalTaxSystemId}
          discountGroups={conditionsTabOptions?.discountGroups}
          quantityDiscounts={conditionsTabOptions?.quantityDiscounts}
        />
        <IndividualPrices
          productListOptions={conditionsTabOptions.productList}
          {...{ customerSpecialPrices }}
        />
        <InvoiceSection customersOptions={conditionsTabOptions.customers} />
        <PaymentSection
          bank1MandatLoading={bank1MandatLoading}
          bank2MandatLoading={bank2MandatLoading}
          handleOpenPopup={handleOpenPopup}
          bankOptions={conditionsTabOptions.banks}
          countryId={configData?.countryId}
        />
      </form>
      <individualPopup.Component />
    </FormProvider>
  );
};

export interface IConditionsForm {
  formMethods: UseFormReturn<CustomerConditionsRes>;
  bank1MandatLoading: boolean;
  bank2MandatLoading: boolean;
}
