import { FC, ReactNode, useEffect, useLayoutEffect, useMemo, useState } from 'react';
/* Icons */
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FlipIcon from '@mui/icons-material/Flip';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
/* Cells */
import { VatCell } from '../../../../common/orderGridItems/cells/vat/vat.cell.tsx';
import { VatEditCell } from '../../../../common/orderGridItems/cells/vat/vatEdit.cell.tsx';
import { DiscountEditCell } from '../../../../common/orderGridItems/cells/discountEdit.cell.tsx';
import { QuantityEditCell } from '../../../../common/orderGridItems/cells/quantity/quantityEdit.cell.tsx';
import { QuantityCell } from '../../../../common/orderGridItems/cells/quantity/quantity.cell.tsx';
import { PriceEditCell } from '../../../../common/orderGridItems/cells/price/priceEdit.cell.tsx';
import { ProductCell } from '../../../../common/orderGridItems/cells/product/product.cell.tsx';
import { ProductEditCell } from '../../../../common/orderGridItems/cells/product/productEdit.cell.tsx';
import { PriceCell } from '../../../../common/orderGridItems/cells/price/price.cell.tsx';

import { FieldsRow } from '../../../../../../shared/components/form';
import { CircularProgress, Stack } from '@mui/material';
import { OrderActions } from './orderActions.component.tsx';
import { useTranslation } from 'react-i18next';
import { orderDetailsState } from '../../../states/orderDetails/orderDetails.state.ts';
import { Table } from '../../../../../../shared/components/table/table.component.tsx';
import { Column } from '../../../../../../shared/components/table/components/common.components.tsx';
import { GridAggregationFunction } from '@mui/x-data-grid-premium';
import { localeFormatterHelper } from '../../../../../../shared/helpers/formatter/localeFormatter.helper.ts';
import { viewCells } from '../../../../common/orderGridItems/views/viewCells.ts';
import { IOrderGridOptions, TOrderDetails } from '../orderDetails.component.tsx';
import { C_Discount_Kind } from '../../../../../../graphql/generatedModel.ts';
import { storageHelper } from '../../../../../../shared/helpers/storage';
import { customerListState, ICustomerListState } from '../../../states/customerList.state.ts';
import {
  attachSplittingToQuantity,
  generateSplittingColumns,
} from '../../../../common/orderGridItems/cells/splitting/additionalFunctions.tsx';
import { IndividualDescriptionPopup } from '../../../../common/popups/individualDescription/individualDescription.popup.tsx';
import { ChooseDefaultOrderPopup } from '../popups/chooseDefaultOrder/chooseDefaultOrder.popup.tsx';
import { blue } from '@mui/material/colors';
import { viewRows } from '../../../../common/orderGridItems/views/viewRows.ts';
import { IconButton } from '../../../../common/iconButton/iconButton.component.tsx';
import { delay } from 'rxjs';
import { DeletionCell } from '../../../../common/orderGridItems/cells/deletionCell.cell.tsx';

export const OrderGrid: FC<IOrderGrid> = ({
  data,
  orderGridOptions,
  rowReordering,
  deliveryFunctionActive,
  isPriceRateFixed,
}) => {
  const { t } = useTranslation();

  const [visibleDeliveryCost, setVisibleDeliveryCost] = useState(false);
  const { positions, ...orderData } = data.orderData;
  const { customerInfo } = data;
  const individualDescriptionPopup = useMemo(() => new IndividualDescriptionPopup(), []);
  const chooseDefaultOrderPopup = useMemo(() => new ChooseDefaultOrderPopup(), []);

  const [visibleSplitting, setVisibleSplitting] = useState(false);
  const [selectedCustomer, setSelectedCustomer] =
    useState<ICustomerListState['selectedCustomer']>();
  const [orderGridLoading, setOrderGridLoading] = useState(false);
  const [cellsLoading, setCellsLoading] = useState<string[]>([]);
  const [calculationPriceError, setCalculationPriceError] = useState<number[]>([]);
  const [deliveryCostLoading, setDeliveryCostLoading] = useState(false);

  useEffect(() => {
    const cellsLoadingSub = orderDetailsState.sub.cellsLoading().subscribe(setCellsLoading);
    const calculationPriceErrorSub = orderDetailsState.sub
      .calculationPriceError()
      .subscribe(setCalculationPriceError);
    const orderGridLoadingSub = orderDetailsState.sub
      .orderGridLoading()
      .subscribe(setOrderGridLoading);
    const deliveryCostLoadingSub = orderDetailsState.sub
      .deliveryCostLoading()
      .subscribe(setDeliveryCostLoading);
    const customerListSub = customerListState.sub
      .state()
      .pipe(delay(100))
      .subscribe(({ selectedCustomer }) => setSelectedCustomer(selectedCustomer));
    individualDescriptionPopup.stream.state().subscribe(({ action, row }) => {
      if (action === 'save') {
        orderDetailsState.pub.updatePosition(row);
      }
    });
    return () => {
      cellsLoadingSub.unsubscribe();
      orderGridLoadingSub.unsubscribe();
      calculationPriceErrorSub.unsubscribe();
      deliveryCostLoadingSub.unsubscribe();
      customerListSub.unsubscribe();
      individualDescriptionPopup.stream.unsubscribe();
      chooseDefaultOrderPopup.stream.unsubscribe();
    };
  }, []);

  const generatedSplittingColumns = useMemo(() => {
    return generateSplittingColumns(
      data.customerInfo.deliverySplittingPartsCount || 0,
      !!data.customerInfo.isDeliverySplitting,
      'create',
    );
  }, [data]);

  useLayoutEffect(() => {
    const isDelivery = !!positions?.some((el) => el?.virtualPositionId);
    setVisibleDeliveryCost((prevState) => {
      if (isDelivery !== prevState) return isDelivery;
      return prevState;
    });
  }, [positions]);
  const formatValue = (value: number) => {
    return localeFormatterHelper.formatNumber(value, {
      returnZero: false,
    });
  };
  const buttonList: IButtonList[] = useMemo(
    () => [
      {
        title: t('order.add_article'),
        icon: <AddIcon />,
        onClick: () => orderDetailsState.pub.addPosition({ type: 'addPosition' }),
      },
      {
        title: t('order.remove_article'),
        icon: <RemoveIcon />,
        disabled: !data.selectedPos,
        onClick: () =>
          orderDetailsState.pub.deletePosition({
            type: 'deletePosition',
            position: data.selectedPos,
          }),
      },
      {
        title: t('order.reset_to_default'),
        icon: <RestartAltIcon />,
        disabled: !data.dirty && !Number(selectedCustomer?.draftOrderId),
        onClick: () => orderDetailsState.pub.revertOrderData(),
      },
      {
        title: t('order.delivery_splitting'),
        icon: <FlipIcon />,
        hidden: !data.customerInfo.isDeliverySplitting,
        active: visibleSplitting,
        onClick: () => {
          const timer = setTimeout(() => {
            setVisibleSplitting((prevState) => !prevState);
            clearTimeout(timer); // fixes the problem if the cell is in edit mode
          }, 0);
        },
      },
      {
        title: t('order.choose_default'),
        icon: <MoveDownIcon />,
        onClick: () => chooseDefaultOrderPopup.stream.emit('open'),
      },
      {
        title: t('order.add_shipping_cost'),
        icon: <LocalShippingIcon />,
        active: visibleDeliveryCost,
        hidden: !deliveryFunctionActive,
        loading: deliveryCostLoading,
        onClick: () => {
          if (visibleDeliveryCost) {
            orderDetailsState.pub.deletePosition({ type: 'deleteDeliveryPosition' });
          } else orderDetailsState.pub.addPosition({ type: 'addDeliveryPosition' });
          setVisibleDeliveryCost((prevState) => !prevState);
        },
      },
    ],
    [
      t,
      data,
      visibleSplitting,
      visibleDeliveryCost,
      deliveryCostLoading,
      selectedCustomer?.draftOrderId,
    ],
  );
  const getAllNumberRow: GridAggregationFunction<string, string | null> = {
    apply: () => {
      const count: number =
        positions?.filter((el) => el?.quantity && el?.articleId && !el?.virtualPositionId).length ||
        0;
      return `${t('order.article', { count })}: ${count}`;
    },
    label: '',
    columnTypes: ['string'],
  };

  const getQuantity: GridAggregationFunction<string, string | null> = {
    apply: () => {
      const count = positions?.reduce((acc, el) => {
        if (el?.quantity && !el?.virtualPositionId) {
          acc += el.quantity;
        }
        return acc;
      }, 0);
      return String(count || '');
    },
    label: '',
    columnTypes: ['string'],
  };

  return (
    <>
      <FieldsRow justifyContent='space-between' alignItems='center' marginTop={3} spacing={0}>
        <Stack direction='row' spacing={2}>
          {buttonList.reduce((acc: ReactNode[], { icon, loading, hidden, ...props }, i) => {
            if (!hidden) {
              acc.push(
                <IconButton
                  sx={{ position: 'relative' }}
                  key={i}
                  color='primary'
                  size='large'
                  {...props}
                >
                  {loading && (
                    <CircularProgress
                      thickness={1}
                      size={48}
                      sx={{
                        position: 'absolute',
                        color: blue[500],
                        top: 0,
                        left: 0,
                        zIndex: 1,
                      }}
                    />
                  )}
                  {icon}
                </IconButton>,
              );
            }
            return acc;
          }, [])}
        </Stack>
        <OrderActions {...{ selectedCustomer }} />
      </FieldsRow>
      <Table
        initialState={{
          aggregation: {
            model: {
              articleNo: 'allNumberRow',
              total: 'sum',
              quantity: 'quantity',
            },
          },
          columns: storageHelper.local.getItem('createOrder.orderColumnModel'),
        }}
        hidingColumnModel={{
          generatedModelFn: attachSplittingToQuantity,
        }}
        onRowsCountChange={(rows, prevRows, apiRef) => {
          const isNotEventDeliveryCost =
            rows?.[rows.length - 1]?.virtualPositionId ===
            prevRows?.[prevRows.length - 1]?.virtualPositionId;
          if (rows.length > prevRows.length && isNotEventDeliveryCost) {
            const newRow = rows?.[rows.length - (visibleDeliveryCost ? 2 : 1)];
            if (newRow.__reorder__ === t('order.new_position')) {
              apiRef?.current.startCellEditMode({ id: newRow.id as string, field: 'quantity' });
            }
          }
        }}
        aggregationFunctions={{ allNumberRow: getAllNumberRow, quantity: getQuantity }}
        data={positions as []}
        heightOffset={334}
        sx={{ mt: 1 }}
        onChangeOrderPosition={(v) => {
          const virtualPositionId = v.findIndex((el) => el.virtualPositionId);
          if (virtualPositionId !== -1) {
            const deliveryPos = v[virtualPositionId];
            v.splice(virtualPositionId, 1);
            v.push(deliveryPos);
          }
          orderDetailsState.pub.updateOrderData({
            ...orderData,
            positions: v as [],
          });
        }}
        disableRowReorder={['deliveryCost']}
        onRowClick={(e) => {
          if (data.selectedPos?.id !== e.id && !e.row.virtualPositionId && e.row.isChangeable)
            orderDetailsState.pub.selectPosition(e.row);
        }}
        rowSelectionModel={data.selectedPos?.id || undefined}
        isCellEditable={(e) => e.row.isChangeable && !e.row.virtualPositionId}
        onChangeColumnModel={(v) => storageHelper.local.setItem('createOrder.orderColumnModel', v)}
        emptyTableIndication={{
          icon: <LibraryAddIcon />,
          text: t('order.empty_order_grid'),
        }}
        columnVisibilityModel={{
          group_splittingPartsPreset: visibleSplitting,
        }}
        onCellEditStop={(v, e) => {
          if (v.reason === 'enterKeyDown') {
            e.defaultMuiPrevented = true;
          }
        }}
        focusTabProps={{
          disabledRowsFocus: ['!virtualPositionId', 'isChangeable'],
          enabled: true,
        }}
        loading={orderGridLoading}
        {...{ viewCells, viewRows, rowReordering }}
      >
        <Column
          field='articleNo'
          headerName={t('common.article_no')}
          width={100}
          sortable={false}
        />
        <Column
          field='quantity'
          headerName={t('order.quantity')}
          width={100}
          sortable={false}
          headerAlign='right'
          align='right'
          renderEditCell={(params) => <QuantityEditCell type='create' {...params} />}
          renderCell={(params) => <QuantityCell loading={cellsLoading} {...{ params }} />}
          editable
        />
        {generatedSplittingColumns}
        <Column
          field='description'
          headerName={t('order.description')}
          width={350}
          sortable={false}
          renderCell={(params) => (
            <ProductCell
              loading={cellsLoading}
              popupStream={individualDescriptionPopup.stream}
              {...{ params, isPriceRateFixed }}
            />
          )}
          renderEditCell={(params) => (
            <ProductEditCell options={orderGridOptions.productList} type='create' {...{ params }} />
          )}
          editable
        />
        <Column
          field='price'
          headerName={t('order.price')}
          width={140}
          type='number'
          headerAlign='right'
          align='right'
          sortable={false}
          editable
          renderCell={(params) => <PriceCell errors={calculationPriceError} {...{ params }} />}
          renderEditCell={(params) => <PriceEditCell type='create' {...params} />}
        />
        <Column
          field='total'
          valueFormatter={({ value }) => (value ? formatValue(value) : '')}
          headerName={t('order.total')}
          width={140}
          headerAlign='right'
          align='right'
          type='number'
          sortable={false}
        />
        <Column
          field='discount'
          headerName={t('order.discount')}
          width={100}
          valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
          renderEditCell={(params) => <DiscountEditCell type='create' {...params} />}
          editable={
            customerInfo?.discountKindId !== C_Discount_Kind.DK5_QUANTITY_DISCOUNT &&
            customerInfo?.discountKindId !== C_Discount_Kind.DK6_QUANTITY_DISCOUNT_GROUPS
          }
          headerAlign='right'
          align='right'
          sortable={false}
        />
        <Column
          field='vatCode'
          headerName={t('order.vat')}
          width={80}
          editable
          renderCell={(params) => (
            <VatCell taxes={orderGridOptions.taxes} loading={cellsLoading} {...{ params }} />
          )}
          renderEditCell={(params) => (
            <VatEditCell taxes={orderGridOptions.taxes} type='create' {...{ params }} />
          )}
          sortable={false}
        />
        <Column
          field='deleting'
          flex={1}
          align='right'
          headerName=''
          renderCell={(params) => <DeletionCell type='create' {...{ params }} />}
          sortable={false}
          resizable={false}
          disableReorder
        />
      </Table>
      <individualDescriptionPopup.Component />
      <chooseDefaultOrderPopup.Component />
    </>
  );
};

export interface IOrderGrid {
  data: TOrderDetails;
  orderGridOptions: IOrderGridOptions;
  rowReordering: boolean;
  deliveryFunctionActive: boolean;
  isPriceRateFixed: boolean;
}
interface IButtonList {
  title: string;
  icon: ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  loading?: boolean;
  active?: boolean;
  onClick: () => void;
}
