import { Box, Button, Stack } from '@mui/material';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Period } from '../../../../../../../../../../shared/components/periodConditionsBox/periodConditionsBox.component';
import { EPeriodTypeForModal } from './period.popup';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { PeriodConditions } from '../../../../../../../../services/article.service';
import { localeFormatterHelper } from '../../../../../../../../../../shared/helpers/formatter/localeFormatter.helper';
import { sortTableByDate } from '../../../dataConvertors.popup';
import { getInitDate, makeTemporaryId } from '../../../common.popup';
import { Table } from '../../../../../../../../../../shared/components/table/table.component';
import { Column } from '../../../../../../../../../../shared/components/table/components/common.components';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

export const BreakOff: FC<IBreakOff> = ({ data, setData, startAddOrEditBreakOff }) => {
  const [t] = useTranslation();
  const formatDate = (value: string) => localeFormatterHelper.formatDate(value);
  const sortedData = sortTableByDate((data || []) as IBreakOffItem[]) as IBreakOffItem[];
  const [selectedBreakOff, setSelectedBreakOff] = useState<IBreakOffItem>();

  const addNewBreakOff = () => {
    const initialDate = getInitDate(sortedData) as string;
    startAddOrEditBreakOff(
      {
        description: '',
        fromDate: initialDate,
        toDate: initialDate,
        id: makeTemporaryId(),
        typeForModal: EPeriodTypeForModal.addNew,
      },
      sortedData,
    );
  };

  const deleteBreakOff = () => {
    if (selectedBreakOff) {
      setData((prevData) => {
        const prev = structuredClone(prevData);
        const { id } = selectedBreakOff;
        prev.vacations = prev.vacations.filter((vacation) => vacation.id !== id);
        setSelectedBreakOff(undefined);
        return prev;
      });
    }
  };

  return (
    <Box sx={{ pb: 3, flexGrow: 1 }}>
      <Stack direction='row' spacing={2} mb={1}>
        <Button size='small' onClick={addNewBreakOff}>
          <AddIcon fontSize='small' sx={{ mr: '8px' }} />
          {t('common.add')}
        </Button>

        <Button size='small' disabled={!selectedBreakOff?.id} onClick={() => deleteBreakOff()}>
          <RemoveIcon fontSize='small' sx={{ mr: '8px' }} />
          {t('common.remove')}
        </Button>
      </Stack>
      <Table
        heightOffset={460}
        data={sortedData as []}
        onRowClick={(e) => {
          const newId = e?.id;
          if (newId && newId !== selectedBreakOff?.id) {
            setSelectedBreakOff(e?.row);
          }
        }}
        rowSelectionModel={selectedBreakOff?.id}
      >
        <Column
          field='fromDate'
          headerName={t('common.from_date')}
          width={120}
          sortable={false}
          valueFormatter={({ value }) => (value ? formatDate(value) : '')}
        />
        <Column
          field='toDate'
          headerName={t('common.to_date')}
          width={120}
          sortable={false}
          valueFormatter={({ value }) => (value ? formatDate(value) : '')}
        />
        <Column field='description' headerName={t('common.note')} sortable={false} flex={1} />
        <Column
          field='id'
          headerName=''
          width={32}
          maxWidth={32}
          disableReorder
          sortable={false}
          renderCell={({ row }) => {
            return (
              <Box
                left='5px'
                position='relative'
                onClick={() =>
                  startAddOrEditBreakOff(
                    { ...row, typeForModal: EPeriodTypeForModal.editOld },
                    sortedData,
                  )
                }
              >
                <ModeEditIcon color='action' />
              </Box>
            );
          }}
        />
      </Table>
    </Box>
  );
};

export type IBreakOffItem = Pick<Period, 'id' | 'description' | 'fromDate' | 'toDate'> & {
  typeForModal?: EPeriodTypeForModal;
};

interface IBreakOff {
  data: PeriodConditions['vacations'];
  setData: Dispatch<SetStateAction<PeriodConditions>>;
  startAddOrEditBreakOff: (breakOff: IBreakOffItem, breakOffsData: IBreakOffItem[]) => void;
}
