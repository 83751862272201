import { useState, useEffect } from 'react';
import { companyConfigService } from '../services/companyConfig/companyConfig.service';
import { C_Country } from '../../graphql/generatedModel';

const useConfigDateFormat = (): string => {
  const [dateFormat, setDateFormat] = useState<string>('dd.MM.yyyy');
  const countryId = companyConfigService.getCachedConfigs()?.countryId as C_Country;
  useEffect(() => {
    switch (countryId as C_Country) {
      case C_Country.CO1_SWITZERLAND:
      case C_Country.CO2_GERMANY:
        setDateFormat('dd.MM.yyyy');
        break;
      case C_Country.CO3_AUSTRIA:
        setDateFormat('dd.MM.yyyy');
        break;
      case C_Country.CO5_LUXEMBOURG:
        setDateFormat('dd.MM.yyyy');
        break;
      default:
        setDateFormat('MM/dd/yyyy');
    }
  }, []);

  return dateFormat;
};

export default useConfigDateFormat;
