import { Observable, merge } from 'rxjs';
import { Pub, State, Sub } from '../../state/state.abstract';

class PubImpl extends Pub<IAppState> {
  networkStatus(networkStatus: IAppState['networkStatus']) {
    this.emit('networkStatus', { networkStatus });
  }
  logOutFromOtherTab() {
    this.emit('logOutFromOtherTab', {});
  }
  appUpdate(handleAppVersionUpdate: IAppState['handleAppVersionUpdate']) {
    this.emit('newAppVersionAvailable', { handleAppVersionUpdate });
  }
}

class SubImpl extends Sub<IAppState> {
  protected actionHandlers(): Observable<IAppState> {
    return merge(this.updateState());
  }

  protected updateState(): Observable<IAppState> {
    return this.actionListener(['newAppVersionAvailable', 'networkStatus', 'logOutFromOtherTab']);
  }
}

class AppState extends State<IAppState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const appState = new AppState({ action: undefined, networkStatus: 'online' });

interface IAppState {
  action: 'newAppVersionAvailable' | 'networkStatus' | 'logOutFromOtherTab' | undefined;
  networkStatus: 'online' | 'offline';
  handleAppVersionUpdate?: () => void;
}
