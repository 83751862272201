import { ListItemButton, ListItemIcon, ListItemText, styled, SvgIcon } from '@mui/material';
import { Context, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { navService } from '../nav.service';
import { INavContext, NavContext } from '../nav.context';
import { navBlocker } from '../blocker/nav.blocker.ts';

const isChildNavActive = (to: string, pathname: string): boolean => {
  const formatPath = (p: string) => p.split('/').filter(Boolean).splice(0, 2).join('/');
  return formatPath(pathname) === formatPath(to);
};

export const SubNavLink = ({ label, icon: Icon, to }: ISubNavLink) => {
  const { pathname } = useLocation();
  const isActive = isChildNavActive(to, pathname);
  const [navActive, setNavActive] = useState<boolean>(isActive);
  const { setParentNavActive } = useContext<INavContext>(NavContext as Context<INavContext>);
  const subNavLinkHandle = () => {
    if (navActive) return;
    navBlocker.controlledNavigate(() => {
      navService.pub.navigateTo(to);
      setNavActive(true);
      setParentNavActive(true);
    });
    navService.pub.closeMenu();
  };

  useEffect(() => {
    if (!isChildNavActive(to, pathname)) {
      setNavActive(false);
    } else {
      setNavActive(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (isActive) {
      setNavActive(true);
      setParentNavActive(true);
    }
    const navigateToSub = navService.sub.navigateTo().subscribe((userActionTo) => {
      const condition = isChildNavActive(userActionTo, pathname);
      if (!condition) {
        setNavActive(false);
        setParentNavActive(false);
      }
    });

    return () => {
      navigateToSub.unsubscribe();
    };
  }, []);

  return (
    <SSubLink dense onClick={subNavLinkHandle}>
      <SListItemIcon>{Icon && <Icon />}</SListItemIcon>
      <ListItemText
        sx={{ margin: 0 }}
        primaryTypographyProps={{
          ...(navActive && { color: 'primary' }),
          noWrap: true,
        }}
        primary={label}
      />
    </SSubLink>
  );
};

const SSubLink = styled(ListItemButton)({
  textDecoration: 'none',
  color: 'inherit',
  height: 36,
});

const SListItemIcon = styled(ListItemIcon)({
  justifyContent: 'flex-end',
  '& svg': {
    marginRight: 5,
    height: 20,
    width: 'auto',
  },
});

interface ISubNavLink {
  label: string;
  icon?: typeof SvgIcon;
  to: string;
}
