import { GridSortModel } from '@mui/x-data-grid-premium';
import { take } from 'rxjs';
import { dataHelper } from '../../../shared/helpers/data/data.helper';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';
import {
  DictRecipesWithConnectedArticlesRes,
  configsData,
} from '../../../shared/services/configsData/configsData.service';
import { ArticleLabelTabDataRes, articleService } from '../services/article.service';
import { ITabState } from '../states/tabState.model';

export const defaultArticleLabelTabData: ArticleLabelTabDataRes = {
  customLabelDescription: null,
  origin: null,
  eanCode: null,
  isEanCodeManagedByCA: false,
  declaration: null,
  isStoreInCoolPlace: false,
  storagePlace: null,
  consumersStorageInfo: null,
  sellingWeight: null,
  consumersInformation: null,
  daysToSell: null,
  daysToConsume: null,
  daysShelfLife: null,
  articleLabelDataFromRA: {
    id: null,
    autoDeclaration: null,
    declaration: null,
    isAutoDeclaration: false,
    isStoreInCoolPlace: false,
    daysToSell: null,
    daysToConsume: null,
    productOutputWeight: null,
    consumersInformation: null,
    consumersStorageInfo: null,
    sellingPoints: null,
    sellingWeight: null,
    daysShelfLife: null,
    sellingPointsFreezer: null,
  },
};

export async function resolveDataForLableTab(data: ITabState): Promise<ITabState> {
  const dictRecipesModel = [{ field: 'recipeNo', sort: 'asc' }] as GridSortModel;
  const defaultValues = data?.articleId
    ? await articleService.globHelpers.streamToPromise(
        articleService.sub.articleLabelTabData().pipe(
          responseHandler<ArticleLabelTabDataRes>({
            errorReturnType: defaultArticleLabelTabData,
            customErrorHandler: () => 'article.tab_not_loaded',
          }),
          take(1),
        ),
        () => {
          articleService.pub.articleLabelTabData({ id: data.articleId! });
        },
      )
    : {};

  const recipes: DictRecipesWithConnectedArticlesRes =
    await articleService.globHelpers.streamToPromise(
      configsData.sub.dictRecipesWithConnectedArticles().pipe(
        responseHandler<DictRecipesWithConnectedArticlesRes>({
          errorReturnType: [],
          customErrorHandler: () => 'article.tab_not_loaded',
        }),
        take(1),
      ),
      () => {
        configsData.pub.common(['dictRecipesWithConnectedArticles']);
      },
    );
  const cleanRecipes = recipes?.map(({ recipeId, recipeNo, recipeName }) => ({
    id: recipeId,
    recipeNo,
    label: recipeName,
  }));
  data.defaultValues = { ...defaultValues };
  data.defaultValues.id = data.articleId;
  data.labelTabOptions = {
    recipes: dataHelper
      .data(cleanRecipes as [])
      .sort({ sortModel: dictRecipesModel })
      .result(),
  };
  return data;
}

export interface LabelTabOptionsRes {
  recipes: Recipe[];
}

interface Recipe {
  id: string;
  recipeNo?: string | null;
  label: string | null;
}
