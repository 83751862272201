import React, {
  FC,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SingleInputDateRangeFieldProps } from '@mui/x-date-pickers-pro/SingleInputDateRangeField/SingleInputDateRangeField.types';
import { DateCalendarProps } from '@mui/x-date-pickers/DateCalendar/DateCalendar.types';
import { Box, Fade, IconButton, Popover, styled } from '@mui/material';
import {
  DateCalendar,
  DateRange,
  PickersDay,
  PickersDayProps,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import { Subject } from 'rxjs';
import useConfigDateFormat from '../../hooks/useConfigDateFormat.tsx';
import { IDateRangePicker } from './dateRangePicker.component.tsx';
import EventIcon from '@mui/icons-material/Event';
import { calculateFutureDate } from '../../helpers/utils/utils.helper.ts';
import { isSameWeek } from 'date-fns';
import { isArray } from 'remirror';
import { ControllerProps } from 'react-hook-form';

export const WeekPicker: FC<IWeekPicker> = forwardRef(
  (
    {
      width,
      onChange,
      onBlur,
      defaultValue,
      value,
      fieldProps = {},
      calendarProps = {},
      navigationLockFn = (handlers: () => void) => handlers(),
      name,
    },
    ref,
  ) => {
    const weekStream$ = useMemo(() => new Subject<(Date | null)[]>(), []);

    const startedFirstDate = getFirstDayOfWeek(defaultValue || new Date());
    const startedSecondDate = calculateFutureDate(startedFirstDate, 6);
    const [valueDates, setValueDates] = useState([startedFirstDate, startedSecondDate]);
    const [hoveredDay, setHoveredDay] = React.useState<Date | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [focused, setFocused] = useState(false);
    const isChanged = useRef(false);
    const pickerRef = useRef(null);
    const disabledBlur = useRef<boolean>(false);
    const open = Boolean(anchorEl);
    const calendarDateFormat = useConfigDateFormat();

    useEffect(() => {
      const dateSub = weekStream$.subscribe((date) => {
        onChange?.(date);
      });
      return () => {
        dateSub.unsubscribe();
      };
    }, []);
    useLayoutEffect(() => {
      if (value) {
        const firstDay = getFirstDayOfWeek(isArray(value) ? value[0] : value);
        setValueDates([firstDay, calculateFutureDate(firstDay, 6)]);
      }
    }, [value]);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      navigationLockFn(() => setAnchorEl(pickerRef.current));
    };

    fieldProps.InputProps = useMemo(
      () => ({
        ...(fieldProps.InputProps && { ...fieldProps.InputProps }),
        startAdornment: (
          <IconButton
            onClick={(e) => {
              const el = (e.target as HTMLDivElement).closest('button');
              const input = el?.nextElementSibling as HTMLDivElement;
              if (input) input?.focus();
            }}
            disabled={fieldProps?.disabled || false}
          >
            <EventIcon />
          </IconButton>
        ),
      }),
      [valueDates, focused, open],
    );
    const handleClose = () => {
      setAnchorEl(null);
      setHoveredDay(null);
    };
    return (
      <Box ref={ref}>
        <SDatePicker ref={pickerRef} {...{ width }}>
          <SingleInputDateRangeField
            onMouseEnter={() => {
              disabledBlur.current = true;
            }}
            onMouseLeave={() => {
              disabledBlur.current = false;
            }}
            size='small'
            fullWidth
            format={calendarDateFormat}
            value={valueDates as DateRange<Date>}
            timezone='UTC'
            name={name}
            onBlur={() => {
              if (isChanged.current && !disabledBlur.current) {
                weekStream$.next(valueDates);
                onBlur?.(valueDates);
                isChanged.current = false;
              }
              if (!open && !disabledBlur.current) setFocused(false);
            }}
            onClick={(e) => {
              navigationLockFn(() => setFocused(true));
              handleClick(e);
            }}
            readOnly={true}
            {...{ focused }}
            {...fieldProps}
          />
        </SDatePicker>
        <Popover
          anchorEl={anchorEl}
          open={open}
          keepMounted
          onClose={handleClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{
            marginTop: 1,
          }}
        >
          <DateCalendar
            value={valueDates[0]}
            onChange={(date, selectionState) => {
              if (date) {
                const changedWeek = !isInSameWeek(date, valueDates[0]);
                const firstWeekDay = getFirstDayOfWeek(date);
                const lastWeekDay = calculateFutureDate(firstWeekDay, 6);
                setValueDates([firstWeekDay, lastWeekDay]);
                if (selectionState === 'finish' && date && changedWeek) {
                  weekStream$.next([firstWeekDay, lastWeekDay]);
                  isChanged.current = true;
                  handleClose();
                }
              }
            }}
            slots={{
              day: (params) => (
                <SCustomPickersDay
                  {...params}
                  dayNumber={getAdjustedDay(params.day)}
                  isSelected={isInSameWeek(params.day, valueDates[0])}
                  isHovered={isInSameWeek(params.day, hoveredDay)}
                  disableMargin
                  disableHighlightToday
                  selected={false}
                />
              ),
            }}
            slotProps={{
              day: (ownerState) =>
                ({
                  onPointerEnter: () => setHoveredDay(ownerState.day),
                  onPointerLeave: () => setHoveredDay(null),
                } as any),
            }}
            showDaysOutsideCurrentMonth
            {...calendarProps}
          />
        </Popover>
      </Box>
    );
  },
);

const SCustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'isSelected' && prop !== 'isHovered' && prop !== 'dayNumber',
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, dayNumber }) => ({
  borderRadius: 0,
  paddingLeft: 20,
  paddingRight: 20,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: 'none',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
  }),
  ...(dayNumber === 0 && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(dayNumber === 6 && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
})) as React.ComponentType<CustomPickerDayProps>;

interface CustomPickerDayProps extends PickersDayProps<Date> {
  isSelected: boolean;
  isHovered: boolean;
  dayNumber: number;
}

export interface IWeekPicker {
  width?: number | string;
  onChange?: (v: (Date | null)[]) => void;
  onBlur?: (v: (Date | null)[]) => void;
  fieldProps?: Omit<SingleInputDateRangeFieldProps<Date>, 'defaultValue' | 'onChange' | 'ref'>;
  calendarProps?: Omit<DateCalendarProps<Date>, 'defaultValue' | 'onChange'>;
  defaultValue?: Date;
  value?: Date;
  navigationLockFn?: (handlers?: () => void) => void;
  name?: ControllerProps['name'];
}

const SDatePicker = styled(Box)<Pick<IDateRangePicker, 'width'>>(({ width }) => ({
  width: width || '100%',
  display: 'flex',
  '.MuiInputBase-root': {
    paddingLeft: 6,
    paddingRight: 0,
    '& > input': {
      textAlign: 'center',
      width: '76%',
      cursor: 'pointer',
    },
  },
}));

const isInSameWeek = (dayA: Date, dayB: Date | null) => {
  if (dayB == null) return false;
  return isSameWeek(dayA, dayB, { weekStartsOn: 1 });
};

const getAdjustedDay = (date: Date) => {
  let day = date.getDay() - 1;
  if (day === -1) day = 6;
  return day;
};

export const getFirstDayOfWeek = (date?: Date): Date => {
  const today = date ? new Date(date) : new Date();
  const dayOfWeek = today.getDay();
  const difference = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
  return new Date(today.setDate(difference));
};
