import { FC, useMemo } from 'react';
import { Box, Stack, styled, Typography } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-premium';
import { Table } from '../../table/table.component.tsx';
import { EmailTrackingByTypeRes } from '../services/emailTracking.service.ts';
import { Column } from '../../table/components/common.components.tsx';
import { localeFormatterHelper } from '../../../helpers/formatter/localeFormatter.helper.ts';
import { useTranslation } from 'react-i18next';
import { viewRows } from './views/subTable/viewRows.ts';
import { amber } from '@mui/material/colors';

export const RowContent: FC<IRowContent> = ({ row }) => {
  const { t } = useTranslation();
  const data = useMemo(() => {
    const events = row.events as EmailTrackingByTypeRes[number]['events'];
    return events
      .map((el, index) => ({ ...el, id: index }))
      .sort((a, b) => new Date(a.timestamp || 0).getTime() - new Date(b.timestamp || 0).getTime());
  }, [row.events]);
  return (
    <SBox isData={!!data.length} sx={{ borderBottom: 1, borderColor: 'divider' }}>
      {data.length ? (
        <Table height={data.length ? data.length * 32 + 38 : 200} {...{ data, viewRows }}>
          <Column
            field='timestamp'
            headerName={t('common.date_time')}
            width={200}
            valueGetter={({ value }) =>
              value
                ? localeFormatterHelper.formatDate(value, {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  })
                : ''
            }
            sortable={false}
          />
          <Column
            field='type'
            valueGetter={({ value }) => t(`enums.${value}`)}
            headerName={t('common.operation')}
            width={230}
            sortable={false}
          />
          <Column
            field='diagnosticInfo'
            headerName={t('common.diagnostic_information')}
            valueGetter={({ value }) => value || '-'}
            flex={1}
            sortable={false}
          />
        </Table>
      ) : (
        <Stack height={50} alignItems='center' justifyContent='center'>
          <Typography variant='caption' fontSize={16}>
            {t('common.delivery_information_is_not_yet_available')}
          </Typography>
        </Stack>
      )}
    </SBox>
  );
};

const SBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isData',
})<{ isData: boolean }>(({ theme, isData }) => ({
  padding: isData ? '8px 16px 16px' : '2px 16px 5px',
  backgroundColor: isData ? `${theme.palette.blue.light}80` : amber['50'],
  '& .MuiDataGrid-root': {
    backgroundColor: theme.palette.common.white,
  },
}));

interface IRowContent extends Partial<GridRowParams> {}
