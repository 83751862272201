import { Button, ListItemIcon, ListItemText, MenuItem, MenuList, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PopupService } from '../../../../../../../../../shared/components/popup/services/popup.service';
import { IDeliveryPeriods } from '../../deliveryPeriods.popup';
import { ITabs } from '../../content.popup';
import { PeriodConditions } from '../../../../../../../services/article.service';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { InitWeekDays } from '../../common.popup';

export const LeftSide: FC<ILeftSide> = ({ stream, changeTab, currentTab, generalData }) => {
  const { t } = useTranslation();

  const {
    weekDays = InitWeekDays,
    periods,
    periodsWithCustomWeekDays,
    vacations,
  } = generalData || {};

  const tabsChangeStatus: ITabsChangeStatus = {
    custom_week_days: checkWeekDaysChangeStatus(weekDays),
    period: !!(periods?.length || periodsWithCustomWeekDays?.length),
    break_off: !!vacations?.length,
  };

  const tabsList: ITabs[] = [ITabs.weekDays, ITabs.period, ITabs.breakOff];

  const tabs = tabsList.map((tabCode) => {
    const isActive = tabCode === currentTab;
    const title = t(`article.${tabCode}`);
    const onClick = () => {
      changeTab(tabCode);
    };
    const isEdited = tabsChangeStatus[tabCode];
    const editedIcon = isEdited ? (
      <ListItemIcon sx={{ marginLeft: 'auto', minWidth: '0 !important' }}>
        <CheckCircleIcon color='primary' />
      </ListItemIcon>
    ) : null;
    return (
      <MenuItem
        selected={isActive}
        onClick={onClick}
        key={tabCode}
        sx={{ display: 'flex', justifyContent: 'space-between', padding: '12px 16px' }}
      >
        <ListItemIcon>
          <DateRangeIcon />
        </ListItemIcon>
        <ListItemText>{title}</ListItemText>
        {editedIcon}
      </MenuItem>
    );
  });

  return (
    <Stack
      sx={{
        minWidth: 300,
        width: 300,
        flexDirection: 'column',
      }}
    >
      <Stack>
        <MenuList sx={{ padding: 0 }}>{tabs}</MenuList>
      </Stack>

      <Stack
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexGrow: 1,
          justifyContent: 'flex-end',
          pb: 3,
        }}
      >
        <Button
          onClick={() => {
            stream.emit('save', { values: { periodConditions: generalData as PeriodConditions } });
            stream.emit('close');
          }}
          variant='contained'
          sx={{ minWidth: '252px' }}
        >
          {t('common.save')}
        </Button>
      </Stack>
    </Stack>
  );
};

const checkWeekDaysChangeStatus = (wd: IWeekDays) => {
  const values = Object.values(wd);
  const allTrue = values.every((isDayChecked) => isDayChecked === true);
  const isChanged = !allTrue;
  return isChanged;
};

export type IWeekDays = PeriodConditions['weekDays'];

export type ITabsChangeStatus = Record<ITabs, boolean>;

interface ILeftSide {
  stream: PopupService<IDeliveryPeriods>;
  changeTab: (tab: ITabs) => void;
  currentTab: ITabs;
  generalData: IDeliveryPeriods['values']['periodConditions'] | undefined;
}
