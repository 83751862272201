import { useEffect, useState } from 'react';
import { authService } from '../services/auth/auth.service';

export const useVisibilityGUID = (guids: string[]) => {
  const [state, setState] = useState<boolean>(false);
  useEffect(() => {
    const subCustomerGuid = authService.sub.customerGUID().subscribe((guid) => {
      const specialCustomers: string[] = guids.map((item) => item.toLowerCase());
      setState(specialCustomers.includes(guid.toLowerCase(), 0));
    });
    return () => {
      subCustomerGuid.unsubscribe();
    };
  }, []);
  return state;
};
