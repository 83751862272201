import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useLoaderData } from 'react-router-dom';
import { Stack } from '@mui/material';
import { ConditionsForm } from './components/conditionsForm.component';
import { Content } from '../../../../../shared/components/content/content.component';
import { ITabState } from '../../../states/tabState.model.ts';
import { CustomerConditionsRes } from '../../../services/customer.service.ts';
import { conditionsTabState } from './conditions.state.ts';
import { yupResolver } from '@hookform/resolvers/yup';
import { createValidationSchema } from './validation/conditions.schema.ts';

const validationSchema = createValidationSchema();
export const ConditionsTab: FC = () => {
  const { defaultValues: loaderDefaultValues } = useLoaderData() as ITabState;
  const [dirtyFieldsState, setDirtyFieldsState] = useState<Record<string, any>>({});
  const [bank1MandatLoading, setBank1MandatLoading] = useState<boolean>(false);
  const [bank2MandatLoading, setBank2MandatLoading] = useState<boolean>(false);
  const formMethods = useForm<CustomerConditionsRes>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    context: { dirtyFieldsState, setBank1MandatLoading, setBank2MandatLoading },
    defaultValues: loaderDefaultValues,
  });

  useEffect(() => {
    setDirtyFieldsState(formMethods.formState.dirtyFields);
  }, [formMethods.formState.dirtyFields]);

  const { t } = useTranslation();

  const { watch, reset, resetField } = formMethods;

  useEffect(() => {
    const formStateSub = watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        conditionsTabState.pub.recordData({
          [fieldName]: data[fieldName],
          id: data.id,
        });
      }
    });
    const unsubState = conditionsTabState.sub
      .state()
      .subscribe(({ action, defaultValues, dataToSave }) => {
        if (action === 'list.selectedRecord') {
          reset(defaultValues);
        } else if (action === 'save') {
          const name = Object.keys(dataToSave)[0] as keyof typeof dataToSave;
          resetField(name, {
            defaultValue: dataToSave[name],
            keepDirty: false,
          });
        }
      });
    return () => {
      formStateSub.unsubscribe();
      unsubState.unsubscribe();
    };
  }, []);

  return (
    <>
      {Object.keys(loaderDefaultValues).length ? (
        <Stack marginTop='10px'>
          <ConditionsForm
            formMethods={formMethods}
            bank1MandatLoading={bank1MandatLoading}
            bank2MandatLoading={bank2MandatLoading}
          />
        </Stack>
      ) : (
        <Content
          type='paper'
          paperProps={{
            variant: 'elevation',
            sx: {
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              height: '100%',
            },
          }}
          emptyData={{
            value: true,
            title: t('common.no_data'),
          }}
        />
      )}
    </>
  );
};
