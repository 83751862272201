import { Pub, Service, Sub } from '../../../services/service.abstract.ts';
import {
  EmailTrackingByTypeQuery,
  EmailTrackingByTypeQueryVariables,
} from '../../../../graphql/generatedModel.ts';
import { map, Observable, switchMap } from 'rxjs';
import { gqlClient } from '../../../../graphql/graphqlRequest.ts';
import { emailTrackingByType } from './gql/emailTracking.gql.ts';

type Action = 'emailTrackingByType' | undefined;

class PubImpl extends Pub<Action> {
  emailTrackingByType(params: EmailTrackingByTypeQueryVariables) {
    this.emit('emailTrackingByType', params);
  }
}

class SubImpl extends Sub<Action> {
  emailTrackingByType(): Observable<EmailTrackingByTypeRes> {
    return this.actionListener('emailTrackingByType').pipe(
      switchMap(({ params }) => gqlClient(emailTrackingByType, params)),
      map((data: EmailTrackingByTypeQuery) => {
        return data.wawiAssist?.getWA_EmailTrackingByType as EmailTrackingByTypeRes;
      }),
    );
  }
}

class EmailService extends Service<Action> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const emailService = new EmailService();

export type EmailTrackingByTypeRes = NonNullable<
  NonNullable<EmailTrackingByTypeQuery['wawiAssist']>['getWA_EmailTrackingByType']
>;
