import React, { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TCompanyConfigs } from '../../../../../shared/services/companyConfig/companyConfig.service.ts';
import { Button, Stack } from '@mui/material';
import { FieldsSection, FormCheckbox } from '../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import { configurationTabState } from '../../states/configuration.state.ts';
import { DictionaryPopup } from '../../../../../shared/components/dictionary/dictionary.popup.tsx';
import { TextEditCellDict } from '../../../../../shared/components/dictionary/cells/textEditDict.cell.tsx';
import { C_Tax_System } from '../../../../../graphql/generatedModel.ts';
import { ISelectOption } from '../../../../../shared/components/form/fields/select.component.tsx';
import { SelectEditCellDict } from '../../../../../shared/components/dictionary/cells/selectEditDict.cell.tsx';

export const ArticleTab: FC<IArticleTab> = ({ params: defaultValues }) => {
  const { t } = useTranslation();
  const formMethods = useForm({ defaultValues, mode: 'onChange' });
  const {
    resetField,
    formState: { dirtyFields },
  } = formMethods;
  const dictionaryPopup = useMemo(() => new DictionaryPopup(), []);
  useEffect(() => {
    const formStateSub = formMethods.watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        configurationTabState.pub.recordData({ [fieldName]: data[fieldName] });
      }
    });
    const configurationTabStateSub = configurationTabState.sub
      .state()
      .subscribe(({ dataToSave }) => {
        const name = Object.keys(dataToSave)[0] as keyof typeof dataToSave;
        resetField(name, {
          defaultValue: dataToSave[name],
          keepDirty: false,
        });
      });

    return () => {
      formStateSub.unsubscribe();
      configurationTabStateSub.unsubscribe();
      dictionaryPopup.stream.unsubscribe();
    };
  }, []);

  const vatOptions: ISelectOption[] = useMemo(
    () => [
      {
        id: C_Tax_System.TAX1_INCLUDED_VAT,
        label: t(`enums.${C_Tax_System.TAX1_INCLUDED_VAT}`),
      },
      {
        id: C_Tax_System.TAX2_EXCLUDED_VAT,
        label: t(`enums.${C_Tax_System.TAX2_EXCLUDED_VAT}`),
      },
      { id: C_Tax_System.TAX3_NO_VAT, label: t(`enums.${C_Tax_System.TAX3_NO_VAT}`) },
    ],
    [t, C_Tax_System],
  );

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    if (!!dirtyFields[e.target.name as keyof typeof dirtyFields]) {
      configurationTabState.pub.saveData();
    }
  };

  const productGroupsHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.product_groups',
        dictionaryType: 'dictDiscountGroups',
        limit: 20,
        validateFields: ['name'],
        columns: [
          {
            field: 'name',
            headerName: t('common.description'),
            renderEditCell: (params) => <TextEditCellDict {...params} />,
            editable: true,
          },
        ],
      },
    });
  };

  const priceCategoriesHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.price_categories',
        width: 500,
        limit: 10,
        dictionaryType: 'dictPriceCategories',
        validateFields: ['name'],
        emptyRow: { name: null, vat: C_Tax_System.TAX1_INCLUDED_VAT },
        columns: [
          {
            field: 'name',
            headerName: t('common.category'),
            renderEditCell: (params) => <TextEditCellDict {...params} />,
            editable: true,
          },
          {
            field: 'vat',
            headerName: t('common.vat'),
            editable: true,
            valueFormatter: ({ value }) => t(`enums.${C_Tax_System[value as C_Tax_System]}`),
            renderEditCell: (params) => (
              <SelectEditCellDict {...params} {...{ options: vatOptions }} />
            ),
          },
        ],
      },
    });
  };

  const articleListsHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.article_list',
        dictionaryType: 'dictArticleLists',
        validateFields: ['name'],
        columns: [
          {
            field: 'name',
            headerName: t('common.description'),
            renderEditCell: (params) => <TextEditCellDict {...params} />,
            editable: true,
          },
        ],
      },
    });
  };
  const marketingGroupsHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.marketing_groups',
        dictionaryType: 'dictMarketingGroups',
        validateFields: ['name'],
        columns: [
          {
            field: 'name',
            headerName: t('common.description'),
            renderEditCell: (params) => <TextEditCellDict {...params} />,
            editable: true,
          },
        ],
      },
    });
  };
  const shippingPointsHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.shipping_points',
        dictionaryType: 'dictShippingPoints',
        validateFields: ['name'],
        columns: [
          {
            field: 'name',
            headerName: t('common.description'),
            renderEditCell: (params) => <TextEditCellDict {...params} />,
            editable: true,
          },
        ],
      },
    });
  };
  const productionGroupsHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.production_groups',
        dictionaryType: 'dictProductionGroups',
        validateFields: ['name'],
        columns: [
          {
            field: 'name',
            headerName: t('common.description'),
            renderEditCell: (params) => <TextEditCellDict {...params} />,
            editable: true,
          },
        ],
      },
    });
  };
  const assortmentGroupsHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.assortment_groups',
        dictionaryType: 'dictAssortmentGroups',
        validateFields: ['name'],
        columns: [
          {
            field: 'name',
            headerName: t('common.name'),
            renderEditCell: (params) => <TextEditCellDict {...params} />,
            editable: true,
          },
        ],
      },
    });
  };

  const storageLocationsHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.storage_locations',
        dictionaryType: 'dictStorageLocations',
        validateFields: ['name'],
        columns: [
          {
            field: 'name',
            headerName: t('common.description'),
            renderEditCell: (params) => <TextEditCellDict {...params} />,
            editable: true,
          },
        ],
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form noValidate onBlur={onSubmit}>
        <Stack direction='column'>
          <FormCheckbox
            name='isAllowSameEANForMultipleArticles'
            label={t('settings.the_same_ean_code_may_be_assigned_to_several_articles')}
          />
          <FormCheckbox
            name='isShowShippingPointsInArticle'
            label={t('settings.show_shipping_points_in_articles')}
          />
        </Stack>
      </form>
      <FieldsSection title={t('settings.related_directories')} nomt>
        <Stack alignItems='start' spacing={1} width='fit-content'>
          <Button
            onClick={articleListsHandler}
            size='medium'
            variant='outlined'
            color='secondary'
            fullWidth
          >
            {t('settings.article_list_settings')}
          </Button>
          <Button
            onClick={productGroupsHandler}
            fullWidth
            size='medium'
            variant='outlined'
            color='secondary'
          >
            {t('settings.product_groups_settings')}
          </Button>
          <Button
            onClick={productionGroupsHandler}
            size='medium'
            variant='outlined'
            color='secondary'
            fullWidth
          >
            {t('settings.production_groups_settings')}
          </Button>
          <Button
            onClick={marketingGroupsHandler}
            size='medium'
            variant='outlined'
            color='secondary'
            fullWidth
          >
            {t('settings.marketing_groups_settings')}
          </Button>
          <Button
            onClick={priceCategoriesHandler}
            size='medium'
            variant='outlined'
            color='secondary'
            fullWidth
          >
            {t('settings.price_categories_settings')}
          </Button>
          <Button
            onClick={assortmentGroupsHandler}
            size='medium'
            variant='outlined'
            color='secondary'
            fullWidth
          >
            {t('settings.assortment_groups_settings')}
          </Button>
          <Button
            onClick={shippingPointsHandler}
            size='medium'
            variant='outlined'
            color='secondary'
            fullWidth
          >
            {t('settings.shipping_points_settings')}
          </Button>
          <Button
            onClick={storageLocationsHandler}
            size='medium'
            variant='outlined'
            color='secondary'
            fullWidth
          >
            {t('settings.storage_locations_settings')}
          </Button>
        </Stack>
      </FieldsSection>
      <dictionaryPopup.Component />
    </FormProvider>
  );
};

interface IArticleTab {
  params: TCompanyConfigs;
}
