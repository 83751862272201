import { take } from 'rxjs';
import { PerformGlobalSearchRes, globalSearchService } from '../services/globalSearch.service';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';

export async function resolveGlobalSearchData(
  searchString: string,
): Promise<PerformGlobalSearchRes> {
  const globalSearchRes = await globalSearchService.globHelpers.streamToPromise(
    globalSearchService.sub.performGlobalSearch().pipe(
      responseHandler<PerformGlobalSearchRes>({
        errorReturnType: {
          articles: [],
          customers: [],
          invoices: [],
          orders: [],
        },
      }),
      take(1),
    ),
    () => {
      globalSearchService.pub.performGlobalSearch({ searchString });
    },
  );
  return globalSearchRes;
}
