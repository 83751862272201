import { ElementType, FC, ReactNode, useMemo } from 'react';
import Grid, { GridProps } from '@mui/material/Grid/Grid';
import Box, { BoxProps } from '@mui/material/Box/Box';
import Container, { ContainerProps } from '@mui/material/Container/Container';
import Stack, { StackProps } from '@mui/material/Stack/Stack';
import Paper, { PaperProps } from '@mui/material/Paper/Paper';
import styled from '@mui/material/styles/styled';
import { CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
/* colors */
import { grey } from '@mui/material/colors';
/* Icons */
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';
export const Content: FC<TWrapper> = ({
  type,
  children,
  stackProps,
  paperProps,
  gridProps,
  containerProps,
  boxProps,
  loading,
  emptyData,
  lsx = {},
}) => {
  const { t } = useTranslation();

  const props = { ...stackProps, ...gridProps, ...paperProps, ...boxProps, ...containerProps };
  const Component: ElementType = useMemo(() => {
    switch (type) {
      case 'stack':
        return Stack;
      case 'box':
        return Box;
      case 'grid':
        return Grid;
      case 'paper':
        return Paper;
      default:
        return Container;
    }
  }, [type]);

  return (
    <Component {...props} sx={{ ...props.sx, position: 'relative' }}>
      {emptyData?.value ? (
        <SEmptyData>
          <Stack alignItems='center'>
            <SEmptyIcon color='primary' />
            <Typography variant='h5'>{t('common.empty')}</Typography>
            <Typography variant='subtitle2' color={grey['600']}>
              {emptyData.title}
            </Typography>
          </Stack>
        </SEmptyData>
      ) : (
        children
      )}
      {loading && (
        <SLoader className='loader' lsx={lsx as Record<string, SxProps>}>
          <CircularProgress />
        </SLoader>
      )}
    </Component>
  );
};

const commonStyle: Record<string, string | number> = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  transition: 'opacity 0.5s',
  alignItems: 'center',
  justifyContent: 'center',
};

export const SLoader = styled(Box)<{ lsx: Record<string, SxProps> }>(({ lsx }) => ({
  ...commonStyle,
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  zIndex: 1000,
  ...lsx,
}));

const SEmptyData = styled(Box)(() => ({
  ...commonStyle,
}));

const SEmptyIcon = styled(AssignmentIndIcon)(() => ({
  height: 60,
  width: 60,
  marginBottom: 8,
}));

type TWrapper = TGrid | TStack | TBox | TContainer | TPaper;

interface IWrapperCommon {
  children?: ReactNode;
  loading?: boolean;
  emptyData?: {
    value: boolean;
    title?: string;
  };
  lsx?: SxProps;
}
type TGrid = IWrapperCommon & {
  type: 'grid';
  gridProps?: GridProps;
  stackProps?: never;
  boxProps?: never;
  containerProps?: never;
  paperProps?: never;
};
type TStack = IWrapperCommon & {
  type: 'stack';
  stackProps?: StackProps;
  gridProps?: never;
  boxProps?: never;
  containerProps?: never;
  paperProps?: never;
};
type TBox = IWrapperCommon & {
  type: 'box';
  boxProps?: BoxProps;
  gridProps?: never;
  stackProps?: never;
  containerProps?: never;
  paperProps?: never;
};
type TContainer = IWrapperCommon & {
  type: 'container';
  containerProps?: ContainerProps;
  gridProps?: never;
  stackProps?: never;
  boxProps?: never;
  paperProps?: never;
};
type TPaper = IWrapperCommon & {
  type: 'paper';
  paperProps?: PaperProps;
  gridProps?: never;
  stackProps?: never;
  boxProps?: never;
  containerProps?: never;
};
