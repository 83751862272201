import { FC } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { ITimePicker, TimePicker } from '../../datePicker/timePicker.component.tsx';

export const FormTimePicker: FC<IFormTimePicker> = ({ name, onChange, onBlur, ...props }) => {
  const disabled = props?.fieldProps?.disabled;
  return (
    <Controller
      name={name}
      render={({
        field: {
          value,
          onChange: onFieldChange,
          onBlur: onFieldBlur,
          disabled: fieldDisabled,
          ...restFields
        },
      }) => {
        return (
          <TimePicker
            {...props}
            value={value}
            onChange={(e) => {
              onFieldChange(e);
              onChange?.(e);
            }}
            onBlur={(e) => {
              onFieldBlur();
              onBlur?.(e);
            }}
            disabled={typeof disabled === 'boolean' ? disabled : fieldDisabled}
            {...restFields}
          />
        );
      }}
    />
  );
};

interface IFormTimePicker extends Omit<ITimePicker, 'value' | 'defaultValue'> {
  name: ControllerProps['name'];
}
