import { getReportOptionsByGroup } from '../../../../../../../../../shared/helpers/reportOptionsByGroup/getReportOptionsByGroup';
import { ISelectOption } from '../../../../../../../../../shared/components/form/fields/select.component';
import {
  C_Debitor_Status,
  C_Discount_Kind,
  C_Invoice_Interval,
  C_Report,
  C_Report_Group,
  C_Tax_System,
} from '../../../../../../../../../graphql/generatedModel';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export const useOptionsForSecectors = () => {
  const { t } = useTranslation();

  const orderReportOptions = useMemo(() => {
    const reports = getReportOptionsByGroup(C_Report_Group.RG5_ORDER);
    reports.push({ id: C_Report.R_NOT_TO_PRINT, label: t(`enums.${C_Report.R_NOT_TO_PRINT}`) });
    return reports;
  }, [t]);

  const invoiceReportOptions = useMemo(() => {
    return [
      {
        id: C_Report.R9_INVOICE_A4,
        label: t(`enums.${C_Report.R9_INVOICE_A4}`),
      },
      {
        id: C_Report.R19_INVOICE_A4_DETAILED,
        label: t(`enums.${C_Report.R19_INVOICE_A4_DETAILED}`),
      },
      {
        id: C_Report.R31_INVOICE_A4_ARTICLE_GROUPING,
        label: t(`enums.${C_Report.R31_INVOICE_A4_ARTICLE_GROUPING}`),
      },
      {
        id: C_Report.R39_INVOICE_A4_CUSTOMER_GROUPING,
        label: t(`enums.${C_Report.R39_INVOICE_A4_CUSTOMER_GROUPING}`),
      },
      { id: C_Report.R_NOT_TO_PRINT, label: t(`enums.${C_Report.R_NOT_TO_PRINT}`) },
    ];
  }, [t]);

  const invoiceSummaryReportOptions = useMemo(() => {
    return [
      {
        id: C_Report.R17_INVOICE_SUMMARY,
        label: t(`enums.${C_Report.R17_INVOICE_SUMMARY}`),
      },
      {
        id: C_Report.R52_DELIVERY_OVERVIEW,
        label: t(`enums.${C_Report.R52_DELIVERY_OVERVIEW}`),
      },
      { id: C_Report.R_NOT_TO_PRINT, label: t(`enums.${C_Report.R_NOT_TO_PRINT}`) },
    ];
  }, [t]);

  const invoicePaymentSlipReportOptions = useMemo(() => {
    return [
      {
        id: C_Report.R18_INVOICE_PAYMENTSLIP,
        label: t(`enums.${C_Report.R18_INVOICE_PAYMENTSLIP}`),
      },
      {
        id: C_Report.R59_INVOICE_PAYMENTSLIP_WITH_QR,
        label: t(`enums.${C_Report.R59_INVOICE_PAYMENTSLIP_WITH_QR}`),
      },
      { id: C_Report.R_NOT_TO_PRINT, label: t(`enums.${C_Report.R_NOT_TO_PRINT}`) },
    ];
  }, [t]);

  const invoiceIntervalOptions = useMemo(
    () => [
      {
        id: C_Invoice_Interval.II1_MONTHLY,
        label: t(`enums.${C_Invoice_Interval.II1_MONTHLY}`),
      },
      {
        id: C_Invoice_Interval.II2_MONTHLY2,
        label: t(`enums.${C_Invoice_Interval.II2_MONTHLY2}`),
      },
      {
        id: C_Invoice_Interval.II3_MONTHLY3,
        label: t(`enums.${C_Invoice_Interval.II3_MONTHLY3}`),
      },
      {
        id: C_Invoice_Interval.II4_MONTHLY4,
        label: t(`enums.${C_Invoice_Interval.II4_MONTHLY4}`),
      },
      {
        id: C_Invoice_Interval.II5_HALF_MONTHLY,
        label: t(`enums.${C_Invoice_Interval.II5_HALF_MONTHLY}`),
      },
      {
        id: C_Invoice_Interval.II6_HALF_WEEKLY,
        label: t(`enums.${C_Invoice_Interval.II6_HALF_WEEKLY}`),
      },
      {
        id: C_Invoice_Interval.II7_MANUAL,
        label: t(`enums.${C_Invoice_Interval.II7_MANUAL}`),
      },
      {
        id: C_Invoice_Interval.II8_DIRECT_CASH,
        label: t(`enums.${C_Invoice_Interval.II8_DIRECT_CASH}`),
      },
      {
        id: C_Invoice_Interval.II9_DIRECT_DEBITOR,
        label: t(`enums.${C_Invoice_Interval.II9_DIRECT_DEBITOR}`),
      },
      {
        id: C_Invoice_Interval.II10_NO_INVOICE_INTERNAL,
        label: t(`enums.${C_Invoice_Interval.II10_NO_INVOICE_INTERNAL}`),
      },
    ],
    [t],
  );

  const debitorStatusOptions = useMemo(
    () => [
      {
        id: C_Debitor_Status.DS1_DEBITOR,
        label: t(`enums.${C_Debitor_Status.DS1_DEBITOR}`),
      },
      {
        id: C_Debitor_Status.DS2_INVOICE_DOES_NOT_INITIATE_DEBITOR,
        label: t(`enums.${C_Debitor_Status.DS2_INVOICE_DOES_NOT_INITIATE_DEBITOR}`),
      },
      {
        id: C_Debitor_Status.DS3_INTERNAL_INVOICE,
        label: t(`enums.${C_Debitor_Status.DS3_INTERNAL_INVOICE}`),
      },
    ],
    [t],
  );

  const discountKindIdOptions = useMemo(
    () => [
      {
        id: C_Discount_Kind.DK1_NO_DISCOUNT,
        label: t(`enums.${C_Discount_Kind.DK1_NO_DISCOUNT}`),
      },
      {
        id: C_Discount_Kind.DK2_ALL_ARTICLES,
        label: t(`enums.${C_Discount_Kind.DK2_ALL_ARTICLES}`),
      },
      {
        id: C_Discount_Kind.DK3_ARTICLE_GROUPS,
        label: t(`enums.${C_Discount_Kind.DK3_ARTICLE_GROUPS}`),
      },
      {
        id: C_Discount_Kind.DK4_PRICE_REDUCE,
        label: t(`enums.${C_Discount_Kind.DK4_PRICE_REDUCE}`),
      },
      {
        id: C_Discount_Kind.DK5_QUANTITY_DISCOUNT,
        label: t(`enums.${C_Discount_Kind.DK5_QUANTITY_DISCOUNT}`),
      },
      {
        id: C_Discount_Kind.DK6_QUANTITY_DISCOUNT_GROUPS,
        label: t(`enums.${C_Discount_Kind.DK6_QUANTITY_DISCOUNT_GROUPS}`),
      },
    ],
    [t],
  );

  const taxSystemOptions = useMemo(
    () => [
      {
        id: C_Tax_System.TAX1_INCLUDED_VAT,
        label: t(`enums.${C_Tax_System.TAX1_INCLUDED_VAT}`),
      },
      {
        id: C_Tax_System.TAX2_EXCLUDED_VAT,
        label: t(`enums.${C_Tax_System.TAX2_EXCLUDED_VAT}`),
      },
      {
        id: C_Tax_System.TAX3_NO_VAT,
        label: t(`enums.${C_Tax_System.TAX3_NO_VAT}`),
      },
    ],
    [t],
  );

  return {
    orderReportOptions,
    invoiceReportOptions,
    invoiceSummaryReportOptions,
    invoicePaymentSlipReportOptions,
    invoiceIntervalOptions,
    debitorStatusOptions,
    discountKindIdOptions,
    taxSystemOptions,
  } as IOptionsFromEnums;
};

export interface IOptionsFromEnums {
  orderReportOptions: ISelectOption[];
  invoiceReportOptions: ISelectOption[];
  invoiceSummaryReportOptions: ISelectOption[];
  invoicePaymentSlipReportOptions: ISelectOption[];
  invoiceIntervalOptions: ISelectOption[];
  debitorStatusOptions: ISelectOption[];
  discountKindIdOptions: ISelectOption[];
  taxSystemOptions: ISelectOption[];
}
