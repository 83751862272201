import { GridSortModel } from '@mui/x-data-grid-premium';
import { CustomerDataRes } from '../../services/createInvoice.service';
import {
  InvoiceDataRes,
  invoiceListService,
  OrdersRes,
  PositionsRes,
  QueryDataRes,
  QueryProps,
} from '../../services/invoiceList.service';
import { Pub, State, Sub } from '../../../../shared/state/state.abstract';
import { BehaviorSubject, filter, map, merge, Observable, of, share, switchMap, tap } from 'rxjs';
import { invoiceListState } from './invoiceList.state';
import { storageHelper } from '../../../../shared/helpers/storage';
import { dataHelper } from '../../../../shared/helpers/data/data.helper';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler';
import { initQueryData } from '../../loaders/invoiceList.loader';

export const initInvoiceDetailsState: IInvoiceDetailsState = {
  action: undefined,
  customerInfo: undefined,
  invoiceInfo: undefined,
  orders: [],
  orderPositions: [],
  isMultipayCustomer: false,
  orderSort: [{ field: 'orderDate', sort: 'asc' }],
  selectedOrder: undefined,
  isMultiselect: false,
  invoiceId: undefined,
  selectedIds: new Set(),
};

class PubImpl extends Pub<IInvoiceDetailsState> {
  init(params: IInvoiceDetailsState) {
    this.emit('init', params);
  }
  selectOrder(selectedOrder: IInvoiceDetailsState['selectedOrder']) {
    this.emit('selectOrder', { selectedOrder });
    storageHelper.session.setItem('invoiceList.selectedOrder', selectedOrder);
  }
  toggleMultiselect() {
    this.emit('toggleMultiselect', {});
  }
  setSelectedIds(selectedIds: IInvoiceDetailsState['selectedIds']) {
    this.emit('setSelectedIds', { selectedIds });
  }
  orderSort(sortModel: GridSortModel) {
    this.emit('orderSort', { orderSort: sortModel });
    storageHelper.local.setItem('invoiceList.orderSort', sortModel);
  }
  updateLastEmailDetails(
    datetimeOfLastEmail: QueryDataRes['invoiceInfo']['datetimeOfLastEmail'],
    dataRecordId: string,
  ) {
    const { invoiceInfo, invoiceId } = this.stream$.getValue();
    if (invoiceInfo && invoiceId === dataRecordId) {
      invoiceInfo.datetimeOfLastEmail = datetimeOfLastEmail;
      this.emit('updateInvoiceInfo', { invoiceInfo });
    }
  }
}

class SubImpl extends Sub<IInvoiceDetailsState> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());
  private positionsLoading$ = new BehaviorSubject<boolean>(false);
  private sharePositionsLoading$: Observable<boolean> = this.positionsLoading$.pipe(share());
  protected actionHandlers(): Observable<IInvoiceDetailsState> {
    return merge(
      this.queryData(),
      this.selectOrder(),
      this.toggleMultiselect(),
      this.updateState(),
    ).pipe(
      map((state) => {
        const { orders, orderSort } = state;
        const sortedOrders = dataHelper
          .data([...(orders || [])] as [])
          .sort({ sortModel: orderSort })
          .result() as IInvoiceDetailsState['orders'];
        const updatedState = {
          ...state,
          orders: sortedOrders,
        } as IInvoiceDetailsState;

        this.stream$.next({ ...updatedState, action: 'internalUpdate' });
        return updatedState;
      }),
      tap(() => this.loading$.next(false)),
    );
  }

  loading(): Observable<boolean> {
    return this.shareLoading$;
  }

  positionsLoading(): Observable<boolean> {
    return this.sharePositionsLoading$;
  }

  private selectOrder(): Observable<IInvoiceDetailsState> {
    return this.actionListener('selectOrder').pipe(
      tap(({ selectedOrder, invoiceInfo }) => {
        this.positionsLoading$.next(true);
        invoiceListService.pub.positions({
          orderId: selectedOrder?.orderId as string,
          isInvoiceDeleted: invoiceInfo?.isDeleted as boolean,
        });
      }),
      switchMap((state) => {
        return invoiceListService.sub.positions().pipe(
          responseHandler<PositionsRes>({
            customErrorHandler: () => 'invoice.error_failed_to_load_order_positions',
            errorReturnType: [],
          }),
          map((orderPositions) => {
            this.positionsLoading$.next(false);
            return {
              ...state,
              orderPositions,
            };
          }),
        );
      }),
    );
  }

  private toggleMultiselect(): Observable<IInvoiceDetailsState> {
    return this.actionListener('toggleMultiselect').pipe(
      map((state) => {
        const { isMultiselect } = state;
        return {
          ...state,
          isMultiselect: !isMultiselect,
        };
      }),
    );
  }

  private updateState(): Observable<IInvoiceDetailsState> {
    return this.actionListener(['orderSort', 'setSelectedIds', 'updateInvoiceInfo']);
  }

  private queryData(): Observable<IInvoiceDetailsState> {
    return invoiceListState.sub.state().pipe(
      filter(({ action }) => action === 'getCreatedInvoices' || action === 'selectInvoice'),
      tap(() => this.loading$.next(true)),
      switchMap(({ selectedInvoice }) => {
        const { invoiceId, customerId, isDeleted } = selectedInvoice || {};
        if (!invoiceId) {
          return of({
            ...initInvoiceDetailsState,
            action: 'resetData',
          } as IInvoiceDetailsState);
        }
        const state = this.stream$.getValue();
        const { orderSort } = state;
        invoiceListService.pub.queryData({
          customerId,
          invoiceId,
          isDeleted,
          orderSort,
        } as QueryProps);
        return invoiceListService.sub.queryData().pipe(
          responseHandler<QueryDataRes>({
            errorReturnType: initQueryData,
          }),
          map(
            (data: QueryDataRes) =>
              ({
                ...state,
                ...data,
                selectedIds: new Set(),
                invoiceId,
                action: 'queryData',
              } as IInvoiceDetailsState),
          ),
        );
      }),
    );
  }
}

class InvoiceDetailsState extends State<IInvoiceDetailsState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const invoiceDetailsState = new InvoiceDetailsState(initInvoiceDetailsState);

export interface IInvoiceDetailsState {
  action:
    | 'init'
    | 'loader'
    | 'internalUpdate'
    | 'queryData'
    | 'selectOrder'
    | 'setSelectedIds'
    | 'clear'
    | 'toggleMultiselect'
    | 'orderSort'
    | 'resetData'
    | 'updateInvoiceInfo'
    | undefined;
  customerInfo?: CustomerDataRes;
  isMultipayCustomer: boolean;
  invoiceInfo?: InvoiceDataRes;
  orders?: OrdersRes | OrdersForMultipay;
  selectedOrder?: OrdersRes[number];
  isMultiselect: boolean;
  selectedIds: Set<string>;
  invoiceId?: string;
  orderPositions?: PositionsRes;
  orderSort: GridSortModel;
}

type OrderType = OrdersRes[number];

type OrderForMultipay = OrderType & {
  customerNumberAndName: string;
};

type OrdersForMultipay = OrderForMultipay[];
