import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, IconButton, SxProps, Theme, styled } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { storageHelper } from '../../helpers/storage';
import { navigateService } from '../../services/navigate/navigate.service';
import { TextField } from '../form';
import { navBlocker } from '../navigation/blocker/nav.blocker';
import { searchState } from './states/search.state';
import { searchMoreThenLetter } from '../../../pages/globalSearch/loaders/globalSearch.loader';
import CloseIcon from '@mui/icons-material/Close';

export const GlobalSearch: FC<IGlobalSearch> = ({ sxMain }) => {
  const [t] = useTranslation();
  const initHistory = storageHelper.session.getItem('globalSearch.history');
  const [searchHistory, setSearchHistory] = useState<string[]>(initHistory || []);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    searchState.pub.init({ action: 'init', searchString: '', searchHistory: initHistory || [] });
    const searchStateSub = searchState.sub.state().subscribe((v) => {
      setSearchString(v?.searchString);
      setSearchHistory(v?.searchHistory);
    });
    return () => {
      searchStateSub.unsubscribe();
    };
  }, []);

  const onSearch = (searchString: string) => {
    navBlocker.controlledNavigate(() =>
      navigateService.pub.navigateTo(`/globalSearch/${searchString}`),
    );
  };

  const emmitSearchAction = () => {
    const formatedString = searchString?.trim();
    const isValue = formatedString && formatedString?.length > searchMoreThenLetter;
    if (isValue) {
      searchState.pub.updateHistory(formatedString);
      onSearch(formatedString);
    }
  };

  const handleSearchSelect = (value: string | null) => {
    if (value) {
      searchState.pub.updateHistory(value);
      onSearch(value);
    }
  };

  const handleKeyDown = (v: React.KeyboardEvent) => {
    const isEnterEvent = v.target instanceof HTMLInputElement && v.code === 'Enter';
    if (isEnterEvent) {
      emmitSearchAction();
    }
  };

  const handleSearchIconClick = () => {
    emmitSearchAction();
  };

  const handleCleanIconClick = () => {
    searchState.pub.resetSearch();
  };

  return (
    <SWrapper
      freeSolo
      value={searchString}
      onChange={(_, value) => handleSearchSelect(value as string | null)}
      options={searchHistory}
      sx={sxMain}
      renderInput={(params) => (
        <TextField
          {...params}
          width='300px'
          onChange={(e) => searchState.pub.updateSearch(e?.target?.value)}
          onKeyDown={handleKeyDown}
          placeholder={t('common.search')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {searchString && (
                  <IconButton onClick={handleCleanIconClick}>
                    <CloseIcon />
                  </IconButton>
                )}
                <IconButton onClick={handleSearchIconClick}>
                  <SearchIcon />
                </IconButton>
              </>
            ),
          }}
        />
      )}
    />
  );
};

const SWrapper = styled(Autocomplete)(() => ({
  '& .MuiInputBase-root': {
    padding: '8px 12px !important',
    height: '40px',
    width: '300px',
  },
}));

interface IGlobalSearch {
  sxMain?: SxProps<Theme>; // custom styles for the main container
}
