import { t } from 'i18next';
import { FC, useMemo } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { C_Ca_Visibility_Status } from '../../../../../../graphql/generatedModel';
import { FieldsRow, FormSelect } from '../../../../../../shared/components/form';
import {
  ArticleCashAssistTabDataRes,
  CashAssistTabOptionsRes,
} from '../../../../services/article.service';
import { cashAssistTabState } from '../cashassist.state';

export const CashAssistForm: FC<IPostForm> = ({ formMethods, cashAssistTabOptions }) => {
  const {
    formState: { errors, dirtyFields },
  } = formMethods;
  const visibilityOptions = useMemo(
    () => [
      {
        id: C_Ca_Visibility_Status.CAVS1_ALWAYS,
        label: t(`enums.${C_Ca_Visibility_Status.CAVS1_ALWAYS}`),
      },
      {
        id: C_Ca_Visibility_Status.CAVS2_ONLY_CR_MODE,
        label: t(`enums.${C_Ca_Visibility_Status.CAVS2_ONLY_CR_MODE}`),
      },
      {
        id: C_Ca_Visibility_Status.CAVS3_ONLY_ORDER_MODE,
        label: t(`enums.${C_Ca_Visibility_Status.CAVS3_ONLY_ORDER_MODE}`),
      },
      {
        id: C_Ca_Visibility_Status.CAVS4_DISABLED,
        label: t(`enums.${C_Ca_Visibility_Status.CAVS4_DISABLED}`),
      },
    ],
    [t],
  );

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    if (
      !errors[e.target.name as keyof ArticleCashAssistTabDataRes] &&
      !!dirtyFields[e.target.name as keyof ArticleCashAssistTabDataRes]
    ) {
      cashAssistTabState.pub.save();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form noValidate onBlur={onSubmit}>
        <FieldsRow>
          <FormSelect
            name='caTemplateId'
            label={t('article.template')}
            width='220px'
            options={(cashAssistTabOptions.cashAssistTemplates as []) || []}
          />
          <FormSelect
            name='caProductGroupId'
            label={t('article.sales_group')}
            width='220px'
            options={(cashAssistTabOptions?.productionGroups as []) || []}
          />
        </FieldsRow>
        <FieldsRow mt={4}>
          <FormSelect
            name='caVisibilityStatusId'
            label={t('article.visibility')}
            width='220px'
            disableClearable
            options={visibilityOptions as []}
          />
        </FieldsRow>
      </form>
    </FormProvider>
  );
};

interface IPostForm {
  formMethods: UseFormReturn<ArticleCashAssistTabDataRes>;
  cashAssistTabOptions: CashAssistTabOptionsRes;
}
