import { Popup, TPopupComponent } from '../../../../../shared/components/popup/popup.abstract.tsx';
import { TPopupDefaultActions } from '../../../../../shared/components/popup/services/popup.service.ts';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@mui/material/styles/styled';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Content } from './content.popup.tsx';

export class IndividualDescriptionPopup extends Popup<IIndividualDescriptionPopup> {
  Component: FC<TPopupComponent>;

  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(({ theme }) => ({
          '& .ProseMirror': {
            fontFamily: theme.typography.fontFamily,
          },
        }));
      }, []);
      const { t } = useTranslation();
      const [initRow, setInitRow] = useState();

      useEffect(() => {
        stream.actionListener('open').subscribe(({ row }) => setInitRow(row));
      }, []);

      return (
        <SWrapper
          stream={stream}
          popupTitle={t('order.individual_description')}
          PaperProps={{ sx: { maxWidth: '750px' } }}
          fullWidth
        >
          <Content {...{ stream, initRow }} />
        </SWrapper>
      );
    };
  }
}

export interface IIndividualDescriptionPopup {
  action: TPopupDefaultActions | 'save';
  params?: GridRenderCellParams;
  row?: GridRenderCellParams['row'];
}
