import { FC, useEffect, useMemo, useState } from 'react';
/* Icons */
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import {
  FormDatePicker,
  FormDateRangePicker,
  FormRadioGroup,
  FormSelect,
  Radio,
  SelectMenuWithFooter,
} from '../../../../shared/components/form';
import { MenuItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { openDebtsState } from '../states/openDebts.state.ts';
import { C_Report } from '../../../../graphql/generatedModel.ts';
import { DictDebtRemindersRes } from '../../../../shared/services/configsData/configsData.service.ts';
import { ISelectOption } from '../../../../shared/components/form/fields/select.component.tsx';
import { SplitIconButton } from '../../../../shared/components/splitIconButton/splitIconButton.tsx';
import styled from '@mui/material/styles/styled';
import { PreviewPopup } from '../../../../shared/components/previewPopup/preview.popup.tsx';
import { IOpenDebtsLoader } from '../../loaders/openDeps.loader.ts';
import { SendEmailPopup } from './openDebtsGrid/popups/sendEmail/sendEmail.popup.tsx';
import { MultiEmailPopup } from './openDebtsGrid/popups/sendMultipleEmails/sendMultipleEmails.popup.tsx';
import { useHandleReportAction } from './useHandleReportAction.ts';

export const SideControls: FC<ISideControls> = ({ initOpenDebtsGridData, reminderTextOptions }) => {
  const [selectedRow, setSelectedRow] = useState<IOpenDebtsLoader['selectedRow']>(null);
  const [openDebtsGridData, setOpenDebtsGridData] =
    useState<IOpenDebtsLoader['openDebtsGridData']>(initOpenDebtsGridData);
  const { t } = useTranslation();
  const notifierPreviewPopup = useMemo(() => new PreviewPopup(), []);
  const emailPopup = useMemo(() => new SendEmailPopup(), []);
  const multiEmailPopup = new MultiEmailPopup();
  const { handleActionReport } = useHandleReportAction({
    openDebtsGridData,
    selectedRow,
    notifierPreviewPopup,
    emailPopup,
    multiEmailPopup,
  });

  useEffect(() => {
    const subState = openDebtsState.sub.state().subscribe((v) => {
      setOpenDebtsGridData(v.openDebtsGridData);
      setSelectedRow(v.selectedRow);
    });

    return () => {
      subState.unsubscribe();
    };
  }, []);

  const printFormOptions = useMemo(
    () => [
      {
        id: C_Report.R10_CUSTOMERS_DEBT_NOTIFIER,
        label: t(`enums.${C_Report.R10_CUSTOMERS_DEBT_NOTIFIER}`),
      },
      {
        id: C_Report.R64_CUSTOMERS_DEBT_NOTIFIER_WITH_INVOICE_AND_PAYMENTSLIP,
        label: t(`enums.${C_Report.R64_CUSTOMERS_DEBT_NOTIFIER_WITH_INVOICE_AND_PAYMENTSLIP}`),
      },
    ],
    [t],
  );

  const radioValues = useMemo(
    () => [
      { value: SelectedInvoiceOptions.allCustomers, label: t('debitor.allCustomers') },
      {
        value: SelectedInvoiceOptions.allInvoicesOfSelectedCustomer,
        label: t('debitor.allInvoicesOfSelectedCustomer'),
      },
      {
        value: SelectedInvoiceOptions.onlySelectedInvoice,
        label: t('debitor.onlySelectedInvoice'),
      },
    ],
    [t],
  );

  return (
    <Stack direction='row' justifyContent='space-between' mb='20px'>
      <Stack direction='row' spacing={2}>
        <FormDateRangePicker
          fieldProps={{ label: t('debitor.invoicesDates') }}
          name='invoicesDates'
          onChange={(period) => {
            openDebtsState.pub.getOpenDebtsGridData({
              invoicesDates: {
                fromDate: period.fromDate!,
                toDate: period.toDate!,
              },
            });
          }}
          width={280}
        />
        <FormDatePicker
          fieldProps={{ label: t('debitor.considerPaymentsUpTo') }}
          navigation={false}
          name='paymentsUpToDate'
          mode='onChange'
          onChange={(date) => {
            openDebtsState.pub.getOpenDebtsGridData({ paymentsUpToDate: date! });
          }}
          width={280}
        />
      </Stack>
      <Stack direction='row' spacing={2} flexGrow={1} justifyContent='flex-end'>
        <FormSelect
          label={t('debitor.print_form')}
          width='220px'
          name='printFormId'
          options={printFormOptions}
          disableClearable
          disableTextInput
        />
        <FormSelect
          label={t('debitor.reminderText')}
          name='reminderTextId'
          width='220px'
          options={reminderTextOptions as ISelectOption[]}
          disableTextInput
          PaperComponent={(props) => (
            <SelectMenuWithFooter {...props} href='/settings/configuration/invoice' />
          )}
        />
        <SplitIconButton
          icon={RemoveRedEyeIcon}
          onClick={() => handleActionReport('preview')}
          title={t('common.preview')}
          disabled={!openDebtsGridData.length}
        >
          <FormRadioGroup name='previewId'>
            {radioValues.map((el) => (
              <SMenuItem key={el.value}>
                <Radio value={el.value} label={el.label} />
              </SMenuItem>
            ))}
          </FormRadioGroup>
        </SplitIconButton>
        <SplitIconButton
          icon={AttachEmailIcon}
          onClick={() => handleActionReport('email')}
          title={t('common.email')}
          disabled={!openDebtsGridData.length}
        >
          <FormRadioGroup name='sendingId'>
            {radioValues.map((el) => (
              <SMenuItem key={el.value}>
                <Radio value={el.value} label={el.label} />
              </SMenuItem>
            ))}
          </FormRadioGroup>
        </SplitIconButton>
      </Stack>
      <notifierPreviewPopup.Component />
      <emailPopup.Component />
      <multiEmailPopup.Component />
    </Stack>
  );
};

export enum SelectedInvoiceOptions {
  allCustomers = 'allCustomers',
  allInvoicesOfSelectedCustomer = 'allInvoicesOfSelectedCustomer',
  onlySelectedInvoice = 'onlySelectedInvoice',
}
interface ISideControls {
  initOpenDebtsGridData: IOpenDebtsLoader['openDebtsGridData'];
  reminderTextOptions: DictDebtRemindersRes;
}

const SMenuItem = styled(MenuItem)({
  '& label > span': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& label': {
    width: '100%',
    paddingTop: 2,
    paddingBottom: 2,
  },
});
