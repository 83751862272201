import { LoaderFunction, Params } from 'react-router-dom';
import { resolveGlobalSearchData } from './globalSearch.resolver';
import { PerformGlobalSearchRes } from '../services/globalSearch.service';

export const globalSearchLoader: LoaderFunction = async ({
  params,
}: {
  params: Params<keyof Pick<IGlobalSearchData, 'searchString'>>;
}) => {
  const searchString = params.searchString || '';
  const isValue = searchString && searchString?.length > searchMoreThenLetter;
  let searchData = undefined as IGlobalSearchData['searchData'];
  if (isValue) {
    searchData = await resolveGlobalSearchData(searchString);
  }
  return { searchString, searchData } as IGlobalSearchData;
};

export const searchMoreThenLetter = 2;

export const initGlobalSearchData: IGlobalSearchData = {
  searchString: '',
  searchData: undefined,
};

export interface IGlobalSearchData {
  searchString: string;
  searchData?: PerformGlobalSearchRes;
}
