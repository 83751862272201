import { map, take, zipWith } from 'rxjs';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';
import { CustomerGeneralDataRes, customerService } from '../services/customer.service';
import { ITabState } from '../states/tabState.model';
import {
  configsData,
  DictCustomerGroupsRes,
  DictCustomerProfilesRes,
  DictDeliveryNoteGroupsRes,
} from '../../../shared/services/configsData/configsData.service';
import { defaultCreateModeValues } from '../components/tabs/general/generalTab.state';

export async function resolveDataForGeneralTab(data: ITabState): Promise<ITabState> {
  data.defaultValues = data?.customerId
    ? await customerService.globHelpers.streamToPromise(
        customerService.sub.customerGeneralData().pipe(
          responseHandler<CustomerGeneralDataRes>({
            errorReturnType: { ...defaultCreateModeValues, id: '' },
          }),
          take(1),
        ),
        () => {
          customerService.pub.customerGeneralData({ id: data.customerId! });
        },
      )
    : {};

  return data;
}

export async function resolveGeneralTabOptions(data: ITabState): Promise<ITabState> {
  data.generalTabOptions = await customerService.globHelpers.streamToPromise(
    configsData.sub.dictCustomerGroups().pipe(
      zipWith(configsData.sub.dictDeliveryNoteGroups(), configsData.sub.dictCustomerProfiles()),
      responseHandler<GeneralTabOptionsRes>({ errorReturnType: [[], [], []] }),
      map(([customerGroups, deliveryNoteGroups, customerDefaultProfiles]) => ({
        customerGroups,
        deliveryNoteGroups,
        customerDefaultProfiles,
      })),
    ),
    () =>
      configsData.pub.common([
        'dictCustomerGroups',
        'dictDeliveryNoteGroups',
        'dictCustomerProfiles',
      ]),
  );
  return data;
}

type GeneralTabOptionsRes = [
  DictCustomerGroupsRes,
  DictDeliveryNoteGroupsRes,
  DictCustomerProfilesRes,
];
