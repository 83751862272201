import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { zip } from 'rxjs';
import { isBrowserOutdated } from '../shared/helpers/browsersSupport/browserSupport.helper';
import { Auth, authService } from '../shared/services/auth/auth.service';
import { redirectIfProduction } from './helper.routes.ts';

export const ProtectedRoute: FC<IProtectedRoute> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isBrowserOutdated()) {
      navigate('/outdated-browsers', { replace: true });
      return;
    } else {
      const unsub = zip(authService.sub.isLoggedIn()).subscribe(([{ status }]) => {
        if (Auth.loggedIn !== status) {
          redirectIfProduction({
            fallbackHandler: () => {
              navigate('/login');
            },
          });
        }
        if (status === Auth.loggedIn) {
          setIsLoggedIn(true);
        }
      });
      return () => {
        unsub.unsubscribe();
      };
    }
  }, []);

  return isLoggedIn ? <>{children}</> : null;
};
export interface IProtectedRoute {
  children: React.ReactElement;
}
