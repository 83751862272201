import { FC, useMemo } from 'react';
import {
  FieldsRow,
  FieldsSection,
  FormNumericField,
  FormSelect,
  SelectMenuWithFooter,
} from '../../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import { ISelectProps } from '../../../../../../../shared/components/form/fields/select.component';
import { C_Discount_Kind, C_Tax_System } from '../../../../../../../graphql/generatedModel';
import { IConditionsTabOptions } from '../../../../../states/tabState.model';
import { useFormContext } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';

export const DiscountSection: FC<IDiscount> = ({
  priceCategoriesOptions,
  globalTaxSystemId,
  discountGroups,
  quantityDiscounts,
}) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const discountKindId = watch('discountKindId') as C_Discount_Kind;
  let discountAdditionalFields;
  if (discountKindId === C_Discount_Kind.DK2_ALL_ARTICLES) {
    discountAdditionalFields = (
      <FormNumericField
        name='discountOnGroup1'
        label={t('customer.discountPercent')}
        width='100px'
        noTrailingZeros
        precision={2}
        max={999}
      />
    );
  }
  if (
    discountKindId === C_Discount_Kind.DK3_ARTICLE_GROUPS ||
    discountKindId === C_Discount_Kind.DK4_PRICE_REDUCE
  ) {
    discountAdditionalFields = (
      <Stack
        display='flex'
        flexDirection='row'
        sx={{
          flexWrap: 'wrap',
        }}
      >
        {discountGroups?.map((group, index) => {
          if (index % 4 === 0) {
            return (
              <Stack
                key={group?.id || index}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: index !== 0 ? '24px' : '0',
                }}
              >
                {/* here we make HTML structure and CSS in such way that we have 4 inputs in each line */}
                {discountGroups.slice(index, index + 4).map((item, subIndex) => (
                  <Stack
                    key={index + subIndex}
                    sx={{
                      flexDirection: 'row',
                      boxSizing: 'border-box',
                      paddingRight: '48px',
                      width: '241px',
                      justifyContent: 'end',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      fontSize={16}
                      fontWeight={400}
                      mr='16px'
                      sx={{ wordBreak: 'break-word' }}
                    >
                      {item.label}
                    </Typography>
                    <FormNumericField
                      name={`discountOnGroup${index + subIndex + 1}`}
                      label={t('customer.discountPercent')}
                      width='100px'
                      noTrailingZeros
                      precision={2}
                      max={999}
                      sx={{ minWidth: '100px' }}
                    />
                  </Stack>
                ))}
              </Stack>
            );
          }
        })}
      </Stack>
    );
  }
  if (
    discountKindId === C_Discount_Kind.DK5_QUANTITY_DISCOUNT ||
    discountKindId === C_Discount_Kind.DK6_QUANTITY_DISCOUNT_GROUPS
  ) {
    discountAdditionalFields = (
      <FormSelect
        label={t('customer.quantityDiscountValue')}
        width='300px'
        name='quantityDiscountSettingId'
        options={(quantityDiscounts as []) || []}
        PaperComponent={(props) => (
          <SelectMenuWithFooter {...props} href='/settings/configuration/invoice' />
        )}
      />
    );
  }

  const taxSystemOptions: ISelectProps['options'] = useMemo(
    () => [
      {
        id: C_Tax_System.TAX1_INCLUDED_VAT,
        label: t(`enums.${C_Tax_System.TAX1_INCLUDED_VAT}`),
      },
      {
        id: C_Tax_System.TAX2_EXCLUDED_VAT,
        label: t(`enums.${C_Tax_System.TAX2_EXCLUDED_VAT}`),
      },
      {
        id: C_Tax_System.TAX3_NO_VAT,
        label: t(`enums.${C_Tax_System.TAX3_NO_VAT}`),
      },
    ],
    [t, C_Tax_System],
  );

  const discountKindIdOptions = useMemo(
    () => [
      {
        id: C_Discount_Kind.DK1_NO_DISCOUNT,
        label: t(`enums.${C_Discount_Kind.DK1_NO_DISCOUNT}`),
      },
      {
        id: C_Discount_Kind.DK2_ALL_ARTICLES,
        label: t(`enums.${C_Discount_Kind.DK2_ALL_ARTICLES}`),
      },
      {
        id: C_Discount_Kind.DK3_ARTICLE_GROUPS,
        label: t(`enums.${C_Discount_Kind.DK3_ARTICLE_GROUPS}`),
      },
      {
        id: C_Discount_Kind.DK4_PRICE_REDUCE,
        label: t(`enums.${C_Discount_Kind.DK4_PRICE_REDUCE}`),
      },
      {
        id: C_Discount_Kind.DK5_QUANTITY_DISCOUNT,
        label: t(`enums.${C_Discount_Kind.DK5_QUANTITY_DISCOUNT}`),
      },
      {
        id: C_Discount_Kind.DK6_QUANTITY_DISCOUNT_GROUPS,
        label: t(`enums.${C_Discount_Kind.DK6_QUANTITY_DISCOUNT_GROUPS}`),
      },
    ],
    [t, C_Discount_Kind],
  );
  return (
    <FieldsSection titleBoxSx={{ pt: 0 }} nomt title={t('customer.price_and_discount')}>
      <FieldsRow>
        <FormSelect
          label={t('customer.priceCategories')}
          width='220px'
          name='priceCategoryId'
          disableClearable
          options={(priceCategoriesOptions as []) || []}
          PaperComponent={(props) => (
            <SelectMenuWithFooter {...props} href='/settings/configuration/customer' />
          )}
        />
        {globalTaxSystemId === C_Tax_System.TAX4_INDIVIDUAL_FOR_CUSTOMER && (
          <FormSelect
            label={t('customer.vat_system')}
            width='220px'
            disableClearable
            name='taxSystemId'
            options={taxSystemOptions}
          />
        )}
        <FormSelect
          label={t('customer.discountKind')}
          width='456px'
          name='discountKindId'
          disableClearable
          options={(discountKindIdOptions as []) || []}
        />
      </FieldsRow>

      {discountAdditionalFields && (
        <FieldsRow flexWrap='wrap' width={820}>
          {discountAdditionalFields}
        </FieldsRow>
      )}
    </FieldsSection>
  );
};

interface IDiscount {
  priceCategoriesOptions: IConditionsTabOptions['priceCategories'];
  globalTaxSystemId: C_Tax_System | undefined;
  discountGroups: IConditionsTabOptions['discountGroups'];
  quantityDiscounts: IConditionsTabOptions['quantityDiscounts'];
}
