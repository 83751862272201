import { DictDeliveryNoteGroupsRes } from '../../../../shared/services/configsData/configsData.service';
import { IOverviewState } from './overview.state';

export enum OverviewTabs {
  deliveryNoteOverview = 'delivery-note-overview',
  articleOverview = 'article-overview',
}
export interface ITabState {
  defaultValues: any;
  deliveryNoteGroups: DictDeliveryNoteGroupsRes;
  datePeriod: IOverviewState['datePeriod'];
  deliveryNoteGroupId: number | null;
}
