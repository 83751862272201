import React, { FC, SyntheticEvent, useMemo } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { TaxesByDateRes } from '../../../../services/order.service.ts';
import { TableSelect } from '../../../../../../shared/components/table/styles/styleFields.ts';
import { ISelectOption } from '../../../../../../shared/components/form/fields/select.component.tsx';
import { orderDetailsState as orderDetailsStateCreate } from '../../../../create/states/orderDetails/orderDetails.state.ts';
import { orderDetailsState as orderDetailsStateEdit } from '../../../../edit/states/orderDetails.state.ts';

export const VatEditCell: FC<IVatEditCell> = ({ params, taxes, type }) => {
  const { api, id, field, row } = params;
  const { setEditCellValue, stopCellEditMode } = api;
  const value = useMemo(() => {
    return taxes?.find((el) => el?.id === params.value);
  }, []);
  const handleChange = (_: SyntheticEvent, v: ISelectOption | null) => {
    setEditCellValue({ id, field, value: v?.id });
    (type === 'create' ? orderDetailsStateCreate : orderDetailsStateEdit).pub.updateCell(
      {
        ...row,
        vatCode: v?.id,
      },
      'vatCodeCell',
    );
    stopCellEditMode({ id, field });
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.target instanceof HTMLInputElement && e.code === 'Enter') {
      stopCellEditMode({ id, field });
    }
  };

  return (
    <TableSelect
      autoFocus
      openOnFocus
      size='small'
      width='100%'
      fontSize='14px'
      autoHighlight={true}
      disableClearable
      disableTextInput
      options={taxes as []}
      value={value as ISelectOption}
      inputValue={value?.label || undefined}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
};

interface IVatEditCell {
  params: GridRenderCellParams;
  taxes: TaxesByDateRes;
  type: 'create' | 'edit';
}
