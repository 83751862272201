/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';

import { Popup, TPopupComponent } from '../../popup/popup.abstract.tsx';
import { TPopupDefaultActions } from '../../popup/services/popup.service.ts';

export class ConfirmationPopup extends Popup<IConfirmationPopup> {
  Component: FC<TPopupComponent>;

  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const Wrapper = useMemo(() => this.Wrapper, []);

      const { t } = useTranslation();
      const [message, setMessage] = useState<string>('');
      const handleApply = useRef(() => {});
      useEffect(() => {
        stream.actionListener('open').subscribe(({ onApply, message }) => {
          setMessage(message);
          handleApply.current = onApply;
        });
      }, []);
      const onApply = () => {
        stream.emit('close');
        handleApply.current();
      };

      return (
        <Wrapper
          stream={stream}
          popupTitle={t('common.confirm')}
          PaperProps={{ sx: { maxWidth: '500px' } }}
          fullWidth
        >
          <Typography>{message}</Typography>
          <Stack mt={3} direction='row' justifyContent='flex-end' spacing={2}>
            <Button onClick={onApply} variant='contained'>
              {t('common.confirm')}
            </Button>
          </Stack>
        </Wrapper>
      );
    };
  }
}

interface IConfirmationPopup {
  action: TPopupDefaultActions;
  onApply: () => void;
  message: string;
}
