import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const TableHeader: FC<ITableHeader> = ({ icon, title, isAllDataShown, dataLength }) => {
  const { t } = useTranslation();

  return (
    <Stack flexDirection='row' mb='16px'>
      {icon}
      <Typography
        marginLeft='8px'
        variant='h4'
        fontSize='18px'
        fontWeight='500'
        lineHeight='21.94px'
      >
        {title}
      </Typography>
      {!isAllDataShown && (
        <Typography
          marginLeft='8px'
          variant='h4'
          fontSize='12px'
          fontWeight='400'
          lineHeight='21.94px'
        >
          {`(${t('common.shown')}: 5/${dataLength})`}
        </Typography>
      )}
    </Stack>
  );
};

interface ITableHeader {
  icon: JSX.Element;
  title: string;
  dataLength?: number;
  isAllDataShown: boolean;
}
