import { GridColDef } from '@mui/x-data-grid-premium';
import { ReactElement, Children, JSXElementConstructor } from 'react';
import { TypeTable } from '../table.component';

export const formColumnsAndFooter = ({
  children,
  columnVisibilityModel,
  viewCells,
}: IformColumnsAndFooterArgs): IformColumnsAndFooterRes => {
  const columns: GridColDef[] = [];
  let footer: ReactElement | null = null;

  Children.forEach(children, (child) => {
    if (child.props.view === 'column') {
      let visibility = true;
      if (columnVisibilityModel) {
        const keys = Object.keys(columnVisibilityModel);
        const findEl = keys.find((key) => {
          const groupField = key.split('group_')?.[1];
          /* The group keyword combines multiple columns that contain text after "group_" in their field name.
          Example: group_test: false. All columns containing the word "test" in the field name will be hidden*/
          if (groupField && child.props.field.includes(groupField)) return key;
          else if (key === child.props.field) return key;
        });
        findEl && (visibility = columnVisibilityModel[findEl]);
      }
      if (visibility) {
        columns.push({
          ...child.props,
          ...(viewCells?.actions[child.props.field] && {
            cellClassName: viewCells.actions[child.props.field],
          }),
        });
      }
    }
    if (child.props.view === 'footer') footer = child;
  });

  return { columns, footer };
};

interface IformColumnsAndFooterArgs {
  children:
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactElement<any, string | JSXElementConstructor<any>>[];
  columnVisibilityModel: TypeTable['columnVisibilityModel'];
  viewCells: TypeTable['viewCells'];
}

interface IformColumnsAndFooterRes {
  columns: GridColDef[];
  footer: ReactElement | null;
}
