import { gql } from 'graphql-request';

export const getOverviewOrders = gql`
  query listWA_OverviewOrders($filter: WA_OverviewInput!) {
    wawiAssist {
      listWA_OverviewOrders(filter: $filter) {
        customerGroupName
        internalOrFullName
        customerNr
        deliveryNoteGroupName
        historyUserFullName
        orderDate
        orderId
        orderNetTotal
        orderTotal
        orderType
        orderNo
      }
    }
  }
`;

export const getOverviewArticles = gql`
  query listWA_OverviewArticles($filter: WA_OverviewInput!) {
    wawiAssist {
      listWA_OverviewArticles(filter: $filter) {
        orderId
        articleId
        articleNo
        customerNo
        quantity
        quantityProd
        articleName
        internalOrFullName
        orderDate
        indTextDeliveryNote
        indTextProduction
        orderNo
        orderTotal
        orderNetTotal
        positionPrice
        positionDiscount
        virtualPositionId
        positionVatCode
      }
    }
  }
`;
