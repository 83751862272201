import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SvgIcon,
} from '@mui/material';
import { Children, FC, MouseEventHandler, ReactElement, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BehaviorSubject, withLatestFrom } from 'rxjs';
import { NavContext } from '../nav.context';
import { navService } from '../nav.service';
import { navBlocker } from '../blocker/nav.blocker.ts';

const isParentNavActive = (pathname: string, to: string): boolean => {
  const getBasePath = (path: string): string | null => {
    const segments = path.split('/').filter(Boolean);
    return segments.length > 0 ? segments[0] : null;
  };
  return getBasePath(to) === getBasePath(pathname);
};

export const NavLink: FC<INavLink> = ({ icon: Icon, label, to, children }) => {
  const { pathname } = useLocation();
  const [navActive, setNavActive] = useState<boolean>(isParentNavActive(pathname, to));
  const [expandNav, setExpandNav] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const childNav = Boolean(Children.count(children));
  const nav$ = useMemo(
    () => new BehaviorSubject<{ navActive: boolean; expandNav: boolean }>({ navActive, expandNav }),
    [],
  );

  const navLinkClickHandle: MouseEventHandler<HTMLElement> = (evt) => {
    evt.stopPropagation();
    if (childNav) {
      if (!open) {
        navService.pub.openMenu();
      }
      setExpandNav(!expandNav);
    } else if (!navActive) {
      navBlocker.controlledNavigate(() => {
        navService.pub.navigateTo(to);
        setNavActive(true);
      });
      navService.pub.closeMenu();
    }
  };
  useEffect(() => {
    if (isParentNavActive(pathname, to)) {
      navBlocker.controlledNavigate(() => {
        setNavActive(true);
      });
    } else {
      setNavActive(false);
    }
  }, [pathname]);

  useEffect(() => {
    nav$.next({ navActive, expandNav });
  }, [navActive, expandNav]);

  useEffect(() => {
    const navigateToSub = navService.sub.navigateTo().subscribe((userActionTo) => {
      if (!isParentNavActive(userActionTo, to)) {
        setNavActive(false);
      }
    });
    const closeMenuSub = navService.sub
      .closeMenu()
      .pipe(withLatestFrom(nav$))
      .subscribe(([menu, props]) => {
        setOpen(menu);
        if (childNav && props.expandNav) {
          setExpandNav(false);
        }
      });
    const openMenuSub = navService.sub
      .openMenu()
      .pipe(withLatestFrom(nav$))
      .subscribe(([menu, props]) => {
        const { state, isBurger } = menu;
        setOpen(state);
        if (isBurger && childNav && props.navActive) {
          setExpandNav(true);
        }
      });
    return () => {
      navigateToSub.unsubscribe();
      closeMenuSub.unsubscribe();
      openMenuSub.unsubscribe();
      nav$.unsubscribe();
    };
  }, []);

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton onClick={navLinkClickHandle} sx={{ pl: 0 }} selected={navActive}>
        <Stack flexGrow={1} direction='row' justifyContent='center' alignItems='center'>
          <ListItemIcon
            sx={{
              minWidth: (theme) => theme.nav.width,
              mr: (theme) => theme.spacing(0),
              justifyContent: 'center',
            }}
            title={label}
          >
            <Icon {...(navActive && { color: 'primary' })} />
          </ListItemIcon>
          <ListItemText primary={label} />
          {childNav ? expandNav ? <ExpandLess /> : <ExpandMore /> : null}
        </Stack>
      </ListItemButton>
      {childNav && (
        <Collapse in={expandNav}>
          <List sx={{ padding: 0 }}>
            <NavContext.Provider value={{ setParentNavActive: setNavActive }}>
              {children}
            </NavContext.Provider>
          </List>
        </Collapse>
      )}
    </ListItem>
  );
};

interface INavLink {
  icon: typeof SvgIcon;
  to: string;
  label: string;
  children?: ReactElement | ReactElement[];
}
