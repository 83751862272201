import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useOptionsForSecectors } from '../../../../hooks/useOptionsForSecectors';
import {
  FieldsRow,
  FieldsSection,
  FormSelect,
} from '../../../../../../../../../../../../shared/components/form';

export const Invoice: FC = () => {
  const { t } = useTranslation();
  const optionsFromEnums = useOptionsForSecectors();

  return (
    <FieldsSection title={t('invoice.invoice')}>
      <FieldsRow>
        <FormSelect
          width='300px'
          name='invoicingIntervalId'
          label={t('customer.accounting')}
          options={(optionsFromEnums?.invoiceIntervalOptions as []) || []}
          disableClearable
        />
        <FormSelect
          width='300px'
          name='debitorStatus'
          label={t('customer.debtor')}
          options={(optionsFromEnums?.debitorStatusOptions as []) || []}
          disableClearable
        />
      </FieldsRow>
    </FieldsSection>
  );
};
