import { Pub, State, Sub } from '../../../../shared/state/state.abstract.ts';
import {
  C_New_Orders_Filter_Mode,
  C_Order_Type,
  Scalars,
  Wa_CustomersForCreatingOrdersGridFilter,
} from '../../../../graphql/generatedModel.ts';
import {
  CreateNoOrderForCustomerRes,
  CreateOneTimeCustomerRes,
  createOrderService,
  CustomerListRes,
  DeleteNoOrderForCustomerRes,
  SaveOneTimeCustomerRes,
} from '../../services/createOrder.service.ts';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import {
  BehaviorSubject,
  delay,
  filter,
  map,
  merge,
  Observable,
  of,
  share,
  switchMap,
  tap,
} from 'rxjs';
import { dataHelper } from '../../../../shared/helpers/data/data.helper.ts';
import { storageHelper } from '../../../../shared/helpers/storage';
import { format } from 'date-fns';
import { orderDetailsState } from './orderDetails/orderDetails.state.ts';
import { snackbarService } from '../../../../shared/components/snackbar/service/snackbar.service.ts';
import i18n from 'i18next';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler.ts';
import { ISubmitData } from '../components/customerList/popups/oneTimeCustomer/components/content.tsx';

export const initCustomerListState: ICustomerListState = {
  action: undefined,
  filter: {
    date: undefined,
    filterBy: C_New_Orders_Filter_Mode.DAILY_CUSTOMERS,
    orderType: C_Order_Type.OT1_ORDER,
  },
  search: '',
  sortModel: [{ field: 'customerNo', sort: 'asc' }],
  selectedCustomer: undefined,
  customerList: [],
  allCustomerListLength: 0,
};
class PubImpl extends Pub<ICustomerListState> {
  init(params: Partial<ICustomerListState>) {
    this.emit('init', params);
  }
  search(search: string) {
    this.emit('search', { search });
  }
  sort(sortModel: ICustomerListState['sortModel']) {
    this.emit('sort', { sortModel });
  }
  selectCustomer(selectedCustomer: ICustomerListState['selectedCustomer']) {
    this.emit('selectCustomer', { selectedCustomer });
    storageHelper.session.setItem('createOrder.selectedCustomer', selectedCustomer);
  }
  changeNoOrderStatus() {
    this.emit('changeNoOrderStatus', {});
  }
  reSelectCustomer() {
    const streamV = this.stream$.getValue();
    const { filter, customerList, selectedCustomer } = streamV;
    let newSelectedCustomer = selectedCustomer;
    const newCustomerList = [...customerList];
    if (filter.filterBy === C_New_Orders_Filter_Mode['DAILY_CUSTOMERS']) {
      // Select of the next customer is required by such filter
      if (customerList.length > 0) {
        if (selectedCustomer) {
          const selectedInd = customerList.indexOf(selectedCustomer);
          newSelectedCustomer = customerList[selectedInd + 1] || customerList[selectedInd - 1];
          newCustomerList.splice(selectedInd, 1);
        }
      } else {
        newSelectedCustomer = undefined;
      }
      this.emit('selectCustomer', {
        selectedCustomer: newSelectedCustomer,
        customerList: newCustomerList,
      });
    } else {
      // Reset the selected customer
      orderDetailsState.pub.revertOrderData();
    }
  }
  updateDraftStatus(args: { id: Scalars['ID']; draftOrderId: Scalars['ID'] | null }) {
    let { customerList, selectedCustomer } = structuredClone(this.stream$.getValue());
    let newId: string;
    let updatedCustomer: ICustomerListState['selectedCustomer'] = selectedCustomer;
    customerList = customerList.map((customer: ICustomerListState['customerList'][number]) => {
      if (customer?.id === args.id) {
        newId = `${customer?.customerId || ''}${args.draftOrderId || ''}${customer?.offerId || ''}`;
        updatedCustomer = { ...customer, id: newId, draftOrderId: args.draftOrderId };
        return updatedCustomer;
      }
      return customer;
    });
    if (selectedCustomer?.id === args.id) {
      selectedCustomer = updatedCustomer;
      storageHelper.session.setItem('createOrder.selectedCustomer', updatedCustomer);
    }
    this.emit('updateDraftStatus', { customerList, selectedCustomer });
  }
  customerList() {
    this.emit('customerList', {});
  }
  removeCustomers(customersIds: string[]) {
    const { customerList, selectedCustomer } = this.stream$.getValue();
    const newList = customerList.filter((item) => !customersIds.includes(item?.customerId));
    const isRemoveSelected = selectedCustomer && customersIds.includes(selectedCustomer.customerId);
    const newSelected = isRemoveSelected ? newList[0] : selectedCustomer;
    this.emit(isRemoveSelected ? 'selectCustomer' : 'removeCustomers', {
      customerList: newList,
      selectedCustomer: newSelected,
    });
  }
  filter(filter: ICustomerListState['filter']) {
    this.emit('filter', { filter });
  }
  oneTimeCustomer(params: ISubmitData) {
    const { customerProfileId, id, isRegularUse, orderIsPaid, address, ...data } = params;
    const action = id ? 'saveOneTimeCustomer' : 'createOneTimeCustomer';
    if (!id) {
      createOrderService.pub.createOneTimeCustomer({ customerProfileId, data });
    } else {
      const dataToSave = {
        ...data,
        id,
        isOneTimeUse: !isRegularUse,
      };
      createOrderService.pub.saveOneTimeCustomerData({ dataToSave });
    }
    snackbarService.pub.show({
      content: i18n.t('order.creatingCustomer'),
      type: 'loading',
      id: 'oneTimeCustomer',
      noAutoHide: true,
    });
    this.emit(action, {});
  }
}
class SubImpl extends Sub<ICustomerListState> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());
  protected actionHandlers(): Observable<ICustomerListState> {
    return merge(
      this.initState(),
      this.customerList(),
      this.changeNoOrderStatus(),
      this.oneTimeCustomer(),
      this.updateState(),
    ).pipe(
      map(({ customerList, search, sortModel, allCustomerListLength, ...rest }) => {
        let sortedCustomerList = [...customerList];
        let selectedCustomer = rest.selectedCustomer;
        allCustomerListLength = customerList.length;
        customerList = dataHelper
          .data(customerList as [])
          .sort({
            sortModel,
            callback: (data) => {
              sortedCustomerList = data as [];
            },
          })
          .search({ search, fields: ['customerNo', 'internalOrFullName'] })
          .result() as ICustomerListState['customerList'];
        // Check if selectedRecord is in new list. Otherwise, select the first one from the list.
        if (rest.action === 'customerList' || rest.action === 'filter') {
          const foundSelectedRecord =
            customerList.find((item) => item?.id === selectedCustomer?.id) || customerList[0];
          selectedCustomer = foundSelectedRecord || null;
          rest.action = 'selectCustomer';
          storageHelper.session.setItem('createOrder.selectedCustomer', selectedCustomer);
        }
        const updatedState = {
          ...rest,
          customerList,
          selectedCustomer,
          search,
          sortModel,
          allCustomerListLength,
        };
        this.stream$.next({
          ...updatedState,
          customerList: sortedCustomerList,
          action: 'internalUpdate',
        });
        return updatedState;
      }),
      tap(({ action }) => {
        if (action !== 'sort' && action !== 'search') this.loading$.next(false);
      }),
    );
  }
  loading(): Observable<boolean> {
    return this.shareLoading$;
  }
  sharedValues(): Observable<ISharedValues> {
    // if you need current values of this state in other places
    const values = this.stream$.getValue();
    return of({
      date: values.filter.date,
      customer: values.selectedCustomer,
      orderType: values.filter.orderType,
      filter: values.filter,
    });
  }
  /* PRIVATE METHODS start */
  private customerList(): Observable<ICustomerListState> {
    return this.actionListener(['customerList', 'filter']).pipe(
      tap(({ filter }) => {
        this.loading$.next(true);
        typeof filter.date === 'object' && (filter.date = format(filter.date, 'yyyy-MM-dd'));
        createOrderService.pub.customerList({ filter });
      }),
      switchMap((state) => {
        return createOrderService.sub.customerList().pipe(
          responseHandler<ICustomerListState['customerList']>({
            errorReturnType: [],
          }),
          map((v) => ({ ...state, customerList: v })),
        );
      }),
    );
  }
  private changeNoOrderStatus(): Observable<ICustomerListState> {
    let method: 'deleteNoOrder' | 'createNoOrder';
    let content: string;
    return this.actionListener('changeNoOrderStatus').pipe(
      filter(({ selectedCustomer }) => !!selectedCustomer),
      tap(({ filter, selectedCustomer }) => {
        if (selectedCustomer?.isNoOrderOnDate) {
          createOrderService.pub.deleteNoOrder({
            customerId: selectedCustomer.customerId,
            onDate: filter.date,
          });
          method = 'deleteNoOrder';
          content = i18n.t('order.have_orders_for_this_date');
        } else {
          createOrderService.pub.createNoOrder({
            customerId: selectedCustomer?.customerId!,
            onDate: filter.date,
          });
          method = 'createNoOrder';
          content = i18n.t('order.no_orders_for_this_date');
        }
        snackbarService.pub.hide('noOrderStatus');
        snackbarService.pub.show({
          content,
          type: 'loading',
          noAutoHide: true,
          id: 'noOrderStatusLoading',
        });
      }),
      switchMap((state) => {
        return createOrderService.sub[method]().pipe(
          responseHandler<DeleteNoOrderForCustomerRes | CreateNoOrderForCustomerRes>({
            errorReturnType: false,
          }),
          tap((response) => {
            if (!response) {
              snackbarService.pub.hide('noOrderStatusLoading');
            }
          }),
          filter((response) => response),
          map(() => {
            const updatedState = structuredClone(state);
            const {
              filter: { filterBy },
              customerList,
              selectedCustomer,
            } = updatedState;
            if (
              filterBy === C_New_Orders_Filter_Mode['DAILY_CUSTOMERS'] ||
              filterBy === C_New_Orders_Filter_Mode['CUSTOMERS_WITH_NO_ORDERS']
            ) {
              if (customerList.length > 0) {
                if (selectedCustomer) {
                  const selectedInd = customerList.indexOf(selectedCustomer);
                  updatedState.selectedCustomer =
                    customerList[selectedInd + 1] || customerList[selectedInd - 1];
                  updatedState.customerList.splice(selectedInd, 1);
                }
              } else {
                updatedState.selectedCustomer = undefined;
              }
            } else if (updatedState.selectedCustomer) {
              const { isNoOrderOnDate } = updatedState.selectedCustomer;
              updatedState.selectedCustomer.isNoOrderOnDate = !isNoOrderOnDate;
            }
            snackbarService.pub.hide('noOrderStatusLoading');
            snackbarService.pub.show({
              content,
              type: 'success',
              id: 'noOrderStatus',
            });
            return updatedState;
          }),
        );
      }),
    );
  }
  private oneTimeCustomer(): Observable<ICustomerListState> {
    return this.actionListener(['createOneTimeCustomer', 'saveOneTimeCustomer']).pipe(
      switchMap((state) => {
        const method =
          state.action === 'createOneTimeCustomer'
            ? 'createOneTimeCustomer'
            : 'saveOneTimeCustomerData';
        return createOrderService.sub[method]().pipe(
          responseHandler<CreateOneTimeCustomerRes | SaveOneTimeCustomerRes>({
            success: () => 'order.customerCreated',
            errorReturnType: {
              isAllowedAutoOrder: false,
              isDeliveredOnDate: false,
              isNoOrderOnDate: false,
              hasOrderOnDate: false,
              customerId: '',
            },
          }),
          tap(() => snackbarService.pub.hide('oneTimeCustomer')),
          filter((data) => !!data),
          map((data) => {
            const updatedState = structuredClone(state);
            const selectedCustomer = { ...data, id: data.customerId };
            updatedState.customerList = [...state.customerList, selectedCustomer];
            updatedState.selectedCustomer = selectedCustomer;
            updatedState.action = 'selectCustomer';
            return updatedState;
          }),
        );
      }),
    );
  }
  private updateState(): Observable<ICustomerListState> {
    return this.actionListener([
      'search',
      'sort',
      'selectCustomer',
      'updateDraftStatus',
      'removeCustomers',
    ]);
  }
  private initState(): Observable<ICustomerListState> {
    // emits the first value for correct operation of the pairwise method
    return this.actionListener('init').pipe(delay(0));
  }
  /* PRIVATE METHODS end */
}

class CustomerListState extends State<ICustomerListState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const customerListState = new CustomerListState(initCustomerListState);

export interface ICustomerListState {
  action:
    | 'init'
    | 'search'
    | 'sort'
    | 'selectCustomer'
    | 'updateDraftStatus'
    | 'changeNoOrderStatus'
    | 'customerList'
    | 'removeCustomers'
    | 'saveOneTimeCustomer'
    | 'createOneTimeCustomer'
    | 'loader'
    | 'filter'
    | 'internalUpdate'
    | undefined;
  filter: Wa_CustomersForCreatingOrdersGridFilter;
  search: string;
  allCustomerListLength: number;
  sortModel: GridSortModel;
  selectedCustomer: CustomerListRes[number] | undefined;
  customerList: CustomerListRes;
}

export interface ISharedValues {
  date: Scalars['Date'];
  customer: ICustomerListState['selectedCustomer'];
  orderType: C_Order_Type;
  filter: ICustomerListState['filter'];
}
