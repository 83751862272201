import { take, zip } from 'rxjs';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';
import {
  configsData,
  DictCashAssistProductGroupsRes,
  DictCashAssistTemplatesRes,
} from '../../../shared/services/configsData/configsData.service';
import { ArticleCashAssistTabDataRes, articleService } from '../services/article.service';
import { ITabState } from '../states/tabState.model';
import { C_Ca_Visibility_Status } from '../../../graphql/generatedModel';

export const defaultArticleCashAssistData: ArticleCashAssistTabDataRes = {
  caVisibilityStatusId: C_Ca_Visibility_Status.CAVS4_DISABLED,
  caTemplateId: null,
  caProductGroupId: null,
  id: '',
};

export async function resolveArticleCashAssistData(data: ITabState): Promise<ITabState> {
  const cashAssistData = data?.articleId
    ? await articleService.globHelpers.streamToPromise(
        articleService.sub.articleCashAssistTabData().pipe(
          responseHandler<ArticleCashAssistTabDataRes>({
            errorReturnType: defaultArticleCashAssistData,
            customErrorHandler: () => 'article.tab_not_loaded',
          }),
          take(1),
        ),
        () => {
          articleService.pub.articleCashAssistTabData({ id: data.articleId! });
        },
      )
    : {};

  const [cashAssistTemplates = [], productionGroups = []] =
    await articleService.globHelpers.streamToPromise(
      zip(
        configsData.sub.dictCashAssistTemplates(),
        configsData.sub.dictCashAssistProductGroups(),
      ).pipe(
        responseHandler<[DictCashAssistTemplatesRes, DictCashAssistProductGroupsRes]>({
          customErrorHandler: () => 'article.tab_not_loaded',
          errorReturnType: [[], []],
        }),
        take(1),
      ),
      () => {
        configsData.pub.common(['dictCashAssistTemplates', 'dictCashAssistProductGroups']);
      },
    );

  data.defaultValues = cashAssistData;
  data.defaultValues.id = data.articleId;
  data.cashAssistTabOptions = { cashAssistTemplates, productionGroups };

  return data;
}
