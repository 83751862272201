import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { take, zip } from 'rxjs';
import { IPopupWrapper } from '../../../../../../../shared/components/popup/components/popup.component';
import { ICustomerAttachmentReport, IMultiEmail } from './sendMultipleEmails.popup';
import {
  customerService,
  DataForMultiEmailsRes,
} from '../../../../../../customer/services/customer.service';
import { companyConfigService } from '../../../../../../../shared/services/companyConfig/companyConfig.service';
import { responseHandler } from '../../../../../../../shared/responseHandler/responseHandler';
import { snackbarService } from '../../../../../../../shared/components/snackbar/service/snackbar.service';
import {
  C_Pdf_Document_Type,
  SendReminderOfInvoiceEmailMutationVariables,
} from '../../../../../../../graphql/generatedModel';
import {
  IPopupMarkupProps,
  PopupMarkup,
} from '../../../../../../../shared/components/multiEmailPopup/popupMarkup.component';
import { notificationCenter } from '../../../../../../../shared/notificationCenter/notificationCenter';
import {
  openDebtsService,
  SendReminderOfInvoiceWithIds,
} from '../../../../../services/openDebts.service.ts';
import { openDebtsState } from '../../../../states/openDebts.state.ts';

export const MultiEmailContent: FC<IEmailContent> = ({ stream, params }) => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState<IPopupMarkupProps['tableData']>([]);
  const generatedReportsData = useRef<Array<ICustomerAttachmentReport>>([]);
  const { t } = useTranslation();
  const { pub, sub, customerInvoices } = params || {};
  const ID_SEPARATOR = ',';

  useEffect(() => {
    const customerServiceSub = zip(customerService.sub.getDataForMultiEmails(), sub())
      .pipe(
        responseHandler<[DataForMultiEmailsRes, Array<ICustomerAttachmentReport>]>({
          customErrorHandler: () => {
            setLoading(false);
            stream.emit('close');
            return undefined;
          },
          errorReturnType: [[], []],
        }),
      )
      .subscribe((v: [DataForMultiEmailsRes, Array<ICustomerAttachmentReport>]) => {
        const [emailData, reportGenerationData] = v;
        if (emailData) {
          const mergedData = emailData.map((item) => {
            const documentId: string =
              customerInvoices?.[item.customerId].invoiceIds.join(ID_SEPARATOR) || '';
            return { ...item, documentId };
          });
          setTableData(mergedData);
        }
        generatedReportsData.current = reportGenerationData;
        setLoading(false);
      });

    const documentIds = Object.values(customerInvoices || {}).map((item) => item.invoiceIds[0]);
    customerService.pub.getDataForMultiEmails({
      documentIds,
      documentType: C_Pdf_Document_Type.PDT2_INVOICE,
    });
    pub();
    return () => {
      customerServiceSub.unsubscribe();
    };
  }, []);

  const emailText = useMemo<string>(() => {
    const configs = companyConfigService.getCachedConfigs();

    return configs?.isDefaultEmailTextForDebtor
      ? t('debitor.emailMessage')
      : configs?.customEmailTextForDebtor || '';
  }, [t]);

  const handleSendMultiEmail = () => {
    notificationCenter.notify<SendReminderOfInvoiceWithIds[]>(
      openDebtsService.sub.sendReminderOfInvoiceMultipleEmail().pipe(
        responseHandler<SendReminderOfInvoiceWithIds[]>({
          errorReturnType: [],
        }),
      ),
      {
        type: 'snackbar',
        handler(src, target) {
          src.pipe(take(1)).subscribe({
            next: (data) => {
              const resultData = data as SendReminderOfInvoiceWithIds[];
              snackbarService.pub.hide('snackbarLoading');
              let successCount = 0;
              let failureCount = 0;
              resultData.forEach((res) => {
                if (res?.invoiceIds?.length) {
                  openDebtsState.pub.updateReminderCount(res.invoiceIds);
                }
                if (res.sentMessages?.length) {
                  successCount += res.sentMessages.length;
                }
                if (res.unsentMessages?.length) {
                  failureCount += res.unsentMessages?.length;
                }
              });
              if (failureCount === 0 && successCount > 0) {
                snackbarService.pub.show({
                  type: 'success',
                  content: t('common.emails_sent_successfully', { successCount }),
                });
              } else if (failureCount > 0 && successCount > 0) {
                snackbarService.pub.show({
                  type: 'warning',
                  content: `${t('common.emails_sent_successfully', { successCount })}\n${t(
                    'common.emails_failed_to_send',
                    { failureCount },
                  )}`,
                });
              } else {
                snackbarService.pub.show({
                  type: 'error',
                  content: t('common.emails_failed_to_send', { failureCount }),
                });
              }
              target.next(data);
            },
            error: (error) => {
              console.error('Error occurred while sending emails:', error);
              snackbarService.pub.hide('snackbarLoading');
              snackbarService.pub.show({
                type: 'error',
                content: t('common.error_while_sending_emails'),
              });
              target.error(error);
            },
          });
        },
      },
    );
    const emailsParams: Array<SendReminderOfInvoiceEmailMutationVariables> = tableData?.map(
      ({ email, customerId, documentId }) => {
        const reportUrl = generatedReportsData.current.find(
          (item) => item.customerId === customerId,
        )?.url;
        return {
          emailParams: {
            to: email ? email.replace(/\s/g, '').split(/,|;/) : [],
            fromName: 'HS-SOFT',
            body: emailText,
            subject: t('debitor.reminder'),
            attachments: [
              {
                fileName: `${t('debitor.reminder')}.pdf`,
                url: reportUrl as string,
              },
            ],
          },
          invoiceIds: documentId.split(ID_SEPARATOR),
        };
      },
    );
    openDebtsService.pub.sendReminderOfInvoiceMultipleEmail(emailsParams);
    stream.emit('close');
  };

  return (
    <PopupMarkup
      tableData={tableData}
      loading={loading}
      onCancel={() => stream.emit('close')}
      onConfirm={handleSendMultiEmail}
      defaultEmailText={emailText}
    />
  );
};

interface IEmailContent {
  stream: IPopupWrapper['stream'];
  params: IMultiEmail['params'];
}
