import { Observable, Subject } from 'rxjs';
import { snackbarService } from '../components/snackbar/service/snackbar.service';
import { t } from 'i18next';

type NotificationTypeOptions = {
  type: 'snackbar' | undefined;
  handler: <T>(src: Observable<T>, target: Subject<T>) => void;
};

class NotificationCenter {
  protected subject$ = new Subject<any>();
  notify<T>(stream: Observable<T>, notificationTypeOptions: NotificationTypeOptions): Subject<T> {
    const { type, handler } = notificationTypeOptions;
    if (type === 'snackbar') {
      snackbarService.pub.show({
        type: 'loading',
        id: 'snackbarLoading',
        content: t('common.sending'),
        noAutoHide: true,
      });
    }
    if (typeof handler === 'function') {
      handler<T>(stream, this.subject$);
    }
    return this.subject$;
  }
}

export const notificationCenter = new NotificationCenter();
