import { Observable, merge, take, tap, finalize } from 'rxjs';
import { storageHelper } from '../../../../shared/helpers/storage';
import {
  TCompanyConfigs,
  companyConfigService,
} from '../../../../shared/services/companyConfig/companyConfig.service.ts';
import { Pub, State, Sub } from '../../../../shared/state/state.abstract.ts';

class PubImpl extends Pub<ICompanyTabState> {
  recordData(dataToSave: ICompanyTabState['dataToSave']) {
    this.emit('recordData', { dataToSave });
  }
  saveData() {
    this.emit('saveData', {});
  }
  clearStream() {
    this.emit(undefined, {});
  }
}
class SubImpl extends Sub<ICompanyTabState> {
  protected actionHandlers(): Observable<ICompanyTabState> {
    return merge(this.saveData());
  }

  private saveData(): Observable<ICompanyTabState> {
    return this.actionListener('saveData').pipe(
      tap(({ dataToSave }) => {
        companyConfigService
          .saveConfigData(dataToSave)
          .pipe(take(1))
          .subscribe((res) => {
            if (res) {
              const cachedConfigData = storageHelper.session.getItem('companyConfig');
              storageHelper.session.setItem('companyConfig', {
                ...cachedConfigData,
                ...dataToSave,
              });
            }
          });
      }),
      finalize(() => companyTabState.pub.clearStream()),
    );
  }
}

class CompanyTabState extends State<ICompanyTabState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const companyTabState = new CompanyTabState({
  action: undefined,
  dataToSave: {},
});

export interface ICompanyTabState {
  action: undefined | 'recordData' | 'saveData';
  dataToSave: TCompanyConfigs | object;
}
