import { FC } from 'react';
import {
  FieldsSection,
  FormSelect,
} from '../../../../../../../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import { IOptionsForDefaultCustomerSettings } from '../../../../../../customer.tab';

export const Bank: FC<IBank> = ({ options }) => {
  const { t } = useTranslation();

  return (
    <FieldsSection
      titleProps={{ variant: 'body1' }}
      titleBoxSx={{ pt: 4 }}
      nomt
      title={t('customer.bank_abbreviation')}
    >
      <FormSelect
        width='400px'
        name='besrBankId'
        label={t('customer.bank')}
        options={(options?.banksDictOptions as []) || []}
        disableClearable
      />
    </FieldsSection>
  );
};

interface IBank {
  options: IOptionsForDefaultCustomerSettings;
}
