import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { Popup, TPopupComponent } from '../../popup/popup.abstract.tsx';
import { TPopupDefaultActions } from '../../popup/services/popup.service.ts';
/* Icons */
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export class PromptPopup extends Popup<IPromptPopup> {
  Component: FC<TPopupComponent>;

  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const Wrapper = useMemo(() => this.Wrapper, []);

      const { t } = useTranslation();
      const [message, setMessage] = useState<string>('');
      const [withWarningIcon, setWithWarningIcon] = useState<boolean>(false);
      const handleApply = useRef(() => {});
      const handleCancel = useRef(() => {});
      useEffect(() => {
        stream
          .actionListener('open')
          .subscribe(({ onApply, onCancel, message, withWarningIcon }) => {
            setMessage(message);
            if (onApply) handleApply.current = onApply;
            if (onCancel) handleCancel.current = onCancel;
            setWithWarningIcon(!!withWarningIcon);
          });
      }, []);
      const onApply = () => {
        stream.emit('proceed');
        stream.emit('close');
        handleApply.current?.();
      };

      const onCancel = () => {
        stream.emit('cancel');
        stream.emit('close');
        handleCancel.current?.();
      };

      return (
        <Wrapper
          stream={stream}
          popupTitle={
            <Stack direction='row' alignItems='center'>
              {withWarningIcon && (
                <WarningAmberIcon color='warning' sx={{ fontSize: 35, marginRight: '5px' }} />
              )}
              <span>{t('common.warning')}</span>
            </Stack>
          }
          PaperProps={{ sx: { maxWidth: '430px' } }}
          fullWidth
        >
          <Typography variant='caption' fontSize={16}>
            {message}
          </Typography>
          <Stack mt={3} direction='row' justifyContent='center' spacing={2}>
            <Button onClick={onApply} variant='outlined'>
              {t('common.proceed_anyway')}
            </Button>
            <Button onClick={onCancel} variant='contained'>
              {t('common.no')}
            </Button>
          </Stack>
        </Wrapper>
      );
    };
  }
}

interface IPromptPopup {
  action: TPopupDefaultActions | 'proceed' | 'cancel';
  onApply?: () => void;
  onCancel?: () => void;
  message: string;
  withWarningIcon?: boolean;
}
