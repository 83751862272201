import { Pub, State, Sub } from '../../../../../shared/state/state.abstract.ts';
import {
  BehaviorSubject,
  delay,
  filter,
  finalize,
  map,
  merge,
  Observable,
  of,
  share,
  switchMap,
  take,
  tap,
} from 'rxjs';
import equal from 'fast-deep-equal/react';
import { customersListState } from '../../../states/customersList.state.ts';
import {
  customerService,
  ExtendedDefaultOrderGridDataPos,
  GetCustomerDefaultOrderDataRes,
  GetCustomerDefaultOrderDataResExtended,
  GetCustomerDefaultOrderFromDateResExtended,
  SaveCustomerDataRes,
} from '../../../services/customer.service.ts';
import { customerTabLoadingService } from '../customerTabLoading.service.ts';
import {
  C_Article_Weekly_Availability_Status,
  C_Customer_Def_Order_Source,
  C_Save_Operation_Status,
  Scalars,
  Wa_DefaultOrderPositionInput as DefaultOrderPosition,
  Wa_DefaultOrderPositionWeekDaySettingsInput as DefaultOrderPositionWeekDaySettings,
} from '../../../../../graphql/generatedModel.ts';
import { format } from 'date-fns';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler.ts';
import i18n from 'i18next';
import { getFirstDayOfWeek } from '../../../../../shared/components/datePicker/weekPicker.component.tsx';
import { defaultCustomerOrderData } from '../../../loaders/defaultOrderTab.resolver.ts';

export const initDefaultOrderState: IDefaultOrderState = {
  action: undefined,
  selectedPos: null,
  formData: {},
  splittingFormData: {},
  splittingFormDataToSave: {},
  defaultOrderGridData: [],
  defaultOrderGridDataBackup: [],
  customerId: null,
  fromDate: getFirstDayOfWeek(new Date()),
};
class PubImpl extends Pub<IDefaultOrderState> {
  init(params: IDefaultOrderState) {
    this.emit('init', params);
  }
  selectPosition(selectedPos: IDefaultOrderState['selectedPos']) {
    this.emit('selectPosition', { selectedPos });
  }
  updateCell(selectedPos: IDefaultOrderState['selectedPos'], type: TypeCell) {
    this.emit(type, { selectedPos });
  }
  fromDate(fromDate: IDefaultOrderState['fromDate']) {
    this.emit('fromDate', { fromDate });
  }
  formSave(formData: IDefaultOrderState['formData']) {
    this.emit('formSave', { formData });
  }
  updateGridData(defaultOrderGridData: IDefaultOrderState['defaultOrderGridData']) {
    this.emit('updateGridData', { defaultOrderGridData });
  }
  addPosition() {
    this.emit('addPosition', {});
  }
  deletePosition() {
    this.emit('deletePosition', {});
  }
  saveDefaultOrderGrid() {
    this.emit('saveDefaultOrderGrid', {});
  }
  saveDeliverySplittingData(
    data: Pick<IDefaultOrderState, 'defaultOrderGridData' | 'splittingFormDataToSave'>,
  ) {
    this.emit('updateState', { ...data, splittingFormData: data.splittingFormDataToSave });
  }
  clearStream() {
    this.emit(undefined, {});
  }
}
class SubImpl extends Sub<IDefaultOrderState> {
  private loadingMainGrid$ = new BehaviorSubject<boolean>(false);
  private cellsLoading$ = new BehaviorSubject<string[]>([]); // Loading by cells
  private shareLoadingMainGrid$: Observable<boolean> = this.loadingMainGrid$.pipe(share());
  private shareCellsLoading$ = this.cellsLoading$.pipe(share());
  protected actionHandlers(): Observable<IDefaultOrderState> {
    return merge(
      this.simpleCell(),
      this.descriptionCell(),
      this.selectedCustomer(),
      this.formSave(),
      this.formDate(),
      this.addPosition(),
      this.deletePosition(),
      this.saveDefaultOrderGrid(),
      this.updateState(),
    ).pipe(
      tap((state) => {
        this.stream$.next({ ...state, action: 'internalUpdate' });
      }),
    );
  }

  loadingMainGrid(): Observable<boolean> {
    return this.shareLoadingMainGrid$;
  }
  cellsLoading(): Observable<string[]> {
    return this.shareCellsLoading$;
  }
  private formSave(): Observable<IDefaultOrderState> {
    return this.actionListener('formSave').pipe(
      tap(({ customerId, formData: { ...dataToSave } }) => {
        customerService.sub
          .editCustomerData()
          .pipe(
            responseHandler<SaveCustomerDataRes | undefined>({
              success: () => 'customer.defaultOrder_saved',
              customErrorHandler: () => 'common.error_chnages_not_saved',
            }),
            filter((v) => v !== undefined),
            take(1),
          )
          .subscribe((res) => {
            if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
              const { updatedGridItem } = res;
              customersListState.pub.updateCustomer(updatedGridItem!);
            }
          });

        customerService.pub.editCustomerData({
          data: { id: customerId!, ...dataToSave },
        });
      }),
      finalize(() => defaultOrderState.pub.clearStream()),
    );
  }
  private formDate(): Observable<IDefaultOrderState> {
    return this.actionListener('fromDate').pipe(
      tap(({ fromDate, customerId }) => {
        if (fromDate && customerId) {
          this.loadingMainGrid$.next(true);
          customerService.pub.getCustomerDefaultOrderFromDate({
            customerId,
            date: format(fromDate, 'yyyy-MM-dd'),
          });
        }
      }),
      switchMap(() => {
        return customerService.sub.getCustomerDefaultOrderFromDate().pipe(
          responseHandler<GetCustomerDefaultOrderFromDateResExtended>({
            errorReturnType: {
              id: '',
              defaultOrderSourceId: C_Customer_Def_Order_Source.DO1_DEFAULT_ORDER,
              customerWeeklyDefaultOrder: [],
              isAllowedAutoOrder: false,
            },
          }),
          take(1),
        );
      }),
      map((data) => {
        this.loadingMainGrid$.next(false);
        const { customerWeeklyDefaultOrder: defaultOrderGridData, id: customerId } = data;
        let state = structuredClone(this.stream$.getValue());
        if (customerId === state.customerId) {
          state = {
            ...state,
            defaultOrderGridData,
            customerId,
            defaultOrderGridDataBackup: structuredClone(defaultOrderGridData),
          };
        }
        return state;
      }),
    );
  }

  private addPosition(): Observable<IDefaultOrderState> {
    return this.actionListener('addPosition').pipe(
      switchMap((state) => {
        const updatedState = structuredClone(state);
        const newPosition = {
          isActive: true,
          articleId: '',
          description: null,
          articleNo: null,
          itemSortPos: updatedState.defaultOrderGridData.length + 1,
          isMatchAssortmentGroup: true,
          canChangeInOrders: true,
          articleInformation: C_Article_Weekly_Availability_Status.AWAS0_AVAILABLE,
          mondaySettings: {
            isArticleAvailable: true,
            date: null,
            quantity: null,
            quantityMin: null,
            quantityMax: null,
            splittingPartsPreset: ['r', '', '', '', '', '', '', '', '', ''],
          },
          tuesdaySettings: {
            isArticleAvailable: true,
            date: null,
            quantity: null,
            quantityMin: null,
            quantityMax: null,
            splittingPartsPreset: ['r', '', '', '', '', '', '', '', '', ''],
          },
          wednesdaySettings: {
            isArticleAvailable: true,
            date: null,
            quantity: null,
            quantityMin: null,
            quantityMax: null,
            splittingPartsPreset: ['r', '', '', '', '', '', '', '', '', ''],
          },
          thursdaySettings: {
            isArticleAvailable: true,
            date: null,
            quantity: null,
            quantityMin: null,
            quantityMax: null,
            splittingPartsPreset: ['r', '', '', '', '', '', '', '', '', ''],
          },
          fridaySettings: {
            isArticleAvailable: true,
            date: null,
            quantity: null,
            quantityMin: null,
            quantityMax: null,
            splittingPartsPreset: ['r', '', '', '', '', '', '', '', '', ''],
          },
          saturdaySettings: {
            isArticleAvailable: true,
            date: null,
            quantity: null,
            quantityMin: null,
            quantityMax: null,
            splittingPartsPreset: ['r', '', '', '', '', '', '', '', '', ''],
          },
          sundaySettings: {
            isArticleAvailable: true,
            date: null,
            quantity: null,
            quantityMin: null,
            quantityMax: null,
            splittingPartsPreset: ['r', '', '', '', '', '', '', '', '', ''],
          },
          id: Date.now(),
          __reorder__: i18n.t('order.new_position'),
        };
        updatedState.defaultOrderGridData.push(newPosition);
        updatedState.selectedPos = newPosition;
        return of(updatedState);
      }),
    );
  }

  private deletePosition(): Observable<IDefaultOrderState> {
    return this.actionListener('deletePosition').pipe(
      delay(1),
      map((state) => {
        const updatedState = structuredClone(state);
        let selectedPos: IDefaultOrderState['selectedPos'] = null;
        updatedState.defaultOrderGridData = updatedState.defaultOrderGridData?.filter(
          (pos, i, arr) => {
            if (pos?.id === state.selectedPos?.id) {
              const nextPos = arr?.[i + 1];
              const prevPos = arr?.[i - 1];
              if (i === 0 && arr!.length > 1) {
                selectedPos = nextPos;
              }
              if (i !== 0) {
                selectedPos = nextPos || prevPos || null;
              }
              return false;
            } else return true;
          },
        );
        updatedState.selectedPos = selectedPos;
        return updatedState;
      }),
    );
  }

  private simpleCell(): Observable<IDefaultOrderState> {
    return this.actionListener('simpleCell').pipe(
      map((state) => {
        const { selectedPos } = state;
        const updatedPos = structuredClone(selectedPos!);
        state.defaultOrderGridData = state.defaultOrderGridData?.map((el) =>
          el?.id === updatedPos?.id ? updatedPos : el,
        );
        state.selectedPos = updatedPos;
        return { ...state };
      }),
    );
  }

  private descriptionCell(): Observable<IDefaultOrderState> {
    let method: 'getDataForNewDefaultOrderPosition' | 'getDataForExistingDefaultOrderPosition';
    return this.actionListener('descriptionCell').pipe(
      tap((state) => {
        const { selectedPos, fromDate, customerId } = state;
        const { id, articleId } = selectedPos!;
        this.preUpdateCell(selectedPos);
        method =
          selectedPos?.__reorder__ === i18n.t('order.new_position')
            ? 'getDataForNewDefaultOrderPosition'
            : 'getDataForExistingDefaultOrderPosition';
        customerService.pub[method]({
          customerId: customerId!,
          date: format(fromDate!, 'yyyy-MM-dd'),
          id,
          articleId,
        });
        this.cellsLoading$.next([...this.cellsLoading$.value, `${id}_description`]);
      }),
      switchMap(() => {
        return customerService.sub[method]().pipe(
          filter((values) => {
            const state = this.stream$.getValue();
            return !!state.defaultOrderGridData?.some((el) => el.id === values.id);
          }), // if position is deleted
          map((data) => {
            const updatedState = structuredClone(this.stream$.getValue()!);
            updatedState.defaultOrderGridData = updatedState.defaultOrderGridData.map((item) => {
              if (data.id === item.id) {
                const updatedPosition = {
                  ...item,
                  ...data,
                  mondaySettings: { ...item.mondaySettings, ...data.mondaySettings },
                  tuesdaySettings: { ...item.tuesdaySettings, ...data.tuesdaySettings },
                  wednesdaySettings: { ...item.wednesdaySettings, ...data.wednesdaySettings },
                  thursdaySettings: { ...item.thursdaySettings, ...data.thursdaySettings },
                  fridaySettings: { ...item.fridaySettings, ...data.fridaySettings },
                  saturdaySettings: { ...item.saturdaySettings, ...data.saturdaySettings },
                  sundaySettings: { ...item.sundaySettings, ...data.sundaySettings },
                  __reorder__: `${data?.articleNo ? '(' + data?.articleNo + ')' : ''} ${
                    data?.description || ''
                  }`,
                };
                updatedState.selectedPos = updatedPosition;
                return updatedPosition;
              } else return item;
            });
            this.cellsLoading$.next(
              this.cellsLoading$.value.filter((item) => item !== `${data?.id}_description`),
            );
            return updatedState;
          }),
        );
      }),
    );
  }

  private saveDefaultOrderGrid(): Observable<IDefaultOrderState> {
    return this.actionListener('saveDefaultOrderGrid').pipe(
      tap((state) => {
        const updatedState = structuredClone(state);
        const { defaultOrderGridData, defaultOrderGridDataBackup, splittingFormDataToSave } =
          updatedState;
        const isDirtyGrid = !equal(defaultOrderGridData, defaultOrderGridDataBackup);
        if (isDirtyGrid || Object.keys(splittingFormDataToSave).length) {
          let customerWeeklyDefaultOrder;
          if (isDirtyGrid) {
            customerWeeklyDefaultOrder = updatedState.defaultOrderGridData.reduce(
              (acc: DefaultOrderPosition[], item) => {
                if (item.articleId) {
                  const lastEl = acc?.[acc?.length - 1];
                  let itemSortPos = lastEl ? lastEl.itemSortPos : 0;
                  acc?.push({
                    articleId: item.articleId,
                    canChangeInOrders: item.canChangeInOrders,
                    mondaySettings: this.prepareDaySettings(item.mondaySettings),
                    tuesdaySettings: this.prepareDaySettings(item.tuesdaySettings),
                    wednesdaySettings: this.prepareDaySettings(item.wednesdaySettings),
                    thursdaySettings: this.prepareDaySettings(item.thursdaySettings),
                    fridaySettings: this.prepareDaySettings(item.fridaySettings),
                    saturdaySettings: this.prepareDaySettings(item.saturdaySettings),
                    sundaySettings: this.prepareDaySettings(item.sundaySettings),
                    itemSortPos: ++itemSortPos,
                  });
                }
                return acc;
              },
              [],
            );
          }

          customerService.sub
            .editCustomerData()
            .pipe(
              responseHandler<SaveCustomerDataRes | undefined>({
                success: () => 'customer.defaultOrder_saved',
                customErrorHandler: () => 'common.error_chnages_not_saved',
              }),
              filter((v) => v !== undefined),
              take(1),
            )
            .subscribe((res) => {
              if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
                const { updatedGridItem } = res;
                customersListState.pub.updateCustomer(updatedGridItem!);
              }
            });
          customerService.pub.editCustomerData({
            data: {
              id: updatedState.customerId!,
              ...(customerWeeklyDefaultOrder && { customerWeeklyDefaultOrder }),
              ...splittingFormDataToSave,
            },
          });
          this.stream$.next({
            ...updatedState,
            splittingFormDataToSave: {},
            defaultOrderGridDataBackup: structuredClone(updatedState.defaultOrderGridData),
            action: 'internalUpdate',
          });
        }
      }),
      finalize(() => defaultOrderState.pub.clearStream()),
      filter(() => false), // state updating is not needed
    );
  }

  private selectedCustomer(): Observable<IDefaultOrderState> {
    return customersListState.sub.state().pipe(
      filter(({ action }) => action === 'selectRecord'),
      filter(({ selectedRecord }) => typeof selectedRecord?.id === 'string'),
      switchMap(({ selectedRecord }) => {
        customerTabLoadingService.pub.loading(true);
        const id = selectedRecord!.id;
        const state = this.stream$.getValue();
        state.action = 'list.selectedRecord';
        return this.details(state, id);
      }),
    );
  }

  private updateState(): Observable<IDefaultOrderState> {
    return this.actionListener(['selectPosition', 'updateGridData', 'updateState']);
  }

  private details(state: IDefaultOrderState, id: string): Observable<IDefaultOrderState> {
    const details = customerService.sub.getCustomerDefaultOrderData().pipe(
      responseHandler<GetCustomerDefaultOrderDataResExtended>({
        errorReturnType: defaultCustomerOrderData,
      }),
      map((data) => {
        customerTabLoadingService.pub.loading(false);
        const {
          customerWeeklyDefaultOrder,
          id: customerId,
          deliverySplittingPartsCount,
          isDeliverySplitting,
          ...formData
        } = data;
        state.defaultOrderGridData = customerWeeklyDefaultOrder;
        state.formData = { ...formData };
        state.defaultOrderGridDataBackup = structuredClone(customerWeeklyDefaultOrder);
        state.splittingFormData = {
          deliverySplittingPartsCount,
          isDeliverySplitting,
          enabledDays: [],
        };
        state.fromDate = initDefaultOrderState.fromDate;
        state.splittingFormDataToSave = {};
        state.customerId = customerId;
        return state;
      }),
    );
    customerService.pub.getCustomerDefaultOrderData({ customerId: id });
    return details;
  }
  /* UTILITY start */
  private preUpdateCell(position: IDefaultOrderState['selectedPos']) {
    const state = this.stream$.getValue();
    if (position && state.defaultOrderGridData) {
      state.defaultOrderGridData = state.defaultOrderGridData?.map((el) =>
        el.id === position.id ? position : el,
      );
      this.stream$.next({ ...state, action: 'updateState' });
    }
  }
  private prepareDaySettings(
    daySettings: ExtendedDefaultOrderGridDataPos['mondaySettings'],
  ): DefaultOrderPositionWeekDaySettings {
    const copyDaySettings = structuredClone(daySettings);
    const { date: _, isArticleAvailable: __, ...rest } = copyDaySettings;
    return rest;
  }
  /* UTILITY end */
}

class DefaultOrderState extends State<IDefaultOrderState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const defaultOrderState = new DefaultOrderState(initDefaultOrderState);
export interface IDefaultOrderState {
  action:
    | 'init'
    | 'selectPosition'
    | 'list.selectedRecord'
    | 'fromDate'
    | 'formSave'
    | 'updateGridData'
    | 'addPosition'
    | 'deletePosition'
    | 'updateState'
    | 'saveDefaultOrderGrid'
    | 'internalUpdate'
    | TypeCell
    | undefined;
  selectedPos: ExtendedDefaultOrderGridDataPos | null;
  formData: Partial<IFormData>;
  splittingFormData: Partial<ISplittingFormData>;
  splittingFormDataToSave: Partial<Omit<ISplittingFormData, 'enabledDays'>>;
  defaultOrderGridData: DefaultOrderGridData;
  defaultOrderGridDataBackup: DefaultOrderGridData;
  customerId: Scalars['ID'] | null;
  fromDate: Date;
}

type TypeCell = 'descriptionCell' | 'simpleCell';
export type IFormData = Pick<
  GetCustomerDefaultOrderDataRes,
  'defaultOrderSourceId' | 'isAllowedAutoOrder'
>;

export interface ISplittingFormData
  extends Pick<
    GetCustomerDefaultOrderDataRes,
    'deliverySplittingPartsCount' | 'isDeliverySplitting'
  > {
  enabledDays: string[];
}
export type DefaultOrderGridData = ExtendedDefaultOrderGridDataPos[];
