/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react-hooks/rules-of-hooks */
import { FC, ReactNode, useMemo, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Stack, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import equal from 'fast-deep-equal/react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SettingsIcon from '@mui/icons-material/Settings';

import { viewRows } from '../../../../editPrintList/views/viewRows.ts';
import { Table } from '../../../../../../../../shared/components/table/table.component.tsx';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../shared/components/popup/popup.abstract.tsx';
import { TPopupDefaultActions } from '../../../../../../../../shared/components/popup/services/popup.service.ts';
import {
  Column,
  Footer,
  SFooter,
} from '../../../../../../../../shared/components/table/components/common.components.tsx';
import { storageHelper } from '../../../../../../../../shared/helpers/storage/index.ts';
import { FieldsRow, FormTextField } from '../../../../../../../../shared/components/form/index.ts';
import { SStack } from '../../../../../../../order/common/orderGridItems/cells/product/product.cell';
import { SValue } from '../../../../../../../settings/configuration/components/tabs/invoice/dictionaries/quantityDiscountsDict/cells/descriptionDict.cell.tsx';
import { initTourplansSettings } from '../../generalTab.state.ts';
import { EditPrintListGeneralTabRes } from '../../../../../../services/editPrintList.service.ts';
import { useBeforeClosePopup } from '../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import { TourplansCaregoryFilterPopup } from './tourplansCategoryFilter.popup.tsx';
import { DictArticleListsRes } from '../../../../../../../../shared/services/configsData/configsData.service.ts';

export class TourplansPopup extends Popup<ITourplansPopup> {
  Component: FC<TPopupComponent>;

  constructor() {
    super();
    const stream = this.innerStream;
    this.Component = () => {
      const Wrapper = useMemo(() => this.Wrapper, []);
      const { t } = useTranslation();
      const {
        getValues,
        reset,
        setValue,
        control,
        formState: { dirtyFields, isDirty, defaultValues },
      } = useFormContext();

      const [values, setValues] = useState<ITourplansPopup['values']>(undefined);
      const [selectedRow, setSelectedRow] = useState<TTourplansSettings | null>(null);

      const tourplanConfigurationData =
        getValues()?.tourplanConfigurationData?.map(
          (setting: EditPrintListGeneralTabRes['tourplanConfigurationData'][number]) => ({
            ...setting,
            id: setting?.categoryId,
          }),
        ) || [];

      const tourplansCaregoryFilterPopup = useMemo(() => new TourplansCaregoryFilterPopup(), []);
      const handleApply = useRef(() => {});

      const onClose = () => {
        if (dirtyFields?.tourplanConfigurationData) reset();
      };
      const onApply = () => {
        if (
          !equal(getValues()?.tourplanConfigurationData, defaultValues?.tourplanConfigurationData)
        ) {
          handleApply.current();
        }
        setSelectedRow(null);
        stream.emit('close');
      };

      const onAddNewTourplansSetting = () => {
        const lastCategoryId =
          getValues()?.tourplanConfigurationData.reduce(
            (maxId: number, setting: TTourplansSettings) =>
              Math.max(maxId, parseInt(setting.categoryId, 10)),
            0,
          ) + 1;

        const newSetting = {
          ...initTourplansSettings,
          categoryId: lastCategoryId.toString(),
          categoryFilter: values?.articleListsOptions.map((option) => ({
            ...option,
            checked: false,
          })),
        };
        const updatedData = [...getValues().tourplanConfigurationData, newSetting];

        const addSettings = {
          ...getValues(),
          tourplanConfigurationData: updatedData,
        };

        setValue('tourplanConfigurationData', updatedData, {
          shouldDirty: true,
        });
        control._subjects.values.next({
          type: 'change',
          name: 'tourplanConfigurationData',
          values: addSettings,
        });
      };
      const onDeleteTourplansSetting = () => {
        if (selectedRow) {
          const updatedData = tourplanConfigurationData.filter(
            (setting: TTourplansSettings) => setting.id !== selectedRow.id,
          );
          const currentIndex = tourplanConfigurationData.findIndex(
            (setting: TTourplansSettings) => setting.id === selectedRow.id,
          );

          let newSelectedRow = null;
          if (currentIndex > 0) {
            newSelectedRow = updatedData[currentIndex - 1];
          } else if (currentIndex === 0 && updatedData.length > 0) {
            newSelectedRow = updatedData[0];
          }
          const newTourplanConfigurationData = updatedData.map(
            ({ id, ...setting }: TTourplansSettings) => ({ ...setting }),
          );

          const deleteSettings = {
            ...getValues(),
            tourplanConfigurationData: newTourplanConfigurationData,
          };

          setValue('tourplanConfigurationData', newTourplanConfigurationData, {
            shouldDirty: true,
          });
          control._subjects.values.next({
            type: 'change',
            name: 'tourplanConfigurationData',
            values: deleteSettings,
          });

          setSelectedRow(newSelectedRow);
        }
      };

      const buttonList: IButtonList[] = useMemo(
        () => [
          {
            title: t('article.addNewRecord'),
            icon: <AddIcon />,
            onClick: onAddNewTourplansSetting,
            disabled: tourplanConfigurationData.length > 10,
          },
          {
            title: t('article.deleteRecord'),
            icon: <RemoveIcon />,
            onClick: onDeleteTourplansSetting,
            disabled: !tourplanConfigurationData.length || !selectedRow,
          },
        ],
        [t, tourplanConfigurationData, selectedRow],
      );

      useBeforeClosePopup({
        stream,
        isDirty,
        handlers: () => {
          onClose();
          setSelectedRow(null);
        },
      });

      useEffect(() => {
        stream.actionListener('open').subscribe(({ onApply, values }) => {
          setValues(values);
          handleApply.current = onApply;
        });

        return () => {
          tourplansCaregoryFilterPopup.stream.unsubscribe();
        };
      }, []);

      return (
        <Wrapper
          stream={stream}
          popupTitle={t('production.tour_list_config_edit')}
          PaperProps={{ sx: { maxWidth: '500px', maxHeight: '800px' } }}
          fullWidth
        >
          <Stack direction='column' justifyContent='space-between'>
            <FieldsRow marginBottom={1} alignItems='center' spacing={0}>
              <Stack direction='row' spacing={2}>
                {buttonList.map(({ icon, ...props }, i) => (
                  <IconButton key={i} color='primary' size='large' {...props}>
                    {icon}
                  </IconButton>
                ))}
              </Stack>
            </FieldsRow>
            <Table
              data={tourplanConfigurationData || []}
              initialState={{
                columns: storageHelper.local.getItem('editPrintList.tourplansColumnModel'),
              }}
              onChangeColumnModel={(v) =>
                storageHelper.local.setItem('editPrintList.tourplansColumnModel', v)
              }
              rowSelectionModel={selectedRow?.id || undefined}
              onRowClick={(v) => setSelectedRow(v.row)}
              height={400}
              {...{ viewRows }}
              onRowsCountChange={(rows, prevRows, apiRef) => {
                if (rows.length > prevRows.length) {
                  const newRow = rows?.[rows.length - 1];
                  if (newRow && !newRow['categoryName']) {
                    apiRef?.current.startCellEditMode({
                      id: newRow.id as string,
                      field: 'categoryName',
                    });
                  }
                }
              }}
            >
              <Column
                field='categoryName'
                headerName={t('production.group_name')}
                sortable={false}
                flex={1}
                editable
                renderEditCell={({ id, ...params }) => (
                  <FormTableTextField
                    name={`tourplanConfigurationData.${params.api.getRowIndexRelativeToVisibleRows(
                      id,
                    )}.categoryName`}
                    autoFocus
                    onChange={(v) => {
                      params.api.setEditCellValue({
                        id,
                        field: params.field,
                        value: v,
                      });
                    }}
                  />
                )}
                renderCell={({ row, api }) => (
                  <SStack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    width='100%'
                  >
                    <SValue title={row?.categoryName}>{row?.categoryName}</SValue>
                    <IconButton
                      className='button'
                      title={t('common.settings')}
                      sx={{ p: '3.5px' }}
                      size='small'
                      onClick={() => {
                        tourplansCaregoryFilterPopup.stream.emit('open', {
                          values: {
                            row,
                            index: api.getRowIndexRelativeToVisibleRows(row.id),
                          },
                        });
                      }}
                    >
                      <SettingsIcon />
                    </IconButton>
                  </SStack>
                )}
              />
              <Footer>
                <SCustomFooter>{t('common.max')}: 10</SCustomFooter>
              </Footer>
            </Table>
          </Stack>
          <Stack mt={3} direction='row' justifyContent='flex-end' spacing={2}>
            <Button onClick={onApply} variant='contained' sx={{ width: 200 }}>
              {t('common.save')}
            </Button>
          </Stack>
          <tourplansCaregoryFilterPopup.Component />
        </Wrapper>
      );
    };
  }
}

const FormTableTextField = styled(FormTextField)({
  width: '100%',
  '& fieldset': {
    border: 'none',
  },
  '& input': {
    fontSize: 14,
  },
});

const SCustomFooter = styled(SFooter)({
  justifyContent: 'end',
});

type TTourplansSettings = EditPrintListGeneralTabRes['tourplanConfigurationData'][number] & {
  id: string;
};

interface ITourplansPopup {
  action: TPopupDefaultActions;
  onApply: () => void;
  values?: {
    articleListsOptions: DictArticleListsRes;
  };
}

interface IButtonList {
  title: string;
  icon: ReactNode;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
}
