import {
  Observable,
  delay,
  filter,
  finalize,
  forkJoin,
  map,
  of,
  repeat,
  switchMap,
  take,
  takeWhile,
  tap,
  zip,
  zipWith,
} from 'rxjs';
import {
  C_Pdf_Document_Type,
  C_Win_Report_Task_Status,
  Create_R10_CustomersDebtNotifierMutation,
  Create_R10_CustomersDebtNotifierMutationVariables,
  Create_R24_PaymentJournalA4Mutation,
  Create_R24_PaymentJournalA4MutationVariables,
  Create_R35_ExtendedPaymentJournalMutation,
  Create_R35_ExtendedPaymentJournalMutationVariables,
  GetTasksStatusQuery,
  ListWa_ReportsQueryVariables,
  Wa_MergePdFsMutation,
  Wa_MergePdFsMutationVariables,
} from '../../../graphql/generatedModel';
import { Pub, Service, Sub } from '../../../shared/services/service.abstract';
import { gqlClient } from '../../../graphql/graphqlRequest';
import { r10, r24, r35 } from './gql/debtReports.gql';
import { getTasksStatus } from '../../production/services/gql/winReports.gql';
import { ListWAReportRes, reportsService } from '../../../shared/services/reports/reports.service';
import { mergePDFs } from '../../../shared/services/commonRequests/gql/commonRequests.gql';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';

class PubImpl extends Pub<Actions> {
  getR10Notifier(params: Create_R10_CustomersDebtNotifierMutationVariables) {
    this.emit('getR10Notifier', params);
  }
  getMultipleR10Notifier(params: Array<Create_R10_CustomersDebtNotifierMutationVariables>) {
    this.emit('getMultipleR10Notifier', params);
  }
  getR64NotifierFiles(params: IGetR64NotifierFilesParams) {
    this.emit('getR64NotifierFiles', params);
  }
  getMultipleR64NotifierFiles(params: IGetMultipleR64NotifierFilesParams) {
    this.emit('getMultipleR64NotifierFiles', params);
  }
  getR24PaymentJournal(params: Create_R24_PaymentJournalA4MutationVariables) {
    this.emit('getR24PaymentJournal', params);
  }
  getR35ExtendedPaymentJournal(params: Create_R35_ExtendedPaymentJournalMutationVariables) {
    this.emit('getR35ExtendedPaymentJournal', params);
  }
  mergeFilesByCustomer(params: IMergeFilesByCustomerParams) {
    this.emit('mergeFilesByCustomer', params);
  }
  clearStream() {
    this.emit(undefined, {});
  }
}

class SubImpl extends Sub<Actions> {
  getR10Notifier(): Observable<GetR10NotifierRes> {
    return this.actionListener('getR10Notifier').pipe(
      this._queryR10Notifier(),
      finalize(() => debtReports.pub.clearStream()),
    );
  }

  getMultipleR10Notifier(): Observable<IGetMultipleR10NotifierRes> {
    return this.actionListener('getMultipleR10Notifier').pipe(
      switchMap(({ params }) => {
        const requests: Array<Observable<GetR10NotifierRequestRes & { customerId: string }>> =
          params.map((gqlVariables: Create_R10_CustomersDebtNotifierMutationVariables) =>
            gqlClient(r10, gqlVariables).pipe(
              map(
                (res: Create_R10_CustomersDebtNotifierMutation) =>
                  res.wawiAssist?.create_r10_CustomersDebtNotifier,
              ),
              map((res) => ({ ...res, customerId: gqlVariables.params.customerId })),
            ),
          );
        return forkJoin(requests);
      }),
      delay(5000),
      switchMap((res) => {
        const tasksId = res.map((item) => item.taskId);

        return this._taskStatusPolling({ tasksId, maxPollingAttempts: 20 }).pipe(
          map((v) => {
            const items = v.map((item) => {
              const isReady: boolean = item.status === C_Win_Report_Task_Status.WRTS2_READY;
              const generateResItem = res.find((it) => it.taskId === item.taskId);
              if (!isReady || !generateResItem) {
                throw new Error();
              }
              return generateResItem;
            });

            return items;
          }),
        );
      }),
      finalize(() => debtReports.pub.clearStream()),
    );
  }

  getR64NotifierFiles(): Observable<IGetR64NotifierFiles> {
    return this.actionListener('getR64NotifierFiles').pipe(
      map((v) => {
        const { r10NotifierParams, listInvoicesReportParams } =
          v.params as IGetR64NotifierFilesParams;
        reportsService.pub.listWAReports(listInvoicesReportParams);
        v.params = r10NotifierParams;
        return v;
      }),
      this._queryR10Notifier(),
      zipWith(
        reportsService.sub
          .safeListWAReports()
          .pipe(responseHandler<ListWAReportRes>({ errorReturnType: {} })),
      ),
      map((v) => {
        const notifierS3Key = v?.[0]?.s3Key;
        const invoicesReport = v?.[1];
        if (!notifierS3Key || !invoicesReport?.mergedFile?.s3Key) {
          throw new Error();
        }
        return {
          notifierS3Key,
          invoice: {
            s3Key: invoicesReport.mergedFile.s3Key,
            bucket: invoicesReport.mergedFile.bucket,
            isExternalBucket: false,
          },
        };
      }),
      finalize(() => debtReports.pub.clearStream()),
    );
  }

  getMultipleR64NotifierFiles(): Observable<IGetMultipleR64NotifierFilesRes> {
    return this.actionListener('getMultipleR64NotifierFiles').pipe(
      tap((v) => {
        const params: IGetMultipleR64NotifierFilesParams = v.params;
        const invoicesId = Object.values(params).reduce<Array<string>>((outArr, item) => {
          return [...outArr, ...item.invoiceIds];
        }, []);
        reportsService.pub.listWAReports({
          params: {
            dataRecordId: invoicesId,
            reportType: C_Pdf_Document_Type.PDT2_INVOICE,
            mergeFiles: false,
          },
        });
      }),
      switchMap((v) => {
        const params = v.params as IGetMultipleR64NotifierFilesParams;
        const r10Requests = Object.values(params).map(({ r10NotifierParams }) =>
          gqlClient(r10, r10NotifierParams).pipe(
            map(
              (res: Create_R10_CustomersDebtNotifierMutation) =>
                res.wawiAssist?.create_r10_CustomersDebtNotifier as GetR10NotifierRequestRes,
            ),
            map((res) => ({ ...res, customerId: r10NotifierParams.params.customerId })),
          ),
        );
        return zip(
          forkJoin(r10Requests),
          reportsService.sub
            .safeListWAReports()
            .pipe(responseHandler<ListWAReportRes>({ errorReturnType: {} })),
          of(params),
        );
      }),
      delay(5000),
      switchMap((res) => {
        const [notifiersRes, invoicesRes, params] = res || [];
        const tasksId = notifiersRes?.map((item) => item.taskId);

        return this._taskStatusPolling({ tasksId, maxPollingAttempts: 20 }).pipe(
          map((v) => {
            const items = v.map((item) => {
              const isReady: boolean = item.status === C_Win_Report_Task_Status.WRTS2_READY;
              const generateResItem = res?.[0]?.find((it) => it.taskId === item.taskId);

              if (!isReady || !generateResItem) {
                throw new Error();
              }
              const { s3Key, customerId } = generateResItem || {};
              const customerInvoicesId = params[customerId]?.invoiceIds || [];
              const invoices =
                invoicesRes.reports?.reduce<IInvoicesReport>((outArr, invoiceReport) => {
                  const isInvoiceOfCustomer = !!customerInvoicesId.find(
                    (invoiceId) => invoiceId === invoiceReport.recordId,
                  );
                  const isExternalBucket = invoiceReport?.bucket !== 'wawi-dev';
                  if (isInvoiceOfCustomer) {
                    outArr.push({
                      s3Key: invoiceReport?.s3Key!,
                      bucket: invoiceReport?.bucket!,
                      isExternalBucket,
                    });
                  }
                  return outArr;
                }, []) || [];

              return { notifierS3Key: s3Key, invoices, customerId };
            });

            return items;
          }),
        );
      }),
      finalize(() => debtReports.pub.clearStream()),
    );
  }

  getR24PaymentJournal(): Observable<IGetR24PaymentJournalRes> {
    return this.actionListener('getR24PaymentJournal').pipe(
      switchMap(
        ({ params }) => gqlClient(r24, params) as Observable<Create_R24_PaymentJournalA4Mutation>,
      ),
      delay(2000),
      switchMap((res) => {
        const resData = res.wawiAssist?.create_r24_PaymentJournalA4;
        const { taskId } = resData || {};
        const nonSuccessRes = {
          taskId,
          url: null,
          s3Key: null,
        };
        if (!taskId) {
          return of(nonSuccessRes);
        }
        return this._taskStatusPolling({ tasksId: [taskId] }).pipe(
          map((v) => {
            if (v?.[0].status !== C_Win_Report_Task_Status.WRTS2_READY) {
              return nonSuccessRes;
            }
            return resData as IGetR24PaymentJournalRes;
          }),
        );
      }),
      finalize(() => debtReports.pub.clearStream()),
    );
  }

  getR35ExtendedPaymentJournal(): Observable<IGetR35ExtendedPaymentJournalRes> {
    return this.actionListener('getR35ExtendedPaymentJournal').pipe(
      switchMap(
        ({ params }) =>
          gqlClient(r35, params) as Observable<Create_R35_ExtendedPaymentJournalMutation>,
      ),
      delay(2000),
      switchMap((res) => {
        const resData = res.wawiAssist?.create_r35_ExtendedPaymentJournal;
        const { taskId } = resData || {};
        const nonSuccessRes = {
          taskId,
          url: null,
          s3Key: null,
        };
        if (!taskId) {
          return of(nonSuccessRes);
        }
        return this._taskStatusPolling({ tasksId: [taskId] }).pipe(
          map((v) => {
            if (v?.[0].status !== C_Win_Report_Task_Status.WRTS2_READY) {
              return nonSuccessRes;
            }
            return resData as IGetR35ExtendedPaymentJournalRes;
          }),
        );
      }),
      finalize(() => debtReports.pub.clearStream()),
    );
  }

  mergeFilesByCustomer(): Observable<IMergeFilesByCustomerRes> {
    return this.actionListener('mergeFilesByCustomer').pipe(
      switchMap((v) => {
        const params = v.params as IMergeFilesByCustomerParams;
        const mergeRequests = params.map(({ mergeItems, customerId }) =>
          (gqlClient(mergePDFs, { mergeItems }) as Observable<Wa_MergePdFsMutation>).pipe(
            map((res) => res?.wawiAssist?.WA_mergePDFs as Wa_MergePdFsMutationRes),
            map((res) => (res ? { ...res, customerId } : res)),
          ),
        );
        return forkJoin(mergeRequests);
      }),
      finalize(() => debtReports.pub.clearStream()),
    );
  }

  private _queryR10Notifier() {
    return (stream: Observable<any>): Observable<GetR10NotifierRes> =>
      stream.pipe(
        switchMap(
          ({ params }) =>
            gqlClient(r10, params) as Observable<Create_R10_CustomersDebtNotifierMutation>,
        ),
        delay(2000),
        switchMap((res) => {
          const resData = res.wawiAssist?.create_r10_CustomersDebtNotifier as GetR10NotifierRes;
          const { taskId } = resData || {};
          const nonSuccessRes = {
            taskId,
            url: null,
            s3Key: null,
          };
          if (!taskId) {
            return of(nonSuccessRes);
          }
          return this._taskStatusPolling({ tasksId: [taskId] }).pipe(
            map((v) => {
              if (v?.[0].status !== C_Win_Report_Task_Status.WRTS2_READY) {
                return nonSuccessRes;
              }
              return resData;
            }),
          );
        }),
      );
  }

  private _taskStatusPolling({
    tasksId,
    maxPollingAttempts = 10,
  }: {
    tasksId: Array<number>;
    maxPollingAttempts?: number;
  }): Observable<GetWinReportsStatusRes> {
    return (gqlClient(getTasksStatus, { tasksId }) as Observable<GetTasksStatusQuery>).pipe(
      repeat({ delay: 2000 }),
      map((v) => v.wawiAssist?.getWinReportsStatus as GetWinReportsStatusRes),
      takeWhile((v) => {
        return (
          v?.every(
            (item) =>
              item.status === C_Win_Report_Task_Status.WRTS5_IN_PROGRESS ||
              item.status === C_Win_Report_Task_Status.WRTS1_NOT_STARTED,
          ),
          true
        );
      }),
      filter((v, ind) => {
        const isInProcess = v?.some(
          (item) =>
            item.status === C_Win_Report_Task_Status.WRTS5_IN_PROGRESS ||
            item.status === C_Win_Report_Task_Status.WRTS1_NOT_STARTED,
        );
        const maxAttemptsReached = ind + 1 > maxPollingAttempts;
        if (!isInProcess) {
          return true;
        }
        // Limit polling by maximum number of attempts
        return maxAttemptsReached;
      }),
      take(1),
    );
  }
}

class DebtReports extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const debtReports = new DebtReports();

type Actions =
  | 'getR10Notifier'
  | 'getMultipleR10Notifier'
  | 'getR64NotifierFiles'
  | 'getMultipleR64NotifierFiles'
  | 'getR24PaymentJournal'
  | 'getR35ExtendedPaymentJournal'
  | 'mergeFilesByCustomer'
  | undefined;

type GetR10NotifierRequestRes = NonNullable<
  NonNullable<
    Create_R10_CustomersDebtNotifierMutation['wawiAssist']
  >['create_r10_CustomersDebtNotifier']
>;
export interface GetR10NotifierRes extends Omit<GetR10NotifierRequestRes, 'url' | 's3Key'> {
  url: string | null;
  s3Key: string | null;
}

export type IGetMultipleR10NotifierRes = Array<GetR10NotifierRes & { customerId: string }>;
type GetWinReportsStatusRes = NonNullable<
  NonNullable<GetTasksStatusQuery['wawiAssist']>['getWinReportsStatus']
>;
interface IGetR64NotifierFilesParams {
  r10NotifierParams: Create_R10_CustomersDebtNotifierMutationVariables;
  listInvoicesReportParams: ListWa_ReportsQueryVariables;
}
export interface IGetR64NotifierFiles {
  notifierS3Key: string;
  invoice: {
    s3Key: string;
    bucket: string;
    isExternalBucket: boolean;
  };
}
export interface IGetMultipleR64NotifierFilesParams {
  [customerId: string]: {
    r10NotifierParams: Create_R10_CustomersDebtNotifierMutationVariables;
    invoiceIds: Array<string>;
  };
}
type IInvoicesReport = Array<{
  s3Key: string;
  bucket: string;
  isExternalBucket: boolean;
}>;
export type IGetMultipleR64NotifierFilesRes = Array<{
  customerId: string;
  notifierS3Key: string;
  invoices: IInvoicesReport;
}>;
export type IMergeFilesByCustomerParams = Array<
  Wa_MergePdFsMutationVariables & { customerId: string }
>;
type Wa_MergePdFsMutationRes = NonNullable<
  NonNullable<Wa_MergePdFsMutation['wawiAssist']>['WA_mergePDFs']
>;
type IMergeFilesByCustomerRes = Array<
  Wa_MergePdFsMutationRes & {
    customerId: string;
  }
>;
export interface IGetR24PaymentJournalRes
  extends Omit<Create_R24_PaymentJournalA4Mutation, 'url' | 's3Key'> {
  url: string | null;
  s3Key: string | null;
}
export interface IGetR35ExtendedPaymentJournalRes
  extends Omit<Create_R35_ExtendedPaymentJournalMutation, 'url' | 's3Key'> {
  url: string | null;
  s3Key: string | null;
}
