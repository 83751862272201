import { TypeTable } from '../../../../table/table.component.tsx';
import { Theme as theme } from '../../../../../styles/theme/theme.ts';
import { amber } from '@mui/material/colors';

export const viewRows: TypeTable['viewRows'] = {
  action: ({ row }) => {
    if (!row.senderAddress) {
      return 'empty-row';
    }
    return 'row';
  },
  styles: [
    {
      className: 'row',
      rowStyles: {
        '&.MuiDataGrid-row.Mui-selected': {
          backgroundColor: `${theme.palette.blue.light}80!important`,
          '& .MuiDataGrid-cell': {
            borderColor: 'transparent!important',
          },
        },
      },
    },
    {
      className: 'empty-row',
      rowStyles: {
        '&.MuiDataGrid-row': {
          backgroundColor: `${amber['50']}!important`,
        },
        '&.MuiDataGrid-row.Mui-selected': {
          backgroundColor: `${amber['50']}!important`,
          '& .MuiDataGrid-cell': {
            borderColor: 'transparent!important',
          },
        },
      },
    },
  ],
};
