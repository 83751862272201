import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CircularProgress, InputAdornment, Stack, styled } from '@mui/material';
import { AlternateEmail, Fax, Language, Phone, PhoneIphone } from '@mui/icons-material';
import { useLoaderData } from 'react-router-dom';

import {
  FieldsRow,
  FieldsSection,
  FormCheckbox,
  FormSelect,
  FormTextField,
  SelectMenuWithFooter,
} from '../../../../../../shared/components/form';
import { C_Customer_Address_Kind } from '../../../../../../graphql/generatedModel';
import { CustomerGeneralDataRes } from '../../../../services/customer.service';
import { countries } from '../../../../utils/countries';
import { TMode } from '../../../../../../shared/services/mode/mode.service';
import { ITabState } from '../../../../states/tabState.model';
import { generalTabState } from '../generalTab.state.ts';
import { resetCustomerDeps } from '../../../../states/customersList.state.ts';
import {
  IServerValidation,
  ServerValidation,
} from '../../../../../../shared/components/serverValidation/serverValidation.component.tsx';

export const GeneralForm: FC<IGeneralForm> = ({ mode, loading, handleSubmitValue }) => {
  const { t } = useTranslation();
  const {
    formState: { errors, dirtyFields },
    watch,
  } = useFormContext();

  const { generalTabOptions } = useLoaderData() as ITabState;

  const addressType = watch('customerAddressKindId');
  const countriesOptions = useMemo(
    () => countries.map((country) => ({ id: country, label: country })),
    [countries],
  );

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    const fieldName = e.target.name as keyof CustomerGeneralDataRes;
    if (!errors[fieldName] && !!dirtyFields[fieldName] && fieldName !== 'customerNo') {
      if (mode === 'edit') {
        generalTabState.pub.save();
      }
    }
  };

  return (
    <form {...(mode === 'edit' && { onBlur: onSubmit })} noValidate>
      <FieldsRow>
        <ServerValidation
          fieldName='customerNo'
          allowEmpty={false}
          handleSubmitValue={handleSubmitValue}
          renderItem={({ fieldName, errors, setIsFocused }) => (
            <FormTextField
              width='220px'
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              name={fieldName}
              label={t('common.customer_no')}
              required
              error={Boolean(errors[fieldName])}
              helperText={errors[fieldName] ? t(String(errors[fieldName]!.message)) : ''}
              InputProps={{
                endAdornment: loading && (
                  <InputAdornment position='end'>
                    <CircularProgress size={20} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <FormCheckbox
          name='isActive'
          label={t('customer.customer_active')}
          onChange={() => resetCustomerDeps()}
        />
        {mode === 'create' && generalTabOptions!.customerDefaultProfiles!.length > 1 && (
          <FormSelect
            name='customerProfileId'
            label={t('customer.select_default_setting')}
            options={(generalTabOptions.customerDefaultProfiles as []) || []}
            disableClearable
            PaperComponent={(props) => (
              <SelectMenuWithFooter {...props} href='/settings/configuration/customer' />
            )}
          />
        )}
      </FieldsRow>
      <FieldsRow>
        <FormSelect
          controllerProps={{ rules: { deps: ['name', 'surname'] } }}
          name='customerAddressKindId'
          label={t('customer.type_of_address')}
          options={Object.values(C_Customer_Address_Kind).map((key) => ({
            id: key,
            label: t(`enums.${key}`),
          }))}
          disableClearable
        />
        <FormSelect
          name='customerGroupId'
          label={t('customer.group')}
          options={(generalTabOptions?.customerGroups as []) || []}
          PaperComponent={(props) => (
            <SelectMenuWithFooter {...props} href='/settings/configuration/customer' />
          )}
        />
        <FormSelect
          name='deliveryNoteGroupId'
          label={t('customer.delivery_note_group')}
          options={(generalTabOptions?.deliveryNoteGroups as []) || []}
          PaperComponent={(props) => (
            <SelectMenuWithFooter {...props} href='/settings/configuration/customer' />
          )}
        />
      </FieldsRow>

      <Stack direction='row' flexWrap='wrap' useFlexGap spacing={5}>
        <FieldsSection title={t('customer.address')}>
          <Stack direction='column' sx={{ minWidth: '350px' }} spacing={2}>
            <FormTextField name='salutation' label={t('customer.salutation')} width='100%' />
            <FormTextField
              width='100%'
              name='name'
              label={t('common.name')}
              controllerProps={{ rules: { deps: ['internalName'] } }}
              {...(errors.name && {
                error: true,
                helperText: t(errors.name.message as string),
              })}
            />
            {addressType === C_Customer_Address_Kind.CAK3_PRIVATE_PERSON && (
              <FormTextField
                width='100%'
                name='surname'
                label={t('customer.surname')}
                {...(errors.surname && {
                  error: true,
                  helperText: t(errors.surname.message as string),
                })}
              />
            )}
            <FormTextField name='addition' label={t('customer.additive')} width='100%' />

            <FormTextField name='street' label={t('customer.street')} width='100%' />
            <FormTextField name='postBox' label={t('customer.post_box')} width='100%' />
            <ColumnWrapper>
              <FormTextField name='zipCode' label={t('customer.zip_code')} width='100px' />
              <FormTextField name='city' label={t('customer.city')} width='100%' />
            </ColumnWrapper>
            <FormSelect
              name='country'
              label={t('customer.country')}
              freeSolo
              options={countriesOptions}
              width='100%'
            />
            <FormTextField
              width='100%'
              name='internalName'
              label={t('customer.internal_name')}
              controllerProps={{ rules: { deps: ['name'] } }}
            />
          </Stack>
        </FieldsSection>

        {addressType === C_Customer_Address_Kind.CAK2_COMPANY_WITH_DELIVERY_ADDRESS && (
          <FieldsSection title={t('customer.delivery_address')}>
            <Stack direction='column' sx={{ minWidth: '350px' }} spacing={2}>
              <FormTextField
                name='deliveryAddressSalutation'
                label={t('customer.salutation')}
                width='100%'
              />
              <FormTextField name='deliveryAddressName' label={t('common.name')} width='100%' />
              <FormTextField
                name='deliveryAddressAddition'
                label={t('customer.additive')}
                width='100%'
              />
              <FormTextField
                name='deliveryAddressStreet'
                label={t('customer.street')}
                width='100%'
              />
              <FormTextField
                name='deliveryAddressPostBox'
                label={t('customer.post_box')}
                width='100%'
              />
              <ColumnWrapper>
                <FormTextField
                  name='deliveryAddressZipCode'
                  label={t('customer.zip_code')}
                  width='100px'
                />

                <FormTextField name='deliveryAddressCity' label={t('customer.city')} width='100%' />
              </ColumnWrapper>
              <FormSelect
                name='deliveryAddressCountry'
                label={t('customer.country')}
                freeSolo
                options={countriesOptions}
                width='100%'
              />
            </Stack>
          </FieldsSection>
        )}

        <FieldsSection title={t('customer.contacts')}>
          <Stack direction='column' sx={{ minWidth: '350px' }} spacing={2}>
            <FormTextField
              name='phoneBusiness'
              label={t('customer.phone_business')}
              width='100%'
              InputProps={{ endAdornment: <Phone sx={{ color: 'rgba(0, 0, 0, 0.54)' }} /> }}
            />
            <FormTextField
              name='phoneBusiness2'
              label={t('customer.phone_business') + ' II'}
              width='100%'
              InputProps={{ endAdornment: <Phone sx={{ color: 'rgba(0, 0, 0, 0.54)' }} /> }}
            />
            <FormTextField
              name='phonePrivate'
              label={t('customer.phone_private')}
              width='100%'
              InputProps={{ endAdornment: <Phone sx={{ color: 'rgba(0, 0, 0, 0.54)' }} /> }}
            />
            <FormTextField
              name='fax'
              label={t('customer.fax')}
              width='100%'
              InputProps={{ endAdornment: <Fax sx={{ color: 'rgba(0, 0, 0, 0.54)' }} /> }}
            />
            <FormTextField
              name='phoneMobile'
              label={t('customer.mobile')}
              width='100%'
              InputProps={{ endAdornment: <PhoneIphone sx={{ color: 'rgba(0, 0, 0, 0.54)' }} /> }}
            />
            <FormTextField
              name='email'
              label={t('common.email')}
              width='100%'
              InputProps={{
                endAdornment: <AlternateEmail sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
              }}
              {...(errors.email && {
                error: true,
                helperText: t(errors.email.message as string),
              })}
            />
            <FormTextField
              name='emailForInvoice'
              label={t('customer.email_for_invoices')}
              width='100%'
              InputProps={{
                endAdornment: <AlternateEmail sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
              }}
              {...(errors.emailForInvoice && {
                error: true,
                helperText: t(errors.emailForInvoice.message as string),
              })}
            />
            <FormTextField
              name='website'
              label={t('customer.website')}
              width='100%'
              InputProps={{ endAdornment: <Language sx={{ color: 'rgba(0, 0, 0, 0.54)' }} /> }}
            />

            <FormTextField name='contactPerson' label={t('customer.contact_person')} width='100%' />
          </Stack>
        </FieldsSection>
      </Stack>
    </form>
  );
};

const ColumnWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  width: '100%',
  gap: '16px',
});

interface IGeneralForm extends Pick<IServerValidation, 'handleSubmitValue'> {
  loading: boolean;
  mode: TMode;
}
