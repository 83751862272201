import { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { TaxesByDateRes } from '../../../../services/order.service.ts';
import { Box, CircularProgress } from '@mui/material';
import { SStack, SValue } from '../product/product.cell.tsx';

export const VatCell: FC<IVatCell> = ({ params, taxes = [], loading }) => {
  const value = taxes?.find((el) => el?.id === params.value);
  return (
    <SStack>
      <SValue>{value?.label}</SValue>
      {loading.includes(`${params?.id}_${params.field}`) && (
        <Box sx={{ display: 'flex', p: '3.5px' }}>
          <CircularProgress size={16} thickness={5} />
        </Box>
      )}
    </SStack>
  );
};

interface IVatCell {
  params: GridRenderCellParams;
  taxes: TaxesByDateRes;
  loading: string[];
}
