import { GridSortModel } from '@mui/x-data-grid-premium';
import {
  CreateInvoiceRes,
  CreatePartialInvoiceRes,
  CustomerDataRes,
  GetCustomersDetailsRes,
  OrdersForMultipay,
  OrdersRes,
  PositionsRes,
  createInvoiceService,
} from '../../services/createInvoice.service';
import { ICustomerListState, customerListState } from './customerList.state';
import { Pub, State, Sub } from '../../../../shared/state/state.abstract';
import {
  BehaviorSubject,
  Observable,
  concatMap,
  filter,
  from,
  map,
  merge,
  of,
  share,
  switchMap,
  take,
  tap,
  zip,
} from 'rxjs';
import i18n from 'i18next';
import { snackbarService } from '../../../../shared/components/snackbar/service/snackbar.service';
import { format } from 'date-fns';
import { storageHelper } from '../../../../shared/helpers/storage';
import { dataHelper } from '../../../../shared/helpers/data/data.helper';
import { CreateInvoiceMutationVariables } from '../../../../graphql/generatedModel';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler';
import {
  configsData,
  DictTextBlocksRes,
} from '../../../../shared/services/configsData/configsData.service';

const dateFromStorage = storageHelper.session.getItem('createInvoice.invoiceDate');

export const initInvoiceDetailsState: IInvoiceDetailsState = {
  action: undefined,
  customerInfo: undefined,
  isMultipayCustomer: false,
  invoiceDate: dateFromStorage ? new Date(dateFromStorage) : new Date(),
  orders: [],
  orderPositions: [],
  linkText: undefined,
  defaultLinkText: undefined,
  customerId: '',
  datePeriod: {
    fromDate: '',
    toDate: '',
  },
  orderSort: [{ field: 'orderDate', sort: 'asc' }],
  selectedOrder: undefined,
  isMultiselect: false,
  selectedIds: new Set(),
  selectedCustomersIds: new Set(),
};

class PubImpl extends Pub<IInvoiceDetailsState> {
  init(params: IInvoiceDetailsState) {
    this.emit('init', params);
  }
  clearStream() {
    this.emit(undefined, {});
  }
  updateInvoiceData(data: IUpdateInvoiceData) {
    this.emit('updateInvoiceData', { params: data });
  }
  createInvoice() {
    this.emit('createInvoice', {});
  }
  createMultiInvoice(closePopup: () => void) {
    this.emit('createMultiInvoice', { params: closePopup });
  }
  orderSort(sortModel: GridSortModel) {
    this.emit('orderSort', { orderSort: sortModel });
    storageHelper.local.setItem('createInvoice.orderSort', sortModel);
  }
  selectOrder(selectedOrder: IInvoiceDetailsState['selectedOrder']) {
    this.emit('selectOrder', { selectedOrder });
    storageHelper.session.setItem('createInvoice.selectedOrder', selectedOrder);
  }
  toggleMultiselect() {
    this.emit('toggleMultiselect', {});
  }
  setSelectedIds(selectedIds: IInvoiceDetailsState['selectedIds']) {
    this.emit('setSelectedIds', { selectedIds });
  }
  setSelectedCustomersIds(selectedCustomersIds: IInvoiceDetailsState['selectedCustomersIds']) {
    this.emit('setSelectedCustomersIds', { selectedCustomersIds });
  }
}

class SubImpl extends Sub<IInvoiceDetailsState> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());
  private isMultiCreateInProgress$ = new BehaviorSubject<boolean>(false);
  private shareIsMultiCreateInProgress$: Observable<boolean> = this.isMultiCreateInProgress$.pipe(
    share(),
  );
  private multiCreateProgress$ = new BehaviorSubject<number>(0);
  private shareMultiCreateProgress$: Observable<number> = this.multiCreateProgress$.pipe(share());
  private positionsLoading$ = new BehaviorSubject<boolean>(false);
  private sharePositionsLoading$: Observable<boolean> = this.positionsLoading$.pipe(share());
  private isCreating$ = new BehaviorSubject<boolean>(false);
  private shareIsCreating$: Observable<boolean> = this.isCreating$.pipe(share());
  protected actionHandlers(): Observable<IInvoiceDetailsState> {
    return merge(
      this.dataForSelectedCustomer(),
      this.updateInvoiceData(),
      this.createInvoice(),
      this.updateState(),
      this.selectOrder(),
      this.createMultiInvoice(),
      this.toggleMultiselect(),
    ).pipe(
      map((state) => {
        const { orders, orderSort } = state;
        const sortedOrders = dataHelper
          .data([...(orders || [])] as [])
          .sort({ sortModel: orderSort })
          .result() as IInvoiceDetailsState['orders'];
        const updatedState = {
          ...state,
          orders: sortedOrders,
        } as IInvoiceDetailsState;

        this.stream$.next({ ...updatedState, action: 'internalUpdate' });
        return updatedState;
      }),
      tap(
        ({ action }) =>
          action !== 'createInvoice' &&
          action !== 'createMultiInvoice' &&
          this.loading$.next(false),
      ),
    );
  }

  loading(): Observable<boolean> {
    return this.shareLoading$;
  }

  positionsLoading(): Observable<boolean> {
    return this.sharePositionsLoading$;
  }

  isMultiCreateInProgress(): Observable<boolean> {
    return this.shareIsMultiCreateInProgress$;
  }

  multiCreateProgress(): Observable<number> {
    return this.shareMultiCreateProgress$;
  }

  isCreating(): Observable<boolean> {
    return this.shareIsCreating$;
  }

  private updateState(): Observable<IInvoiceDetailsState> {
    return this.actionListener(['orderSort', 'setSelectedIds', 'setSelectedCustomersIds']);
  }

  private toggleMultiselect(): Observable<IInvoiceDetailsState> {
    return this.actionListener('toggleMultiselect').pipe(
      map((state) => {
        const { isMultiselect } = state;
        return {
          ...state,
          isMultiselect: !isMultiselect,
        };
      }),
    );
  }

  private selectOrder(): Observable<IInvoiceDetailsState> {
    return this.actionListener('selectOrder').pipe(
      tap(({ selectedOrder }) => {
        this.positionsLoading$.next(true);
        createInvoiceService.pub.positions({ orderId: selectedOrder?.orderId as string });
      }),
      switchMap((state) => {
        return createInvoiceService.sub.positions().pipe(
          responseHandler<IInvoiceDetailsState['orderPositions']>({
            customErrorHandler() {
              return 'invoice.error_order_position_not_loaded';
            },
            errorReturnType: [],
          }),
          map((orderPositions) => {
            this.positionsLoading$.next(false);
            return {
              ...state,
              orderPositions,
            };
          }),
        );
      }),
    );
  }

  private dataForSelectedCustomer(): Observable<IInvoiceDetailsState> {
    return customerListState.sub.state().pipe(
      filter(
        ({ action }) =>
          action === 'selectCustomer' ||
          action === 'getCustomers' ||
          action === 'removeSelectedCustomer',
      ),
      tap(() => this.loading$.next(true)),
      map(({ selectedCustomer, datePeriod, customerList }) => ({
        id: selectedCustomer?.customerId,
        datePeriod,
        customerList,
      })),
      switchMap(({ id, datePeriod, customerList }) => {
        if (!id) {
          return of({
            ...initInvoiceDetailsState,
            customerId: undefined,
            datePeriod,
            action: 'resetData',
          } as IInvoiceDetailsState);
        }
        const state = this.stream$.getValue();
        const { orderSort, selectedCustomersIds } = state;
        const linkText = id !== state?.customerId ? state?.defaultLinkText : state?.linkText;
        createInvoiceService.pub.queryData({ id, datePeriod, orderSort });
        const needResetCustomerIds = ![...selectedCustomersIds]?.every((id) =>
          customerList.some((customer) => customer.customerId === id),
        );

        return createInvoiceService.sub.queryData().pipe(
          responseHandler<Partial<IInvoiceDetailsState>>({
            errorReturnType: initInvoiceDetailsState,
          }),
          map(
            (data) =>
              ({
                ...state,
                ...data,
                customerId: id,
                datePeriod,
                linkText,
                selectedIds: new Set(),
                selectedCustomersIds: needResetCustomerIds
                  ? new Set()
                  : state?.selectedCustomersIds,
                action: 'dataForSelectedCustomer',
              } as IInvoiceDetailsState),
          ),
        );
      }),
    );
  }

  private updateInvoiceData(): Observable<IInvoiceDetailsState> {
    return this.actionListener('updateInvoiceData').pipe(
      map((state) => {
        const params = state?.params as IUpdateInvoiceData;
        const { note, ...rest } = params || {};
        return {
          ...state,
          ...rest,
          customerInfo: {
            ...state.customerInfo,
            defaultNoteInInvoice: note,
          },
        } as IInvoiceDetailsState;
      }),
    );
  }

  private hideLoadings() {
    this.isCreating$.next(false);
    this.positionsLoading$.next(false);
    snackbarService.pub.hide('invoiceCreateLoading');
  }

  private createMultiInvoice(): Observable<IInvoiceDetailsState> {
    return this.actionListener('createMultiInvoice').pipe(
      switchMap(() => {
        const state = this.stream$.getValue();
        const {
          selectedCustomersIds,
          customerId: selectedCustomerId,
          invoiceDate,
          datePeriod,
          customerInfo: infoForSelectedCustomer,
          linkText: linkTextForSelectedCustomer,
          params: closePopup,
        } = state;
        const { defaultNoteInInvoice: noteForSelectedCustomer } = infoForSelectedCustomer || {};

        configsData.pub.common(['dictTextBlocks']);
        createInvoiceService.pub.getCustomersDetails({ ids: Array.from(selectedCustomersIds) });

        return zip(
          configsData.sub.dictTextBlocks(),
          createInvoiceService.sub.getCustomersDetails(),
        ).pipe(
          responseHandler<[DictTextBlocksRes, GetCustomersDetailsRes]>({
            errorReturnType: [[], []],
          }),
          take(1),
          map(([textList, customersDetails]) => {
            const linkText = textList?.find((v) => v?.isDefaultForInvoice)?.id;
            return { customersDetails, linkText };
          }),
          switchMap(({ customersDetails, linkText }) => {
            this.isMultiCreateInProgress$.next(true);
            return from(customersDetails).pipe(
              concatMap((customerInfo, ind) => {
                const progress = Math.floor(((ind + 1) / customersDetails?.length) * 100);
                this.multiCreateProgress$.next(progress);
                const { customerId, defaultNoteInInvoice } = customerInfo || {};
                const requestArgs = {
                  customerId,
                  date: invoiceDate && format(invoiceDate, 'yyyy-MM-dd'),
                  ordersStartDate: datePeriod?.fromDate,
                  ordersEndDate: datePeriod?.toDate,
                  note: defaultNoteInInvoice,
                  linkText,
                  isPaidByCash: false,
                } as CreateInvoiceMutationVariables;
                const isSelectedCustomer = customerId === selectedCustomerId;
                if (isSelectedCustomer) {
                  requestArgs.note = noteForSelectedCustomer;
                  requestArgs.linkText = linkTextForSelectedCustomer;
                }
                createInvoiceService.pub.createInvoice(requestArgs);

                return createInvoiceService.sub.createInvoice().pipe(
                  responseHandler<CreateInvoiceRes>({
                    customErrorHandler: () => {
                      snackbarService.pub.show({
                        type: 'error',
                        content: i18n.t('common.invoice_with_id_not_saved', { id: customerId }),
                      });
                      return undefined;
                    },
                    errorReturnType: undefined,
                  }),
                  filter((data) => !!data),
                  tap(() => {
                    const lastInvoice = ind + 1 === customersDetails?.length;
                    if (lastInvoice) {
                      this.isMultiCreateInProgress$.next(false);
                      this.multiCreateProgress$.next(0);
                      snackbarService.pub.show({
                        content: `${i18n.t('invoice.number_of_created_invoices')}: ${
                          customersDetails?.length
                        }`,
                        type: 'success',
                      });
                      const idsForDelete = customersDetails?.map(({ customerId }) => customerId);
                      customerListState.pub.removeCustomersById(idsForDelete);
                      closePopup();
                    }
                  }),
                );
              }),
            );
          }),
        );
      }),
      map(() => {
        const state = this.stream$.getValue();
        const isFinalize = this.isMultiCreateInProgress$.value === false;
        if (isFinalize) {
          return {
            ...state,
            selectedCustomersIds: new Set(),
            action: 'createMultiInvoice',
          } as IInvoiceDetailsState;
        }
        return state;
      }),
    );
  }

  private createInvoice(): Observable<IInvoiceDetailsState> {
    return this.actionListener('createInvoice').pipe(
      map((state) => {
        const {
          customerId,
          invoiceDate,
          datePeriod,
          customerInfo,
          linkText,
          isMultiselect,
          selectedIds,
          selectedOrder,
          orders,
        } = state;
        const { defaultNoteInInvoice } = customerInfo || {};
        const isPartialInvoice = isMultiselect && [...selectedIds]?.length;
        if (customerId) {
          this.isCreating$.next(true);
          snackbarService.pub.show({
            id: 'invoiceCreateLoading',
            content: i18n.t('invoice.creating_invoice'),
            type: 'loading',
            noAutoHide: true,
          });
          const queryParams = {
            customerId,
            date: invoiceDate && format(invoiceDate, 'yyyy-MM-dd'),
            ordersStartDate: datePeriod?.fromDate,
            ordersEndDate: datePeriod?.toDate,
            note: defaultNoteInInvoice,
            linkText: linkText ? linkText.toString() : null,
            isPaidByCash: false,
          } as CreateInvoiceMutationVariables;
          if (isPartialInvoice) {
            const selectedIdsSet = new Set(selectedIds);
            const allIdsSelected = orders.every((order) =>
              selectedIdsSet.has(order?.orderId as string),
            );
            if (!allIdsSelected) {
              queryParams.manuallySelectedOrdersIds = [...selectedIds];
              const ordersRest = orders.filter(
                (order) => !selectedIdsSet.has(order?.orderId as string),
              );
              const newSelectedOrder = ordersRest?.find(
                (order) => order?.orderId === selectedOrder?.orderId,
              )
                ? selectedOrder
                : ordersRest?.[0];
              if (newSelectedOrder?.orderId === selectedOrder?.orderId) {
                createInvoiceService.pub.createInvoice(queryParams);
                return {
                  ...state,
                  orders: ordersRest,
                  params: {
                    isSameOrder: true,
                  },
                };
              } else {
                this.positionsLoading$.next(true);
                createInvoiceService.pub.createPartialInvoice({
                  createParams: queryParams,
                  positionParams: { orderId: newSelectedOrder!.orderId },
                });
                return {
                  ...state,
                  orders: ordersRest,
                  selectedOrder: newSelectedOrder,
                };
              }
            }
          }
          createInvoiceService.pub.createInvoice(queryParams);
          return state;
        }
      }),
      switchMap((stateData) => {
        let state = stateData as IInvoiceDetailsState;
        const { isSameOrder } = (state?.params || {}) as { isSameOrder: boolean };

        if (this.positionsLoading$.value) {
          return createInvoiceService.sub.createPartialInvoice().pipe(
            tap(() => this.hideLoadings()),
            responseHandler<CreatePartialInvoiceRes>({
              customErrorHandler: () => {
                this.hideLoadings();
                return 'invoice.invoice_not_saved';
              },
              errorReturnType: undefined,
            }),
            filter((data) => !!data),
            map((data) => {
              const { invoiceId, positions } = data || {};
              snackbarService.pub.show({
                content: `${i18n.t('invoice.invoice_no')} ${invoiceId}`,
                type: 'success',
              });

              return {
                ...state,
                selectedIds: new Set(),
                orderPositions: positions,
              } as IInvoiceDetailsState;
            }),
          );
        }

        return createInvoiceService.sub.createInvoice().pipe(
          tap(() => this.hideLoadings()),
          responseHandler<CreateInvoiceRes>({
            customErrorHandler: () => {
              this.hideLoadings();
              return 'invoice.invoice_not_saved';
            },
            errorReturnType: undefined,
          }),
          filter((data) => !!data),
          map((data) => {
            const id = data?.invoiceId;
            snackbarService.pub.show({
              content: `${i18n.t('invoice.invoice_no')} ${data?.invoiceNo}`,
              type: 'success',
            });
            if (isSameOrder) {
              return {
                ...state,
                selectedIds: new Set(),
                params: null,
              } as IInvoiceDetailsState;
            }
            if (id) {
              this.loading$.next(true);
              customerListState.pub.removeSelectedCustomer();
            }
            state = this.stream$.getValue();
            return { ...state, selectedIds: new Set() } as IInvoiceDetailsState;
          }),
        );
      }),
    );
  }
}

class InvoiceDetailsState extends State<IInvoiceDetailsState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const invoiceDetailsState = new InvoiceDetailsState(initInvoiceDetailsState);

export interface IUpdateInvoiceData extends Pick<IInvoiceDetailsState, 'invoiceDate' | 'linkText'> {
  note: NonNullable<IInvoiceDetailsState['customerInfo']>['defaultNoteInInvoice'];
}

export interface ITextForSnackbar {
  success: string;
  error: string;
  loading: string;
}

export interface IInvoiceDetailsState {
  action:
    | 'init'
    | 'loader'
    | 'dataForPeriod'
    | 'updateInvoiceData'
    | 'internalUpdate'
    | 'dataForSelectedCustomer'
    | 'selectOrder'
    | 'createInvoice'
    | 'createMultiInvoice'
    | 'orderSort'
    | 'selectOrder'
    | 'customerInfo'
    | 'setSelectedIds'
    | 'setSelectedCustomersIds'
    | 'resetData'
    | 'toggleMultiselect'
    | undefined;
  customerInfo?: CustomerDataRes;
  invoiceDate?: Date;
  orders: OrdersRes | OrdersForMultipay;
  orderPositions: PositionsRes;
  isMultipayCustomer: boolean;
  linkText?: string;
  defaultLinkText?: string;
  customerId?: string;
  datePeriod: ICustomerListState['datePeriod'];
  orderSort: GridSortModel;
  isMultiselect: boolean; // used for multiselect in Orders table
  selectedIds: Set<string>; // used for multiselect in Orders table
  selectedCustomersIds: Set<string>;
  selectedOrder?: OrdersRes[number];
  multiSelectId?: string;
  params?: any;
}
