import { Controller, ControllerProps } from 'react-hook-form';
import { NumericField, TNumericFieldProps } from '../fields/numericField.component.tsx';
import { FC } from 'react';

export const FormNumericField: FC<TFormNumericFieldProps> = ({
  name,
  onBlur,
  onChange,
  defaultValue,
  controllerProps,
  ...props
}) => {
  return (
    <Controller
      {...controllerProps}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { value, onChange: onFieldChange, onBlur: onFieldBlur, ...restField },
      }) => {
        return (
          <NumericField
            {...props}
            value={value}
            onChange={(e) => {
              onFieldChange(props.returnZero ? e : e || null);
              onChange?.(e);
            }}
            onBlur={(e) => {
              onFieldBlur(); // notify react-hook-form when input is touched/blur
              onBlur?.(props.returnZero ? e : e || null);
            }}
            {...restField}
          />
        );
      }}
    />
  );
};

type TFormNumericFieldProps = Omit<TNumericFieldProps, 'value'> & {
  name: ControllerProps['name'];
  controllerProps?: Omit<ControllerProps, 'name' | 'render'>;
};
