import { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldsSection, FormCheckbox } from '../../../../../../shared/components/form/index.ts';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import { configurationTabState } from '../../../states/configuration.state.ts';
import { TCompanyConfigs } from '../../../../../../shared/services/companyConfig/companyConfig.service.ts';
import { PostalWeightPopup } from './popups/postalWeight/postalWeight.popup.tsx';
import { DictionaryPopup } from '../../../../../../shared/components/dictionary/dictionary.popup.tsx';
import { TextEditCellDict } from '../../../../../../shared/components/dictionary/cells/textEditDict.cell.tsx';
import { NameDictCell } from './popups/postSectors/cells/nameDict.cell.tsx';

export const PostTab: FC<IPostTab> = ({ params }) => {
  const { t } = useTranslation();
  const dictionaryPopup = useMemo(() => new DictionaryPopup(), []);
  const postalWeightPopup = useMemo(() => new PostalWeightPopup(), []);
  const formMethods = useForm({ defaultValues: params, mode: 'onChange' });
  const {
    resetField,
    formState: { dirtyFields },
  } = formMethods;

  useEffect(() => {
    const formStateSub = formMethods.watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        configurationTabState.pub.recordData({ [fieldName]: data[fieldName] });
      }
    });
    const configurationTabStateSub = configurationTabState.sub
      .state()
      .subscribe(({ dataToSave }) => {
        const name = Object.keys(dataToSave)[0] as keyof typeof dataToSave;
        resetField(name, {
          defaultValue: dataToSave[name],
          keepDirty: false,
        });
      });

    return () => {
      formStateSub.unsubscribe();
      configurationTabStateSub.unsubscribe();
      postalWeightPopup.stream.unsubscribe();
      dictionaryPopup.stream.unsubscribe();
    };
  }, []);

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    if (!!dirtyFields[e.target.name as keyof typeof dirtyFields]) {
      configurationTabState.pub.saveData();
    }
  };

  const openPostSectorsPopup = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.post_sectors',
        dictionaryType: 'dictPostSectors',
        validateFields: ['name'],
        recordDeletetionDisabled: (selectedRecord) => selectedRecord.id === '1',
        columns: [
          {
            field: 'name',
            headerName: t('settings.name'),
            renderEditCell: (params) => (
              <TextEditCellDict
                rightButton={{
                  title: t('settings.post_sectors_one'),
                  handler: () =>
                    postalWeightPopup.stream.emit('open', {
                      values: {
                        id: String(params.id),
                        title: params.row?.name,
                      },
                    }),
                }}
                {...params}
              />
            ),
            renderCell: (params) => (
              <NameDictCell popupStream={postalWeightPopup.stream} {...params} />
            ),
            editable: true,
            resizable: false,
          },
        ],
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form noValidate onBlur={onSubmit}>
        <Stack direction='column'>
          <FormCheckbox
            name='isShippingFunctionActivated'
            label={t('settings.shipping_function_activated')}
          />
        </Stack>
      </form>
      <FieldsSection title={t('settings.related_directories')} nomt>
        <Stack alignItems='start' spacing={1} width='fit-content'>
          <Button
            onClick={openPostSectorsPopup}
            fullWidth
            size='medium'
            variant='outlined'
            color='secondary'
          >
            {t('settings.post_sectors_settings')}
          </Button>
        </Stack>
      </FieldsSection>
      <dictionaryPopup.Component />
      <postalWeightPopup.Component />
    </FormProvider>
  );
};

interface IPostTab {
  params: TCompanyConfigs;
}
