import { styled } from '@mui/material';
import { fadeIn, fadeOut } from '../../../styles/animations';

export const SWrapper = styled('div')<{ isLoading?: boolean }>(({ isLoading }) => ({
  position: 'relative',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: isLoading ? 'hidden' : 'none',
}));

export const SContent = styled('div')({
  padding: '5px',
  height: '100%',
});

export const SAnimation = styled('div')<IAnimation>(({ animated, show, duration }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  zIndex: 999,
  opacity: 0,
  willChange: 'auto',
  backgroundColor: 'white',
  transform: `translate3d(${show ? 0 : '-100%'}, 0, 0)`,
  animation: `${animated ? fadeOut : fadeIn} ${duration}ms ease forwards`,
}));

export const SLoading = styled('div')<{ show: boolean }>(({ show }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  zIndex: 999,
  opacity: 1,
  willChange: 'auto',
  transform: `translate3d(${show ? 0 : '-100%'}, 0, 0)`,
}));

interface IAnimation {
  animated: boolean;
  show: boolean;
  duration: string;
}
