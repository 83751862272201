import { FC, useEffect, useState } from 'react';
import { PopupService } from '../../../../../../../../shared/components/popup/services/popup.service';
import { IDefaultSettingsPopup } from './defaultSettings.popup';
import { Content as Wrapper } from '../../../../../../../../shared/components/content/content.component';
import { Subscription, take } from 'rxjs';
import {
  dictionaryAdditionalService,
  GetDictCustomerProfileRes,
} from '../../../../../../../../shared/components/dictionary/services/dictionaryAdditional.service';
import { DefaultSettingsForm, TSetData } from './components/defaultSettingsForm.component';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack } from '@mui/material';
import { IOptionsForDefaultCustomerSettings } from '../../customer.tab';
import equal from 'fast-deep-equal/react';
import { dictionaryState } from '../../../../../../../../shared/components/dictionary/states/dictionary.state';
import { useBeforeClosePopup } from '../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup';

export const Content: FC<IContent> = ({ stream }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<GetDictCustomerProfileRes>();
  const [dataBackup, setDataBackup] = useState<GetDictCustomerProfileRes>();
  const [options, setOptions] = useState<IOptionsForDefaultCustomerSettings>();

  useEffect(() => {
    let customerProfileSub: Subscription;
    const openSub = stream
      .actionListener('open')
      .pipe(take(1))
      .subscribe((v) => {
        const id = v?.values?.id;
        const options = v?.values?.optionsForDefaultCustomerSettings;
        setOptions(options);
        if (id) {
          customerProfileSub = dictionaryAdditionalService.sub
            .getDictCustomerProfile()
            .subscribe((data) => {
              setLoading(false);
              setData(data);
              setDataBackup(structuredClone(data));
            });
          dictionaryAdditionalService.pub.getDictCustomerProfile({ id });
        }
      });
    return () => {
      openSub.unsubscribe();
      customerProfileSub && customerProfileSub?.unsubscribe?.();
    };
  }, []);

  const saveData = () => {
    const { id } = data || {};
    if (id && !equal(data, dataBackup)) {
      dictionaryState.pub.updateCell(
        {
          id,
          field: 'virtualField',
          value: { ...data },
        },
        true,
      );
    }
    stream.emit('close');
  };

  useBeforeClosePopup({
    stream,
    isDirty: !equal(dataBackup, data),
  });

  return (
    <Wrapper
      type='box'
      {...{ loading }}
      boxProps={{ sx: { minHeight: '304px', width: '1200px', maxWidth: '100%', padding: 3 } }}
    >
      {data ? (
        <>
          <DefaultSettingsForm
            defaultValues={data as GetDictCustomerProfileRes}
            setData={setData as TSetData}
            options={options as IOptionsForDefaultCustomerSettings}
          />
          <Stack direction='row' justifyContent='end' mt={3}>
            <Button onClick={saveData} variant='contained' sx={{ minWidth: '125px' }}>
              {t('common.save')}
            </Button>
          </Stack>
        </>
      ) : (
        !loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '20px',
            }}
          >
            {t('errors.unknown')}
          </Box>
        )
      )}
    </Wrapper>
  );
};

export interface IContent {
  stream: PopupService<IDefaultSettingsPopup>;
}
