import { UseFormReturn } from 'react-hook-form';
import {
  BehaviorSubject,
  filter,
  map,
  merge,
  Observable,
  of,
  share,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  C_Save_Operation_Status,
  SaveArticleDataMutationVariables,
} from '../../../../../graphql/generatedModel';
import { storageHelper } from '../../../../../shared/helpers/storage';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler';
import { Pub, State } from '../../../../../shared/state/state.abstract';
import {
  ArticleLabelDataFromRaRes,
  ArticleLabelTabDataRes,
  articleService,
  SaveArticleDataRes,
} from '../../../services/article.service';
import { articleListState } from '../../../states/articleList.state';
import { ITabState, tabStateModel } from '../../../states/tabState.model';
import { artcileTabsSub } from '../articleCommonTabs.sub';
import { articleTabLoadingService } from '../articleTababLoading.service';
import { defaultArticleLabelTabData } from '../../../loaders/labelTab.resolver';

class PubImpl extends Pub<ILabelTabState> {
  form(form: ILabelTabState['form']) {
    this.emit('form', { form });
  }
  save() {
    this.emit('save', {});
  }
  updateRAData(recipeId?: string) {
    this.emit('updateRAData', { params: recipeId });
  }
  updateEANHint(eanHint?: string) {
    this.emit('updateEANHint', { eanHint });
  }
}
class SubImpl extends artcileTabsSub<ILabelTabState>() {
  private eanHint$ = new BehaviorSubject<string>('');
  private shareEanHint$: Observable<string> = this.eanHint$.pipe(share());
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());
  protected actionHandlers(): Observable<ILabelTabState> {
    return merge(
      this.selectedArticle(),
      this.save(),
      this.updateRAData(),
      this.updateEANHint(),
    ).pipe(
      filter(
        ({ action }) =>
          action !== 'form' &&
          action !== 'save' &&
          action !== 'updateRAData' &&
          action !== 'updateEANHint',
      ),
    );
  }
  private save(): Observable<ILabelTabState> {
    return this.actionListener('save').pipe(tap(() => this._save()));
  }

  private updateRAData(): Observable<ILabelTabState> {
    return this.actionListener('updateRAData').pipe(
      tap(() => this.loading$.next(true)),
      switchMap((state) => {
        const recipeId = state?.params as string | undefined;
        const form = state?.form;
        if (form) {
          if (recipeId) {
            articleService.pub.articleLabelDataFromRa({ recipeId });
            return articleService.sub.articleLabelDataFromRa().pipe(
              responseHandler<ArticleLabelDataFromRaRes>({
                errorReturnType: initArticleLabelDataFromRA,
              }),
              map((raData) => {
                this.loading$.next(false);
                form.setValue('articleLabelDataFromRA', raData);
                return state;
              }),
            );
          }
          // when we reseted recipeId
          form.setValue('articleLabelDataFromRA', initArticleLabelDataFromRA);
          this.loading$.next(false);
        }
        return of(state);
      }),
    );
  }

  loading(): Observable<boolean> {
    return this.shareLoading$;
  }

  private selectedArticle(): Observable<ILabelTabState> {
    return articleListState.sub.state().pipe(
      filter(
        ({ action }) =>
          action === 'selectArticle' || action === 'articleList' || action === 'filter',
      ),
      filter(({ selectedArticle }) => {
        if (!selectedArticle || !selectedArticle?.id) {
          this.articleIdNotExist$.next(true);
        }
        return typeof selectedArticle?.id === 'string';
      }),
      switchMap(({ selectedArticle }) => {
        articleTabLoadingService.pub.loading(true);
        const id = selectedArticle!.id;
        const state = this.stream$.getValue();
        state.action = 'list.selectedArticle';
        return this.details(state, id);
      }),
    );
  }
  private _save() {
    const { form } = this.stream$.getValue();
    if (form) {
      const {
        formState: { isDirty, errors, dirtyFields },
      } = form;
      if (isDirty) {
        articleService.sub
          .editArticle()
          .pipe(
            responseHandler<SaveArticleDataRes | undefined>({
              success: () => 'article.article_saved',
              customErrorHandler: () => 'article.article_not_saved',
            }),
            filter((v) => v !== undefined),
            take(1),
          )
          .subscribe((res) => {
            if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
              const { updatedGridItem } = res;
              articleListState.pub.updateArticle(updatedGridItem!);
            }
            storageHelper.memory.removeItem('configsData.articlesForOrderPositionList');
          });

        if (Object.keys(dirtyFields).length) {
          const formData = form.getValues();
          const {
            articleLabelDataFromRA,
            customLabelDescription,
            origin,
            eanCode,
            isEanCodeManagedByCA,
            declaration,
            isStoreInCoolPlace,
            storagePlace,
          } = formData;
          const {
            id: recipeId,
            isAutoDeclaration,
            autoDeclaration,
            declaration: declarationRA,
            sellingPoints: sellingPointsRA,
            sellingPointsFreezer: sellingPointsFreezerRA,
          } = articleLabelDataFromRA || {};

          let declarationValue = declaration;
          if (recipeId) {
            if (isAutoDeclaration) {
              declarationValue = autoDeclaration;
            } else {
              declarationValue = declarationRA;
            }
          }

          let storagePlaceValue = storagePlace;
          if (recipeId) {
            if (sellingPointsRA) {
              storagePlaceValue = sellingPointsRA;
            } else {
              storagePlaceValue = sellingPointsFreezerRA;
            }
          }

          const dirtyRaData = dirtyFields?.articleLabelDataFromRA as IDirtyArticleLabelDataFromRA;
          const dataToSave = {
            id: formData?.id,
            ...(dirtyFields?.customLabelDescription && {
              customLabelDescription,
            }),
            ...(dirtyFields?.origin && {
              origin,
            }),
            ...(dirtyFields?.eanCode &&
              !errors.eanCode && {
                eanCode,
              }),
            ...(dirtyFields?.isEanCodeManagedByCA && {
              isEanCodeManagedByCA,
            }),
            ...(dirtyRaData?.id && {
              labelRecipeId: recipeId,
            }),
            ...((dirtyFields?.declaration || dirtyRaData?.id) && {
              declaration: declarationValue,
            }),
            ...((dirtyFields?.daysShelfLife || dirtyRaData?.id) && {
              daysShelfLife: recipeId
                ? articleLabelDataFromRA?.daysShelfLife
                : formData?.daysShelfLife,
            }),
            ...((dirtyFields?.daysToConsume || dirtyRaData?.id) && {
              daysToConsume: recipeId
                ? articleLabelDataFromRA?.daysToConsume
                : formData?.daysToConsume,
            }),
            ...((dirtyFields?.daysToSell || dirtyRaData?.id) &&
              !errors.daysToSell && {
                daysToSell: recipeId ? articleLabelDataFromRA?.daysToSell : formData?.daysToSell,
              }),
            ...(dirtyFields?.sellingWeight && {
              sellingWeight: formData?.sellingWeight,
            }),
            ...((dirtyFields?.isStoreInCoolPlace || dirtyRaData?.id) && {
              isStoreInCoolPlace: recipeId
                ? articleLabelDataFromRA?.isStoreInCoolPlace
                : isStoreInCoolPlace,
            }),
            ...((dirtyFields?.storagePlace || dirtyRaData?.id) && {
              storagePlace: storagePlaceValue,
            }),
            ...((dirtyFields?.consumersStorageInfo || dirtyRaData?.id) && {
              consumersStorageInfo: recipeId
                ? articleLabelDataFromRA?.consumersStorageInfo
                : formData?.consumersStorageInfo,
            }),
            ...((dirtyFields?.consumersInformation || dirtyRaData?.id) && {
              consumersInformation: recipeId
                ? articleLabelDataFromRA?.consumersInformation
                : formData?.consumersInformation,
            }),
          } as SaveArticleDataMutationVariables['dataToSave'];
          articleService.pub.editArticle({ dataToSave });
        }
      }
    }
  }
  private details(state: ILabelTabState, id: string): Observable<ILabelTabState> {
    const details = articleService.sub.articleLabelTabData().pipe(
      responseHandler<ArticleLabelTabDataRes>({
        errorReturnType: defaultArticleLabelTabData,
      }),
      map((data) => {
        state.defaultValues = data;
        state.defaultValues.id = id;
        articleTabLoadingService.pub.loading(false);
        return state;
      }),
    );
    articleService.pub.articleLabelTabData({ id });

    return details;
  }

  private updateEANHint(): Observable<ILabelTabState> {
    return this.actionListener('updateEANHint').pipe(
      map((state) => {
        const isNew = state?.eanHint !== this.eanHint$.value;
        if (isNew) {
          this.eanHint$.next(state?.eanHint || '');
        }
        return state;
      }),
    );
  }

  eanHint(): Observable<string> {
    return this.shareEanHint$;
  }
}

class LabelTabState extends State<ILabelTabState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const labelTabState = new LabelTabState({
  ...tabStateModel,
  action: undefined,
  articleId: '',
  form: undefined,
  eanHint: undefined,
});

const initArticleLabelDataFromRA = {
  id: null,
  autoDeclaration: null,
  declaration: null,
  isAutoDeclaration: false,
  consumersInformation: null,
  consumersStorageInfo: null,
  daysToSell: null,
  daysToConsume: null,
  daysShelfLife: null,
  isStoreInCoolPlace: false,
  productOutputWeight: null,
  sellingPoints: null,
  sellingPointsFreezer: null,
  sellingWeight: null,
};

interface IDirtyArticleLabelDataFromRA {
  id?: boolean;
}

export interface ILabelTabState extends Pick<ITabState, 'defaultValues' | 'articleId'> {
  action: undefined | 'list.selectedArticle' | 'form' | 'save' | 'updateRAData' | 'updateEANHint';
  form: undefined | UseFormReturn<ArticleLabelTabDataRes>;
  params?: any;
  eanHint?: string;
}
