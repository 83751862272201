/* eslint-disable react-hooks/rules-of-hooks */

import { FC, useEffect, useMemo, useState } from 'react';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../shared/components/popup/popup.abstract';
import { TPopupDefaultActions } from '../../../../../../../../shared/components/popup/services/popup.service';
import { useTranslation } from 'react-i18next';
import { map } from 'rxjs';
import { styled } from '@mui/material';
import { Content } from './content.popup';
import { IOptionsForDefaultCustomerSettings } from '../../customer.tab';

export class DefaultSettingsPopup extends Popup<IDefaultSettingsPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const { t } = useTranslation();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 1335 },
          '& > div > div > div': { padding: 0 },
        }));
      }, []);
      const [popupTitle, setPopupTitle] = useState('');

      useEffect(() => {
        stream
          .actionListener('open')
          .pipe(map(({ values: { title } }) => `${t('settings.default_settings')} | ${title}`))
          .subscribe(setPopupTitle);
      }, []);

      return (
        <SWrapper stream={stream} {...{ popupTitle }}>
          <Content stream={stream} />
        </SWrapper>
      );
    };
  }
}

export interface IDefaultSettingsPopup {
  action: 'save' | TPopupDefaultActions;
  values: {
    id: string;
    title: string;
    optionsForDefaultCustomerSettings: IOptionsForDefaultCustomerSettings;
  };
}
