import { Observable, finalize, forkJoin, map, switchMap } from 'rxjs';
import {
  Wa_MergePdFsMutation,
  Wa_MergePdFsMutationVariables,
  SendEmailMutation,
  SendEmailMutationVariables,
} from '../../../graphql/generatedModel';
import { Pub, Service, Sub } from '../service.abstract';
import { gqlClient } from '../../../graphql/graphqlRequest';
import { mergePDFs, sendEmail } from './gql/commonRequests.gql';

class PubImpl extends Pub<Actions> {
  clearStream() {
    this.emit(undefined, {});
  }
  sendEmail(params: SendEmailMutationVariables): void {
    this.emit('sendEmail', params);
  }
  sendMultipleEmails(params: Array<SendEmailMutationVariables>): void {
    this.emit('sendMultipleEmails', params);
  }
  mergePDFs(params: Wa_MergePdFsMutationVariables): void {
    this.emit('mergePDFs', params);
  }
}

class SubImpl extends Sub<Actions> {
  sendEmail(): Observable<SendEmailRes> {
    return this.actionListener('sendEmail').pipe(
      switchMap(({ params }) => {
        return gqlClient(sendEmail, params) as Observable<SendEmailMutation>;
      }),
      map((res) => res.wawiAssist?.sendEmail as SendEmailRes),
      finalize(() => {
        commonRequestsService.pub.clearStream();
      }),
    );
  }
  sendMultipleEmails(): Observable<Array<SendEmailRes>> {
    return this.actionListener('sendMultipleEmails').pipe(
      switchMap(({ params }) => {
        const emailsRequests: Observable<SendEmailMutation>[] = params.map(
          (variables: SendEmailMutationVariables) => gqlClient(sendEmail, { ...variables }),
        );
        return forkJoin(emailsRequests).pipe(
          map((responses) => {
            return responses.map((response) => response?.wawiAssist?.sendEmail) as SendEmailRes[];
          }),
        );
      }),
      finalize(() => {
        commonRequestsService.pub.clearStream();
      }),
    );
  }
  mergePDFs(): Observable<MergePDFsRes> {
    return this.actionListener('mergePDFs').pipe(
      switchMap(({ params }) => {
        return gqlClient(mergePDFs, params) as Observable<Wa_MergePdFsMutation>;
      }),
      map((data) => {
        return data.wawiAssist?.WA_mergePDFs as MergePDFsRes;
      }),
      finalize(() => {
        commonRequestsService.pub.clearStream();
      }),
    );
  }
}

class CommonRequests extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const commonRequestsService = new CommonRequests();

type Actions = 'sendEmail' | 'sendMultipleEmails' | 'mergePDFs' | undefined;

export type SendEmailRes = NonNullable<NonNullable<SendEmailMutation>['wawiAssist']>['sendEmail'];
export type MergePDFsRes = NonNullable<
  NonNullable<Wa_MergePdFsMutation['wawiAssist']>['WA_mergePDFs']
>;
