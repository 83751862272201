import { LocalizationHelper } from '../../shared/helpers/localization/localization.helper';

type Keys =
  | 'new_article'
  | 'article'
  | 'articles'
  | 'general'
  | 'availability'
  | 'availability_setting'
  | 'availability_in_procurement_module'
  | 'lists'
  | 'post'
  | 'production'
  | 'label'
  | 'regular_price'
  | 'special_price'
  | 'addNewRecord'
  | 'deleteRecord'
  | 'net_article'
  | 'article_is_active'
  | 'product_group'
  | 'production_group'
  | 'marketing_group'
  | 'sale_unit'
  | 'custom_week_days'
  | 'period'
  | 'break_off'
  | 'repeat_yearly'
  | 'use_custom_weekdays_settings'
  | 'piece_or_unit'
  | 'description_column'
  | 'number_column'
  | 'drop_file_here'
  | 'active_article'
  | 'copy'
  | 'delivery_periods'
  | 'assortment_groups'
  | 'cashassist_groups'
  | 'cashassist_templates'
  | 'product_groups'
  | 'marketing_groups'
  | 'production_groups'
  | 'shipping_points'
  | 'fraction'
  | 'dough'
  | 'from_date_or_to_date_should_be_selected'
  | 'at_least_one_price_category_should_be_not_empty'
  | 'there_is_a_record_with_the_same_period'
  | 'go_to_the_general_tab_for_copy'
  | 'dough_item'
  | 'dough_weight_before_baking'
  | 'fermentation_interrupt'
  | 'display_on_production_print_lists'
  | 'recipe'
  | 'article_link'
  | 'sheet'
  | 'number_per_sheet'
  | 'sheets_per_production'
  | 'calculation_for_production'
  | 'article_will_be_replaced_with_calculation'
  | 'round_to_whole_pieces'
  | 'rounds_to_units'
  | 'article_no'
  | 'factor_on_quantity'
  | 'used_in_the_calculation'
  | 'sales_group'
  | 'consume_by'
  | 'consumers_storage_info'
  | 'sales_information_for_customer'
  | 'visibility'
  | 'template'
  | 'labeling'
  | 'alternative_designation_on_label'
  | 'origin'
  | 'ean'
  | 'check_digit'
  | 'ean_code'
  | 'ean_code_is_managed_in_cashAssist'
  | 'declaration'
  | 'link_to_recipeAssist'
  | 'sell_by'
  | 'selling_weight_piece'
  | 'alternative_sales_weight'
  | 'shelf_life_in_days'
  | 'item_must_be_kept_cool'
  | 'storage'
  | 'ean_test'
  | 'used_in_the_calculation_of_items'
  | 'create_article'
  | 'copy_article'
  | 'warn_user_with_this_note'
  | 'articleNo_maxLen'
  | 'eanCode_must_digits'
  | 'eanCode_onlyNumb'
  | 'eanCode_not_valid'
  | 'allProductGroups'
  | 'description_maxLen'
  | 'not_unique_number'
  | 'article_list_not_loaded'
  | 'article_saved'
  | 'article_not_saved'
  | 'tab_not_loaded'
  | 'filter_not_loaded'
  | 'basis'
  | 'groups'
  | 'picture'
  | 'cashassist'
  | 'weight'
  | 'days_to_produce'
  | 'fractional_article'
  | 'used_fractional_article'
  | 'number_of_used_fractions'
  | 'charge_splitting'
  | 'kg_pro_charge'
  | 'liters'
  | 'shipping_point'
  | 'weight_of_liter_of_dough'
  | 'round_to'
  | 'number_of_fractions_per_unit'
  | 'round_up'
  | 'article_already_linked'
  | 'multiplier_on_amount';

const t = LocalizationHelper<Keys>('article');

export const articleDe = t({
  new_article: 'Neuartikel',
  article: 'Artikel',
  articles: 'Artikel',
  cashassist: 'CashAssist',
  general: 'Allgemein',
  availability: 'Verfügbarkeit',
  availability_setting: 'Verfügbarkeit Einstellung',
  availability_in_procurement_module: 'Verfügbarkeit in Beschaffungsmodul',
  lists: 'Listen',
  post: 'Post',
  production: 'Produktion',
  label: 'Etikett',
  special_price: 'Sonderpreise',
  addNewRecord: 'Neuen Eintrag hinzufügen',
  deleteRecord: 'Eintrag löschen',
  consumers_storage_info: 'Verbrauchsinformation für den Kunden',
  sales_information_for_customer: 'Verkaufsinformationen für den Kunden',
  regular_price: 'Standartpreis',
  net_article: 'Nettoartikel (kein Rabatt möglich)',
  article_is_active: 'Artikel ist aktiv',
  product_group: 'Warengruppe',
  production_group: 'Produktionsgruppe',
  marketing_group: 'Marketinggruppe',
  sale_unit: 'Verkaufseinheit',
  piece_or_unit: 'Stück/Einheit',
  description_column: 'Bezeichnung',
  number_column: 'Nummer',
  drop_file_here: 'Datei hier ablegen',
  active_article: 'Aktive Artikel',
  copy: 'Kopieren',
  assortment_groups: 'Sortimentsgruppen',
  cashassist_groups: 'CashAssist Umsatzgruppen',
  cashassist_templates: 'CashAssist Schablonen',
  product_groups: 'Warengruppen',
  marketing_groups: 'Marketinggruppen',
  production_groups: 'Produktionsgruppen',
  shipping_points: 'Versandstellen',
  fraction: 'Bruch',
  dough: 'Teig',
  from_date_or_to_date_should_be_selected: '"Von Datum" oder "Bis Datum" muss gewählt werden',
  at_least_one_price_category_should_be_not_empty:
    'Mindestens eine Preisklasse sollte nicht leer sein',
  there_is_a_record_with_the_same_period: 'Es gibt einen Datensatz mit demselben Zeitraum',
  repeat_yearly: 'Wiederhole  jährlich',
  use_custom_weekdays_settings: 'Verwenden Sie benutzerdefinierte Wochentag-Einstellungen',
  custom_week_days: 'Wochentage',
  period: 'Zeitraum',
  break_off: 'Aussetzen',
  delivery_periods: 'Lieferperioden',
  go_to_the_general_tab_for_copy: 'Gehen Sie zum Kopieren auf die Registerkarte Allgemein',
  dough_item: 'Teigartikel',
  dough_weight_before_baking: 'Teiggewicht vor dem Backen (g)',
  fermentation_interrupt: 'Gärunterbrechung',
  display_on_production_print_lists: 'Auf Produktionslisten anzeigen',
  recipe: 'Rezept',
  article_link: 'Artikelverknüpfung',
  sheet: 'Blech',
  shipping_point: 'Versandstelle',
  consume_by: 'Zu verbrauchen bis',
  number_per_sheet: 'Anzahl pro Blech',
  sheets_per_production: 'Anzahl Blech pro Herstellung',
  calculation_for_production: 'Kalkulation für Produktion',
  article_will_be_replaced_with_calculation: 'Artikel wird mit Berechnung ersetzt',
  round_to_whole_pieces: 'Auf ganze Stück runden',
  rounds_to_units: 'Runden auf Einheiten',
  article_no: 'Artikel-Nr.',
  factor_on_quantity: 'Faktor auf Menge',
  used_in_the_calculation: 'Bei der Kalkulation werden folgende verwendet',
  sales_group: 'Umsatzgruppe',
  visibility: 'Sichtbarkeit',
  template: 'Schablone',
  allProductGroups: 'Alle Sortimentsgruppen',
  labeling: 'Etikettierung',
  alternative_designation_on_label: 'Alternative Bezeichnung auf Etikett',
  origin: 'Herkunft',
  ean: 'EAN',
  ean_code: 'EAN-Code',
  ean_code_is_managed_in_cashAssist: 'EAN-Code wird im CashAssist verwaltet',
  declaration: 'Deklaration',
  link_to_recipeAssist: 'Verknüpfung mit RezeptAssist',
  sell_by: 'Zu verkaufen bis',
  selling_weight_piece: 'Verkaufsgewicht Stück',
  alternative_sales_weight: 'Alternativen Verkaufsgewicht (g)',
  shelf_life_in_days: 'Haltbarkeit in Tagen',
  item_must_be_kept_cool: 'Artikel muss kühl gehalten werden',
  storage: 'Lagerung',
  eanCode_must_digits: 'Die Codes müssen acht- oder dreizehnstellig sein',
  check_digit: 'Prüfziffer',
  ean_test: 'EAN Test',
  used_in_the_calculation_of_items:
    'Dieser Artikel wird bei der Kalkulation folgender Artikel verwendet',
  create_article: 'Artikel erstellen',
  copy_article: 'Artikel kopieren',
  warn_user_with_this_note:
    'Den Benutzer mit diesem Hinweis warnen, wenn der Artikel bestellt wird',
  not_unique_number: 'Nummer existiert bereits',
  articleNo_maxLen: 'Maximale Zeichenanzahl - 10',
  eanCode_onlyNumb: 'EAN darf nur Zahlen enthalten',
  eanCode_not_valid: 'EAN nicht gültig',
  description_maxLen: 'Maximale Zeichenanzahl - 64',
  filter_not_loaded: 'Filterdaten werden nicht geladen!',
  article_list_not_loaded: 'Artikellistendaten nicht geladen!',
  article_saved: 'Artikel wurde erfolgreich gespeichert',
  article_not_saved: 'Fehler: Artikel nicht gespeichert!',
  tab_not_loaded: 'Daten für Tab nicht geladen!',
  basis: 'Basis',
  groups: 'Gruppen',
  picture: 'Bild',
  weight: 'Gewicht (g)',
  days_to_produce: 'Tage zu produzieren',
  fractional_article: 'Bruch-Artkiel',
  used_fractional_article: 'Verwendet Bruch-Artikel',
  number_of_used_fractions: 'Verwendete Teile des Bruchs für diesen Artikel',
  charge_splitting: 'Charge Splitting',
  kg_pro_charge: 'Kg pro Charge',
  liters: 'Liter',
  weight_of_liter_of_dough: 'Literanzahl Teig (g)',
  round_to: 'Runden auf (lt)',
  number_of_fractions_per_unit: 'Anzahl Teile des Bruchs',
  round_up: 'Aufrunden',
  article_already_linked: 'Dieser Rezept ist gerade mit einem anderen Artikel verknüpft',
  multiplier_on_amount: 'Faktor auf Menge',
});

export const articleEn = t({
  new_article: 'New article',
  article: 'Article',
  cashassist: 'CashAssist',
  articles: 'Articles',
  availability: 'Availability',
  availability_setting: 'Availability Setting',
  availability_in_procurement_module: 'Availability in procurement module',
  general: 'General',
  lists: 'Lists',
  post: 'Post',
  production: 'Production',
  label: 'Label',
  regular_price: 'Regular price',
  net_article: 'No discount possible',
  article_is_active: 'Article is active',
  product_group: 'Product group',
  production_group: 'Production group',
  marketing_group: 'Marketing group',
  sale_unit: 'Sale unit',
  piece_or_unit: 'Quantity/Lot',
  description_column: 'Description',
  number_column: 'Number',
  drop_file_here: 'Drop file here',
  active_article: 'Active article',
  copy: 'Copy',
  special_price: 'Special prices',
  addNewRecord: 'Add new record',
  deleteRecord: 'Delete record',
  repeat_yearly: 'Repeat yearly',
  use_custom_weekdays_settings: 'Use custom weekdays settings',
  custom_week_days: 'Custom week days',
  period: 'Period',
  break_off: 'Break off',
  assortment_groups: 'Assortment groups',
  cashassist_groups: 'CashAssist product groups',
  cashassist_templates: 'CashAssist templates',
  product_groups: 'Product groups',
  marketing_groups: 'Marketing groups',
  production_groups: 'Production groups',
  shipping_points: 'Shipping points',
  fraction: 'Fraction',
  dough: 'Dough',
  from_date_or_to_date_should_be_selected: '"From date" or "To date" should be selected',
  at_least_one_price_category_should_be_not_empty:
    'At least one price category should be not empty',
  there_is_a_record_with_the_same_period: 'There is a record with the same period',
  shipping_point: 'Shipping point',
  go_to_the_general_tab_for_copy: 'Go to the General tab for copy',
  dough_item: 'Dough article',
  dough_weight_before_baking: 'Dough weight before baking (g)',
  fermentation_interrupt: 'Fermentation interrupt',
  display_on_production_print_lists: 'Display on production print lists',
  recipe: 'Recipe',
  article_link: 'Article linking',
  sheet: 'Sheet',
  number_per_sheet: 'Number per sheet',
  sheets_per_production: 'Number of sheets per production',
  calculation_for_production: 'Calculation for production',
  article_will_be_replaced_with_calculation: 'Article will be replaced with calculation',
  round_to_whole_pieces: 'Round to whole pieces',
  rounds_to_units: 'Round to units',
  article_no: 'Article No.',
  factor_on_quantity: 'Factor on quantity',
  used_in_the_calculation: 'The following are used in the calculation',
  sales_group: 'Sales group',
  visibility: 'Visibility',
  template: 'Template',
  labeling: 'Labeling',
  alternative_designation_on_label: 'Alternative description on label',
  origin: 'Origin',
  ean: 'EAN',
  consume_by: 'Consume by',
  ean_code: 'EAN Number',
  ean_code_is_managed_in_cashAssist: 'EAN Code is managed in CashAssist',
  declaration: 'Declaration',
  link_to_recipeAssist: 'Connection to RezeptAssist',
  sell_by: 'To sell by',
  selling_weight_piece: 'Item sale weight',
  alternative_sales_weight: 'Alternative sale weight (g)',
  shelf_life_in_days: 'Shelf life in days',
  item_must_be_kept_cool: 'Article must be kept cool',
  consumers_storage_info: 'Consumption Information for customer',
  sales_information_for_customer: 'Sales information for customer',
  storage: 'Storage',
  ean_test: 'EAN test',
  allProductGroups: 'All assortment groups',
  delivery_periods: 'Delivery periods',
  used_in_the_calculation_of_items: 'This item is used in the calculation of the following items',
  create_article: 'Create article',
  copy_article: 'Copy article',
  warn_user_with_this_note: 'Warn user with this note when ordering article',
  not_unique_number: 'Number already exists',
  articleNo_maxLen: 'Maximum characters number - 10',
  eanCode_must_digits: 'EAN must be eight or thirteen digits long',
  check_digit: 'Check digit',
  eanCode_onlyNumb: 'EAN must contain only numbers',
  eanCode_not_valid: 'EAN not valid',
  description_maxLen: 'Maximum characters number - 64',
  filter_not_loaded: `Filter data don't loaded!`,
  article_list_not_loaded: `Article list data don't loaded!`,
  article_saved: 'Article was successfully saved',
  article_not_saved: 'Error: Article not saved!',
  tab_not_loaded: 'Data for tab not loaded!',
  basis: 'Basis',
  groups: 'Groups',
  picture: 'Picture',
  weight: 'Weight (g)',
  days_to_produce: 'Days to produce',
  fractional_article: 'Fractional article',
  used_fractional_article: 'Used fractional article',
  number_of_used_fractions: 'Number of used fractions',
  charge_splitting: 'Charge splitting',
  kg_pro_charge: 'Kg pro charge',
  liters: 'Liters',
  weight_of_liter_of_dough: 'Weight of liter of dough (g)',
  round_to: 'Round to (lt)',
  number_of_fractions_per_unit: 'Number of fractions per unit',
  round_up: 'Round up',
  article_already_linked: 'The current recipe is already linked to another article',
  multiplier_on_amount: 'Multiplier on amount',
});
