import { Observable, finalize, map, merge, mergeMap, take } from 'rxjs';
import {
  C_Report,
  Create_R11_CrosstableReportA4LandscapeReportMutation,
  Create_R11_CrosstableReportA4LandscapeReportMutationVariables,
  Create_R14_BakingListWithRecipe2ColumnsReportMutation,
  Create_R14_BakingListWithRecipe2ColumnsReportMutationVariables,
  Create_R15_ForwardingListCustomerArticlesReportMutation,
  Create_R15_ForwardingListCustomerArticlesReportMutationVariables,
  Create_R1_BakingList2ColumnsReportMutation,
  Create_R1_BakingList2ColumnsReportMutationVariables,
  Create_R20_ForwardingListArticleCustomersReportMutation,
  Create_R20_ForwardingListArticleCustomersReportMutationVariables,
  Create_R25_BakingList2ColumnsCombinedReportMutation,
  Create_R25_BakingList2ColumnsCombinedReportMutationVariables,
  Create_R26_BakingListWithRecipeA4ReportMutation,
  Create_R26_BakingListWithRecipeA4ReportMutationVariables,
  Create_R27_BakingListWithRecipeA5ReportMutation,
  Create_R27_BakingListWithRecipeA5ReportMutationVariables,
  Create_R32_TourlistReportMutation,
  Create_R32_TourlistReportMutationVariables,
  Create_R34_BakingListA4ReportMutation,
  Create_R34_BakingListA4ReportMutationVariables,
  Create_R41_BakingListA4DetailedReportMutation,
  Create_R41_BakingListA4DetailedReportMutationVariables,
  Create_R42_ForwardingListCustomerArticlesOnePerPageReportMutation,
  Create_R42_ForwardingListCustomerArticlesOnePerPageReportMutationVariables,
  Create_R51_DeliveryNotesReportsMutation,
  Create_R51_DeliveryNotesReportsMutationVariables,
  Create_R57_BakingListRawmaterials2ColumnsReportMutation,
  Create_R57_BakingListRawmaterials2ColumnsReportMutationVariables,
  Create_R65_CrosstableReportA4PortraitReportMutation,
  Create_R65_CrosstableReportA4PortraitReportMutationVariables,
  Create_R66_BakingListForCakesAndPiesReportMutation,
  Create_R66_BakingListForCakesAndPiesReportMutationVariables,
} from '../../../graphql/generatedModel';
import { Pub, Service, Sub } from '../../../shared/services/service.abstract';
import { gqlClient } from '../../../graphql/graphqlRequest';
import {
  r1,
  r11,
  r14,
  r15,
  r20,
  r25,
  r26,
  r27,
  r32,
  r34,
  r41,
  r42,
  r51,
  r57,
  r65,
  r66,
} from './gql/winReports.gql';

class PubImpl extends Pub<Action> {
  r1(args: { variables: Create_R1_BakingList2ColumnsReportMutationVariables; uuid: string }) {
    this.emit(C_Report.R1_BAKING_LIST_2_COLUMNS, args);
  }
  r11(args: {
    variables: Create_R11_CrosstableReportA4LandscapeReportMutationVariables;
    uuid: string;
  }) {
    this.emit(C_Report.R11_CROSSTABLE_REPORT_A4_LANDSCAPE, args);
  }
  r14(args: {
    variables: Create_R14_BakingListWithRecipe2ColumnsReportMutationVariables;
    uuid: string;
  }) {
    this.emit(C_Report.R14_BAKING_LIST_WITH_RECIPE_2_COLUMNS, args);
  }
  r15(args: {
    variables: Create_R15_ForwardingListCustomerArticlesReportMutationVariables;
    uuid: string;
  }) {
    this.emit(C_Report.R15_FORWARDING_LIST_CUSTOMER_ARTICLES, args);
  }
  r20(args: {
    variables: Create_R20_ForwardingListArticleCustomersReportMutationVariables;
    uuid: string;
  }) {
    this.emit(C_Report.R20_FORWARDING_LIST_ARTICLE_CUSTOMERS, args);
  }
  r25(args: {
    variables: Create_R25_BakingList2ColumnsCombinedReportMutationVariables;
    uuid: string;
  }) {
    this.emit(C_Report.R25_BAKING_LIST_2_COLUMNS_COMBINED, args);
  }
  r26(args: { variables: Create_R26_BakingListWithRecipeA4ReportMutationVariables; uuid: string }) {
    this.emit(C_Report.R26_BAKING_LIST_WITH_RECIPE_A4, args);
  }
  r27(args: { variables: Create_R27_BakingListWithRecipeA5ReportMutationVariables; uuid: string }) {
    this.emit(C_Report.R27_BAKING_LIST_WITH_RECIPE_A5, args);
  }
  r32(args: { variables: Create_R32_TourlistReportMutationVariables; uuid: string }) {
    this.emit(C_Report.R32_TOURLIST, args);
  }
  r34(args: { variables: Create_R34_BakingListA4ReportMutationVariables; uuid: string }) {
    this.emit(C_Report.R34_BAKING_LIST_A4, args);
  }
  r41(args: { variables: Create_R41_BakingListA4DetailedReportMutationVariables; uuid: string }) {
    this.emit(C_Report.R41_BAKING_LIST_A4_DETAILED, args);
  }
  r42(args: {
    variables: Create_R42_ForwardingListCustomerArticlesOnePerPageReportMutationVariables;
    uuid: string;
  }) {
    this.emit(C_Report.R42_FORWARDING_LIST_CUSTOMER_ARTICLES_ONE_PER_PAGE, args);
  }
  r51(args: { variables: Create_R51_DeliveryNotesReportsMutationVariables; uuid: string }) {
    this.emit(C_Report.R51_DELIVERY_NOTES_IN_PRODUCTION, args);
  }
  r57(args: {
    variables: Create_R57_BakingListRawmaterials2ColumnsReportMutationVariables;
    uuid: string;
  }) {
    this.emit(C_Report.R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS, args);
  }
  r65(args: {
    variables: Create_R65_CrosstableReportA4PortraitReportMutationVariables;
    uuid: string;
  }) {
    this.emit(C_Report.R65_CROSSTABLE_REPORT_A4_PORTRAIT, args);
  }
  r66(args: {
    variables: Create_R66_BakingListForCakesAndPiesReportMutationVariables;
    uuid: string;
  }) {
    this.emit(C_Report.R66_BAKING_LIST_FOR_CAKES_AND_PIES, args);
  }
  clear() {
    this.emit(undefined);
  }
}

class SubImpl extends Sub<Action> {
  mergedAll(): Observable<ReportCreateRes> {
    return merge(
      this.r1(),
      this.r11(),
      this.r14(),
      this.r15(),
      this.r20(),
      this.r25(),
      this.r26(),
      this.r27(),
      this.r32(),
      this.r34(),
      this.r41(),
      this.r42(),
      this.r51(),
      this.r57(),
      this.r65(),
      this.r66(),
    ).pipe(finalize(() => productionWinReportService.pub.clear()));
  }
  r1(): Observable<ReportCreateRes> {
    return this.actionListener(C_Report.R1_BAKING_LIST_2_COLUMNS).pipe(
      this.commonRequest<Create_R1_BakingList2ColumnsReportMutation>(r1),
      map(({ data, uuid }) => ({
        data: data.wawiAssist?.create_r1_BakingList2ColumnsReport,
        uuid,
      })),
    );
  }
  r11(): Observable<ReportCreateRes> {
    return this.actionListener(C_Report.R11_CROSSTABLE_REPORT_A4_LANDSCAPE).pipe(
      this.commonRequest<Create_R11_CrosstableReportA4LandscapeReportMutation>(r11),
      map(({ data, uuid }) => ({
        data: data.wawiAssist?.create_r11_CrosstableReportA4LandscapeReport,
        uuid,
      })),
    );
  }
  r14(): Observable<ReportCreateRes> {
    return this.actionListener(C_Report.R14_BAKING_LIST_WITH_RECIPE_2_COLUMNS).pipe(
      this.commonRequest<Create_R14_BakingListWithRecipe2ColumnsReportMutation>(r14),
      map(({ data, uuid }) => ({
        data: data.wawiAssist?.create_r14_BakingListWithRecipe2ColumnsReport,
        uuid,
      })),
    );
  }
  r15(): Observable<ReportCreateRes> {
    return this.actionListener(C_Report.R15_FORWARDING_LIST_CUSTOMER_ARTICLES).pipe(
      this.commonRequest<Create_R15_ForwardingListCustomerArticlesReportMutation>(r15),
      map(({ data, uuid }) => ({
        data: data.wawiAssist?.create_r15_ForwardingListCustomerArticlesReport,
        uuid,
      })),
    );
  }
  r20(): Observable<ReportCreateRes> {
    return this.actionListener(C_Report.R20_FORWARDING_LIST_ARTICLE_CUSTOMERS).pipe(
      this.commonRequest<Create_R20_ForwardingListArticleCustomersReportMutation>(r20),
      map(({ data, uuid }) => ({
        data: data.wawiAssist?.create_r20_ForwardingListArticleCustomersReport,
        uuid,
      })),
    );
  }
  r25(): Observable<ReportCreateRes> {
    return this.actionListener(C_Report.R25_BAKING_LIST_2_COLUMNS_COMBINED).pipe(
      this.commonRequest<Create_R25_BakingList2ColumnsCombinedReportMutation>(r25),
      map(({ data, uuid }) => ({
        data: data.wawiAssist?.create_r25_BakingList2ColumnsCombinedReport,
        uuid,
      })),
    );
  }
  r26(): Observable<ReportCreateRes> {
    return this.actionListener(C_Report.R26_BAKING_LIST_WITH_RECIPE_A4).pipe(
      this.commonRequest<Create_R26_BakingListWithRecipeA4ReportMutation>(r26),
      map(({ data, uuid }) => ({
        data: data.wawiAssist?.create_r26_BakingListWithRecipeA4Report,
        uuid,
      })),
    );
  }
  r27(): Observable<ReportCreateRes> {
    return this.actionListener(C_Report.R27_BAKING_LIST_WITH_RECIPE_A5).pipe(
      this.commonRequest<Create_R27_BakingListWithRecipeA5ReportMutation>(r27),
      map(({ data, uuid }) => ({
        data: data.wawiAssist?.create_r27_BakingListWithRecipeA5Report,
        uuid,
      })),
    );
  }
  r32(): Observable<ReportCreateRes> {
    return this.actionListener(C_Report.R32_TOURLIST).pipe(
      this.commonRequest<Create_R32_TourlistReportMutation>(r32),
      map(({ data, uuid }) => ({ data: data.wawiAssist?.create_r32_TourlistReport, uuid })),
    );
  }
  r34(): Observable<ReportCreateRes> {
    return this.actionListener(C_Report.R34_BAKING_LIST_A4).pipe(
      this.commonRequest<Create_R34_BakingListA4ReportMutation>(r34),
      map(({ data, uuid }) => ({
        data: data.wawiAssist?.create_r34_BakingListA4Report,
        uuid,
      })),
    );
  }
  r41(): Observable<ReportCreateRes> {
    return this.actionListener(C_Report.R41_BAKING_LIST_A4_DETAILED).pipe(
      this.commonRequest<Create_R41_BakingListA4DetailedReportMutation>(r41),
      map(({ data, uuid }) => ({
        data: data.wawiAssist?.create_r41_BakingListA4DetailedReport,
        uuid,
      })),
    );
  }
  r42(): Observable<ReportCreateRes> {
    return this.actionListener(C_Report.R42_FORWARDING_LIST_CUSTOMER_ARTICLES_ONE_PER_PAGE).pipe(
      this.commonRequest<Create_R42_ForwardingListCustomerArticlesOnePerPageReportMutation>(r42),
      map(({ data, uuid }) => ({
        data: data.wawiAssist?.create_r42_ForwardingListCustomerArticlesOnePerPageReport,
        uuid,
      })),
    );
  }
  r51(): Observable<ReportCreateRes> {
    return this.actionListener(C_Report.R51_DELIVERY_NOTES_IN_PRODUCTION).pipe(
      this.commonRequest<Create_R51_DeliveryNotesReportsMutation>(r51),
      map(({ data, uuid }) => ({
        data: data.wawiAssist?.create_r51_DeliveryNotesReports,
        uuid,
      })),
    );
  }
  r57(): Observable<ReportCreateRes> {
    return this.actionListener(C_Report.R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS).pipe(
      this.commonRequest<Create_R57_BakingListRawmaterials2ColumnsReportMutation>(r57),
      map(({ data, uuid }) => ({
        data: data.wawiAssist?.create_r57_BakingListRawmaterials2ColumnsReport,
        uuid,
      })),
    );
  }
  r65(): Observable<ReportCreateRes> {
    return this.actionListener(C_Report.R65_CROSSTABLE_REPORT_A4_PORTRAIT).pipe(
      this.commonRequest<Create_R65_CrosstableReportA4PortraitReportMutation>(r65),
      map(({ data, uuid }) => ({
        data: data.wawiAssist?.create_r65_CrosstableReportA4PortraitReport,
        uuid,
      })),
    );
  }
  r66(): Observable<ReportCreateRes> {
    return this.actionListener(C_Report.R66_BAKING_LIST_FOR_CAKES_AND_PIES).pipe(
      this.commonRequest<Create_R66_BakingListForCakesAndPiesReportMutation>(r66),
      map(({ data, uuid }) => ({
        data: data.wawiAssist?.create_r66_BakingListForCakesAndPiesReport,
        uuid,
      })),
    );
  }

  private commonRequest<T>(query: string) {
    return (stream$: Observable<any>) =>
      stream$.pipe(
        mergeMap(({ params }) => {
          return gqlClient(query, params.variables).pipe(
            map((data) => ({ data, uuid: params.uuid as string })),
            take(1),
          ) as Observable<{ data: T; uuid: string }>;
        }),
      );
  }
}

class ProductionWinReportService extends Service<Action> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const productionWinReportService = new ProductionWinReportService();

type Action =
  | C_Report.R1_BAKING_LIST_2_COLUMNS
  | C_Report.R11_CROSSTABLE_REPORT_A4_LANDSCAPE
  | C_Report.R14_BAKING_LIST_WITH_RECIPE_2_COLUMNS
  | C_Report.R15_FORWARDING_LIST_CUSTOMER_ARTICLES
  | C_Report.R20_FORWARDING_LIST_ARTICLE_CUSTOMERS
  | C_Report.R25_BAKING_LIST_2_COLUMNS_COMBINED
  | C_Report.R26_BAKING_LIST_WITH_RECIPE_A4
  | C_Report.R27_BAKING_LIST_WITH_RECIPE_A5
  | C_Report.R32_TOURLIST
  | C_Report.R34_BAKING_LIST_A4
  | C_Report.R41_BAKING_LIST_A4_DETAILED
  | C_Report.R42_FORWARDING_LIST_CUSTOMER_ARTICLES_ONE_PER_PAGE
  | C_Report.R51_DELIVERY_NOTES_IN_PRODUCTION
  | C_Report.R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS
  | C_Report.R65_CROSSTABLE_REPORT_A4_PORTRAIT
  | C_Report.R66_BAKING_LIST_FOR_CAKES_AND_PIES
  | undefined;

export type CommonPubArgs = {
  uuid: string;
  variables:
    | Create_R1_BakingList2ColumnsReportMutationVariables
    | Create_R11_CrosstableReportA4LandscapeReportMutationVariables
    | Create_R14_BakingListWithRecipe2ColumnsReportMutationVariables
    | Create_R15_ForwardingListCustomerArticlesReportMutationVariables
    | Create_R20_ForwardingListArticleCustomersReportMutationVariables
    | Create_R25_BakingList2ColumnsCombinedReportMutationVariables
    | Create_R26_BakingListWithRecipeA4ReportMutationVariables
    | Create_R27_BakingListWithRecipeA5ReportMutationVariables
    | Create_R32_TourlistReportMutationVariables
    | Create_R34_BakingListA4ReportMutationVariables
    | Create_R41_BakingListA4DetailedReportMutationVariables
    | Create_R42_ForwardingListCustomerArticlesOnePerPageReportMutationVariables
    | Create_R51_DeliveryNotesReportsMutationVariables
    | Create_R57_BakingListRawmaterials2ColumnsReportMutationVariables
    | Create_R65_CrosstableReportA4PortraitReportMutationVariables
    | Create_R66_BakingListForCakesAndPiesReportMutationVariables;
};
type WinReportTaskRes =
  | {
      taskId: number;
      url: string;
      s3Key: string;
      __typename?: 'WinReportTask';
    }
  | null
  | undefined;

export interface ReportCreateRes {
  data: WinReportTaskRes;
  uuid: string;
}
