import { IconButton as IButton, styled } from '@mui/material';

export const IconButton = styled(IButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ active, theme }) => ({
  ...(active && {
    backgroundColor: theme.palette.blue.light,
    '&:hover': {
      backgroundColor: theme.palette.blue.light,
    },
  }),
}));
