import { delay, forkJoin, from, map, Observable, of, retry, switchMap, zip } from 'rxjs';
import { ClientError } from 'graphql-request';

import {
  C_Global_Error_Code,
  C_Pdf_Document_Type,
  GetWa_ReportQuery,
  GetWa_ReportQueryVariables,
  ListWa_ReportsQuery,
  ListWa_ReportsQueryVariables,
  SendEmailWithReportMutation,
  SendEmailWithReportMutationVariables,
} from '../../../graphql/generatedModel';
import { Pub, Service, Sub } from '../service.abstract';
import { gqlClient } from '../../../graphql/graphqlRequest';
import { getWAReport, listWAReports, sendEmailWithReport } from './gql/reports.gql';

type Actions =
  | 'getWAReprt'
  | 'listWAReports'
  | 'presetReport'
  | 'sendEmailWithReport'
  | 'sendMultiEmailWithReport';

class PubImpl extends Pub<Actions> {
  getWAReport(params: GetWa_ReportQueryVariables) {
    this.emit('getWAReprt', params);
  }

  listWAReports(params: ListWa_ReportsQueryVariables) {
    this.emit('listWAReports', params);
  }

  sendEmailWithReport(params: SendEmailWithReportMutationVariables): void {
    this.emit('sendEmailWithReport', params);
  }

  sendMultiEmailWithReport(params: SendEmailWithReportMutationVariables[]): void {
    this.emit('sendMultiEmailWithReport', params);
  }
}

class SubImpl extends Sub<Actions> {
  getWAReport(): Observable<GetWAReportRes> {
    return this.actionListener('getWAReprt').pipe(
      switchMap(({ params }) => {
        return gqlClient(getWAReport, params) as Observable<GetWa_ReportQuery>;
      }),
      map((data) => data?.wawiAssist?.getWA_Report as GetWAReportRes),
    );
  }
  safeGetWAReport(): Observable<GetWAReportRes> {
    return this.getWAReport().pipe(
      this._retryer({
        retryCondition: ({ response }) =>
          response.errors?.[0].extensions.code === C_Global_Error_Code.GEC20_NO_DATA,
      }),
    );
  }

  listWAReports(): Observable<ListWAReportRes> {
    return this.actionListener('listWAReports').pipe(
      switchMap(({ params }) => {
        return gqlClient(listWAReports, params) as Observable<ListWa_ReportsQuery>;
      }),
      map((data) => data?.wawiAssist?.listWA_Reports as ListWAReportRes),
    );
  }
  safeListWAReports(): Observable<ListWAReportRes> {
    return this.listWAReports().pipe(
      this._retryer({
        retryCondition: ({ response }) =>
          response.errors?.[0].extensions.code === C_Global_Error_Code.GEC20_NO_DATA,
      }),
    );
  }

  sendEmailWithReport(): Observable<SendEmailRes> {
    return this.actionListener('sendEmailWithReport').pipe(
      switchMap(({ params }) => {
        const { dataRecordId, reportType } = params.params;
        return zip(
          gqlClient(sendEmailWithReport, params) as Observable<SendEmailWithReportMutation>,
          of({ dataRecordId, reportType }),
        );
      }),
      map(([data, details]) => {
        return { ...data?.wawiAssist?.sendEmailWithReport, ...details } as SendEmailRes;
      }),
    );
  }
  sendMultiEmailWithReport(): Observable<SendEmailRes[]> {
    return this.actionListener('sendMultiEmailWithReport').pipe(
      switchMap(({ params }: { params: SendEmailWithReportMutationVariables[] }) => {
        const emailsRequests = params.map((variables) => {
          const { dataRecordId, reportType } = variables.params;
          return zip(
            gqlClient(sendEmailWithReport, { ...variables }),
            of({ dataRecordId, reportType }),
          );
        });
        return forkJoin(emailsRequests).pipe(
          map((responses) => {
            return responses.map(([response, details]) => {
              return { ...response?.wawiAssist?.sendEmailWithReport, ...details } as SendEmailRes;
            }) as SendEmailRes[];
          }),
        );
      }),
    );
  }

  private _retryer<T>(options: IRetryerArgs) {
    const { retryCondition, attempts = 10, interval = 1000 } = options || {};
    return (stream: Observable<T>) => {
      return stream.pipe(
        retry({
          count: attempts,
          delay: (v: ClientError) => {
            const cond = retryCondition(v);
            if (cond) {
              return from([v]).pipe(delay(interval));
            }
            throw v;
          },
        }),
      );
    };
  }
}

class ReportsService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const reportsService = new ReportsService();

interface IRetryerArgs {
  retryCondition: (err: ClientError) => boolean;
  attempts?: number;
  interval?: number;
}

export interface SendEmailRes
  extends NonNullable<
    NonNullable<SendEmailWithReportMutation['wawiAssist']>['sendEmailWithReport']
  > {
  dataRecordId: string;
  reportType: C_Pdf_Document_Type | null;
}
export type GetWAReportRes = NonNullable<
  NonNullable<GetWa_ReportQuery['wawiAssist']>['getWA_Report']
>;
export type ListWAReportRes = NonNullable<
  NonNullable<ListWa_ReportsQuery['wawiAssist']>['listWA_Reports']
>;
