import { Observable, map } from 'rxjs';
import { Pub, Service, Sub } from '../../../../services/service.abstract';
type Actions = 'tilte';
class PubImpl extends Pub<Actions> {
  title(title: string): void {
    this.emit('tilte', title);
  }
}
class SubImpl extends Sub<Actions> {
  title(): Observable<string> {
    return this.actionListener('tilte').pipe(
      map(({ params }) => {
        return params;
      }),
    );
  }
}
class TopBarService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}
export const topbarService = new TopBarService();
