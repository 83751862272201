import { MenuItem, Select, SelectChangeEvent, Stack, styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { FC, useEffect, useState } from 'react';
import { environment } from '../../../../../environment';
import { C_Language } from '../../../../../graphql/generatedModel';
import useLanguage from '../../../../hooks/useLanguage';
import { GlobalSearch } from '../../../globalSearch/globalSearch.component';
import PageIcon from './pageIcon.component';
import { topbarService } from './topBar.service';

export const TopBar: FC = () => {
  const appEnv = import.meta.env.VITE_REACT_APP_ENV as keyof typeof environment;
  const { currentLanguage, changeLanguageHandler } = useLanguage();
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    const topbarSub = topbarService.sub.title().subscribe(setTitle);
    return () => {
      topbarSub.unsubscribe();
    };
  }, []);

  return (
    <AppBar
      position='static'
      color='inherit'
      elevation={0}
      style={{
        minHeight: 56,
      }}
    >
      <Toolbar style={{ minHeight: 'inherit', justifyContent: 'space-between' }}>
        <STitleContainer>
          <PageIcon />
          <STitle variant='h1'>{title}</STitle>
        </STitleContainer>
        {appEnv === 'development' && (
          <Select
            sx={{ marginRight: '32px' }}
            data-testid='language'
            value={currentLanguage}
            onChange={(e: SelectChangeEvent) => {
              changeLanguageHandler(e.target.value as C_Language);
            }}
            size='small'
            autoWidth
          >
            <MenuItem value={C_Language.L2_GERMAN}>de</MenuItem>
            <MenuItem value={C_Language.L1_ENGLISH}>en</MenuItem>
          </Select>
        )}
        <GlobalSearch />
      </Toolbar>
    </AppBar>
  );
};

const STitleContainer = styled(Stack)(() => ({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  flexDirection: 'row',
  '& > h1': { marginLeft: 8 },
}));

const STitle = styled(Typography)(() => ({
  marginBottom: 0,
  fontSize: 18,
  fontWeight: 'normal',
  lineHeight: 1.235,
}));
