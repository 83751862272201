import OrderIcon from '@mui/icons-material/ShoppingCartCheckout';
import { Button, Stack, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHeader } from './tableHeader.component';
import { PerformGlobalSearchRes } from '../services/globalSearch.service';
import { storageHelper } from '../../../shared/helpers/storage';
import { localeFormatterHelper } from '../../../shared/helpers/formatter/localeFormatter.helper';
import { Table } from '../../../shared/components/table/table.component';
import { navigateService } from '../../../shared/services/navigate/navigate.service';
import { Column } from '../../../shared/components/table/components/common.components';
import { globalSearchBuildUrl } from '../../../shared/components/globalSearch/globalSearchBuildUrl.util';
import { searchState } from '../../../shared/components/globalSearch/states/search.state';

export const OrderTable: FC<IOrderTable> = ({ data }) => {
  const { t } = useTranslation();
  const lessThanSixOrders = data?.length < 6;
  const [isAllDataShown, setIsAllDataShown] = useState(lessThanSixOrders);

  const handleShowAll = () => {
    setIsAllDataShown(true);
  };

  const icon = <OrderIcon color='primary' fontSize='small' />;
  const tableData = useMemo(() => {
    const firstFiveOrders = data?.slice(0, 5);
    const dataForShow = isAllDataShown ? data : firstFiveOrders;
    return dataForShow;
  }, [data, isAllDataShown]);
  let height = isAllDataShown ? 198 + 32 * (data?.length - 5) : 198;
  if (lessThanSixOrders) {
    height = 198;
  }

  const formatDate = (value: string) => localeFormatterHelper.formatDate(value);
  const formatNumberValue = (value: number) => {
    return localeFormatterHelper.formatNumber(value, {
      returnZero: false,
    });
  };

  useEffect(() => {
    const lessThanSixOrders = data?.length < 6;
    setIsAllDataShown(lessThanSixOrders);
  }, [data]);

  return (
    <>
      {tableData?.length ? (
        <Stack>
          <TableHeader
            icon={icon}
            title={t('common.order')}
            dataLength={data?.length}
            isAllDataShown={isAllDataShown}
          />
          <Table
            data={(tableData as []) || []}
            initialState={{
              columns: storageHelper.local.getItem('globalSearch.orderTable.positionColumnModel'),
            }}
            height={height}
            onChangeColumnModel={(v) =>
              storageHelper.local.setItem('globalSearch.orderTable.positionColumnModel', v)
            }
            onRowClick={(e) => {
              if (e?.row?.id) {
                navigateService.pub.navigateTo(globalSearchBuildUrl('order', e.row!));
                searchState.pub.resetSearch();
              }
            }}
          >
            <Column
              field='date'
              width={100}
              valueFormatter={({ value }) => (value ? formatDate(value) : '')}
              headerName={t('common.date')}
              sortable={false}
            />
            <Column
              field='orderNo'
              width={170}
              headerName={t('common.delivery_no')}
              sortable={false}
            />
            <Column
              field='internalOrFullName'
              width={200}
              headerName={t('order.customer')}
              flex={1}
              renderCell={({ row }) => (
                <Typography sx={{ fontSize: 14 }}>{`${row.customerNo ?? ''} ${
                  row.internalOrFullName ?? ''
                }`}</Typography>
              )}
              sortable={false}
            />
            <Column
              field='total'
              headerAlign='right'
              align='right'
              width={100}
              valueFormatter={({ value }) => (value ? formatNumberValue(value) : '')}
              headerName={t('order.total')}
              sortable={false}
            />
          </Table>
          {!isAllDataShown && (
            <Button
              onClick={handleShowAll}
              variant='outlined'
              size='small'
              sx={{ width: '200px', marginTop: '16px', margin: '16px auto 0 auto' }}
            >
              {t('common.show_all')}
            </Button>
          )}
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
};

interface IOrderTable {
  data: PerformGlobalSearchRes['orders'];
}
