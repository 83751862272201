import { Observable, map, switchMap } from 'rxjs';
import { performGlobalSearch } from './gql/globalSearch.gql';
import { Pub, Service, Sub } from '../../../shared/services/service.abstract';
import {
  ListWa_GlobalSearchResultsQuery,
  ListWa_GlobalSearchResultsQueryVariables,
} from '../../../graphql/generatedModel';
import { gqlClient } from '../../../graphql/graphqlRequest';

type Actions = 'performGlobalSearch';

class PubImpl extends Pub<Actions> {
  performGlobalSearch(params: ListWa_GlobalSearchResultsQueryVariables) {
    this.emit('performGlobalSearch', params);
  }
}

class SubImpl extends Sub<Actions> {
  performGlobalSearch(): Observable<PerformGlobalSearchRes> {
    return this.actionListener('performGlobalSearch').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          performGlobalSearch,
          params,
        ) as Observable<ListWa_GlobalSearchResultsQuery>;
      }),
      map((data: ListWa_GlobalSearchResultsQuery) => {
        return data.wawiAssist?.listWA_GlobalSearchResults as PerformGlobalSearchRes;
      }),
    );
  }
}

class GlobalSearchService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const globalSearchService = new GlobalSearchService();

export type PerformGlobalSearchRes = NonNullable<
  NonNullable<ListWa_GlobalSearchResultsQuery['wawiAssist']>['listWA_GlobalSearchResults']
>;
