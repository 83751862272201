import { LoaderFunction } from 'react-router-dom';
import { ListPaymentsRes, paymentsService } from '../services/payments.service.ts';
import { responseHandler } from '../../../shared/responseHandler/responseHandler.ts';
import { map, take, zipWith } from 'rxjs';
import { initPaymentsData, IPayments } from '../payments/states/payments.state.ts';
import { storageHelper } from '../../../shared/helpers/storage';
import { dataHelper } from '../../../shared/helpers/data/data.helper.ts';
import {
  configsData,
  DictCustomersRes,
} from '../../../shared/services/configsData/configsData.service.ts';
import { ISelectOption } from '../../../shared/components/form/fields/select.component.tsx';

export const paymentsLoader: LoaderFunction = async (): Promise<IPaymentsLoader> => {
  const initData = structuredClone(initPaymentsData);
  const sessionSort = storageHelper.local.getItem('payments.sortModel');
  if (sessionSort) {
    initData.sort = sessionSort;
  }
  initData.action = 'loader';
  const [paymentsData, customerList] = await paymentsService.globHelpers.streamToPromise(
    paymentsService.sub.listPayments().pipe(
      zipWith(
        configsData.sub.dictCustomers().pipe(
          map((data: DictCustomersRes) => {
            return data.map(
              (el) =>
                ({
                  id: String(el?.id) || '',
                  label:
                    `${String(el?.customerNo ?? '')} ${String(el?.internalOrFullName ?? '')}` || '',
                } as ISelectOption),
            );
          }),
        ),
      ),
      responseHandler<[ListPaymentsRes, ISelectOption[]]>({ errorReturnType: [[], []] }),
      take(1),
    ),
    () => {
      paymentsService.pub.listPayments({
        filter: initData.filter,
      });
      configsData.pub.common(['dictCustomers']);
    },
  );
  if (paymentsData.length && initData.sort[0].sort) {
    initData.paymentsData = dataHelper
      .data(paymentsData)
      .sort({ sortModel: initData.sort })
      .result() as IPayments['paymentsData'];
  } else {
    initData.paymentsData = paymentsData;
  }
  return { ...initData, customerList };
};

export interface IPaymentsLoader extends IPayments {
  customerList: Array<ISelectOption>;
}
