import { TypeTable } from '../../../table/table.component.tsx';
import { Theme } from '../../../../styles/theme/theme.ts';

export const viewCells: TypeTable['viewCells'] = {
  actions: {
    name: ({ row }) => {
      if (row.invalid) return 'validate';
      return '';
    },
    description: ({ row }) => {
      if (row.invalid) return 'validate';
      return '';
    },
  },
  styles: [
    {
      className: 'validate',
      cellStyles: {
        backgroundColor: Theme.palette.red.light,
      },
    },
  ],
};
