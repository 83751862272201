import {
  Popup,
  TPopupComponent,
} from '../../../../../../../shared/components/popup/popup.abstract.tsx';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TPopupDefaultActions } from '../../../../../../../shared/components/popup/services/popup.service.ts';
import { EmailContentPopup } from './content.popup.tsx';
import { Observable } from 'rxjs';

export class SendEmailPopup extends Popup<ISendEmailPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const { t } = useTranslation();
      const Wrapper = useMemo(() => this.Wrapper, []);
      const [params, setParams] = useState<IParams | undefined>();
      useEffect(() => {
        stream.state().subscribe(({ params }) => {
          if (params) {
            setParams(params);
          }
        });
      }, []);

      return params === undefined ? null : (
        <Wrapper fullWidth stream={stream} popupTitle={t('common.email')}>
          <EmailContentPopup stream={stream} params={params} />
        </Wrapper>
      );
    };
  }
}

export interface ISendEmailPopup {
  action: TPopupDefaultActions;
  params: IParams;
}

interface IParams {
  pub: () => void;
  sub: () => Observable<any>;
  customerId: string;
  invoicesList: string[];
}
