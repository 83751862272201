import { Pub, Service, Sub } from '../../../shared/services/service.abstract.ts';
import {
  ListProcurementArticlesQuery,
  ListProcurementArticlesQueryVariables,
  GetProcurementArticleQueryVariables,
  GetProcurementArticleQuery,
  SaveProcurementArticleDataMutationVariables,
  SaveProcurementArticleDataMutation,
  ListWa_ProcurementChangesInStockQuery,
  ListWa_ProcurementChangesInStockQueryVariables,
  GetArticleStorageQuantityOnDateQueryVariables,
  GetArticleStorageQuantityOnDateQuery,
  ChangeArticleStorageQuantityOnDateMutationVariables,
  ChangeArticleStorageQuantityOnDateMutation,
} from '../../../graphql/generatedModel.ts';

import { map, Observable, switchMap, tap, zip } from 'rxjs';
import { gqlClient } from '../../../graphql/graphqlRequest.ts';
import {
  getProcurementArticle,
  getProcurementChangesInStock,
  procurementArticlesList,
  saveProcurementArticleData,
  getArticleStorageQuantityOnDate,
  changeArticleStorageQuantityOnDate,
} from './gql/stockOfFreezer.gql.ts';

type Actions =
  | 'procurementArticlesList'
  | 'getProcurementArticle'
  | 'saveProcurementArticleData'
  | 'getProcurementChangesInStock'
  | 'getArticleDataAndChangesInStock'
  | 'getArticleStorageQuantityOnDate'
  | 'changeArticleStorageQuantityOnDate'
  | undefined;

class PubImpl extends Pub<Actions> {
  procurementArticlesList(params: ListProcurementArticlesQueryVariables): void {
    this.emit('procurementArticlesList', params);
  }
  getProcurementArticle(params: GetProcurementArticleQueryVariables): void {
    this.emit('getProcurementArticle', params);
  }
  getProcurementChangesInStock(params: ListWa_ProcurementChangesInStockQueryVariables): void {
    this.emit('getProcurementChangesInStock', params);
  }
  getArticleDataAndChangesInStock(params: ListWa_ProcurementChangesInStockQueryVariables): void {
    this.emit('getArticleDataAndChangesInStock', params);
  }
  getArticleStorageQuantityOnDate(params: GetArticleStorageQuantityOnDateQueryVariables): void {
    this.emit('getArticleStorageQuantityOnDate', params);
  }
  saveProcurementArticleData(params: SaveProcurementArticleDataMutationVariables): void {
    this.emit('saveProcurementArticleData', params);
  }
  changeArticleStorageQuantityOnDate(
    params: ChangeArticleStorageQuantityOnDateMutationVariables,
  ): void {
    this.emit('changeArticleStorageQuantityOnDate', params);
  }
}

class SubImpl extends Sub<Actions> {
  procurementArticlesList(): Observable<ListProcurementArticlesRes> {
    return this.actionListener('procurementArticlesList').pipe(
      switchMap(({ params }) => {
        return gqlClient(procurementArticlesList, params);
      }),
      map((data: ListProcurementArticlesQuery) => {
        return data.wawiAssist?.listWA_ProcurementArticles as ListProcurementArticlesRes;
      }),
    );
  }
  getProcurementArticle(): Observable<GetProcurementArticleRes> {
    return this.actionListener('getProcurementArticle').pipe(
      switchMap(({ params }) => {
        return gqlClient(getProcurementArticle, params);
      }),
      map((data: GetProcurementArticleQuery) => {
        return data.wawiAssist?.getWA_ProcurementArticle as GetProcurementArticleRes;
      }),
    );
  }
  getProcurementChangesInStock(): Observable<GetProcurementChangesInStockRes> {
    return this.actionListener('getProcurementChangesInStock').pipe(
      switchMap(({ params }) => {
        return gqlClient(getProcurementChangesInStock, params);
      }),
      map((data: ListWa_ProcurementChangesInStockQuery) => {
        return data.wawiAssist?.listWA_ProcurementChangesInStock?.map((values, idx) => ({
          ...values,
          id: String(idx),
        })) as GetProcurementChangesInStockRes;
      }),
    );
  }
  getArticleDataAndChangesInStock(): Observable<ArticleDataAndChangesInStockRes> {
    return this.actionListener('getArticleDataAndChangesInStock').pipe(
      tap(({ params }) => {
        stockOfFreezerService.pub.getProcurementArticle({
          id: String(params?.procurementArticleId),
        });
      }),
      switchMap(({ params }) => {
        return zip(
          this.getProcurementArticle(),
          gqlClient(getProcurementChangesInStock, params),
        ).pipe(
          map(([articleInfo, changesInStockData]) => {
            const procurementChangesInStock = (
              changesInStockData?.wawiAssist
                ?.listWA_ProcurementChangesInStock as ProcurementChangesInStockResWithoutId[]
            )?.map((values, idx) => ({
              ...values,
              id: String(idx),
            })) as GetProcurementChangesInStockRes;

            return {
              articleInfo,
              procurementChangesInStock,
            };
          }),
        );
      }),
    );
  }
  getArticleStorageQuantityOnDate(): Observable<ArticleStorageQuantityOnDateRes> {
    return this.actionListener('getArticleStorageQuantityOnDate').pipe(
      switchMap(({ params }) => {
        return gqlClient(getArticleStorageQuantityOnDate, params);
      }),
      map((data: GetArticleStorageQuantityOnDateQuery) => {
        return data.wawiAssist
          ?.getWA_ProcurementArticleStorageQuantityOnDate as ArticleStorageQuantityOnDateRes;
      }),
    );
  }
  saveProcurementArticleData(): Observable<SaveProcurementArticleDataRes> {
    return this.actionListener('saveProcurementArticleData').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          saveProcurementArticleData,
          params,
        ) as Observable<SaveProcurementArticleDataMutation>;
      }),
      map((data) => {
        return data.wawiAssist?.saveProcurementArticleData as SaveProcurementArticleDataRes;
      }),
    );
  }
  changeArticleStorageQuantityOnDate(): Observable<ChangeArticleStorageQuantityOnDateRes> {
    return this.actionListener('changeArticleStorageQuantityOnDate').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          changeArticleStorageQuantityOnDate,
          params,
        ) as Observable<ChangeArticleStorageQuantityOnDateMutation>;
      }),
      map((data) => {
        return data.wawiAssist
          ?.changeWA_ProcurementArticleStorageQuantityOnDate as ChangeArticleStorageQuantityOnDateRes;
      }),
    );
  }
}

class StockOfFreezerService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const stockOfFreezerService = new StockOfFreezerService();

export type ListProcurementArticlesRes = NonNullable<
  NonNullable<ListProcurementArticlesQuery['wawiAssist']>['listWA_ProcurementArticles']
>;

export type GetProcurementArticleRes = NonNullable<
  NonNullable<GetProcurementArticleQuery['wawiAssist']>['getWA_ProcurementArticle']
>;

type ProcurementChangesInStockResWithoutId = NonNullable<
  NonNullable<
    ListWa_ProcurementChangesInStockQuery['wawiAssist']
  >['listWA_ProcurementChangesInStock']
>[number];

type ProcurementChangesInStockRes = ProcurementChangesInStockResWithoutId & {
  id: string;
};

export type GetProcurementChangesInStockRes = ProcurementChangesInStockRes[];

export type ArticleDataAndChangesInStockRes = {
  articleInfo: GetProcurementArticleRes;
  procurementChangesInStock: GetProcurementChangesInStockRes;
};

export type SaveProcurementArticleDataRes = NonNullable<
  NonNullable<SaveProcurementArticleDataMutation['wawiAssist']>['saveProcurementArticleData']
>;

export type ArticleStorageQuantityOnDateRes = NonNullable<
  NonNullable<
    GetArticleStorageQuantityOnDateQuery['wawiAssist']
  >['getWA_ProcurementArticleStorageQuantityOnDate']
>;

export type ChangeArticleStorageQuantityOnDateRes = NonNullable<
  NonNullable<
    ChangeArticleStorageQuantityOnDateMutation['wawiAssist']
  >['changeWA_ProcurementArticleStorageQuantityOnDate']
>;
