import { CircularProgress, Stack } from '@mui/material';
import { FC, ReactNode } from 'react';
import { SContent, SLoading, SWrapper } from './components/common.component.tsx';

export const ContentLoading: FC<{
  loading: boolean;
  children: ReactNode | ReactNode[];
}> = ({ loading, children }) => {
  return (
    <SWrapper isLoading={!!loading} data-testid='loading'>
      <SContent>{children}</SContent>
      <SLoading show={loading}>
        <Stack
          justifyContent='center'
          alignItems='center'
          sx={{ background: 'white', opacity: '0.5', height: '100%' }}
        ></Stack>
        <Stack
          justifyContent='center'
          alignItems='center'
          sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        >
          <CircularProgress />
        </Stack>
      </SLoading>
    </SWrapper>
  );
};
