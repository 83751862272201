import { Controller, ControllerProps } from 'react-hook-form';
import { RadioGroup } from '@mui/material';
import { RadioGroupProps } from '@mui/material/RadioGroup/RadioGroup';
import { ChangeEvent, FC } from 'react';

export const FormRadioGroup: FC<IFormRadioGroups> = ({
  onChange,
  name,
  controllerProps = {},
  defaultValue,
  ...props
}) => {
  return (
    <Controller
      {...controllerProps}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange: onFieldChange, value, ...fieldProps } }) => (
        <RadioGroup
          {...fieldProps}
          value={value}
          onChange={(e) => {
            onFieldChange(e.target.value);
            onChange?.(e.target?.value);
          }}
          {...props}
        />
      )}
    />
  );
};

interface IFormRadioGroups extends Omit<RadioGroupProps, 'value' | 'onChange'> {
  onChange?: (v: ChangeEvent<HTMLInputElement>['target']['value']) => void;
  controllerProps?: Omit<ControllerProps, 'render' | 'name'>;
  name: ControllerProps['name'];
}
