import { Observable, filter, map } from 'rxjs';
import { Pub, Service, Sub } from '../../../../shared/services/service.abstract';

type Actions = 'isDough' | 'clean';

class PubImpl extends Pub<Actions> {
  isDough(isDough: boolean) {
    this.emit('isDough', isDough);
  }
  clean() {
    this.emit('clean');
  }
}
class SubImpl extends Sub<Actions> {
  isDough(): Observable<boolean> {
    return this.actionListener('isDough').pipe(
      filter(({ action }) => {
        return action !== 'clean';
      }),
      map(({ params }) => params),
    );
  }
}

class IsDoughService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}
export const isDoughService = new IsDoughService();
