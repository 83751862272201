import { Button, Stack, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import EmotionIco from '@mui/icons-material/InsertEmoticon';
import { useTranslation } from 'react-i18next';

export const GlobalErrorBoundary: FC = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    console.error(props);
  }, []);
  return (
    <Stack height='100%' justifyContent='center' alignItems='center'>
      <EmotionIco color='primary' sx={{ width: '150px', height: '150px' }} />
      <Typography variant='h4'>{t('errors.unknown')}</Typography>
      <Button
        sx={{ mt: 2 }}
        size='medium'
        variant='contained'
        onClick={() => {
          window.location.reload();
        }}
      >
        {t('errors.reload')}
      </Button>
    </Stack>
  );
};
