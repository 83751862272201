import { FC } from 'react';
import { IOptionsForDefaultCustomerSettings } from '../../../../../customer.tab';
import { GetDictCustomerProfileRes } from '../../../../../../../../../../../shared/components/dictionary/services/dictionaryAdditional.service';
import { PriceAndDiscount } from './priceAndDiscount/priceAndDiscount.component';
import { Invoice } from './invoice/invoice.component';
import { Payment } from './payment/payment.component';
import { CashDiscount } from './cashDiscount/cashDiscount.component';
import { Bank } from './bank/bank.component';
import { DebtorAccount } from './debtorAccount/debtorAccount.component';

export const Conditions: FC<IConditions> = (props) => {
  const { options, discountKindId } = props;

  return (
    <>
      <PriceAndDiscount options={options} discountKindId={discountKindId} />
      <Invoice />
      <Payment />
      <CashDiscount />
      <Bank options={options} />
      <DebtorAccount />
    </>
  );
};

interface IConditions {
  options: IOptionsForDefaultCustomerSettings;
  discountKindId: GetDictCustomerProfileRes['discountKindId'];
}
