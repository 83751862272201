import { format, isValid } from 'date-fns';

import {
  C_Created_Invoices_Filter_Mode as FilterMode,
  C_Language,
  C_Payments_Filter_Mode,
  ListWa_CreatedInvoicesQueryVariables as filterVariables,
} from '../../../graphql/generatedModel';
import { WeekDays } from '../../../pages/article/services/article.service';
import {
  IFormatNumberOptions,
  localeFormatterHelper,
} from '../formatter/localeFormatter.helper.ts';

export const getFirstAndLastDateOfMonth = (
  currentDate: Date,
  typeMonth: typeMonth,
): Pick<filterVariables['filter'], 'date' | 'dateTo'> => {
  const month = currentDate.getMonth() - (typeMonth === 'LAST_MONTH' ? 1 : 0);
  const year = currentDate.getFullYear();
  return {
    date: format(currentDate.setFullYear(year, month, 1), 'yyyy-MM-dd'),
    dateTo: format(currentDate.setFullYear(year, month + 1, 0), 'yyyy-MM-dd'),
  };
};

export const calculateFutureDate = (fromDate: Date, addedDays: number): Date => {
  const fromDateCopy = new Date(fromDate);
  const sixthDay = fromDateCopy.getDate() + addedDays;
  return new Date(fromDateCopy.setDate(sixthDay));
};

export const resetTimeInDate = (date: Date) => {
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getDeliveryTime = (dateOrTime: typeDateOrTime): Date | null => {
  if (typeof dateOrTime === 'object') return dateOrTime;
  if (typeof dateOrTime === 'string') {
    const parsedTime = dateOrTime?.split(':').map(Number);
    if (parsedTime) {
      const date = new Date();
      date.setHours(parsedTime[0]);
      date.setMinutes(parsedTime[1]);
      return isValid(date) ? date : null;
    }
  }
  return null;
};

export const formatDeliveryTime = (time: typeDateOrTime): string | null => {
  if (!time) {
    return null;
  }
  if (typeof time === 'string') {
    return time;
  }
  return `${time?.getHours()}:${time?.getMinutes()}`;
};

export const getLanguageCode = (lang: string): C_Language => {
  switch (lang) {
    case 'de':
      return C_Language.L2_GERMAN;
    case 'en':
      return C_Language.L1_ENGLISH;
    case 'fr':
      return C_Language.L3_FRENCH;
    default:
      return C_Language.L2_GERMAN;
  }
};

export const getCurrentYearPreviousMonthPeriod = (): { fromDate: Date; toDate: Date } => {
  const today = new Date();
  const fromDate = new Date(Date.UTC(today.getUTCFullYear() - 1, today.getUTCMonth(), 1));
  const toDate = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() + 1, 0));
  return { fromDate, toDate };
};

export const generateWeekShortTitles = (t: (key: string) => string): IShortTitleAndKey[] => [
  { key: WeekDays.isDeliveredMon, shortTitle: t('common.mon') },
  { key: WeekDays.isDeliveredTue, shortTitle: t('common.tue') },
  { key: WeekDays.isDeliveredWed, shortTitle: t('common.wed') },
  { key: WeekDays.isDeliveredThu, shortTitle: t('common.thu') },
  { key: WeekDays.isDeliveredFri, shortTitle: t('common.fri') },
  { key: WeekDays.isDeliveredSat, shortTitle: t('common.sat') },
  { key: WeekDays.isDeliveredSun, shortTitle: t('common.sun') },
];

export interface IShortTitleAndKey {
  key: WeekDays;
  shortTitle: string;
}
export const weekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const formatValue = (value: number | null, options?: IFormatNumberOptions) => {
  if (value === null) return '';
  return localeFormatterHelper.formatNumber(value, {
    precision: 3,
    noTrailingZeros: true,
    ...(options && options),
  });
};

export const formatDate = (dateString: string) => (dateString ? new Date(dateString) : null);

type typeDateOrTime = string | Date | null | undefined;

type typeMonth =
  | FilterMode.CURRENT_MONTH
  | FilterMode.LAST_MONTH
  | C_Payments_Filter_Mode.CURRENT_MONTH
  | C_Payments_Filter_Mode.LAST_MONTH;

export const cleanObject = (obj: Record<string, any>) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== undefined));
};
