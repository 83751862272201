import { gql } from 'graphql-request';

/* QUERIES start */
export const getDictTextBlock = gql`
  query GetDictWA_TextBlock($id: ID!) {
    wawiAssist {
      getDictWA_TextBlock(id: $id) {
        textBlock
      }
    }
  }
`;
export const getDictQuantityDiscount = gql`
  query GetDictQuantityDiscount($id: ID!) {
    wawiAssist {
      getDictWA_QuantityDiscount(id: $id) {
        discountValues {
          fromQuantity
          discountOnAllGroups
          discountOnGroup1
          discountOnGroup2
          discountOnGroup3
          discountOnGroup4
          discountOnGroup5
          discountOnGroup6
          discountOnGroup7
          discountOnGroup8
          discountOnGroup9
          discountOnGroup10
          discountOnGroup11
          discountOnGroup12
          discountOnGroup13
          discountOnGroup14
          discountOnGroup15
          discountOnGroup16
          discountOnGroup17
          discountOnGroup18
          discountOnGroup19
          discountOnGroup20
        }
      }
    }
  }
`;

export const getDictCustomerProfile = gql`
  query GetDictWA_CustomerProfile($id: ID!) {
    wawiAssist {
      getDictWA_CustomerProfile(id: $id) {
        id
        name
        priceCategoryId
        taxSystemId
        discountKindId
        quantityDiscountSettingId
        discountOnGroup1
        discountOnGroup2
        discountOnGroup3
        discountOnGroup4
        discountOnGroup5
        discountOnGroup6
        discountOnGroup7
        discountOnGroup8
        discountOnGroup9
        discountOnGroup10
        discountOnGroup11
        discountOnGroup12
        discountOnGroup13
        discountOnGroup14
        discountOnGroup15
        discountOnGroup16
        discountOnGroup17
        discountOnGroup18
        discountOnGroup19
        discountOnGroup20
        invoicingIntervalId
        debitorStatus
        paymentPeriod
        skonto
        skontoPeriod
        besrBankId
        debtorAccount
        slipReferenceNo
        isDeliveredMon
        isDeliveredTue
        isDeliveredWed
        isDeliveredThu
        isDeliveredFri
        isDeliveredSat
        isDeliveredSun
        orderReportId
        invoiceReportId
        invoiceSummaryReportId
        invoicePaymentSlipReportId
        customerDeliveryPeriodsSettings {
          customerRegularDeliveryPeriods {
            id
            fromDate
            toDate
            isRepeatYearly
            description
          }
          customerCustomWeekDaysAndDeliveryPeriods {
            id
            fromDate
            toDate
            isDeliveredMon
            isDeliveredTue
            isDeliveredWed
            isDeliveredThu
            isDeliveredFri
            isDeliveredSat
            isDeliveredSun
            isRepeatYearly
            description
          }
          customerVacations {
            id
            fromDate
            toDate
            description
          }
        }
      }
    }
  }
`;

export const dictSingleBank = gql`
  query getDictWA_Bank($id: ID!) {
    wawiAssist {
      getDictWA_Bank(id: $id) {
        id
        name
        internalName
        internalNameOrName
        pcAccount
        memberNo
        bankLocation
        customerName1
        customerName2
        customerStreet
        customerLocation
        fibuAccount
        flagBookingAfter
        qrIBAN
      }
    }
  }
`;
export const getDictDebtReminder = gql`
  query GetDictDebtReminder($id: ID!) {
    wawiAssist {
      getDictWA_DebtReminder(id: $id) {
        description
        reminderText
      }
    }
  }
`;
/* QUERIES end */
/* MUTATIONS start */
export const saveDictDiscountGroups = gql`
  mutation SaveDictWA_DiscountGroup($id: ID!, $fields: WA_DiscountGroupInput!) {
    wawiAssist {
      saveDictWA_DiscountGroup(id: $id, fields: $fields)
    }
  }
`;
export const deleteDictDiscountGroups = gql`
  mutation DeleteDictWA_DiscountGroup($id: ID!, $forceUnbindAndDelete: Boolean) {
    wawiAssist {
      deleteDictWA_DiscountGroup(id: $id, forceUnbindAndDelete: $forceUnbindAndDelete)
    }
  }
`;
export const saveDictPriceCategories = gql`
  mutation SaveDictWA_PriceCategory($id: ID!, $fields: WA_PriceCategoryInput!) {
    wawiAssist {
      saveDictWA_PriceCategory(id: $id, fields: $fields)
    }
  }
`;
export const deleteDictPriceCategories = gql`
  mutation DeleteDictWA_PriceCategory($id: ID!, $forceUnbindAndDelete: Boolean) {
    wawiAssist {
      deleteDictWA_PriceCategory(id: $id, forceUnbindAndDelete: $forceUnbindAndDelete)
    }
  }
`;
export const saveDictDeliveryNoteGroups = gql`
  mutation SaveDictWA_DeliveryNoteGroup($id: ID!, $fields: WA_DeliveryNoteGroupInput!) {
    wawiAssist {
      saveDictWA_DeliveryNoteGroup(id: $id, fields: $fields)
    }
  }
`;
export const deleteDictDeliveryNoteGroups = gql`
  mutation DeleteDictWA_DeliveryNoteGroup($id: ID!, $forceUnbindAndDelete: Boolean) {
    wawiAssist {
      deleteDictWA_DeliveryNoteGroup(id: $id, forceUnbindAndDelete: $forceUnbindAndDelete)
    }
  }
`;
export const createDictArticleLists = gql`
  mutation CreateDictWA_ArticleList($fields: WA_ArticleListInput!) {
    wawiAssist {
      createDictWA_ArticleList(fields: $fields) {
        id
      }
    }
  }
`;
export const saveDictArticleLists = gql`
  mutation SaveDictWA_ArticleList($id: ID!, $fields: WA_ArticleListInput!) {
    wawiAssist {
      saveDictWA_ArticleList(id: $id, fields: $fields) {
        id
      }
    }
  }
`;
export const deleteDictArticleLists = gql`
  mutation DeleteDictWA_ArticleList($id: ID!, $forceUnbindAndDelete: Boolean) {
    wawiAssist {
      deleteDictWA_ArticleList(id: $id, forceUnbindAndDelete: $forceUnbindAndDelete)
    }
  }
`;
export const createDictCustomerLists = gql`
  mutation CreateDictWA_CustomerList($fields: WA_CustomerListInput!) {
    wawiAssist {
      createDictWA_CustomerList(fields: $fields) {
        id
      }
    }
  }
`;
export const saveDictCustomerLists = gql`
  mutation SaveDictWA_CustomerList($id: ID!, $fields: WA_CustomerListInput!) {
    wawiAssist {
      saveDictWA_CustomerList(id: $id, fields: $fields) {
        id
      }
    }
  }
`;
export const deleteDictCustomerLists = gql`
  mutation DeleteDictWA_CustomerList($id: ID!, $forceUnbindAndDelete: Boolean) {
    wawiAssist {
      deleteDictWA_CustomerList(id: $id, forceUnbindAndDelete: $forceUnbindAndDelete)
    }
  }
`;
export const createDictCustomerGroups = gql`
  mutation CreateDictWA_CustomerGroup($fields: WA_CustomerGroupInput!) {
    wawiAssist {
      createDictWA_CustomerGroup(fields: $fields) {
        id
      }
    }
  }
`;
export const saveDictCustomerGroups = gql`
  mutation SaveDictWA_CustomerGroup($id: ID!, $fields: WA_CustomerGroupInput!) {
    wawiAssist {
      saveDictWA_CustomerGroup(id: $id, fields: $fields) {
        id
      }
    }
  }
`;
export const deleteDictCustomerGroups = gql`
  mutation DeleteDictWA_CustomerGroup($id: ID!, $forceUnbindAndDelete: Boolean) {
    wawiAssist {
      deleteDictWA_CustomerGroup(id: $id, forceUnbindAndDelete: $forceUnbindAndDelete)
    }
  }
`;

export const createDictPostSectors = gql`
  mutation CreateDictWA_TransportSector($fields: WA_TransportSectorInput!) {
    wawiAssist {
      createDictWA_TransportSector(fields: $fields) {
        id
      }
    }
  }
`;

export const createDictCustomerProfiles = gql`
  mutation CreateDictWA_CustomerProfile($fields: WA_CustomerProfileInput!) {
    wawiAssist {
      createDictWA_CustomerProfile(fields: $fields) {
        id
      }
    }
  }
`;

export const deleteDictCustomerProfiles = gql`
  mutation DeleteDictWA_CustomerProfile($id: ID!) {
    wawiAssist {
      deleteDictWA_CustomerProfile(id: $id)
    }
  }
`;

export const saveDictCustomerProfiles = gql`
  mutation SaveDictWA_CustomerProfile($id: ID!, $fields: WA_CustomerProfileInput!) {
    wawiAssist {
      saveDictWA_CustomerProfile(id: $id, fields: $fields) {
        id
      }
    }
  }
`;

export const getDictTransportSector = gql`
  query GetDictWA_TransportSector($id: ID!) {
    wawiAssist {
      getDictWA_TransportSector(id: $id) {
        id
        name
        isPriceRateFixed
        priceRateFixed
        priceRatesForWeight {
          id
          weight
          price
        }
      }
    }
  }
`;

export const deleteDictPostSectors = gql`
  mutation DeleteDictWA_TransportSector($id: ID!, $forceUnbindAndDelete: Boolean) {
    wawiAssist {
      deleteDictWA_TransportSector(id: $id, forceUnbindAndDelete: $forceUnbindAndDelete)
    }
  }
`;

export const saveDictPostSectors = gql`
  mutation SaveDictWA_TransportSector($id: ID!, $fields: WA_TransportSectorInput!) {
    wawiAssist {
      saveDictWA_TransportSector(id: $id, fields: $fields) {
        id
      }
    }
  }
`;

export const createDictTextBlocks = gql`
  mutation CreateDictWA_TextBlock($fields: WA_TextBlockInput!) {
    wawiAssist {
      createDictWA_TextBlock(fields: $fields) {
        id
      }
    }
  }
`;
export const saveDictTextBlocks = gql`
  mutation SaveDictWA_TextBlock($id: ID!, $fields: WA_TextBlockInput!) {
    wawiAssist {
      saveDictWA_TextBlock(id: $id, fields: $fields) {
        id
      }
    }
  }
`;
export const deleteDictTextBlocks = gql`
  mutation DeleteDictWA_TextBlock($id: ID!, $forceUnbindAndDelete: Boolean) {
    wawiAssist {
      deleteDictWA_TextBlock(id: $id, forceUnbindAndDelete: $forceUnbindAndDelete)
    }
  }
`;
export const createDictTourplans = gql`
  mutation CreateDictWA_Tourplan($fields: WA_TourplanInput!) {
    wawiAssist {
      createDictWA_Tourplan(fields: $fields) {
        id
      }
    }
  }
`;
export const saveDictTourplans = gql`
  mutation SaveDictWA_Tourplan($id: ID!, $fields: WA_TourplanInput!) {
    wawiAssist {
      saveDictWA_Tourplan(id: $id, fields: $fields) {
        id
      }
    }
  }
`;
export const deleteDictTourplans = gql`
  mutation DeleteDictWA_Tourplan($id: ID!, $forceUnbindAndDelete: Boolean) {
    wawiAssist {
      deleteDictWA_Tourplan(id: $id, forceUnbindAndDelete: $forceUnbindAndDelete)
    }
  }
`;
export const createDictProductionGroups = gql`
  mutation CreateDictWA_ProductionGroup($fields: WA_ProductionGroupInput!) {
    wawiAssist {
      createDictWA_ProductionGroup(fields: $fields) {
        id
      }
    }
  }
`;
export const saveDictProductionGroups = gql`
  mutation SaveDictWA_ProductionGroup($id: ID!, $fields: WA_ProductionGroupInput!) {
    wawiAssist {
      saveDictWA_ProductionGroup(id: $id, fields: $fields) {
        id
      }
    }
  }
`;
export const deleteDictProductionGroups = gql`
  mutation DeleteDictWA_ProductionGroup($id: ID!, $forceUnbindAndDelete: Boolean) {
    wawiAssist {
      deleteDictWA_ProductionGroup(id: $id, forceUnbindAndDelete: $forceUnbindAndDelete)
    }
  }
`;
export const createDictShippingPoints = gql`
  mutation CreateDictWA_ShippingPoint($fields: WA_ShippingPointInput!) {
    wawiAssist {
      createDictWA_ShippingPoint(fields: $fields) {
        id
      }
    }
  }
`;
export const saveDictShippingPoints = gql`
  mutation SaveDictWA_ShippingPoint($id: ID!, $fields: WA_ShippingPointInput!) {
    wawiAssist {
      saveDictWA_ShippingPoint(id: $id, fields: $fields) {
        id
      }
    }
  }
`;
export const deleteDictShippingPoints = gql`
  mutation DeleteDictWA_ShippingPoint($id: ID!, $forceUnbindAndDelete: Boolean) {
    wawiAssist {
      deleteDictWA_ShippingPoint(id: $id, forceUnbindAndDelete: $forceUnbindAndDelete)
    }
  }
`;
export const createDictMarketingGroups = gql`
  mutation CreateDictWA_MarketingGroup($fields: WA_MarketingGroupInput!) {
    wawiAssist {
      createDictWA_MarketingGroup(fields: $fields) {
        id
      }
    }
  }
`;
export const saveDictMarketingGroups = gql`
  mutation SaveDictWA_MarketingGroup($id: ID!, $fields: WA_MarketingGroupInput!) {
    wawiAssist {
      saveDictWA_MarketingGroup(id: $id, fields: $fields) {
        id
      }
    }
  }
`;
export const deleteDictMarketingGroups = gql`
  mutation DeleteDictWA_MarketingGroup($id: ID!, $forceUnbindAndDelete: Boolean) {
    wawiAssist {
      deleteDictWA_MarketingGroup(id: $id, forceUnbindAndDelete: $forceUnbindAndDelete)
    }
  }
`;
export const createDictAssortmentGroups = gql`
  mutation CreateDictWA_AssortimentGroup($fields: WA_AssortimentGroupInput!) {
    wawiAssist {
      createDictWA_AssortimentGroup(fields: $fields) {
        id
      }
    }
  }
`;
export const saveDictAssortmentGroups = gql`
  mutation SaveDictWA_AssortimentGroup($id: ID!, $fields: WA_AssortimentGroupInput!) {
    wawiAssist {
      saveDictWA_AssortimentGroup(id: $id, fields: $fields) {
        id
      }
    }
  }
`;
export const deleteDictAssortmentGroups = gql`
  mutation DeleteDictWA_AssortimentGroup($id: ID!, $forceUnbindAndDelete: Boolean) {
    wawiAssist {
      deleteDictWA_AssortimentGroup(id: $id, forceUnbindAndDelete: $forceUnbindAndDelete)
    }
  }
`;
export const createDictQuantityDiscounts = gql`
  mutation CreateDictQuantityDiscount($fields: WA_QuantityDiscountInput!) {
    wawiAssist {
      createDictWA_QuantityDiscount(fields: $fields) {
        id
      }
    }
  }
`;
export const saveDictQuantityDiscounts = gql`
  mutation SaveDictQuantityDiscount($id: ID!, $fields: WA_QuantityDiscountInput!) {
    wawiAssist {
      saveDictWA_QuantityDiscount(id: $id, fields: $fields) {
        id
      }
    }
  }
`;
export const deleteDictQuantityDiscounts = gql`
  mutation DeleteDictQuantityDiscount($id: ID!, $forceUnbindAndDelete: Boolean) {
    wawiAssist {
      deleteDictWA_QuantityDiscount(id: $id, forceUnbindAndDelete: $forceUnbindAndDelete)
    }
  }
`;
export const createDictBanks = gql`
  mutation CreateDictWA_Bank($fields: WA_BankInput!) {
    wawiAssist {
      createDictWA_Bank(fields: $fields) {
        id
      }
    }
  }
`;
export const saveDictBanks = gql`
  mutation SaveDictWA_Bank($id: ID!, $fields: WA_BankInput!) {
    wawiAssist {
      saveDictWA_Bank(id: $id, fields: $fields) {
        id
      }
    }
  }
`;
export const deleteDictBanks = gql`
  mutation DeleteDictWA_Bank($id: ID!, $forceUnbindAndDelete: Boolean) {
    wawiAssist {
      deleteDictWA_Bank(id: $id, forceUnbindAndDelete: $forceUnbindAndDelete)
    }
  }
`;
export const createDictDebtReminders = gql`
  mutation CreateDictDebtReminder($fields: WA_DebtReminderInput!) {
    wawiAssist {
      createDictWA_DebtReminder(fields: $fields) {
        id
      }
    }
  }
`;
export const saveDictDebtReminders = gql`
  mutation SaveDictDebtReminder($id: ID!, $fields: WA_DebtReminderInput!) {
    wawiAssist {
      saveDictWA_DebtReminder(id: $id, fields: $fields) {
        id
      }
    }
  }
`;
export const deleteDictDebtReminders = gql`
  mutation DeleteDictDebtReminder($id: ID!) {
    wawiAssist {
      deleteDictWA_DebtReminder(id: $id)
    }
  }
`;
export const createDictStorageLocations = gql`
  mutation CreateDictStorageLocation($fields: WA_StorageLocationInput!) {
    wawiAssist {
      createDictWA_StorageLocation(fields: $fields) {
        id
      }
    }
  }
`;
export const saveDictStorageLocations = gql`
  mutation SaveDictStorageLocation($id: ID!, $fields: WA_StorageLocationInput!) {
    wawiAssist {
      saveDictWA_StorageLocation(id: $id, fields: $fields) {
        id
        name
      }
    }
  }
`;
export const deleteDictStorageLocations = gql`
  mutation DeleteDictStorageLocation($id: ID!, $forceUnbindAndDelete: Boolean) {
    wawiAssist {
      deleteDictWA_StorageLocation(id: $id, forceUnbindAndDelete: $forceUnbindAndDelete)
    }
  }
`;
/* MUTATIONS end */
