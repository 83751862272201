import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import {
  Popup,
  TPopupComponent,
} from '../../../../../../shared/components/popup/popup.abstract.tsx';
import { TPopupDefaultActions } from '../../../../../../shared/components/popup/services/popup.service';
import { fontHelper } from '../../../../../../shared/helpers/font/font.helper';
import { CustomerTourDataRes } from '../../../../services/customer.service';
import { FormTextEditor } from '../../../../../../shared/components/form';
import { useBeforeClosePopup } from '../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';

export class NotePopup extends Popup<INotePopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const {
        resetField,
        getValues,
        formState: { isDirty },
      } = useFormContext();
      const { t } = useTranslation();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 756 },
        }));
      }, []);
      const defaultFontSize = useMemo(() => fontHelper.getDefaultFont(['fontSize']), []);
      const [defaultValues, setDefaultValues] = useState<INotePopup['values']>({
        tourplanNote: null,
      });
      useEffect(() => {
        stream.actionListener('open').subscribe((data) => {
          setDefaultValues(data?.values);
        });
      }, []);

      useBeforeClosePopup({
        stream,
        isDirty,
        handlers: () => {
          resetField('tourplanNote', { defaultValue: defaultValues?.tourplanNote });
        },
        deps: [defaultValues?.tourplanNote],
      });

      return (
        <SWrapper fullWidth stream={stream} popupTitle={t('customer.tourNote')}>
          <FormTextEditor name='tourplanNote' defaultFormat={defaultFontSize} />
          <Stack marginTop='34px' direction='row' spacing={2} justifyContent='flex-end'>
            <Button
              variant='contained'
              onClick={() => {
                const { note } = getValues();
                if (isDirty) {
                  stream.emit('save', { values: { tourplanNote: note } });
                }
                stream.emit('close');
              }}
            >
              {t('common.save')}
            </Button>
          </Stack>
        </SWrapper>
      );
    };
  }
}

interface INotePopup {
  action: 'save' | TPopupDefaultActions;
  values?: { tourplanNote: CustomerTourDataRes['tourplanNote'] };
}
