/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react-hooks/rules-of-hooks */
import { FC, ReactNode, useMemo, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton, Stack, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { map, take, tap } from 'rxjs';
import equal from 'fast-deep-equal/react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { Table } from '../../../../../../../../shared/components/table/table.component';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../shared/components/popup/popup.abstract';
import { TPopupDefaultActions } from '../../../../../../../../shared/components/popup/services/popup.service';
import {
  Column,
  Footer,
  SFooter,
} from '../../../../../../../../shared/components/table/components/common.components';
import { storageHelper } from '../../../../../../../../shared/helpers/storage';
import { FieldsRow } from '../../../../../../../../shared/components/form';
import { CrossTableEditPopup } from './crossTableEdit.popup';
import {
  defaultCrosstableConfigurationDataRes,
  initCrossTableSettings,
} from '../../generalTab.state';
import { responseHandler } from '../../../../../../../../shared/responseHandler/responseHandler.ts';
import {
  CrosstableConfigurationDataRes,
  EditPrintListGeneralTabRes,
  editPrintListService,
} from '../../../../../../services/editPrintList.service';
import {
  ISelectOption,
  Select,
} from '../../../../../../../../shared/components/form/fields/select.component';
import {
  DictCustomerListsRes,
  DictCustomersRes,
  DictProductionPrintListsRes,
} from '../../../../../../../../shared/services/configsData/configsData.service';
import { CustomIconButton } from '../../../../../../../../shared/components/form/buttons/addButton.component';
import { useBeforeClosePopup } from '../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import { C_Order_Type } from '../../../../../../../../graphql/generatedModel';
import { getCustomerOrListId } from '../../utils/generalTab.utils';

export class CrossTablePopup extends Popup<ICrossTablePopup> {
  Component: FC<TPopupComponent>;

  constructor() {
    super();
    const stream = this.innerStream;
    this.Component = () => {
      const Wrapper = useMemo(() => this.Wrapper, []);
      const { t } = useTranslation();
      const {
        getValues,
        reset,
        setValue,
        control,
        formState: { dirtyFields, isDirty, defaultValues },
      } = useFormContext();
      const crosstableConfigurationData =
        getValues()?.crosstableConfigurationData?.map(
          (setting: EditPrintListGeneralTabRes['crosstableConfigurationData'][number]) => ({
            ...setting,
            id: setting?.categoryId,
          }),
        ) || [];
      const [selectedRow, setSelectedRow] = useState<
        EditPrintListGeneralTabRes['crosstableConfigurationData'][number] | null
      >(null);
      const [values, setValues] = useState<ICrossTablePopup['values']>(undefined);
      const [selectedPrintList, setSelectedPrintList] = useState<ISelectOption | null>(null);
      const [loading, setLoading] = useState<boolean>(false);

      const crossTableEditPopup = useMemo(() => new CrossTableEditPopup(), []);
      const handleApply = useRef(() => {});

      const onClose = () => {
        if (dirtyFields?.crosstableConfigurationData) reset();
      };
      const onApply = () => {
        if (
          !equal(
            getValues()?.crosstableConfigurationData,
            defaultValues?.crosstableConfigurationData,
          )
        ) {
          handleApply.current();
        }
        setSelectedPrintList(null);
        setSelectedRow(null);
        stream.emit('close');
      };

      const onAddNewCrosstableSetting = () => {
        const lastIndex = crosstableConfigurationData.length;
        const lastCategoryId =
          crosstableConfigurationData.reduce(
            (
              maxId: number,
              setting: EditPrintListGeneralTabRes['crosstableConfigurationData'][number],
            ) => Math.max(maxId, parseInt(setting.categoryId, 10)),
            0,
          ) + 1;

        const newSetting = {
          ...initCrossTableSettings,
          id: lastCategoryId.toString(),
          categoryId: lastCategoryId.toString(),
        };
        const updatedData = [...crosstableConfigurationData, newSetting];

        const addSettings = {
          ...getValues(),
          crosstableConfigurationData: updatedData,
        };

        setValue('crosstableConfigurationData', updatedData, {
          shouldDirty: true,
        });
        control._subjects.values.next({
          type: 'change',
          name: 'crosstableConfigurationData',
          values: addSettings,
        });

        crossTableEditPopup.stream.emit('open', {
          values: {
            row: {
              ...newSetting,
              index: lastIndex,
            },
            type: 'create',
            field: 'categoryName',
            customersOptions:
              values?.customersOptions.map(({ id, customerNo, internalOrFullName }) => ({
                id,
                label: `${customerNo} ${internalOrFullName}`,
                customerNo,
                internalOrFullName,
              })) || [],
            customerListsOptions: values?.customerListsOptions || [],
          },
        });
      };
      const onDeleteCrosstableSetting = () => {
        if (selectedRow) {
          const updatedData = crosstableConfigurationData.filter(
            (setting: EditPrintListGeneralTabRes['crosstableConfigurationData'][number]) =>
              setting.id !== selectedRow.id,
          );
          const currentIndex = crosstableConfigurationData.findIndex(
            (setting: EditPrintListGeneralTabRes['crosstableConfigurationData'][number]) =>
              setting.id === selectedRow.id,
          );

          let newSelectedRow = null;
          if (currentIndex > 0) {
            newSelectedRow = updatedData[currentIndex - 1];
          } else if (currentIndex === 0 && updatedData.length > 0) {
            newSelectedRow = updatedData[0];
          }
          const deleteSettings = {
            ...getValues(),
            crosstableConfigurationData: updatedData,
          };

          setValue('crosstableConfigurationData', updatedData, {
            shouldDirty: true,
          });
          control._subjects.values.next({
            type: 'change',
            name: 'crosstableConfigurationData',
            values: deleteSettings,
          });

          setSelectedRow(newSelectedRow);
        }
      };
      const onCopyPrintList = () => {
        setLoading(true);
        setSelectedPrintList(null);
        editPrintListService.sub
          .crosstableConfigurationData()
          .pipe(
            responseHandler<CrosstableConfigurationDataRes>({
              errorReturnType: { ...defaultCrosstableConfigurationDataRes },
            }),
            map(({ crosstableConfigurationData }) => {
              const copiedSettings = {
                ...getValues(),
                crosstableConfigurationData,
              };
              setValue('crosstableConfigurationData', crosstableConfigurationData, {
                shouldDirty: true,
              });
              // Tricky solution how to set reason field with (type: change) for form watch
              control._subjects.values.next({
                type: 'change',
                name: 'crosstableConfigurationData',
                values: copiedSettings,
              });
            }),
            tap(() => setLoading(false)),
            take(1),
          )
          .subscribe();
        editPrintListService.pub.crosstableConfigurationData({
          id: String(selectedPrintList!.id),
        });
      };

      const buttonList: IButtonList[] = useMemo(
        () => [
          {
            title: t('article.addNewRecord'),
            icon: <AddIcon />,
            onClick: onAddNewCrosstableSetting,
            disabled: crosstableConfigurationData.length > 52,
          },
          {
            title: t('article.deleteRecord'),
            icon: <RemoveIcon />,
            onClick: onDeleteCrosstableSetting,
            disabled: !crosstableConfigurationData.length || !selectedRow,
          },
        ],
        [t, values, crosstableConfigurationData, selectedRow],
      );

      useBeforeClosePopup({
        stream,
        isDirty,
        handlers: () => {
          onClose();
          setSelectedPrintList(null);
          setSelectedRow(null);
        },
      });

      useEffect(() => {
        stream.actionListener('open').subscribe(({ onApply, values }) => {
          handleApply.current = onApply;
          if (values) setValues(values);
        });
      }, []);

      return (
        <Wrapper
          stream={stream}
          popupTitle={t('production.cross_table_setup')}
          PaperProps={{ sx: { maxWidth: '1200px', maxHeight: '700px' } }}
          fullWidth
        >
          <Stack direction='column' justifyContent='space-between'>
            <FieldsRow alignItems='center' spacing={1}>
              <Select
                label={t('production.copy_settings_from')}
                onChange={(_, v) => setSelectedPrintList(v)}
                value={selectedPrintList}
                options={values?.printListsOptions as ISelectOption[]}
                width='300px'
                disabled={loading}
              />
              <CustomIconButton
                size='large'
                disabled={selectedPrintList === null}
                onClick={onCopyPrintList}
                title={t('production.copy')}
              >
                <ContentCopyIcon sx={{ fill: 'rgba(0, 0, 0, 0.54)' }} />
              </CustomIconButton>
            </FieldsRow>
            <FieldsRow marginBottom={1} alignItems='center' spacing={0}>
              <Stack direction='row' spacing={2}>
                {buttonList.map(({ icon, ...props }, i) => (
                  <IconButton key={i} color='primary' size='large' {...props}>
                    {icon}
                  </IconButton>
                ))}
              </Stack>
            </FieldsRow>
            <STable
              data={crosstableConfigurationData?.map(
                (
                  setting: EditPrintListGeneralTabRes['crosstableConfigurationData'][number],
                  index: number,
                ) => ({
                  ...setting,
                  index,
                }),
              )}
              initialState={{
                columns: storageHelper.local.getItem('editPrintList.crossTableColumnModel'),
              }}
              onChangeColumnModel={(v) =>
                storageHelper.local.setItem('editPrintList.crossTableColumnModel', v)
              }
              rowSelectionModel={selectedRow?.id || undefined}
              onRowClick={(v, e) => {
                const targetElement = e.target as HTMLElement;
                const field =
                  targetElement.dataset?.field || targetElement.parentElement?.dataset?.field;
                setSelectedRow(v.row);
                crossTableEditPopup.stream.emit('open', {
                  values: {
                    row: v.row,
                    field,
                    type: 'edit',
                    customersOptions: values?.customersOptions.map(
                      ({ id, customerNo, internalOrFullName }) => ({
                        id,
                        label: `${customerNo} ${internalOrFullName}`,
                        customerNo,
                        internalOrFullName,
                      }),
                    ),
                    customerListsOptions: values?.customerListsOptions,
                  },
                });
              }}
              focusTabProps={{
                enabled: true,
                enabledFields: [
                  'categoryId',
                  'customerIndicator',
                  'categoryName',
                  'categoryCustomerOrListId',
                  'categoryFilterDeliveryType',
                  'categoryFilterSplitNo',
                ],
              }}
              onCellKeyDown={({ row, field }, { code }) => {
                if (code === 'Enter') {
                  crossTableEditPopup.stream.emit('open', {
                    values: {
                      row,
                      field,
                      type: 'edit',
                      customersOptions: values?.customersOptions.map(
                        ({ id, customerNo, internalOrFullName }) => ({
                          id,
                          label: `${customerNo} ${internalOrFullName}`,
                          customerNo,
                          internalOrFullName,
                        }),
                      ),
                      customerListsOptions: values?.customerListsOptions,
                    },
                  });
                }
              }}
              height={400}
              loading={loading}
            >
              <Column
                field='categoryName'
                headerName={t('production.group_name')}
                sortable={false}
                width={200}
              />
              <Column
                field='customerIndicator'
                headerName={t('production.filter_by')}
                sortable={false}
                width={200}
                renderCell={({ value }) => (
                  <Box p={1}>
                    {Number(value) ? t('common.customers_list') : t('common.customer')}
                  </Box>
                )}
              />
              <Column
                field='categoryCustomerOrListId'
                headerName={t('production.customer_or_customer_list')}
                sortable={false}
                width={400}
                renderCell={({ row }) => {
                  const id = getCustomerOrListId(
                    row,
                    values!.customersOptions,
                    values!.customerListsOptions,
                  );

                  return <Box p={1}>{id}</Box>;
                }}
              />
              <Column
                field='categoryFilterDeliveryType'
                headerName={t('production.order_type')}
                sortable={false}
                width={200}
                renderCell={({ value }) => {
                  const deliveryType = value
                    ? t(`enums.${C_Order_Type[value as keyof typeof C_Order_Type]}`)
                    : null;
                  return <Box p={1}>{deliveryType}</Box>;
                }}
              />
              <Column
                field='categoryFilterSplitNo'
                headerName={t('production.split_no')}
                sortable={false}
                flex={1}
              />
              <Footer>
                <SCustomFooter>{t('common.max')}: 51</SCustomFooter>
              </Footer>
            </STable>
          </Stack>
          <Stack mt={3} direction='row' justifyContent='flex-end' spacing={2}>
            <Button onClick={onApply} variant='contained' sx={{ width: 200 }}>
              {t('common.save')}
            </Button>
          </Stack>
          <crossTableEditPopup.Component />
        </Wrapper>
      );
    };
  }
}

const STable = styled(Table)({
  '& .MuiDataGrid-cell': {
    padding: 0,
  },
  '& .MuiDataGrid-cellContent': {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'inherit',
  },
});

const SCustomFooter = styled(SFooter)({
  justifyContent: 'end',
});
interface ICrossTablePopup {
  action: TPopupDefaultActions;
  onApply: () => void;
  values?: {
    printListsOptions: DictProductionPrintListsRes;
    customersOptions: DictCustomersRes;
    customerListsOptions: DictCustomerListsRes;
  };
}

interface IButtonList {
  title: string;
  icon: ReactNode;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
}
