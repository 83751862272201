import { FC, useMemo } from 'react';
import { CircularProgress, Tooltip, styled } from '@mui/material';
import { ReportPreviewStatus, StatusItem } from '../../../states/statusWorker/statusWorker.state';
import PreviewIconMUI from '@mui/icons-material/RemoveRedEye';
import ErrorIcon from '@mui/icons-material/Error';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslation } from 'react-i18next';
import { C_Win_Report_Task_Status } from '../../../../../../graphql/generatedModel';
import { Warning } from '@mui/icons-material';

export const PreviewStatusIcon: FC<IColumnPreviewProps> = ({ status, onClick, errorType }) => {
  const isClickEnabled = useMemo<boolean>(
    () =>
      status !== ReportPreviewStatus.disabled &&
      status !== ReportPreviewStatus.loading &&
      status !== ReportPreviewStatus.error,
    [status],
  );

  const handlePreview = () => {
    if (!isClickEnabled) {
      return null;
    }
    onClick();
  };

  const errorIcon = useMemo(() => {
    switch (errorType) {
      case C_Win_Report_Task_Status.WRTS15_NO_DATA_FOR_REPORT:
        return <Warning color='warning' />;
      default:
        return <ErrorIcon color='error' />;
    }
  }, [errorType]);

  return (
    <>
      {status === ReportPreviewStatus.loading ? (
        <CircularProgress size={20} />
      ) : status === ReportPreviewStatus.error ? (
        <Tooltip arrow title={<ToolTipContent onClick={onClick} errorType={errorType} />}>
          {errorIcon}
        </Tooltip>
      ) : (
        <SIconWrapper useMark={status === ReportPreviewStatus.viewed}>
          <PreviewIcon
            onClick={handlePreview}
            state={
              status === ReportPreviewStatus.disabled
                ? 'disabled'
                : status === ReportPreviewStatus.ready || status === ReportPreviewStatus.viewed
                ? 'active'
                : undefined
            }
          />
        </SIconWrapper>
      )}
    </>
  );
};

const ToolTipContent: FC<{ onClick: () => void; errorType: StatusItem['errorType'] }> = ({
  onClick,
  errorType,
}) => {
  const { t } = useTranslation();
  const errMsg = useMemo(() => {
    switch (errorType) {
      case C_Win_Report_Task_Status.WRTS15_NO_DATA_FOR_REPORT:
        return t('common.no_data');
      default:
        return t('common.server_error');
    }
  }, [errorType, t]);

  return (
    <>
      <span>{errMsg}</span>
      <br />
      <RefreshIcon
        onClick={onClick}
        sx={{ display: 'block', margin: 'auto', cursor: 'pointer', width: '16px', height: '16px' }}
      />
    </>
  );
};

const SIconWrapper = styled('div')<{ useMark: boolean }>(({ useMark, theme }) => ({
  position: 'relative',
  ...(useMark && {
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '4px',
      height: '4px',
      borderRadius: '50%',
      background: theme.palette.success.light,
    },
  }),
}));
export const PreviewIcon = styled(PreviewIconMUI)<{ state?: 'disabled' | 'active' }>(
  ({ state, theme }) => ({
    width: '20px',
    height: '20px',
    color: theme.palette.grey['600'],
    cursor: 'pointer',
    ...(state === 'disabled' && {
      opacity: 0.3,
      cursor: 'default',
    }),
    ...(state === 'active' && {
      color: theme.palette.primary.main,
    }),
  }),
);

interface IColumnPreviewProps {
  id: string | number;
  status: ReportPreviewStatus;
  onClick: () => void;
  errorType: StatusItem['errorType'];
}
