import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { TextEditor } from '../../../../../shared/components/textEditor/textEditor.component.tsx';
import { IIndividualDescriptionPopup } from './individualDescription.popup.tsx';
import { IPopupWrapper } from '../../../../../shared/components/popup/components/popup.component.tsx';
import { useBeforeClosePopup } from '../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';

export const Content: FC<IContent> = ({ stream, initRow }) => {
  const { t } = useTranslation();
  const [row, setRow] = useState<IIndividualDescriptionPopup['row']>(structuredClone(initRow));
  useBeforeClosePopup({
    stream,
    isDirty: !(
      (initRow?.indTextDeliveryNote === row?.indTextDeliveryNote || !row?.indTextDeliveryNote) &&
      (initRow?.indTextProduction === row?.indTextProduction || !row?.indTextProduction)
    ),
  });
  useEffect(() => {
    stream.actionListener('open').subscribe(({ row }) => setRow(structuredClone(row)));
  }, []);
  const getText = (text: string | null) => {
    return (
      text &&
      text
        .replace(/(<(\/?[^>]+)>)/g, '')
        .replace(/&nbsp;/g, '')
        .trim()
    );
  };
  const clearValue = (value: string | null, prevValue: string) => {
    const onlyValueText = value && getText(value);
    if (onlyValueText) {
      if (prevValue !== value)
        return value
          .replace(/&nbsp;/g, '')
          .replace(/ +/g, ' ')
          .replace(/<p>/g, '')
          .replace(/<\/p>/g, '');
      else return prevValue;
    } else return onlyValueText;
  };
  return (
    <>
      <Typography variant='h5' mb={2}>
        {t('order.text_on_production_list')}
      </Typography>
      <TextEditor
        onChange={(e) => {
          const indTextProduction = clearValue(e, row?.indTextProduction || '');
          setRow((prevVal: IIndividualDescriptionPopup['row']) => ({
            ...prevVal,
            indTextProduction,
          }));
        }}
        minHeight={86}
        toolbarItems={['bold']}
        defaultValue={row?.indTextProduction || row?.description}
      />
      <Typography variant='h5' mt={4} mb={2}>
        {t('order.text_on_delivery_note_invoice')}
      </Typography>
      <TextEditor
        onChange={(e) => {
          const indTextDeliveryNote = clearValue(e, row?.indTextDeliveryNote);
          setRow((prevVal: IIndividualDescriptionPopup['row']) => ({
            ...prevVal,
            indTextDeliveryNote,
          }));
        }}
        minHeight={86}
        toolbarItems={['bold']}
        defaultValue={row?.indTextDeliveryNote || row?.description}
      />
      <Stack mt={5} direction='row' justifyContent='flex-end' spacing={2}>
        <Button
          onClick={() => {
            stream.emit('save', { row });
            stream.emit('close');
          }}
          variant='contained'
        >
          {t('common.save')}
        </Button>
      </Stack>
    </>
  );
};

export interface IContent {
  stream: IPopupWrapper['stream'];
  initRow: IIndividualDescriptionPopup['row'];
}
