import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { format } from 'date-fns';
import {
  BehaviorSubject,
  filter,
  map,
  merge,
  Observable,
  of,
  share,
  switchMap,
  take,
  tap,
} from 'rxjs';

import {
  C_Incoming_Orders_Source,
  C_Report_Group,
  ListWa_ProductionPrintJobsQueryVariables as ProductionPrintJobsFilter,
} from '../../../../graphql/generatedModel.ts';
import { dataHelper, TypeData } from '../../../../shared/helpers/data/data.helper.ts';
import { storageHelper } from '../../../../shared/helpers/storage/index.ts';
import { companyConfigService } from '../../../../shared/services/companyConfig/companyConfig.service.ts';
import { Pub, State, Sub } from '../../../../shared/state/state.abstract.ts';
import {
  storageFilter,
  storagePrintDeliveryNotesSortModel,
  storageProductionSortModel,
} from '../../loaders/printJobs/printJobs.loader.ts';
import {
  ChangeIncomingOrdersStatusRes,
  CountOrderPreProductionRes,
  CountOrdersRes,
  FullPrintJobsDataRes,
  PrintDeliveryNotesTabRes,
  PrintJobsProductionTabRes,
  printJobsService,
  ProductionListRes,
  ProductionPrintDeliveryNotesRes,
  StatusBakery2bCAdirectRes,
} from '../../services/printJobs.service.ts';
import { StateHelpers } from './printJobsDetails.helpers.ts';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler.ts';

export enum PrintJobsTabs {
  production = 'production',
  printDeliveryNotes = 'print-delivery-notes',
}
export enum ProductionFilterListType {
  fullList = 'fullList',
  activeLists = 'activeLists',
  inactiveLists = 'inactiveLists',
}
export const initProductionFilter = {
  printListsActiveState: true,
  reportGroupIds: [
    C_Report_Group.RG1_PRODUCTION_PRODUCTION,
    C_Report_Group.RG8_PRODUCTION_FORWARDING_LISTS,
    C_Report_Group.RG9_PRODUCTION_CROSSTABLE,
    C_Report_Group.RG3_ARTICLE,
    C_Report_Group.RG11_PRODUCTION_SMARTSCALE,
  ],
};
export const tabs: IDataTabs = {
  [PrintJobsTabs.production]: undefined,
  [PrintJobsTabs.printDeliveryNotes]: undefined,
};
export type IDataTabs = {
  [tabKey in PrintJobsTabs]: IProductionTabDataRes | IDeliveryNotesTabDataRes | undefined;
};

const defaultDate = format(new Date(), 'yyyy-MM-dd');
const date = companyConfigService.getCachedConfigs()?.nextDeliveryDate || defaultDate;
const fromDate = date;
const toDate = date;

export const defaultProductionFilter = {
  fromDate,
  toDate,
  ...(storageFilter || initProductionFilter),
};
export const initPrintJobsDetailsState: IPrintJobsDetailsState = {
  action: undefined,
  dataTabs: { ...tabs },
  loading: false,
  selectedTab: PrintJobsTabs.production,
  statusList: {
    bakery2b: false,
    CAdirect: false,
  },
  datePeriod: {
    fromDate,
    toDate,
  },
};
export const initPrintJobsDetailsSortModel = (field: string): GridSortModel => [
  { field, sort: 'asc' },
];

class PubImpl extends Pub<IPrintJobsDetailsState> {
  init(params: Partial<IPrintJobsDetailsState>) {
    this.emit('init', params);
  }
  search(search: string) {
    const { dataTabs, selectedTab } = this.stream$.getValue();
    dataTabs[selectedTab]!.params.search = search;
    this.emit('search', { dataTabs });
  }
  sort(sortModel: GridSortModel) {
    const { dataTabs, selectedTab } = this.stream$.getValue();
    dataTabs[selectedTab]!.params.sortModel = sortModel;
    this.emit('sort', { dataTabs });
    storageHelper.local.setItem(
      selectedTab === PrintJobsTabs.production
        ? 'printJobs.productionSortModel'
        : 'printJobs.printDeliveryNotesSortModel',
      sortModel,
    );
  }
  filter(filter: IProductionTabDataRes['filter']) {
    const { dataTabs, selectedTab } = this.stream$.getValue();
    this.emit('filter', {
      dataTabs: {
        ...dataTabs,
        production: {
          ...dataTabs[selectedTab],
          filter,
        },
      },
    });
    storageHelper.session.setItem('printJobs.productionFilterValue', filter);
  }
  selectDatePeriod(date: string) {
    const filter =
      storageHelper.session.getItem('printJobs.productionFilterValue') || initProductionFilter;
    this.emit('selectDatePeriod', { datePeriod: { fromDate: date, toDate: date } });
    storageHelper.session.setItem('printJobs.productionFilterValue', {
      ...filter,
      fromDate: date,
      toDate: date,
    });
  }
  changeStatusBakery2B(checked: boolean) {
    const { statusList } = this.stream$.getValue();
    this.emit('changeStatusBakery2B', {
      statusList: {
        ...statusList,
        bakery2b: checked,
      },
    });
  }
  changeStatusCAdirect(checked: boolean) {
    const { statusList } = this.stream$.getValue();
    this.emit('changeStatusCAdirect', {
      statusList: {
        ...statusList,
        CAdirect: checked,
      },
    });
  }
  dataForSelectedTab(tab: PrintJobsTabs) {
    this.emit('dataForSelectedTab', { selectedTab: tab });
  }
  productionList() {
    this.emit('productionList', {});
  }
  selectProductionRecord(selectedRecord: IProductionTabDataRes['selectedRecord']) {
    const { dataTabs } = this.stream$.getValue();
    if (dataTabs.production) {
      const production = dataTabs.production as IProductionTabDataRes;
      production.selectedRecord = selectedRecord;
      dataTabs.production = production;
      this.emit('selectProductionRecord', { dataTabs });
    }
  }
  navigateInProductionList(navTo: 'next' | 'previous') {
    const { dataTabs } = this.stream$.getValue();
    const production = dataTabs.production as IProductionTabDataRes;
    production.navTo = navTo;
    dataTabs.production = production;
    this.emit('productionListNav', { dataTabs });
  }
}

class SubImpl extends Sub<IPrintJobsDetailsState> {
  private listLoading$ = new BehaviorSubject<boolean>(false);
  private shareListLoading$: Observable<boolean> = this.listLoading$.pipe(share());

  private tabLoading$ = new BehaviorSubject<boolean>(false);
  private shareTabLoading$: Observable<boolean> = this.tabLoading$.pipe(share());

  protected actionHandlers(): Observable<IPrintJobsDetailsState> {
    return merge(
      this.updateState(),
      this.dataForSelectedTab(),
      this.listenForStateUpdates(),
      this.changeStatusListValue(),
    ).pipe(
      map(({ dataTabs, selectedTab, ...rest }) => {
        const deepCopyOfDataTabs = structuredClone(dataTabs);
        const selectedTabData = deepCopyOfDataTabs[selectedTab] as TypeData[number];
        switch (selectedTab) {
          case PrintJobsTabs.production:
            selectedTabData.allProductionListLength = selectedTabData!.productionList.length;
            selectedTabData!.productionList = this.updateDataTabByParams(
              deepCopyOfDataTabs,
              selectedTab,
              'productionList',
              ['name'],
            );
            break;
          case PrintJobsTabs.printDeliveryNotes:
            selectedTabData!.printDeliveryNotesList = this.updateDataTabByParams(
              deepCopyOfDataTabs,
              selectedTab,
              'printDeliveryNotesList',
            );
            break;
          default:
            break;
        }
        return { ...rest, dataTabs: deepCopyOfDataTabs, selectedTab };
      }),
      map((streamV) => {
        const { action, dataTabs } = streamV;
        if (
          action === 'dataForSelectedTab' ||
          action === 'productionList' ||
          action === 'filter' ||
          action === 'selectDatePeriod' ||
          action === 'search'
        ) {
          const productionData = dataTabs.production as IProductionTabDataRes | undefined;
          if (productionData !== undefined) {
            streamV.dataTabs.production = { ...productionData, selectedRecord: undefined };
          }
        }
        return streamV;
      }),
      filter((v) => {
        if (v.action === 'productionListNav') {
          // List navigation handler should go after list mutation
          // such as search, filter implemented in this.updateDataTabByParams
          this.handleNavInProductionList(v);
          return false;
        }
        return true;
      }),
      tap(() => {
        this.tabLoading$.next(false);
        this.listLoading$.next(false);
      }),
    );
  }

  listLoading(): Observable<boolean> {
    return this.shareListLoading$;
  }

  tabLoading(): Observable<boolean> {
    return this.shareTabLoading$;
  }

  sharedValues(): Observable<IPrintJobsDetailsState> {
    return of(this.stream$.getValue());
  }

  /* PRIVATE METHODS */
  private updateState(): Observable<IPrintJobsDetailsState> {
    return this.actionListener([
      'init',
      'search',
      'sort',
      'selectProductionRecord',
      'productionListNav',
    ]);
  }
  private changeStatusListValue(): Observable<IPrintJobsDetailsState> {
    return this.actionListener(['changeStatusBakery2B', 'changeStatusCAdirect']).pipe(
      tap((state) => {
        const {
          statusList,
          datePeriod: { fromDate },
          action,
        } = state;
        this.selectMutationForStatusListValues(statusList, fromDate, action)
          .pipe(take(1))
          .subscribe();
      }),
    );
  }
  private dataForSelectedTab(): Observable<IPrintJobsDetailsState> {
    return this.actionListener('dataForSelectedTab').pipe(
      switchMap((state) => {
        const { dataTabs, selectedTab, datePeriod } = state;
        if (dataTabs[selectedTab]?.params) {
          dataTabs[selectedTab]!.params.search = '';
        }
        if (!dataTabs[selectedTab]) {
          this.tabLoading$.next(true);
          const sortModel =
            (selectedTab === PrintJobsTabs.production
              ? storageProductionSortModel
              : storagePrintDeliveryNotesSortModel) ||
            initPrintJobsDetailsSortModel(
              selectedTab === PrintJobsTabs.production ? 'name' : 'orderNo',
            );
          return this.queryDataForTab(selectedTab, datePeriod).pipe(
            map((dataTab) => {
              state.dataTabs[selectedTab] = {
                ...dataTab,
                params: { sortModel, search: '' },
                ...(selectedTab === PrintJobsTabs.production && {
                  filter: defaultProductionFilter,
                }),
              } as IProductionTabDataRes | IDeliveryNotesTabDataRes;
              return state;
            }),
          );
        }
        return of(state);
      }),
    );
  }
  private listenForStateUpdates(): Observable<IPrintJobsDetailsState> {
    return this.actionListener(['productionList', 'filter', 'selectDatePeriod']).pipe(
      tap(({ dataTabs, selectedTab, datePeriod: { fromDate, toDate }, action }) => {
        this.listLoading$.next(true);
        const filterValue = (dataTabs[selectedTab] as IProductionTabDataRes)?.filter;
        let filter: ProductionPrintJobsFilter['filter'] | undefined;
        if (filterValue) {
          filter = {
            ...filterValue,
            fromDate,
            toDate,
          };
        }
        this.publishDataBasedOnAction(action, selectedTab, { fromDate, toDate }, filter);
      }),
      switchMap((state) => this.fetchAndUpdateStateBasedOnAction(state)),
      map((state) => {
        const productionTabData = state.dataTabs.production as IProductionTabDataRes | undefined;
        if (productionTabData) {
          productionTabData.selectedRecord = undefined;
          state.dataTabs.production = productionTabData;
        }
        return state;
      }),
    );
  }

  /* Private non merged methods */
  private updateDataTabByParams(
    dataTabs: IDataTabs,
    selectedTab: PrintJobsTabs,
    tabDataKey: 'productionList' | 'printDeliveryNotesList',
    fieldToSearch?: string[],
  ) {
    const currentData = (dataTabs[selectedTab] as TypeData[number])?.[tabDataKey];
    const updatedData = dataHelper
      .data(currentData)
      .sort({
        sortModel: dataTabs[selectedTab]!.params.sortModel,
        callback: (data) => {
          (this.stream$.value.dataTabs[selectedTab] as TypeData[number])![tabDataKey] = data || [];
        },
      })
      .search({
        search: dataTabs[selectedTab]!.params.search,
        fields: fieldToSearch as string[],
      })
      .result();
    return updatedData;
  }
  private queryDataForTab(
    selectedTab: PrintJobsTabs,
    datePeriod: IPrintJobsDetailsState['datePeriod'],
  ): Observable<
    | Pick<IProductionTabDataRes, 'productionList' | 'countOrders' | 'countOrderPreProduction'>
    | Pick<IDeliveryNotesTabDataRes, 'printDeliveryNotesList'>
    | undefined
  > {
    const { fromDate, toDate } = datePeriod || {};
    switch (selectedTab) {
      case PrintJobsTabs.production:
        printJobsService.pub.printJobsProductionTabData({
          filter: { ...defaultProductionFilter, fromDate, toDate },
          fromDate,
          toDate,
        });
        break;
      case PrintJobsTabs.printDeliveryNotes:
        printJobsService.pub.printJobsDeliveryNotesTabData({ fromDate, toDate });
        break;
      default:
        return of(undefined);
    }
    return merge(
      printJobsService.sub.printJobsProductionTabData().pipe(
        responseHandler<PrintJobsProductionTabRes>({
          errorReturnType: {
            productionList: [],
            countOrders: 0,
            countOrderPreProduction: 0,
          },
        }),
      ),
      printJobsService.sub.printJobsDeliveryNotesTabData().pipe(
        responseHandler<PrintDeliveryNotesTabRes>({
          errorReturnType: {
            printDeliveryNotesList: [],
          },
        }),
      ),
    );
  }
  private selectMutationForStatusListValues(
    statusList: IPrintJobsDetailsState['statusList'],
    onDate: IPrintJobsDetailsState['datePeriod']['fromDate'],
    action: IPrintJobsDetailsState['action'],
  ): Observable<ChangeIncomingOrdersStatusRes | undefined> {
    switch (action) {
      case 'changeStatusBakery2B':
        printJobsService.pub.changeIncomingOrdersStatus({
          externalSourceKind: C_Incoming_Orders_Source.IOS0_bakery2b,
          onDate,
          isAccepted: statusList.bakery2b,
        });
        break;
      case 'changeStatusCAdirect':
        printJobsService.pub.changeIncomingOrdersStatus({
          externalSourceKind: C_Incoming_Orders_Source.IOS1_CashAssistDirect,
          onDate,
          isAccepted: statusList.CAdirect,
        });
        break;
      default:
        return of(undefined);
    }
    return merge(
      printJobsService.sub
        .changeIncomingOrdersStatus()
        .pipe(responseHandler<ChangeIncomingOrdersStatusRes>({ errorReturnType: false })),
    );
  }
  private publishDataBasedOnAction(
    action: IPrintJobsDetailsState['action'],
    selectedTab: PrintJobsTabs,
    datePeriod: IPrintJobsDetailsState['datePeriod'],
    productionFilter?: ProductionPrintJobsFilter['filter'],
  ): void {
    switch (action) {
      case 'selectDatePeriod':
        printJobsService.pub.fullPrintJobsDataDependedOnSelectedTab({
          selectedTab,
          datePeriod,
          filter: productionFilter,
        });
        break;

      default:
        // All other actions are responsible only for production list
        printJobsService.pub.printJobsProductionTabData({
          filter: productionFilter as ProductionPrintJobsFilter['filter'],
          fromDate: datePeriod.fromDate,
          toDate: datePeriod.toDate,
        });
    }
  }
  private fetchAndUpdateStateBasedOnAction(
    state: IPrintJobsDetailsState,
  ): Observable<IPrintJobsDetailsState> {
    switch (state.action) {
      case 'selectDatePeriod':
        state.dataTabs[
          state.selectedTab === PrintJobsTabs.production ? 'print-delivery-notes' : 'production'
        ] = undefined;
        return printJobsService.sub.fullPrintJobsDataDependedOnSelectedTab().pipe(
          responseHandler<FullPrintJobsDataRes>({
            errorReturnType: {
              dataTab: {},
              statusList: {},
            },
          }),
          map(({ dataTab, statusList }) => {
            state.statusList = statusList;
            return this.mapDataTabs(dataTab, state);
          }),
        );
      default:
        return printJobsService.sub.printJobsProductionTabData().pipe(
          responseHandler<PrintJobsProductionTabRes>({
            errorReturnType: {
              productionList: [],
              countOrders: 0,
              countOrderPreProduction: 0,
            },
          }),
          map((dataTab) => this.mapDataTabs(dataTab, state)),
        );
    }
  }
  private mapDataTabs(
    data: PrintJobsProductionTabRes | PrintDeliveryNotesTabRes,
    state: IPrintJobsDetailsState,
  ) {
    const { dataTabs, selectedTab, datePeriod } = state || {};
    dataTabs[selectedTab] = {
      ...dataTabs[selectedTab],
      ...data,
      ...(selectedTab === PrintJobsTabs.production && {
        filter: {
          ...(dataTabs[selectedTab] as IProductionTabDataRes).filter,
          fromDate: datePeriod.fromDate,
          toDate: datePeriod.toDate,
        },
      }),
    } as IProductionTabDataRes | IDeliveryNotesTabDataRes;
    return { ...state, dataTabs, selectedTab, datePeriod };
  }
  private handleNavInProductionList(streamV: IPrintJobsDetailsState): void {
    const { dataTabs } = streamV;
    if (dataTabs.production) {
      const { productionList, selectedRecord, navTo } =
        dataTabs.production as IProductionTabDataRes;
      // Disable navigation without currently selected record
      if (!selectedRecord || !navTo) return;

      const selectableRecords: ProductionListRes = productionList.filter(
        (item) =>
          printJobsDetailsState.helpers.isReportForPreview(item.reportId) &&
          printJobsDetailsState.helpers.isReportPreviewEnabled(item),
      );
      const currentInd = selectableRecords.findIndex((item) => item.id === selectedRecord.id);
      if (currentInd === -1) return;
      const navToRecord: ProductionListRes[number] | undefined =
        selectableRecords[navTo === 'next' ? currentInd + 1 : currentInd - 1];
      if (navToRecord) {
        printJobsDetailsState.pub.selectProductionRecord(navToRecord);
      }
    }
  }
}
class PrintJobsState extends State<IPrintJobsDetailsState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
  helpers = new StateHelpers(this.stream$);
}

export const printJobsDetailsState = new PrintJobsState(initPrintJobsDetailsState);

export interface IProductionTabDataRes extends TCommonTableParams {
  productionList: ProductionListRes;
  selectedRecord: ProductionListRes[number] | undefined;
  navTo: 'next' | 'previous' | undefined;
  countOrders: CountOrdersRes;
  countOrderPreProduction: CountOrderPreProductionRes;
  filter: ProductionPrintJobsFilter['filter'];
  allProductionListLength: number;
}

export interface IDeliveryNotesTabDataRes extends TCommonTableParams {
  printDeliveryNotesList: ProductionPrintDeliveryNotesRes;
}

export type TabDataRes = IDataTabs[keyof IDataTabs];

type TCommonTableParams = {
  params: {
    sortModel: GridSortModel;
    search: string;
  };
};

export interface IPrintJobsDetailsState {
  action:
    | 'init'
    | 'sort'
    | 'search'
    | 'filter'
    | 'selectDatePeriod'
    | 'dataForSelectedTab'
    | 'selectProductionRecord'
    | 'productionListNav'
    | 'changeStatusBakery2B'
    | 'changeStatusCAdirect'
    | 'productionList'
    | 'loader'
    | undefined;
  params?: any;
  dataTabs: IDataTabs;
  loading: boolean;
  statusList: StatusBakery2bCAdirectRes;
  selectedTab: PrintJobsTabs;
  datePeriod: {
    fromDate: string;
    toDate: string;
  };
}
