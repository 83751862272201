import { useFormContext } from 'react-hook-form';
import { map, take } from 'rxjs';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { TSideControlsFormValues } from '../openDebts.page';
import { snackbarService } from '../../../../shared/components/snackbar/service/snackbar.service';
import { IOpenDebtsLoader } from '../../loaders/openDeps.loader';
import { SelectedInvoiceOptions } from './sideControls.component';
import { PreviewPopup } from '../../../../shared/components/previewPopup/preview.popup';
import { SendEmailPopup } from './openDebtsGrid/popups/sendEmail/sendEmail.popup';
import { MultiEmailPopup } from './openDebtsGrid/popups/sendMultipleEmails/sendMultipleEmails.popup';
import {
  debtReports,
  IGetMultipleR10NotifierRes,
  IGetMultipleR64NotifierFilesParams,
  IGetMultipleR64NotifierFilesRes,
  IGetR64NotifierFiles,
  IMergeFilesByCustomerParams,
} from '../../services/debtReports.service';
import {
  commonRequestsService,
  MergePDFsRes,
} from '../../../../shared/services/commonRequests/commonRequests.service';
import {
  C_Pdf_Document_Type,
  C_Report,
  Create_R10_CustomersDebtNotifierMutationVariables,
  R10CustomersDebtNotifierInput,
  Wa_MergePdfInput,
} from '../../../../graphql/generatedModel';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler';

export const useHandleReportAction = ({
  openDebtsGridData,
  selectedRow,
  notifierPreviewPopup,
  emailPopup,
  multiEmailPopup,
}: IUseHandleReportActionParams) => {
  const { getValues } = useFormContext<TSideControlsFormValues>();
  const { t } = useTranslation();
  const noSelectWarning = (message: string): void => {
    snackbarService.pub.show({
      content: message,
      type: 'info',
    });
  };

  const handleAllCustomerAction = (actionType: 'preview' | 'email'): void => {
    const [invoicesDates, paymentsUpToDate, printFormId, reminderTextId] = getValues([
      'invoicesDates',
      'paymentsUpToDate',
      'printFormId',
      'reminderTextId',
    ]);
    const customerInvoices = openDebtsGridData.reduce<
      Record<string, { customerId: string; invoiceIds: Array<string> }>
    >((outObj, { customerId, invoiceId }) => {
      if (!outObj[customerId]) {
        outObj[customerId] = {
          customerId,
          invoiceIds: [invoiceId],
        };
      } else {
        outObj[customerId].invoiceIds.push(invoiceId);
      }
      return outObj;
    }, {});
    const getR10Params = ({
      customerId,
      invoiceIds,
    }: {
      customerId: string;
      invoiceIds: Array<string>;
    }): Create_R10_CustomersDebtNotifierMutationVariables => ({
      params: {
        customerId,
        invoicesList: invoiceIds,
        periodStart: format(invoicesDates.fromDate, 'yyyy-MM-dd'),
        periodEnd: format(invoicesDates.toDate, 'yyyy-MM-dd'),
        paymentsTill: format(paymentsUpToDate, 'yyyy-MM-dd'),
        reminderTextId,
      },
    });
    const getMultipleR10Params: Array<Create_R10_CustomersDebtNotifierMutationVariables> =
      Object.values(customerInvoices).map(getR10Params);
    const getMultipleR64Params: IGetMultipleR64NotifierFilesParams = Object.values(
      customerInvoices,
    ).reduce<IGetMultipleR64NotifierFilesParams>((outObj, { customerId, invoiceIds }) => {
      const r10NotifierParams = getR10Params({ customerId, invoiceIds });
      outObj[customerId] = {
        invoiceIds,
        r10NotifierParams,
      };
      return outObj;
    }, {});

    if (actionType === 'preview') {
      if (printFormId === C_Report.R10_CUSTOMERS_DEBT_NOTIFIER) {
        notifierPreviewPopup.stream.emit('open', {
          pub: () => debtReports.pub.getMultipleR10Notifier(getMultipleR10Params),
          // Since sub should be called before pub, pub for this sub is called below to run it after debtReports.sub.getMultipleR10Notifier()
          sub: () =>
            commonRequestsService.sub.mergePDFs().pipe(
              responseHandler<MergePDFsRes>({
                errorReturnType: {
                  url: '',
                  s3Key: '',
                },
              }),
              take(1),
            ),
        });
        debtReports.sub
          .getMultipleR10Notifier()
          .pipe(
            take(1),
            responseHandler<IGetMultipleR10NotifierRes>({
              customErrorHandler: () => {
                notifierPreviewPopup.stream.emit('close');
                return undefined;
              },
              errorReturnType: [],
            }),
          )
          .subscribe((res: IGetMultipleR10NotifierRes | undefined) => {
            if (res) {
              const filesData: Array<Wa_MergePdfInput> = res?.map((item) => ({
                s3Key: item.s3Key as string,
                s3Bucket: NOTIFIERS_BUCKET_NAME,
                isExternalBucket: true,
              }));
              commonRequestsService.pub.mergePDFs({ mergeItems: filesData });
            }
          });
      } else if (
        printFormId === C_Report.R64_CUSTOMERS_DEBT_NOTIFIER_WITH_INVOICE_AND_PAYMENTSLIP
      ) {
        notifierPreviewPopup.stream.emit('open', {
          pub: () => debtReports.pub.getMultipleR64NotifierFiles(getMultipleR64Params),
          // Since sub should be called before pub, pub for this sub is called below to run it after debtReports.sub.getMultipleR64NotifierFiles()
          sub: () =>
            commonRequestsService.sub.mergePDFs().pipe(
              responseHandler<MergePDFsRes>({
                errorReturnType: {
                  url: '',
                  s3Key: '',
                },
              }),
              take(1),
            ),
        });
        debtReports.sub
          .getMultipleR64NotifierFiles()
          .pipe(
            take(1),
            responseHandler<IGetMultipleR64NotifierFilesRes>({
              customErrorHandler: () => {
                notifierPreviewPopup.stream.emit('close');
                return undefined;
              },
              errorReturnType: [],
            }),
          )
          .subscribe((res: IGetMultipleR64NotifierFilesRes | undefined) => {
            if (res) {
              const filesData = res.reduce<Array<Wa_MergePdfInput>>((outArr, item) => {
                outArr.push({
                  s3Key: item.notifierS3Key,
                  s3Bucket: NOTIFIERS_BUCKET_NAME,
                  isExternalBucket: true,
                });
                item.invoices.forEach((invoice) => {
                  outArr.push({
                    s3Key: invoice.s3Key,
                    s3Bucket: invoice.bucket,
                    isExternalBucket: invoice.isExternalBucket,
                  });
                });
                return outArr;
              }, []);
              commonRequestsService.pub.mergePDFs({ mergeItems: filesData });
            }
          });
      }
    } else if (actionType === 'email') {
      if (printFormId === C_Report.R10_CUSTOMERS_DEBT_NOTIFIER) {
        multiEmailPopup.stream.emit('open', {
          params: {
            customerInvoices,
            pub: () => {
              debtReports.pub.getMultipleR10Notifier(getMultipleR10Params!);
            },
            sub: () =>
              debtReports.sub
                .getMultipleR10Notifier()
                .pipe(map((v) => v.map((res) => ({ customerId: res.customerId, url: res.url! })))),
          },
        });
      } else if (
        printFormId === C_Report.R64_CUSTOMERS_DEBT_NOTIFIER_WITH_INVOICE_AND_PAYMENTSLIP
      ) {
        multiEmailPopup.stream.emit('open', {
          params: {
            customerInvoices,
            pub: () => {
              debtReports.pub.getMultipleR64NotifierFiles(getMultipleR64Params);
            },
            // Since sub should be called before pub, pub for this sub is called below to run it after debtReports.sub.getMultipleR64NotifierFiles()
            sub: () => debtReports.sub.mergeFilesByCustomer().pipe(take(1)),
          },
        });
        debtReports.sub
          .getMultipleR64NotifierFiles()
          .pipe(
            take(1),
            responseHandler<IGetMultipleR64NotifierFilesRes>({
              customErrorHandler: () => {
                multiEmailPopup.stream.emit('close');
                return undefined;
              },
              errorReturnType: [],
            }),
          )
          .subscribe((res: IGetMultipleR64NotifierFilesRes | undefined) => {
            if (res) {
              const mergeItems: IMergeFilesByCustomerParams = res.map((item) => {
                const param = {
                  customerId: item.customerId,
                  mergeItems: [
                    {
                      s3Key: item.notifierS3Key,
                      s3Bucket: NOTIFIERS_BUCKET_NAME,
                      isExternalBucket: true,
                    },
                  ],
                };
                item.invoices.forEach((invoice) => {
                  param.mergeItems.push({
                    s3Key: invoice.s3Key,
                    s3Bucket: invoice.bucket,
                    isExternalBucket: invoice.isExternalBucket,
                  });
                });
                return param;
              });
              debtReports.pub.mergeFilesByCustomer(mergeItems);
            }
          });
      }
    }
  };

  const handleActionReport = (actionType: 'preview' | 'email') => {
    const [printFormId, previewId, sendingId, reminderTextId, invoicesDates, paymentsUpToDate] =
      getValues([
        'printFormId',
        'previewId',
        'sendingId',
        'reminderTextId',
        'invoicesDates',
        'paymentsUpToDate',
      ]);
    const selectOption = actionType === 'preview' ? previewId : sendingId;
    if (selectedRow === null && selectOption !== SelectedInvoiceOptions.allCustomers) {
      noSelectWarning(t('debitor.noRecordSelected'));
      return;
    }
    let invoicesList: Array<string> = [];
    switch (selectOption) {
      case SelectedInvoiceOptions.onlySelectedInvoice: {
        invoicesList.push(selectedRow!.invoiceId);
        break;
      }
      case SelectedInvoiceOptions.allInvoicesOfSelectedCustomer: {
        const customerInvoices: Array<string> = openDebtsGridData.reduce<Array<string>>(
          (outArr, item) => {
            if (item.customerId === selectedRow?.customerId) {
              outArr.push(item.invoiceId);
            }
            return outArr;
          },
          [],
        );
        invoicesList = invoicesList.concat(customerInvoices);
        break;
      }
      case SelectedInvoiceOptions.allCustomers: {
        handleAllCustomerAction(actionType);
        return;
      }
    }
    const customerId = selectedRow!.customerId;
    const getReportParams: R10CustomersDebtNotifierInput = {
      customerId,
      invoicesList,
      periodStart: format(invoicesDates.fromDate, 'yyyy-MM-dd'),
      periodEnd: format(invoicesDates.toDate, 'yyyy-MM-dd'),
      paymentsTill: format(paymentsUpToDate, 'yyyy-MM-dd'),
      reminderTextId,
    };
    const r64ReportPubSub = {
      pub: () =>
        debtReports.pub.getR64NotifierFiles({
          r10NotifierParams: { params: getReportParams },
          listInvoicesReportParams: {
            params: {
              reportType: C_Pdf_Document_Type.PDT2_INVOICE,
              dataRecordId: invoicesList,
              mergeFiles: true,
            },
          },
        }),
      // Since sub should be called before pub, pub for this sub is called below to run it after debtReports.sub.getR64NotifierFiles()
      sub: () =>
        commonRequestsService.sub.mergePDFs().pipe(
          responseHandler<MergePDFsRes>({
            errorReturnType: {
              url: '',
              s3Key: '',
            },
          }),
        ),
    };
    const pubForMergeR64Files = () => {
      debtReports.sub
        .getR64NotifierFiles()
        .pipe(
          take(1),
          responseHandler<IGetR64NotifierFiles>({
            customErrorHandler: () => {
              notifierPreviewPopup.stream.emit('close');
              emailPopup.stream.emit('close');
              return undefined;
            },
            errorReturnType: {
              notifierS3Key: '',
              invoice: {
                s3Key: '',
                bucket: '',
                isExternalBucket: false,
              },
            },
          }),
        )
        .subscribe((res: IGetR64NotifierFiles | undefined) => {
          const { notifierS3Key, invoice } = res || {};
          if (notifierS3Key && invoice) {
            commonRequestsService.pub.mergePDFs({
              mergeItems: [
                { s3Key: notifierS3Key, s3Bucket: NOTIFIERS_BUCKET_NAME, isExternalBucket: true },
                {
                  s3Key: invoice?.s3Key,
                  s3Bucket: invoice?.bucket,
                  isExternalBucket: invoice?.isExternalBucket,
                },
              ],
            });
          }
        });
    };

    switch (actionType) {
      case 'preview': {
        if (printFormId === C_Report.R10_CUSTOMERS_DEBT_NOTIFIER) {
          notifierPreviewPopup.stream.emit('open', {
            pub: () => debtReports.pub.getR10Notifier({ params: getReportParams }),
            sub: () => debtReports.sub.getR10Notifier(),
          });
        } else if (
          printFormId === C_Report.R64_CUSTOMERS_DEBT_NOTIFIER_WITH_INVOICE_AND_PAYMENTSLIP
        ) {
          notifierPreviewPopup.stream.emit('open', r64ReportPubSub);
          pubForMergeR64Files();
        }
        break;
      }
      case 'email': {
        if (printFormId === C_Report.R10_CUSTOMERS_DEBT_NOTIFIER) {
          emailPopup.stream.emit('open', {
            params: {
              customerId: getReportParams.customerId,
              invoicesList,
              pub: () => {
                debtReports.pub.getR10Notifier({ params: getReportParams });
              },
              sub: () => debtReports.sub.getR10Notifier(),
            },
          });
        } else if (
          printFormId === C_Report.R64_CUSTOMERS_DEBT_NOTIFIER_WITH_INVOICE_AND_PAYMENTSLIP
        ) {
          emailPopup.stream.emit('open', {
            params: {
              customerId: getReportParams.customerId,
              invoicesList,
              ...r64ReportPubSub,
            },
          });
          pubForMergeR64Files();
        }
        break;
      }
    }
  };

  return { handleActionReport };
};

const NOTIFIERS_BUCKET_NAME = 'hstemp';

interface IUseHandleReportActionParams {
  openDebtsGridData: IOpenDebtsLoader['openDebtsGridData'];
  selectedRow: IOpenDebtsLoader['selectedRow'];
  notifierPreviewPopup: PreviewPopup;
  emailPopup: SendEmailPopup;
  multiEmailPopup: MultiEmailPopup;
}
