import { FC, MouseEvent, ReactElement, ReactNode, useState } from 'react';
import { Fade, Popover } from '@mui/material';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';
import { PopoverOrigin } from '@mui/material/Popover/Popover';
import { IconButton } from '../../../pages/order/common/iconButton/iconButton.component.tsx';

export const ToggleActionButton: FC<IToggleActionButton> = ({
  icon,
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  children,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton active={open} onClick={handleClick} {...props}>
        {icon}
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        keepMounted
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{
          marginTop: 1,
        }}
        {...{ anchorOrigin, transformOrigin }}
      >
        <>{children}</>
      </Popover>
    </>
  );
};

interface IToggleActionButton extends IconButtonProps {
  icon: ReactNode;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  children: ReactElement;
}
