import { TypeTable } from '../../../../../../shared/components/table/table.component.tsx';

export const viewRows: TypeTable['viewRows'] = {
  action: () => {
    return 'row';
  },
  styles: [
    {
      className: 'row',
      rowStyles: {
        '& .button': {
          display: 'none',
          transition: '0.3s',
        },
        '&:hover .button': {
          display: 'flex',
        },
      },
    },
  ],
};
