import { Button, DialogActions, Stack } from '@mui/material';
import { FC } from 'react';
import { FieldsRow, FormTextField } from '../../form';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import AttachFileIcon from '@mui/icons-material/AttachFile';

export const EmailForm: FC = () => {
  const [t] = useTranslation();
  const {
    formState: { errors },
  } = useFormContext();

  const validateEmails = (emails: string): boolean => {
    if (!emails) {
      return false;
    }
    const regEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const arrayOfEmails = emails.replace(/\s/g, '').split(/,|;/);

    for (let i = 0; i < arrayOfEmails.length; i++) {
      if (!regEx.test(arrayOfEmails[i])) {
        return false;
      }
    }

    return true;
  };

  return (
    <>
      <Stack direction='column' spacing={3}>
        <FieldsRow>
          <FormTextField
            name='recipient'
            label={t('common.recipient')}
            controllerProps={{ rules: { validate: validateEmails } }}
            error={Boolean(errors['recipient'])}
            helperText={!!errors?.recipient ? t('common.valid_emails') : ''}
            width='100%'
          />
        </FieldsRow>
        <FieldsRow>
          <FormTextField name='subject' label={t('common.regarding')} width='100%' />
        </FieldsRow>
        <FieldsRow>
          <FormTextField name='text' multiline rows={6} width='100%' label={t('common.message')} />
        </FieldsRow>
        <FieldsRow>
          <FormTextField
            name='fileName'
            disabled
            title={t('common.facilities')}
            width='220px'
            InputProps={{
              endAdornment: <AttachFileIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
            }}
          />
        </FieldsRow>
      </Stack>
      <Stack>
        <DialogActions sx={{ mt: 6, p: 0 }}>
          <Button variant='contained' type='submit'>
            {t('common.send')}
          </Button>
        </DialogActions>
      </Stack>
    </>
  );
};
