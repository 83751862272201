/* eslint-disable react-hooks/rules-of-hooks */

import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../shared/components/popup/popup.abstract';
import { TPopupDefaultActions } from '../../../../../../../../shared/components/popup/services/popup.service';
import { useTranslation } from 'react-i18next';
import { Content } from './content.popup';
import { GridColDef } from '@mui/x-data-grid-premium';
import { styled } from '@mui/material';
import { GetDictTransportSectorRes } from '../../../../../../../../shared/components/dictionary/services/dictionaryAdditional.service';
import { map } from 'rxjs';

export class PostalWeightPopup extends Popup<IPostalWeightPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const { t } = useTranslation();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 335 },
          '& > div > div > div': { padding: 0 },
        }));
      }, []);
      const [popupTitle, setPopupTitle] = useState('');

      useEffect(() => {
        stream
          .actionListener('open')
          .pipe(map(({ values: { title } }) => `${t('settings.post_sectors_one')} | ${title}`))
          .subscribe(setPopupTitle);
      }, []);

      return (
        <SWrapper stream={stream} {...{ popupTitle }}>
          <Content stream={stream} />
        </SWrapper>
      );
    };
  }
}

export type TUpdateFunc = Dispatch<SetStateAction<GetDictTransportSectorRes>>;
export type TColumnsCreator = (updateFunc: TUpdateFunc) => GridColDef[];

export interface IPostalWeightPopup {
  action: 'save' | TPopupDefaultActions;
  values: { id: string; title: string };
}
