import { Button, Stack, Typography } from '@mui/material';
import { Popup, TPopupComponent } from '../../../../shared/components/popup/popup.abstract.tsx';
import { TPopupDefaultActions } from '../../../../shared/components/popup/services/popup.service.ts';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../../shared/styles/theme/theme.ts';
import { IProductEditCell } from '../orderGridItems/cells/product/productEdit.cell.tsx';
import { IOrderDetailsState } from '../../create/states/orderDetails/orderDetails.state.ts';
import { useBeforeClosePopup } from '../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';

export class ProductConfirmationPopup extends Popup<IProductConfirmationPopup> {
  Component: FC<TPopupComponent>;

  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const Wrapper = useMemo(() => this.Wrapper, []);
      const { t } = useTranslation();
      const [productListed, setProductListed] =
        useState<IProductConfirmationPopup['values']['productListed']>();

      useEffect(() => {
        stream
          .actionListener('open')
          .subscribe(({ values }) => setProductListed(values.productListed));
      }, []);

      useBeforeClosePopup({
        stream,
        handlers: () => {
          stream.emit('cancel');
        },
      });

      return (
        <Wrapper
          stream={stream}
          popupTitle={t('order.confirm')}
          PaperProps={{ sx: { maxWidth: '750px' } }}
          fullWidth
        >
          <Typography color='text.secondary'>
            This article already exists in the order. Would you like to have an additional, separate
            line with this item or edit the quantity in an existing line?
          </Typography>
          <Typography
            sx={{
              borderLeft: `2px solid ${Theme.palette.primary.light}`,
              padding: '3px 10px',
              display: 'flex',
              marginTop: '24px',
              alignItems: 'center',
              color: 'text.secondary',
            }}
          >
            {productListed?.articleNo} - {productListed?.description}
          </Typography>
          <Stack mt={5} direction='row' justifyContent='flex-end' spacing={2}>
            <Button onClick={() => stream.emit('addArticle')} variant='contained'>
              {t('order.add_article')}
            </Button>
            <Button
              onClick={() => {
                stream.emit('editAmount');
                stream.emit('close');
              }}
              variant='contained'
            >
              {t('order.edit_amount')}
            </Button>
          </Stack>
        </Wrapper>
      );
    };
  }
}

export interface IProductConfirmationPopup {
  action: TPopupDefaultActions | 'addArticle' | 'editAmount' | 'cancel';
  values: {
    productListed: IOrderDetailsState['selectedPos'];
    value: IProductEditCell['options'][number];
  };
}
