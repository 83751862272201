import { Button, Stack } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { orderDetailsState } from '../../../states/orderDetails.state';
import { snackbarService } from '../../../../../../shared/components/snackbar/service/snackbar.service';
import { ConfirmCreationPopup } from '../../../../common/popups/confirm.popup';
import { C_Order_Error_Code } from '../../../../../../graphql/generatedModel';

export const OrderActions: FC<IOrderActions> = ({ dirty }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const confirmCreationModal = useMemo(() => new ConfirmCreationPopup(), []);

  useEffect(() => {
    const loadingSub = orderDetailsState.sub.saveLoading().subscribe((isLoading) => {
      setLoading(isLoading);
      if (isLoading) {
        snackbarService.pub.show({
          id: 'orderSaveOrderLoading',
          type: 'loading',
          content: t('order.save_order'),
          noAutoHide: true,
        });
      } else {
        snackbarService.pub.hide('orderSaveOrderLoading');
      }
    });

    const warningSub = orderDetailsState.sub.warnings().subscribe((warnings) => {
      if (warnings) {
        if (warnings === C_Order_Error_Code.OEC6_NOT_ENOUGH_DAYS_TO_PRODUCE) {
          const content = t('order.not_enough_days_to_produce');
          confirmCreationModal.stream.emit('open', {
            content,
            onConfirm: () => {
              orderDetailsState.pub.saveOrder({
                ignoreDaysToProduce: true,
                ignoreQuantityPerLotProblems: false,
                ignoreMinMaxProblems: false,
                ignoreSplittingProblems: false,
              });
            },
          });
        }
        if (warnings === C_Order_Error_Code.OEC3_QUANTITY_PER_LOT) {
          const content = t('order.positions_lot_quantity_warning');
          confirmCreationModal.stream.emit('open', {
            content,
            onConfirm: () => {
              orderDetailsState.pub.saveOrder({
                ignoreDaysToProduce: true,
                ignoreQuantityPerLotProblems: true,
                ignoreMinMaxProblems: false,
                ignoreSplittingProblems: false,
              });
            },
          });
        }
        if (warnings === C_Order_Error_Code.OEC4_MIN_MAX) {
          const content = t('order.positions_min_max_quantity_warning');
          confirmCreationModal.stream.emit('open', {
            content,
            onConfirm: () => {
              orderDetailsState.pub.saveOrder({
                ignoreDaysToProduce: true,
                ignoreQuantityPerLotProblems: true,
                ignoreMinMaxProblems: true,
                ignoreSplittingProblems: false,
              });
            },
          });
        }
        if (warnings === C_Order_Error_Code.OEC5_SPLITTING) {
          const content = t('order.delivery_splitting_calculations_warning');
          confirmCreationModal.stream.emit('open', {
            content,
            onConfirm: () => {
              orderDetailsState.pub.saveOrder({
                ignoreDaysToProduce: true,
                ignoreQuantityPerLotProblems: true,
                ignoreMinMaxProblems: true,
                ignoreSplittingProblems: true,
              });
            },
          });
        }
      }
    });

    return () => {
      loadingSub.unsubscribe();
      warningSub.unsubscribe();
    };
  }, []);

  return (
    <Stack>
      <Button
        onClick={() => orderDetailsState.pub.saveOrder()}
        disabled={!dirty || loading}
        variant='contained'
      >
        {t('common.save')}
      </Button>
      <confirmCreationModal.Component />
    </Stack>
  );
};

interface IOrderActions {
  dirty: boolean;
}
