import { FC } from 'react';
import {
  FieldsRow,
  FieldsSection,
  FormSelect,
} from '../../../../../../../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import { IOptionsForDefaultCustomerSettings } from '../../../../../../customer.tab';
import { GetDictCustomerProfileRes } from '../../../../../../../../../../../../shared/components/dictionary/services/dictionaryAdditional.service';
import { ConditionInputs } from './conditionInputs.component';
import { useOptionsForSecectors } from '../../../../hooks/useOptionsForSecectors';

export const PriceAndDiscount: FC<IPriceAndDiscount> = ({ options, discountKindId }) => {
  const { t } = useTranslation();
  const optionsFromEnums = useOptionsForSecectors();

  return (
    <FieldsSection titleBoxSx={{ pt: 0 }} nomt title={t('customer.price_and_discount')}>
      <FieldsRow>
        <FormSelect
          width='350px'
          name='priceCategoryId'
          label={t('settings.price_category')}
          options={(options?.dictPriceCategories as []) || []}
          disableClearable
        />
        <FormSelect
          label={t('customer.vat_system')}
          width='200px'
          disableClearable
          name='taxSystemId'
          options={optionsFromEnums?.taxSystemOptions}
        />
        <FormSelect
          width='450px'
          name='discountKindId'
          label={t('customer.discountKind')}
          options={(optionsFromEnums?.discountKindIdOptions as []) || []}
          disableClearable
        />
      </FieldsRow>
      <ConditionInputs
        discountKindIdValue={discountKindId}
        discountGroups={options?.discountGroups}
        quantityDiscounts={options?.quantityDiscounts}
      />
    </FieldsSection>
  );
};

interface IPriceAndDiscount {
  options: IOptionsForDefaultCustomerSettings;
  discountKindId: GetDictCustomerProfileRes['discountKindId'];
}
