import { FC } from 'react';
import {
  FieldsRow,
  FieldsSection,
  FormSelect,
} from '../../../../../../../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import { useOptionsForSecectors } from '../../../../hooks/useOptionsForSecectors';

export const Invoice: FC = () => {
  const { t } = useTranslation();
  const optionsFromEnums = useOptionsForSecectors();

  return (
    <FieldsSection title={t('invoice.invoice')}>
      <FieldsRow>
        <FormSelect
          width='400px'
          name='invoiceReportId'
          label={t('customer.print_form')}
          options={(optionsFromEnums?.invoiceReportOptions as []) || []}
          disableClearable
        />
      </FieldsRow>
      <FieldsRow>
        <FormSelect
          width='400px'
          name='invoiceSummaryReportId'
          label={t('settings.summary_report')}
          options={(optionsFromEnums?.invoiceSummaryReportOptions as []) || []}
          disableClearable
        />
      </FieldsRow>
      <FieldsRow>
        <FormSelect
          width='400px'
          name='invoicePaymentSlipReportId'
          label={t('settings.payment_slip_report')}
          options={(optionsFromEnums?.invoicePaymentSlipReportOptions as []) || []}
          disableClearable
        />
      </FieldsRow>
    </FieldsSection>
  );
};
