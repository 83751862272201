import { Observable, map, switchMap } from 'rxjs';
import {
  ListWa_OverviewArticlesQuery,
  ListWa_OverviewArticlesQueryVariables,
  ListWa_OverviewOrdersQuery,
  ListWa_OverviewOrdersQueryVariables,
} from '../../../graphql/generatedModel';
import { Pub, Service, Sub } from '../../../shared/services/service.abstract';
import { gqlClient } from '../../../graphql/graphqlRequest';
import { getOverviewArticles, getOverviewOrders } from './gql/overview.gql';

type Actions = 'getOverviewOrders' | 'getOverviewArticles';

class PubImpl extends Pub<Actions> {
  getOverviewOrders(params: ListWa_OverviewOrdersQueryVariables) {
    this.emit('getOverviewOrders', params);
  }
  getOverviewArticles(params: ListWa_OverviewArticlesQueryVariables) {
    this.emit('getOverviewArticles', params);
  }
}

class SubImpl extends Sub<Actions> {
  getOverviewOrders(): Observable<OverviewOrdersRes> {
    return this.actionListener('getOverviewOrders').pipe(
      switchMap(({ params }) => {
        return gqlClient(getOverviewOrders, params) as Observable<ListWa_OverviewOrdersQuery>;
      }),
      map((data: ListWa_OverviewOrdersQuery) => {
        return data.wawiAssist?.listWA_OverviewOrders;
      }),
    );
  }
  getOverviewArticles(): Observable<OverviewArticlesRes> {
    return this.actionListener('getOverviewArticles').pipe(
      switchMap(({ params }) => {
        return gqlClient(getOverviewArticles, params) as Observable<ListWa_OverviewArticlesQuery>;
      }),
      map((data: ListWa_OverviewArticlesQuery) => {
        return data.wawiAssist?.listWA_OverviewArticles;
      }),
    );
  }
}

class OverviewService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const overviewService = new OverviewService();

export type OverviewOrdersRes = NonNullable<
  NonNullable<ListWa_OverviewOrdersQuery>['wawiAssist']
>['listWA_OverviewOrders'];

export type OverviewArticlesRes = NonNullable<
  NonNullable<ListWa_OverviewArticlesQuery>['wawiAssist']
>['listWA_OverviewArticles'];
