import { ITabState } from '../states/tabState.model.ts';
import { CustomerCashAssistDataRes, customerService } from '../services/customer.service.ts';
import { responseHandler } from '../../../shared/responseHandler/responseHandler.ts';
import { take } from 'rxjs';
import { C_Discounts_And_Prices_Kind } from '../../../graphql/generatedModel.ts';

export const defaultCustomerCashAssistData: CustomerCashAssistDataRes = {
  id: '',
  caPriceAndDiscountSourceId: C_Discounts_And_Prices_Kind.DNP1_DISCOUNT_WAWI_PRICE_CASH,
  isCashAssistCreditCustomer: false,
  caSpecialDiscount: null,
};

export async function resolveDataForCashAssistTab(data: ITabState): Promise<ITabState> {
  data.defaultValues = data?.customerId
    ? await customerService.globHelpers.streamToPromise(
        customerService.sub.customerCashAssistData().pipe(
          responseHandler<CustomerCashAssistDataRes>({
            errorReturnType: defaultCustomerCashAssistData,
          }),
          take(1),
        ),
        () => {
          customerService.pub.customerCashAssistData({ id: data.customerId! });
        },
      )
    : {};

  return data;
}
