import { TPopupDefaultActions } from '../../popup/services/popup.service.ts';
import { Popup, TPopupComponent } from '../../popup/popup.abstract.tsx';
import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, styled, Typography } from '@mui/material';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { Table } from '../../table/table.component.tsx';
import { Column, Footer } from '../../table/components/common.components.tsx';
import { localeFormatterHelper } from '../../../helpers/formatter/localeFormatter.helper.ts';
/* Icons */
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { useBeforeClosePopup } from '../../popup/hooks/useBeforeClosePopup.tsx';

export class DeletionConfirmationPopup extends Popup<IDeletionConfirmationPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = ({ popupTitle }) => {
      const { t } = useTranslation();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 600 },
        }));
      }, []);
      const [linkedRecordsFields, setLinkedRecordsFields] = useState<string>('');
      const [linkedRecordsJSX, setLinkedRecordsJSX] = useState<ReactElement[]>([]);
      const [customMessage, setCustomMessage] = useState('');
      const [forbiddenToDelete, setForbiddenToDelete] = useState(false);

      const generatedColumns = (data: Record<string, any>) => {
        const result: any = [];
        for (const field in data) {
          if (field !== 'id') {
            result.push(
              <Column
                flex={1}
                headerName={t(`enums.${field}`)}
                field={field}
                sortable={false}
                disableReorder
                {...(field === 'DATE' && {
                  valueFormatter: ({ value }) =>
                    value ? localeFormatterHelper.formatDate(value) : '',
                })}
              />,
            );
          }
        }
        return result;
      };

      useEffect(() => {
        stream
          .actionListener('open')
          .subscribe(({ linkedRecords, customMessage, forbiddenToDelete }) => {
            const linkedRecordsFields = Object.keys(linkedRecords || {})
              .map((field) => t(`enums.${field}`))
              .join(', ');
            setLinkedRecordsJSX(() => {
              const component: ReactElement[] = [];
              for (const key in linkedRecords) {
                const tableData = (linkedRecords[key] || []).map((el: any, i: number) => ({
                  ...el,
                  id: i,
                }));
                component.push(
                  <Accordion>
                    <AccordionSummary>
                      <Typography>{t(`enums.${key}`)}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table data={tableData} height={250}>
                        {generatedColumns(tableData[0])}
                        <Footer
                          height={36}
                          titleRows={t('common.quantity')}
                          numberRows={tableData.length}
                        />
                      </Table>
                    </AccordionDetails>
                  </Accordion>,
                );
              }
              return component;
            });
            setLinkedRecordsFields(linkedRecordsFields);
            setCustomMessage(customMessage);
            setForbiddenToDelete(forbiddenToDelete);
          });
      }, []);

      useBeforeClosePopup({
        stream,
        handlers: () => {
          stream.emit('cancel');
        },
      });

      return (
        <SWrapper fullWidth stream={stream} popupTitle={t('common.warning')}>
          <Typography variant='subtitle1'>
            {customMessage
              ? t(customMessage)
              : t('common.do_you_want_unbind_and_delete', {
                  dictionary: t(`${popupTitle}`, { count: 1 }),
                  lists: linkedRecordsFields,
                })}
          </Typography>
          <Box mt={2}>{linkedRecordsJSX}</Box>
          <Stack mt={3} direction='row' justifyContent='flex-end' spacing={2}>
            {!forbiddenToDelete && (
              <Button
                onClick={() => {
                  stream.emit('confirm');
                  stream.emit('close');
                }}
                variant='contained'
              >
                {t('common.confirm')}
              </Button>
            )}
          </Stack>
        </SWrapper>
      );
    };
  }
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:last-child': {
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
  '&:first-child': {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface IDeletionConfirmationPopup {
  action: TPopupDefaultActions | 'cancel' | 'confirm';
  linkedRecords?: Record<string, any>;
  customMessage: string;
  forbiddenToDelete: boolean;
}
