import styled from '@mui/material/styles/styled';
import { NumericField, Select, TextField } from '../../form';

export const TableNumericField = styled(NumericField)({
  height: '100%',
  '& > div': {
    fontSize: 'inherit',
  },
  '& input': {
    padding: 0,
    textAlign: 'right',
    paddingRight: 16,
    paddingLeft: 16,
  },
  '& fieldset': {
    border: 'none',
  },
});

export const TableSelect = styled(Select)({
  '& fieldset': {
    border: 'none',
  },
  '& input': {
    fontSize: 14,
  },
});

export const TableTextField = styled(TextField)({
  '& fieldset': {
    border: 'none',
  },
  '& input': {
    fontSize: 14,
  },
});
