import { TPopupDefaultActions } from '../../../../../../../../../shared/components/popup/services/popup.service.ts';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../../shared/components/popup/popup.abstract.tsx';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { ContentPopup } from './content.popup.tsx';
import { IDefaultOrderGrid } from '../../defaultOrderGrid.tsx';

export class DeliverySplittingPopup extends Popup<IDeliverySplittingPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const { t } = useTranslation();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 1910, width: '100%' },
        }));
      }, []);

      return (
        <SWrapper fullWidth stream={stream} popupTitle={t('customer.partialDelivery')}>
          <ContentPopup {...{ stream }} />
        </SWrapper>
      );
    };
  }
}

export interface IDeliverySplittingPopup {
  action: TPopupDefaultActions | 'save';
  tableData: IDefaultOrderGrid['data']['defaultOrderGridData'];
}
