import { gql } from 'graphql-request';

export const getPrintJobsProductionList = gql`
  query listWA_ProductionPrintJobs($filter: WA_ProductionPrintJobsGridFilter!) {
    wawiAssist {
      listWA_ProductionPrintJobs(filter: $filter) {
        id
        name
        isActive
        addDaysForPreProduction
        reportId
        reportGroupId
      }
    }
  }
`;

export const getPrintJobsOrdersCount = gql`
  query getPrintJobsOrdersCount($fromDate: Date!, $toDate: Date!) {
    wawiAssist {
      getWA_OrdersCountForPrintJobs(fromDate: $fromDate, toDate: $toDate)
    }
  }
`;

export const getPrintJobsPreProductionOrdersCount = gql`
  query getPrintJobsPreProductionOrdersCount($onDate: Date!) {
    wawiAssist {
      getWA_PreProductionOrdersCountForPrintJobs(onDate: $onDate)
    }
  }
`;

export const getPrintJobsProductionPrintDeliveryNotes = gql`
  query listWA_ProductionDeliveryNotes($fromDate: Date!, $toDate: Date!) {
    wawiAssist {
      listWA_ProductionDeliveryNotes(fromDate: $fromDate, toDate: $toDate) {
        id
        customerNo
        internalOrFullName
        customerDeliveryNoteGroup
        orderNo
        orderDate
        orderType
        isPrinted
        printedDateTime
      }
    }
  }
`;

export const getIncomingOrdersStatus = gql`
  query getIncomingOrdersAcceptanceStatus(
    $externalSourceKind: C_INCOMING_ORDERS_SOURCE!
    $onDate: Date!
  ) {
    wawiAssist {
      getWA_IncomingOrdersAcceptanceStatus(externalSourceKind: $externalSourceKind, onDate: $onDate)
    }
  }
`;

export const changeIncomingOrdersStatus = gql`
  mutation changeIncomingOrdersStatus(
    $externalSourceKind: C_INCOMING_ORDERS_SOURCE!
    $onDate: Date!
    $isAccepted: Boolean!
  ) {
    wawiAssist {
      saveIncomingOrdersAcceptanceStatus(
        externalSourceKind: $externalSourceKind
        onDate: $onDate
        isAccepted: $isAccepted
      )
    }
  }
`;
