import { gql } from 'graphql-request';

export const performGlobalSearch = gql`
  query ListWA_GlobalSearchResults($searchString: String!) {
    wawiAssist {
      listWA_GlobalSearchResults(searchString: $searchString) {
        articles {
          articleNo
          description
          id
          isActive
        }
        customers {
          customerNo
          id
          internalOrFullName
          isActive
        }
        invoices {
          customerNo
          date
          id
          internalOrFullName
          invoiceNo
          isDeleted
          total
        }
        orders {
          customerNo
          date
          id
          internalOrFullName
          isDeleted
          orderNo
          total
        }
      }
    }
  }
`;
