import ArticleIcon from '@mui/icons-material/ViewInAr';
import { Button, Stack } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHeader } from './tableHeader.component';
import { storageHelper } from '../../../shared/helpers/storage';
import { Table } from '../../../shared/components/table/table.component';
import { navigateService } from '../../../shared/services/navigate/navigate.service';
import { globalSearchBuildUrl } from '../../../shared/components/globalSearch/globalSearchBuildUrl.util';
import { Column } from '../../../shared/components/table/components/common.components';
import { PerformGlobalSearchRes } from '../services/globalSearch.service';
import { searchState } from '../../../shared/components/globalSearch/states/search.state';

export const ArticleTable: FC<IArticleTable> = ({ data }) => {
  const { t } = useTranslation();
  const lessThanSixArticles = data?.length < 6;
  const [isAllDataShown, setIsAllDataShown] = useState(lessThanSixArticles);

  const handleShowAll = () => {
    setIsAllDataShown(true);
  };

  const icon = <ArticleIcon color='primary' fontSize='small' />;

  const tableData = useMemo(() => {
    const firstFiveArticles = data?.slice(0, 5);
    const dataForShow = isAllDataShown ? data : firstFiveArticles;
    return dataForShow;
  }, [data, isAllDataShown]);
  let height = isAllDataShown ? 198 + 32 * (data?.length - 5) : 198;
  if (lessThanSixArticles) {
    height = 198;
  }

  useEffect(() => {
    const lessThanSixArticles = data?.length < 6;
    setIsAllDataShown(lessThanSixArticles);
  }, [data]);

  return (
    <>
      {tableData?.length ? (
        <Stack>
          <TableHeader
            icon={icon}
            title={t('article.article')}
            dataLength={data?.length}
            isAllDataShown={isAllDataShown}
          />
          <Table
            data={(tableData as []) || []}
            initialState={{
              columns: storageHelper.local.getItem('globalSearch.articleTable.positionColumnModel'),
            }}
            height={height}
            onChangeColumnModel={(v) =>
              storageHelper.local.setItem('globalSearch.articleTable.positionColumnModel', v)
            }
            onRowClick={(e) => {
              if (e?.row?.id) {
                navigateService.pub.navigateTo(globalSearchBuildUrl('article', e.row!));
                searchState.pub.resetSearch();
              }
            }}
          >
            <Column
              field='articleNo'
              headerName={t('article.article_no')}
              width={150}
              sortable={false}
            />
            <Column
              field='description'
              headerName={t('article.description_column')}
              width={100}
              flex={1}
              sortable={false}
            />
          </Table>
          {!isAllDataShown && (
            <Button
              onClick={handleShowAll}
              variant='outlined'
              size='small'
              sx={{ width: '200px', marginTop: '16px', margin: '16px auto 0 auto' }}
            >
              {t('common.show_all')}
            </Button>
          )}
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
};

interface IArticleTable {
  data: PerformGlobalSearchRes['articles'];
}
