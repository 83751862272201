import { FC } from 'react';
import styled from '@mui/material/styles/styled';
import { Paper } from '@mui/material';
import { FormTextField, TextField } from '../../form';
import { useTranslation } from 'react-i18next';
import { TInfoBlock } from '../infoBlock.components.tsx';

export const AddressField: FC<IAddressField> = ({
  register,
  addressValue,
  addressNameField,
  readOnly = false,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const { t } = useTranslation();
  const commonProps = {
    width: '100%',
    placeholder: t('common.enter_delivery_address'),
    label: t('common.address'),
    multiline: true,
    disabled: readOnly,
    minRows: 3,
    className: 'addressField',
  };

  return (
    <SAddressWrapper onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {register ? (
        <FormTextField name={addressNameField || 'specialAddress'} {...commonProps} />
      ) : (
        <TextField value={addressValue || ''} {...commonProps} />
      )}
    </SAddressWrapper>
  );
};

const SAddressWrapper = styled(Paper)(({ theme }) => ({
  padding: 10,
  fontSize: 14,
  width: 330,
  '& textarea.Mui-disabled': {
    color: theme.palette.black.main,
    WebkitTextFillColor: theme.palette.black.main,
  },
}));

interface IAddressField extends Pick<TInfoBlock, 'register' | 'addressNameField' | 'addressValue'> {
  readOnly?: boolean;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
}
