import { firstValueFrom, map, take } from 'rxjs';
import { ITabState } from '../states/tabState.model';
import { companyConfigService } from '../../../../shared/services/companyConfig/companyConfig.service';
import { companyTabLoadingService } from '../companyTabLoading.service';

export async function resolveDataForCompanyTab(data: ITabState): Promise<ITabState> {
  data.defaultValues = await firstValueFrom(
    companyConfigService.getConfigs(true).pipe(
      map((data) => {
        const {
          companySalutation,
          companyName,
          companyAddition,
          companyStreet,
          companyZipCode,
          companyCity,
          companyPhone,
          companyFax,
          companyEmail,
          companyWebsite,
          companyGLN,
          companyVatNo,
          countryId,
          bankName,
          bankBIN,
          bankAccountNo,
          bankIBAN,
          creditorIdNumber,
          bankBIC,
        } = data;
        return {
          companySalutation,
          companyName,
          companyAddition,
          companyStreet,
          companyZipCode,
          companyCity,
          companyPhone,
          companyFax,
          companyEmail,
          companyWebsite,
          companyGLN,
          companyVatNo,
          countryId,
          bankName,
          bankBIN,
          bankAccountNo,
          bankIBAN,
          creditorIdNumber,
          bankBIC,
        };
      }),
      take(1),
    ),
  );
  companyTabLoadingService.pub.loading(false);
  return data;
}
