import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { EmailForm } from '../../../../../../../shared/components/emailPopup/components/emailForm.component.tsx';
import { IPopupWrapper } from '../../../../../../../shared/components/popup/components/popup.component.tsx';
import { ISendEmailPopup } from './sendEmail.popup.tsx';
import { companyConfigService } from '../../../../../../../shared/services/companyConfig/companyConfig.service.ts';
import { useTranslation } from 'react-i18next';
import { take, zip } from 'rxjs';
import {
  CustomerEmailsDataRes,
  customerService,
} from '../../../../../../customer/services/customer.service.ts';
import { responseHandler } from '../../../../../../../shared/responseHandler/responseHandler.ts';
import { GetR10NotifierRes } from '../../../../../services/debtReports.service.ts';
import { snackbarService } from '../../../../../../../shared/components/snackbar/service/snackbar.service.ts';
import { useBeforeClosePopup } from '../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import { notificationCenter } from '../../../../../../../shared/notificationCenter/notificationCenter.ts';
import {
  openDebtsService,
  SendReminderOfInvoiceEmailRes,
} from '../../../../../services/openDebts.service.ts';
import { openDebtsState } from '../../../../states/openDebts.state.ts';

export const EmailContentPopup: FC<IEmailContentPopup> = ({ stream, params }) => {
  const { pub, sub, customerId, invoicesList } = params;
  const [loading, setLoading] = useState<boolean>(true);
  const reportUrl = useRef<string | undefined>();
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    const subscr = zip(customerService.sub.customerEmailsData(), sub())
      .pipe(
        responseHandler<[CustomerEmailsDataRes, GetR10NotifierRes]>({
          customErrorHandler: () => {
            setLoading(false);
            stream.emit('close');
            return undefined;
          },
          errorReturnType: [
            {
              id: 'errorId',
            },
            {
              url: null,
              s3Key: null,
              taskId: 0,
            },
          ],
        }),
      )
      .subscribe((v: [CustomerEmailsDataRes, GetR10NotifierRes]) => {
        const [emailData, reportData] = v || [];
        const { url, s3Key } = reportData || {};
        if (url && s3Key) {
          setValue<'recipient'>('recipient', emailData?.emailForInvoice || '');
          reportUrl.current = url;
        } else {
          snackbarService.pub.show({
            type: 'warning',
            title: t('common.no_data'),
            content: t('common.report_is_generating'),
          });
          stream.emit('close');
        }
        setLoading(false);
      });

    customerService.pub.customerEmailsData({ id: customerId.toString() });
    pub();

    return () => {
      subscr.unsubscribe();
    };
  }, []);

  const emailText = useMemo<string>(() => {
    const configs = companyConfigService.getCachedConfigs();

    return configs?.isDefaultEmailTextForDebtor
      ? t('debitor.emailMessage')
      : configs?.customEmailTextForDebtor || '';
  }, [t]);

  const defaultValues = {
    recipient: '',
    subject: t('debitor.reminder'),
    text: emailText,
    fileName: `${t('debitor.reminder')}.pdf`,
  };
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const {
    setValue,
    formState: { isDirty },
  } = formMethods;

  useBeforeClosePopup({
    stream,
    isDirty,
  });

  const submitHandler: SubmitHandler<typeof defaultValues> = (v) => {
    if (!reportUrl.current) {
      snackbarService.pub.show({
        type: 'error',
        content: t('common.problem_while_sending_email'),
      });
      stream.emit('close');
      return;
    }
    const { recipient, subject, text, fileName } = v;

    notificationCenter.notify<SendReminderOfInvoiceEmailRes>(
      openDebtsService.sub.sendReminderOfInvoiceEmail().pipe(
        responseHandler<SendReminderOfInvoiceEmailRes>({
          errorReturnType: {
            sentMessages: [],
            unsentMessages: [],
          },
        }),
      ),
      {
        type: 'snackbar',
        handler(src, target) {
          src.pipe(take(1)).subscribe({
            next(v) {
              const data = v as SendReminderOfInvoiceEmailRes;
              snackbarService.pub.hide('snackbarLoading');
              if (data.sentMessages?.length) {
                openDebtsState.pub.updateReminderCount(invoicesList);
              }
              if (data.sentMessages?.length && !data.unsentMessages?.length) {
                snackbarService.pub.show({
                  type: 'success',
                  content: t('common.email_sent_successfully', { count: data.sentMessages.length }),
                });
              }
              if (data.sentMessages?.length && data.unsentMessages?.length) {
                snackbarService.pub.show({
                  content: `${t('common.emails_sent_successfully', {
                    successCount: data.sentMessages?.length,
                  })}\n${t('common.emails_failed_to_send', {
                    failureCount: data.unsentMessages?.length,
                  })}`,
                  type: 'warning',
                });
              }
              if (!data.sentMessages?.length && data.unsentMessages?.length) {
                snackbarService.pub.show({
                  type: 'error',
                  content: t('common.problem_while_sending_email', {
                    count: data.unsentMessages.length,
                  }),
                });
              }

              target.next(v);
            },
            error(err) {
              snackbarService.pub.hide('snackbarLoading');
              snackbarService.pub.show({
                type: 'error',
                content: t('common.problem_while_sending_email'),
              });
              target.error(err);
            },
          });
        },
      },
    );

    openDebtsService.pub.sendReminderOfInvoiceEmail({
      emailParams: {
        to: recipient.replace(/\s/g, '').split(/,|;/),
        fromName: 'HS-SOFT',
        body: text,
        subject,
        attachments: [
          {
            fileName,
            url: reportUrl.current,
          },
        ],
      },
      invoiceIds: invoicesList,
    });

    stream.emit('close');
  };

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(submitHandler)}>
            <EmailForm />
          </form>
        </FormProvider>
      )}
    </>
  );
};

interface IEmailContentPopup {
  stream: IPopupWrapper['stream'];
  params: ISendEmailPopup['params'];
}
