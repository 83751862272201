import { FC, useMemo } from 'react';
import {
  FieldsRow,
  FieldsSection,
  FormNumericField,
  FormSelect,
} from '../../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import {
  ISelectOption,
  ISelectProps,
} from '../../../../../../../shared/components/form/fields/select.component';
import { C_Debitor_Status, C_Invoice_Interval } from '../../../../../../../graphql/generatedModel';
import { IConditionsTabOptions } from '../../../../../states/tabState.model';
import { useFormContext } from 'react-hook-form';

export const InvoiceSection: FC<IInvoice> = ({ customersOptions }) => {
  const { getValues } = useFormContext();

  const customerListOptions = useMemo(
    () =>
      customersOptions?.reduce(
        (outArr: ISelectOption[], { id, customerNo, internalOrFullName }) => {
          if (id && internalOrFullName)
            outArr.push({
              id: id && id.toString(),
              label: `${customerNo} ${internalOrFullName}`,
              disabled: customerNo === getValues('customerNo'),
            });
          return outArr;
        },
        [],
      ),
    [customersOptions, getValues('customerNo')],
  );
  const { t } = useTranslation();
  const invoiceIntervalOptions: ISelectProps['options'] = useMemo(
    () => [
      {
        id: C_Invoice_Interval.II1_MONTHLY,
        label: t(`enums.${C_Invoice_Interval.II1_MONTHLY}`),
      },
      {
        id: C_Invoice_Interval.II2_MONTHLY2,
        label: t(`enums.${C_Invoice_Interval.II2_MONTHLY2}`),
      },
      {
        id: C_Invoice_Interval.II3_MONTHLY3,
        label: t(`enums.${C_Invoice_Interval.II3_MONTHLY3}`),
      },
      {
        id: C_Invoice_Interval.II4_MONTHLY4,
        label: t(`enums.${C_Invoice_Interval.II4_MONTHLY4}`),
      },
      {
        id: C_Invoice_Interval.II5_HALF_MONTHLY,
        label: t(`enums.${C_Invoice_Interval.II5_HALF_MONTHLY}`),
      },
      {
        id: C_Invoice_Interval.II6_HALF_WEEKLY,
        label: t(`enums.${C_Invoice_Interval.II6_HALF_WEEKLY}`),
      },
      {
        id: C_Invoice_Interval.II7_MANUAL,
        label: t(`enums.${C_Invoice_Interval.II7_MANUAL}`),
      },
      {
        id: C_Invoice_Interval.II8_DIRECT_CASH,
        label: t(`enums.${C_Invoice_Interval.II8_DIRECT_CASH}`),
      },
      {
        id: C_Invoice_Interval.II9_DIRECT_DEBITOR,
        label: t(`enums.${C_Invoice_Interval.II9_DIRECT_DEBITOR}`),
      },
      {
        id: C_Invoice_Interval.II10_NO_INVOICE_INTERNAL,
        label: t(`enums.${C_Invoice_Interval.II10_NO_INVOICE_INTERNAL}`),
      },
    ],
    [t, C_Invoice_Interval],
  );

  const debitorStatusOptions: ISelectProps['options'] = useMemo(
    () => [
      {
        id: C_Debitor_Status.DS1_DEBITOR,
        label: t(`enums.${C_Debitor_Status.DS1_DEBITOR}`),
      },
      {
        id: C_Debitor_Status.DS2_INVOICE_DOES_NOT_INITIATE_DEBITOR,
        label: t(`enums.${C_Debitor_Status.DS2_INVOICE_DOES_NOT_INITIATE_DEBITOR}`),
      },
      {
        id: C_Debitor_Status.DS3_INTERNAL_INVOICE,
        label: t(`enums.${C_Debitor_Status.DS3_INTERNAL_INVOICE}`),
      },
    ],
    [t, C_Debitor_Status],
  );

  return (
    <FieldsSection nomt title={t('common.invoice')} sx={{ margin: '16px 0' }}>
      <FieldsRow>
        <FormSelect
          width='350px'
          label={t('customer.accounting')}
          disableClearable
          name='invoicingIntervalId'
          options={invoiceIntervalOptions}
        />
        <FormSelect
          width='350px'
          disableClearable
          label={t('customer.debtor')}
          name='debitorStatus'
          options={debitorStatusOptions}
        />
      </FieldsRow>
      <FieldsRow>
        <FormSelect
          disableClearable={false}
          width='350px'
          label={t('customer.customerIsInvoicedWith')}
          name='collectiveInvoicingToCustomerId'
          options={(customerListOptions as []) || []}
          getOptionDisabled={(option) => !!option.disabled}
        />
        <FormNumericField
          name='returnLimit'
          label={t('customer.returnLimitIn')}
          width='350px'
          noTrailingZeros
          precision={2}
          max={999}
        />
      </FieldsRow>
    </FieldsSection>
  );
};

interface IInvoice {
  customersOptions: IConditionsTabOptions['customers'];
}
