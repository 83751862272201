import { GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { TableNumericField } from '../../../../../../../../../shared/components/table/styles/styleFields';
import { GetDictTransportSectorRes } from '../../../../../../../../../shared/components/dictionary/services/dictionaryAdditional.service';

export const NumberEditCell: FC<INumberEditCell> = (props) => {
  const { value, field, id, updateFunc, precision = 0 } = props;
  const { setEditCellValue, stopCellEditMode } = props.api;

  const updateCell = useCallback(
    (v: number | null) => {
      if (typeof Number(v) === 'number' && value !== v) {
        const updatingCell = { id, field, value: v };
        setEditCellValue(updatingCell);
        updateFunc((prev) => {
          return {
            ...prev,
            priceRatesForWeight: prev?.priceRatesForWeight?.map((initRow) => {
              let updatedRow;
              if (initRow?.id === id) {
                updatedRow = { ...initRow, [field]: v };
              } else {
                updatedRow = initRow;
              }
              const { invalid: _, ...row } = updatedRow as TPriceRatesForWeight;
              const invalid = Object.values(row).some((value) => !value);
              updatedRow = { ...updatedRow, invalid };
              return updatedRow;
            }),
          };
        });
      }
    },
    [props],
  );

  const handleKeyDown = (v: React.KeyboardEvent) => {
    if (v.target instanceof HTMLInputElement && v.code === 'Enter') {
      const value = v.target.value.replace(/,/g, '.');
      updateCell(Number(value));
      stopCellEditMode({ id, field });
    }
  };

  return (
    <TableNumericField
      onKeyDown={handleKeyDown}
      onBlur={updateCell}
      autoFocus
      precision={precision}
      noTrailingZeros
      defaultValue={value}
    />
  );
};

export type TPriceRatesForWeight = NonNullable<
  GetDictTransportSectorRes['priceRatesForWeight']
>[number] & {
  invalid?: boolean;
};

export interface INumberEditCell extends GridRenderEditCellParams {
  updateFunc: Dispatch<SetStateAction<GetDictTransportSectorRes>>;
  precision?: number;
}
