import { useEffect, useState } from 'react';
import { TransitionActions, transitionService } from './service/transition.service';

export const useLoading = (show: boolean) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [lock, setLock] = useState<TransitionActions>('on');

  useEffect(() => {
    const unsubOff = transitionService.sub.off().subscribe(setLock);
    const unsubOn = transitionService.sub.on().subscribe(setLock);
    return () => {
      unsubOff.unsubscribe();
      unsubOn.unsubscribe();
    };
  }, []);
  useEffect(() => {
    if (show === true && lock === 'on') {
      setLoading(true);
    }
  }, [show, lock]);

  const animationHandle = () => {
    if (show === false) {
      setLoading(false);
    }
  };
  return { loading, animationHandle };
};
