import { LoaderFunction } from 'react-router-dom';

import {
  editPrintListState,
  initEditPrintListState,
} from '../../editPrintList/states/editPrintList.state';
import {
  EditPrintListTabs,
  ITabState,
  tabStateModel,
} from '../../editPrintList/states/tabState.model';
import { editPrintListTabLoadingService } from '../../editPrintList/components/tabs/editPrintListTabLoading.service';
import { IEditPrintListLoader, resolveEditPrintListData } from './editPrintList.resolver';
import {
  resolveDataForGeneralTab,
  resolveTourTabOptions,
  resolveCustomersOptions,
  resolveCustomerListsOptions,
  resolveProductionPrintListsOptions,
  resolveArticleListsOptions,
} from './generalTab.resolver';

function checkTab404(selectedTab: EditPrintListTabs) {
  if (!Object.values(EditPrintListTabs).includes(selectedTab)) {
    location.href = '/404';
  }
}

export const editPrintListLoader: LoaderFunction = async () => {
  let initListData = { ...initEditPrintListState };
  initListData.action = 'loader';
  initListData = await resolveEditPrintListData(initListData);
  return { initListData } as IEditPrintListLoader;
};

export const editPrintListTabLoader: LoaderFunction = async ({ params, context }) => {
  const tab = params.tab as EditPrintListTabs;
  checkTab404(tab);
  const tabState = { ...tabStateModel };
  let selectedPrintList: IEditPrintListLoader['initListData']['selectedPrintList'] | undefined;
  if (context?.parentInitData) {
    selectedPrintList = (context.parentInitData as IEditPrintListLoader).initListData
      .selectedPrintList;
  } else selectedPrintList = editPrintListState.sub.shareDataForTabLoader().selectedPrintList;

  tabState.printId = selectedPrintList?.id as string;
  if (tab === EditPrintListTabs.general) {
    await Promise.all([
      resolveDataForGeneralTab(tabState),
      resolveTourTabOptions(tabState),
      resolveCustomersOptions(tabState),
      resolveCustomerListsOptions(tabState),
      resolveProductionPrintListsOptions(tabState),
      resolveArticleListsOptions(tabState),
    ]);
  }
  editPrintListTabLoadingService.pub.loading(false);
  return tabState as ITabState;
};
