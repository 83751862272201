import { FC } from 'react';
import { DeliveryNote } from './deliveryNote/deliveryNote.component';
import { Invoice } from './invoice/invoice.component';

export const PrintForms: FC = () => {
  return (
    <>
      <DeliveryNote />
      <Invoice />
    </>
  );
};
