import { t } from 'i18next';
import { take, zipWith } from 'rxjs';

import {
  ArticleParams,
  IArticleGlobalSearchParams,
  globalSearchParams,
} from '../../../shared/components/globalSearch/globalSearchParams.util';
import { dataHelper } from '../../../shared/helpers/data/data.helper';
import { storageHelper } from '../../../shared/helpers/storage';
import {
  configsData,
  DictArticleListsRes,
  DictAssortmentGroupsRes,
  DictCashAssistProductGroupsRes,
  DictCashAssistTemplatesRes,
  DictDiscountGroupsRes,
  DictMarketingGroupsRes,
  DictProductionGroupsRes,
  DictShippingPointsRes,
} from '../../../shared/services/configsData/configsData.service';
import { articleService, ArticlesListRes } from '../services/article.service';
import {
  IArticleListState,
  initArticleListState as initListState,
} from '../states/articleList.state';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';

export async function resolveArticleListData(
  articleListData: IArticleListState,
  params: ArticleParams,
): Promise<IArticleListState> {
  const data = structuredClone(articleListData);
  const initArticleListState = structuredClone(initListState);
  let param: IArticleGlobalSearchParams | undefined;
  let globalSearchSelectedArticle: { id: string } | undefined;
  let takeListFromCache = true;

  if ((param = globalSearchParams('article', params) as IArticleGlobalSearchParams)) {
    globalSearchSelectedArticle = { id: param.entityId };
    if (data.filter.isActive !== param.filter.active) {
      takeListFromCache = false;
    }
    data.filter.isActive = param.filter.active;
  }

  const sortModel: IArticleListState['sortModel'] =
    storageHelper.local.getItem('article.articlesListSort') || initArticleListState.sortModel;
  const [
    listData,
    articleLists,
    assortmentGroups,
    cashAssistProductGroups,
    cashAssistTemplates,
    discountGroups,
    marketingGroups,
    productionGroups,
    shippingPoints,
  ] = await articleService.globHelpers.streamToPromise(
    articleService.sub
      .articleList()
      .pipe(
        zipWith(
          configsData.sub.dictArticleLists(),
          configsData.sub.dictAssortmentGroups(),
          configsData.sub.dictCashAssistProductGroups(),
          configsData.sub.dictCashAssistTemplates(),
          configsData.sub.dictDiscountGroups(),
          configsData.sub.dictMarketingGroups(),
          configsData.sub.dictProductionGroups(),
          configsData.sub.dictShippingPoints(),
        ),
        responseHandler<
          [
            ArticlesListRes,
            DictArticleListsRes,
            DictAssortmentGroupsRes,
            DictCashAssistProductGroupsRes,
            DictCashAssistTemplatesRes,
            DictDiscountGroupsRes,
            DictMarketingGroupsRes,
            DictProductionGroupsRes,
            DictShippingPointsRes,
          ]
        >({ errorReturnType: [[], [], [], [], [], [], [], [], []] }),
        take(1),
      ),
    () => {
      articleService.pub.articlesList({ filter: data.filter, cache: takeListFromCache }),
        configsData.pub.common([
          'dictArticleLists',
          'dictAssortmentGroups',
          'dictCashAssistProductGroups',
          'dictCashAssistTemplates',
          'dictDiscountGroups',
          'dictMarketingGroups',
          'dictProductionGroups',
          'dictShippingPoints',
        ]);
    },
  );
  const filterOptionData = {
    articleLists,
    assortmentGroups,
    cashAssistProductGroups,
    cashAssistTemplates,
    discountGroups,
    marketingGroups,
    productionGroups,
    shippingPoints,
  };

  const sessionSelectedArticle =
    globalSearchSelectedArticle || storageHelper.session.getItem('article.selectedArticle');
  let listDataInitial = listData;

  if (listDataInitial.length) {
    listDataInitial = dataHelper
      .data(listDataInitial as [])
      .sort({ sortModel: { ...sortModel } })
      .result() as IArticleListState['articleList'];
  }
  if (sessionSelectedArticle && sessionSelectedArticle.id) {
    const foundSelectedRecord = listDataInitial.find(
      (item) => item?.id === sessionSelectedArticle?.id,
    );
    data.selectedArticle = foundSelectedRecord || listDataInitial[0];
  } else {
    data.selectedArticle = listDataInitial[0] as IArticleListState['selectedArticle'];
  }
  storageHelper.session.setItem('article.selectedArticle', data.selectedArticle || {});

  const articleNo = data.selectedArticle?.articleNo ?? '';
  const description = data.selectedArticle?.description ?? '';

  data.articleTitle =
    articleNo && description
      ? `${articleNo} ${description}`
      : articleNo || description || t('common.new_record');
  data.articleList = listDataInitial;
  data.allArticleListLength = listDataInitial.length;
  data.filterOptionData = filterOptionData;
  data.sortModel = sortModel;
  return data;
}

export interface IArticleLoader {
  initListData: IArticleListState;
}
