import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { DeliveryPeriods } from '../../../../../../../../../../customer/components/tabs/availability/popups/deliveryPeriods/deliveryPeriods.popup';
import { PeriodConditions } from '../../../../../../../../../../customer/services/customer.service';
import { PeriodConditionsBox } from '../../../../../../../../../../../shared/components/periodConditionsBox/periodConditionsBox.component';
import { TSetData } from '../../defaultSettingsForm.component';
import { Content } from '../../../../../../../../../../../shared/components/content/content.component';

export const Availability: FC<IAvailability> = ({ initPeriodConditions, setData }) => {
  const { t } = useTranslation();
  const deliveryPeriodsPopup = useMemo(() => new DeliveryPeriods(), []);
  const [periodConditions, setPeriodConditions] = useState<PeriodConditions | null>(
    initPeriodConditions,
  );

  useEffect(() => {
    deliveryPeriodsPopup.stream.state().subscribe((v) => {
      if (v?.action === 'save') {
        const dataForSave = v?.values?.periodConditions;
        setPeriodConditions(dataForSave);
        setData((oldData) => ({
          ...oldData,
          ...dataForSave,
        }));
      }
    });

    return () => {
      deliveryPeriodsPopup.stream.unsubscribe();
    };
  }, []);

  return (
    <>
      {Boolean(periodConditions) ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '5px',
          }}
        >
          <PeriodConditionsBox
            periodConditions={periodConditions as PeriodConditions}
            width='600px'
          />
          <Box>
            <Button
              onClick={() => {
                deliveryPeriodsPopup.stream.emit('open', {
                  values: {
                    periodConditions: periodConditions as PeriodConditions,
                  },
                });
              }}
              sx={{ mt: 2 }}
              variant='contained'
              startIcon={<SettingsIcon />}
            >
              {t('customer.availabilitySetting')}
            </Button>
          </Box>
          <deliveryPeriodsPopup.Component />
        </Box>
      ) : (
        <Content
          type='paper'
          paperProps={{
            variant: 'elevation',
            sx: {
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              height: '200px',
            },
          }}
          emptyData={{
            value: true,
            title: t('common.no_data'),
          }}
        />
      )}
    </>
  );
};

interface IAvailability {
  initPeriodConditions: PeriodConditions | null;
  setData: TSetData;
}
