import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Paper, Stack, Typography, styled } from '@mui/material';
import {
  IStatusWorkerState,
  ReportPreviewStatus,
  StatusItem,
  statusWorkerState,
} from '../../../states/statusWorker/statusWorker.state';
import {
  InsertDriveFile as FileIcon,
  Error as ErrorIcon,
  ChevronLeft,
  ChevronRight,
  Inbox,
} from '@mui/icons-material';
import { C_Win_Report_Task_Status } from '../../../../../../graphql/generatedModel';

export const ProductionDetails: FC<IProductionDetailsProps> = ({
  data,
  name,
  hasNext,
  hasPrevious,
  onNavigate,
}) => {
  useEffect(() => {
    if (data.status === ReportPreviewStatus.ready && data.url) {
      statusWorkerState.pub.updateItem({
        uuid: data.uuid,
        newItem: { status: ReportPreviewStatus.viewed },
      });
    }
  }, [data.status]);
  const runPreview = () => {
    statusWorkerState.pub.itemAction({ ...data });
  };
  const { t } = useTranslation();

  const previewContent = useMemo(() => {
    switch (data?.status) {
      case ReportPreviewStatus.enabled:
      case ReportPreviewStatus.loading: {
        return (
          <SCenterContainer>
            <PreviewStub
              onClickPreview={runPreview}
              loading={data?.status === ReportPreviewStatus.loading}
              title={name}
            />
          </SCenterContainer>
        );
      }
      case ReportPreviewStatus.ready:
      case ReportPreviewStatus.viewed: {
        return data.url ? (
          <SIFrame src={data.url} title='PDF' />
        ) : (
          <SCenterContainer>
            <PreviewError onClickPreview={runPreview} errorType={data.errorType} title={name} />
          </SCenterContainer>
        );
      }
      case ReportPreviewStatus.error: {
        return (
          <SCenterContainer>
            <PreviewError onClickPreview={runPreview} errorType={data.errorType} title={name} />
          </SCenterContainer>
        );
      }
      default:
        return null;
    }
  }, [data.status, data.url, data.uuid]);

  return (
    <Paper variant='outlined' sx={{ height: '100%' }}>
      <Stack direction='column' sx={{ height: '100%' }}>
        <Box sx={{ flexGrow: 1 }}>{previewContent}</Box>
        <SNavContainer direction='row' gap='80px' justifyContent='center' p='10px 16px'>
          <Button
            onClick={() => onNavigate('previous')}
            variant='text'
            startIcon={<ChevronLeft />}
            disabled={!hasPrevious}
            size='small'
          >
            {t('common.previous_record')}
          </Button>
          <Button
            onClick={() => onNavigate('next')}
            variant='text'
            endIcon={<ChevronRight />}
            disabled={!hasNext}
            size='small'
          >
            {t('common.next_record')}
          </Button>
        </SNavContainer>
      </Stack>
    </Paper>
  );
};

const PreviewStub: FC<IPreviewStubProps> = ({ loading, title, onClickPreview }) => {
  const { t } = useTranslation();

  return (
    <div>
      <FileIcon sx={{ width: '60px', height: '60px', fill: 'rgba(0,0,0,0.56)' }} />
      <Typography variant='body2' fontSize={24}>
        {title}
      </Typography>
      <Button
        onClick={onClickPreview}
        variant='outlined'
        size='large'
        sx={{ mt: 3 }}
        {...(loading && {
          startIcon: <CircularProgress size={16} sx={{ color: 'rgba(0,0,0,0.38)' }} />,
          disabled: true,
        })}
      >
        {t('common.preview')}
      </Button>
    </div>
  );
};

const PreviewError: FC<PreviewErrorProps> = ({ title, onClickPreview, errorType }) => {
  const { t } = useTranslation();
  const errMsg = useMemo(() => {
    switch (errorType) {
      case C_Win_Report_Task_Status.WRTS15_NO_DATA_FOR_REPORT:
        return t('production.no_data_no_preview');
      default:
        return t('production.request_error_message');
    }
  }, [errorType, t]);
  const errorIcon = useMemo(() => {
    switch (errorType) {
      case C_Win_Report_Task_Status.WRTS15_NO_DATA_FOR_REPORT:
        return <Inbox sx={{ width: '60px', height: '60px', fill: 'rgba(0,0,0,0.56)' }} />;
      default:
        return <ErrorIcon sx={{ width: '60px', height: '60px', fill: 'rgba(211, 47, 47, 1)' }} />;
    }
  }, [errorType]);

  return (
    <div>
      {errorIcon}
      <Typography variant='body2' fontSize={24}>
        {title}
      </Typography>
      <Typography variant='body2' fontSize={14} color='rgba(0,0,0,0.6)'>
        {errMsg}
        <br />
        {t('production.try_preview_again')}
      </Typography>
      <Button onClick={onClickPreview} variant='outlined' size='large' sx={{ mt: 3 }}>
        {t('production.try_again')}
      </Button>
    </div>
  );
};

const SCenterContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}));
const SIFrame = styled('iframe')(() => ({
  width: '100%',
  height: '100%',
  display: 'block',
}));
const SNavContainer = styled(Stack)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
}));

interface IPreviewStubProps {
  loading: boolean;
  onClickPreview: () => void;
  title: string;
}
interface PreviewErrorProps {
  onClickPreview: () => void;
  title: string;
  errorType: StatusItem['errorType'];
}
interface IProductionDetailsProps {
  data: IStatusWorkerState['list'][number];
  name: string;
  hasNext: boolean;
  hasPrevious: boolean;
  onNavigate: (nav: 'next' | 'previous') => void;
}
