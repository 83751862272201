import { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  FieldsSection,
  FormNumericField,
  FormSelect,
} from '../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';

import { configurationTabState } from '../../../states/configuration.state.ts';
import { C_Besr_Format } from '../../../../../../graphql/generatedModel.ts';
import { IInitTabData } from '../../configurationDetails.tsx';
import { ISelectOption } from '../../../../../../shared/components/form/fields/select.component.tsx';
import { DictionaryPopup } from '../../../../../../shared/components/dictionary/dictionary.popup.tsx';
import { TextEditCellDict } from '../../../../../../shared/components/dictionary/cells/textEditDict.cell.tsx';
import { InternalDictCell } from './cells/internalDict.cell.tsx';
import { BankSettingsPopup } from './popup/bankSettings.popup.tsx';

export const BankAccountsTab: FC<IBankAccountsTab> = ({ params }) => {
  const { t } = useTranslation();
  const formMethods = useForm({
    defaultValues: params,
    mode: 'onChange',
  });
  const {
    resetField,
    formState: { dirtyFields },
  } = formMethods;

  const bestFormatOptions = useMemo(
    () => [
      {
        id: C_Besr_Format.BF1_HSSOFT,
        label: t(`enums.${C_Besr_Format.BF1_HSSOFT}`),
      },
      {
        id: C_Besr_Format.BF2_ABACUS,
        label: t(`enums.${C_Besr_Format.BF2_ABACUS}`),
      },
      {
        id: C_Besr_Format.BF3_ABACUS2,
        label: t(`enums.${C_Besr_Format.BF3_ABACUS2}`),
      },
    ],
    [t],
  );

  const dictionaryPopup = useMemo(() => new DictionaryPopup(), []);
  const bankSettingsPopup = useMemo(() => new BankSettingsPopup(), []);

  useEffect(() => {
    const formStateSub = formMethods.watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        configurationTabState.pub.recordData({ [fieldName]: data[fieldName] });
      }
    });
    const configurationTabStateSub = configurationTabState.sub
      .state()
      .subscribe(({ dataToSave }) => {
        const name = Object.keys(dataToSave)[0] as keyof typeof dataToSave;
        resetField(name, {
          defaultValue: dataToSave[name],
          keepDirty: false,
        });
      });

    return () => {
      formStateSub.unsubscribe();
      configurationTabStateSub.unsubscribe();
      dictionaryPopup.stream.unsubscribe();
      bankSettingsPopup.stream.unsubscribe();
    };
  }, []);

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    if (!!dirtyFields[e.target.name as keyof typeof dirtyFields]) {
      configurationTabState.pub.saveData();
    }
  };

  const bankSettingsHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.banks',
        dictionaryType: 'dictBanks',
        width: 500,
        validateFields: ['name'],
        emptyRow: {
          name: null,
          internalName: null,
        },
        columns: [
          {
            field: 'name',
            headerName: t('common.name'),
            renderEditCell: (params) => <TextEditCellDict {...params} />,
            editable: true,
          },
          {
            field: 'internalName',
            headerAlign: 'center',
            align: 'center',
            flex: 0,
            width: 215,
            renderEditCell: (params) => (
              <TextEditCellDict
                rightButton={{
                  title: t('settings.bank_settings'),
                  handler: () =>
                    bankSettingsPopup.stream.emit('open', {
                      id: String(params.id),
                      title: params.row?.name,
                    }),
                }}
                {...params}
                allowSubmitOnlyChangedValues
              />
            ),
            renderCell: (params) => (
              <InternalDictCell popupStream={bankSettingsPopup.stream} {...params} />
            ),
            headerName: t('settings.internal_bank_description'),
            editable: true,
          },
        ],
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form noValidate onBlur={onSubmit}>
        <Stack direction='column' maxWidth='500px'>
          <FieldsSection titleBoxSx={{ pt: 0 }} title={t('settings.standart_besr')} nomt>
            <FormSelect
              name='standardBankId'
              options={params.banksDictOptions as ISelectOption[]}
              label={t('settings.standart_bank')}
              disableClearable
              sx={{ marginBottom: 3 }}
            />
            <Stack direction='row' spacing={2}>
              <FormSelect
                name='besrFormatId'
                options={bestFormatOptions}
                label={t('settings.besr_format')}
                disableClearable
              />
              <FormNumericField
                name='operationCostCenter'
                label={t('settings.operation_cost_center')}
                noTrailingZeros
              />
            </Stack>
          </FieldsSection>
          <FieldsSection title={t('settings.related_directories')} nomt>
            <Stack alignItems='start' spacing={1} width='fit-content'>
              <Button
                onClick={bankSettingsHandler}
                size='medium'
                variant='outlined'
                color='secondary'
                fullWidth
              >
                {t('settings.banks_settings')}
              </Button>
            </Stack>
          </FieldsSection>
        </Stack>
      </form>
      <dictionaryPopup.Component />
      <bankSettingsPopup.Component />
    </FormProvider>
  );
};

interface IBankAccountsTab {
  params: IInitTabData;
}
