import { IRowStyles } from '../table.component.tsx';
import { ICellStyles } from '../table.component.tsx';
import { Theme } from '../../../styles/theme/theme.ts';

export const setRowStyles = (params: IRowStyles[]) => {
  return params?.reduce((acc: Record<string, any>, item) => {
    acc[`& .${item.className}`] = {
      ...(item.bgColor && { backgroundColor: `${item.bgColor}!important` }),
      transition: 'background-color 0.3s',
      ...(item.bgColorHover && {
        '&:hover': {
          backgroundColor: `${item.bgColorHover}!important`,
        },
      }),
      ...((item.bgColorHover || item.bgColorSelected) && {
        '&.Mui-selected.MuiDataGrid-row': {
          transition: 'background-color 0.3s',
          backgroundColor: `${item?.bgColorSelected || item.bgColorHover}!important`,
          '&:hover': {
            backgroundColor: `${
              item?.bgColorSelectedHover || item.bgColorSelected || item.bgColorHover
            }!important`,
          },
        },
      }),
      ...(item?.rowStyles && { ...item.rowStyles }),
    };
    return acc;
  }, {});
};

export const setCellStyles = (params: ICellStyles[]) => {
  return params?.reduce((acc: Record<string, any>, item) => {
    acc[`& .${item.className}`] = {
      ...item.cellStyles,
      '&.MuiDataGrid-cell--editing': {
        backgroundColor: `${Theme.palette.background.default}!important`,
        zIndex: 1,
      },
    };
    return acc;
  }, {});
};
