import { t } from 'i18next';
import { FC } from 'react';
import {
  FieldsRow,
  FieldsSection,
  FormNumericField,
} from '../../../../../../../shared/components/form';

export const SheetSection: FC = () => {
  return (
    <FieldsSection marginTop={'31px'} title={t('article.sheet')} titleBoxSx={{ pt: 0 }} nomt>
      <FieldsRow>
        <FormNumericField
          controls
          precision={0}
          width='230px'
          label={t('article.number_per_sheet')}
          name='countPerSheetOfCuttingMachine'
        />
        <FormNumericField
          controls
          precision={0}
          width='230px'
          label={t('article.sheets_per_production')}
          name='countOfSheetsPerCuttingMachine'
        />
      </FieldsRow>
    </FieldsSection>
  );
};
