import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

export const TabPanel: FC<ITabPanel> = ({ value, activeValue, children, ...restProps }) => {
  return (
    <Box hidden={activeValue !== value} height='100%' role='tabpanel' {...restProps}>
      {value === activeValue ? children : null}
    </Box>
  );
};

interface ITabPanel {
  value: string | number;
  activeValue: string | number;
  children?: ReactNode;
}
