import { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ConfirmationPopup: FC<IConfirmationPopup> = ({ handleConfirm, handleClose, open }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={() => handleConfirm(false)}>
      <DialogContent sx={{ pt: 3 }}>
        <DialogContentText>{t('common.discard_your_changes')}</DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ display: 'flex', justifyContent: 'center', gap: 1, padding: '0 15px 15px 15px' }}
      >
        <Button variant='outlined' onClick={() => handleConfirm(false)} fullWidth>
          {t('common.cancel')}
        </Button>
        <Button
          fullWidth
          variant='contained'
          onClick={() => {
            handleConfirm(false);
            handleClose(true);
          }}
        >
          {t('common.discard')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface IConfirmationPopup {
  handleConfirm: (v: boolean) => void;
  handleClose: (v?: boolean) => void;
  open: boolean;
}

export default ConfirmationPopup;
