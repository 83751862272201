import { TPopupDefaultActions } from '../../../../../../../shared/components/popup/services/popup.service.ts';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Popup,
  TPopupComponent,
} from '../../../../..//../../shared/components/popup/popup.abstract.tsx';
import { useTranslation } from 'react-i18next';
import { FormTextEditor } from '../../../../../../../shared/components/form';
import { Button, Stack, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { fontHelper } from '../../../../../../../shared/helpers/font/font.helper.ts';
import { CustomerConditionsRes } from '../../../../../services/customer.service.ts';
import { useBeforeClosePopup } from '../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';

export class IndividualPopup extends Popup<IIndividualPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;
    this.Component = () => {
      const {
        resetField,
        getValues,
        formState: { isDirty },
      } = useFormContext();
      const { t } = useTranslation();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 756 },
        }));
      }, []);
      const defaultFontSize = useMemo(() => fontHelper.getDefaultFont(['fontSize']), []);
      const [defaultValues, setDefaultValues] = useState<IIndividualPopup['values']>({
        individualPaymentTerms: null,
      });
      useEffect(() => {
        stream.actionListener('open').subscribe((data) => setDefaultValues(data?.values));
      }, []);

      useBeforeClosePopup({
        stream,
        isDirty,
        handlers: () => {
          resetField('individualPaymentTerms', {
            defaultValue: defaultValues?.individualPaymentTerms,
          });
        },
        deps: [defaultValues?.individualPaymentTerms],
      });

      return (
        <SWrapper
          fullWidth
          stream={stream}
          popupTitle={t('customer.individual_payment_conditions')}
        >
          <FormTextEditor
            toolbarItems={['size', 'bold', 'color']}
            name='individualPaymentTerms'
            defaultFormat={defaultFontSize}
          />
          <Stack marginTop='34px' direction='row' spacing={2} justifyContent='flex-end'>
            <Button
              variant='contained'
              onClick={() => {
                const { individualPaymentTerms } = getValues();
                if (defaultValues?.individualPaymentTerms !== individualPaymentTerms) {
                  stream.emit('save', { values: { individualPaymentTerms } });
                }

                stream.emit('close');
              }}
            >
              {t('common.save')}
            </Button>
          </Stack>
        </SWrapper>
      );
    };
  }
}

interface IIndividualPopup {
  action: 'save' | TPopupDefaultActions;
  values?: Pick<CustomerConditionsRes, 'individualPaymentTerms'>;
}
