import * as yup from 'yup';
import { C_Report } from '../../../../../../../graphql/generatedModel';

export const createValidationSchema = (): yup.AnyObjectSchema => {
  return yup.object({
    name: yup.string().required('common.not_empty'),
    reportId: yup.string().nonNullable().required('common.not_empty'),
    reportGroupId: yup.string().nonNullable().required('common.not_empty'),
    tourplanId: yup.string().when('reportId', {
      is: C_Report.R32_TOURLIST,
      then: (schema) => schema.required('common.not_empty'),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
};
