/* eslint-disable react-hooks/rules-of-hooks */
import { Popup, TPopupComponent } from '../popup/popup.abstract.tsx';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { TPopupDefaultActions } from '../popup/services/popup.service.ts';
import { ContentPopup } from './content.popup.tsx';
import { GridColDef } from '@mui/x-data-grid-pro';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { IDictionaryState } from './states/dictionary.state.ts';

/* todo Use only one instance of this class, even if you need multiple popups of this type on the one page.
    Changing the context is done using the arguments described below. */
export class DictionaryPopup extends Popup<IDictionaryPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const { t } = useTranslation();
      const [params, setParams] = useState<IDictionaryPopup['params']>();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: params?.width || 380 },
        }));
      }, [params?.width]);

      useEffect(() => {
        stream.actionListener('open').subscribe(({ params }) => setParams(params));
      }, []);

      return (
        <SWrapper
          fullWidth
          stream={stream}
          popupTitle={params?.popupTitle ? t(`${params?.popupTitle}`, { count: 5 }) : ''}
        >
          <ContentPopup
            limit={params?.limit}
            dictionaryType={params?.dictionaryType!}
            columns={params?.columns || []}
            emptyRow={params?.emptyRow}
            editNewCell={params?.editNewCell}
            validateFields={params?.validateFields}
            nameAlias={params?.nameAlias}
            popupTitle={params?.popupTitle || ''}
            recordDeletetionDisabled={params?.recordDeletetionDisabled}
          />
        </SWrapper>
      );
    };
  }
}

export interface IDictionaryPopup {
  action: TPopupDefaultActions;
  params: {
    popupTitle: string; // popup title
    dictionaryType: TypeDictionaries; // this parameter determines which dictionary will be loaded
    columns: GridColDef[]; // this parameter that defines the table columns takes the same arguments as the Column component
    width?: number; // this parameter that determines the width of the popup. Default 380px
    limit?: number; // this parameter determines the type of list: limited or unlimited. For an unlimited list, there is no need to pass this parameter.
    editNewCell?: string; // the name of the cell that will be in edit mode when adding a new row
    emptyRow?: GridRowParams['row']; // this parameter determines for the template of the new row. Use if you want certain cells to have a default value when creating a new row. If no value is passed, the cells will be empty.
    validateFields?: string[]; // an array of cell names that need to be validated (for standard validation only)
    nameAlias?: string; // use if the label field is renamed to something other than 'name', default: 'name'
    recordDeletetionDisabled?: (selectedRecord: IDictionaryState['selectedRow']) => boolean; // use it to disable selected row deletion
  };
}

export type TypeDictionaries =
  | 'dictCustomers'
  | 'dictCustomerGroups'
  | 'dictCustomerLists'
  | 'dictDeliveryNoteGroups'
  | 'dictPriceCategories'
  | 'dictArticleLists'
  | 'dictAssortmentGroups'
  | 'dictCashAssistProductGroups'
  | 'dictCashAssistTemplates'
  | 'dictDiscountGroups'
  | 'dictMarketingGroups'
  | 'dictProductionGroups'
  | 'dictShippingPoints'
  | 'dictTourplans'
  | 'dictTextBlocks'
  | 'dictPostSectors'
  | 'dictCustomerProfiles'
  | 'dictQuantityDiscounts'
  | 'dictBanks'
  | 'dictDebtReminders'
  | 'dictStorageLocations';
