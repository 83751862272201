import { GridSortModel } from '@mui/x-data-grid-premium';
import { LoaderFunction, Params } from 'react-router-dom';
import { map, take, zipWith } from 'rxjs';
import {
  C_Created_Invoices_Filter_Mode as FilterMode,
  ListWa_CreatedInvoicesQueryVariables as filterVariables,
} from '../../../graphql/generatedModel';
import {
  IInvoiceGlobalSearchParams,
  InvoiceParams,
  globalSearchParams,
} from '../../../shared/components/globalSearch/globalSearchParams.util';
import { dataHelper } from '../../../shared/helpers/data/data.helper';
import { storageHelper } from '../../../shared/helpers/storage';
import { getFirstAndLastDateOfMonth } from '../../../shared/helpers/utils/utils.helper';
import {
  DictTextBlocksRes,
  configsData,
} from '../../../shared/services/configsData/configsData.service';
import { IInvoiceDetailsState, initInvoiceDetailsState } from '../list/states/invoiceDetails.state';
import { IInvoiceListState, initInvoiceListState } from '../list/states/invoiceList.state';
import {
  CreatedInvoicesRes,
  QueryDataRes,
  QueryProps,
  invoiceListService,
} from '../services/invoiceList.service';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';

export const invoiceListLoader: LoaderFunction = async ({
  params,
}: {
  params: Params<keyof InvoiceParams>;
}): Promise<IInvoiceListLoader> => {
  let initInvoiceList = { ...initInvoiceListState };
  initInvoiceList = await resolveInvoiceList(initInvoiceList, params);
  const { customerId = '', invoiceId = '', isDeleted } = initInvoiceList?.selectedInvoice || {};
  const initInvoiceData = await resolveInvoiceData({
    customerId,
    invoiceId,
    isDeleted: !!isDeleted,
  });
  return {
    initInvoiceList,
    initInvoiceData,
  };
};

const resolveInvoiceList = async (
  data: IInvoiceListState,
  params: InvoiceParams,
): Promise<IInvoiceListState> => {
  const initListState = structuredClone(initInvoiceListState);
  let param: IInvoiceGlobalSearchParams | undefined;
  const filterValues = {
    ...initListState.filter,
    ...getFirstAndLastDateOfMonth(new Date(), FilterMode.CURRENT_MONTH),
  };

  const sortModel = storageHelper.local.getItem('invoiceList.sortModel') || data.sortModel;

  const filter: filterVariables['filter'] = {
    ...filterValues,
  };

  if ((param = globalSearchParams('invoice', params) as IInvoiceGlobalSearchParams)) {
    filter.filterBy = FilterMode.DATE_RANGE;
    filter.date = param.dateRange.from;
    filter.dateTo = param.dateRange.to;
    filter.withDeleted = param.withDeleted;
  }
  let selectedInvoice: IInvoiceListState['selectedInvoice'] = storageHelper.session.getItem(
    'invoiceList.selectedInvoice',
  );
  let createdInvoicesListLength = 0;
  let invoiceList = await invoiceListService.globHelpers.streamToPromise(
    invoiceListService.sub.createdInvoicesList().pipe(
      responseHandler<CreatedInvoicesRes>({
        customErrorHandler: () => 'invoice.error_failed_to_load_created_invoice_list',
        errorReturnType: [],
      }),
      take(1),
    ),
    () => invoiceListService.pub.createdInvoicesList({ filter }),
  );
  if (invoiceList.length) {
    invoiceList = dataHelper
      .data(invoiceList as [])
      .sort({ sortModel })
      .result() as CreatedInvoicesRes;
  }
  const action = 'loader' as IInvoiceListState['action'];

  createdInvoicesListLength = invoiceList.length;
  const isSelectedRecordInList =
    selectedInvoice && !!invoiceList.find((item) => item?.invoiceId === selectedInvoice?.invoiceId);
  selectedInvoice = isSelectedRecordInList ? selectedInvoice : invoiceList[0];

  return {
    ...data,
    action,
    createdInvoicesList: invoiceList,
    createdInvoicesListLength,
    sortModel,
    filter,
    selectedInvoice,
  };
};

const resolveInvoiceData = async ({
  customerId,
  invoiceId,
  isDeleted,
}: resolveInvoiceDataProps): Promise<IInitData> => {
  const noData = !customerId || !invoiceId;
  if (noData) {
    const textList =
      (await invoiceListService.globHelpers.streamToPromise(
        configsData.sub.dictTextBlocks().pipe(take(1)),
        () => {
          configsData.pub.common(['dictTextBlocks']);
        },
      )) || [];
    return {
      ...initInvoiceDetailsState,
      textList,
    };
  }

  const orderSort = (storageHelper.local.getItem('invoiceList.orderSort') ||
    initInvoiceDetailsState?.orderSort) as GridSortModel;

  const data = await invoiceListService.globHelpers.streamToPromise(
    configsData.sub.dictTextBlocks().pipe(
      zipWith(invoiceListService.sub.queryData()),
      responseHandler<[DictTextBlocksRes, QueryDataRes]>({
        errorReturnType: [
          [],
          {
            customerInfo: { isEmailServiceForInvoice: false },
            orders: [],
            selectedOrder: {
              orderId: 'errorId',
              orderDate: new Date(),
              orderNo: '',
              customerId: '',
            },
            invoiceInfo: { id: '', isDeleted: false },
            orderPositions: [],
            isMultipayCustomer: false,
          },
        ],
      }),
      take(1),
      map(([textList, restData]) => ({
        ...restData,
        textList,
        orderSort,
      })),
    ),
    () => {
      invoiceListService.pub.queryData({ customerId, invoiceId, isDeleted, orderSort });
      configsData.pub.common(['dictTextBlocks']);
    },
  );

  return {
    ...initInvoiceDetailsState,
    ...data,
    action: 'loader',
  } as IInitData;
};
export interface IInitData extends IInvoiceDetailsState {
  textList: DictTextBlocksRes;
}

export interface IInvoiceListLoader {
  initInvoiceList: IInvoiceListState;
  initInvoiceData: IInitData;
}

export const initQueryData: QueryDataRes = {
  customerInfo: {
    isEmailServiceForInvoice: false,
  },
  invoiceInfo: {
    id: '',
    isDeleted: false,
  },
  isMultipayCustomer: false,
  orderPositions: [],
  orders: [],
  selectedOrder: {
    customerId: '',
    orderDate: undefined,
    orderId: '',
    orderNo: '',
  },
};

type resolveInvoiceDataProps = Pick<QueryProps, 'customerId' | 'invoiceId' | 'isDeleted'>;
