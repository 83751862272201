import { TPopupDefaultActions } from '../../../../../../../../../shared/components/popup/services/popup.service.ts';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../../shared/components/popup/popup.abstract.tsx';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, styled } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ArticleProductionTabDataRes,
  ProductionTabOptionsRes,
} from '../../../../../../../services/article.service.ts';
import { FormNumericField, FormSelect } from '../../../../../../../../../shared/components/form';
import { ISelectOption } from '../../../../../../../../../shared/components/form/fields/select.component.tsx';
import { productionTabState } from '../../../../production.state.ts';
import { yupResolver } from '@hookform/resolvers/yup';
import { createValidationSchema } from './validation/calculation.schema.ts';
import { useBeforeClosePopup } from '../../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';

const validationSchema = createValidationSchema();
export class CalculationPopup extends Popup<ICalculationPopup> {
  Component: FC<TPopupComponent>;
  dictArticles: ProductionTabOptionsRes['dictArticles'];
  constructor(params: { dictArticles: ProductionTabOptionsRes['dictArticles'] }) {
    super();
    const stream = this.innerStream;
    this.dictArticles = params.dictArticles;

    this.Component = () => {
      const { t } = useTranslation();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 464 },
        }));
      }, []);
      const [field, setField] = useState('');
      const [dictArticlesOptions, setDictArticlesOptions] = useState<IDictArticlesOptions[]>([]);
      const formMethods = useForm<TDefaultValues>({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {},
      });

      const {
        formState: { isDirty, dirtyFields, errors, isValid },
      } = formMethods;

      useEffect(() => {
        stream.actionListener('open').subscribe(({ values }) => {
          const dictArticles = this.dictArticles?.reduce((acc: IDictArticlesOptions[], item) => {
            if (item.isActive && item.id !== values?.currentArticleId) {
              const { description, ...rest } = item;
              acc.push({ ...rest, label: description });
            }
            return acc;
          }, []);
          setDictArticlesOptions(dictArticles);
          if (values?.row) formMethods.reset(values.row);
          if (values?.field) setField(values.field);
        });
      }, []);

      const handleClick = useCallback(() => {
        if (isDirty && isValid) {
          const values = formMethods.getValues();
          const foundArticle = dictArticlesOptions.find((el) => el.id === values.addedArticleId);
          if (foundArticle) {
            const result: TDefaultValues = {
              ...values,
              isFermentationInterrupt: foundArticle.isFermentationInterrupt,
              addedArticleDescription: foundArticle.label,
              addedArticleNo: foundArticle.articleNo,
            };
            productionTabState.pub.updateArticleProdCalcPos(result);
            stream.emit('close');
          }
        } else {
          formMethods.trigger('addedArticleId');
          formMethods.trigger('multiplier');
        }
      }, [isDirty, dirtyFields, isValid]);

      const filterOptions = (
        options: ExtendedSelectOption[],
        { inputValue }: { inputValue: string },
      ): ExtendedSelectOption[] =>
        options.filter(
          (option) =>
            ((option?.articleNo ? `${option?.articleNo} ` : '') + (option?.label || ''))
              ?.toLowerCase()
              .includes(inputValue?.toLowerCase()) as boolean,
        );

      useBeforeClosePopup({
        stream,
        isDirty: !!Object.keys(dirtyFields).length,
      });

      return (
        <SWrapper fullWidth stream={stream} popupTitle={t('article.calculation_for_production')}>
          <FormProvider {...formMethods}>
            <FormSelect
              disableClearable
              autoFocus={'addedArticleDescription' === field}
              width='100%'
              name='addedArticleId'
              label={t('customer.article')}
              options={dictArticlesOptions as []}
              datasetattribute='articleOptions'
              getOptionLabel={(params) => {
                const { articleNo, label } = params as ExtendedSelectOption;
                return `${articleNo} ${label}`;
              }}
              {...(errors.addedArticleId && {
                error: true,
                helperText: t(errors['addedArticleId'].message as string),
              })}
              {...{ filterOptions }}
            />
            <FormNumericField
              label={t('article.factor_on_quantity')}
              width='100%'
              sx={{ mt: 2 }}
              autoFocus={'multiplier' === field}
              name='multiplier'
              noTrailingZeros
              returnZero
              min={-10000}
              {...(errors.multiplier && {
                error: true,
                helperText: t(errors['multiplier'].message as string),
              })}
            />
          </FormProvider>
          <Stack marginTop='34px' direction='row' spacing={2} justifyContent='flex-end'>
            <Button
              disabled={!!Object.keys(errors)?.length}
              onClick={handleClick}
              variant='contained'
            >
              {t('common.save')}
            </Button>
          </Stack>
        </SWrapper>
      );
    };
  }
}

interface ICalculationPopup {
  action: TPopupDefaultActions;
  values?: { row: GridRowParams['row']; field?: string; currentArticleId: string };
}

type TDefaultValues = ArticleProductionTabDataRes['articleProductionCalculation'][number];

interface ExtendedSelectOption extends ISelectOption {
  articleNo?: string;
}

interface IDictArticlesOptions
  extends Omit<ProductionTabOptionsRes['dictArticles'][number], 'description'> {
  label: string | null | undefined;
}
