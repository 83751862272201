import { gql } from 'graphql-request';

export const getTasksStatus = gql`
  query getTasksStatus($tasksId: [Int!]!) {
    wawiAssist {
      getWinReportsStatus(tasksId: $tasksId) {
        taskId
        status
      }
    }
  }
`;

export const r1 = gql`
  mutation create_r1_BakingList2ColumnsReport($params: CreateProductionReportParamsInput) {
    wawiAssist {
      create_r1_BakingList2ColumnsReport(params: $params) {
        url
        taskId
        s3Key
      }
    }
  }
`;
export const r11 = gql`
  mutation create_r11_CrosstableReportA4LandscapeReport(
    $params: CreateProductionReportParamsInput
  ) {
    wawiAssist {
      create_r11_CrosstableReportA4LandscapeReport(params: $params) {
        url
        taskId
        s3Key
      }
    }
  }
`;
export const r14 = gql`
  mutation create_r14_BakingListWithRecipe2ColumnsReport(
    $params: CreateProductionReportParamsInput
  ) {
    wawiAssist {
      create_r14_BakingListWithRecipe2ColumnsReport(params: $params) {
        url
        taskId
        s3Key
      }
    }
  }
`;
export const r15 = gql`
  mutation create_r15_ForwardingListCustomerArticlesReport(
    $params: CreateProductionReportParamsInput
  ) {
    wawiAssist {
      create_r15_ForwardingListCustomerArticlesReport(params: $params) {
        url
        taskId
        s3Key
      }
    }
  }
`;
export const r20 = gql`
  mutation create_r20_ForwardingListArticleCustomersReport(
    $params: CreateProductionReportParamsInput
  ) {
    wawiAssist {
      create_r20_ForwardingListArticleCustomersReport(params: $params) {
        url
        taskId
        s3Key
      }
    }
  }
`;
export const r25 = gql`
  mutation create_r25_BakingList2ColumnsCombinedReport($params: CreateProductionReportParamsInput) {
    wawiAssist {
      create_r25_BakingList2ColumnsCombinedReport(params: $params) {
        url
        taskId
        s3Key
      }
    }
  }
`;
export const r26 = gql`
  mutation create_r26_BakingListWithRecipeA4Report($params: CreateProductionReportParamsInput) {
    wawiAssist {
      create_r26_BakingListWithRecipeA4Report(params: $params) {
        url
        taskId
        s3Key
      }
    }
  }
`;
export const r27 = gql`
  mutation create_r27_BakingListWithRecipeA5Report($params: CreateProductionReportParamsInput) {
    wawiAssist {
      create_r27_BakingListWithRecipeA5Report(params: $params) {
        url
        taskId
        s3Key
      }
    }
  }
`;
export const r32 = gql`
  mutation create_r32_TourlistReport($params: CreateProductionReportParamsInput) {
    wawiAssist {
      create_r32_TourlistReport(params: $params) {
        url
        taskId
        s3Key
      }
    }
  }
`;
export const r34 = gql`
  mutation create_r34_BakingListA4Report($params: CreateProductionReportParamsInput) {
    wawiAssist {
      create_r34_BakingListA4Report(params: $params) {
        url
        taskId
        s3Key
      }
    }
  }
`;
export const r41 = gql`
  mutation create_r41_BakingListA4DetailedReport($params: CreateProductionReportParamsInput) {
    wawiAssist {
      create_r41_BakingListA4DetailedReport(params: $params) {
        url
        taskId
        s3Key
      }
    }
  }
`;
export const r42 = gql`
  mutation create_r42_ForwardingListCustomerArticlesOnePerPageReport(
    $params: CreateProductionReportParamsInput
  ) {
    wawiAssist {
      create_r42_ForwardingListCustomerArticlesOnePerPageReport(params: $params) {
        url
        taskId
        s3Key
      }
    }
  }
`;
export const r51 = gql`
  mutation create_r51_DeliveryNotesReports($params: CreateProductionReportParamsInput) {
    wawiAssist {
      create_r51_DeliveryNotesReports(params: $params) {
        url
        taskId
        s3Key
      }
    }
  }
`;
export const r57 = gql`
  mutation create_r57_BakingListRawmaterials2ColumnsReport(
    $params: CreateProductionReportParamsInput
  ) {
    wawiAssist {
      create_r57_BakingListRawmaterials2ColumnsReport(params: $params) {
        url
        taskId
        s3Key
      }
    }
  }
`;
export const r65 = gql`
  mutation create_r65_CrosstableReportA4PortraitReport($params: CreateProductionReportParamsInput) {
    wawiAssist {
      create_r65_CrosstableReportA4PortraitReport(params: $params) {
        url
        taskId
        s3Key
      }
    }
  }
`;
export const r66 = gql`
  mutation create_r66_BakingListForCakesAndPiesReport($params: CreateProductionReportParamsInput) {
    wawiAssist {
      create_r66_BakingListForCakesAndPiesReport(params: $params) {
        url
        taskId
        s3Key
      }
    }
  }
`;
