import { useMemo } from 'react';
import { GridColDef, GridRowGroupingModel } from '@mui/x-data-grid-premium';
import { TypeTable } from '../../table/table.component';
import { t } from 'i18next';

export const useGroupColumnWidth = (args: IUseGroupColumnWidth) => {
  const { rowGroupingModel, tableData, columns } = args;

  const groupingData = useMemo(() => getGroupingData(tableData), [tableData]);

  const maxTextWidth = useMemo(
    () => getMaxTextWidth({ groupingData, rowGroupingModel, columns }),
    [groupingData, rowGroupingModel, columns],
  );

  return maxTextWidth;
};

const getTextWidth = (args: IGetTextWidth) => {
  const { text: valueForText, groupName, groupIndex } = args;
  const text = valueForText ? String(valueForText) : null;
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = 'bold 14px Plus Jakarta Sans, sans-serif';

    const textForMeasure = `${groupName}: ${text || t('common.empty')}`;
    const textWidth = context.measureText(textForMeasure).width;
    // beforeTextWidth consists of: margin-left = 16 * groupIndex; arrow width = 28; margin-right = 16
    const beforeTextWidth = 16 * groupIndex + 28 + 16;
    // afterTextWidth width consists of: margin-left = 8; quantity circle width 20-28 (if one number then 20. Two - 28); just in case additional 26px;
    const afterTextWidth = 62;
    const widthForGroupCell = beforeTextWidth + textWidth + afterTextWidth;
    return widthForGroupCell;
  }
  return 0;
};

const getMaxTextWidth = (args: IGetMaxTextWidth) => {
  const { groupingData, rowGroupingModel, columns } = args;

  const maxWidth = rowGroupingModel?.reduce((max, columnKey, groupIndex) => {
    const { headerName: groupName } = columns?.find(({ field }) => field === columnKey) || {};
    const maxWidthFromColumn = groupingData?.[columnKey]?.reduce((maxFromCol, groupCellValue) => {
      const textWidth = getTextWidth({ text: groupCellValue, groupName, groupIndex });
      return Math.max(maxFromCol, textWidth);
    }, 0);
    return Math.max(max, maxWidthFromColumn);
  }, 0);

  return maxWidth;
};

const getGroupingData = (data: IUseGroupColumnWidth['tableData']) => {
  const groupingData = data?.reduce((res, row) => {
    for (const key in row) {
      if (!res[key]) {
        res[key] = [];
      }
      const value = row[key];
      res[key].push(value);
    }
    return res;
  }, {} as IGroupingData);
  return groupingData;
};

interface IGetTextWidth {
  text: any;
  groupName?: string;
  groupIndex: number;
}

interface IGetMaxTextWidth {
  groupingData: IGroupingData;
  rowGroupingModel: GridRowGroupingModel;
  columns: GridColDef[];
}

interface IGroupingData {
  [key: string]: any[];
}

interface IUseGroupColumnWidth {
  rowGroupingModel: GridRowGroupingModel;
  tableData: TypeTable['data'];
  columns: GridColDef[];
}
