import { environment } from '../environment.ts';

export const appEnv = import.meta.env.VITE_REACT_APP_ENV as keyof typeof environment;

export const redirectIfProduction = (args?: { fallbackHandler?: () => any }) => {
  if (
    appEnv === 'production' &&
    window.location.href.indexOf(environment[appEnv].productionLink) > -1
  ) {
    window.location.replace(`${environment[appEnv].consoleURL}/account/login`);
  } else {
    args?.fallbackHandler?.();
  }
};
