import { FC } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { TextField } from '../index.ts';
import { TTextFieldProps } from '../fields/textField.component.tsx';

export const FormTextField: FC<TFormTextFieldProps> = ({
  name,
  onBlur,
  onChange,
  defaultValue,
  controllerProps,
  disabled,
  transformValue,
  ...props
}) => {
  return (
    <Controller
      {...controllerProps}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: {
          value,
          onChange: onFieldChange,
          onBlur: onFieldBlur,
          disabled: fieldDisabled,
          ...restField
        },
      }) => {
        let displayedValue = value || '';
        if (transformValue) {
          displayedValue = transformValue(displayedValue);
        }
        return (
          <TextField
            {...props}
            value={displayedValue}
            onChange={(e) => {
              onFieldChange(e.target.value ? e : null);
              onChange?.(e.target.value);
            }}
            onBlur={(e) => {
              onFieldBlur(); // notify react-hook-form when input is touched/blur
              onBlur?.(e);
            }}
            disabled={typeof disabled === 'boolean' ? disabled : fieldDisabled}
            {...restField}
          />
        );
      }}
    />
  );
};

type TFormTextFieldProps = Omit<TTextFieldProps, 'value' | 'onChange'> & {
  name: ControllerProps['name'];
  onChange?: (v: string) => void;
  controllerProps?: Omit<ControllerProps, 'name' | 'render'>;
  disabled?: boolean;
  transformValue?: (v: string) => string;
};
