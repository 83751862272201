import { gql } from 'graphql-request';

export const listSpecialAssortmentGroupsOfCustomer = gql`
  query ListSpecialAssortmentGroupsOfCustomer($customerId: ID!) {
    wawiAssist {
      listWA_SpecialAssortmentGroupsOfCustomerForSelectInOrderPosition(customerId: $customerId)
    }
  }
`;

export const listSpecialAvailabilityState = gql`
  query ListWA_SpecialAvailabilityStateForArticlesSelectedInOrderPosition($onDate: Date!) {
    wawiAssist {
      listWA_SpecialAvailabilityStateForArticlesSelectedInOrderPosition(onDate: $onDate) {
        articleId
        isDelivered
      }
    }
  }
`;

export const getArticleDataAsOrderPosition = gql`
  query GetWA_ArticleDataAsOrderPosition($orderAndArticleProps: WA_OrderAndArticleInputs!) {
    wawiAssist {
      getWA_ArticleDataAsOrderPosition(orderAndArticleProps: $orderAndArticleProps) {
        price
        discount
        minQuantity
        maxQuantity
        splittingPartsPreset
      }
    }
  }
`;

export const getTaxesByDate = gql`
  query ListWA_ActualTaxs($date: Date!) {
    wawiAssist {
      listWA_ActualTaxs(date: $date) {
        id
        label: rate
      }
    }
  }
`;

export const getArticlePriceAndDiscount = gql`
  query GetWA_ArticleOrderPriceAndDiscount($orderAndArticleProps: WA_OrderAndArticleInputs!) {
    wawiAssist {
      getWA_ArticleOrderPriceAndDiscount(orderAndArticleProps: $orderAndArticleProps) {
        price
        discount
      }
    }
  }
`;

export const getOrderDeliveryCost = gql`
  query GetWA_OrderDeliveryCost($customerId: ID!, $totalWeight: Float!) {
    wawiAssist {
      getWA_OrderDeliveryCost(customerId: $customerId, totalWeight: $totalWeight)
    }
  }
`;
