import { Pub, State, Sub } from '../../../../shared/state/state.abstract';
import { BehaviorSubject, Observable, filter, map, merge, of, share, switchMap, tap } from 'rxjs';
import {
  ArticleDataAndChangesInStockRes,
  GetProcurementArticleRes,
  GetProcurementChangesInStockRes,
  SaveProcurementArticleDataRes,
  stockOfFreezerService,
} from '../../services/stockOfFreezer.service';
import { stockOfFreezerListState } from './stockOfFreezerList.state';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler';
import {
  C_Save_Operation_Status,
  SaveProcurementArticleDataMutationVariables,
} from '../../../../graphql/generatedModel';
import { DictStorageLocationsRes } from '../../../../shared/services/configsData/configsData.service';

export const initStockOfFreezerDetailsState: IStockOfFreezerDetailsState = {
  action: undefined,
  dataToSave: {},
  storageLocationOptions: [],
  stockOfFreezerTitle: '',
  procurementChangesInStock: [],
  isEmptyRightSide: false,
  datePeriod: {
    dateFrom: '',
    dateTo: '',
  },
  articleId: '',
};

class PubImpl extends Pub<IStockOfFreezerDetailsState> {
  init(params: IStockOfFreezerDetailsState) {
    this.emit('init', params);
  }
  recordData(dataToSave: IStockOfFreezerDetailsState['dataToSave']) {
    this.emit('recordData', { dataToSave });
  }
  save() {
    this.emit('save', {});
  }
  clearStream() {
    this.emit(undefined, {});
  }
  getProcurementChangesInStock(datePeriod: IDatePeriod) {
    this.emit('getProcurementChangesInStock', { datePeriod });
  }
}

class SubImpl extends Sub<IStockOfFreezerDetailsState> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());
  private procurementChangesLoading$ = new BehaviorSubject<boolean>(false);
  private shareProcurementChangesLoading$: Observable<boolean> =
    this.procurementChangesLoading$.pipe(share());

  protected actionHandlers(): Observable<IStockOfFreezerDetailsState> {
    return merge(this.selectedArticle(), this.save(), this.getProcurementChangesInStock()).pipe(
      map((state) => {
        this.stream$.next({ ...state, action: 'internalUpdate' });
        return state;
      }),
    );
  }

  loading(): Observable<boolean> {
    return this.shareLoading$;
  }

  procurementChangesLoading(): Observable<boolean> {
    return this.shareProcurementChangesLoading$;
  }

  private save(): Observable<IStockOfFreezerDetailsState> {
    return this.actionListener('save').pipe(
      tap(({ dataToSave }) => {
        stockOfFreezerService.pub.saveProcurementArticleData({
          dataToSave,
        } as SaveProcurementArticleDataMutationVariables);
      }),
      switchMap((state) => {
        return stockOfFreezerService.sub.saveProcurementArticleData().pipe(
          responseHandler<SaveProcurementArticleDataRes>({
            success: () => 'production.stock_of_freezer_saved',
            customErrorHandler: () => 'production.stock_of_freezer_saved_not_saved',
            errorReturnType: {
              status: C_Save_Operation_Status.SOS0_DATA_UNCHANGED,
            },
          }),
          filter((v) => v !== undefined),
          map(() => {
            const updatedArticleFields = state?.dataToSave as Partial<GetProcurementArticleRes>;
            return {
              ...state,
              selectedArticleInfo: {
                ...state.selectedArticleInfo,
                ...updatedArticleFields,
              },
            } as IStockOfFreezerDetailsState;
          }),
        );
      }),
    );
  }

  private getProcurementChangesInStock(): Observable<IStockOfFreezerDetailsState> {
    return this.actionListener('getProcurementChangesInStock').pipe(
      tap(({ articleId, datePeriod }) => {
        this.procurementChangesLoading$.next(true);
        stockOfFreezerService.pub.getProcurementChangesInStock({
          dateFrom: datePeriod?.dateFrom,
          dateTo: datePeriod?.dateTo,
          procurementArticleId: Number(articleId),
        });
      }),
      switchMap((state) => {
        return stockOfFreezerService.sub.getProcurementChangesInStock().pipe(
          responseHandler<GetProcurementChangesInStockRes>({
            customErrorHandler: () => {
              this.procurementChangesLoading$.next(false);
              return 'common.server_error';
            },
            errorReturnType: [],
          }),
          map((procurementChangesInStock) => {
            this.procurementChangesLoading$.next(false);
            return {
              ...state,
              procurementChangesInStock,
            };
          }),
        );
      }),
    );
  }

  private selectedArticle(): Observable<IStockOfFreezerDetailsState> {
    return stockOfFreezerListState.sub.state().pipe(
      filter(
        ({ action }) =>
          action === 'articleList' || action === 'selectArticle' || action === 'filter',
      ),
      switchMap(({ selectedArticle }) => {
        if (!selectedArticle) {
          return of({
            ...initStockOfFreezerDetailsState,
            isEmptyRightSide: true,
            action: 'emptyData',
          } as IStockOfFreezerDetailsState);
        }
        this.loading$.next(true);
        const id = selectedArticle!.id;
        const state = this.stream$.getValue();
        const { datePeriod } = state || {};

        state.action = 'list.selectedArticle';
        state.isEmptyRightSide = false;

        const details = stockOfFreezerService.sub.getArticleDataAndChangesInStock().pipe(
          responseHandler<ArticleDataAndChangesInStockRes>({
            customErrorHandler: () => {
              this.loading$.next(false);
              return 'common.server_error';
            },
            errorReturnType: {
              articleInfo: { id: 'errorId' },
              procurementChangesInStock: [],
            },
          }),
          map(({ articleInfo, procurementChangesInStock }) => {
            const { articleNo, description } = articleInfo;
            state.selectedArticleInfo = articleInfo;
            state.stockOfFreezerTitle =
              articleNo && description
                ? `${articleNo} ${description}`
                : articleNo || description || '';
            state.articleId = id;
            state.procurementChangesInStock = procurementChangesInStock;

            this.loading$.next(false);
            return state;
          }),
        );

        stockOfFreezerService.pub.getArticleDataAndChangesInStock({
          dateFrom: datePeriod?.dateFrom,
          dateTo: datePeriod?.dateTo,
          procurementArticleId: Number(id),
        });
        return details;
      }),
    );
  }
}

class StockOfFreezerDetailsState extends State<IStockOfFreezerDetailsState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const stockOfFreezerDetailsState = new StockOfFreezerDetailsState(
  initStockOfFreezerDetailsState,
);

interface IDatePeriod {
  dateFrom: string;
  dateTo: string;
}

export interface IStockOfFreezerDetailsState {
  action:
    | undefined
    | 'init'
    | 'loader'
    | 'list.selectedArticle'
    | 'recordData'
    | 'save'
    | 'emptyData'
    | 'internalUpdate'
    | 'getProcurementChangesInStock';
  dataToSave: GetProcurementArticleRes | Record<string, any>;
  storageLocationOptions: DictStorageLocationsRes;
  stockOfFreezerTitle: string;
  selectedArticleInfo?: GetProcurementArticleRes;
  procurementChangesInStock: GetProcurementChangesInStockRes;
  datePeriod: IDatePeriod;
  articleId: string;
  isEmptyRightSide: boolean;
}
