import { Pub, Service, Sub } from '../../../../shared/services/service.abstract.ts';
import { map, Observable, switchMap } from 'rxjs';
import { gqlClient } from '../../../../graphql/graphqlRequest.ts';
import { listTaxesPeriodsAndRates } from './gql/configuration.gql.ts';
import { ListWa_TaxesPeriodsAndRatesQuery as ListTaxesPeriodsAndRatesQuery } from '../../../../graphql/generatedModel.ts';

type Actions = 'listTaxesPeriodsAndRates' | undefined;
class PubImpl extends Pub<Actions> {
  listTaxesPeriodsAndRates() {
    this.emit('listTaxesPeriodsAndRates');
  }
  clearStream() {
    this.emit(undefined, {});
  }
}

class SubImpl extends Sub<Actions> {
  listTaxesPeriodsAndRates(): Observable<ListTaxesPeriodsAndRatesQueryRes> {
    return this.actionListener('listTaxesPeriodsAndRates').pipe(
      switchMap(() => {
        return gqlClient(listTaxesPeriodsAndRates) as Observable<ListTaxesPeriodsAndRatesQuery>;
      }),
      map((data: ListTaxesPeriodsAndRatesQuery) => {
        return data.wawiAssist?.listWA_TaxesPeriodsAndRates as ListTaxesPeriodsAndRatesQueryRes;
      }),
    );
  }
}

class ConfigurationService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const configurationService = new ConfigurationService();

export type ListTaxesPeriodsAndRatesQueryRes = NonNullable<
  NonNullable<ListTaxesPeriodsAndRatesQuery['wawiAssist']>['listWA_TaxesPeriodsAndRates']
>;
