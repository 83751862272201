import { t } from 'i18next';
import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';

/* Icons start */
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
/* Icons end */
import { Box, Button, Checkbox, Stack } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { useFormContext } from 'react-hook-form';
import { delay } from 'rxjs';
import {
  FieldsRow,
  FieldsSection,
  FormCheckbox,
  FormNumericField,
  FormTextField,
} from '../../../../../../../../shared/components/form';
import { Column } from '../../../../../../../../shared/components/table/components/common.components.tsx';
import { Table } from '../../../../../../../../shared/components/table/table.component.tsx';
import { ProductionTabOptionsRes } from '../../../../../../services/article.service.ts';
import { IProductionTabState, productionTabState } from '../../../production.state.ts';
import { CalculationPopup } from './popups/calculation.popup.tsx';

export const CalculationSection: FC<ICalculationSection> = ({ dictArticles }) => {
  const calculationPopup = useMemo(() => new CalculationPopup({ dictArticles }), []);
  const { getValues } = useFormContext();
  const currentArticleId = getValues('id');
  const [articleProductionCalculationData, setArticleProductionCalculationData] = useState(
    getValues('articleProductionCalculation'),
  );
  const [selectedArticleProdCalcPos, setSelectedArticleProdCalcPos] =
    useState<IProductionTabState['selectedArticleProdCalcPos']>(null);

  const saveArticleProductionCalculation = useRef((e: MouseEvent) => {
    if (!e.target) return;
    const target = e.target as HTMLElement;
    if (!target.closest('.articleProductionCalculation')) {
      productionTabState.pub.saveArticleProductionCalculation();
    }
  });

  useEffect(() => {
    productionTabState.pub.initArticleProductionCalculation(
      getValues('articleProductionCalculation'),
    );
    const productionTabStateSub = productionTabState.sub
      .state()
      .subscribe(({ selectedArticleProdCalcPos, articleProductionCalculation }) => {
        setArticleProductionCalculationData(articleProductionCalculation);
        setSelectedArticleProdCalcPos(selectedArticleProdCalcPos);
      });
    calculationPopup.stream
      .state()
      .pipe(delay(0))
      .subscribe(({ action }) => {
        action === 'open' &&
          document.removeEventListener('click', saveArticleProductionCalculation.current);
        action === 'close' &&
          document.addEventListener('click', saveArticleProductionCalculation.current);
      });
    document.addEventListener('click', saveArticleProductionCalculation.current);
    return () => {
      productionTabStateSub.unsubscribe();
      document.removeEventListener('click', saveArticleProductionCalculation.current);
    };
  }, []);

  const isProductionQuantityRounding = getValues('isProductionQuantityRounding');
  const buttonList: IButtonList[] = useMemo(
    () => [
      {
        title: t('common.add'),
        icon: <AddIcon />,
        onClick: () => {
          calculationPopup.stream.emit('open', {
            values: {
              row: {
                isFermentationInterrupt: false,
                addedArticleDescription: '',
                addedArticleNo: '',
                addedArticleId: '',
                multiplier: null,
                id: `new_${Date.now()}`,
              },
              field: 'addedArticleDescription',
              currentArticleId,
            },
          });
        },
      },
      {
        title: t('common.remove'),
        icon: <RemoveIcon />,
        onClick: () => productionTabState.pub.deleteArticleProdCalcPos(),
        disabled: !articleProductionCalculationData.length || !selectedArticleProdCalcPos,
      },
    ],
    [t, selectedArticleProdCalcPos, articleProductionCalculationData, currentArticleId],
  );

  return (
    <FieldsSection
      marginTop={'31px'}
      title={t('article.calculation_for_production')}
      titleBoxSx={{ pt: 0 }}
      nomt
    >
      <FieldsRow>
        <FormCheckbox
          label={t('article.article_will_be_replaced_with_calculation')}
          name='willReplacedWithProductionCalc'
        />
        <FormCheckbox
          label={t('article.round_to_whole_pieces')}
          name='isProductionQuantityRounding'
        />
        <FormNumericField
          width='170px'
          label={t('article.rounds_to_units')}
          controls
          disabled={!isProductionQuantityRounding}
          precision={0}
          name='productionQuantityRoundTo'
        />
      </FieldsRow>
      <Box className='articleProductionCalculation' mt={2}>
        <Stack direction='row' spacing={2} mb={1}>
          {buttonList.reduce((acc: ReactNode[], { icon, ...props }, i) => {
            acc.push(
              <Button
                aria-hidden={true}
                key={i}
                color='primary'
                size='large'
                startIcon={icon}
                {...props}
              >
                {props.title}
              </Button>,
            );
            return acc;
          }, [])}
        </Stack>
        <STable
          rowSelectionModel={selectedArticleProdCalcPos?.id}
          onRowClick={(v, e) => {
            if (v.id !== selectedArticleProdCalcPos?.id) {
              productionTabState.pub.selectArticleProdCalcPos(v.row);
            }
            const field = (e.target as any).parentElement?.dataset?.field;
            if (field === 'addedArticleDescription' || field === 'multiplier') {
              calculationPopup.stream.emit('open', {
                values: {
                  row: v.row,
                  field,
                  currentArticleId,
                },
              });
            }
          }}
          onCellKeyDown={({ row, field }, { code }) => {
            if (
              code === 'Enter' &&
              (field === 'addedArticleDescription' || field === 'multiplier')
            ) {
              calculationPopup.stream.emit('open', {
                values: { row, field, currentArticleId },
              });
            }
          }}
          focusTabProps={{
            enabled: true,
            enabledFields: ['addedArticleDescription', 'multiplier'],
          }}
          height={200}
          data={articleProductionCalculationData || []}
        >
          <Column
            field='addedArticleNo'
            headerName={t('common.article_no')}
            width={110}
            sortable={false}
          />
          <Column
            field='addedArticleDescription'
            headerName={t('common.description')}
            sortable={false}
            width={350}
          />
          <Column
            field='multiplier'
            headerName={t('article.multiplier_on_amount')}
            width={165}
            sortable={false}
            align='right'
            headerAlign='right'
          />
          <Column
            field='isFermentationInterrupt'
            headerName={t('article.fermentation_interrupt')}
            width={178}
            headerAlign='center'
            align='center'
            renderCell={({ value }) => (
              <SCheckbox sx={{ m: 0 }} readOnly disableRipple checked={value} />
            )}
            sortable={false}
          />
        </STable>
      </Box>
      <FieldsRow>
        <FormTextField
          width='100%'
          label={t('article.used_in_the_calculation')}
          name='articleProductionCalculationDependencies.whichArticlesThisUses'
          multiline
          InputProps={{
            readOnly: true,
          }}
          rows={6}
        />
        <FormTextField
          width='100%'
          label={t('article.used_in_the_calculation_of_items')}
          name='articleProductionCalculationDependencies.whichArticlesUseThis'
          multiline
          InputProps={{
            readOnly: true,
          }}
          rows={6}
        />
      </FieldsRow>
      <calculationPopup.Component />
    </FieldsSection>
  );
};

const SCheckbox = styled(Checkbox)({
  '& label': {
    margin: 0,
  },
});

const STable = styled(Table)({
  '& .MuiDataGrid-cell': {
    padding: 0,
  },
  '& .MuiDataGrid-cellContent': {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'inherit',
  },
});

interface IButtonList {
  title: string;
  icon: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

interface ICalculationSection {
  dictArticles: ProductionTabOptionsRes['dictArticles'];
}
