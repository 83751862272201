import { gql } from 'graphql-request';

/* QUERIES start */
export const getCustomerList = gql`
  query listWA_CustomersForCreatingOrders($filter: WA_CustomersForCreatingOrdersGridFilter!) {
    wawiAssist {
      listWA_CustomersForCreatingOrders(filter: $filter) {
        customerId
        customerNo
        internalOrFullName
        draftOrderId
        offerId
        isAllowedAutoOrder
        isDeliveredOnDate
        isNoOrderOnDate
        hasOrderOnDate
      }
    }
  }
`;

export const getCustomerInfo = gql`
  query GetCustomerInfo($getCustomerDataId: ID!) {
    wawiAssist {
      getCustomerData(id: $getCustomerDataId) {
        phoneBusiness
        phoneBusiness2
        phoneMobile
        phonePrivate
        fullName
        fax
        email
        deliveryAddress
        customerNo
        informationTip
        discountKindId
        isDeliverySplitting
        deliverySplittingPartsCount
        transportSectorId
        transportSector {
          isPriceRateFixed
          priceRateFixed
        }
      }
    }
  }
`;

export const getNewOrderData = gql`
  query getWA_OrderDataForNewOrder($selectionProps: WA_OrderDataForNewOrderInputs!) {
    wawiAssist {
      getWA_OrderDataForNewOrder(selectionProps: $selectionProps) {
        deliveryTime
        note
        note2
        linkText
        referenceOrderNo
        specialAddress
        orderSourceInfo {
          orderNo
          orderDate
          orderTotal
        }
        positions {
          virtualPositionId
          articleId
          articleNo
          description
          quantity
          price
          total
          discount
          vatCode
          indTextDeliveryNote
          indTextProduction
          splittingPartsPreset
          quantityPerLot
          minQuantity
          maxQuantity
          saleUnit
          weight
          isChangeable
        }
      }
    }
  }
`;

export const listExistingOrders = gql`
  query listWA_ExistingOrdersOfCustomer($customerId: ID!, $onDate: Date!) {
    wawiAssist {
      listWA_ExistingOrdersOfCustomer(customerId: $customerId, onDate: $onDate) {
        orderNo
        userName
        createDateTime
      }
    }
  }
`;

export const getProfileForOneTimeCustomer = gql`
  query GetProfileForOneTimeCustomer($getDictWaCustomerProfileId: ID!) {
    wawiAssist {
      getDictWA_CustomerProfile(id: $getDictWaCustomerProfileId) {
        invoicingIntervalId
        paymentPeriod
        orderReportId
      }
    }
  }
`;

export const getCustomerFields = gql`
  query getCustomerFields($id: ID!) {
    wawiAssist {
      getCustomerData(id: $id) {
        id
        name
        address
        salutation
        addition
        street
        postBox
        city
        zipCode
        country
        email
        emailForInvoice
        phoneBusiness
        contactPerson
        orderReportId
        paymentPeriod
        invoicingIntervalId
      }
    }
  }
`;
export const dictOneTimeCustomers = gql`
  query DictWA_OneTimeCustomers {
    wawiAssist {
      dictWA_OneTimeCustomers {
        id
        label: name
      }
    }
  }
`;
/* QUERIES end */
/* MUTATIONS start */
export const createDraftOrder = gql`
  mutation CreateWA_DraftOrder($draftOrderProps: WA_DraftOrderInputs!) {
    wawiAssist {
      createWA_DraftOrder(draftOrderProps: $draftOrderProps)
    }
  }
`;
export const deleteDraftOrder = gql`
  mutation DeleteWA_DraftOrder($deleteWaDraftOrderId: ID!) {
    wawiAssist {
      deleteWA_DraftOrder(id: $deleteWaDraftOrderId)
    }
  }
`;
export const createOrder = gql`
  mutation createWA_Order($orderProps: WA_CreateOrderInputs!) {
    wawiAssist {
      createWA_Order(orderProps: $orderProps) {
        orderId
        orderNo
      }
    }
  }
`;
export const createAutoOrders = gql`
  mutation CreateWA_AutomaticOrders($customersIds: [ID!]!, $onDate: Date!) {
    wawiAssist {
      createWA_AutomaticOrders(customersIds: $customersIds, onDate: $onDate) {
        ordersCount
        affectedCustomersIds
      }
    }
  }
`;
export const createNoOrderForCustomer = gql`
  mutation CreateWA_NoOrderForCustomer($customerId: ID!, $onDate: Date!) {
    wawiAssist {
      createWA_NoOrderForCustomer(customerId: $customerId, onDate: $onDate)
    }
  }
`;
export const deleteNoOrderForCustomer = gql`
  mutation DeleteWA_NoOrderForCustomer($customerId: ID!, $onDate: Date!) {
    wawiAssist {
      deleteWA_NoOrderForCustomer(customerId: $customerId, onDate: $onDate)
    }
  }
`;
export const createOneTimeCustomer = gql`
  mutation CreateWA_OneTimeCustomer(
    $data: WA_CreateOneTimeCustomerInput!
    $customerProfileId: ID!
  ) {
    wawiAssist {
      createWA_OneTimeCustomer(data: $data, customerProfileId: $customerProfileId) {
        customerId
        customerNo
        internalOrFullName
        draftOrderId
        offerId
        isAllowedAutoOrder
        isDeliveredOnDate
        isNoOrderOnDate
        hasOrderOnDate
      }
    }
  }
`;
export const saveOneTimeCustomerData = gql`
  mutation SaveOneTimeCustomerData($dataToSave: WA_SaveOneTimeCustomerInput!) {
    wawiAssist {
      saveOneTimeCustomerData(dataToSave: $dataToSave) {
        customerId
        customerNo
        internalOrFullName
        draftOrderId
        offerId
        isAllowedAutoOrder
        isDeliveredOnDate
        isNoOrderOnDate
        hasOrderOnDate
      }
    }
  }
`;
/* MUTATIONS end */
