import { BehaviorSubject, Observable, filter, share } from 'rxjs';
export interface IState<T extends Record<string, any>> {
  action: T['action'];
  [key: string]: any;
}
/**
 * Abstract Pub class for state
 */
export abstract class Pub<T extends IState<T>> {
  constructor(protected stream$: BehaviorSubject<T>) {}
  protected emit(action: T['action'], value: Partial<Record<keyof Omit<T, 'action'>, any>>): void {
    let state = this.stream$.getValue();
    state = { ...state, ...value };
    state.action = action;
    this.stream$.next(state);
  }
}

/**
 * Abstract Sub class for state
 */
export abstract class Sub<T extends IState<T>> {
  protected shared$: Observable<T>;
  constructor(protected stream$: BehaviorSubject<T>) {
    this.shared$ = this.actionHandlers().pipe(
      filter(({ action }) => action !== undefined),
      share(),
    );
  }
  /**
   * Merge your all action handlers in one stream and return from this method `actionHandlers`
   * @example actionHandlers() {
    return merge(
      this.queryData(),
      this.cachedData(),
      ... )
}   */
  protected abstract actionHandlers(): Observable<T>;
  protected actionListener(name: T['action'] | T['action'][]): Observable<T> {
    if (Array.isArray(name)) {
      return this.stream$.pipe(filter(({ action }) => name.includes(action)));
    }
    return this.stream$.pipe(filter(({ action }) => action === name));
  }

  public state(): Observable<T> {
    return this.shared$;
  }
}
/**
 * Abstract State class for state implementation
 */
export abstract class State<T extends IState<T>> {
  protected stream$: BehaviorSubject<T>;
  constructor(initData: T) {
    this.stream$ = new BehaviorSubject<T>(initData);
  }
  abstract pub: Pub<T>;
  abstract sub: Sub<T>;
}
