import { FC } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import styled from '@mui/material/styles/styled';
import { SxProps, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Search: FC<ISearch> = ({ onSearch, value, sx }) => {
  const { t } = useTranslation();
  return typeof onSearch === 'function' ? (
    <SSearchWrapper sx={{ ...sx }}>
      <SSearchIconWrapper>
        <SearchIcon color='secondary' />
      </SSearchIconWrapper>
      <SSearch
        value={value}
        onChange={(e) => onSearch(e.target.value)}
        size='small'
        placeholder={t('common.search')}
        inputProps={{ 'aria-label': 'search' }}
      />
    </SSearchWrapper>
  ) : (
    <></>
  );
};

const SSearchWrapper = styled('div')(() => ({
  position: 'relative',
  marginBottom: 16,
  flexGrow: 1,
}));

const SSearch = styled(TextField)({
  width: '100%',
  '& > div': {
    paddingRight: 30,
  },
});

const SSearchIconWrapper = styled('div')(({ theme }) => ({
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: 10,
  '& svg': {
    color: theme.palette.grey['600'],
  },
}));

interface ISearch {
  onSearch?: (v: string) => void;
  value?: string;
  sx?: SxProps;
}
