import React, { FC, ReactNode, useRef, useState } from 'react';
/* Icons */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { Grow, IconButton, Stack, styled } from '@mui/material';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import { PopperOwnProps } from '@mui/base/Popper/Popper.types';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import useTouchRipple from '@mui/material/useTouchRipple';

export const SplitIconButton: FC<ISplitIconButton> = ({
  icon: Icon,
  children,
  placement = 'bottom',
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [disableRipple, setDisableRipple] = useState(false);

  const rippleRef = useRef(null);
  const { getRippleHandlers } = useTouchRipple({
    disabled: false,
    focusVisible: false,
    rippleRef,
  });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const isNestedBtn = !!(e.target as HTMLElement).closest('[datatype="spit-icon-arrow"]');
    if (isNestedBtn) {
      setOpen((prevOpen) => !prevOpen);
    } else {
      props.onClick?.(e);
      setOpen(false);
    }
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <SIconButton
        centerRipple={false}
        sx={{ borderRadius: '24px' }}
        ref={anchorRef}
        {...{ disableRipple }}
        {...props}
        onClick={handleClick}
      >
        <Icon />
        <SNestedBtn
          datatype='spit-icon-arrow'
          {...getRippleHandlers()}
          onMouseEnter={() => setDisableRipple(true)}
          onMouseLeave={(e) => {
            getRippleHandlers().onMouseLeave(e);
            setDisableRipple(false);
          }}
        >
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          <TouchRipple ref={rippleRef} />
        </SNestedBtn>
      </SIconButton>
      <SPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        {...{ placement }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose} mouseEvent='onMouseUp'>
                <MenuList id='split-icon-button-menu' autoFocusItem>
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </SPopper>
    </>
  );
};

const SIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    background: theme.palette.grey.A100,
  },
}));

const SNestedBtn = styled(Stack)(({ theme }) => ({
  position: 'relative',
  padding: 0,
  marginLeft: 2,
  width: 24,
  height: 24,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  '&:hover': {
    background: theme.palette.grey['200'],
  },
}));

const SPopper = styled(Popper)({
  zIndex: 1,
});

interface ISplitIconButton extends IconButtonProps {
  icon: OverridableComponent<SvgIconTypeMap>;
  placement?: PopperOwnProps['placement'];
  children: ReactNode;
}
