import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { IPopupWrapper } from '../../../../../../../../../shared/components/popup/components/popup.component.tsx';
import { Box, Button, Stack, styled, ToggleButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Table } from '../../../../../../../../../shared/components/table/table.component.tsx';
import { Column } from '../../../../../../../../../shared/components/table/components/common.components.tsx';
import {
  formatValue,
  weekDays,
} from '../../../../../../../../../shared/helpers/utils/utils.helper.ts';
import { FormCheckbox, FormNumericField } from '../../../../../../../../../shared/components/form';
import {
  attachSplittingsToQuantity,
  clearDataFromVirtualKeys,
  generateSplittingColumns,
} from './gridItems/cells/splitting/additionalFunctions.tsx';
import { defaultOrderState, IDefaultOrderState } from '../../../../defaultOrder.state.ts';
import { useFormContext } from 'react-hook-form';
import { FormToggleButtonGroup } from '../../../../../../../../../shared/components/form/formConnected/formToggleButtonGroup.tsx';
import { ProductCell } from './gridItems/cells/product.cell.tsx';
import { storageHelper } from '../../../../../../../../../shared/helpers/storage';
import { viewCells } from '../../gridItems/views/viewCells.ts';
import { viewRows } from '../../gridItems/views/viewRows.ts';
import { deliverySplittingState, IDeliverySplittingState } from './deliverySplitting.state.ts';
import { useBeforeClosePopup } from '../../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import equal from 'fast-deep-equal/react';

export const ContentPopup: FC<IContentPopup> = ({ stream }) => {
  const { t } = useTranslation();
  const {
    watch,
    getValues,
    resetField,
    formState: { dirtyFields },
  } = useFormContext();
  const [initTableData, setInitTableData] = useState<IDefaultOrderState['defaultOrderGridData']>(
    [],
  );
  const [tableData, setTableData] = useState<IDefaultOrderState['defaultOrderGridData']>([]);
  const [productCellLoading, setProductCellLoading] = useState<number | null>(null);
  const [calculatePositionSplitting, setCalculatePositionSplitting] = useState<
    IDeliverySplittingState['calculatedSplitting']
  >({});
  const [selectedPos, setSelectedPos] = useState<IDeliverySplittingState['selectedPos']>();

  useLayoutEffect(() => {
    stream.actionListener('open').subscribe(({ tableData }) => {
      setTableData(tableData);
      setInitTableData(structuredClone(tableData));
      deliverySplittingState.pub.initGridData(tableData);
    });
  }, []);

  useEffect(() => {
    const deliverySplittingStateSub = deliverySplittingState.sub
      .state()
      .subscribe(({ deliverySplittingGridData, calculatedSplitting, selectedPos }) => {
        setCalculatePositionSplitting(calculatedSplitting);
        setTableData(deliverySplittingGridData);
        setSelectedPos(selectedPos);
      });
    const productCellsLoadingSub = deliverySplittingState.sub
      .productCellLoading()
      .subscribe(setProductCellLoading);
    return () => {
      deliverySplittingStateSub.unsubscribe();
      productCellsLoadingSub.unsubscribe();
    };
  }, []);

  const weekDayButtons = useMemo(
    () =>
      weekDays.map((day, i) => (
        <ToggleButton
          key={i}
          title={t(`common.${day}`)}
          value={day}
          size='small'
          sx={{ flex: 1, height: '40px', maxWidth: 140, minWidth: 30 }}
        >
          <SButtonName>{t(`common.${day}`)}</SButtonName>
        </ToggleButton>
      )),
    [t],
  );

  const handleApply = () => {
    const { enabledDays: _, ...values } = getValues() as IDefaultOrderState['splittingFormData'];
    const splittingFormDataToSave = {} as IDefaultOrderState['splittingFormDataToSave'];
    for (const field in values as IDefaultOrderState['splittingFormDataToSave']) {
      if (dirtyFields[field]) {
        const typedField = field as keyof IDefaultOrderState['splittingFormDataToSave'];
        splittingFormDataToSave[typedField] = values[typedField] as any;
      }
    }
    defaultOrderState.pub.saveDeliverySplittingData({
      defaultOrderGridData: clearDataFromVirtualKeys(tableData),
      splittingFormDataToSave,
    });
    stream.emit('close');
  };

  const getDirtyStatus = useCallback(() => {
    const { enabledDays: _, ...fields } = dirtyFields;
    return !!Object.keys(fields).length || !equal(tableData, initTableData);
  }, [dirtyFields, tableData, initTableData]);

  useBeforeClosePopup({
    stream,
    isDirty: getDirtyStatus(),
    handlers: () => {
      resetField('isDeliverySplitting');
      resetField('deliverySplittingPartsCount');
      stream.emit('close');
    },
  });

  return (
    <Box>
      <FormCheckbox label={t('customer.enablePartialDelivery')} name='isDeliverySplitting' />
      <Stack direction='row' mb={3} mt={2}>
        <FormNumericField
          disabled={!watch('isDeliverySplitting')}
          name='deliverySplittingPartsCount'
          onChange={() => deliverySplittingState.pub.clearCalculatedSplitting()}
          label={t('customer.numberOfPieces')}
          precision={0}
          min={2}
          max={10}
          controls
          width='170px'
        />
        <SFormToggleButtonGroup
          disabled={!watch('isDeliverySplitting')}
          name='enabledDays'
          sx={{ width: '100%', display: 'flex', ml: 2 }}
        >
          {weekDayButtons}
        </SFormToggleButtonGroup>
      </Stack>
      <STable
        data={tableData}
        heightOffset={310}
        initialState={{
          columns: storageHelper.local.getItem('defaultOrder.splittingGridColumnModel'),
        }}
        columnVisibilityModel={{
          'group_mondaySettings.splittingPartsPreset':
            watch('isDeliverySplitting') && watch('enabledDays').includes('monday'),
          'group_tuesdaySettings.splittingPartsPreset':
            watch('isDeliverySplitting') && watch('enabledDays').includes('tuesday'),
          'group_wednesdaySettings.splittingPartsPreset':
            watch('isDeliverySplitting') && watch('enabledDays').includes('wednesday'),
          'group_thursdaySettings.splittingPartsPreset':
            watch('isDeliverySplitting') && watch('enabledDays').includes('thursday'),
          'group_fridaySettings.splittingPartsPreset':
            watch('isDeliverySplitting') && watch('enabledDays').includes('friday'),
          'group_saturdaySettings.splittingPartsPreset':
            watch('isDeliverySplitting') && watch('enabledDays').includes('saturday'),
          'group_sundaySettings.splittingPartsPreset':
            watch('isDeliverySplitting') && watch('enabledDays').includes('sunday'),
        }}
        onChangeColumnModel={(v) =>
          storageHelper.local.setItem('defaultOrder.splittingGridColumnModel', v)
        }
        hidingColumnModel={{
          generatedModelFn: attachSplittingsToQuantity,
        }}
        onRowClick={({ row }) => {
          if (row.id !== selectedPos?.id) deliverySplittingState.pub.selectPosition(row);
        }}
        rowSelectionModel={selectedPos?.id}
        onCellEditStop={(v, e) => {
          if (v.reason === 'enterKeyDown') {
            e.defaultMuiPrevented = true;
          }
        }}
        focusTabProps={{ enabled: true }}
        {...{ viewRows, viewCells }}
      >
        <Column sortable={false} headerName={t('common.number')} field='articleNo' width={100} />
        <Column
          sortable={false}
          headerName={t('common.description')}
          renderCell={(params) => <ProductCell {...{ params, productCellLoading }} />}
          width={300}
          field='description'
        />
        <Column
          sortable={false}
          headerName={t('common.mon')}
          field='mondaySettings.quantity'
          valueGetter={({ row }) => row.mondaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
        />
        {generateSplittingColumns(
          watch('deliverySplittingPartsCount'),
          'mondaySettings',
          calculatePositionSplitting,
        )}
        <Column
          sortable={false}
          headerName={t('common.tue')}
          field='tuesdaySettings.quantity'
          valueGetter={({ row }) => row.tuesdaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
        />
        {generateSplittingColumns(
          watch('deliverySplittingPartsCount'),
          'tuesdaySettings',
          calculatePositionSplitting,
        )}
        <Column
          sortable={false}
          headerName={t('common.wed')}
          field='wednesdaySettings.quantity'
          valueGetter={({ row }) => row.wednesdaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
        />
        {generateSplittingColumns(
          watch('deliverySplittingPartsCount'),
          'wednesdaySettings',
          calculatePositionSplitting,
        )}
        <Column
          sortable={false}
          headerName={t('common.thu')}
          field='thursdaySettings.quantity'
          valueGetter={({ row }) => row.thursdaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
        />
        {generateSplittingColumns(
          watch('deliverySplittingPartsCount'),
          'thursdaySettings',
          calculatePositionSplitting,
        )}
        <Column
          sortable={false}
          headerName={t('common.fri')}
          field='fridaySettings.quantity'
          valueGetter={({ row }) => row.fridaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
        />
        {generateSplittingColumns(
          watch('deliverySplittingPartsCount'),
          'fridaySettings',
          calculatePositionSplitting,
        )}
        <Column
          sortable={false}
          headerName={t('common.sat')}
          field='saturdaySettings.quantity'
          valueGetter={({ row }) => row.saturdaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
        />
        {generateSplittingColumns(
          watch('deliverySplittingPartsCount'),
          'saturdaySettings',
          calculatePositionSplitting,
        )}
        <Column
          sortable={false}
          headerName={t('common.sun')}
          field='sundaySettings.quantity'
          valueGetter={({ row }) => row.sundaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
        />
        {generateSplittingColumns(
          watch('deliverySplittingPartsCount'),
          'sundaySettings',
          calculatePositionSplitting,
        )}
      </STable>
      <Stack mt={3} direction='row' justifyContent='flex-end' spacing={2}>
        <Button onClick={handleApply} variant='contained'>
          {t('common.apply')}
        </Button>
      </Stack>
    </Box>
  );
};

const STable = styled(Table)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders [data-field*="splittingPartsPreset"]': {
    backgroundColor: theme.palette.grey['50'],
  },
}));

const SButtonName = styled('span')(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const SFormToggleButtonGroup = styled(FormToggleButtonGroup)(({ disabled }) => ({
  ...(disabled && {
    '& .Mui-selected': {
      color: 'rgba(0, 0, 0, 0.26)!important',
      border: '1px solid rgba(0, 0, 0, 0.12)!important',
      backgroundColor: 'transparent',
    },
    '.MuiToggleButtonGroup-middleButton': {
      borderLeft: '1px solid transparent!important',
    },
  }),
}));

interface IContentPopup {
  stream: IPopupWrapper['stream'];
}
