import { take } from 'rxjs';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';
import { ArticleListsTabDataRes, articleService } from '../services/article.service';
import { ITabState } from '../states/tabState.model';

export const defaultArticleListsTabData: ArticleListsTabDataRes = {
  id: '',
  articleListsCheckedState: [
    {
      id: '',
    },
  ],
};

export async function resolveDataForListsTab(data: ITabState): Promise<ITabState> {
  data.defaultValues = data?.articleId
    ? await articleService.globHelpers.streamToPromise(
        articleService.sub.articleListsTabData().pipe(
          responseHandler<ArticleListsTabDataRes>({
            errorReturnType: defaultArticleListsTabData,
            customErrorHandler: () => 'article.tab_not_loaded',
          }),
          take(1),
        ),
        () => {
          articleService.pub.articleListsTabData({ id: data.articleId! });
        },
      )
    : {};
  data.defaultValues.id = data.articleId;
  return data;
}
