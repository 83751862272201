import { ChangeEvent, FC } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup/ToggleButtonGroup';

export const FormToggleButtonGroup: FC<IFormToggleButtonGroup> = ({
  onChange,
  name,
  controllerProps = {},
  defaultValue,
  children,
  ...props
}) => {
  return (
    <Controller
      {...controllerProps}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange: onFieldChange, value, ...fieldProps } }) => (
        <ToggleButtonGroup
          {...fieldProps}
          value={value}
          onChange={(_, v) => {
            onFieldChange(v);
            onChange?.(v);
          }}
          {...props}
        >
          {children}
        </ToggleButtonGroup>
      )}
    />
  );
};

interface IFormToggleButtonGroup extends Omit<ToggleButtonGroupProps, 'value' | 'onChange'> {
  onChange?: (v: ChangeEvent<HTMLInputElement>['target']['value']) => void;
  controllerProps?: Omit<ControllerProps, 'render' | 'name'>;
  name: ControllerProps['name'];
}
