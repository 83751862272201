import {
  Popup,
  TPopupComponent,
} from '../../../../../../../shared/components/popup/popup.abstract.tsx';
import { FC, useMemo } from 'react';
import { TPopupDefaultActions } from '../../../../../../../shared/components/popup/services/popup.service.ts';
import { useTranslation } from 'react-i18next';
import { Content } from './content.popup.tsx';

export class ChooseDefaultOrderPopup extends Popup<IChooseDefaultOrderPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const { t } = useTranslation();
      const Wrapper = useMemo(() => this.Wrapper, []);

      return (
        <Wrapper
          fullWidth
          stream={stream}
          PaperProps={{ sx: { maxWidth: '700px' } }}
          popupTitle={t('order.choose_default')}
        >
          <Content {...{ stream }} />
        </Wrapper>
      );
    };
  }
}

export interface IChooseDefaultOrderPopup {
  action: TPopupDefaultActions | 'confirm';
}
