import { FC, useEffect } from 'react';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { delay, filter, tap } from 'rxjs';
import { navigateService } from '../navigate.service';
import equal from 'fast-deep-equal/react';

let oldParams: Params = {};

export const Navigate: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    const unsub = navigateService.sub
      .state()
      .pipe(
        filter((state) => state.action === 'navigate'),
        delay(300),
        tap(({ navigateTo, replace }) => {
          navigate(navigateTo, { replace, state: { navigateFrom: location.pathname } });
        }),
      )
      .subscribe();
    return () => {
      unsub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (equal(params, oldParams) === false) {
      oldParams = params;
      navigateService.pub.params({ params, navigateTo: location.pathname });
    }
  }, [params]);
  return null;
};
