import { FC } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getDeliveryTime } from '../../../../../../shared/helpers/utils/utils.helper.ts';
import {
  TimePicker,
  ITimePicker,
} from '../../../../../../shared/components/datePicker/timePicker.component.tsx';

export const FormDeliveryTimePicker: FC<IFormDeliveryTimePicker> = ({
  name,
  onChange,
  onBlur,
  ...props
}) => {
  const disabled = props?.fieldProps?.disabled;
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      render={({
        field: {
          value,
          onChange: onFieldChange,
          onBlur: onFieldBlur,
          disabled: fieldDisabled,
          ...restFields
        },
      }) => {
        return (
          <TimePicker
            {...props}
            errorText={
              !getDeliveryTime(value) && typeof value === 'string'
                ? `${t('customer.invalid_value')}: ${value}`
                : undefined
            }
            value={getDeliveryTime(value)}
            onChange={(e) => {
              onFieldChange(e);
              onChange?.(e);
            }}
            onBlur={(e) => {
              onFieldBlur(); // notify react-hook-form when input is touched/blur
              onBlur?.(e);
            }}
            disabled={typeof disabled === 'boolean' ? disabled : fieldDisabled}
            {...restFields}
          />
        );
      }}
    />
  );
};

interface IFormDeliveryTimePicker extends Omit<ITimePicker, 'value' | 'defaultValue'> {
  name: ControllerProps['name'];
}
