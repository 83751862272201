import { FC, ReactNode, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { GridAggregationFunction } from '@mui/x-data-grid-premium';
import { CircularProgress, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
/* Icons */
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FlipIcon from '@mui/icons-material/Flip';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import MergeIcon from '@mui/icons-material/Merge';

import { FieldsRow } from '../../../../../../shared/components/form';
import { IconButton } from '../../../../common/iconButton/iconButton.component';
import { Table } from '../../../../../../shared/components/table/table.component';
import { Column } from '../../../../../../shared/components/table/components/common.components';
import { OrderActions } from './orderActions.component';
import {
  attachSplittingToQuantity,
  generateSplittingColumns,
} from '../../../../common/orderGridItems/cells/splitting/additionalFunctions';
import { storageHelper } from '../../../../../../shared/helpers/storage';
import { IOrderGridOptions, TOrderDetails } from '../orderDetails.component';
import { IndividualDescriptionPopup } from '../../../../common/popups/individualDescription/individualDescription.popup';
import {
  C_Discount_Kind,
  C_Kg_Representation,
  C_Sale_Unit,
} from '../../../../../../graphql/generatedModel';
/* Cells */
import { ProductCell } from '../../../../common/orderGridItems/cells/product/product.cell';
import { ProductEditCell } from '../../../../common/orderGridItems/cells/product/productEdit.cell';
import { PriceEditCell } from '../../../../common/orderGridItems/cells/price/priceEdit.cell.tsx';
import { DiscountEditCell } from '../../../../common/orderGridItems/cells/discountEdit.cell';
import { VatCell } from '../../../../common/orderGridItems/cells/vat/vat.cell';
import { VatEditCell } from '../../../../common/orderGridItems/cells/vat/vatEdit.cell';
import { QuantityEditCell } from '../../../../common/orderGridItems/cells/quantity/quantityEdit.cell';
import { QuantityCell } from '../../../../common/orderGridItems/cells/quantity/quantity.cell';
import { DeletionCell } from '../../../../common/orderGridItems/cells/deletionCell.cell.tsx';

import { blue } from '@mui/material/colors';
import { viewRows } from '../../../../common/orderGridItems/views/viewRows.ts';
import {
  getQuantityOrPriceFromBarcode,
  IOrderDetailsState,
  orderDetailsState,
} from '../../../states/orderDetails.state';
import { localeFormatterHelper } from '../../../../../../shared/helpers/formatter/localeFormatter.helper';
import { viewCells } from '../../../../common/orderGridItems/views/viewCells';
import { PriceCell } from '../../../../common/orderGridItems/cells/price/price.cell.tsx';
import { snackbarService } from '../../../../../../shared/components/snackbar/service/snackbar.service.ts';
import { ConfirmationPopup } from '../../../../../../shared/components/popupTemplates/cofirmationPopup/confirmation.popup.tsx';
import { onlyNumbers } from '../../../../../article/components/tabs/label/validation/label.schema.ts';
import { companyConfigService } from '../../../../../../shared/services/companyConfig/companyConfig.service.ts';

export const OrderGrid: FC<IOrderGrid> = ({
  data,
  rowReordering,
  deliveryFunctionActive,
  orderGridOptions,
  isEditable,
  isPriceRateFixed,
}) => {
  const { t } = useTranslation();
  const { positions, ...orderData } = data.orderData;
  const columnModel = storageHelper.local.getItem('editOrder.orderDetailsColumnModel');
  const { customerInfo } = data;
  const [cellsLoading, setCellsLoading] = useState<string[]>([]);
  const [calculationPriceError, setCalculationPriceError] = useState<number[]>([]);
  const [deliveryCostLoading, setDeliveryCostLoading] = useState(false);
  const [visibleSplitting, setVisibleSplitting] = useState(false);
  const [visibleDeliveryCost, setVisibleDeliveryCost] = useState(false);
  const [isBarcodeScanning, setIsBarcodeScanning] = useState(false);
  // this state store barcode (when customer use isBarcodeScanning mode)
  const [_, setBarcode] = useState('');
  const [barcodeLoading, setBarcodeLoading] = useState(false);

  const individualDescriptionPopup = useMemo(() => new IndividualDescriptionPopup(), []);
  const mergePositionsPopup = useMemo(() => new ConfirmationPopup(), []);
  const formatValue = (value: number) => {
    return localeFormatterHelper.formatNumber(value, {
      returnZero: false,
    });
  };
  const buttonList: IButtonList[] = useMemo(
    () => [
      {
        title: t('order.add_article'),
        icon: <AddIcon />,
        onClick: () => orderDetailsState.pub.addPosition({ type: 'addPosition' }),
      },
      {
        title: t('order.remove_article'),
        icon: <RemoveIcon />,
        disabled: !data.selectedPos,
        onClick: () => {
          return orderDetailsState.pub.deletePosition({
            type: 'deletePosition',
            position: data.selectedPos,
          });
        },
      },
      {
        title: t('common.revert_changes'),
        icon: <RestartAltIcon />,
        disabled: !data.dirty,
        onClick: () => {
          return orderDetailsState.pub.resetOrderDataChanges();
        },
      },
      {
        title: t('order.delivery_splitting'),
        icon: <FlipIcon />,
        hidden: !data.customerInfo.isDeliverySplitting,
        active: visibleSplitting,
        onClick: () => {
          const timer = setTimeout(() => {
            setVisibleSplitting((prevState) => !prevState);
            clearTimeout(timer); // fixes the problem if the cell is in edit mode
          }, 0);
        },
      },
      {
        title: t('order.add_shipping_cost'),
        icon: <LocalShippingIcon />,
        active: visibleDeliveryCost,
        hidden: !deliveryFunctionActive,
        loading: deliveryCostLoading,
        onClick: () => {
          if (visibleDeliveryCost) {
            orderDetailsState.pub.deletePosition({ type: 'deleteDeliveryPosition' });
          } else orderDetailsState.pub.addPosition({ type: 'addDeliveryPosition' });
          setVisibleDeliveryCost((prevState) => !prevState);
        },
      },
      {
        title: t('order.barcode_scan'),
        icon: <QrCodeScannerIcon />,
        active: isBarcodeScanning,
        loading: barcodeLoading,
        onClick: () => {
          setIsBarcodeScanning((v) => {
            const inverseValue = !v;
            // when we finish Scanning mode we clean our barcode state
            if (!inverseValue) {
              setBarcode('');
            }
            return inverseValue;
          });
        },
      },
      {
        title: t('order.merge_positions'),
        icon: <MergeIcon />,
        disabled: !data?.orderData?.positions?.length,
        onClick: () => {
          mergePositionsPopup.stream.emit('open', {
            onApply: () => orderDetailsState.pub.mergePositions(),
            message: t('order.should_similar_items_be_merged'),
          });
        },
      },
    ],
    [t, data, visibleSplitting, visibleDeliveryCost, isBarcodeScanning, barcodeLoading],
  );

  const getAllNumberRow: GridAggregationFunction<string, string | null> = {
    apply: () => {
      const count: number =
        positions?.filter((el) => el?.quantity && el?.articleId && !el?.virtualPositionId).length ||
        0;
      return `${t('order.article', { count })}: ${count}`;
    },
    label: '',
    columnTypes: ['string'],
  };

  const getQuantity: GridAggregationFunction<string, string | null> = {
    apply: () => {
      const count = positions?.reduce((acc, el) => {
        if (el?.quantity && !el?.virtualPositionId) {
          acc += el.quantity;
        }
        return acc;
      }, 0);
      return String(count || '');
    },
    label: '',
    columnTypes: ['string'],
  };

  const isValidBarcode = (value: string) => {
    const length = value?.length;
    return length === 13 || length === 8;
  };

  const showError = (content: string) => {
    snackbarService.pub.show({
      content,
      type: 'error',
    });
  };

  useEffect(() => {
    const handleBarcodeScanned = (e: KeyboardEvent) => {
      const sign = e?.key;
      if (isBarcodeScanning && sign) {
        setBarcode((barCode) => {
          if (sign === 'Enter') {
            e.preventDefault();
            const isLengthValid = isValidBarcode(barCode);
            if (isLengthValid) {
              const isOnlyNumbers = onlyNumbers(barCode);
              if (isOnlyNumbers) {
                const prefix = barCode.slice(0, 7);
                let barecodeWithPG = false;
                let quantity = 1;
                let price = 0;
                const simpleArticleInfo = orderGridOptions?.productList?.find(
                  ({ eanCode }) => eanCode === barCode,
                );
                if (simpleArticleInfo?.id) {
                  orderDetailsState.pub.addPositionByBarcode(simpleArticleInfo, quantity, price);
                } else {
                  const articleInfoWithPG = orderGridOptions?.productList?.find(
                    ({ eanCode, saleUnit }) => {
                      const endsOnPG = eanCode?.endsWith('GGGGGG') || eanCode?.endsWith('PPPPPP');
                      const isMatch = eanCode?.slice(0, 7) === prefix;
                      const neededProduct = endsOnPG && isMatch;
                      if (neededProduct) {
                        barecodeWithPG = !onlyNumbers(eanCode);
                        if (barecodeWithPG && eanCode?.endsWith('GGGGGG')) {
                          const quantityFromBarcode = getQuantityOrPriceFromBarcode(barCode);
                          if (quantityFromBarcode) {
                            const kiloAmountRepresentationId =
                              companyConfigService.getCachedConfigs()?.kiloAmountRepresentationId;
                            if (
                              kiloAmountRepresentationId === C_Kg_Representation.KGR1_1_IS_1 &&
                              saleUnit === C_Sale_Unit.SU3_1000G
                            ) {
                              quantity = quantityFromBarcode / 1000;
                            } else {
                              quantity = quantityFromBarcode;
                            }
                          }
                        }
                        if (barecodeWithPG && eanCode?.endsWith('PPPPPP')) {
                          price = getQuantityOrPriceFromBarcode(barCode) / 100;
                        }
                        return true;
                      }
                      return false;
                    },
                  );
                  if (articleInfoWithPG?.id) {
                    orderDetailsState.pub.addPositionByBarcode(articleInfoWithPG, quantity, price);
                  } else {
                    showError(t('order.error_article_with_this_barcode_not_found'));
                  }
                }
              } else {
                showError(t('article.eanCode_onlyNumb'));
              }
            } else {
              if (barCode?.length) {
                showError(t('article.eanCode_must_digits'));
              }
            }
            return '';
          }
          return onlyNumbers(sign) ? barCode + sign : barCode;
        });
      }
    };

    document.addEventListener('keydown', handleBarcodeScanned);

    return () => {
      document.removeEventListener('keydown', handleBarcodeScanned);
    };
  }, [isBarcodeScanning]);

  useEffect(() => {
    const cellsLoadingSub = orderDetailsState.sub.cellsLoading().subscribe(setCellsLoading);
    const barcodeLoadingSub = orderDetailsState.sub.barcodeLoading().subscribe(setBarcodeLoading);
    const calculationPriceErrorSub = orderDetailsState.sub
      .calculationPriceError()
      .subscribe(setCalculationPriceError);
    individualDescriptionPopup.stream.state().subscribe(({ action, row }) => {
      if (action === 'save') {
        orderDetailsState.pub.updatePosition(row);
      }
    });
    const deliveryCostLoadingSub = orderDetailsState.sub
      .deliveryCostLoading()
      .subscribe(setDeliveryCostLoading);
    return () => {
      cellsLoadingSub.unsubscribe();
      deliveryCostLoadingSub.unsubscribe();
      calculationPriceErrorSub.unsubscribe();
      individualDescriptionPopup.stream.unsubscribe();
      mergePositionsPopup?.stream?.unsubscribe();
      barcodeLoadingSub.unsubscribe();
    };
  }, []);

  useLayoutEffect(() => {
    const isDelivery = !!positions?.some((el) => el?.virtualPositionId);
    setVisibleDeliveryCost((prevState) => {
      if (isDelivery !== prevState) return isDelivery;
      return prevState;
    });
  }, [positions]);

  const generatedSplittingColumns = useMemo(() => {
    return generateSplittingColumns(
      data.customerInfo.deliverySplittingPartsCount || 0,
      !!data.customerInfo.isDeliverySplitting,
      'edit',
    );
  }, [data]);

  return (
    <>
      <FieldsRow justifyContent='space-between' alignItems='center' marginTop={3} spacing={0}>
        {
          <>
            <Stack direction='row' spacing={2}>
              {buttonList.reduce(
                (acc: ReactNode[], { icon, loading, disabled = false, hidden, ...props }, i) => {
                  if (!hidden) {
                    acc.push(
                      <IconButton
                        sx={{ position: 'relative' }}
                        key={i}
                        color='primary'
                        size='large'
                        {...props}
                        disabled={!isEditable ? true : disabled}
                      >
                        {loading && (
                          <CircularProgress
                            thickness={1}
                            size={48}
                            sx={{
                              position: 'absolute',
                              color: blue[500],
                              top: 0,
                              left: 0,
                              zIndex: 1,
                            }}
                          />
                        )}
                        {icon}
                      </IconButton>,
                    );
                  }
                  return acc;
                },
                [],
              )}
            </Stack>
            <OrderActions dirty={data.dirty} />
          </>
        }
      </FieldsRow>
      <Table
        initialState={{
          aggregation: {
            model: {
              articleNo: 'allNumberRow',
              total: 'sum',
              quantity: 'quantity',
            },
          },
          columns: columnModel,
        }}
        data={positions as []}
        heightOffset={348}
        sx={{ mt: 1 }}
        emptyTableIndication={{
          icon: <LibraryAddIcon />,
          text: t('order.empty_order_grid'),
        }}
        columnVisibilityModel={{
          group_splittingPartsPreset: visibleSplitting,
        }}
        disableRowReorder={['deliveryCost']}
        onRowClick={(e) => {
          if (data.selectedPos?.id !== e.id && !e.row.virtualPositionId && e.row.isChangeable)
            orderDetailsState.pub.selectPosition(e.row);
        }}
        rowSelectionModel={data.selectedPos?.id || undefined}
        isCellEditable={(e) => {
          return isEditable && e.row.isChangeable && !e.row.virtualPositionId;
        }}
        hidingColumnModel={{
          generatedModelFn: attachSplittingToQuantity,
        }}
        onChangeColumnModel={(v) =>
          storageHelper.local.setItem('editOrder.orderDetailsColumnModel', v)
        }
        onCellEditStop={(v, e) => {
          if (v.reason === 'enterKeyDown') {
            e.defaultMuiPrevented = true;
          }
        }}
        focusTabProps={{
          disabledRowsFocus: ['!virtualPositionId', 'isChangeable'],
          enabled: true,
        }}
        onChangeOrderPosition={(v) => {
          const virtualPositionId = v.findIndex((el) => el.virtualPositionId);
          if (virtualPositionId !== -1) {
            const deliveryPos = v[virtualPositionId];
            v.splice(virtualPositionId, 1);
            v.push(deliveryPos);
          }
          orderDetailsState.pub.updateOrderData({
            ...orderData,
            positions: v || [],
          } as IOrderDetailsState['orderData']);
        }}
        onRowsCountChange={(rows, prevRows, apiRef) => {
          const isNotEventDeliveryCost =
            rows?.[rows.length - 1]?.virtualPositionId ===
            prevRows?.[prevRows.length - 1]?.virtualPositionId;
          if (rows.length > prevRows.length && isNotEventDeliveryCost) {
            const newRow = rows?.[rows.length - (visibleDeliveryCost ? 2 : 1)];
            if (newRow.__reorder__ === t('order.new_position')) {
              apiRef?.current.startCellEditMode({ id: newRow.id as string, field: 'quantity' });
            }
          }
        }}
        aggregationFunctions={{ allNumberRow: getAllNumberRow, quantity: getQuantity }}
        rowReordering={rowReordering}
        viewCells={viewCells}
        viewRows={viewRows}
        scrollToSelectedRow
      >
        <Column
          field='articleNo'
          headerName={t('common.article_no')}
          width={100}
          sortable={false}
        />
        <Column
          field='quantity'
          headerName={t('order.quantity')}
          width={100}
          sortable={false}
          headerAlign='right'
          align='right'
          renderEditCell={(params) => <QuantityEditCell type='edit' {...params} />}
          renderCell={(params) => <QuantityCell loading={cellsLoading} {...{ params }} />}
          editable
        />
        {generatedSplittingColumns}
        <Column
          field='description'
          headerName={t('order.description')}
          width={350}
          sortable={false}
          renderCell={(params) => (
            <ProductCell
              loading={cellsLoading}
              popupStream={individualDescriptionPopup.stream}
              isEditable={isEditable}
              {...{ params, isPriceRateFixed }}
            />
          )}
          renderEditCell={(params) => (
            <ProductEditCell options={orderGridOptions.productList} type='edit' {...{ params }} />
          )}
          editable
        />
        <Column
          field='price'
          headerName={t('order.price')}
          width={140}
          type='number'
          headerAlign='right'
          align='right'
          sortable={false}
          editable
          renderCell={(params) => <PriceCell errors={calculationPriceError} {...{ params }} />}
          renderEditCell={(params) => <PriceEditCell type='edit' {...params} />}
        />
        <Column
          field='total'
          valueFormatter={({ value }) => (value ? formatValue(value) : '')}
          headerName={t('order.total')}
          width={140}
          headerAlign='right'
          align='right'
          type='number'
          sortable={false}
        />
        <Column
          field='discount'
          headerName={t('order.discount')}
          width={100}
          valueFormatter={(param) => (param.value ? `${param.value}%` : '')}
          renderEditCell={(params) => <DiscountEditCell type='edit' {...params} />}
          editable={
            customerInfo?.discountKindId !== C_Discount_Kind.DK5_QUANTITY_DISCOUNT &&
            customerInfo?.discountKindId !== C_Discount_Kind.DK6_QUANTITY_DISCOUNT_GROUPS
          }
          headerAlign='right'
          align='right'
          sortable={false}
        />
        <Column
          field='vatCode'
          headerName={t('order.vat')}
          width={80}
          editable
          renderCell={(params) => (
            <VatCell taxes={orderGridOptions.taxes} loading={cellsLoading} {...{ params }} />
          )}
          renderEditCell={(params) => (
            <VatEditCell taxes={orderGridOptions.taxes} type='edit' {...{ params }} />
          )}
          sortable={false}
        />
        <Column
          field='deleting'
          flex={1}
          align='right'
          headerName=''
          renderCell={(params) => <DeletionCell type='edit' {...{ params, isEditable }} />}
          sortable={false}
          resizable={false}
          disableReorder
        />
      </Table>
      <individualDescriptionPopup.Component />
      <mergePositionsPopup.Component />
    </>
  );
};

export interface IOrderGrid {
  data: TOrderDetails;
  rowReordering: boolean;
  deliveryFunctionActive: boolean;
  orderGridOptions: IOrderGridOptions;
  isEditable: boolean;
  isPriceRateFixed: boolean;
}

interface IButtonList {
  title: string;
  icon: ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  loading?: boolean;
  active?: boolean;
  onClick: () => void;
}
