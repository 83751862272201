import { FC, forwardRef, ReactNode, useRef } from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel';

export const Checkbox: FC<ICheckboxProps> = forwardRef(
  ({ label, labelProps, ...checkboxProps }, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    return (
      <FormControlLabel
        sx={{ whiteSpace: 'nowrap' }}
        control={
          <MuiCheckbox
            {...checkboxProps}
            onChange={(e, v) => {
              inputRef?.current?.focus();
              checkboxProps?.onChange?.(e, v);
            }}
          />
        }
        inputRef={inputRef}
        label={label}
        ref={ref}
        {...labelProps}
      />
    );
  },
);

export interface ICheckboxProps extends CheckboxProps {
  label?: ReactNode;
  labelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
}
