import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { C_Language } from '../../graphql/generatedModel';
import { storageHelper } from '../helpers/storage';

interface ILanguage {
  value: C_Language;
  locale: 'en' | 'de';
}
const languageList: Array<ILanguage> = [
  {
    value: C_Language.L1_ENGLISH,
    locale: 'en',
  },
  {
    value: C_Language.L2_GERMAN,
    locale: 'de',
  },
];

const useLanguage = () => {
  const { i18n } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState<C_Language>(
    storageHelper.local.getItem('sys_data.language') || C_Language.L2_GERMAN,
  );

  const changeLanguageHandler = (lang: C_Language) => {
    const locale = languageList.find((l) => l.value === lang)?.locale;
    i18n.changeLanguage(locale);
    setCurrentLanguage(lang);
    storageHelper.local.setItem('sys_data.language', lang);
  };
  return { currentLanguage, changeLanguageHandler };
};

export default useLanguage;
