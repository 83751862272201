import { Box, Paper } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import { ITabs } from '../../content.popup';
import { BreakOff, IBreakOffItem } from './tabs/breakOff.popup';
import { WeekDays } from './tabs/weekDays.popup';
import { IPeriodForTable, Period } from './tabs/period.popup';
import { PeriodConditions } from '../../../../../../../services/article.service';
import { PeriodConditionsBox } from '../../../../../../../../../shared/components/periodConditionsBox/periodConditionsBox.component';
import { useTranslation } from 'react-i18next';
import { AddOrEditPeriod } from '../../../addOrEditPeriod/addOrEditPeriod.popup';
import { AddOrEditBreakOff } from '../../../addOrEditBreakOff/addOrEditBreakOff.popup';

export const RightSide: FC<IRightSide> = ({ currentTab, data, setData, isSmall }) => {
  const [t] = useTranslation();
  const addOrEditPeriodPopup = useMemo(() => new AddOrEditPeriod(), []);
  const addOrEditBreakOffPopup = useMemo(() => new AddOrEditBreakOff(), []);
  const startAddOrEditPeriod = (period: IPeriodForTable, periodsData: IPeriodForTable[]) => {
    addOrEditPeriodPopup.stream.emit('open', {
      values: {
        period,
        periodsData,
      },
    });
  };
  const startAddOrEditPopup = (breakOff: IBreakOffItem, breakOffsData: IBreakOffItem[]) => {
    addOrEditBreakOffPopup.stream.emit('open', {
      values: {
        breakOff,
        breakOffsData,
      },
    });
  };
  const tab = useMemo(() => {
    switch (currentTab) {
      case ITabs.weekDays:
        return <WeekDays data={data?.weekDays} setData={setData} />;
      case ITabs.period:
        return (
          <Period
            periods={data?.periods}
            periodsWithCustomWeekDays={data?.periodsWithCustomWeekDays}
            setData={setData}
            startAddOrEditPeriod={startAddOrEditPeriod}
          />
        );
      default:
        return (
          <BreakOff
            data={data?.vacations}
            setData={setData}
            startAddOrEditBreakOff={startAddOrEditPopup}
          />
        );
    }
  }, [currentTab, data, setData]);

  useEffect(() => {
    addOrEditPeriodPopup.stream.state().subscribe((v) => {
      if (v?.action === 'save') {
        const periods = [] as PeriodConditions['periods'];
        const periodsWithCustomWeekDays = [] as PeriodConditions['periodsWithCustomWeekDays'];
        v?.values?.periodsData?.forEach(
          ({ id, description, fromDate, toDate, isCustom, isRepeatYearly, weekDays }) => {
            if (isCustom && weekDays) {
              periodsWithCustomWeekDays.push({
                id,
                period: {
                  fromDate,
                  toDate,
                  isRepeatYearly,
                },
                weekDays,
                description,
              });
            } else {
              periods.push({
                id,
                description,
                fromDate,
                toDate,
                isRepeatYearly,
              });
            }
          },
        );
        setData((prev) => ({
          ...prev,
          periods,
          periodsWithCustomWeekDays,
        }));
      }
    });

    addOrEditBreakOffPopup.stream.state().subscribe((v) => {
      if (v?.action === 'save') {
        setData((prev) => ({
          ...prev,
          vacations: v?.values?.breakOffsData as PeriodConditions['vacations'],
        }));
      }
    });

    return () => {
      addOrEditPeriodPopup.stream.unsubscribe();
      addOrEditBreakOffPopup.stream.unsubscribe();
    };
  }, []);

  return (
    <Paper
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        borderRadius: 0,
      }}
    >
      <Box sx={{ p: 3, flexGrow: 1 }}>
        {tab}
        <PeriodConditionsBox
          periodConditions={data}
          rows={isSmall ? 11.5 : 3}
          label={t('common.preview')}
        />
      </Box>
      <addOrEditPeriodPopup.Component />
      <addOrEditBreakOffPopup.Component />
    </Paper>
  );
};

interface IRightSide {
  currentTab: ITabs;
  data: PeriodConditions;
  setData: Dispatch<SetStateAction<PeriodConditions>>;
  isSmall: boolean;
}
