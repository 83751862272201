import React, { FC, useEffect, useMemo, useState } from 'react';
import { Badge, BadgeProps, Box, Button, Fade, Popover } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import styled from '@mui/material/styles/styled';
import { useTranslation } from 'react-i18next';
import { ISideFilter } from './sideFilter.component.tsx';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import equal from 'fast-deep-equal/react';

export const FilterContent: FC<IFilterContent> = ({
  filterBtnProps = {},
  confirmBtnProps = {},
  minWidthMenu = 350,
  maxMediaHeight,
  initValues,
  defaultFilterSettings = {},
  onFilter,
  onClose,
  onSubmit,
  clearEmpty,
  ignoreDefaultFields,
  children,
  navigationLockFn = (handlers: () => void) => handlers(),
}) => {
  const { t } = useTranslation();
  const { label: filterLabel, icon: filterIcon } = filterBtnProps;
  const { label: confirmLabel } = confirmBtnProps;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { getValues, reset } = useFormContext();

  const isEqual = (firstObj: Record<string, any>, secondObj: Record<string, any>) => {
    if (Object.keys(firstObj).length !== Object.keys(secondObj).length) return false;
    for (const key in firstObj) {
      if (
        !secondObj.hasOwnProperty(key) ||
        !equal(
          Array.isArray(firstObj[key]) ? firstObj[key].sort() : firstObj[key],
          Array.isArray(secondObj[key]) ? secondObj[key].sort() : secondObj[key],
        )
      ) {
        return false;
      }
    }
    return true;
  };

  const deleteNullAndUndefindFromObj = (obj: IAnyObject) => {
    const objectWithoutEmptyValues: IAnyObject = {};
    for (const key in obj) {
      const value = obj[key];
      if (value !== null && value !== undefined) {
        objectWithoutEmptyValues[key] = value;
      }
    }
    return objectWithoutEmptyValues;
  };

  const visibleBadge = useMemo(() => {
    const defaultFilter = deleteNullAndUndefindFromObj(defaultFilterSettings);
    const filterData = getValues();
    const currentFilter = deleteNullAndUndefindFromObj(filterData);
    if (ignoreDefaultFields) {
      ignoreDefaultFields.forEach((el) => {
        delete defaultFilter[el];
        delete currentFilter[el];
      });
    }
    return isEqual(defaultFilter, currentFilter);
  }, [open]);

  useEffect(() => {
    if (open) {
      const updatedObj = Object.fromEntries(
        Object.keys(structuredClone(getValues())).map((key) => [key, null]),
      );
      reset({ ...updatedObj, ...initValues });
    }
  }, [open, initValues /*, defaultFilterSettings*/]);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const currentTarget = event.currentTarget;
    navigationLockFn(() => {
      setAnchorEl(currentTarget);
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSubmit = (val: Record<string, any>) => {
    if (!equal(val, initValues)) {
      if (clearEmpty) {
        val = Object.entries(val).reduce((acc: Record<string, any>, [key, value]) => {
          if (value !== null && value !== undefined) {
            acc[key] = value;
          }
          return acc;
        }, {});
      }
      onFilter(val);
    }
    handleClose();
  };

  const SBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 4,
      top: 4,
      outline: `1.5px solid ${theme.palette.background.paper}`,
      zIndex: 0,
    },
  }));
  return (
    <>
      <Button
        sx={{ minHeight: '42px', pt: '5px', pb: '5px', flexShrink: 0 }}
        onClick={handleClick}
        variant='outlined'
        size='large'
        color='secondary'
        className='filterButton'
        startIcon={
          <SBadge variant='dot' invisible={visibleBadge} color='primary'>
            {filterIcon || <FilterAltIcon sx={{ fill: 'rgba(0, 0, 0, 0.54)' }} />}
          </SBadge>
        }
        {...filterBtnProps}
      >
        {filterLabel || t('common.filter')}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={open}
        keepMounted
        onClose={() => {
          handleClose();
          const timer = setTimeout(() => {
            reset();
            clearTimeout(timer);
            onClose && onClose();
          }, 300);
        }}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          marginTop: 1,
        }}
      >
        <Box
          component='form'
          onSubmit={onSubmit(handleSubmit)}
          minWidth={`${minWidthMenu}px`}
          sx={{
            maxHeight: '100%',
            ...(maxMediaHeight && {
              [`@media (max-height:${maxMediaHeight}px)`]: {
                maxHeight: 600, // Could be changed and set dynamically if it's will be needed in future
              },
            }),
          }}
        >
          <ScrollableContainer
            sx={{
              ...(maxMediaHeight && {
                [`@media (max-height:${maxMediaHeight}px)`]: {
                  maxHeight: 500,
                },
              }),
            }}
          >
            <SContentBox>{children}</SContentBox>
          </ScrollableContainer>
          <SButtonWrapper>
            <Button
              type='submit'
              size='large'
              color='primary'
              variant='contained'
              sx={{ maxHeight: '40px', width: '100%', marginTop: '41px' }}
              {...confirmBtnProps}
            >
              {confirmLabel || t('common.confirm')}
            </Button>
          </SButtonWrapper>
        </Box>
      </Popover>
    </>
  );
};

const ScrollableContainer = styled(Box)(() => ({
  maxHeight: '100%',
  overflowY: 'auto',
}));

const SButtonWrapper = styled('div')(() => ({
  padding: '0 16px 16px',
}));

const SContentBox = styled(Box)(() => ({
  padding: '16px 16px 0',
  '& > :last-child': {
    marginBottom: '0!important',
  },
}));

interface IAnyObject {
  [key: string]: any;
}
interface IFilterContent extends Omit<ISideFilter<any>, 'defaultValues'> {
  onSubmit: UseFormReturn['handleSubmit'];
}
