import { Box, Button } from '@mui/material';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { take } from 'rxjs';

import { storageHelper } from '../../../../../shared/helpers/storage/index.ts';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler.ts';
import { modeService } from '../../../../../shared/services/mode/mode.service.ts';
import {
  ArticleGeneralTabDataRes,
  articleService,
  CreateOrCopyArticleRes,
} from '../../../services/article.service.ts';
import { articleListState } from '../../../states/articleList.state.ts';
import { GeneralForm } from './components/generalForm.component.tsx';
import { articleTabLoadingService } from '../articleTababLoading.service.ts';
import { IGeneralTabProps } from './general.tab.tsx';

export const GeneralCreateTab: FC<Pick<IGeneralTabProps, 'mode' | 'loading'>> = ({
  mode,
  loading,
}) => {
  const { t } = useTranslation();
  const { getValues, handleSubmit, trigger, setValue, reset } = useFormContext();

  const onSubmitForm = () => {
    articleTabLoadingService.pub.loading(true);
    articleService.pub.createArticle(getValues() as ArticleGeneralTabDataRes);
  };

  const onCancelCreateMode = () => {
    articleListState.pub.selectArticle(storageHelper.session.getItem('article.selectedArticle'));
    // keep this reset() for server validation handler
    // this keepDirty blocks pub.save() in handleSubmitValue from being called again after copying or creating a new entry
    reset(undefined, { keepDirty: false });
    modeService.pub.mode('edit');
  };

  const validationValueHandle = (v: Record<string, any>) => {
    setValue('articleNo', v['articleNo']);
    trigger('articleNo');
  };

  useEffect(() => {
    articleListState.pub.unselectArticle();

    const createArticleSub = articleService.sub
      .createArticle()
      .pipe(
        take(1),
        responseHandler<CreateOrCopyArticleRes | undefined>({
          success: () => 'article.article_saved',
          customErrorHandler: () => {
            articleTabLoadingService.pub.loading(false);
            return 'article.article_not_saved';
          },
        }),
      )
      .subscribe(() => articleTabLoadingService.pub.loading(false));

    return () => {
      createArticleSub.unsubscribe();
    };
  }, []);

  return (
    <>
      <GeneralForm mode={mode} handleSubmitValue={validationValueHandle} loading={loading} />
      <Box mt={2} display='flex' gap={2}>
        <Button data-testid='cancel' variant='outlined' onClick={onCancelCreateMode}>
          {t('common.cancel')}
        </Button>
        <Button variant='contained' type='submit' onClick={handleSubmit(onSubmitForm)}>
          {t('article.create_article')}
        </Button>
      </Box>
    </>
  );
};
