import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PeriodConditions,
  WeekDays,
  WeekDaysFlags,
} from '../../../pages/article/services/article.service';
import { TextField } from '../form';
import { localeFormatterHelper } from '../../helpers/formatter/localeFormatter.helper';
import { PeriodConditions as PeriodConditionsCustomer } from '../../../pages/customer/services/customer.service';

export const PeriodConditionsBox: FC<IPeriodConditionsBox> = ({
  periodConditions,
  rows = 4,
  label,
  width = '100%',
}) => {
  const [t] = useTranslation();
  const formatDate = (value: string) => localeFormatterHelper.formatDate(value);

  const periodConditionsToString = (
    availabilitySettings: PeriodConditions | PeriodConditionsCustomer,
  ): string => {
    const { weekDays, periods, periodsWithCustomWeekDays, vacations } = availabilitySettings || {};

    let result = t('common.rules_is_not_set');

    const parseWeekDaysLngText = (weekDays: WeekDaysFlags): string => {
      const dayTitles: DayTitles = {
        isDeliveredMon: t('common.monday'),
        isDeliveredTue: t('common.tuesday'),
        isDeliveredWed: t('common.wednesday'),
        isDeliveredThu: t('common.thursday'),
        isDeliveredFri: t('common.friday'),
        isDeliveredSat: t('common.saturday'),
        isDeliveredSun: t('common.sunday'),
      };
      let result = '';

      for (const day in weekDays) {
        if (weekDays[day as keyof WeekDaysFlags]) {
          if (result !== '') {
            result += ', ';
          }
          result += dayTitles[day as keyof DayTitles];
        }
      }

      return result;
    };

    const parsePeriodsLngText = (periods: Period[], customWeekDays?: WeekDaysFlags[]): string => {
      const text = periods.reduce((text, item, index) => {
        const { fromDate, toDate, isRepeatYearly } = item || {};
        text += `${text ? ',\n' : ''}`;
        const isCustomWeekDays = customWeekDays && customWeekDays[index];
        if (isCustomWeekDays) {
          const weekDays = t('common.regional_datetime_entered_day_of_week', {
            days: parseWeekDaysLngText(customWeekDays[index]),
          });
          text += `${weekDays} ${t('common.in_period')} `;
        }
        const isRepeat = isRepeatYearly ? ` (${t('common.repeat_yearly')})` : '';
        text += `[${fromDate ? formatDate(fromDate) : '...'} - ${
          toDate ? formatDate(toDate) : '...'
        }${isRepeat}]`;
        return text;
      }, '');
      return text;
    };

    const checkIsAvailableForAtLeastOneDay = (weekDays: WeekDaysFlags): boolean => {
      let availableForAtLeastOneDay = false;
      for (const day in weekDays) {
        if (weekDays[day as keyof WeekDaysFlags]) {
          availableForAtLeastOneDay = true;
          break;
        }
      }
      return availableForAtLeastOneDay;
    };

    const parseRegularPart = (
      weekDays: WeekDaysFlags,
      periods: PeriodConditions['periods'] | PeriodConditionsCustomer['periods'],
    ): string => {
      const availableForAtLeastOneDay = checkIsAvailableForAtLeastOneDay(weekDays);
      let result = t('common.rules_is_not_set');

      if (availableForAtLeastOneDay) {
        result = t('common.regional_datetime_entered_day_of_week', {
          days: parseWeekDaysLngText(weekDays),
        });

        if (periods.length > 0) {
          result += ` ${t('common.in_periods', {
            periods: parsePeriodsLngText(periods as Period[]),
          })}`;
        }
      }

      return result;
    };

    const regularPart = parseRegularPart(weekDays, periods || []);

    let customPart = '';
    if (periodsWithCustomWeekDays) {
      customPart = parsePeriodsLngText(
        periodsWithCustomWeekDays.map((item) => item.period),
        periodsWithCustomWeekDays.map((item) => item.weekDays),
      );
    }

    let vacationsPart = vacations ? parsePeriodsLngText(vacations as Period[]) : '';

    if (vacationsPart.length > 0) {
      vacationsPart = `\n${t('common.vacation')} ${vacationsPart}`;
    }

    const noDayAvailable = !checkIsAvailableForAtLeastOneDay(weekDays);
    const noData = noDayAvailable && regularPart.length === 0;

    if (customPart.length === 0) {
      if (noData) {
        result = t('common.not_delivered');
      } else {
        result = `${regularPart}${vacationsPart}`;
      }
    } else if (regularPart === t('common.rules_is_not_set')) {
      result = `${customPart}${vacationsPart}`;
    } else {
      result = `${regularPart}\n${customPart}${vacationsPart}`;
    }

    return result;
  };

  return (
    <TextField
      label={label || t('customer.overview')}
      multiline
      rows={rows}
      disabled
      value={periodConditionsToString(periodConditions)}
      width={width}
    />
  );
};

export type Period = {
  fromDate: string | null | any;
  toDate: string | null | any;
  isRepeatYearly?: boolean;
  id?: string;
  description?: string;
};

type DayTitles = {
  [key in WeekDays]: string;
};

interface IPeriodConditionsBox {
  periodConditions: PeriodConditions | PeriodConditionsCustomer;
  rows?: number;
  label?: string;
  width?: string;
}
