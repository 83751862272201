import {
  Popup,
  TPopupComponent,
} from '../../../../../../../shared/components/popup/popup.abstract.tsx';
import { TPopupDefaultActions } from '../../../../../../../shared/components/popup/services/popup.service.ts';
import { FC, useEffect, useMemo, useState } from 'react';
import { ContentPopup } from './content.popup.tsx';
import { map } from 'rxjs';
import { useTranslation } from 'react-i18next';

export class BankSettingsPopup extends Popup<ITextPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;
    this.Component = () => {
      const Wrapper = useMemo(() => this.Wrapper, []);
      const { t } = useTranslation();
      const [popupTitle, setPopupTitle] = useState('');
      useEffect(() => {
        stream
          .actionListener('open')
          .pipe(map(({ title }) => `${t('settings.section_bank')} | ${title}`))
          .subscribe(setPopupTitle);
      }, []);

      return (
        <Wrapper
          stream={stream}
          PaperProps={{ sx: { maxWidth: 900, width: '100%' } }}
          fullWidth
          {...{ popupTitle }}
        >
          <ContentPopup {...{ stream }} />
        </Wrapper>
      );
    };
  }
}

export interface ITextPopup {
  action: TPopupDefaultActions;
  id: string;
  title: string;
}
