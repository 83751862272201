import { LoaderFunction, Params } from 'react-router-dom';
import { ArticleParams } from '../../../shared/components/globalSearch/globalSearchParams.util.ts';
import { articleTabLoadingService } from '../components/tabs/articleTababLoading.service.ts';
import { articleListState, initArticleListState } from '../states/articleList.state.ts';
import { ArticleTabs, ITabState, tabStateModel } from '../states/tabState.model.ts';
import { IArticleLoader, resolveArticleListData } from './articleList.resolver.ts';
import { resolveDataForAvailabilityTab } from './availabilityTab.resolver.ts';
import { resolveArticleCashAssistData } from './cashAssistTab.resolver.ts';
import { resolveDataForGeneralTab, resolveGeneralTabOptions } from './generalTab.resolver.tsx';
import { resolveDataForLableTab } from './labelTab.resolver.ts';
import { resolveDataForListsTab } from './listsTab.resolver.ts';
import { resolveDataForPostTab } from './postTab.resolver.ts';
import { resolveDataForProductionTab } from './productionTab.resolver.ts';

function checkTab404(selectedTab: ArticleTabs) {
  if (!Object.values(ArticleTabs).includes(selectedTab)) {
    location.href = '/404';
  }
}

export const articleListLoader: LoaderFunction = async ({
  params,
}: {
  params: Params<keyof ArticleParams>;
}) => {
  let initListData = { ...initArticleListState };
  initListData.action = 'loader';
  initListData = await resolveArticleListData(initListData, params);
  return { initListData } as IArticleLoader;
};

export const articleTabLoader: LoaderFunction = async ({ params, context }) => {
  const tab = params.tab as ArticleTabs;
  const globalSearchParams = params as ArticleParams;
  checkTab404(tab);
  let tabState = { ...tabStateModel };

  let selectedArticle: IArticleLoader['initListData']['selectedArticle'] | undefined;
  if (context?.parentInitData) {
    selectedArticle = (context.parentInitData as IArticleLoader)?.initListData?.selectedArticle;
  } else selectedArticle = articleListState.sub.shareDataForTabLoader().selectedArticle;

  tabState.articleId = selectedArticle?.id as string;
  if (typeof globalSearchParams.entityId === 'string') {
    tabState.articleId = globalSearchParams.entityId;
  }
  tabState.isDough = !!selectedArticle?.isDough;

  if (tab === ArticleTabs.general) {
    await Promise.all([resolveDataForGeneralTab(tabState), resolveGeneralTabOptions(tabState)]);
  }

  if (tab === ArticleTabs.lists) {
    tabState = await resolveDataForListsTab(tabState);
  }

  if (tab === ArticleTabs.production) {
    tabState = await resolveDataForProductionTab(tabState);
  }

  if (tab === ArticleTabs.availability) {
    tabState = await resolveDataForAvailabilityTab(tabState);
  }

  if (tab === ArticleTabs.cashAssist) {
    tabState = await resolveArticleCashAssistData(tabState);
  }

  if (tab === ArticleTabs.post) {
    tabState = await resolveDataForPostTab(tabState);
  }

  if (tab === ArticleTabs.label) {
    tabState = await resolveDataForLableTab(tabState);
  }

  articleTabLoadingService.pub.loading(false);
  return tabState as ITabState;
};
