import { ReactNode, MouseEvent, FC } from 'react';
import { Box, styled } from '@mui/material';

export const IconWrapper: FC<IconWrapperProps> = ({ children, onClick, width, svgsize }) => (
  <SWrapper onClick={onClick} width={width} svgsize={svgsize}>
    {children}
  </SWrapper>
);

const SWrapper = styled(Box)<Pick<IconWrapperProps, 'width' | 'svgsize'>>(({ width, svgsize }) => ({
  position: 'relative',
  background: 'transparent',
  border: 'none',
  height: '100%',
  width: width || '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textTransform: 'uppercase',
  fontWeight: 500,
  textAlign: 'center',
  color: 'white',

  '& svg': {
    transition: 'all 0.3s ease',
    width: svgsize || '24px',
    height: svgsize || '24px',
    cursor: 'pointer',
  },
}));

interface IconWrapperProps {
  children: ReactNode;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  width?: string | undefined;
  svgsize?: string | undefined;
}
