import { useSnackbar } from 'notistack';
import { FC, useEffect } from 'react';
import { snackbarService } from './service/snackbar.service';

export const SnackBarService: FC = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    const snackbarServiceShowSub = snackbarService.sub
      .show()
      .subscribe(({ params: { content, ...options } }) => {
        enqueueSnackbar(content, options);
      });
    const snackbarServiceHideSub = snackbarService.sub.hide().subscribe(({ params }) => {
      closeSnackbar(params);
    });
    return () => {
      snackbarServiceShowSub.unsubscribe();
      snackbarServiceHideSub.unsubscribe();
    };
  }, []);
  return null;
};
