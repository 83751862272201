import React, { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import {
  FieldsSection,
  FormCheckbox,
  FormNumericField,
  FormSelect,
} from '../../../../../../shared/components/form';
import { configurationTabState } from '../../../states/configuration.state.ts';
import { TCompanyConfigs } from '../../../../../../shared/services/companyConfig/companyConfig.service.ts';
import {
  C_Kg_Representation,
  C_Order_Positions_Sorting_Kind,
  C_Rounding_Method,
} from '../../../../../../graphql/generatedModel.ts';

export const OrderTab: FC<IOrderTab> = ({ params }) => {
  const { t } = useTranslation();
  const formMethods = useForm({ defaultValues: params, mode: 'onChange' });

  const {
    resetField,
    formState: { dirtyFields },
  } = formMethods;
  const priceRoundingOptions = useMemo(
    () => [
      { id: C_Rounding_Method.RM1_NOT_USED, label: t(`enums.${C_Rounding_Method.RM1_NOT_USED}`) },
      { id: C_Rounding_Method.RM2_TO_0_05, label: t(`enums.${C_Rounding_Method.RM2_TO_0_05}`) },
      { id: C_Rounding_Method.RM3_TO_0_01, label: t(`enums.${C_Rounding_Method.RM3_TO_0_01}`) },
      { id: C_Rounding_Method.RM4_TO_0_001, label: t(`enums.${C_Rounding_Method.RM4_TO_0_001}`) },
      { id: C_Rounding_Method.RM5_TO_0_1, label: t(`enums.${C_Rounding_Method.RM5_TO_0_1}`) },
    ],
    [t],
  );
  const orderPositionSortingOptions = useMemo(
    () => [
      {
        id: C_Order_Positions_Sorting_Kind.OPS1_ARTICLE_NO,
        label: t(`enums.${C_Order_Positions_Sorting_Kind.OPS1_ARTICLE_NO}`),
      },
      {
        id: C_Order_Positions_Sorting_Kind.OPS2_DESCRIPTION,
        label: t(`enums.${C_Order_Positions_Sorting_Kind.OPS2_DESCRIPTION}`),
      },
      {
        id: C_Order_Positions_Sorting_Kind.OPS3_ORIGINAL,
        label: t(`enums.${C_Order_Positions_Sorting_Kind.OPS3_ORIGINAL}`),
      },
    ],
    [t],
  );
  const kiloAmountPresentationOptions = useMemo(
    () => [
      { id: C_Kg_Representation.KGR1_1_IS_1, label: t(`enums.${C_Kg_Representation.KGR1_1_IS_1}`) },
      {
        id: C_Kg_Representation.KGR2_1000_IS_1,
        label: t(`enums.${C_Kg_Representation.KGR2_1000_IS_1}`),
      },
    ],
    [t],
  );
  const daysOfTheWeek = useMemo(
    () => [
      { label: t('common.mon'), value: 'isDeliveryOnMon' },
      { label: t('common.tue'), value: 'isDeliveryOnTue' },
      { label: t('common.wed'), value: 'isDeliveryOnWed' },
      { label: t('common.thu'), value: 'isDeliveryOnThu' },
      { label: t('common.fri'), value: 'isDeliveryOnFri' },
      { label: t('common.sat'), value: 'isDeliveryOnSat' },
      { label: t('common.sun'), value: 'isDeliveryOnSun' },
    ],
    [t],
  );

  useEffect(() => {
    const formStateSub = formMethods.watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        configurationTabState.pub.recordData({ [fieldName]: data[fieldName] });
      }
    });
    const configurationTabStateSub = configurationTabState.sub
      .state()
      .subscribe(({ dataToSave }) => {
        const name = Object.keys(dataToSave)[0] as keyof typeof dataToSave;
        resetField(name, {
          defaultValue: dataToSave[name],
          keepDirty: false,
        });
      });

    return () => {
      formStateSub.unsubscribe();
      configurationTabStateSub.unsubscribe();
    };
  }, []);

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    if (!!dirtyFields[e.target.name as keyof typeof dirtyFields]) {
      configurationTabState.pub.saveData();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form noValidate onBlur={onSubmit}>
        <Stack direction='column' spacing={1} pl={0.1} pt={1}>
          <Stack direction='row'>
            <Stack direction='column' spacing={3} minWidth='350px'>
              <FormNumericField
                name='actualOrderNo'
                precision={0}
                label={t('settings.actual_order_number')}
              />
              <FormSelect
                name='roundingArticlePricesMethodId'
                options={priceRoundingOptions}
                label={t('settings.rounding_total_prices')}
                disableClearable
              />
              <FormNumericField
                name='decimalPlacesCount'
                precision={0}
                noTrailingZeros
                min={2}
                max={3}
                controls
                label={t('settings.decimal_place_count')}
              />
              <FormSelect
                name='orderPositionsSortingKindId'
                options={orderPositionSortingOptions}
                label={t('settings.order_position_sorting_kind')}
                disableClearable
              />
              <FormSelect
                name='kiloAmountRepresentationId'
                options={kiloAmountPresentationOptions}
                label={t('settings.kilo_amount_representation')}
                disableClearable
              />
            </Stack>
            <Stack direction='column' spacing={2}>
              <FormCheckbox
                name='isUsePriceCategoryAhead'
                label={t('settings.is_use_price_category_ahead')}
              />
              <FormCheckbox
                name='isShippingDocsFunctionActivated'
                label={t('settings.is_shipping_docs_function_activated')}
              />
              <FormCheckbox
                name='isShippingDocsForCardboardBoxes'
                label={t('settings.is_shipping_docs_for_cardboard')}
              />
            </Stack>
          </Stack>
          <FieldsSection nomt title={t('settings.delivery_days')}>
            <Stack direction='row' ml={'-27px'}>
              {daysOfTheWeek.map(({ label, value }) => (
                <FormCheckbox
                  key={value}
                  name={value}
                  label={label}
                  labelProps={{ labelPlacement: 'top', sx: { marginRight: '0' } }}
                />
              ))}
            </Stack>
            <FormCheckbox
              name='isGotoNextDeliveryDay'
              label={t('settings.is_got_next_delivery_day')}
            />
          </FieldsSection>
        </Stack>
      </form>
    </FormProvider>
  );
};

interface IOrderTab {
  params: TCompanyConfigs;
}
