import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button, ButtonProps, IconButton, styled } from '@mui/material';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';

export const NoteButton: FC<INoteButtonProps> = ({
  badged,
  view = 'large',
  buttonIcon,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const badgeComp = useMemo(
    () => badged && <SBadge variant='dot' color='primary' {...{ view }} />,
    [badged, view],
  );
  const ButtonIcon = useMemo(() => buttonIcon || StickyNote2Icon, []);

  const iconStyle = useMemo(
    () => ({
      fill: props.disabled ? 'rgba(0, 0, 0, 0.26)' : 'rgba(0, 0, 0, 0.54)',
    }),
    [props.disabled],
  );

  return view === 'small' ? (
    <IconButton {...props}>
      <ButtonIcon sx={iconStyle} />
      {badgeComp}
    </IconButton>
  ) : (
    <Button
      variant='outlined'
      size='large'
      color='secondary'
      startIcon={<ButtonIcon sx={iconStyle} />}
      {...props}
    >
      {children || t('common.note')}
      {badgeComp}
    </Button>
  );
};

const SBadge = styled(Badge)<{ view: INoteButtonProps['view'] }>(({ view }) => ({
  position: 'absolute',
  top: view === 'small' ? 7 : 12,
  left: view === 'small' ? 33 : 35,
}));

interface INoteButtonProps extends ButtonProps {
  badged?: boolean;
  view?: 'small' | 'large';
  buttonIcon?: typeof SvgIcon;
}
