import React, { FC, useMemo } from 'react';
import { FieldsRow, FormCheckbox, FormSelect } from '../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { ISelectOption } from '../../../../../../shared/components/form/fields/select.component.tsx';
import { C_Customer_Def_Order_Source } from '../../../../../../graphql/generatedModel.ts';
import { defaultOrderState, IFormData } from '../defaultOrder.state.ts';
import { DictCustomersRes } from '../../../../../../shared/services/configsData/configsData.service.ts';
import { ITabState } from '../../../../states/tabState.model.ts';

export const DefaultOrderForm: FC<IDefaultOrderForm> = ({
  formMethods,
  customerList,
  customerId,
}) => {
  const { t } = useTranslation();
  const {
    formState: { dirtyFields },
    getValues,
  } = formMethods;
  const presetOptions: ISelectOption[] = useMemo(
    () => [
      {
        id: C_Customer_Def_Order_Source.DO1_DEFAULT_ORDER,
        label: t(`enums.${C_Customer_Def_Order_Source.DO1_DEFAULT_ORDER}`),
      },
      {
        id: C_Customer_Def_Order_Source.DO2_WEEK_AGO_ORDER,
        label: t(`enums.${C_Customer_Def_Order_Source.DO2_WEEK_AGO_ORDER}`),
      },
      {
        id: C_Customer_Def_Order_Source.DO3_LAST_ORDER,
        label: t(`enums.${C_Customer_Def_Order_Source.DO3_LAST_ORDER}`),
      },
      {
        id: C_Customer_Def_Order_Source.DO4_EMPTY_ORDER,
        label: t(`enums.${C_Customer_Def_Order_Source.DO4_EMPTY_ORDER}`),
      },
    ],
    [t, C_Customer_Def_Order_Source],
  );

  const customerOptions = useMemo(() => {
    return customerList.reduce((acc: ExtendedSelectOption[], item) => {
      if (item.id !== customerId) {
        acc.push({
          label: item.internalOrFullName || '',
          id: item.id,
          customerNo: item.customerNo || '',
        });
      }
      return acc;
    }, []);
  }, [customerId]);

  const filterOptions = (
    options: ExtendedSelectOption[],
    { inputValue }: { inputValue: string },
  ): ExtendedSelectOption[] =>
    options.filter(
      (option) =>
        (option.customerNo?.toLowerCase().includes(inputValue?.toLowerCase()) ||
          option.label?.toLowerCase().includes(inputValue?.toLowerCase())) as boolean,
    );

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    const fieldName = e.target.name as keyof IFormData;
    if (dirtyFields.hasOwnProperty(fieldName)) {
      const values = getValues();
      defaultOrderState.pub.formSave({ [fieldName]: values[fieldName] });
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form noValidate onBlur={onSubmit}>
        <FieldsRow>
          <FormSelect
            options={presetOptions}
            label={t('customer.preset')}
            name='defaultOrderSourceId'
            disableTextInput
            disableClearable
          />
          <FormSelect
            options={customerOptions}
            label={t('customer.useDefOrderFromCustomer')}
            name='defaultOrderOverrideFromCustomerId'
            datasetattribute='customerOptions'
            filterOptions={filterOptions}
          />
          <FormCheckbox
            label={t('customer.createAutomaticallyFromPresetting')}
            name='isAllowedAutoOrder'
          />
        </FieldsRow>
      </form>
    </FormProvider>
  );
};

interface IDefaultOrderForm {
  formMethods: UseFormReturn<IFormData>;
  customerList: DictCustomersRes;
  customerId: ITabState['customerId'] | null;
}

interface ExtendedSelectOption extends ISelectOption {
  customerNo?: string;
}
