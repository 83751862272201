import { FC, useEffect, useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Checkbox } from '../../../../../../../../../shared/components/form';
import { Stack, styled } from '@mui/material';
import { defaultOrderState } from '../../../../defaultOrder.state.ts';
import { clearRowFromVirtualKeys } from './additionalFunctions.ts';

export const ChangeInOrderCell: FC<IChangeInOrderCell> = ({ params, disabled }) => {
  const { value, row, field } = params;
  const [checked, setChecked] = useState(!value);

  useEffect(() => {
    setChecked(!value);
  }, [value]);

  const updateCell = (v: boolean) => {
    defaultOrderState.pub.updateCell(
      { ...clearRowFromVirtualKeys(row), [field]: !v },
      'simpleCell',
    );
    setChecked(!v);
  };

  return (
    <SStack>
      <Checkbox
        tabIndex={params.tabIndex}
        onKeyDown={(e) => {
          if (e.code === 'Enter') updateCell(!checked);
        }}
        onChange={(_, v) => updateCell(v)}
        size='small'
        {...{ checked, disabled }}
      />
    </SStack>
  );
};

const SStack = styled(Stack)({
  '& label': {
    margin: 0,
  },
});

interface IChangeInOrderCell {
  params: GridRenderCellParams;
  disabled: boolean;
}
