import { FC, useCallback, useEffect, useState } from 'react';
import { IAddOrEditBreakOff } from './addOrEditBreakOff.popup';
import { PopupService } from '../../../../../../../shared/components/popup/services/popup.service';
import { useTranslation } from 'react-i18next';
import { IBreakOffItem } from '../deliveryPeriods/components/rightSide/tabs/breakOff.popup';
import {
  isBothDatesFromSamePeriod,
  isDateOutOfPeriods,
  transformDateToString,
} from '../deliveryPeriods/common.popup';
import { resetTimeInDate } from '../../../../../../../shared/helpers/utils/utils.helper';
import { EPeriodTypeForModal } from '../deliveryPeriods/components/rightSide/tabs/period.popup';
import { EDateType } from '../addOrEditPeriod/content.popup';
import { Button, Stack } from '@mui/material';
import { DatePicker } from '../../../../../../../shared/components/datePicker/datePicker.component';
import { TextField } from '../../../../../../../shared/components/form';
import { useBeforeClosePopup } from '../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import equal from 'fast-deep-equal/react';

export const Content: FC<IContent> = ({ stream }) => {
  const [t] = useTranslation();
  const [data, setData] = useState<IBreakOffItem>();
  const [initData, setInitData] = useState<IBreakOffItem>();
  const [breakOffsData, setBreakOffsData] = useState<IBreakOffItem[]>([]);
  const {
    id,
    description = '',
    fromDate = transformDateToString(new Date()),
    toDate = transformDateToString(new Date()),
    typeForModal,
  } = data || {};

  useEffect(() => {
    const openSub = stream.actionListener('open').subscribe((data) => {
      setData(data?.values?.breakOff);
      setInitData(structuredClone(data?.values?.breakOff));
      setBreakOffsData(data?.values?.breakOffsData);
    });
    return () => {
      openSub.unsubscribe();
    };
  }, []);

  const onFromDateChange = (date: Date | null) => {
    if (date) {
      date = resetTimeInDate(date);
      let isFromSame = true;
      if (typeForModal === EPeriodTypeForModal.editOld && data) {
        isFromSame = isBothDatesFromSamePeriod({
          fromDate: date,
          toDate: new Date(toDate as string),
          periodsData: breakOffsData,
          editPeriod: data,
        });
      } else {
        isFromSame = isBothDatesFromSamePeriod({
          fromDate: date,
          toDate: new Date(toDate as string),
          periodsData: breakOffsData,
        });
      }
      if (isFromSame) {
        changeDate(date);
      } else {
        changeDate(date, EDateType.fromDate, date);
      }
    }
  };

  const onToDateChange = (date: Date | null) => {
    if (date) {
      date = resetTimeInDate(date);
      let isFromSame = true;
      if (typeForModal === EPeriodTypeForModal.editOld && data) {
        isFromSame = isBothDatesFromSamePeriod({
          fromDate: new Date(fromDate as string),
          toDate: date,
          periodsData: breakOffsData,
          editPeriod: data,
        });
      } else {
        isFromSame = isBothDatesFromSamePeriod({
          fromDate: new Date(fromDate as string),
          toDate: date,
          periodsData: breakOffsData,
        });
      }
      if (isFromSame) {
        changeDate(date, EDateType.toDate);
      } else {
        changeDate(date, EDateType.toDate, date);
      }
    }
  };

  const changeDate = (date: Date, type: EDateType = EDateType.fromDate, date2?: Date) => {
    const newDate = transformDateToString(date);
    if (date2) {
      const type2 = type === EDateType.fromDate ? EDateType.toDate : EDateType.fromDate;
      const newDate2 = transformDateToString(date2);
      setData((prev: IBreakOffItem | any) => ({
        ...prev,
        [type]: newDate,
        [type2]: newDate2,
      }));
    } else {
      setData((prev: IBreakOffItem | any) => ({
        ...prev,
        [type]: newDate,
      }));
    }
  };

  const onDescriptionChange = (description: string) => {
    setData((prev: IBreakOffItem | any) => ({
      ...prev,
      description,
    }));
  };

  const shouldDisableDate = useCallback(
    (date: Date) => {
      if (data?.typeForModal === EPeriodTypeForModal.editOld) {
        return !isDateOutOfPeriods(date, breakOffsData, data);
      }
      return !isDateOutOfPeriods(date, breakOffsData);
    },
    [breakOffsData, data],
  );

  const saveData = () => {
    if (data) {
      const { typeForModal: _, ...dataForSave } = data;
      const prev = structuredClone(breakOffsData);
      const index = prev?.findIndex((period) => period.id === id);
      if (index !== -1) {
        prev[index] = dataForSave;
      } else {
        prev.push(dataForSave);
      }
      stream.emit('save', { values: { breakOffsData: prev } });
    }
    stream.emit('close');
  };

  useBeforeClosePopup({
    stream,
    isDirty: !equal(initData, data),
  });

  return (
    <Stack width='594px' p={3}>
      <Stack direction='row' justifyContent='space-between'>
        <DatePicker
          navigation={false}
          fieldProps={{ label: t('common.from_date') }}
          width={265}
          value={new Date(fromDate as string)}
          onChange={onFromDateChange}
          onChangeDependencies={[data, breakOffsData]}
          calendarProps={{ shouldDisableDate }}
        />
        <DatePicker
          navigation={false}
          fieldProps={{ label: t('common.to_date') }}
          width={265}
          value={new Date(toDate as string)}
          onChange={onToDateChange}
          onChangeDependencies={[data, breakOffsData]}
          calendarProps={{ shouldDisableDate }}
        />
      </Stack>
      <TextField
        value={description}
        onChange={(e) => onDescriptionChange(e?.target?.value)}
        label={t('common.note')}
        width='100%'
        sx={{ margin: '24px 0' }}
      />
      <Stack direction='row' justifyContent='flex-end' mt={6}>
        <Button onClick={saveData} variant='contained' sx={{ minWidth: '125px' }}>
          {t('common.save')}
        </Button>
      </Stack>
    </Stack>
  );
};

export interface IContent {
  stream: PopupService<IAddOrEditBreakOff>;
}
