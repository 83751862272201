import { ReactElement } from 'react';
import { LabelTabOptionsRes } from '../loaders/labelTab.resolver';
import {
  ArticleGeneralTabDataRes,
  ArticleProductionTabDataRes,
  CashAssistTabOptionsRes,
  GeneralTabOptionsRes,
  ProductionTabOptionsRes,
} from '../services/article.service';
import { FieldErrors } from 'react-hook-form';

export enum ArticleTabs {
  general = 'general',
  lists = 'lists',
  availability = 'availability',
  production = 'production',
  label = 'label',
  cashAssist = 'cashAssist',
  post = 'post',
}
export interface ITabState {
  articleId: string;
  isDough: boolean;
  defaultValues: any;
  articleSpecialPrices: ArticleGeneralTabDataRes['articleSpecialPrices'];
  articleProductionCalculation: ArticleProductionTabDataRes['articleProductionCalculation'];
  specialPriceCategoriesColumns: ReactElement[] | any;
  createSpecialPriceCategoriesInputs: (
    focusedField: string,
    errors: FieldErrors,
  ) => ReactElement | any;
  generalTabOptions: GeneralTabOptionsRes | object;
  generalPriceCategories: React.ReactElement[] | [];
  cashAssistTabOptions: CashAssistTabOptionsRes | object;
  productionTabOptions: ProductionTabOptionsRes | object;
  labelTabOptions: LabelTabOptionsRes | object;
}
export const tabStateModel: ITabState = {
  articleId: '',
  isDough: false,
  defaultValues: {},
  generalTabOptions: {},
  generalPriceCategories: [],
  cashAssistTabOptions: {},
  productionTabOptions: {},
  labelTabOptions: {},
  articleSpecialPrices: [],
  specialPriceCategoriesColumns: [],
  articleProductionCalculation: [],
  createSpecialPriceCategoriesInputs: () => null,
};
