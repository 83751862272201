import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ContentLoading } from '../../../../shared/components/loading/loading.component';
import { transitionService } from '../../../../shared/components/loading/service/transition.service';
import { TabPanel, Tabs } from '../../../../shared/components/tabs';
import { TMode, modeService } from '../../../../shared/services/mode/mode.service';
import { navigateService } from '../../../../shared/services/navigate/navigate.service';
import { CustomerTabs } from '../../states/tabState.model';
import { TabContentContainer } from '../../../../shared/components/tabs/tabsContainer.component.tsx';
import { FormsTab } from './forms/forms.tab.tsx';
import { customerTabLoadingService } from './customerTabLoading.service.ts';
import { Lists } from './lists/lists.tab.tsx';
import { Tour } from './tour/tour.tab.tsx';
import { Availability } from './availability/availability.tab.tsx';
import { CashAssist } from './cashAssist/cashAssist.tab.tsx';
import { DefaultOrder } from './defaultOrder/defaultOrder.tab.tsx';
import { ConditionsTab } from './conditions/conditions.tab.tsx';
import { companyConfigService } from '../../../../shared/services/companyConfig/companyConfig.service.ts';
import { GeneralModeContainer } from './general/generalModeContainer.tsx';

const CustomerDetails: FC = () => {
  const { tab } = useParams() as { tab: CustomerTabs };
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [mode, setMode] = useState<TMode>('edit');

  const disableTab = mode !== 'edit';
  const isCADBAvailable = companyConfigService.getCachedConfigs()?.isCADBAvailable;

  useEffect(() => {
    const unsubLoading = customerTabLoadingService.sub.loading().subscribe(setLoading);
    const unsubMode = modeService.sub.mode().subscribe(setMode);

    return () => {
      modeService.pub.mode('edit');

      unsubMode.unsubscribe();
      unsubLoading.unsubscribe();
    };
  }, []);

  const handleActiveTab: THandleActiveTab = (_, tab) => {
    if (!loading) {
      transitionService.pub.off();
      customerTabLoadingService.pub.loading(true);
      navigateService.pub.navigateTo(`/customer/${tab}`);
    }
  };

  return (
    <>
      <Tabs value={tab} onChange={handleActiveTab} scrollButtons={false} variant='scrollable'>
        <Tab label={t('customer.general')} value={CustomerTabs.general} />
        <Tab label={t('customer.lists')} value={CustomerTabs.lists} disabled={disableTab} />
        <Tab
          value={CustomerTabs.availability}
          label={t('customer.availability')}
          disabled={disableTab}
        />
        <Tab
          value={CustomerTabs.conditions}
          label={t('customer.conditions')}
          disabled={disableTab}
        />
        <Tab
          value={CustomerTabs.defaultOrder}
          label={t('customer.defaultOrder')}
          disabled={disableTab}
        />
        <Tab label={t('customer.print_forms')} value={CustomerTabs.forms} disabled={disableTab} />
        <Tab
          value={CustomerTabs.cashAssist}
          label={t('customer.cashAssist')}
          disabled={disableTab || !isCADBAvailable}
        />
        <Tab value={CustomerTabs.tour} label={t('customer.tour')} disabled={disableTab} />
      </Tabs>
      <TabContentContainer>
        <ContentLoading loading={loading}>
          {tab === CustomerTabs.general && <GeneralModeContainer mode={mode} tab={tab} />}

          {mode === 'edit' && (
            <>
              <TabPanel value={CustomerTabs.forms} activeValue={tab}>
                <FormsTab />
              </TabPanel>
              <TabPanel value={CustomerTabs.lists} activeValue={tab}>
                <Lists />
              </TabPanel>
              <TabPanel value={CustomerTabs.availability} activeValue={tab}>
                <Availability />
              </TabPanel>
              <TabPanel value={CustomerTabs.tour} activeValue={tab}>
                <Tour />
              </TabPanel>
              <TabPanel value={CustomerTabs.cashAssist} activeValue={tab}>
                <CashAssist />
              </TabPanel>
              <TabPanel value={CustomerTabs.defaultOrder} activeValue={tab}>
                <DefaultOrder />
              </TabPanel>
              <TabPanel value={CustomerTabs.conditions} activeValue={tab}>
                <ConditionsTab />
              </TabPanel>
            </>
          )}
        </ContentLoading>
      </TabContentContainer>
    </>
  );
};
export default CustomerDetails;

type THandleActiveTab = (evt: SyntheticEvent<Element, Event>, tab: CustomerTabs) => void;
