import { Popup, TPopupComponent } from '../../popup/popup.abstract.tsx';
import { TPopupDefaultActions } from '../../popup/services/popup.service.ts';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { PopupContent } from './popupContent.tsx';
import { EmailTrackingByTypeQueryVariables } from '../../../../graphql/generatedModel.ts';

export class EmailTrackingPopup extends Popup<IEmailTrackingPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;
    this.Component = () => {
      const { t } = useTranslation();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 1310 },
        }));
      }, []);
      const [params, setParams] = useState<IEmailTrackingPopup['params']>(null);
      useEffect(() => {
        stream.actionListener('open').subscribe(({ params }) => {
          if (params) setParams(params);
        });
      }, []);
      return (
        <SWrapper fullWidth stream={stream} popupTitle={t('common.history')}>
          <PopupContent {...{ params }} />
        </SWrapper>
      );
    };
  }
}

export interface IEmailTrackingPopup {
  action: TPopupDefaultActions;
  params: EmailTrackingByTypeQueryVariables | null;
}
