import { take } from 'rxjs';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler';
import { ITabState } from '../../overview/states/tabState.model';
import { OverviewOrdersRes, overviewService } from '../../services/overview.service';
import { getInitSortModel } from '../../overview/components/tabs/orders/ordersOverview.state';
import { dataHelper } from '../../../../shared/helpers/data/data.helper';

export async function resolveDataForDeliveryNoteOverviewTab(
  tabState: ITabState,
): Promise<ITabState> {
  const { datePeriod, deliveryNoteGroupId } = tabState;

  let list: OverviewOrdersRes = await overviewService.globHelpers.streamToPromise(
    overviewService.sub.getOverviewOrders().pipe(
      responseHandler<OverviewOrdersRes>({
        customErrorHandler: () => 'order.tab_not_loaded',
        errorReturnType: [],
      }),
      take(1),
    ),
    () => {
      overviewService.pub.getOverviewOrders({
        filter: {
          periodStart: datePeriod?.fromDate,
          periodEnd: datePeriod?.toDate,
          deliveryNoteGroupId,
        },
      });
    },
  );

  const initSortModel = getInitSortModel();

  if (list?.length) {
    list = dataHelper
      .data(list as [])
      .sort({ sortModel: initSortModel })
      .result() as OverviewOrdersRes;
  }

  return { ...tabState, defaultValues: list };
}
