import { IconButton, IconButtonProps, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FC } from 'react';

export const AddButton: FC<IAddButtonProps> = ({ onClick, ...props }) => {
  return (
    <CustomIconButton onClick={onClick} {...props}>
      <AddIcon sx={{ fill: 'rgba(0, 0, 0, 0.54)' }} />
    </CustomIconButton>
  );
};

export const CustomIconButton = styled(IconButton)(({ disabled }) => ({
  ...(disabled && {
    opacity: 0.5,
    cursor: 'not-allowed',
  }),
}));

interface IAddButtonProps extends IconButtonProps {
  onClick: () => void;
}
