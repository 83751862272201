import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { C_Sale_Unit } from '../../../../../../graphql/generatedModel';
import { ISelectProps } from '../../../../../../shared/components/form/fields/select.component';

export const getSaleUnitOptions = (t: TFunction) => {
  return useMemo<ISelectProps['options']>(
    () => [
      { id: C_Sale_Unit.SU1_PIECE, label: t(`enums.${C_Sale_Unit.SU1_PIECE}`) },
      {
        id: C_Sale_Unit.SU2_100G,
        label: t(`enums.${C_Sale_Unit.SU2_100G}`),
      },
      {
        id: C_Sale_Unit.SU3_1000G,
        label: t(`enums.${C_Sale_Unit.SU3_1000G}`),
      },
      {
        id: C_Sale_Unit.SU4_PIECE_AND_WEIGTH,
        label: t(`enums.${C_Sale_Unit.SU4_PIECE_AND_WEIGTH}`),
      },
      {
        id: C_Sale_Unit.SU5_OPEN_AMOUNT,
        label: t(`enums.${C_Sale_Unit.SU5_OPEN_AMOUNT}`),
      },
      {
        id: C_Sale_Unit.SU6_OPEN_AMOUNT_MINUS,
        label: t(`enums.${C_Sale_Unit.SU6_OPEN_AMOUNT_MINUS}`),
      },
    ],
    [C_Sale_Unit, t],
  );
};
