import { ITabState } from '../states/tabState.model.ts';
import { CustomerAvailabilityRes, customerService } from '../services/customer.service.ts';
import { responseHandler } from '../../../shared/responseHandler/responseHandler.ts';
import { take } from 'rxjs';
import { InitWeekDays } from '../components/tabs/availability/popups/deliveryPeriods/common.popup.ts';

export const defaultCustomerAvailabilityData: CustomerAvailabilityRes = {
  id: '',
  customerAssortimentGroupsCheckedState: [
    {
      id: '',
      checked: false,
      label: null,
    },
  ],
  periods: [],
  periodsWithCustomWeekDays: [],
  vacations: [],
  weekDays: InitWeekDays,
};

export async function resolveDataForAvailabilityTab(data: ITabState): Promise<ITabState> {
  data.defaultValues = data?.customerId
    ? await customerService.globHelpers.streamToPromise(
        customerService.sub.customerAvailabilityData().pipe(
          responseHandler<CustomerAvailabilityRes>({
            errorReturnType: defaultCustomerAvailabilityData,
          }),
          take(1),
        ),
        () => {
          customerService.pub.customerAvailabilityData({ id: data.customerId! });
        },
      )
    : {};

  return data;
}
