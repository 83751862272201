import { PutResult, S3ProviderListOutput } from '@aws-amplify/storage';
import { Storage, StorageClass } from 'aws-amplify';
import normalize from 'path-normalize';
import { Observable, combineLatestWith, from, map, switchMap } from 'rxjs';
import { authService } from '../../../services/auth/auth.service';
import { companyConfigService } from '../../../services/companyConfig/companyConfig.service';
import { localstorage } from '../local.keys';
import { memorystorage } from '../memory.keys';
import { sessionstorage } from '../session.keys';
import { StorageHelper } from './storage.abstract';

class LocalImpl extends StorageHelper<localstorage> {
  protected set_item(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }
  protected get_item(key: string) {
    return JSON.parse(localStorage.getItem(key) as string);
  }
  protected remove_item(key: string): void {
    localStorage.removeItem(key);
  }
  protected clear_all(): void {
    localStorage.clear();
  }
}
class SessionImpl extends StorageHelper<sessionstorage> {
  protected set_item(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  protected get_item(key: string) {
    return JSON.parse(sessionStorage.getItem(key) as string);
  }
  protected remove_item(key: string): void {
    sessionStorage.removeItem(key);
  }
  protected clear_all(): void {
    sessionStorage.clear();
  }
}

class MemoryImpl extends StorageHelper<memorystorage> {
  private srg = {} as any;
  protected set_item(key: string, value: any): void {
    this.srg[key] = value;
  }
  protected get_item(key: string) {
    const value = this.srg[key];
    if (Array.isArray(value)) {
      return [...value];
    }
    if (value instanceof Object) {
      return { ...value };
    }
    return value;
  }
  protected remove_item(key: string): void {
    delete this.srg[key];
  }
  protected clear_all(): void {
    this.srg = {};
  }
}

type UploadParams = Parameters<StorageClass['put']>;

class File {
  /**
   * By default upload file to private folder in s3 bucket
   */
  upload(
    key: UploadParams[0],
    file: UploadParams[1],
    config: UploadParams[2] = { level: 'private', acl: 'bucket-owner-full-control' },
  ): Observable<PutResult> {
    return companyConfigService.getConfigs().pipe(
      combineLatestWith(authService.sub.userIdentityId()),
      switchMap(([{ id }, userIdentityId]) => {
        const path = normalize(`${id}/${key}`);
        return from(Storage.put(path, file, config)).pipe(
          map((result) => {
            result.key = normalize(`${config.level}/${userIdentityId}/${result.key}`);
            return result;
          }),
        );
      }),
    );
  }
  /**
   * By default get list of files from private folder in s3 bucket
   */
  lists(
    key: UploadParams[0],
    config: UploadParams[2] = { level: 'private' },
  ): Observable<S3ProviderListOutput> {
    return companyConfigService.getConfigs().pipe(
      switchMap(({ id }) => {
        const path = normalize(`${id}/${key}`);
        return from(Storage.list(path, config));
      }),
    );
  }
}

class SorageHelperImpl {
  local = new LocalImpl();
  session = new SessionImpl();
  memory = new MemoryImpl();
  file = new File();
}
export const storageHelper = new SorageHelperImpl();
