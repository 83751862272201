import { gql } from 'graphql-request';

export const procurementArticlesList = gql`
  query ListProcurementArticles($filter: WA_ProcurementArticleFilter) {
    wawiAssist {
      listWA_ProcurementArticles(filter: $filter) {
        id
        description
        articleNo
        storageMin
        storageMax
        storageActual
      }
    }
  }
`;

export const getProcurementArticle = gql`
  query GetProcurementArticle($id: ID!) {
    wawiAssist {
      getWA_ProcurementArticle(id: $id) {
        id
        description
        articleNo
        storageMin
        storageMax
        storageActual
        storageLocationId
      }
    }
  }
`;

export const getProcurementChangesInStock = gql`
  query ListWA_ProcurementChangesInStock(
    $dateFrom: Date!
    $dateTo: Date!
    $procurementArticleId: Int!
  ) {
    wawiAssist {
      listWA_ProcurementChangesInStock(
        dateFrom: $dateFrom
        dateTo: $dateTo
        procurementArticleId: $procurementArticleId
      ) {
        onDate
        quantityBeginOfTheDay
        historyUser
        operationDate
        numberOfSource
        quantityDiff
      }
    }
  }
`;

export const saveProcurementArticleData = gql`
  mutation SaveProcurementArticleData($dataToSave: WA_SaveProcurementArticleInput!) {
    wawiAssist {
      saveProcurementArticleData(dataToSave: $dataToSave) {
        status
        updatedGridItem {
          id
          description
          articleNo
          storageMin
          storageMax
          storageActual
        }
      }
    }
  }
`;

export const getArticleStorageQuantityOnDate = gql`
  query GetArticleStorageQuantityOnDate($procurementArticleId: ID!, $onDate: Date!) {
    wawiAssist {
      getWA_ProcurementArticleStorageQuantityOnDate(
        procurementArticleId: $procurementArticleId
        onDate: $onDate
      )
    }
  }
`;

export const changeArticleStorageQuantityOnDate = gql`
  mutation ChangeArticleStorageQuantityOnDate(
    $procurementArticleId: ID!
    $onDate: Date!
    $difference: Float!
  ) {
    wawiAssist {
      changeWA_ProcurementArticleStorageQuantityOnDate(
        procurementArticleId: $procurementArticleId
        onDate: $onDate
        difference: $difference
      )
    }
  }
`;
