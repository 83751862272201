import { map, Observable } from 'rxjs';
import { Pub, Service, Sub } from '../../services/service.abstract';

type Actions = 'open' | 'close' | 'navigateTo';
class PubImpl extends Pub<Actions> {
  openMenu(isBurger?: boolean): void {
    this.emit('open', { isBurger: Boolean(isBurger) });
  }
  closeMenu(): void {
    this.emit('close');
  }
  navigateTo(navigateTo: string): void {
    this.emit('close');
    this.emit('navigateTo', navigateTo);
  }
}
class SubImpl extends Sub<Actions> {
  openMenu(): Observable<{ state: true; isBurger: boolean }> {
    return this.actionListener('open').pipe(
      map(({ params }) => {
        return { state: true, isBurger: params.isBurger };
      }),
    );
  }
  closeMenu(): Observable<false> {
    return this.actionListener('close').pipe(map(() => false));
  }
  navigateTo(): Observable<string> {
    return this.actionListener('navigateTo').pipe(map(({ params }) => params));
  }
}

class NavService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const navService = new NavService();
