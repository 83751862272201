import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../../shared/components/popup/popup.abstract.tsx';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TPopupDefaultActions } from '../../../../../../../../../shared/components/popup/services/popup.service.ts';
import { Content } from './content.popup.tsx';
import { map } from 'rxjs';

export class DebtReminderSettingPopup extends Popup<IDebtReminderSettingPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;
    this.Component = () => {
      const Wrapper = useMemo(() => this.Wrapper, []);
      const { t } = useTranslation();
      const [popupTitle, setPopupTitle] = useState('');

      useEffect(() => {
        stream
          .actionListener('open')
          .pipe(map(({ title }) => `${t('settings.reminder_text')} | ${title}`))
          .subscribe(setPopupTitle);
      }, []);

      return (
        <Wrapper
          stream={stream}
          PaperProps={{ sx: { maxWidth: '748px' } }}
          fullWidth
          {...{ popupTitle }}
        >
          <Content {...{ stream }} />
        </Wrapper>
      );
    };
  }
}

export interface IDebtReminderSettingPopup {
  action: TPopupDefaultActions;
  id: string;
  title: string;
}
