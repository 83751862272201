import { FC, useMemo } from 'react';

import { SideFilter } from '../../../../../../shared/components/sideFilter/sideFilter.component.tsx';
import {
  initProductionFilter,
  IProductionTabDataRes,
  printJobsDetailsState,
  ProductionFilterListType,
} from '../../../states/printJobsDetails.state.ts';
import { FilterForm } from './filterForm.component.tsx';
import { InputMaybe } from '../../../../../../graphql/generatedModel.ts';

export const Filter: FC<IFilterProps> = ({
  initFilter: { fromDate, toDate, ...restFilterDate },
}) => {
  const getPrintListStateByListType = (
    state: InputMaybe<ProductionFilterListType | boolean> | undefined,
  ): boolean | null => {
    switch (state) {
      case ProductionFilterListType.activeLists:
        return true;
      case ProductionFilterListType.inactiveLists:
        return false;
      default:
        return null;
    }
  };

  const getFilterListType = (value?: boolean | null): ProductionFilterListType => {
    if (typeof value === 'boolean') {
      return value ? ProductionFilterListType.activeLists : ProductionFilterListType.inactiveLists;
    } else {
      return ProductionFilterListType.fullList;
    }
  };

  const initValues = useMemo(
    () => ({
      ...restFilterDate,
      printListsActiveState: getFilterListType(restFilterDate.printListsActiveState),
    }),
    [restFilterDate],
  );

  return (
    <SideFilter<TSideFilterProps>
      onFilter={({ printListsActiveState, ...filter }) => {
        printJobsDetailsState.pub.filter({
          ...filter,
          fromDate,
          toDate,
          printListsActiveState: getPrintListStateByListType(printListsActiveState),
        });
      }}
      minWidthMenu={350}
      clearEmpty
      defaultFilterSettings={{
        ...initProductionFilter,
        printListsActiveState: getFilterListType(initProductionFilter.printListsActiveState),
      }}
      {...{ initValues }}
    >
      <FilterForm initFilter={initValues} />
    </SideFilter>
  );
};

export interface IFilterProps {
  initFilter: IProductionTabDataRes['filter'];
}

export type TSideFilterProps = Pick<IFilterProps['initFilter'], 'reportGroupIds'> & {
  printListsActiveState: ProductionFilterListType;
};
