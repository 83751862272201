import { FC, useCallback } from 'react';
/* Icons */
import CalculateIcon from '@mui/icons-material/Calculate';

import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import {
  SStack,
  SValue,
} from '../../../../../../../../../../order/common/orderGridItems/cells/product/product.cell.tsx';
import { CircularProgress, IconButton, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { blue } from '@mui/material/colors';
import { deliverySplittingState } from '../../deliverySplitting.state.ts';

export const ProductCell: FC<IProductCell> = ({ params, productCellLoading }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const { value, row, id } = params;

  const prepareData = useCallback((): IPreparedData => {
    const count = watch('deliverySplittingPartsCount');
    const copyRow = structuredClone(row);
    const preparedPositions: IPreparedPositions = {};
    let disabledCalcBtn = true;
    for (const key in copyRow) {
      if (copyRow[key]?.splittingPartsPreset) {
        const splittingPartsPreset = copyRow[key].splittingPartsPreset;
        splittingPartsPreset.length = count;
        splittingPartsPreset.length = 10;
        splittingPartsPreset.fill('', count, 10);
        if (disabledCalcBtn && copyRow[key]?.quantity) {
          disabledCalcBtn = !splittingPartsPreset.some((el?: string | null) => el !== 'r' && el);
        }
        preparedPositions[key] = {
          quantity: copyRow[key].quantity,
          splittingPartsPreset: copyRow[key]?.splittingPartsPreset,
        };
      }
    }
    return { preparedPositions, disabledCalcBtn };
  }, [row, watch('deliverySplittingPartsCount')]);
  const handleClick = () => {
    deliverySplittingState.pub.calculatePositionSplitting(row, prepareData().preparedPositions);
  };
  return (
    <SStack>
      <SValue>{value}</SValue>
      <IconButton
        className='calculationBtn'
        title={t('customer.checkSplitting')}
        onClick={handleClick}
        sx={{ p: '3px', position: 'relative' }}
        color='default'
        size='small'
        disabled={!watch('isDeliverySplitting') || prepareData().disabledCalcBtn}
      >
        {productCellLoading === id && <SCircularProgress thickness={2} size={30} />}
        <CalculateIcon />
      </IconButton>
    </SStack>
  );
};

const SCircularProgress = styled(CircularProgress)({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
  '& > svg': {
    color: blue[500],
  },
});

interface IProductCell {
  params: GridRenderCellParams;
  productCellLoading: number | null;
}

interface IPreparedData {
  preparedPositions: IPreparedPositions;
  disabledCalcBtn: boolean;
}

export interface IPreparedPositions {
  [key: string]: {
    quantity?: number;
    splittingPartsPreset?: string[];
  };
}
