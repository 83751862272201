import { ITabState } from '../states/tabState.model.ts';
import { CustomerListsDataRes, customerService } from '../services/customer.service.ts';
import { responseHandler } from '../../../shared/responseHandler/responseHandler.ts';
import { take } from 'rxjs';

export const defaultCustomerListsData: CustomerListsDataRes = {
  id: '',
  customerListsCheckedState: [],
};

export async function resolveDataForListsTab(data: ITabState): Promise<ITabState> {
  data.defaultValues = data?.customerId
    ? await customerService.globHelpers.streamToPromise(
        customerService.sub
          .customerListsData()
          .pipe(
            responseHandler<CustomerListsDataRes>({ errorReturnType: defaultCustomerListsData }),
            take(1),
          ),
        () => {
          customerService.pub.customerListsData({ id: data.customerId! });
        },
      )
    : {};

  return data;
}
