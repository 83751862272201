class ValueHelper {
  private readonly domParser;
  constructor() {
    this.domParser = new DOMParser();
  }
  checkValueInHtml(html?: string | null): boolean {
    if (html) {
      const doc = this.domParser.parseFromString(html, 'text/html');
      return !!doc.querySelector('p')?.innerText?.trim();
    }
    return false;
  }
}

export const valueHelper = new ValueHelper();
