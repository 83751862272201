import { FC, ReactNode } from 'react';
import { environment } from '../../../environment';

export const appEnv = import.meta.env.VITE_REACT_APP_ENV as keyof typeof environment;
export const Guard: FC<IGuard> = ({ module, componentName, children }) => {
  if (appEnv === 'development') {
    return <>{children}</>;
  }
  const m = environment[appEnv].modules[module];
  let showContent = true;
  if (m.page === false) {
    showContent = false;
  } else if (
    componentName &&
    m.components &&
    (m.components as Record<string, any>)[componentName] === false
  ) {
    showContent = false;
  }

  return <>{showContent && children}</>;
};
interface IGuard {
  module: keyof (typeof environment)['production']['modules'];
  componentName?: string;
  children: ReactNode;
}
