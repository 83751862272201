import { SW_MESSAGE_TYPE } from './appInit';
import { appState } from '../shared/services/app/app.state';

export const updateHandler = (registration: ServiceWorkerRegistration) => {
  const handleUpdate = () => {
    if (registration.waiting) {
      registration.waiting.postMessage?.({ type: SW_MESSAGE_TYPE.skipWaitng });
    } else {
      // Service worker was updated on the different tab.
      // Just reload page to get new app version.
      window.location.reload();
    }
  };
  appState.pub.appUpdate(handleUpdate);
};
