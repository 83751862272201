import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { Content } from '../../../../../shared/components/content/content.component';
import { ArticleListsTabDataRes } from '../../../services/article.service';
import { ITabState } from '../../../states/tabState.model';
import { ListsForm } from './components/listsForm.component';
import { IListsTabState, listsTabState } from './lists.state';

export const ListsTab: FC = () => {
  const { defaultValues } = useLoaderData() as IListsTab;
  const [isArticleIdExist, setIsArticleIdExist] = useState<boolean>(Boolean(defaultValues?.id));
  const { t } = useTranslation();
  const formMetods = useForm<ArticleListsTabDataRes>({
    mode: 'onChange',
    defaultValues,
  });

  const { watch, reset, resetField } = formMetods;

  useEffect(() => {
    const formStateSub = watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        listsTabState.pub.recordData({
          [fieldName]: data[fieldName],
          id: data.id,
        });
      }
    });
    const unsubarticleIdNotExist = listsTabState.sub.articleIdNotExist().subscribe(() => {
      setIsArticleIdExist(false);
      reset({});
    });
    const unsubListsState = listsTabState.sub
      .state()
      .subscribe(({ action, defaultValues, dataToSave }: IState) => {
        setIsArticleIdExist(true);
        if (action === 'list.selectedArticle') {
          reset(defaultValues);
        } else {
          const name = Object.keys(dataToSave)[0] as keyof typeof dataToSave;
          resetField(name, {
            defaultValue: dataToSave[name],
            keepDirty: false,
          });
        }
      });
    return () => {
      formStateSub.unsubscribe();
      unsubListsState.unsubscribe();
      unsubarticleIdNotExist.unsubscribe();
    };
  }, []);

  return (
    <>
      {isArticleIdExist ? (
        <ListsForm formMethods={formMetods} />
      ) : (
        <Content
          type='paper'
          paperProps={{
            variant: 'elevation',
            sx: {
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              height: '100%',
            },
          }}
          emptyData={{
            value: true,
            title: t('common.no_data'),
          }}
        />
      )}
    </>
  );
};
interface IState extends IListsTabState {
  defaultValues: ArticleListsTabDataRes;
}
interface IListsTab extends ITabState {
  defaultValues: ArticleListsTabDataRes;
}
