import { LoaderFunction } from 'react-router-dom';

import {
  initStockOfFreezerListState,
  IStockOfFreezerListState,
} from '../../stockOfFreezer/states/stockOfFreezerList.state';

import {
  initStockOfFreezerDetailsState,
  IStockOfFreezerDetailsState,
} from '../../stockOfFreezer/states/stockOfFreezerDetails.state';
import {
  ArticleDataAndChangesInStockRes,
  ListProcurementArticlesRes,
  stockOfFreezerService,
} from '../../services/stockOfFreezer.service.ts';
import { take } from 'rxjs';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler.ts';
import { storageHelper } from '../../../../shared/helpers/storage';
import { dataHelper } from '../../../../shared/helpers/data/data.helper.ts';
import {
  configsData,
  DictStorageLocationsRes,
} from '../../../../shared/services/configsData/configsData.service.ts';
import { addDays, format, subDays } from 'date-fns';

export const stockOfFreezerLoader: LoaderFunction = async (): Promise<IStockOfFreezerLoader> => {
  const initListData = {
    ...(await resolveStockOfFreezerListData(structuredClone(initStockOfFreezerListState))),
  };

  const description = initListData.initData.selectedArticle?.description;
  const articleNo = initListData.initData.selectedArticle?.articleNo;

  const initDetailsData = await resolveStockOfFreezerDetailsData({
    initData: {
      ...initStockOfFreezerDetailsState,
      stockOfFreezerTitle:
        articleNo && description ? `${articleNo} ${description}` : articleNo || description || '',
    },
    id: initListData.initData.selectedArticle?.id,
  });

  return {
    initListData,
    initDetailsData,
  };
};

async function resolveStockOfFreezerListData(
  data: IStockOfFreezerListState,
): Promise<IStockOfFreezerLoader['initListData']> {
  const storageLocationOptions =
    (await stockOfFreezerService.globHelpers.streamToPromise(
      configsData.sub.dictStorageLocations().pipe(take(1)),
      () => configsData.pub.common(['dictStorageLocations']),
    )) || [];

  data.articleList = await stockOfFreezerService.globHelpers.streamToPromise(
    stockOfFreezerService.sub
      .procurementArticlesList()
      .pipe(responseHandler<ListProcurementArticlesRes>({ errorReturnType: [] }), take(1)),
    () => {
      stockOfFreezerService.pub.procurementArticlesList({ filter: data.filter });
    },
  );
  data.sortModel =
    storageHelper.local.getItem('stockOfFreezer.articleList.sortModel') || data.sortModel;
  if (data.articleList.length) {
    data.articleList = dataHelper
      .data(data.articleList as [])
      .sort({ sortModel: data.sortModel })
      .result() as IStockOfFreezerListState['articleList'];
  }
  const selectedArticleStorage = storageHelper.session.getItem('stockOfFreezer.selectedArticle');
  const foundSelectedArticle =
    selectedArticleStorage &&
    data.articleList.find((item) => item?.id === selectedArticleStorage?.id);
  data.selectedArticle = foundSelectedArticle || data.articleList[0];
  data.allArticleListLength = data.articleList.length;
  return { initData: { ...data, action: 'loader' }, filterOptions: { storageLocationOptions } };
}

const resolveStockOfFreezerDetailsData = async ({
  initData,
  id,
}: resolveDetailsDataProps): Promise<IStockOfFreezerLoader['initDetailsData']> => {
  const storageLocationOptions =
    (await stockOfFreezerService.globHelpers.streamToPromise(
      configsData.sub.dictStorageLocations().pipe(take(1)),
      () => configsData.pub.common(['dictStorageLocations']),
    )) || [];
  const dateFrom = format(subDays(new Date(), 7), 'yyyy-MM-dd');
  const dateTo = format(addDays(new Date(), 7), 'yyyy-MM-dd');
  const datePeriod = {
    dateFrom,
    dateTo,
  };

  if (!id) {
    return { ...initData, isEmptyRightSide: true, storageLocationOptions, datePeriod };
  }

  const { articleInfo, procurementChangesInStock } =
    await stockOfFreezerService.globHelpers.streamToPromise(
      stockOfFreezerService.sub.getArticleDataAndChangesInStock().pipe(
        responseHandler<ArticleDataAndChangesInStockRes>({
          customErrorHandler: () => 'common.server_error',
          errorReturnType: {
            articleInfo: { id: 'errorId' },
            procurementChangesInStock: [],
          },
        }),
        take(1),
      ),
      () => {
        stockOfFreezerService.pub.getArticleDataAndChangesInStock({
          dateFrom,
          dateTo,
          procurementArticleId: Number(id),
        });
      },
    );

  return {
    ...initData,
    action: 'loader',
    selectedArticleInfo: articleInfo,
    storageLocationOptions,
    procurementChangesInStock,
    articleId: id,
    datePeriod,
  };
};

export interface IStockOfFreezerLoader {
  initListData: {
    initData: IStockOfFreezerListState;
    filterOptions: { storageLocationOptions: DictStorageLocationsRes };
  };
  initDetailsData: IStockOfFreezerDetailsState;
}
interface resolveDetailsDataProps {
  initData: IStockOfFreezerDetailsState;
  id?: NonNullable<IStockOfFreezerListState['selectedArticle']>['id'];
}
