import { LocalizationHelper } from '../../shared/helpers/localization/localization.helper';

type Keys =
  | 'customer'
  | 'customers'
  | 'create_customer'
  | 'new_customer'
  | 'number'
  | 'customer_active'
  | 'surname'
  | 'general'
  | 'availability'
  | 'standardOrder'
  | 'type_of_address'
  | 'group'
  | 'delivery_note_group'
  | 'address'
  | 'salutation'
  | 'additive'
  | 'internal_name'
  | 'street'
  | 'post_box'
  | 'zip_code'
  | 'city'
  | 'country'
  | 'delivery_address'
  | 'contacts'
  | 'contact_person'
  | 'phone_business'
  | 'phone_private'
  | 'mobile'
  | 'fax'
  | 'email_for_invoices'
  | 'website'
  | 'lists'
  | 'customer_groups'
  | 'tourplans'
  | 'print_form'
  | 'print_forms'
  | 'summary_form'
  | 'payment_slip_form'
  | 'copy'
  | 'copy_other'
  | 'copy_customer'
  | 'delivery_note_comments'
  | 'invoice_comments'
  | 'additional_comments'
  | 'e_bill_account_id'
  | 'select_default_setting'
  | 'invalid_data_warning'
  | 'enter_valid_email'
  | 'customer_saved'
  | 'overview'
  | 'availabilitySetting'
  | 'allProductGroups'
  | 'setUpStation'
  | 'setUpStationSat'
  | 'setUpStationSun'
  | 'standardTour'
  | 'drivingPos'
  | 'deliveryTime'
  | 'saturdayTour'
  | 'sundayTour'
  | 'addInTourIfNoDelivery'
  | 'tour'
  | 'cashAssist'
  | 'canBuyOnCreditOnCashAssist'
  | 'takeReductionsAndPricesFrom'
  | 'defaultOrder'
  | 'preset'
  | 'createAutomaticallyFromPresetting'
  | 'editing'
  | 'conditions'
  | 'discount'
  | 'price_and_discount'
  | 'priceCategories'
  | 'discountKind'
  | 'discountPercent'
  | 'quantityDiscountValue'
  | 'discountType'
  | 'articlePrice'
  | 'accounting'
  | 'debtor'
  | 'customerIsInvoicedWith'
  | 'payment'
  | 'bank'
  | 'paymentDeadline'
  | 'termOfPaymentInDays'
  | 'individual'
  | 'cashDiscount'
  | 'cashDiscountIn'
  | 'debtorAccount'
  | 'slipReference'
  | 'returnLimitIn'
  | 'debit'
  | 'bankName'
  | 'bin'
  | 'accountNo'
  | 'iban'
  | 'bic'
  | 'specialDsicountCashAssist'
  | 'mandatNumber'
  | 'dateOfMandatSignature'
  | 'changesProhibited'
  | 'orderLimit'
  | 'addNewRecord'
  | 'deleteRecord'
  | 'deliverySplittingConfig'
  | 'tourNote'
  | 'partialDelivery'
  | 'numberOfPieces'
  | 'enablePartialDelivery'
  | 'part'
  | 'checkSplitting'
  | 'defaultOrder_saved'
  | 'defaultOrderItems'
  | 'articlesAvailabilityOn'
  | 'individual_payment_conditions'
  | 'bank_abbreviation'
  | 'vat_system'
  | 'invalid_value'
  | 'useDefOrderFromCustomer'
  | 'articlePrices'
  | 'stdPrice'
  | 'price'
  | 'noDiscount'
  | 'fromQuantity'
  | 'kind'
  | 'comment'
  | 'individualPrices'
  | 'article'
  | 'shipping_costs_sector';

const t = LocalizationHelper<Keys>('customer');

export const customerDe = t({
  customer: 'Kunde',
  customers: 'Kunden',
  create_customer: 'Kunde erstellen',
  copy_customer: 'Kunde kopien',
  new_customer: 'Neukunde',
  surname: 'Nachname',
  number: 'Nummer',
  general: 'Allgemein',
  customer_active: 'Kunde ist aktiv',
  type_of_address: 'Art der Adresse',
  group: 'Gruppe',
  delivery_note_group: 'Lieferscheingruppe',
  address: 'Adresse',
  salutation: 'Anrede',
  additive: 'Zusatz',
  internal_name: 'Interner Name',
  street: 'Strasse',
  post_box: 'Postfach',
  zip_code: 'PLZ/Ort',
  availability: 'Verfügbarkeit',
  city: 'Stadt',
  discountKind: 'Rabattart',
  discountPercent: 'Rabatt %',
  quantityDiscountValue: 'Mengenrabattwert',
  country: 'Land',
  delivery_address: 'Lieferschein Adresse',
  contacts: 'Kontakte',
  contact_person: 'Ansprechperson',
  phone_business: 'Telefon Geschäftlich',
  phone_private: 'Telefon Privat',
  mobile: 'Mobile',
  fax: 'Fax',
  email_for_invoices: 'Email für Rechnungen',
  website: 'Website',
  lists: 'Listen',
  customer_groups: 'Kundengruppen',
  tourplans: 'Touren',
  print_form: 'Formular',
  print_forms: 'Formulare',
  summary_form: 'Zusammenzug Formular',
  payment_slip_form: 'Einzahlungsschein Formular',
  copy: 'Kopie',
  copy_other: 'Kopien',
  delivery_note_comments: 'Individuelle Bemerkungen auf dem Lieferschein',
  invoice_comments: 'Individuelle Bemarkungen auf der Rechnung',
  additional_comments: 'Zusätzliche Bemerkung',
  e_bill_account_id: 'E-Bill Account ID',
  select_default_setting: 'Kunden Standardeinstellung',
  invalid_data_warning:
    'Sie haben einen Fehler in Ihren Daten. Ihre Änderungen gehen verloren. Möchten Sie fortfahren?',
  enter_valid_email: 'Geben Sie eine gültige Email Adresse ein',
  customer_saved: 'Kunde wurde erfolgreich gespeichert',
  standardOrder: 'Standardreihenfolge',
  overview: 'Überblick',
  availabilitySetting: 'Verfügbarkeit Einstellung',
  allProductGroups: 'Alle Sortimentsgruppen',
  setUpStation: 'Rüstplatz',
  setUpStationSat: 'Rüstplatz Sa',
  setUpStationSun: 'Rüstplatz So',
  standardTour: 'Standard Tour',
  drivingPos: 'Anfahrt Pos.',
  deliveryTime: 'Lieferzeit',
  saturdayTour: 'Sa. Tour',
  sundayTour: 'So. Tour',
  addInTourIfNoDelivery: 'Wenn keine Lieferung aufführen',
  tour: 'Tour',
  cashAssist: 'CashAssist',
  canBuyOnCreditOnCashAssist: 'Kreditkunde CashAssist',
  takeReductionsAndPricesFrom: 'Rabatte und Preise übernehmen von ...',
  defaultOrder: 'Vorgabebestellung',
  preset: 'Voreinstellung',
  createAutomaticallyFromPresetting: 'Fixbestellung (aus Vorgabe)',
  editing: 'Bearbeitung',
  conditions: 'Konditionen',
  discount: 'Rabatt',
  price_and_discount: 'Preis und Rabatt',
  priceCategories: 'Preiskategorie',
  discountType: 'Rabatt typ',
  articlePrice: 'Artikel-Preise',
  accounting: 'Abrechnung',
  debtor: 'Debitor-Status',
  customerIsInvoicedWith: 'Dieser Kunde wird fakturiert mit',
  payment: 'Zahlung',
  bank: 'Bank',
  paymentDeadline: 'Zahlungsziel',
  termOfPaymentInDays: 'Zahlungsziel in Tagen',
  individual: 'Individuelle',
  cashDiscount: 'Skonto',
  cashDiscountIn: 'Skonto in %',
  debtorAccount: 'Debitorenkonto',
  slipReference: 'Beleg-Referenz',
  returnLimitIn: 'Retourenbegrenzung in %',
  debit: 'Belastung',
  bankName: 'Bankname',
  bin: 'BLZ',
  accountNo: 'Konto-Nr.',
  iban: 'IBAN',
  bic: 'BIC',
  specialDsicountCashAssist: 'Sonderrabatt CashAssist (%)',
  mandatNumber: 'Mandat Nummer',
  dateOfMandatSignature: 'Datum der Unterschrift des Mandats',
  changesProhibited: 'Änderungen sperren',
  orderLimit: 'Bestellbegrenzung',
  addNewRecord: 'Neuen Eintrag hinzufügen',
  deleteRecord: 'Eintrag löschen',
  deliverySplittingConfig: 'Konfiguration für Bestellaufteilung',
  tourNote: 'Tourbemerkung',
  partialDelivery: 'Teillieferung',
  numberOfPieces: 'Anzahl der Teile',
  enablePartialDelivery: 'Teillieferung aktivieren',
  part: 'Anf.',
  checkSplitting: 'Splitting prüfen',
  defaultOrder_saved: 'Vorgabebestellung wurde erfolgreich gespeichert',
  defaultOrderItems: 'Vorgabebestellung Positionen',
  articlesAvailabilityOn: 'Artikelverfügbarkeit auf',
  individual_payment_conditions: 'Individuelle Zahlungskonditionen',
  bank_abbreviation: 'BESR/VESR Bank',
  vat_system: 'MwSt-System',
  invalid_value: 'Ungültiger Wert',
  useDefOrderFromCustomer: 'Vorgabebestellung von Kunde verwenden',
  articlePrices: 'Artikel-Preise',
  stdPrice: 'Std. Preis',
  price: 'Preis',
  noDiscount: 'Nettoartikel',
  fromQuantity: 'Ab Menge',
  kind: 'Art',
  comment: 'Bemerkung',
  individualPrices: 'Individuelle Preise',
  article: 'Artikel',
  shipping_costs_sector: 'Versandkosten Bereich',
});

export const customerEn = t({
  customer: 'Customer',
  customers: 'Customers',
  create_customer: 'Create customer',
  copy_customer: 'Copy customer',
  new_customer: 'New customer',
  surname: 'Surname',
  number: 'Number',
  general: 'General',
  customer_active: 'Customer is active',
  type_of_address: 'Type of address',
  group: 'Group',
  delivery_note_group: 'Delivery note group',
  address: 'Address',
  salutation: 'Salutation',
  additive: 'Additive',
  internal_name: 'Internal name',
  street: 'Street',
  post_box: 'Post office box',
  zip_code: 'ZIP',
  city: 'City',
  availability: 'Availability',
  country: 'Country',
  delivery_address: 'Delivery address',
  contacts: 'Contacts',
  contact_person: 'Contact person',
  phone_business: 'Phone business',
  phone_private: 'Phone private',
  mobile: 'Mobile',
  fax: 'Fax',
  discountKind: 'Discount kind',
  quantityDiscountValue: 'Quantity discount value',
  discountPercent: 'Discount %',
  email_for_invoices: 'Email for invoices',
  website: 'Website',
  lists: 'Lists',
  customer_groups: 'Customer groups',
  tourplans: 'Tourplans',
  print_form: 'Print form',
  print_forms: 'Print forms',
  summary_form: 'Summary form',
  payment_slip_form: 'Payment slip form',
  copy: 'Copy',
  copy_other: 'Copies',
  delivery_note_comments: 'Individual comments on the delivery note',
  invoice_comments: 'Individual comments on the invoice',
  additional_comments: 'Additional comments',
  e_bill_account_id: 'E-Bill Account ID',
  select_default_setting: 'Customer default setting',
  invalid_data_warning:
    'You have an error in your data. Your changes will be lost. Do you want to proceed?',
  enter_valid_email: 'Please enter a correct email address',
  customer_saved: 'Customer was successfully saved',
  standardOrder: 'Standard order',
  overview: 'Overview',
  availabilitySetting: 'Availability setting',
  allProductGroups: 'All assortment groups',
  setUpStation: 'Set-up station',
  setUpStationSat: 'Set-up station Sat',
  setUpStationSun: 'Set-up station Sun',
  standardTour: 'Standard tour',
  drivingPos: 'Driving pos.',
  deliveryTime: 'Delivery time',
  saturdayTour: 'Sat. tour',
  sundayTour: 'Sun. tour',
  addInTourIfNoDelivery: 'Add in tour if no delivery',
  tour: 'Tour',
  cashAssist: 'CashAssist',
  canBuyOnCreditOnCashAssist: 'Credit customer CashAssist',
  takeReductionsAndPricesFrom: 'Take reductions and prices from ...',
  defaultOrder: 'Default order',
  preset: 'Preset',
  createAutomaticallyFromPresetting: 'Create automatically from presetting',
  editing: 'Editing',
  conditions: 'Conditions',
  discount: 'Discount',
  price_and_discount: 'Price and discount',
  priceCategories: 'Price category',
  discountType: 'Discount type',
  articlePrice: 'Article price',
  accounting: 'Accounting',
  debtor: 'Debtor status',
  customerIsInvoicedWith: 'This customer is invoiced with',
  payment: 'Payment',
  bank: 'Bank',
  paymentDeadline: 'Payment deadline',
  termOfPaymentInDays: 'Payment periods in days',
  individual: 'Individual',
  cashDiscount: 'Cash discount',
  cashDiscountIn: 'Skonto in %',
  debtorAccount: 'Debtor account',
  slipReference: 'Slip reference',
  returnLimitIn: 'Return limit in %',
  debit: 'Debit',
  bankName: 'Bank name',
  bin: 'BIN',
  accountNo: 'Account No.',
  iban: 'IBAN',
  bic: 'BIC',
  specialDsicountCashAssist: 'Credit customer Special discount CashAssist (%)',
  mandatNumber: 'Mandate number',
  dateOfMandatSignature: 'Date of mandate signature',
  changesProhibited: 'Changes prohibited',
  orderLimit: 'Order limit',
  addNewRecord: 'Add new record',
  deleteRecord: 'Delete record',
  deliverySplittingConfig: 'Delivery splitting configuration',
  tourNote: 'Tour note',
  partialDelivery: 'Partial delivery',
  numberOfPieces: 'Number of pieces',
  enablePartialDelivery: 'Enable partial delivery',
  part: 'Part',
  checkSplitting: 'Check splitting',
  defaultOrder_saved: 'Default order was successfully saved',
  defaultOrderItems: 'Default order items',
  articlesAvailabilityOn: 'Articles availability on',
  individual_payment_conditions: 'Individual payment conditions',
  bank_abbreviation: 'BESR/VESR bank',
  vat_system: 'VAT system',
  invalid_value: 'Invalid value',
  useDefOrderFromCustomer: 'Use default order from customer',
  articlePrices: 'Article prices',
  stdPrice: 'Std. price',
  price: 'Price',
  noDiscount: 'No discount',
  fromQuantity: 'From quantity',
  kind: 'Kind',
  comment: 'Comment',
  individualPrices: 'Individual prices',
  article: 'Article',
  shipping_costs_sector: 'Shipping costs sector',
});
