import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { FilterGroup } from '../../../../../../shared/components/sideFilter/filterGroup.tsx';
import { Checkbox, FormRadioGroup, Radio } from '../../../../../../shared/components/form';
import { C_Report_Group } from '../../../../../../graphql/generatedModel.ts';
import {
  IProductionTabDataRes,
  ProductionFilterListType,
} from '../../../states/printJobsDetails.state.ts';
import { TSideFilterProps } from './filter.component.tsx';

export const FilterForm: FC<IFilterFormProps> = ({ initFilter }) => {
  const { printListsActiveState, reportGroupIds } = initFilter || {};
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const [activeRadio, setActiveRadio] = useState<string>(printListsActiveState);
  const [activeCheckboxList, setActiveCheckboxList] = useState<
    IProductionTabDataRes['filter']['reportGroupIds']
  >(reportGroupIds || []);

  const handleChangeRadio = (v: string) => {
    setActiveRadio(v);
  };

  const handleChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;
    const castValue = value as C_Report_Group;
    if (checked) {
      if (!activeCheckboxList.includes(castValue)) {
        setActiveCheckboxList((prev) => {
          const updatedArr = [...prev, castValue];
          setValue('reportGroupIds', updatedArr);
          setValue('printListsActiveState', activeRadio);
          return updatedArr;
        });
      }
      return;
    }
    if (activeCheckboxList.includes(castValue)) {
      setActiveCheckboxList((prev) => {
        const updatedArr = prev.filter((v) => v !== castValue);
        setValue('reportGroupIds', updatedArr);
        setValue('printListsActiveState', activeRadio);
        return updatedArr;
      });
    }
  };

  return (
    <>
      <FilterGroup marginBottom={16}>
        <FormRadioGroup name='printListsActiveState' onChange={handleChangeRadio}>
          <Radio
            value={ProductionFilterListType.fullList}
            checked={activeRadio === ProductionFilterListType.fullList}
            label={t('production.all_print_lists')}
          />
          <Radio
            value={ProductionFilterListType.activeLists}
            checked={activeRadio === ProductionFilterListType.activeLists}
            label={t('production.active_print_lists')}
          />
          <Radio
            value={ProductionFilterListType.inactiveLists}
            checked={activeRadio === ProductionFilterListType.inactiveLists}
            label={t('production.inactive_print_lists')}
          />
        </FormRadioGroup>
      </FilterGroup>
      <FilterGroup>
        <Checkbox
          label={t('production.production')}
          value={C_Report_Group.RG1_PRODUCTION_PRODUCTION}
          onChange={handleChangeCheckbox}
          checked={activeCheckboxList.includes(C_Report_Group.RG1_PRODUCTION_PRODUCTION)}
        />
        <Checkbox
          label={t('production.forwarding_list')}
          value={C_Report_Group.RG8_PRODUCTION_FORWARDING_LISTS}
          onChange={handleChangeCheckbox}
          checked={activeCheckboxList.includes(C_Report_Group.RG8_PRODUCTION_FORWARDING_LISTS)}
        />
        <Checkbox
          label={t('production.cross_table_report')}
          value={C_Report_Group.RG9_PRODUCTION_CROSSTABLE}
          onChange={handleChangeCheckbox}
          checked={activeCheckboxList.includes(C_Report_Group.RG9_PRODUCTION_CROSSTABLE)}
        />
        <Checkbox
          label={t('production.labels')}
          value={C_Report_Group.RG3_ARTICLE}
          onChange={handleChangeCheckbox}
          checked={activeCheckboxList.includes(C_Report_Group.RG3_ARTICLE)}
        />
        <Checkbox
          label={t('production.dough_list')}
          value={C_Report_Group.RG11_PRODUCTION_SMARTSCALE}
          onChange={handleChangeCheckbox}
          checked={activeCheckboxList.includes(C_Report_Group.RG11_PRODUCTION_SMARTSCALE)}
        />
      </FilterGroup>
    </>
  );
};

interface IFilterFormProps {
  initFilter: TSideFilterProps;
}
