import { CircularProgress, styled } from '@mui/material';
import { SnackbarMessage } from 'notistack';
import { FC } from 'react';

export const LoadingContent: FC<IProps> = ({ text }) => {
  return (
    <SLoadingContent>
      <SCircularProgress size={30} /> <span>{text}</span>
    </SLoadingContent>
  );
};

const SLoadingContent = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 15,
}));

const SCircularProgress = styled(CircularProgress)(({ size }) => ({
  minWidth: size,
}));

interface IProps {
  text: SnackbarMessage;
}
