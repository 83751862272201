import { gql } from 'graphql-request';

export const listAllCompanies = gql`
  query ListAllCompanies {
    wawiAssist {
      listAllCompanies {
        CompanyID
        Email
        ContractorFirma
        ContractorName
      }
    }
  }
`;
