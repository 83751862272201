import { filter, finalize, map, merge, Observable, switchMap, take, tap } from 'rxjs';
import {
  C_Save_Operation_Status,
  SaveArticleDataMutationVariables,
} from '../../../../../graphql/generatedModel';
import { storageHelper } from '../../../../../shared/helpers/storage';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler';
import { Pub, State } from '../../../../../shared/state/state.abstract';
import {
  ArticleCashAssistTabDataRes,
  articleService,
  SaveArticleDataRes,
} from '../../../services/article.service';
import { articleListState } from '../../../states/articleList.state';
import { ITabState } from '../../../states/tabState.model';
import { artcileTabsSub } from '../articleCommonTabs.sub';
import { articleTabLoadingService } from '../articleTababLoading.service';
import { defaultArticleCashAssistData } from '../../../loaders/cashAssistTab.resolver';

class PubImpl extends Pub<ICashAssistTabState> {
  recordData(dataToSave: ICashAssistTabState['dataToSave']) {
    this.emit('recordData', { dataToSave });
  }
  save() {
    this.emit('save', {});
  }
  clearStream() {
    this.emit(undefined, {});
  }
}
class SubImpl extends artcileTabsSub<ICashAssistTabState>() {
  protected actionHandlers(): Observable<ICashAssistTabState> {
    return merge(this.selectedArticle(), this.save());
  }
  private save(): Observable<ICashAssistTabState> {
    return this.actionListener('save').pipe(
      tap(({ dataToSave }) => {
        articleService.sub
          .editArticle()
          .pipe(
            responseHandler<SaveArticleDataRes | undefined>({
              success: () => 'article.article_saved',
              customErrorHandler: () => 'article.article_not_saved',
            }),
            filter((v) => v !== undefined),
            take(1),
          )
          .subscribe((res) => {
            if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
              const { updatedGridItem } = res;
              articleListState.pub.updateArticle(updatedGridItem!);
            }
            storageHelper.memory.removeItem('configsData.articlesForOrderPositionList');
          });

        articleService.pub.editArticle({
          dataToSave,
        } as SaveArticleDataMutationVariables);
      }),
      finalize(() => cashAssistTabState.pub.clearStream()),
    );
  }

  private selectedArticle(): Observable<ICashAssistTabState> {
    return articleListState.sub.state().pipe(
      filter(
        ({ action }) =>
          action === 'selectArticle' || action === 'articleList' || action === 'filter',
      ),
      filter(({ selectedArticle }) => {
        if (!selectedArticle || !selectedArticle?.id) {
          this.articleIdNotExist$.next(true);
        }
        return typeof selectedArticle?.id === 'string';
      }),
      switchMap(({ selectedArticle }) => {
        articleTabLoadingService.pub.loading(true);
        const id = selectedArticle!.id;
        const state = this.stream$.getValue();
        state.action = 'list.selectedArticle';
        const details = articleService.sub.articleCashAssistTabData().pipe(
          responseHandler<ArticleCashAssistTabDataRes>({
            errorReturnType: defaultArticleCashAssistData,
          }),
          map((data) => {
            state.defaultValues = data;
            state.defaultValues.id = id;
            articleTabLoadingService.pub.loading(false);
            return state;
          }),
        );
        articleService.pub.articleCashAssistTabData({ id });

        return details;
      }),
    );
  }
}

class CashAssistTabState extends State<ICashAssistTabState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const cashAssistTabState = new CashAssistTabState({
  action: undefined,
  defaultValues: {},
  dataToSave: {},
});

export interface ICashAssistTabState extends Pick<ITabState, 'defaultValues'> {
  action: undefined | 'list.selectedArticle' | 'recordData' | 'save';
  dataToSave: ArticleCashAssistTabDataRes | Record<string, any>;
}
