import { TypeTable } from '../../../../../../../../../shared/components/table/table.component.tsx';
import { Theme } from '../../../../../../../../../shared/styles/theme/theme.ts';
import { weekDays } from '../../../../../../../../../shared/helpers/utils/utils.helper.ts';
import { GridCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { defineDisabled } from '../../../../../../../../order/common/orderGridItems/views/viewCells.ts';

export const viewCells: TypeTable['viewCells'] = {
  actions: {
    ...dayQuantityCells(),
    ...dayLimitCells(),
    ...splittingPartsCells(),
  },
  styles: [
    {
      className: 'invalid',
      cellStyles: {
        backgroundColor: Theme.palette.red.light,
      },
    },
    {
      className: 'invalid-editable',
      cellStyles: {
        '&:hover': {
          backgroundColor: Theme.palette.red.main,
        },
      },
    },
    {
      className: 'negative',
      cellStyles: {
        backgroundColor: Theme.palette.brightRed.light,
      },
    },
    {
      className: 'negative-editable',
      cellStyles: {
        '&:hover': {
          backgroundColor: Theme.palette.brightRed.main,
        },
      },
    },
    {
      className: 'valid',
      cellStyles: {
        backgroundColor: Theme.palette.green.light,
      },
    },
    {
      className: 'valid-editable',
      cellStyles: {
        '&:hover': {
          backgroundColor: Theme.palette.green.main,
        },
      },
    },
    {
      className: 'neutral',
      cellStyles: {
        backgroundColor: Theme.palette.yellow.light,
      },
    },
    {
      className: 'neutral-editable',
      cellStyles: {
        '&:hover': {
          backgroundColor: Theme.palette.yellow.main,
        },
      },
    },
    {
      className: 'splitting',
      cellStyles: {
        backgroundColor: Theme.palette.lightGreen.light,
      },
    },
    {
      className: 'splitting-editable',
      cellStyles: {
        '&:hover': {
          backgroundColor: Theme.palette.lightGreen.main,
        },
      },
    },
  ],
};

function dayQuantityCells() {
  return weekDays.reduce(
    (acc: { [key in string]: (v: GridCellParams<any, any>) => string }, day) => {
      const field = `${day}Settings`;
      acc[`${field}.quantity`] = ({ row, isEditable }) => {
        if (
          !row.isActive ||
          !row[`${field}`].isArticleAvailable ||
          row[`${field}`].quantity === 0
        ) {
          return `invalid ${defineDisabled(!!isEditable, 'invalid-editable')}`;
        }
        if (row[`${field}`].quantity < 0) {
          return `negative ${defineDisabled(!!isEditable, 'negative-editable')}`;
        }
        return `valid ${defineDisabled(!!isEditable, 'valid-editable')}`;
      };
      return acc;
    },
    {},
  );
}

function dayLimitCells() {
  return weekDays.reduce(
    (acc: { [key in string]: (v: GridCellParams<any, any>) => string }, day) => {
      acc[`limit.${day}Settings.quantityMin`] = ({ isEditable }) => {
        return `neutral ${defineDisabled(!!isEditable, 'neutral-editable')}`;
      };
      acc[`limit.${day}Settings.quantityMax`] = ({ isEditable }) => {
        return `neutral ${defineDisabled(!!isEditable, 'neutral-editable')}`;
      };
      return acc;
    },
    {},
  );
}

function splittingPartsCells() {
  return weekDays.reduce(
    (acc: { [key in string]: (v: GridCellParams<any, any>) => string }, day) => {
      for (let i = 0; i <= 10; i++) {
        acc[`${day}Settings.splittingPartsPreset_${i}`] = ({ isEditable }) => {
          return `splitting ${defineDisabled(!!isEditable, 'splitting-editable')}`;
        };
      }
      return acc;
    },
    {},
  );
}
