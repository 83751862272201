import { TypeTable } from '../../../../table/table.component.tsx';

export const viewRows: TypeTable['viewRows'] = {
  action: () => 'sub-row',
  styles: [
    {
      className: 'sub-row',
      rowStyles: {
        '&.MuiDataGrid-row--lastVisible  .MuiDataGrid-cell': {
          borderColor: 'transparent!important',
        },
      },
    },
  ],
};
