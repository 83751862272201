import { Dialog, DialogContent, DialogProps, DialogTitle, IconButton, styled } from '@mui/material';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { tap } from 'rxjs';
import {
  IBeforeCloseArgs,
  type PopupService,
  TPopupDefaultActions,
} from '../services/popup.service';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationPopup from './confirmation.popup.tsx';

export const PopupWrapper: FC<IPopupWrapper> = ({
  stream,
  scroll = 'paper',
  popupTitle = '',
  children,
  closureOverlay = true,
  closeBtn = true,
  ...props
}) => {
  const innerSteam = stream as PopupService<{
    action: TPopupDefaultActions;
    beforeClosingArgs?: IBeforeCloseArgs;
  }>;
  const [open, setOpen] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const beforeClose = useRef<IBeforeCloseArgs>({});
  useEffect(() => {
    innerSteam
      .actionListener('open')
      .pipe(tap(() => setOpen(true)))
      .subscribe();
    innerSteam
      .actionListener('close')
      .pipe(tap(() => setOpen(false)))
      .subscribe();
    innerSteam.beforeCloseListener().subscribe((v) => {
      beforeClose.current = v?.beforeClosingArgs || {};
    });
  }, []);

  const handleClose = (forceClosing?: boolean) => {
    if (beforeClose?.current?.isDirty && !forceClosing) {
      setOpenConfirmation(true);
    } else {
      beforeClose?.current?.handlers?.();
      stream.emit('close');
    }
  };
  return (
    <>
      <Dialog
        {...props}
        open={open}
        scroll={scroll}
        {...(closureOverlay && { onClose: () => handleClose() })}
      >
        <SDialogTitle {...{ closeBtn }}>
          {popupTitle}
          {closeBtn && (
            <SIconButton size='small' onClick={() => handleClose()}>
              <CloseIcon />
            </SIconButton>
          )}
        </SDialogTitle>
        <DialogContent sx={{ paddingBottom: 3 }} dividers={scroll === 'paper'}>
          {children}
        </DialogContent>
      </Dialog>
      {typeof beforeClose?.current?.isDirty === 'boolean' && (
        <ConfirmationPopup
          handleConfirm={setOpenConfirmation}
          open={openConfirmation}
          {...{ handleClose }}
        />
      )}
    </>
  );
};

export interface IPopupWrapper extends Omit<DialogProps, 'open'> {
  stream: PopupService<any>;
  popupTitle?: ReactNode;
  closureOverlay?: boolean;
  closeBtn?: boolean;
}

const SDialogTitle = styled(DialogTitle, {
  shouldForwardProp: (prop) => prop !== 'closeBtn',
})<{ closeBtn: boolean }>(({ closeBtn }) => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  position: 'relative',
  minHeight: '64px',
  ...(closeBtn && { padding: '16px 50px 16px 24px' }),
}));

const SIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: 16,
  top: '50%',
  transform: 'translateY(-50%)',
}));
