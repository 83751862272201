import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from '@sentry/react';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import { environment } from './environment.ts';
import './i18n/init.ts';
import { routes } from './routes/routes';
import { appInit } from './serviceWorker/appInit.ts';
import { registerServiceWorker } from './serviceWorker/registerServiceWorker';
import { updateHandler } from './serviceWorker/updateHandler';
import { SplashScreen } from './shared/components/loading/splashScreen.component';
import SnackbarComponent from './shared/components/snackbar/snackbar.component.tsx';
import { SnackBarService } from './shared/components/snackbar/snackbarService.component.tsx';
import { Theme } from './shared/styles/theme/theme';
import { GlobalStyles } from '@mui/material';

const appEnv = import.meta.env.VITE_REACT_APP_ENV as keyof typeof environment;
const release = import.meta.env.VITE_REACT_APP_RELEASE as string; // Release date day-month-year

if (appEnv === 'production') {
  LicenseInfo.setLicenseKey(environment[appEnv].muiLicenseKey);
  const sentryReplay = Sentry.replayIntegration({
    maskAllText: false,
    blockAllMedia: false,
    unblock: ['div, [data-sentry-unblock]'],
    unmask: ['input, [data-sentry-unmask]'],
  });
  Sentry.init({
    dsn: 'https://bd5ccc4ba7a44ba5b4959d5ef4e20b8d@o4504242163613696.ingest.sentry.io/4504242167414784',
    release,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      sentryReplay,
    ],
    // Ignor issues by name
    beforeSend(event, hint) {
      const error = hint.originalException as Error;
      const notInteresting = error?.name?.match?.(
        /^(Loading chunk)|(ChunkLoadError)|(Large Render Blocking Asset)|(SecurityError)$/gi,
      );

      if (notInteresting) {
        return null;
      }
      return event;
    },
    // Ignor issues by message
    ignoreErrors: [
      /^The user is not authenticated$/,
      /^No current user$/,
      /^Load failed$/,
      'ResizeObserver loop limit exceeded',
    ],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <>
    <SplashScreen />
    <CssBaseline />
    <GlobalStyles styles={(theme) => ({ html: { fontSize: theme.typography.fontSize } })} />
    <ThemeProvider theme={Theme}>
      <SnackbarProvider Components={{ default: SnackbarComponent }}>
        <SnackBarService />
        <RouterProvider router={routes} />
      </SnackbarProvider>
    </ThemeProvider>
  </>,
);

appInit();
registerServiceWorker({ onUpdate: updateHandler });
