// import { Player } from '@lottiefiles/react-lottie-player';
import { Box, Stack, Typography } from '@mui/material';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { SAnimation, SWrapper } from './components/common.component';
import { useLoading } from './loading.hook';

import { ReactComponent as LoadingAnimation } from '../../styles/animations/loading/wawi_loading.svg';

export const Transition: FC<ITransition> = ({ show, children }) => {
  const { t } = useTranslation();
  const { loading, animationHandle } = useLoading(show);
  const fadeInFadeOut = show;

  return (
    <SWrapper data-testid='trasition'>
      <Box sx={{ height: '100%', overflow: 'auto' }}>{children}</Box>
      <SAnimation
        duration='700'
        animated={fadeInFadeOut}
        show={loading}
        onAnimationEnd={animationHandle}
      >
        <Stack justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
          <Box sx={{ height: 100 }}>
            <LoadingAnimation />
          </Box>
          <Typography variant='h6'>{t('common.loading')}</Typography>
        </Stack>
      </SAnimation>
    </SWrapper>
  );
};
interface ITransition {
  show: boolean;
  children: ReactElement | ReactElement[];
}
