import { Observable, map } from 'rxjs';
import { Pub, Service, Sub } from '../../../services/service.abstract';
type Actions = 'off';
class PubImpl extends Pub<Actions> {
  off(): void {
    this.emit('off');
  }
}
class SubImpl extends Sub<Actions> {
  off(): Observable<boolean> {
    return this.actionListener('off').pipe(map(() => false));
  }
}
class SplashScreenService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}
export const splashScreenService = new SplashScreenService();
