import { createSvgIcon } from '@mui/material/utils';

export const GotoSettingsIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15 20.66V15H20.66V17H18.42L21.37 19.95L19.96 21.36L17 18.41V20.65H15V20.66Z'
      fill='currentColor'
    />
    <path
      d='M19.2 12.0004C19.2 12.3304 19.18 12.6404 19.14 12.9404L19.2158 13H15.4579C15.5504 12.6823 15.6 12.3469 15.6 12.0004C15.6 10.0204 13.98 8.40039 12 8.40039C10.02 8.40039 8.39999 10.0204 8.39999 12.0004C8.39999 13.9804 10.02 15.6004 12 15.6004C12.3466 15.6004 12.6822 15.5507 13 15.4582V21.6004H10.08C9.83999 21.6004 9.64999 21.4304 9.59999 21.1904L9.23999 18.6504C8.64999 18.4104 8.11999 18.0904 7.61999 17.7104L5.22999 18.6704C5.00999 18.7404 4.75999 18.6704 4.63999 18.4504L2.71999 15.1304C2.60999 14.9304 2.65999 14.6604 2.83999 14.5204L4.86999 12.9404C4.81999 12.6404 4.79999 12.3104 4.79999 12.0004C4.79999 11.6904 4.83999 11.3604 4.88999 11.0604L2.85999 9.48039C2.65999 9.34039 2.61999 9.08039 2.73999 8.87039L4.64999 5.55039C4.76999 5.33039 5.01999 5.25039 5.23999 5.33039L7.62999 6.29039C8.11999 5.92039 8.65999 5.59039 9.24999 5.35039L9.60999 2.81039C9.64999 2.57039 9.83999 2.40039 10.08 2.40039H13.92C14.16 2.40039 14.36 2.57039 14.4 2.81039L14.76 5.35039C15.35 5.59039 15.88 5.91039 16.38 6.29039L18.77 5.33039C18.99 5.26039 19.24 5.33039 19.36 5.55039L21.28 8.87039C21.39 9.07039 21.34 9.34039 21.16 9.48039L19.13 11.0604C19.18 11.3604 19.2 11.6804 19.2 12.0004Z'
      fill='currentColor'
    />
  </svg>,
  'GotoSettings',
);
