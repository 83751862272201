import { gql } from 'graphql-request';

export const listTaxesPeriodsAndRates = gql`
  query ListWA_TaxesPeriodsAndRates {
    wawiAssist {
      listWA_TaxesPeriodsAndRates {
        fromDate
        toDate
        vatNormal
        vatReduced
        vatSpecial1
        vatSpecial2
        vatZero
      }
    }
  }
`;
