import { ReactElement, ReactNode } from 'react';
import { ButtonProps } from '@mui/material';
import { FormProvider, useForm, UseFormProps } from 'react-hook-form';
import { FilterContent } from './filterContent.tsx';

export const SideFilter = <T,>({ children, ...props }: ISideFilter<T>): ReactElement => {
  const formMethods = useForm({
    values: props.initValues as UseFormProps['defaultValues'],
  });

  return (
    <FormProvider {...formMethods}>
      <FilterContent onSubmit={formMethods.handleSubmit} {...props}>
        {children}
      </FilterContent>
    </FormProvider>
  );
};

export interface ISideFilter<T> {
  filterBtnProps?: IButtonProps;
  confirmBtnProps?: IButtonProps;
  minWidthMenu?: number;
  maxMediaHeight?: number;
  clearEmpty?: boolean /* This prop is responsible for removing fields with null and undefined values from the output object. */;
  onFilter: (v: T) => void;
  onClose?: () => void /* This prop useful when u need to do something when filter was not approved */;
  initValues?: T;
  defaultFilterSettings?: T /* This prop is intended to determine the state of the filter relative to its initial settings. */;
  ignoreDefaultFields?: string[] /* This prop specifies which fields are not involved in defining the default settings. */;
  children: ReactNode | undefined;
  navigationLockFn?: (handlers: () => void) => void;
}

interface IButtonProps extends ButtonProps {
  label?: string;
  icon?: ReactNode;
}
