import {
  createContext,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  useContext,
  useLayoutEffect,
  useRef,
} from 'react';
import {
  autocompleteClasses,
  Popper,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { ListChildComponentProps, VariableSizeList } from 'react-window';

const LISTBOX_PADDING = 8;

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const [liProps, option, datasetattribute] = data[index];
  const inlineStyle = {
    ...style,
    ...liProps.style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  switch (datasetattribute && datasetattribute) {
    case 'productEditSelect':
      return (
        <Typography
          title={option?.label}
          component='li'
          {...liProps}
          datatype='productEditSelect'
          noWrap
          key={option.id}
          style={inlineStyle}
        >
          <span datatype='productEditSelect'>
            <b>{option?.articleNo} </b>
            {option?.label}
          </span>
        </Typography>
      );
    case 'customerOptions':
      return (
        <Typography
          title={option?.label}
          component='li'
          {...liProps}
          datatype='customerOptions'
          noWrap
          key={option.id}
          style={inlineStyle}
        >
          <span datatype='customerOptions'>
            <b>{option?.customerNo} </b>
            {option?.label}
          </span>
        </Typography>
      );
    case 'articleOptions':
      return (
        <Typography
          title={option?.label}
          component='li'
          {...liProps}
          datatype='articleOptions'
          noWrap
          key={option.id}
          style={inlineStyle}
        >
          <span datatype='articleOptions'>
            <b>{option?.articleNo} </b>
            {option?.label}
          </span>
        </Typography>
      );
    case 'customersOptions':
      return (
        <Typography
          title={option?.label}
          component='li'
          {...liProps}
          datatype='customersOptions'
          noWrap
          key={option.id}
          style={inlineStyle}
        >
          <span datatype='customersOptions'>
            <b>{option?.customerNo} </b>
            {option?.internalOrFullName}
          </span>
        </Typography>
      );
    case 'productionRecipeOptions':
      return (
        <>
          <Typography
            title={option?.label}
            component='li'
            {...liProps}
            noWrap
            key={`${option.id}_${option.label}`}
            style={inlineStyle}
          >
            <SLabelWrapper
              subTitle={!!(option?.connectedArticleNo || option?.connectedArticleName)}
            >
              <span>
                <b>{option?.itemNo} </b>
                {option?.label}
              </span>
              <span style={{ fontSize: '9px' }}>
                <b>{option?.connectedArticleNo} </b>
                {option?.connectedArticleName}
              </span>
            </SLabelWrapper>
          </Typography>
        </>
      );
    case 'recipes':
      return (
        <Typography
          title={option?.label}
          component='li'
          {...liProps}
          noWrap
          key={option.id}
          style={inlineStyle}
        >
          <span>
            <b>{option?.recipeNo} </b>
            {option?.label}
          </span>
        </Typography>
      );
    default:
      return (
        <Typography
          title={option?.label}
          component='li'
          {...liProps}
          noWrap
          key={option.id}
          style={inlineStyle}
        >
          {option?.label}
        </Typography>
      );
  }
}

function useGridRef(itemCount: number, selectedIndex: number) {
  const ref = useRef<VariableSizeList>(null);
  useLayoutEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
      ref.current.scrollToItem(selectedIndex, 'center');
    }
  }, [itemCount]);
  return ref;
}

// Outer Element
const OuterElementContext = createContext({});
const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const ListboxComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(
  function ListboxComponent({ children, ...other }, ref) {
    const itemData: ReactElement[] = [];
    (children as ReactElement[]).forEach((item: ReactElement & { children?: ReactElement[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
      noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;
    const itemsSelectedIndex = itemData?.findIndex((item: any) => item[0]?.['aria-selected']) || 0;
    const gridRef = useGridRef(itemCount, itemsSelectedIndex);

    const getChildSize = (__child: ReactElement) => itemSize;
    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width='100%'
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType='ul'
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

const SLabelWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'subTitle',
})<{ subTitle: boolean }>(({ subTitle }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  ...(subTitle && { transform: 'translateY(-5px)' }),
  '& > span': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export { ListboxComponent, StyledPopper };
