import { C_Order_Type } from '../../../../../graphql/generatedModel.ts';
import { TypeTable } from '../../../../../shared/components/table/table.component.tsx';
import { Theme as theme } from '../../../../../shared/styles/theme/theme.ts';

export const orderListRow: TypeTable['viewRows'] = {
  action: ({ row }) => {
    if (row.isDeleted) {
      return 'deleted';
    }
    if (row.isInvoiced) {
      return 'invoiced';
    }
    if (row.orderType === C_Order_Type.OT7_INTERNET_ORDER) {
      return 'internet_order';
    }
    if (row.orderType === C_Order_Type.OT8_HOGASHOP) {
      return 'hoogashop';
    }
    if (isChashAssistOrder(row.orderType)) {
      return 'cashassist';
    }
    return '';
  },
  styles: [
    {
      className: 'deleted',
      bgColor: theme.palette.red.light,
      bgColorHover: theme.palette.red.main,
    },
    {
      className: 'invoiced',
      bgColor: theme.palette.lightBlue.light,
      bgColorHover: theme.palette.lightBlue.dark,
    },
    {
      className: 'internet_order',
      bgColor: theme.palette.deepPurple.light,
      bgColorHover: theme.palette.deepPurple.main,
    },
    {
      className: 'hoogashop',
      bgColor: theme.palette.teal.light,
      bgColorHover: theme.palette.teal.main,
    },
    {
      className: 'cashassist',
      bgColor: theme.palette.lightBrown.light,
      bgColorHover: theme.palette.lightBrown.main,
    },
  ],
};

export const isChashAssistOrder = (orderType: C_Order_Type): boolean => {
  return (
    orderType === C_Order_Type.OT11_CASHASSIST_ORDER ||
    orderType === C_Order_Type.OT12_CASHASSIST_REDISCOUNT ||
    orderType === C_Order_Type.OT13_CASHASSIST_OUTPUT ||
    orderType === C_Order_Type.OT14_CASHASSIST_RETURN ||
    orderType === C_Order_Type.OT15_CASHASSIST_TRANSFER ||
    orderType === C_Order_Type.OT16_CASHASSIST_ADDITIONAL_ORDER ||
    orderType === C_Order_Type.OT20_CASHASSIST_CUSTOMER_ORDER ||
    orderType === C_Order_Type.OT21_CASHASSIST_INVOICE ||
    orderType === C_Order_Type.OT22_CASHASSIST_CREDIT_CUSTOMER
  );
};
