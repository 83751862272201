import { finalize, map, Observable, of, switchMap, take, tap, zipWith } from 'rxjs';
import {
  C_Order_Type,
  GetWa_ArticleDataAsOrderPositionQuery as ProductDataQuery,
  GetWa_ArticleDataAsOrderPositionQueryVariables as ProductDataVariables,
  GetWa_ArticleOrderPriceAndDiscountQuery as ProductOrderPriceAndDiscountQuery,
  GetWa_ArticleOrderPriceAndDiscountQueryVariables as ProductPriceAndDiscountVariables,
  GetWa_OrderDeliveryCostQuery as GetOrderDeliveryCostQuery,
  GetWa_OrderDeliveryCostQueryVariables as GetOrderDeliveryCostQueryVariables,
  ListSpecialAssortmentGroupsOfCustomerQuery,
  ListSpecialAssortmentGroupsOfCustomerQueryVariables as ListSpecialAssortmentGroups,
  ListWa_ActualTaxsQuery as ListTaxesQuery,
  ListWa_ActualTaxsQueryVariables as ListTaxesVariables,
  ListWa_SpecialAvailabilityStateForArticlesSelectedInOrderPositionQuery,
  ListWa_SpecialAvailabilityStateForArticlesSelectedInOrderPositionQueryVariables as ListSpecialAvailabilityState,
} from '../../../graphql/generatedModel.ts';
import { gqlClient } from '../../../graphql/graphqlRequest.ts';
import { localeFormatterHelper } from '../../../shared/helpers/formatter/localeFormatter.helper.ts';
import { companyConfigService } from '../../../shared/services/companyConfig/companyConfig.service.ts';
import {
  ArticlesForOrderPositionListRes,
  configsData,
} from '../../../shared/services/configsData/configsData.service.ts';
import { Pub, Service, Sub } from '../../../shared/services/service.abstract.ts';
import {
  getArticleDataAsOrderPosition,
  getArticlePriceAndDiscount,
  getOrderDeliveryCost,
  getTaxesByDate,
  listSpecialAssortmentGroupsOfCustomer,
  listSpecialAvailabilityState,
} from './gql/order.gql.ts';
import { responseHandler } from '../../../shared/responseHandler/responseHandler.ts';

type Actions =
  | 'filteredProductList'
  | 'articleData'
  | 'taxes'
  | 'productPriceAndDiscount'
  | 'orderDeliveryCost'
  | undefined;
class PubImpl extends Pub<Actions> {
  clearStream() {
    this.emit(undefined, {});
  }
  filteredProductData(params: IFilteredProductListParams) {
    this.emit('filteredProductList', params);
  }
  productData(params: ProductDataVariables) {
    this.emit('articleData', params);
  }
  taxes(params: ListTaxesVariables): void {
    this.emit('taxes', params);
  }
  productPriceAndDiscount(params: ProductPriceAndDiscountVariables): void {
    this.emit('productPriceAndDiscount', params);
  }
  orderDeliveryCost(params: GetOrderDeliveryCostQueryVariables): void {
    this.emit('orderDeliveryCost', params);
  }
}
class SubImpl extends Sub<Actions> {
  filteredProductData(): Observable<IFilteredProductDataRes> {
    return this.actionListener('filteredProductList').pipe(
      tap(() => {
        configsData.pub.common(['articlesForOrderPositionList']);
      }),
      switchMap(
        ({
          params: {
            orderType,
            customerId,
            date,
            listSpecialAssortmentGroups,
            listSpecialAvailabilityStateForArticles,
            isDateChanged = true,
          },
        }) => {
          return configsData.sub.articlesForOrderPositionList().pipe(
            zipWith(
              (customerId || !listSpecialAssortmentGroups
                ? gqlClient(listSpecialAssortmentGroupsOfCustomer, {
                    customerId,
                  })
                : of(
                    listSpecialAssortmentGroups,
                  )) as Observable<ListSpecialAssortmentGroupsOfCustomerQuery>,
              (isDateChanged || !listSpecialAvailabilityStateForArticles
                ? gqlClient(listSpecialAvailabilityState, {
                    onDate: date,
                  })
                : of(
                    listSpecialAvailabilityStateForArticles,
                  )) as Observable<ListWa_SpecialAvailabilityStateForArticlesSelectedInOrderPositionQuery>,
            ),
            responseHandler<
              [
                ArticlesForOrderPositionListRes,
                ListSpecialAssortmentGroupsOfCustomerQuery,
                ListWa_SpecialAvailabilityStateForArticlesSelectedInOrderPositionQuery,
              ]
            >({ errorReturnType: [[], {}, {}] }),
            map(([products, listSpecialAssortment, listSpecialAvailabilityState]) => {
              const listSpecialAssortmentGroups =
                listSpecialAssortment?.wawiAssist
                  ?.listWA_SpecialAssortmentGroupsOfCustomerForSelectInOrderPosition ||
                (listSpecialAssortment as []);
              const listSpecialAvailabilityStateForArticles =
                listSpecialAvailabilityState?.wawiAssist
                  ?.listWA_SpecialAvailabilityStateForArticlesSelectedInOrderPosition ||
                (listSpecialAvailabilityState as []);

              const filterActive = orderType !== C_Order_Type.OT4_RETURN;
              const filteredProducts = products.filter((item) => {
                const passByActive = filterActive ? item?.isActive : true;
                if (!(!item?.isDough && passByActive)) return false;

                let passByAssortment = true;
                let passByDate: boolean;
                if (item?.specialAssortmentGroups.length && listSpecialAssortmentGroups?.length) {
                  passByAssortment = item.specialAssortmentGroups.some((v) =>
                    listSpecialAssortmentGroups.includes(v),
                  );
                }
                const dateValue: boolean | undefined =
                  listSpecialAvailabilityStateForArticles?.find(
                    (el) => el?.articleId === item?.id,
                  )?.isDelivered;

                if (typeof dateValue === 'boolean') {
                  passByDate = dateValue;
                } else {
                  // Filter by deliverySunday...deliverySaturday flags in case article doesn't exist in dateMapExclude
                  const deliveryDays = [
                    item?.isDeliveredSun,
                    item?.isDeliveredMon,
                    item?.isDeliveredTue,
                    item?.isDeliveredWed,
                    item?.isDeliveredThu,
                    item?.isDeliveredFri,
                    item?.isDeliveredSat,
                  ];
                  const valueByDay = deliveryDays[new Date(date).getDay()];
                  passByDate = typeof valueByDay === 'boolean' ? valueByDay : true;
                }
                return passByAssortment && passByDate;
              });
              return {
                productList: filteredProducts,
                listSpecialAssortmentGroups,
                listSpecialAvailabilityStateForArticles,
              };
            }),
            finalize(() => orderService.pub.clearStream()),
          );
        },
      ),
    );
  }
  productData(): Observable<ProductDataRes> {
    return this.actionListener('articleData').pipe(
      take(1),
      switchMap(({ params }) => gqlClient(getArticleDataAsOrderPosition, params)),
      map((data) => data.wawiAssist?.getWA_ArticleDataAsOrderPosition),
      finalize(() => orderService.pub.clearStream()),
    );
  }
  taxes(): Observable<TaxesByDateRes> {
    return this.actionListener('taxes').pipe(
      switchMap(({ params }) => {
        return gqlClient(getTaxesByDate, params) as Observable<ListTaxesQuery>;
      }),
      map((data: ListTaxesQuery) => {
        const precision = companyConfigService.getCachedConfigs()?.decimalPlacesCount || 2;
        const result = (data?.wawiAssist?.listWA_ActualTaxs || [])?.map((item) => ({
          ...item,
          label:
            localeFormatterHelper.formatNumber(Number.parseFloat(item?.label || ''), {
              precision,
              noTrailingZeros: true,
            }) + '%',
        }));
        return result as TaxesByDateRes;
      }),
      finalize(() => orderService.pub.clearStream()),
    );
  }
  articlePriceAndDiscount(): Observable<ProductPriceAndDiscountRes> {
    return this.actionListener('productPriceAndDiscount').pipe(
      take(1),
      switchMap(({ params }) => gqlClient(getArticlePriceAndDiscount, params)),
      map((data) => data.wawiAssist?.getWA_ArticleOrderPriceAndDiscount),
      finalize(() => orderService.pub.clearStream()),
    );
  }
  orderDeliveryCost(): Observable<OrderDeliveryCostRes> {
    return this.actionListener('orderDeliveryCost').pipe(
      take(1),
      switchMap(({ params }) => {
        return gqlClient(getOrderDeliveryCost, params) as Observable<GetOrderDeliveryCostQuery>;
      }),
      map((data: GetOrderDeliveryCostQuery) => {
        return data.wawiAssist?.getWA_OrderDeliveryCost as OrderDeliveryCostRes;
      }),
    );
  }
}

class OrderService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}
export const orderService = new OrderService();

interface IFilteredProductListParams {
  orderType: C_Order_Type;
  date: ListSpecialAvailabilityState['onDate'];
  isDateChanged?: boolean; // trigger for call the listSpecialAvailabilityState query
  customerId?: ListSpecialAssortmentGroups['customerId'];
  listSpecialAssortmentGroups?: ListSpecialAssortmentGroupsRes;
  listSpecialAvailabilityStateForArticles?: ListSpecialAvailabilityStateForArticlesRes;
}

export interface IFilteredProductDataRes {
  productList: ArticlesForOrderPositionListRes;
  listSpecialAssortmentGroups?: ListSpecialAssortmentGroupsRes;
  listSpecialAvailabilityStateForArticles?: ListSpecialAvailabilityStateForArticlesRes;
}

export type ListSpecialAssortmentGroupsRes = NonNullable<
  NonNullable<
    ListSpecialAssortmentGroupsOfCustomerQuery['wawiAssist']
  >['listWA_SpecialAssortmentGroupsOfCustomerForSelectInOrderPosition']
>;
export type ListSpecialAvailabilityStateForArticlesRes = NonNullable<
  NonNullable<
    ListWa_SpecialAvailabilityStateForArticlesSelectedInOrderPositionQuery['wawiAssist']
  >['listWA_SpecialAvailabilityStateForArticlesSelectedInOrderPosition']
>;

export type ProductDataRes = NonNullable<
  NonNullable<ProductDataQuery['wawiAssist']>['getWA_ArticleDataAsOrderPosition']
>;

export type TaxesByDateRes = NonNullable<
  NonNullable<ListTaxesQuery>['wawiAssist']
>['listWA_ActualTaxs'];

export type ProductPriceAndDiscountRes = NonNullable<
  NonNullable<ProductOrderPriceAndDiscountQuery['wawiAssist']>['getWA_ArticleOrderPriceAndDiscount']
>;

export type OrderDeliveryCostRes = NonNullable<
  NonNullable<GetOrderDeliveryCostQuery['wawiAssist']>['getWA_OrderDeliveryCost']
>;
