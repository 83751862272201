/* eslint-disable react-hooks/rules-of-hooks */
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { Popup, TPopupComponent } from '../../../../shared/components/popup/popup.abstract';
import { TPopupDefaultActions } from '../../../../shared/components/popup/services/popup.service';
import { Box, Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

export class ConfirmCreationPopup extends Popup<IConfirmPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const [params, setParams] = useState<Pick<IConfirmPopup, 'content' | 'onConfirm'>>({});
      const { t } = useTranslation();
      useEffect(() => {
        stream.actionListener('open').subscribe((v) => setParams(v));
      }, []);
      const Wrapper = useMemo(() => this.Wrapper, []);

      return (
        <Wrapper stream={stream} popupTitle={t('order.create_order')}>
          <Box typography='body1'>{params.content || ''}</Box>
          <Stack direction='row' spacing={2} mt={2} justifyContent='flex-end'>
            <Button
              onClick={() => {
                params.onConfirm?.();
                stream.emit('close');
              }}
              variant='contained'
            >
              {t('common.confirm')}
            </Button>
          </Stack>
        </Wrapper>
      );
    };
  }
}

interface IConfirmPopup {
  action: 'confirm' | TPopupDefaultActions;
  content?: ReactNode;
  onConfirm?: () => void;
}
