import { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { ITabState } from '../../../states/tabState.model.ts';
import { CustomerCashAssistDataRes } from '../../../services/customer.service.ts';
import { Content } from '../../../../../shared/components/content/content.component.tsx';
import { Stack } from '@mui/material';
import {
  FormCheckbox,
  FormNumericField,
  FormSelect,
} from '../../../../../shared/components/form/index.ts';
import { cashAssistTabState } from './cashAssist.state.ts';
import { C_Discounts_And_Prices_Kind } from '../../../../../graphql/generatedModel.ts';

export const CashAssist: FC = () => {
  const { defaultValues } = useLoaderData() as ITabState;
  const { t } = useTranslation();
  const formMethods = useForm<CustomerCashAssistDataRes>({
    mode: 'onChange',
    defaultValues,
  });
  const {
    formState: { errors, dirtyFields },
    getValues,
    reset,
    resetField,
    watch,
  } = formMethods;

  useEffect(() => {
    const unsubState = cashAssistTabState.sub
      .state()
      .subscribe(({ action, dataToSave, defaultValues }) => {
        if (action === 'save') {
          for (const key in dataToSave?.data) {
            const fieldName = key as keyof CustomerCashAssistDataRes;
            const value = dataToSave.data[key as keyof typeof dataToSave.data];
            resetField(fieldName, { defaultValue: value, keepDirty: false });
          }
        }
        if (action === 'list.selectedRecord') {
          reset(defaultValues);
        }
      });
    return () => {
      unsubState.unsubscribe();
    };
  }, []);

  const handleSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    const fieldName = e.target.name as keyof CustomerCashAssistDataRes;
    if (!errors[fieldName] && !!dirtyFields[fieldName]) {
      const values = getValues();
      cashAssistTabState.pub.save({ data: { id: values.id, [fieldName]: values[fieldName] } });
    }
  };

  const priceDiscountOptions = useMemo(() => {
    return Object.entries(C_Discounts_And_Prices_Kind).map(([key, value]) => ({
      id: value,
      label: t(`enums.${key}`),
    }));
  }, [t]);

  const isSpecialDiscountVisible =
    watch('caPriceAndDiscountSourceId') ===
    C_Discounts_And_Prices_Kind.DNP6_DISCOUNT_SPECIAL_PRICE_CASH;

  return (
    <>
      {Object.keys(getValues()).length ? (
        <FormProvider {...formMethods}>
          <form onBlur={handleSubmit}>
            <Stack alignItems='start' spacing={2}>
              <FormCheckbox
                name='isCashAssistCreditCustomer'
                label={t('customer.canBuyOnCreditOnCashAssist')}
              />
              <FormSelect
                name='caPriceAndDiscountSourceId'
                options={priceDiscountOptions}
                label={t('customer.takeReductionsAndPricesFrom')}
                disableClearable
              />
              {isSpecialDiscountVisible && (
                <FormNumericField
                  name='caSpecialDiscount'
                  label={t('customer.specialDsicountCashAssist')}
                  precision={2}
                  controls={false}
                />
              )}
            </Stack>
          </form>
        </FormProvider>
      ) : (
        <Content
          type='paper'
          paperProps={{
            variant: 'elevation',
            sx: {
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              height: '100%',
            },
          }}
          emptyData={{
            value: true,
            title: t('common.no_data'),
          }}
        />
      )}
    </>
  );
};
