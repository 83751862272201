import { Button, Stack } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import CustomerIcon from '@mui/icons-material/Person';
import { useTranslation } from 'react-i18next';
import { TableHeader } from './tableHeader.component';
import { storageHelper } from '../../../shared/helpers/storage';
import { Table } from '../../../shared/components/table/table.component';
import { navigateService } from '../../../shared/services/navigate/navigate.service';
import { globalSearchBuildUrl } from '../../../shared/components/globalSearch/globalSearchBuildUrl.util';
import { Column } from '../../../shared/components/table/components/common.components';
import { PerformGlobalSearchRes } from '../services/globalSearch.service';
import { searchState } from '../../../shared/components/globalSearch/states/search.state';

export const CustomerTable: FC<ICustomerTable> = ({ data }) => {
  const { t } = useTranslation();
  const lessThanSixCustomers = data?.length < 6;
  const [isAllDataShown, setIsAllDataShown] = useState(lessThanSixCustomers);

  const handleShowAll = () => {
    setIsAllDataShown(true);
  };

  const icon = <CustomerIcon color='primary' fontSize='small' />;
  const tableData = useMemo(() => {
    const firstFiveCustomers = data?.slice(0, 5);
    const dataForShow = isAllDataShown ? data : firstFiveCustomers;
    return dataForShow;
  }, [data, isAllDataShown]);
  let height = isAllDataShown ? 198 + 32 * (data?.length - 5) : 198;
  if (lessThanSixCustomers) {
    height = 198;
  }

  useEffect(() => {
    const lessThanSixCustomers = data?.length < 6;
    setIsAllDataShown(lessThanSixCustomers);
  }, [data]);

  return (
    <>
      {tableData?.length ? (
        <Stack>
          <TableHeader
            icon={icon}
            title={t('customer.customer')}
            dataLength={data?.length}
            isAllDataShown={isAllDataShown}
          />
          <Table
            data={(tableData as []) || []}
            initialState={{
              columns: storageHelper.local.getItem(
                'globalSearch.customerTable.positionColumnModel',
              ),
            }}
            height={height}
            onChangeColumnModel={(v) =>
              storageHelper.local.setItem('globalSearch.customerTable.positionColumnModel', v)
            }
            onRowClick={(e) => {
              if (e?.row?.id) {
                navigateService.pub.navigateTo(globalSearchBuildUrl('customer', e.row!));
                searchState.pub.resetSearch();
              }
            }}
          >
            <Column
              field='customerNo'
              headerName={t('common.customer_no')}
              width={150}
              sortable={false}
            />
            <Column
              field='internalOrFullName'
              headerName={t('common.name')}
              width={100}
              flex={1}
              sortable={false}
            />
          </Table>
          {!isAllDataShown && (
            <Button
              onClick={handleShowAll}
              variant='outlined'
              size='small'
              sx={{ width: '200px', marginTop: '16px', margin: '16px auto 0 auto' }}
            >
              {t('common.show_all')}
            </Button>
          )}
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
};

interface ICustomerTable {
  data: PerformGlobalSearchRes['customers'];
}
