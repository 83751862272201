import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { generateWeekShortTitles } from '../../../../../../../../../../shared/helpers/utils/utils.helper';
import { useTranslation } from 'react-i18next';
import {
  PeriodConditions,
  WeekDaysFlags,
  WeekDays as wd,
} from '../../../../../../../../services/customer.service';

export const WeekDays: FC<IWeekDays> = ({ data, setData }) => {
  return (
    <>
      <DayBoxes data={data} setData={setData} />
    </>
  );
};

export const DayBoxes: FC<IDayBoxes> = ({ data, setData, width }) => {
  const [t] = useTranslation();

  const weekShortTitles = generateWeekShortTitles(t);
  const dayBoxes = weekShortTitles.map(({ key, shortTitle }) => {
    return (
      <ToggleButton
        value={key}
        key={key}
        sx={{ flex: width ? 0 : 1, height: '48px', minWidth: width ? width : 'auto' }}
      >
        {shortTitle}
      </ToggleButton>
    );
  });

  return (
    <>
      <ToggleButtonGroup
        value={convertDaysObjToArray(data) || []}
        onChange={(_e, wdArr) => {
          setData((prev: { weekDays: WeekDaysFlags }) => ({
            ...prev,
            weekDays: convertDaysArrayToObj(wdArr, prev?.weekDays),
          }));
        }}
        sx={{ marginBottom: '24px', width: '100%' }}
      >
        {dayBoxes}
      </ToggleButtonGroup>
    </>
  );
};

const convertDaysArrayToObj = (
  wdArr: wd[],
  wdObj: PeriodConditions['weekDays'],
): PeriodConditions['weekDays'] => {
  const newWdObj = {} as PeriodConditions['weekDays'];
  for (const day in wdObj) {
    const isActive = !!wdArr.find((d) => d === day);
    newWdObj[day as keyof PeriodConditions['weekDays']] = isActive;
  }
  return newWdObj;
};

const convertDaysObjToArray = (wdObj: PeriodConditions['weekDays']): wd[] => {
  const wdArr = [] as wd[];
  for (const day in wdObj) {
    const d = day as keyof PeriodConditions['weekDays'];
    if (wdObj[d]) {
      wdArr.push(d);
    }
  }
  return wdArr;
};

interface IDayBoxes {
  data: PeriodConditions['weekDays'];
  setData: Dispatch<SetStateAction<any>>;
  width?: string;
}

interface IWeekDays {
  data: PeriodConditions['weekDays'];
  setData: Dispatch<SetStateAction<PeriodConditions>>;
}
