import React, { FC } from 'react';
/* Icons */
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { IIndividualDescriptionPopup } from '../../../popups/individualDescription/individualDescription.popup.tsx';
import { useTranslation } from 'react-i18next';
import { Popup } from '../../../../../../shared/components/popup/popup.abstract.tsx';
import { localeFormatterHelper } from '../../../../../../shared/helpers/formatter/localeFormatter.helper.ts';

export const ProductCell: FC<IProductCell> = ({
  params,
  popupStream,
  loading,
  isEditable = true,
  isPriceRateFixed,
}) => {
  const { t } = useTranslation();
  const { row, id, field, value } = params;
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    popupStream.emit('open', { row });
  };

  const editedData = !(
    (row?.indTextDeliveryNote === value || !row?.indTextDeliveryNote) &&
    (row?.indTextProduction === value || !row?.indTextProduction)
  );

  return (
    <SStack stardisplay={editedData ? 'block' : 'none'}>
      {!row.virtualPositionId ? (
        <>
          <SValue dangerouslySetInnerHTML={{ __html: row?.indTextDeliveryNote || value }} />
          {!loading.includes(`${id}_${field}`) ? (
            <IconButton
              tabIndex={-1}
              title={t('order.individual_description')}
              onClick={handleClick}
              sx={{ p: '3.5px' }}
              color={editedData ? 'primary' : 'default'}
              size='small'
              disabled={!isEditable || !row.isChangeable}
            >
              <StarOutlineIcon className='starIcon' />
            </IconButton>
          ) : (
            <SBox>
              <CircularProgress size={16} thickness={5} />
            </SBox>
          )}
        </>
      ) : (
        <>
          {t(isPriceRateFixed ? 'order.shipping_cost_fixed' : 'order.shipping_cost', {
            weight: localeFormatterHelper.formatNumber(row.weight / 1000, {
              noTrailingZeros: true,
              precision: 3,
            }),
          })}
        </>
      )}
    </SStack>
  );
};

export const SStack = styled(Stack)<{ stardisplay?: string }>(({ theme, stardisplay }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '100%',
  flexGrow: 1,
  position: 'relative',
  '.MuiCircularProgress-svg': {
    color: theme.palette.grey['600'],
  },
  '.starIcon': {
    display: stardisplay,
  },
}));

export const SValue = styled('span')(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const SBox = styled(Box)(() => ({
  display: 'flex',
  padding: '3.5px',
  width: '31px',
  justifyContent: 'center',
}));

interface IProductCell {
  params: GridRenderCellParams;
  loading: string[];
  popupStream: Popup<IIndividualDescriptionPopup>['stream'];
  isEditable?: boolean;
  isPriceRateFixed: boolean;
}
