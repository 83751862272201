import { gql } from 'graphql-request';

export const getExportLabelAssist = gql`
  mutation getExportLabelAssist($productionPrintListId: ID!, $onDate: Date!) {
    wawiAssist {
      prepareLabelAssistExport(productionPrintListId: $productionPrintListId, onDate: $onDate)
    }
  }
`;

export const getExportSmartScale = gql`
  mutation getExportSmartScale($productionPrintListId: ID!, $onDate: Date!) {
    wawiAssist {
      prepareSmartScaleExport(productionPrintListId: $productionPrintListId, onDate: $onDate)
    }
  }
`;
