import * as yup from 'yup';

export const createValidationSchema = (): yup.AnyObjectSchema => {
  return yup.object({
    fromDate: yup
      .date()
      .nullable()
      .test({
        name: 'fromDate-validation',
        test: (value, allData) => {
          const { createError, path, parent } = allData;
          const noSelectedDate = !value && !parent?.toDate;
          if (noSelectedDate) {
            return createError({
              path,
              message: 'article.from_date_or_to_date_should_be_selected',
            });
          }
          return true;
        },
      }),
    price1: yup
      .number()
      .nullable()
      .test({
        name: 'price-validation',
        test: (value, allData) => {
          const { createError, path, parent } = allData;
          const { price2, price3, price4, price5, price6, price7, price8, price9, price10 } =
            parent || {};
          const noPrice =
            !value &&
            !price2 &&
            !price3 &&
            !price4 &&
            !price5 &&
            !price6 &&
            !price7 &&
            !price8 &&
            !price9 &&
            !price10;
          if (noPrice) {
            return createError({
              path,
              message: 'article.at_least_one_price_category_should_be_not_empty',
            });
          }
          return true;
        },
      }),
  });
};
