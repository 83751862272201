/* eslint-disable no-self-assign */
import { captureMessage } from '@sentry/react';
import { catchError, map, Observable, of, pipe } from 'rxjs';
import { C_Global_Error_Code } from '../../graphql/generatedModel';
import i18n from '../../i18n/init';
import { snackbarService } from '../components/snackbar/service/snackbar.service';

export const handler = <B>(res: IErrorsHandler<B>) => {
  const { error, success, errorReturnType, quite = false } = res;
  const data = res.data as IApiError;
  let message: string | undefined = undefined;

  if (data.issuer) {
    if (typeof error === 'function') {
      message = error(data);
    }

    if (message === undefined) {
      if (data.code && C_Global_Error_Code[data.code as C_Global_Error_Code]) {
        message = `errors.${data.code}`;
      } else {
        switch (data.status) {
          case 500:
            message = 'errors.unknown';
            break;
          default:
            message = 'errors.unknown';
            break;
        }
      }
    }

    if (!quite && message) {
      const isShowReload = data.code === C_Global_Error_Code.GEC50_DB_IS_IN_UPDATING_PROCCES;
      message = message;
      snackbarService.pub.show({
        ...(isShowReload && { showReload: true }),
        preventDuplicate: true,
        content: i18n.t(message),
        noAutoHide: true,
        type: 'error',
      });
    }

    return errorReturnType as B;
  }

  if (typeof success === 'function') {
    if ((message = success(data as B))) {
      snackbarService.pub.show({
        content: i18n.t(message),
        type: 'success',
      });
    }
  }
  return data as B;
};

const errorHandlerPipe = pipe(
  map<any, any>((res) => {
    const { errors } = res;
    if (errors) {
      return {
        issuer: 'graphQl',
        // Need review graphql errors
        status: 500,
        message: errors[0].message,
        code: errors[0].extensions.code,
        invalidArgs: errors[0].extensions.invalidArgs,
        linkedRecords: errors[0].extensions.linkedRecords,
      } as IApiError;
    }
    return res;
  }),
  catchError<any, any>(({ message, response = {} }) => {
    const { errors, status } = response;
    const model: IApiError = {
      issuer: 'catchError',
      status,
      message,
    };
    if (errors) {
      model.code = errors[0].extensions.code?.toString?.();
      model.message = errors[0].message.replace(/requestid.+?[',"].+?[',"]/im, '');
      model.invalidArgs = errors[0].extensions.invalidArgs;
      model.linkedRecords = errors[0].extensions.linkedRecords;
    }
    captureMessage(model.message || 'unknown', {
      level: 'error',
      extra: { details: model },
    });
    return of(model);
  }),
);

export const responseHandler = <T = any>(variables?: IServiceErrorHandler<T>) => {
  return (src: Observable<T>) => {
    return src.pipe(
      errorHandlerPipe,
      map((data) => {
        return handler<T>({
          data,
          error: variables?.customErrorHandler,
          success: variables?.success,
          errorReturnType: variables?.errorReturnType,
          quite: variables?.quite,
        });
      }),
    );
  };
};

interface IServiceErrorHandler<T = unknown> {
  customErrorHandler?: IErrorsHandler<T>['error'];
  success?: IErrorsHandler<T>['success'];
  errorReturnType?: IErrorsHandler<T>['errorReturnType'];
  quite?: IErrorsHandler<T>['quite'];
}

type LocalizationKey = string;

export interface IErrorsHandler<H> {
  data: any;
  error?: (err: IApiError) => LocalizationKey | undefined;
  success?: (data: H) => LocalizationKey | undefined;
  errorReturnType?: H;
  quite?: boolean;
}
export interface IApiError {
  issuer?: 'catchError' | 'graphQl';
  status?: number;
  code?: string;
  message?: string;
  linkedRecords?: string;
  invalidArgs?: string[];
}
