import { ComponentType, FC, FunctionComponent, LazyExoticComponent, Suspense } from 'react';
import { LoaderFunction, LoaderFunctionArgs } from 'react-router-dom';
import {
  bufferTime,
  concatMap,
  filter,
  firstValueFrom,
  Observable,
  Subject,
  take,
  tap,
} from 'rxjs';
import { moduleErrorBoundary as defaultErrorBoundary } from '../shared/components/errorBoundaries/moduleErrorBoundary.component';
import { Auth, authService } from '../shared/services/auth/auth.service';
import { companyConfigService } from '../shared/services/companyConfig/companyConfig.service';
import { IProtectedRoute, ProtectedRoute } from './protected.route';
import { isBrowserOutdated } from '../shared/helpers/browsersSupport/browserSupport.helper';
import { redirectIfProduction } from './helper.routes.ts';

class LazyUtil {
  private config: ILazyConfig = {};
  private taskSubject = new Subject<ITaskSubject>();
  private taskObservable: Observable<void>;
  private protectedRoute: FunctionComponent<IProtectedRoute> = ProtectedRoute;
  private queueLength = 0;
  private parentInitData: any;
  constructor() {
    this.taskObservable = this.taskSubject.pipe(
      bufferTime(5), // collect tasks within 5 ms
      filter((tasks) => !!tasks.length),
      tap((tasks) => tasks.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))),
      concatMap((tasks) => tasks),
      concatMap((args) => this.executeTask(args)),
    );

    this.taskObservable.subscribe(); // starts queue processing
  }

  component(lazyComponent: ILazyConfig['lazyComponent']): LazyUtil {
    this.config!.lazyComponent = lazyComponent;
    return this;
  }
  loader(fn: ILazyConfig['loaderFn'], options?: { order?: number }): LazyUtil {
    const order = options?.order || 1;
    this.config!.loaderFn = async (args: LoaderFunctionArgs) => {
      const { status } = await firstValueFrom(authService.sub.isLoggedIn());
      if (Auth.loggedIn !== status) {
        return redirectIfProduction({
          fallbackHandler: () => {
            window.location.replace('/login');
            return false;
          },
        });
      } else {
        // add a task to the queue
        this.queueLength++;
        return new Promise<any>((resolve, reject) => {
          this.taskSubject.next({ args, fn, resolve, reject, order });
        });
      }
    };
    return this;
  }

  private executeTask = async ({ args, fn, resolve, reject }: ITaskSubject) => {
    try {
      await firstValueFrom(companyConfigService.getConfigs().pipe(take(1)));
      const data = await fn?.({
        ...args,
        ...(this.parentInitData && {
          context: { parentInitData: this.parentInitData },
        }),
      });

      if (this.queueLength > 1) {
        this.parentInitData = data;
      } else this.parentInitData = null;
      resolve(data);
    } catch (error) {
      reject(error);
    } finally {
      this.queueLength--;
    }
  };

  protected(customProtectedRoute?: FunctionComponent<any>): LazyUtil {
    if (customProtectedRoute) {
      this.config!.protected = customProtectedRoute;
    } else {
      this.config!.protected = this.protectedRoute;
    }
    return this;
  }

  errorBoundary(errorBoundaryComponent?: FunctionComponent): LazyUtil {
    if (errorBoundaryComponent) {
      this.config.errorBoundary = errorBoundaryComponent;
    } else {
      this.config.errorBoundary = defaultErrorBoundary;
    }
    return this;
  }
  result(): () => Promise<any> {
    const result: ILazyLoading = {};
    const config = { ...this.config };
    this.config = {};

    return async () => {
      if (config.lazyComponent !== undefined) {
        const LazyComponent = config.lazyComponent!;
        result.Component = config.protected
          ? () => {
              const Protected = config!.protected as FC<IProtectedRoute>;
              return (
                <Suspense>
                  <Protected>
                    <LazyComponent />
                  </Protected>
                </Suspense>
              );
            }
          : () => (
              <Suspense>
                <LazyComponent />
              </Suspense>
            );
      }
      if (!isBrowserOutdated() && typeof config.loaderFn === 'function') {
        result.loader = config.loaderFn;
      }
      if (config.errorBoundary) {
        result.ErrorBoundary = config.errorBoundary;
      }
      return result;
    };
  }
}
interface ILazyConfig {
  lazyComponent?: LazyExoticComponent<ComponentType<any>>;
  loaderFn?: LoaderFunction;
  protected?: FC<IProtectedRoute>;
  errorBoundary?: FunctionComponent;
}
interface ILazyLoading {
  loader?: LoaderFunction;
  Component?: FunctionComponent;
  ErrorBoundary?: FunctionComponent;
}
interface ITaskSubject {
  args: LoaderFunctionArgs;
  fn: ILazyConfig['loaderFn'];
  resolve: (value?: any) => void;
  reject: (reason?: any) => void;
  order: number;
}
export const lazyUtil = new LazyUtil();
