import { FC } from 'react';
import { TypeGroupTable } from '../groupTable.component';
import { Stack } from '@mui/material';

export const TopControls: FC<ITopControls> = (props) => {
  const { topControlsFunc, sxTopControls } = props;

  const topControls = topControlsFunc?.();

  return <>{topControls && <Stack sx={{ ...sxTopControls }}>{topControls}</Stack>}</>;
};

interface ITopControls {
  topControlsFunc: TypeGroupTable['topControls'];
  sxTopControls: TypeGroupTable['sxTopControls'];
}
