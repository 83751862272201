import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { ITabState } from '../../../states/tabState.model.ts';
import { CustomerListsDataRes } from '../../../services/customer.service.ts';
import { Content } from '../../../../../shared/components/content/content.component.tsx';
import { ListsForm } from './components/listsForm.component.tsx';
import { listsTabState } from './lists.state.ts';

export const Lists: FC = () => {
  const { defaultValues } = useLoaderData() as ITabState;
  const { t } = useTranslation();
  const formMethods = useForm<CustomerListsDataRes>({
    mode: 'onChange',
    defaultValues,
  });
  const { watch, reset, resetField } = formMethods;

  useEffect(() => {
    const formStateSub = watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        listsTabState.pub.recordData({
          [fieldName]: data[fieldName],
          id: data.id,
        });
      }
    });
    const unsubState = listsTabState.sub
      .state()
      .subscribe(({ action, dataToSave, defaultValues }) => {
        if (action === 'list.selectedRecord') {
          reset(defaultValues);
        } else {
          const name = Object.keys(dataToSave)[0] as keyof typeof dataToSave;
          resetField(name, {
            defaultValue: dataToSave[name],
            keepDirty: false,
          });
        }
      });
    return () => {
      formStateSub.unsubscribe();
      unsubState.unsubscribe();
    };
  }, []);

  return (
    <>
      {Boolean(defaultValues) ? (
        <ListsForm {...{ formMethods }} />
      ) : (
        <Content
          type='paper'
          paperProps={{
            variant: 'elevation',
            sx: {
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              height: '100%',
            },
          }}
          emptyData={{
            value: true,
            title: t('common.no_data'),
          }}
        />
      )}
    </>
  );
};
