import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  FieldsSection,
  FormCheckbox,
  FormNumericField,
  FormTextField,
} from '../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { C_Country } from '../../../../../graphql/generatedModel.ts';
import { configurationTabState } from '../../states/configuration.state.ts';
import { TCompanyConfigs } from '../../../../../shared/services/companyConfig/companyConfig.service.ts';

export const FinancialAccountingTab: FC<IFinancialAccountingTab> = ({ params }) => {
  const { countryId, ...defaultValues } = params;

  const { t } = useTranslation();
  const formMethods = useForm({ defaultValues, mode: 'onChange' });
  const {
    resetField,
    formState: { dirtyFields },
  } = formMethods;
  useEffect(() => {
    const formStateSub = formMethods.watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        configurationTabState.pub.recordData({ [fieldName]: data[fieldName] });
      }
    });
    const configurationTabStateSub = configurationTabState.sub
      .state()
      .subscribe(({ dataToSave }) => {
        const name = Object.keys(dataToSave)[0] as keyof typeof dataToSave;
        resetField(name, {
          defaultValue: dataToSave[name],
          keepDirty: false,
        });
      });

    return () => {
      formStateSub.unsubscribe();
      configurationTabStateSub.unsubscribe();
    };
  }, []);

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    if (!!dirtyFields[e.target.name as keyof typeof dirtyFields]) {
      configurationTabState.pub.saveData();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <FieldsSection
        width={456}
        titleBoxSx={{ pt: 0 }}
        nomt
        title={t(`settings.${countryId === C_Country.CO2_GERMANY ? 'datev' : 'abacus'}`)}
      >
        <form noValidate onBlur={onSubmit}>
          <Stack direction='row' spacing={4}>
            <Stack direction='column' spacing={3} minWidth='300px'>
              <FormNumericField name='fibuClient' precision={0} label={t('settings.client')} />
              <FormTextField name='fibuVatCodeNormal' label={t('settings.vat_normal')} />
              <FormTextField name='fibuVatCodeReduced' label={t('settings.vat_reduced')} />
              <FormTextField name='fibuVatCodeWithoutVAT' label={t('settings.no_vat')} />
            </Stack>
            <Stack direction='column'>
              <FormCheckbox
                name='isAbacusXMLExport'
                label={t(
                  `settings.${
                    countryId === C_Country.CO2_GERMANY
                      ? 'enable_datev_export'
                      : 'enable_xml_export'
                  }`,
                )}
              />
              {countryId === C_Country.CO2_GERMANY && (
                <FormCheckbox name='isDatevCommitment' label={t('settings.activate_commit')} />
              )}
            </Stack>
          </Stack>
        </form>
      </FieldsSection>
    </FormProvider>
  );
};

interface IFinancialAccountingTab {
  params: TCompanyConfigs;
}
