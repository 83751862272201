import { ClickAwayListener, List } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import styled from '@mui/material/styles/styled';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { navigateService } from '../../services/navigate/navigate.service';
import { NavHeader } from './components/navHeader.component';
import { navService } from './nav.service';
import { PromptPopup } from '../popupTemplates/promptPopup/prompt.popup.tsx';
import { map } from 'rxjs';

export const promptPopup = new PromptPopup();
export const Nav: FC<INav> = React.memo(({ children }) => {
  const [navOpen, setNavOpen] = useState<boolean>(false);

  const handleNavOpen = useCallback(() => {
    navService.pub.openMenu(true);
  }, []);
  const handleNavClose = useCallback(() => {
    navService.pub.closeMenu();
  }, []);

  useEffect(() => {
    const navigationToSub = navService.sub.navigateTo().subscribe((to) => {
      navigateService.pub.navigateTo(to);
    });
    const menuOpenSub = navService.sub
      .openMenu()
      .pipe(map(({ state }) => state))
      .subscribe(setNavOpen);
    const menuCloseSub = navService.sub.closeMenu().subscribe(setNavOpen);
    return () => {
      navigationToSub.unsubscribe();
      menuOpenSub.unsubscribe();
      menuCloseSub.unsubscribe();
      promptPopup.stream.unsubscribe();
    };
  }, []);

  return (
    <>
      <ClickAwayListener onClickAway={handleNavClose}>
        <Drawer
          variant='permanent'
          sx={{
            '& .MuiDrawer-paper': {
              willChange: 'auto',
              width: (theme) => (navOpen ? theme.nav.widthOpen : theme.nav.width),
              transition: (theme) => theme.transitions.create('width'),
              position: 'absolute',
              left: 0,
              top: 0,
            },
          }}
          open={navOpen}
        >
          <SDrawer sx={{ zIndex: 1302, position: 'relative' }}>
            <NavHeader
              open={navOpen}
              handleNavOpen={handleNavOpen}
              handleNavClose={handleNavClose}
            />
            <List
              sx={{
                overflow: 'hidden auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
              }}
            >
              {children}
            </List>
          </SDrawer>
        </Drawer>
      </ClickAwayListener>
      <promptPopup.Component />
    </>
  );
});

interface INav {
  children: React.ReactElement | React.ReactElement[];
}

const SDrawer = styled('div')(() => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden',
}));
