import React, { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IInitTabData } from '../configurationDetails.tsx';
import { FieldsSection, FormSelect, TextField } from '../../../../../shared/components/form';
import { localeFormatterHelper } from '../../../../../shared/helpers/formatter/localeFormatter.helper.ts';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { C_Tax_System } from '../../../../../graphql/generatedModel.ts';
import { ISelectOption } from '../../../../../shared/components/form/fields/select.component.tsx';
import { configurationTabState } from '../../states/configuration.state.ts';

export const VatTab: FC<IVatTab> = ({ params }) => {
  const { t } = useTranslation();
  const { listTaxesPeriodsAndRates, ...defaultValues } = params;
  const options: ISelectOption[] = useMemo(
    () => [
      {
        id: C_Tax_System.TAX1_INCLUDED_VAT,
        label: t(`enums.${C_Tax_System.TAX1_INCLUDED_VAT}`),
      },
      {
        id: C_Tax_System.TAX2_EXCLUDED_VAT,
        label: t(`enums.${C_Tax_System.TAX2_EXCLUDED_VAT}`),
      },
      { id: C_Tax_System.TAX3_NO_VAT, label: t(`enums.${C_Tax_System.TAX3_NO_VAT}`) },
      {
        id: C_Tax_System.TAX4_INDIVIDUAL_FOR_CUSTOMER,
        label: t(`enums.${C_Tax_System.TAX4_INDIVIDUAL_FOR_CUSTOMER}`),
      },
    ],
    [t, C_Tax_System],
  );

  const formMethods = useForm({ defaultValues, mode: 'onChange' });
  const {
    resetField,
    formState: { dirtyFields },
  } = formMethods;
  useEffect(() => {
    const formStateSub = formMethods.watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        configurationTabState.pub.recordData({ [fieldName]: data[fieldName] });
      }
    });
    const configurationTabStateSub = configurationTabState.sub
      .state()
      .subscribe(({ dataToSave }) => {
        const name = Object.keys(dataToSave)[0] as keyof typeof dataToSave;
        resetField(name, {
          defaultValue: dataToSave[name],
          keepDirty: false,
        });
      });

    return () => {
      formStateSub.unsubscribe();
      configurationTabStateSub.unsubscribe();
    };
  }, []);

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    if (!!dirtyFields[e.target.name as keyof typeof dirtyFields]) {
      configurationTabState.pub.saveData();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form noValidate onBlur={onSubmit}>
        <FormSelect
          label={t('settings.general_vat_system')}
          name='globalTaxSystemId'
          disableClearable
          disableTextInput
          {...{ options }}
        />
      </form>
      {listTaxesPeriodsAndRates?.map((item, i) => (
        <FieldsSection
          key={i}
          titleBoxSx={{ pt: 0 }}
          title={`${localeFormatterHelper.formatDate(
            item.fromDate,
          )} - ${localeFormatterHelper.formatDate(item.toDate)}`}
        >
          <Stack direction='row' spacing={2}>
            <TextField
              label={t('settings.vat_normal_rate')}
              width='160px'
              InputProps={{
                readOnly: true,
              }}
              value={item.vatNormal}
            />
            <TextField
              label={t('settings.vat_reduced_rate')}
              width='180px'
              InputProps={{
                readOnly: true,
              }}
              value={item.vatReduced}
            />
            <TextField
              label={t('settings.vat_special_1')}
              width='180px'
              InputProps={{
                readOnly: true,
              }}
              value={item.vatSpecial1}
            />
            <TextField
              label={t('settings.vat_special_2')}
              width='180px'
              InputProps={{
                readOnly: true,
              }}
              value={item.vatSpecial2}
            />
            <TextField
              label={t('settings.vat_zero')}
              width='180px'
              InputProps={{
                readOnly: true,
              }}
              value={item.vatZero}
            />
          </Stack>
        </FieldsSection>
      ))}
    </FormProvider>
  );
};

interface IVatTab {
  params: IInitTabData;
}
