import {
  customerService,
  ExtendedDefaultOrderGridDataPos,
  GetDeliverySplittingPartsCalculationRes as CalculatedSplittingParts,
} from '../../../../../../../services/customer.service.ts';
import { DefaultOrderGridData } from '../../../../defaultOrder.state.ts';
import { Pub, State, Sub } from '../../../../../../../../../shared/state/state.abstract.ts';
import { BehaviorSubject, filter, map, merge, Observable, share, switchMap, tap } from 'rxjs';
import { IPreparedPositions } from './gridItems/cells/product.cell.tsx';
import { Wa_DefaultOrderPositionSplittingPresetInput as PositionSplittingPreset } from '../../../../../../../../../graphql/generatedModel.ts';
import { responseHandler } from '../../../../../../../../../shared/responseHandler/responseHandler.ts';

const initDeliverySplittingState: IDeliverySplittingState = {
  action: undefined,
  selectedPos: null,
  deliverySplittingGridData: [],
  preparedPositions: {},
  calculatedSplitting: {},
};

class PubImpl extends Pub<IDeliverySplittingState> {
  initGridData(deliverySplittingGridData: IDeliverySplittingState['deliverySplittingGridData']) {
    this.emit('init', { deliverySplittingGridData });
  }
  selectPosition(selectedPos: IDeliverySplittingState['selectedPos']) {
    this.emit('selectPosition', { selectedPos });
  }
  updateGridData(deliverySplittingGridData: IDeliverySplittingState['deliverySplittingGridData']) {
    this.emit('updateGridData', { deliverySplittingGridData });
  }
  clearCalculatedSplitting() {
    this.emit('clearCalculatedSplitting', { calculatedSplitting: {} });
  }
  calculatePositionSplitting(
    selectedPos: IDeliverySplittingState['selectedPos'],
    preparedPositions: IDeliverySplittingState['preparedPositions'],
  ) {
    this.emit('calculatePositionSplitting', { selectedPos, preparedPositions });
  }
}
class SubImpl extends Sub<IDeliverySplittingState> {
  private productCellLoading$ = new BehaviorSubject<number | null>(null);
  private shareProductCellLoading$ = this.productCellLoading$.pipe(share());
  protected actionHandlers(): Observable<IDeliverySplittingState> {
    return merge(this.calculatePositionSplitting(), this.updateState()).pipe(
      tap((state) => {
        this.stream$.next({ ...state, action: 'internalUpdate' });
      }),
    );
  }
  productCellLoading(): Observable<number | null> {
    return this.shareProductCellLoading$;
  }

  private calculatePositionSplitting(): Observable<IDeliverySplittingState> {
    return this.actionListener('calculatePositionSplitting').pipe(
      filter(({ selectedPos }) => !(this.productCellLoading$.value === selectedPos!.id)),
      tap((state) => {
        const { preparedPositions, selectedPos } = state;
        const { id } = selectedPos!;
        this.productCellLoading$.next(id);
        this.stream$.next({
          ...state,
          action: 'updateState',
        });
        customerService.pub.getDeliverySplittingPartsCalculation({
          position: preparedPositions as PositionSplittingPreset,
          id,
        });
      }),
      switchMap(() => {
        return customerService.sub.getDeliverySplittingPartsCalculation().pipe(
          responseHandler<CalculatedSplittingParts>({
            errorReturnType: {
              id: 0,
              mondayParts: [],
              tuesdayParts: [],
              wednesdayParts: [],
              thursdayParts: [],
              fridayParts: [],
              saturdayParts: [],
              sundayParts: [],
            },
          }),
        );
      }),
      map((data) => {
        const updatedState = structuredClone(this.stream$.getValue());
        const { selectedPos } = updatedState;
        const { id, ...rest } = data;
        this.productCellLoading$.next(null);
        if (Object.keys(rest).length && selectedPos?.id === id) {
          updatedState.calculatedSplitting = data;
        }
        return updatedState;
      }),
    );
  }
  private updateState(): Observable<IDeliverySplittingState> {
    return this.actionListener([
      'updateGridData',
      'selectPosition',
      'clearCalculatedSplitting',
      'updateState',
    ]).pipe(
      map((state) => {
        const updatedState = structuredClone(state);
        if (updatedState.action === 'selectPosition' || updatedState.action === 'updateGridData') {
          updatedState.calculatedSplitting = {};
        }
        return updatedState;
      }),
    );
  }
}

class DeliverySplittingState extends State<IDeliverySplittingState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const deliverySplittingState = new DeliverySplittingState(initDeliverySplittingState);
export interface IDeliverySplittingState {
  action:
    | 'init'
    | 'updateGridData'
    | 'selectPosition'
    | 'calculatePositionSplitting'
    | 'clearCalculatedSplitting'
    | 'updateState'
    | 'internalUpdate'
    | undefined;
  selectedPos: ExtendedDefaultOrderGridDataPos | null;
  deliverySplittingGridData: DefaultOrderGridData;
  preparedPositions: IPreparedPositions;
  calculatedSplitting: Partial<CalculatedSplittingParts>;
}
