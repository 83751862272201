import { LocalizationHelper } from '../../shared/helpers/localization/localization.helper.ts';

type Keys =
  | 'order'
  | 'orders'
  | 'create_order'
  | 'edit_order'
  | 'overview'
  | 'customer'
  | 'customers'
  | 'save_order'
  | 'confirm'
  | 'daily_customers'
  | 'customers_without_order'
  | 'accepted_offers'
  | 'active_customers'
  | 'inactive_customers'
  | 'delivery_note_group'
  | 'order_type'
  | 'one_time_customer_order'
  | 'auto_order'
  | 'draft_order'
  | 'tab_not_loaded'
  | 'accepted_offer'
  | 'no_order'
  | 'orders_overview'
  | 'articles_overview'
  | 'reference'
  | 'delivery_time'
  | 'text'
  | 'customer_group'
  | 'remove_article'
  | 'reset_to_default'
  | 'order_settings'
  | 'choose_default'
  | 'delivery_splitting'
  | 'add_shipping_cost'
  | 'barcode_scan'
  | 'merge_positions'
  | 'should_similar_items_be_merged'
  | 'nothing_to_merge'
  /* don't delete this translation list because it is used to create a dynamic translation list like "order.[key]" start */
  | 'phone_business'
  | 'phone_business2'
  | 'phone_private'
  | 'phone_mobile'
  | 'fax'
  | 'email'
  /* end */
  | 'invalid_value'
  | 'total'
  | 'select_order'
  | 'select_order_title'
  | 'additional_comments'
  | 'quantity'
  | 'description'
  | 'price'
  | 'discount'
  | 'vat'
  | 'article'
  | 'article_other'
  | 'value_between'
  | 'lot_quantity'
  | 'add_article'
  | 'more_settings'
  | 'edit_amount'
  | 'empty_order_grid'
  | 'new_position'
  | 'article_description'
  | 'text_on_production_list'
  | 'text_on_delivery_note_invoice'
  | 'individual_description'
  | 'legend_deleted_order'
  | 'legend_invoiced_order'
  | 'delete_order'
  | 'clear_positions'
  | 'default_order'
  | 'last_delivery_note'
  | 'fromDate'
  | 'shipping_cost'
  | 'shipping_cost_fixed'
  | 'delete_order_confirmation'
  | 'preview_automatically'
  | 'create_with_invoice'
  | 'pay_by_cash'
  | 'email_message'
  // errors messages -->
  | 'error_failed_to_load_regular_customer_list'
  | 'error_article_with_this_barcode_not_found'
  // <-- errors messages
  | 'empty_positions_create_warning'
  | 'existing_orders_warning'
  | 'positions_lot_quantity_warning'
  | 'positions_min_max_quantity_warning'
  | 'delivery_splitting_calculations_warning'
  | 'restore'
  | 'no_orders_for_this_date'
  | 'have_orders_for_this_date'
  | 'create_auto_orders'
  | 'create_auto_orders_confirmation'
  | 'no_customers_for_auto_order'
  | 'orders_range'
  | 'number_of_created_orders'
  | 'creating_standard_orders'
  | 'deleting_order'
  | 'order_successfully_deleted'
  | 'error_calculating_price'
  | 'enter_customer_info'
  | 'name'
  | 'delivery_note_form'
  | 'order_will_be_charged'
  | 'convert_to_regular_customer'
  | 'title'
  | 'one_time_order_message'
  | 'paymentPeriod'
  | 'creatingCustomer'
  | 'customerCreated'
  | 'emails_with_attached_reports_message'
  | 'not_enough_days_to_produce'
  | 'datetime_of_last_email_message';

const t = LocalizationHelper<Keys>('order');

export const orderDe = t({
  order: 'Bestellung',
  orders: 'Bestellungen',
  create_order: 'Bestellung erstellen',
  edit_order: 'Bestellung bearbeiten',
  overview: 'Übersicht',
  customer: 'Kunde',
  customers: 'Kunden',
  confirm: 'Bestätigen',
  customer_group: 'Kunde Gruppe',
  articles_overview: 'Artikelübersicht',
  orders_overview: 'Lieferscheinübersicht',
  tab_not_loaded: 'Daten für Tab nicht geladen!',
  daily_customers: 'Tageskunden',
  customers_without_order: 'Kunden ohne Bestellungen',
  accepted_offers: 'Akzeptierte Angebote',
  active_customers: 'Aktive Kunden',
  inactive_customers: 'Inaktive Kunden',
  delivery_note_group: 'Lieferscheingruppe',
  order_type: 'Bestelltyp',
  one_time_customer_order: 'Bestellung für Einmalkunde',
  auto_order: 'Automatische Bestellung',
  draft_order: 'Bestellungsentwurf',
  accepted_offer: 'Akzeptiertes Angebot',
  no_order: 'Keine Bestellung',
  order_settings: 'Auftragseinstellung',
  reference: 'Referenz',
  delivery_time: 'Lieferzeit',
  text: 'Text',
  save_order: 'Bestellung speichern',
  add_article: 'Artikel hinzufügen',
  remove_article: 'Artikel löschen',
  reset_to_default: 'Auf Standard zurücksetzen',
  choose_default: 'Vorgabe wählen',
  delivery_splitting: 'Teillieferungen anzeigen',
  add_shipping_cost: 'Versandkosten hinzufügen',
  phone_business: 'Telefon G',
  phone_business2: 'Telefon G II',
  phone_private: 'Telefon P',
  phone_mobile: 'Natel',
  fax: 'Fax',
  email: 'Email',
  invalid_value: 'Ungültiger Wert',
  total: 'Total',
  select_order: 'Bestellung wählen',
  select_order_title: 'Bitte wählen Sie die gewünschte Bestellung aus der Liste unten:',
  additional_comments: 'Zusätzliche Bemerkung',
  quantity: 'Menge',
  description: 'Bezeichnung',
  price: 'Preis',
  discount: 'Rabatt',
  vat: 'MwSt',
  merge_positions: 'Positionen zusammenführen',
  should_similar_items_be_merged: 'Sollen gleiche Artikel zusammengeführt werden?',
  nothing_to_merge: 'Nichts zum Zusammenführen',
  barcode_scan: 'Barcode-Scan',
  error_article_with_this_barcode_not_found: 'Artikel mit diesem Barcode wurde nicht gefunden',
  article: 'Artikel',
  article_other: 'Artikel',
  error_failed_to_load_regular_customer_list: 'Die Stammkundenliste konnte nicht geladen werden!',
  more_settings: 'Mehr Einstellungen',
  value_between: 'Der Wert muss zwischen liegen {{minQuantity}} und {{maxQuantity}}',
  lot_quantity: 'Der Wert muss ein Vielfaches der Anzahl der Lots sein ({{quantityPerLot}})',
  edit_amount: 'Menge bearbeiten',
  empty_order_grid: 'Klicken Sie auf “+”, um den ersten Artikel hinzuzufügen',
  new_position: 'Neue Position',
  article_description: 'Artikel-Text',
  text_on_production_list: 'Text auf Produktionsliste',
  text_on_delivery_note_invoice: 'Text auf Lieferschein / Rechnung',
  delete_order: 'Bestellung löschen',
  legend_deleted_order: 'Gelöschte Bestellung',
  legend_invoiced_order: 'Fakturierte Bestellung',
  individual_description: 'Individuelle Bezeichnung',
  clear_positions: 'Klar Positionen',
  default_order: 'Vorgabebestellung',
  last_delivery_note: 'Letzter Lieferschein',
  fromDate: 'Von Datum',
  shipping_cost: 'Versandkosten für {{weight}} kg',
  shipping_cost_fixed: 'Versandkosten',
  delete_order_confirmation: 'Sind Sie sicher, dass Sie die Bestellung löschen möchten?',
  preview_automatically: 'Automatische Vorschau',
  create_with_invoice: 'Erstellen mit Rechnung',
  pay_by_cash: 'Rechnung bar',
  email_message:
    'Sehr geehrte Damen und Herren\n \nIn der Beilage erhalten Sie den Lieferschein [LieferscheinNr] zur Archivierung. Wir danken Ihnen für die Bestellung.\n \nMit freundlichen Grüssen',
  empty_positions_create_warning:
    'Es kann kein Bestellung erstellt werden, da keine Mengen im Bestellung vorhanden sind.',
  existing_orders_warning:
    'Für diesen Kunden liegen am ausgewählten Datum Bestellungen vor. Möchten Sie, dass die Bestellung erstellt wird?',
  positions_lot_quantity_warning: 'Problem mit Menge bei einigen Artikeln. Trotzdem fortfahren?',
  positions_min_max_quantity_warning:
    'Eingegebene Menge von Artikel ist weniger/mehr als der zulässige Menge. Trotzdem fortfahren?',
  delivery_splitting_calculations_warning:
    'Problem der Splitting Kalkulation dieser Lieferung. Trotzdem fortfahren?',
  restore: 'Wiederherstellung',
  no_orders_for_this_date: 'Dieser Kunde wird für dieses Datum keine Bestellungen',
  have_orders_for_this_date: 'Dieser Kunde hat möglicherweise Bestellungen für dieses Datum',
  create_auto_orders: 'Standardbestellungen automatisch erstellen',
  create_auto_orders_confirmation:
    'Sie möchten Bestellungen automatisch erstellen für {{count}} Kunden?',
  no_customers_for_auto_order:
    'Es gibt keine berechtigten Kunden, eine automatische Bestellung zu erstellen.',
  orders_range: 'Bestellungen Bereich',
  number_of_created_orders: 'Anzahl der erstellten Bestellungen',
  creating_standard_orders: 'Erstellen von Standardaufträgen',
  deleting_order: 'Bestellung stornieren...',
  order_successfully_deleted: 'Bestellung erfolgreich gelöscht',
  error_calculating_price: 'Beim Berechnen des Preises ist ein Fehler aufgetreten.',
  enter_customer_info: 'Kundeninformationen eingeben',
  name: 'Name',
  delivery_note_form: 'Lieferschein Formular',
  order_will_be_charged: 'Bestellung wird verrechnet',
  convert_to_regular_customer: 'In Stammkunde umwandeln',
  title: 'Anrede',
  one_time_order_message:
    'Kunde mit diesem Namen wurde bereits für eine frühere Lieferung benutzt. Mochten Sie ihn verwenden?',
  paymentPeriod: 'Zahlungsziel',
  creatingCustomer: 'Erstellen Sie einen Kunden',
  customerCreated: 'Kunde wird angelegt',
  emails_with_attached_reports_message:
    'Emails mit angehängten Reports werden automatisch erstellt und an folgenden Kunden gesendet',
  not_enough_days_to_produce:
    'Für die Produktion einiger Artikel sind nicht genügend Tage vorhanden. Trotzdem fortfahren?',
  datetime_of_last_email_message: 'Email wurde verschickt am {{date}} um {{time}}',
});

export const orderEn = t({
  order: 'Order',
  orders: 'Orders',
  create_order: 'Create order',
  edit_order: 'Edit order',
  overview: 'Overview',
  customer: 'Customer',
  customers: 'Customers',
  confirm: 'Confirm',
  customer_group: 'Customer Group',
  articles_overview: 'Articles overview',
  orders_overview: 'Orders overview',
  daily_customers: 'Daily customers',
  customers_without_order: 'Customers without orders',
  accepted_offers: 'Accepted offers',
  active_customers: 'Active customers',
  inactive_customers: 'Inactive customers',
  delivery_note_group: 'Delivery note group',
  order_type: 'Order type',
  one_time_customer_order: 'Order for one-time customer',
  auto_order: 'Auto-create orders',
  draft_order: 'Draft order',
  accepted_offer: 'Accepted offer ',
  no_order: 'No order',
  reference: 'Reference',
  delivery_time: 'Delivery time',
  text: 'Text',
  save_order: 'Save order',
  order_settings: 'Order settings',
  add_article: 'Add article',
  remove_article: 'Delete article',
  reset_to_default: 'Reset to default',
  choose_default: 'Choose default',
  delivery_splitting: 'Show delivery parts',
  add_shipping_cost: 'Add shipping costs',
  phone_business: 'Phone business',
  phone_business2: 'Phone business II',
  phone_private: 'Phone private',
  phone_mobile: 'Mobile',
  email: 'Email',
  fax: 'Fax',
  merge_positions: 'Merge positions',
  should_similar_items_be_merged: 'Should similar items be merged?',
  nothing_to_merge: 'Nothing to merge',
  barcode_scan: 'Barcode scan',
  error_article_with_this_barcode_not_found: 'Article with this barcode was not found',
  invalid_value: 'Invalid value',
  total: 'Total',
  select_order: 'Select order',
  select_order_title: 'Please select desired order from the list below:',
  additional_comments: 'Additional comments',
  quantity: 'Quantity',
  description: 'Description',
  more_settings: 'More settings',
  error_failed_to_load_regular_customer_list: 'Failed to load regular customer list!',
  price: 'Price',
  discount: 'Discount',
  vat: 'VAT',
  tab_not_loaded: 'Data for tab not loaded!',
  article: 'Article',
  article_other: 'Articles',
  value_between: 'The value must be between {{minQuantity}} and {{maxQuantity}}',
  lot_quantity: 'The value must be a multiple of the number of lots ({{quantityPerLot}})',
  edit_amount: 'Edit Amount',
  empty_order_grid: 'Click “+” to add the first item',
  new_position: 'New position',
  article_description: 'Article description',
  text_on_production_list: 'Text on production list',
  text_on_delivery_note_invoice: 'Text on delivery note / invoice',
  delete_order: 'Delete order',
  legend_deleted_order: 'Deleted order',
  legend_invoiced_order: 'Invoiced order',
  individual_description: 'Individual description',
  clear_positions: 'Clear positions',
  default_order: 'Default order',
  last_delivery_note: 'Last delivery note',
  fromDate: 'From date',
  shipping_cost: 'Shipping costs for {{weight}} kg',
  shipping_cost_fixed: 'Shipping costs',
  delete_order_confirmation: 'Are you sure you want to delete the order?',
  preview_automatically: 'Automatic preview',
  create_with_invoice: 'Create with invoice',
  pay_by_cash: 'Pay by cash',
  email_message:
    'Dear Ladies and Gentlemen\n \nEnclosed you receive the delivery note [LieferscheinNr] for archiving. Thank you for ordering.\n \nBest regards',
  empty_positions_create_warning:
    'Order cannot be created because amount field is empty in all positions of the order.',
  existing_orders_warning:
    'There are orders for this customer on the selected date. Do you want the order to be created?',
  positions_lot_quantity_warning: 'Problem with quantity for some articles. Continue anyway?',
  positions_min_max_quantity_warning:
    'Quantity of article entered is less/greater than allowed quantity. Continue anyway?',
  delivery_splitting_calculations_warning:
    'Problem of delivery splitting calculation. Continue anyway?',
  restore: 'Restore',
  no_orders_for_this_date: 'This customer will have no orders for this date',
  have_orders_for_this_date: 'This customer may have orders for this date',
  create_auto_orders: 'Generate standard orders automatically',
  create_auto_orders_confirmation:
    'Do you want to automatically create orders for {{count}} customers?',
  no_customers_for_auto_order: 'There are no eligible customers to create an auto-order.',
  orders_range: 'Orders range',
  number_of_created_orders: 'Number of created orders',
  creating_standard_orders: 'Creating standard orders',
  deleting_order: 'Deleting order...',
  order_successfully_deleted: 'Order successfully deleted',
  error_calculating_price: 'An error occurred while calculating the price.',
  enter_customer_info: 'Enter customer information',
  name: 'Name',
  delivery_note_form: 'Delivery note form',
  order_will_be_charged: 'Order will be charged',
  convert_to_regular_customer: 'Convert to regular customer',
  title: 'Title',
  one_time_order_message:
    'Customer with this name already been used when creating delivery. Do you want to use it?',
  paymentPeriod: 'Payment period',
  creatingCustomer: 'Creating a customer',
  customerCreated: 'Customer is created',
  emails_with_attached_reports_message:
    'Emails with attached reports wil be automatically created and sent to the following customers',
  not_enough_days_to_produce: 'Not enough days for some articles be produced. Continue anyway?',
  datetime_of_last_email_message: 'Email was sent on {{date}} at {{time}}',
});
