import { LoaderFunction, Params } from 'react-router-dom';
import { customerListResolver, listFilterDataResolver } from './customerList.resolver';

import { CustomerParams } from '../../../shared/components/globalSearch/globalSearchParams.util.ts';
import { customerTabLoadingService } from '../components/tabs/customerTabLoading.service.ts';
import { ListCustomersRes } from '../services/customer.service.ts';
import {
  ICustomersListState,
  customersListState,
  initState as initListState,
} from '../states/customersList.state.ts';
import { CustomerTabs, ITabState, tabStateModel } from '../states/tabState.model.ts';
import { resolveDataForAvailabilityTab } from './availabilityTab.resolver.ts';
import { resolveDataForCashAssistTab } from './cashAssistTab.resolver.ts';
import {
  resolveConditionsTabOptions,
  resolveDataForConditionsTab,
} from './conditionsTab.resolver.ts';
import {
  resolveDefaultOrderTab,
  resolveDefaultOrderTabOptions,
} from './defaultOrderTab.resolver.ts';
import { resolveDataForFormsTab } from './formsTab.resolver.ts';
import { resolveDataForGeneralTab, resolveGeneralTabOptions } from './generalTab.resolver.ts';
import { resolveDataForListsTab } from './listTab.resolver.ts';
import { resolveDataForTourTab, resolveTourTabOptions } from './tourTab.resolver.ts';

function checkTab404(selectedTab: CustomerTabs) {
  if (!Object.values(CustomerTabs).includes(selectedTab)) {
    location.href = '/404';
  }
}

export const customerListLoader: LoaderFunction = async ({
  params,
}: {
  params: Params<keyof CustomerParams>;
}): Promise<ICustomerListLoader> => {
  const [resolvedListState, filterData] = await Promise.all([
    customerListResolver(params),
    listFilterDataResolver(),
  ]);
  return { initListData: { ...initListState, ...resolvedListState, filterData } };
};

export const customerTabLoader: LoaderFunction = async ({ params, context }) => {
  const tab = params.tab as CustomerTabs;
  const globalSearchParams = params as CustomerParams;
  checkTab404(tab);
  const tabState = { ...tabStateModel };
  let selectedCustomer: ListCustomersRes[number] | undefined;
  if (context?.parentInitData) {
    selectedCustomer = (context.parentInitData as ICustomerListLoader).initListData.selectedRecord;
  } else selectedCustomer = customersListState.sub.shareDataForTabLoader().selectedRecord;
  tabState.customerId = selectedCustomer?.id as string;
  if (typeof globalSearchParams.entityId === 'string') {
    tabState.customerId = globalSearchParams.entityId;
  }

  switch (tab) {
    case CustomerTabs.general:
      await Promise.all([resolveDataForGeneralTab(tabState), resolveGeneralTabOptions(tabState)]);
      break;
    case CustomerTabs.tour:
      await Promise.all([resolveDataForTourTab(tabState), resolveTourTabOptions(tabState)]);
      break;
    case CustomerTabs.forms:
      await resolveDataForFormsTab(tabState);
      break;
    case CustomerTabs.lists:
      await resolveDataForListsTab(tabState);
      break;
    case CustomerTabs.availability:
      await resolveDataForAvailabilityTab(tabState);
      break;
    case CustomerTabs.cashAssist:
      await resolveDataForCashAssistTab(tabState);
      break;
    case CustomerTabs.conditions:
      await Promise.all([
        resolveDataForConditionsTab(tabState),
        resolveConditionsTabOptions(tabState),
      ]);
      break;
    case CustomerTabs.defaultOrder:
      await Promise.all([
        resolveDefaultOrderTab(tabState),
        resolveDefaultOrderTabOptions(tabState),
      ]);
      break;
  }

  customerTabLoadingService.pub.loading(false);
  return tabState as ITabState;
};

export interface ICustomerListLoader {
  initListData: ICustomersListState;
}
