import { GridRowId } from '@mui/x-data-grid/models/gridRows';
import { orderDetailsState as orderDetailsStateCreate } from '../../../create/states/orderDetails/orderDetails.state.ts';
import { orderDetailsState as orderDetailsStateEdit } from '../../../edit/states/orderDetails.state.ts';
import { GridApiCommunity } from '@mui/x-data-grid/models/api/gridApiCommunity';

export const useEnterKey = (args: IUseKeyboard) => {
  const { type, api } = args;
  const { getAllColumns, getSortedRows, startCellEditMode, stopCellEditMode, scrollToIndexes } =
    api;

  return {
    handleEnterKey: (field: string, rowId: GridRowId) => {
      const fields = ['quantity', 'description'];
      const currentTemp = getAllColumns().reduce((acc: string[], item) => {
        if (fields.includes(item.field)) acc.push(item.field);
        return acc;
      }, []);

      const fieldIndex = currentTemp.findIndex((el) => el === field);
      if (fieldIndex === currentTemp.length - 1) {
        const filteredRows = getSortedRows().filter(
          (el) => !el.virtualPositionId && el.isChangeable,
        );
        const rowIndex = filteredRows.findIndex((el) => el.id === rowId);
        const nextRow = filteredRows[rowIndex + 1];
        if (nextRow) {
          (type === 'create' ? orderDetailsStateCreate : orderDetailsStateEdit).pub.selectPosition(
            nextRow as any,
          );
          const timer = setTimeout(() => {
            startCellEditMode({ id: nextRow.id as string, field: currentTemp[0] });
            clearTimeout(timer);
          }, 0);
          scrollToIndexes({ rowIndex: rowIndex + 1, colIndex: fieldIndex });
        } else {
          stopCellEditMode({ id: rowId, field: currentTemp[fieldIndex] });
        }
      } else {
        const timer = setTimeout(() => {
          startCellEditMode({ id: rowId, field: currentTemp[fieldIndex + 1] });
          clearTimeout(timer);
        }, 0);
      }
    },
  };
};

interface IUseKeyboard {
  type: 'create' | 'edit';
  api: GridApiCommunity;
}
