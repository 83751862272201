/* eslint-disable @typescript-eslint/no-unused-vars */
import { map, take } from 'rxjs';

import { responseHandler } from '../../../../shared/responseHandler/responseHandler';
import { configsData } from '../../../../shared/services/configsData/configsData.service';
import {
  EditPrintListGeneralTabRes,
  editPrintListService,
} from '../../services/editPrintList.service';
import { ITabState } from '../../editPrintList/states/tabState.model';
import { C_Report } from '../../../../graphql/generatedModel';
import { defaultEditPrintListGeneralTabRes } from '../../editPrintList/components/tabs/general/generalTab.state';

export async function resolveDataForGeneralTab(data: ITabState): Promise<ITabState> {
  data.defaultValues = data?.printId
    ? await editPrintListService.globHelpers.streamToPromise(
        editPrintListService.sub.editPrintListGeneralData().pipe(
          responseHandler<EditPrintListGeneralTabRes>({
            customErrorHandler: () => 'production.tab_not_loaded',
            errorReturnType: { ...defaultEditPrintListGeneralTabRes },
          }),
          take(1),
        ),
        () => {
          editPrintListService.pub.editPrintListGeneralData({ id: data.printId! });
        },
      )
    : {};

  return data;
}

export async function resolveTourTabOptions(data: ITabState): Promise<ITabState> {
  data.tourTabOptions = await editPrintListService.globHelpers.streamToPromise(
    configsData.sub.dictTourplans().pipe(map((tourTabOptions) => tourTabOptions)),
    () => configsData.pub.common(['dictTourplans']),
  );
  return data;
}

export async function resolveCustomersOptions(data: ITabState): Promise<ITabState> {
  data.customersOptions = await editPrintListService.globHelpers.streamToPromise(
    configsData.sub.dictCustomers().pipe(map((customersOptions) => customersOptions)),
    () => configsData.pub.common(['dictCustomers']),
  );
  return data;
}

export async function resolveCustomerListsOptions(data: ITabState): Promise<ITabState> {
  data.customerListsOptions = await editPrintListService.globHelpers.streamToPromise(
    configsData.sub.dictCustomerLists().pipe(map((customerListsOptions) => customerListsOptions)),
    () => configsData.pub.common(['dictCustomerLists']),
  );
  return data;
}

export async function resolveArticleListsOptions(data: ITabState): Promise<ITabState> {
  data.articleListsOptions = await editPrintListService.globHelpers.streamToPromise(
    configsData.sub.dictArticleLists().pipe(map((articleListsOptions) => articleListsOptions)),
    () => configsData.pub.common(['dictArticleLists']),
  );
  return data;
}

export async function resolveProductionPrintListsOptions(data: ITabState): Promise<ITabState> {
  data.productionPrintListsOptions = await editPrintListService.globHelpers.streamToPromise(
    configsData.sub
      .dictProductionPrintLists()
      .pipe(
        map((productionPrintListsOptions) =>
          productionPrintListsOptions
            .filter(
              (option) =>
                option.reportId === C_Report.R11_CROSSTABLE_REPORT_A4_LANDSCAPE ||
                option.reportId === C_Report.R65_CROSSTABLE_REPORT_A4_PORTRAIT,
            )
            .map(({ reportId, ...restFields }) => restFields),
        ),
      ),
    () => configsData.pub.common(['dictProductionPrintLists']),
  );
  return data;
}
