import { C_Report, C_Report_Group } from '../../../graphql/generatedModel';
import { t } from 'i18next';
import { ISelectOption } from '../../components/form/fields/select.component.tsx';

export const ReportsWithGroup: Record<TypeReport, C_Report_Group> = {
  [C_Report.R1_BAKING_LIST_2_COLUMNS]: C_Report_Group.RG1_PRODUCTION_PRODUCTION,
  [C_Report.R14_BAKING_LIST_WITH_RECIPE_2_COLUMNS]: C_Report_Group.RG1_PRODUCTION_PRODUCTION,
  [C_Report.R25_BAKING_LIST_2_COLUMNS_COMBINED]: C_Report_Group.RG1_PRODUCTION_PRODUCTION,
  [C_Report.R26_BAKING_LIST_WITH_RECIPE_A4]: C_Report_Group.RG1_PRODUCTION_PRODUCTION,
  [C_Report.R27_BAKING_LIST_WITH_RECIPE_A5]: C_Report_Group.RG1_PRODUCTION_PRODUCTION,
  [C_Report.R34_BAKING_LIST_A4]: C_Report_Group.RG1_PRODUCTION_PRODUCTION,
  [C_Report.R41_BAKING_LIST_A4_DETAILED]: C_Report_Group.RG1_PRODUCTION_PRODUCTION,
  [C_Report.R56_BAKING_LIST_VERSIONS_DIFFERENCE]: C_Report_Group.RG1_PRODUCTION_PRODUCTION,
  [C_Report.R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS]: C_Report_Group.RG1_PRODUCTION_PRODUCTION,
  [C_Report.R58_BAKING_LIST_VERSIONS_DIFFERENCE_WITH_RECIPE]:
    C_Report_Group.RG1_PRODUCTION_PRODUCTION,
  [C_Report.R66_BAKING_LIST_FOR_CAKES_AND_PIES]: C_Report_Group.RG1_PRODUCTION_PRODUCTION,

  [C_Report.R3_ADDRESS_LIST_A4_LANDSCAPE]: C_Report_Group.RG2_CUSTOMER,
  [C_Report.R4_FACTSHEET]: C_Report_Group.RG2_CUSTOMER,
  [C_Report.R5_PHONES_LIST]: C_Report_Group.RG2_CUSTOMER,
  [C_Report.R45_SHIPPING_LABEL_ADDRESS_ONLY]: C_Report_Group.RG2_CUSTOMER,
  [C_Report.R67_CUSTOMER_PAYMENT_INFO_OVERVIEW]: C_Report_Group.RG2_CUSTOMER,

  [C_Report.R6_ARTICLE_LIST]: C_Report_Group.RG3_ARTICLE,
  [C_Report.R12_LABEL]: C_Report_Group.RG3_ARTICLE,
  [C_Report.R36_ARTICLE_LIST_ALL_PRICE_CATEGORIES]: C_Report_Group.RG3_ARTICLE,
  [C_Report.R50_ARTICLE_LIST_WITH_IMAGES]: C_Report_Group.RG3_ARTICLE,

  [C_Report.R7_WEEKLY_ORDERING_LIST]: C_Report_Group.RG4_DEFAULT_ORDER,
  [C_Report.R28_WEEKLY_ORDERING_LIST_BLANK_QUANTITY]: C_Report_Group.RG4_DEFAULT_ORDER,
  [C_Report.R33_PRICE_LIST_FOR_CUSTOMER]: C_Report_Group.RG4_DEFAULT_ORDER,
  [C_Report.R47_WEEKLY_ORDERINGLIST_BLANK_QUANTITY_BLANK_DATE]: C_Report_Group.RG4_DEFAULT_ORDER,

  [C_Report.R8_DELIVERY_NOTE_A4_WITH_PRICE]: C_Report_Group.RG5_ORDER,
  [C_Report.R16_DELIVERY_NOTE_A4_WITHOUT_PRICE]: C_Report_Group.RG5_ORDER,
  [C_Report.R29_DELIVERY_NOTE_A5_LANDSCAPE_WITH_PRICE]: C_Report_Group.RG5_ORDER,
  [C_Report.R30_DELIVERY_NOTE_A5_LANDSCAPE_WITHOUT_PRICE]: C_Report_Group.RG5_ORDER,
  [C_Report.R40_DELIVERY_NOTE_A4_OVERVIEW]: C_Report_Group.RG5_ORDER,
  [C_Report.R43_DELIVERY_NOTE_A4_WITH_PRICE_AND_DISCOUNT]: C_Report_Group.RG5_ORDER,
  [C_Report.R44_DELIVERY_NOTE_A5_LANDSCAPE_WITH_PRICE_AND_DISCOUNT]: C_Report_Group.RG5_ORDER,
  [C_Report.R46_SHIPPING_LABEL_FOR_ORDERED_ARTICLE]: C_Report_Group.RG5_ORDER,
  [C_Report.R55_DELIVERY_NOTE_A4_WITH_PRICE_AND_ORIGIN]: C_Report_Group.RG5_ORDER,
  [C_Report.R62_DELIVERY_NOTE_PARTIAL_DELIVERY_A4_WITH_PRICE]: C_Report_Group.RG5_ORDER,

  [C_Report.R9_INVOICE_A4]: C_Report_Group.RG6_INVOICE,
  [C_Report.R17_INVOICE_SUMMARY]: C_Report_Group.RG6_INVOICE,
  [C_Report.R18_INVOICE_PAYMENTSLIP]: C_Report_Group.RG6_INVOICE,
  [C_Report.R19_INVOICE_A4_DETAILED]: C_Report_Group.RG6_INVOICE,
  [C_Report.R21_INVOICE_JOURNAL]: C_Report_Group.RG6_INVOICE,
  [C_Report.R31_INVOICE_A4_ARTICLE_GROUPING]: C_Report_Group.RG6_INVOICE,
  [C_Report.R39_INVOICE_A4_CUSTOMER_GROUPING]: C_Report_Group.RG6_INVOICE,
  [C_Report.R52_DELIVERY_OVERVIEW]: C_Report_Group.RG6_INVOICE,
  [C_Report.R59_INVOICE_PAYMENTSLIP_WITH_QR]: C_Report_Group.RG6_INVOICE,

  [C_Report.R10_CUSTOMERS_DEBT_NOTIFIER]: C_Report_Group.RG7_DEBITOR,
  [C_Report.R22_BESR_IMPORT_JOURNAL_A4_LANDSCAPE]: C_Report_Group.RG7_DEBITOR,
  [C_Report.R23_DEBITOR_JOURNAL_A4_LANDSCAPE]: C_Report_Group.RG7_DEBITOR,
  [C_Report.R24_PAYMENT_JOURNAL_A4_LANDSCAPE]: C_Report_Group.RG7_DEBITOR,
  [C_Report.R35_EXTENDED_PAYMENT_JOURNAL]: C_Report_Group.RG7_DEBITOR,
  [C_Report.R64_CUSTOMERS_DEBT_NOTIFIER_WITH_INVOICE_AND_PAYMENTSLIP]: C_Report_Group.RG7_DEBITOR,

  [C_Report.R15_FORWARDING_LIST_CUSTOMER_ARTICLES]: C_Report_Group.RG8_PRODUCTION_FORWARDING_LISTS,
  [C_Report.R20_FORWARDING_LIST_ARTICLE_CUSTOMERS]: C_Report_Group.RG8_PRODUCTION_FORWARDING_LISTS,
  [C_Report.R32_TOURLIST]: C_Report_Group.RG8_PRODUCTION_FORWARDING_LISTS,
  [C_Report.R42_FORWARDING_LIST_CUSTOMER_ARTICLES_ONE_PER_PAGE]:
    C_Report_Group.RG8_PRODUCTION_FORWARDING_LISTS,
  [C_Report.R51_DELIVERY_NOTES_IN_PRODUCTION]: C_Report_Group.RG8_PRODUCTION_FORWARDING_LISTS,
  [C_Report.R68_FORWARDING_LIST_ARTICLE_CUSTOMERS_VERSIONS_DIFFERENCE]:
    C_Report_Group.RG8_PRODUCTION_FORWARDING_LISTS,

  [C_Report.R11_CROSSTABLE_REPORT_A4_LANDSCAPE]: C_Report_Group.RG9_PRODUCTION_CROSSTABLE,
  [C_Report.R65_CROSSTABLE_REPORT_A4_PORTRAIT]: C_Report_Group.RG9_PRODUCTION_CROSSTABLE,

  [C_Report.R37_OFFER_A4]: C_Report_Group.RG10_OFFER,
  [C_Report.R38_OFFER_A4_ARTICLE_GROUPING]: C_Report_Group.RG10_OFFER,
  [C_Report.R48_OFFER_A4_WITH_IMAGES]: C_Report_Group.RG10_OFFER,

  [C_Report.R49_BAKING_LIST_2_COLUMNS_SMART_SCALE]: C_Report_Group.RG11_PRODUCTION_SMARTSCALE,

  [C_Report.R53_PROCUREMENT_ORDER_A4]: C_Report_Group.RG12_PROCUREMENT,
  [C_Report.R54_PROCUREMENT_PROJECT_A4]: C_Report_Group.RG12_PROCUREMENT,

  [C_Report.R60_STOCK_LIST]: C_Report_Group.RG13_PROCUREMENT_GOODS,
  [C_Report.R61_STOCK_VALUE]: C_Report_Group.RG13_PROCUREMENT_GOODS,

  [C_Report.R63_PROCUREMENT_SUPPLIER_GOODS_A4]: C_Report_Group.RG14_PROCUREMENT_SUPPLIERS,
};

export const getReportOptionsByGroup = (groupEnumValue: C_Report_Group | undefined) => {
  const reportOptions: ISelectOption[] = [];
  for (const report in ReportsWithGroup) {
    if (ReportsWithGroup[report as TypeReport] === groupEnumValue) {
      reportOptions.push({
        id: report,
        label: t(`enums.${C_Report[report as keyof typeof C_Report]}`),
      });
    }
  }
  return reportOptions;
};

type TypeReport = Exclude<C_Report, C_Report.R0_USER_REPORT | C_Report.R_NOT_TO_PRINT>;
