/* eslint-disable react-hooks/rules-of-hooks */
import { Popup, TPopupComponent } from '../../../../shared/components/popup/popup.abstract.tsx';
import { FC, useEffect, useMemo, useState } from 'react';
import { TPopupDefaultActions } from '../../../../shared/components/popup/services/popup.service.ts';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { fontHelper } from '../../../../shared/helpers/font/font.helper.ts';
import { FormTextEditor } from '../../../../shared/components/form';
import { Button, Stack, Typography } from '@mui/material';
import { IOrderDetailsState as ICreateDetailsState } from '../../create/states/orderDetails/orderDetails.state.ts';
import { useBeforeClosePopup } from '../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';

export class NotePopup extends Popup<INotePopup> {
  Component: FC<TPopupComponent>;
  isEditable?: boolean;
  showAdditionalNote: boolean;
  constructor(params: { isEditable?: boolean; showAdditionalNote: boolean }) {
    super();
    const { isEditable = true, showAdditionalNote } = params;
    const stream = this.innerStream;
    this.isEditable = isEditable;
    this.showAdditionalNote = showAdditionalNote;

    this.Component = () => {
      const Wrapper = useMemo(() => this.Wrapper, []);
      const { t } = useTranslation();
      const defaultFontSize = useMemo(() => fontHelper.getDefaultFont(['fontSize']), []);
      const [initValues, setInitValues] = useState<INotePopup['values']>({});
      const { resetField, getValues } = useFormContext();
      useEffect(() => {
        stream.actionListener('open').subscribe(({ values }) => {
          setInitValues(values);
        });
      }, []);

      const getDirtyStatus = (): boolean => {
        const { note, note2 } = getValues();
        return (
          initValues.main !== note || (this.showAdditionalNote && initValues.additional !== note2)
        );
      };

      useBeforeClosePopup({
        stream,
        isDirty: getDirtyStatus(),
        handlers: () => {
          resetField('note', { defaultValue: initValues.main });
          resetField('note2', { defaultValue: initValues.additional });
        },
        deps: [initValues.main, initValues.additional],
      });

      return (
        <Wrapper
          stream={stream}
          popupTitle={t('common.note')}
          PaperProps={{ sx: { maxWidth: '750px' } }}
          fullWidth
        >
          <FormTextEditor disabled={!this.isEditable} name='note' defaultFormat={defaultFontSize} />
          {this.showAdditionalNote && (
            <>
              <Typography variant='h5' my={2}>
                {t('order.additional_comments')}
              </Typography>
              <FormTextEditor
                disabled={!this.isEditable}
                name='note2'
                defaultFormat={defaultFontSize}
              />
            </>
          )}
          <Stack mt={3} direction='row' justifyContent='flex-end' spacing={2}>
            <Button
              onClick={() => stream.emit('close')}
              variant='contained'
              disabled={!this.isEditable}
            >
              {t('common.save')}
            </Button>
          </Stack>
        </Wrapper>
      );
    };
  }
}

interface INotePopup {
  action: TPopupDefaultActions;
  values: {
    main?: ICreateDetailsState['orderData']['note'];
    additional?: ICreateDetailsState['orderData']['note2'];
  };
}
