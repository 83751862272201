import { LoaderFunction } from 'react-router-dom';
import { ITabState, OverviewTabs } from '../overview/states/tabState.model';
import { resolveDataForDeliveryNoteOverviewTab } from './overviewTabsResolvers/deliveryNoteOverviewTab.resolver';
import { overviewTabLoadingService } from '../overview/components/tabs/overviewTabLoading.service';
import { resolveCommonDataForTabs } from './overviewTabsResolvers/common.resolver';
import { resolveDataForArticleOverviewTab } from './overviewTabsResolvers/articleOverviewTab.resolver';

export const orderOverviewLoader: LoaderFunction = async ({ params }) => {
  const tab = params?.tab as OverviewTabs;
  if (!tab || !Object.values(OverviewTabs).includes(tab as OverviewTabs)) {
    location.href = '/404';
  }

  let tabState = await resolveCommonDataForTabs();

  if (tab === OverviewTabs.deliveryNoteOverview) {
    tabState = await resolveDataForDeliveryNoteOverviewTab(tabState);
  }
  if (tab === OverviewTabs.articleOverview) {
    tabState = await resolveDataForArticleOverviewTab(tabState);
  }

  overviewTabLoadingService.pub.loading(false);
  return tabState as ITabState;
};
