import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormCheckbox } from '../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { configurationTabState } from '../../states/configuration.state.ts';
import { TCompanyConfigs } from '../../../../../shared/services/companyConfig/companyConfig.service.ts';

export const ProductionTab: FC<IProductionTab> = ({ params }) => {
  const { t } = useTranslation();
  const formMethods = useForm({ defaultValues: params, mode: 'onChange' });
  const {
    resetField,
    formState: { dirtyFields },
  } = formMethods;
  useEffect(() => {
    const formStateSub = formMethods.watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        configurationTabState.pub.recordData({ [fieldName]: data[fieldName] });
      }
    });
    const configurationTabStateSub = configurationTabState.sub
      .state()
      .pipe()
      .subscribe(({ dataToSave }) => {
        const name = Object.keys(dataToSave)[0] as keyof typeof dataToSave;
        resetField(name, {
          defaultValue: dataToSave[name],
          keepDirty: false,
        });
      });

    return () => {
      formStateSub.unsubscribe();
      configurationTabStateSub.unsubscribe();
    };
  }, []);

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    if (!!dirtyFields[e.target.name as keyof typeof dirtyFields]) {
      configurationTabState.pub.saveData();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form noValidate onBlur={onSubmit}>
        <Stack direction='column'>
          <FormCheckbox
            name='isDoughInLitersInProductionReports'
            label={t('settings.show_liters_in_production_print_lists')}
          />
          <FormCheckbox
            name='canForbidIncomingOrdersCAdirect'
            label={t('settings.can_close_orders_from_cashAssist')}
          />
          <FormCheckbox
            name='isUseProcurementLogicInProductionReports'
            label={t(
              'settings.take_into_consideration_own_produced_articles_from_procurement_in_backing_list_reports',
            )}
          />
        </Stack>
      </form>
    </FormProvider>
  );
};

interface IProductionTab {
  params: TCompanyConfigs;
}
