import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Popup } from '../../../../../../../../../shared/components/popup/popup.abstract.tsx';
import { useTranslation } from 'react-i18next';
import { dictionaryState } from '../../../../../../../../../shared/components/dictionary/states/dictionary.state.ts';
import { IconButton, styled } from '@mui/material';
import { SStack } from '../../../../../../../../order/common/orderGridItems/cells/product/product.cell.tsx';
/* Icons */
import SettingsIco from '@mui/icons-material/Settings';
import { IQuantityDiscountPopup } from '../popup/quantityDiscount.popup.tsx';

export const DescriptionDictCell: FC<TDescQuantityDiscountsCell> = ({
  value,
  row,
  id,
  popupStream,
}) => {
  const { t } = useTranslation();
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    dictionaryState.pub.selectRow(row);
    popupStream.emit('open', { id: String(id), title: row.name });
  };
  return (
    <SStack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
      <SValue>{value}</SValue>
      <IconButton
        className='button'
        disabled={row.invalid || String(id).includes('new')}
        title={t('settings.quantity_discount')}
        onClick={handleClick}
        sx={{ p: '3.5px' }}
        size='small'
      >
        <SettingsIco />
      </IconButton>
    </SStack>
  );
};

export const SValue = styled('span')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

type TDescQuantityDiscountsCell = GridRenderCellParams & {
  popupStream: Popup<IQuantityDiscountPopup>['stream'];
};
