import { Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { Content } from '../../shared/components/content/content.component';
import { Page } from '../../shared/components/page/page.component';
import { TabContentContainer } from '../../shared/components/tabs/tabsContainer.component';
import { ArticleTable } from './components/articleTable.component';
import { CustomerTable } from './components/customerTable.component';
import { Header } from './components/header.component';
import { InvoiceTable } from './components/invoiceTable.component';
import { OrderTable } from './components/orderTable.component';
import { PerformGlobalSearchRes } from './services/globalSearch.service';
import { IGlobalSearchData } from './loaders/globalSearch.loader';

const GlobalSearchPage: FC = () => {
  const data = useLoaderData() as IGlobalSearchData;
  const { searchString: initSearchString, searchData: initSearchData } = data;
  const [searchString, setSearchString] = useState(initSearchString);
  const [searchData, setSearchData] = useState(initSearchData);
  const { t } = useTranslation();
  const { customers, invoices, orders, articles } = searchData || {};
  const isData = !(!customers?.length && !invoices?.length && !orders?.length && !articles?.length);

  useEffect(() => {
    const { searchString, searchData } = data;
    setSearchString(searchString);
    setSearchData(searchData);
  }, [data]);

  return (
    <Page title={t('common.search')}>
      <Stack sx={{ paddingBottom: '16px' }}>
        <TabContentContainer
          sx={{
            minHeight: 'calc(100vh - 106px)',
            maxWidth: '1205px',
            margin: '0 auto',
            width: '100%',
            padding: '40px 60px',
          }}
        >
          <Header searchString={searchString} />
          {isData ? (
            <>
              <Stack gap='48px'>
                <CustomerTable data={customers as PerformGlobalSearchRes['customers']} />
                <ArticleTable data={articles as PerformGlobalSearchRes['articles']} />
                <InvoiceTable data={invoices as PerformGlobalSearchRes['invoices']} />
                <OrderTable data={orders as PerformGlobalSearchRes['orders']} />
              </Stack>
            </>
          ) : (
            <Content
              type='paper'
              paperProps={{
                variant: 'outlined',
                sx: {
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'auto',
                  height: '100%',
                  border: 'none',
                },
              }}
              emptyData={{
                value: true,
                title: t('common.no_data_in_global_search', { searchString }),
              }}
            >
              {''}
            </Content>
          )}
        </TabContentContainer>
      </Stack>
    </Page>
  );
};

export default GlobalSearchPage;
