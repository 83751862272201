import { FC, memo, ReactNode } from 'react';
import { Paper, Box, CircularProgress } from '@mui/material';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { Content } from '../content/content.component.tsx';

export const TabsContainer: FC<TabsContainerProps> = memo(({ loading, sx, children }) => (
  <Paper
    sx={{
      flexGrow: 1,
      p: 2,
      position: 'relative',
      ...(sx && { ...sx }),
    }}
    variant='outlined'
  >
    {loading ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    ) : (
      children
    )}
  </Paper>
));

interface TabsContainerProps {
  loading: boolean;
  children: ReactNode;
  sx?: SxProps;
}

// TODO new implementation
export const TabContentContainer: FC<ITabContentContainer> = ({ children, sx }) => (
  <Content
    type='paper'
    paperProps={{
      variant: 'outlined',
      sx: {
        padding: '16px',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      },
    }}
  >
    {children}
  </Content>
);

interface ITabContentContainer {
  children: ReactNode;
  sx?: SxProps;
}
