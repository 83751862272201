import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { PopupService } from '../../../../../../../shared/components/popup/services/popup.service';
import { IDeliveryPeriods } from './deliveryPeriods.popup';
import { Stack } from '@mui/material';
import { LeftSide } from './components/leftSide/leftSide.popup';
import { RightSide } from './components/rightSide/rightSide.popup';
import { PeriodConditions } from '../../../../../services/customer.service';
import { useBeforeClosePopup } from '../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import equal from 'fast-deep-equal/react';

export const Content: FC<IContent> = ({ stream }) => {
  const [currentTab, setCurrentTab] = useState<ITabs>(ITabs.weekDays);
  const isSmall = currentTab === ITabs.weekDays;
  const widthSize = isSmall ? '684px' : '1102px';
  const [data, setData] = useState<IDeliveryPeriods['values']['periodConditions']>();
  const [initData, setInitData] = useState<IDeliveryPeriods['values']['periodConditions']>();
  useEffect(() => {
    const openSub = stream.actionListener('open').subscribe((data) => {
      setData(data?.values?.periodConditions);
      setInitData(structuredClone(data?.values?.periodConditions));
    });
    return () => {
      openSub.unsubscribe();
    };
  }, []);

  const changeTab = (tab: ITabs) => {
    const tryToSwitchInTheSameTab = tab === currentTab;
    if (tryToSwitchInTheSameTab) {
      return;
    }
    setCurrentTab(tab);
  };

  useBeforeClosePopup({
    stream,
    isDirty: !equal(initData, data),
  });

  return (
    <Stack
      direction='row'
      justifyContent='flex-start'
      alignItems='stretch'
      width={widthSize}
      sx={{ transition: 'width 0.2s' }}
    >
      <LeftSide currentTab={currentTab} stream={stream} changeTab={changeTab} generalData={data} />
      <RightSide
        currentTab={currentTab}
        data={data as PeriodConditions}
        setData={setData as Dispatch<SetStateAction<PeriodConditions>>}
        isSmall={isSmall}
      />
    </Stack>
  );
};

export enum ITabs {
  weekDays = 'custom_week_days',
  period = 'period',
  breakOff = 'break_off',
}

export interface IContent {
  stream: PopupService<IDeliveryPeriods>;
}
