import * as yup from 'yup';

export const createValidationSchema = (): yup.AnyObjectSchema => {
  return yup.object({
    isUseOtherCurrencies: yup.boolean(),
    euroRate: yup.number().when('isUseOtherCurrencies', {
      is: true,
      then: (schema) => schema.positive().required('common.not_empty'),
      otherwise: (schema) => schema.notRequired(),
    }),
    usdRate: yup.number().when('isUseOtherCurrencies', {
      is: true,
      then: (schema) => schema.positive().required('common.not_empty'),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
};
