import { LoaderFunction } from 'react-router-dom';
import { take } from 'rxjs';

import {
  companyConfigService,
  TCompanyConfigs,
} from '../../../../shared/services/companyConfig/companyConfig.service.ts';
import { PrintFormsTabs } from '../states/tabState.model.ts';
import { printFormsTabLoadingService } from '../printFormsTabLoading.service.ts';

export const printFormsTabLoader: LoaderFunction = async ({ params }) => {
  const { tab } = params || {};
  if (!tab || !Object.values(PrintFormsTabs).includes(tab as PrintFormsTabs)) {
    location.href = '/404';
  }
  let tabState;
  const res = await getConfigData();

  const { companyNameInReports } = res as TCompanyConfigs;
  switch (tab) {
    case PrintFormsTabs.general:
      tabState = {
        companyNameInReports,
      };
      break;
    default:
      tabState = {};
      break;
  }
  printFormsTabLoadingService.pub.loading(false);
  return tabState;
};

const getConfigData = async () => {
  return new Promise((res) => companyConfigService.getConfigs(true).pipe(take(1)).subscribe(res));
};
