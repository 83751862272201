import { t } from 'i18next';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FieldsRow,
  FieldsSection,
  FormCheckbox,
  FormNumericField,
  FormSelect,
} from '../../../../../../../shared/components/form';
import { ProductionTabOptionsRes } from '../../../../../services/article.service';
import { resetArticleDeps } from '../../../../../states/articleList.state.ts';

export const FractionSection: FC<IFractionSection> = ({ dictArticles }) => {
  const { watch } = useFormContext();
  const fractionalOptions = useMemo(
    () =>
      dictArticles
        .filter(({ isFractionArticle, isActive }) => isFractionArticle && isActive)
        .map(({ id, articleNo, description }) => ({
          id: Number(id),
          label: description,
          itemNo: articleNo,
        })),
    [dictArticles],
  );
  return (
    <FieldsSection marginTop={'31px'} title={t('article.fraction')} titleBoxSx={{ pt: 0 }} nomt>
      <FieldsRow>
        {watch('isFractionArticle') ? (
          <>
            <FormNumericField
              title={t('article.number_of_fractions_per_unit')}
              controls
              precision={0}
              label={t('article.number_of_fractions_per_unit')}
              name='numberOfPartsOfFractionArticle'
            />
            <FormCheckbox label={t('article.round_up')} name='isRoundFractionArticleAmount' />
          </>
        ) : (
          <>
            <FormSelect
              datasetattribute='productionRecipeOptions'
              name='fractionArticleId'
              label={t('article.used_fractional_article')}
              width='300px'
              options={(fractionalOptions as []) || []}
            />
            <FormNumericField
              title={t('article.number_of_used_fractions')}
              controls
              precision={0}
              label={t('article.number_of_used_fractions')}
              name='usedPartOfFractionArticle'
            />
          </>
        )}
        <FormCheckbox
          label={t('article.fractional_article')}
          name='isFractionArticle'
          onChange={() => resetArticleDeps()}
        />
      </FieldsRow>
    </FieldsSection>
  );
};

interface IFractionSection {
  dictArticles: ProductionTabOptionsRes['dictArticles'];
}
