import { GraphQLClient } from 'graphql-request';
import { Observable, from, switchMap, take, zip } from 'rxjs';
import { environment } from '../environment';
import { authService } from '../shared/services/auth/auth.service';

const appEnv = import.meta.env.VITE_REACT_APP_ENV as keyof typeof environment;
const client = new GraphQLClient(environment[appEnv].api);
export const gqlClient = (gql: string, variables: Record<string, any> = {}): Observable<any> => {
  return zip(authService.sub.idToken(), authService.sub.customerGUID()).pipe(
    switchMap(([token, alternativeGuid]) => {
      return from(
        client.request(gql, variables, {
          Authorization: encodeURIComponent(token),
          ...(environment[appEnv].alternativeGuid && { alternativeguid: alternativeGuid || '' }),
        }),
      );
    }),
    take(1),
  );
};
