import { gql } from 'graphql-request';

export const getProductionEditList = gql`
  query ListWA_ProductionPrintLists($filter: WA_ProductionPrintListsGridFilter!) {
    wawiAssist {
      listWA_ProductionPrintLists(filter: $filter) {
        id
        name
      }
    }
  }
`;

export const deleteProductionPrintList = gql`
  mutation deleteWA_ProductionPrintList($id: ID!) {
    wawiAssist {
      deleteWA_ProductionPrintList(id: $id)
    }
  }
`;

export const saveProductionEditPrintListData = gql`
  mutation saveProductionEditPrintListData($dataToSave: WA_SaveProductionPrintListInput!) {
    wawiAssist {
      saveProductionPrintListData(dataToSave: $dataToSave) {
        status
        updatedGridItem {
          id
          name
        }
      }
    }
  }
`;

export const getProductionPrintListData = gql`
  query GetProductionPrintListData($id: ID!) {
    wawiAssist {
      getProductionPrintListData(id: $id) {
        id
        name
        isActive
        isAvailableMon
        isAvailableTue
        isAvailableWed
        isAvailableThu
        isAvailableFri
        isAvailableSat
        isAvailableSun
        reportId
        reportGroupId
        addDaysForPreProduction
        articlesSortingKindId
        customersSortingKindId
        isShowSemiProductsDetails
        isCrosstableEasyMode
        crosstableEasyModeSplitNoFilter
        isNeedSignatureOnForwardingList
        tourplanId
        tourplanDay
        isPrintDeliveryNotesForTourplan
        isShowProductionDescription
        isShowIndividualTexts
        isOnlyPositionsWithIndividualText
        isHighlightArticlesFromProductionCalc
        isSplitDoughBeforePrint
        isShowDeliverySplitting
        crosstableConfigurationData {
          categoryId
          categoryName
          categoryCustomerId
          categoryCustomerListId
          categoryFilterDeliveryType
          categoryFilterSplitNo
          categoryFilterCustomer
          categoryCustomFilterDelivery
        }
        tourplanConfigurationData {
          categoryId
          categoryName
          categoryFilter {
            id
            label: name
            checked
          }
        }
        orderTypesFilter {
          id
          checked
        }
        customerListsFilter {
          id
          label: name
          checked
        }
        articleListsFilter {
          id
          label: name
          checked
        }
      }
    }
  }
`;

export const getCrosstableConfigurationData = gql`
  query GetCrosstableConfigurationData($id: ID!) {
    wawiAssist {
      getProductionPrintListData(id: $id) {
        crosstableConfigurationData {
          categoryId
          categoryName
          categoryCustomerId
          categoryCustomerListId
          categoryFilterDeliveryType
          categoryFilterSplitNo
          categoryFilterCustomer
          categoryCustomFilterDelivery
        }
      }
    }
  }
`;

export const createProductionPrintList = gql`
  mutation createWA_ProductionPrintList($data: WA_CreateProductionPrintListInput!) {
    wawiAssist {
      createWA_ProductionPrintList(data: $data) {
        id
        name
      }
    }
  }
`;

export const copyProductionPrintList = gql`
  mutation copyWA_ProductionPrintList($originalId: ID!, $data: WA_CreateProductionPrintListInput!) {
    wawiAssist {
      copyWA_ProductionPrintList(originalId: $originalId, data: $data) {
        id
        name
      }
    }
  }
`;
