import { GridCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { MuiEvent } from '@mui/x-data-grid-premium';
import React, { MutableRefObject } from 'react';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { IFocusTabProps } from '../table.component.tsx';

export const useTabNavigation = ({ apiRef, params }: IUseTabNavigation) => {
  const { disabledRowsFocus, enabledFields } = params as IFocusTabProps;
  return {
    handleTabNav: (params: GridCellParams, event: MuiEvent<React.KeyboardEvent>) => {
      if (event.key !== 'Tab') return;
      const currentTemp = apiRef.current.getAllColumns().reduce((acc: string[], item) => {
        if (item.editable || enabledFields?.includes(item.field)) acc.push(item.field);
        return acc;
      }, []);
      const columnIndex = currentTemp.findIndex((el) => el === params.field);

      const filteredRows = apiRef.current.getSortedRows().filter((el) => {
        return (disabledRowsFocus || []).every((key) => {
          const isInverse = key.startsWith('!');
          const propName = isInverse ? key.substring(1) : key;
          return isInverse ? !el[propName] : el[propName];
        });
      });
      const rowIndex = filteredRows.findIndex((el) => el.id === params.id);

      // Do nothing if we are at the last cell of the last row
      if (
        columnIndex === currentTemp.length - 1 &&
        rowIndex === filteredRows.length - 1 &&
        !event.shiftKey
      ) {
        return;
      }

      // Do nothing if we are at the first cell of first row
      if (columnIndex === 0 && rowIndex === 0 && event.shiftKey) return;

      event.preventDefault();
      event.defaultMuiPrevented = true;
      if (columnIndex === currentTemp.length - 1) {
        const nextRow = filteredRows[rowIndex + 1];
        if (nextRow && currentTemp[0]) {
          apiRef.current.scrollToIndexes({
            rowIndex: rowIndex + 1,
            colIndex: apiRef.current.getColumnIndex(currentTemp[0]),
          });
          apiRef.current.setCellFocus(nextRow.id, currentTemp[0]);
        }
      } else {
        apiRef.current.scrollToIndexes({
          colIndex: apiRef.current.getColumnIndex(currentTemp[columnIndex + 1]),
        });
        apiRef.current.setCellFocus(params.id, currentTemp[columnIndex + 1]);
      }
    },
  };
};

export interface IUseTabNavigation {
  apiRef: MutableRefObject<GridApiPremium>;
  params: {
    disabledRowsFocus?: string[];
  };
}
