import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLoaderData } from 'react-router-dom';
import { TCompanyConfigs } from '../../../../../shared/services/companyConfig/companyConfig.service.ts';
import { ITabState } from '../../states/tabState.model.ts';
import { createValidationSchema } from './company.schema.ts';
import { companyTabState } from '../../states/company.state.ts';
import { CompanyForm } from './components/companyForm.component.tsx';

const validationSchema = createValidationSchema();

export const CompanyContent: FC<ICompanyContent> = () => {
  const { defaultValues } = useLoaderData() as ITabState;
  const formMethods = useForm<TCompanyConfigs>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver<any>(validationSchema),
  });

  useEffect(() => {
    const formStateSub = formMethods.watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        companyTabState.pub.recordData({ [fieldName]: data[fieldName] });
      }
    });

    const companyTabStateSub = companyTabState.sub.state().subscribe(({ dataToSave }) => {
      const name = Object.keys(dataToSave)[0] as keyof typeof dataToSave;
      formMethods.resetField(name, {
        defaultValue: dataToSave[name],
        keepDirty: false,
      });
    });

    return () => {
      formStateSub.unsubscribe();
      companyTabStateSub.unsubscribe();
    };
  }, []);

  return (
    <>
      <CompanyForm formMethods={formMethods} />
    </>
  );
};

interface ICompanyContent {
  params: TCompanyConfigs;
}
