import { Observable, map } from 'rxjs';
import { Pub, Service, Sub } from '../service.abstract';

type Actions = 'mode';
export type TMode = 'edit' | 'create' | 'copy';
class PubImpl extends Pub<Actions> {
  mode(mode: TMode) {
    this.emit('mode', mode);
  }
}
class SubImpl extends Sub<Actions> {
  mode(): Observable<TMode> {
    return this.actionListener('mode').pipe(map(({ params }) => params));
  }
}

class ModeService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}
export const modeService = new ModeService();
