import { LoaderFunction } from 'react-router-dom';
import { CompanyTabs, tabStateModel } from '../states/tabState.model';
import { resolveDataForCompanyTab } from './company.resolver';

function checkTab404(selectedTab: CompanyTabs) {
  if (!Object.values(CompanyTabs).includes(selectedTab)) {
    location.href = '/404';
  }
}

export const companyTabLoader: LoaderFunction = async ({ params }) => {
  const tab = params.tab as CompanyTabs;
  checkTab404(tab);

  let tabState = { ...tabStateModel };

  if (tab === CompanyTabs.company) {
    tabState = await resolveDataForCompanyTab(tabState);
  }
  return tabState;
};
