import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ArticleAvailabilityTabDataRes } from '../../../../services/article.service';
import { DeliveryPeriods } from '../popups/deliveryPeriods/deliveryPeriods.popup';
import { PeriodConditionsBox } from '../../../../../../shared/components/periodConditionsBox/periodConditionsBox.component';
import { Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  FieldsRow,
  FieldsSection,
  FormCheckboxList,
  FormSelect,
} from '../../../../../../shared/components/form';
import { C_Availability_In_Procurement_System } from '../../../../../../graphql/generatedModel';
import { availabilityTabState } from '../availability.state';

export const AvailabilityForm: FC<IAvailabilityForm> = ({ formMethods }) => {
  const { t } = useTranslation();
  const { getValues, setValue, handleSubmit } = formMethods || {};
  const deliveryPeriodsPopup = useMemo(() => new DeliveryPeriods(), []);
  const { periods, periodsWithCustomWeekDays, vacations, weekDays } = getValues() || {};
  const initPeriodConditions = { periods, periodsWithCustomWeekDays, vacations, weekDays };
  const [periodConditions, setPeriodConditions] = useState(initPeriodConditions);

  const onSubmit = useCallback(() => {
    const {
      formState: { isValid, isDirty },
    } = formMethods;
    if (isValid && isDirty) {
      availabilityTabState.pub.save();
    }
  }, [formMethods]);

  useEffect(() => {
    deliveryPeriodsPopup.stream.state().subscribe((v) => {
      if (v?.action === 'save') {
        const dataForSave = v?.values?.periodConditions;
        const { periods, periodsWithCustomWeekDays, vacations, weekDays } = dataForSave || {};
        setValue('periods', periods, { shouldDirty: true });
        setValue('periodsWithCustomWeekDays', periodsWithCustomWeekDays, { shouldDirty: true });
        setValue('vacations', vacations, { shouldDirty: true });
        setValue('weekDays', weekDays, { shouldDirty: true });
        setPeriodConditions(dataForSave);
        availabilityTabState.pub.save();
      }
    });

    const unsubState = availabilityTabState.sub.state().subscribe(({ defaultValues }) => {
      setPeriodConditions(defaultValues);
    });
    return () => {
      deliveryPeriodsPopup.stream.unsubscribe();
      unsubState.unsubscribe();
    };
  }, []);

  return (
    <>
      <FormProvider {...formMethods}>
        <PeriodConditionsBox periodConditions={periodConditions} width='600px' />
        <div>
          <Button
            onClick={() => {
              deliveryPeriodsPopup.stream.emit('open', {
                values: {
                  periodConditions: { periods, periodsWithCustomWeekDays, vacations, weekDays },
                },
              });
            }}
            sx={{ mt: 2 }}
            variant='contained'
            color='primary'
            startIcon={<SettingsIcon />}
          >
            {t('article.availability_setting')}
          </Button>
        </div>
        <form noValidate onBlur={handleSubmit(onSubmit)}>
          <FieldsSection title={null}>
            <FormCheckboxList
              name='articleAssortimentGroupsCheckedState'
              controlLabel={'<' + t('article.allProductGroups') + '>'}
              minWidth='600px'
            />
          </FieldsSection>
          <FieldsRow>
            <FormSelect
              name='availabilityInProcurementKindId'
              options={Object.values(C_Availability_In_Procurement_System).map((key) => ({
                id: key,
                label: t(`enums.${key}`),
              }))}
              label={t('article.availability_in_procurement_module')}
            />
          </FieldsRow>
        </form>
        <deliveryPeriodsPopup.Component />
      </FormProvider>
    </>
  );
};
interface IAvailabilityForm {
  formMethods: UseFormReturn<ArticleAvailabilityTabDataRes>;
}
