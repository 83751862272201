import { FormProvider, UseFormReturn } from 'react-hook-form';
import { CustomerListsDataRes } from '../../../../services/customer.service.ts';
import { FC } from 'react';
import { FormCheckboxList } from '../../../../../../shared/components/form';
import { Trans, useTranslation } from 'react-i18next';
import { IconButton, styled } from '@mui/material';
import { listsTabState } from '../lists.state.ts';
import { useNavigate } from 'react-router-dom';
import { GotoSettingsIcon } from '../../../../../../svg/custom.svg.tsx';

export const ListsForm: FC<IListsForm> = ({ formMethods }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onSubmit = () => {
    const {
      formState: { isDirty },
    } = formMethods;
    if (isDirty) listsTabState.pub.save();
  };
  return (
    <FormProvider {...formMethods}>
      <SForm noValidate onBlur={onSubmit}>
        <FormCheckboxList
          withDivider
          emptyListIndication={{
            title: t('common.list_are_not_configured'),
            text: (
              <Trans
                style={{ display: 'flex' }}
                i18nKey='common.click_button_above_to_configure'
                components={{ icon: <SGotoSettingsIcon /> }}
              />
            ),
          }}
          headerRightOptions={
            <IconButton
              sx={{ ml: 'auto', height: '40px' }}
              onClick={() => navigate('/settings/configuration/customer')}
              title={t('common.settings')}
            >
              <GotoSettingsIcon />
            </IconButton>
          }
          name='customerListsCheckedState'
          controlLabel={'<' + t('common.all_lists') + '>'}
        />
      </SForm>
    </FormProvider>
  );
};

const SForm = styled('form')({
  height: '100%',
});

const SGotoSettingsIcon = styled(GotoSettingsIcon)(({ theme }) => ({
  marginRight: 2,
  marginLeft: 2,
  height: '24px !important',
  width: '24px !important',
  verticalAlign: 'bottom',
  color: `${theme.palette.text.secondary}!important`,
}));

interface IListsForm {
  formMethods: UseFormReturn<CustomerListsDataRes>;
}
