import {
  GridColDef,
  GridTreeNodeWithRender,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import { formColumnsAndFooter } from '../../table/functions/sharedFunc';
import { TypeTable } from '../../table/table.component';
import { TypeGroupTable } from '../groupTable.component';
import { t } from 'i18next';

export const formColumnsAndFooterForGroupTable = (args: IArgs) => {
  const { children, columnVisibilityModel, viewCells } = args;

  const { columns: initColumns, footer } = formColumnsAndFooter({
    children,
    columnVisibilityModel,
    viewCells,
  });

  const columns = initColumns?.map((v) => ({
    ...v,
    groupingValueGetter: (params: ParamsGroupsValueGetter) => params?.value ?? t('common.empty'),
  })) as GridColDef[];

  return { columns, footer };
};

type ParamsGroupsValueGetter = Pick<
  GridValueGetterParams<any, any, GridTreeNodeWithRender>,
  'colDef' | 'field' | 'id' | 'row' | 'rowNode' | 'value'
>;

interface IArgs {
  children: TypeGroupTable['children'];
  columnVisibilityModel: TypeTable['columnVisibilityModel'];
  viewCells: TypeTable['viewCells'];
}
