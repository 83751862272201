import { Pub, Service, Sub } from '../../../shared/services/service.abstract.ts';
import {
  CreatePaymentForInvoiceFromOverpaymentsMutation,
  CreatePaymentForInvoiceFromOverpaymentsMutationVariables,
  CreatePaymentForInvoiceMutation,
  CreatePaymentForInvoiceMutationVariables,
  DeletePaymentForInvoiceMutation,
  DeletePaymentForInvoiceMutationVariables,
  GetOpenReceivablesReminderQuery,
  GetOpenReceivablesReminderQueryVariables,
  ListPaymentsForInvoiceQuery,
  ListPaymentsForInvoiceQueryVariables,
  OpenDebtsGridDataQuery,
  OpenDebtsGridDataQueryVariables,
  OverpaymentsAmountAvailableQuery,
  OverpaymentsAmountAvailableQueryVariables,
  SaveOpenReceivablesRemindersMutation,
  SaveOpenReceivablesRemindersMutationVariables,
  SendReminderOfInvoiceEmailMutation,
  SendReminderOfInvoiceEmailMutationVariables,
} from '../../../graphql/generatedModel.ts';
import { finalize, forkJoin, map, Observable, of, switchMap, zip } from 'rxjs';
import { gqlClient } from '../../../graphql/graphqlRequest.ts';
import {
  createPaymentForInvoice,
  createPaymentForInvoiceFromOverpayments,
  deletePaymentForInvoice,
  getOpenReceivablesReminder,
  listPaymentsForInvoice,
  openDebtsGridData,
  overpaymentsAmountAvailable,
  saveOpenReceivablesReminders,
  sendReminderOfInvoiceEmail,
} from './gql/openDebts.gql.ts';
import { IDataToCreateRow } from '../openDebts/components/openDebtsGrid/popups/states/enteringPayments.state.ts';

type Actions =
  | 'openDebtsGridData'
  | 'paymentsPopupData'
  | 'createPaymentForInvoice'
  | 'deletePaymentForInvoice'
  | 'createPaymentForInvoiceFromOverpayments'
  | 'overpaymentsAmountAvailable'
  | 'sendReminderOfInvoiceEmail'
  | 'sendReminderOfInvoiceMultipleEmail'
  | 'getOpenReceivablesReminder'
  | 'saveOpenReceivablesReminders'
  | undefined;

class PubImpl extends Pub<Actions> {
  clearStream() {
    this.emit(undefined, {});
  }
  openDebtsGridData(params: OpenDebtsGridDataQueryVariables) {
    this.emit('openDebtsGridData', params);
  }
  overpaymentsAmountAvailable(params: OverpaymentsAmountAvailableQueryVariables) {
    this.emit('overpaymentsAmountAvailable', params);
  }
  paymentsPopupData(params: ListPaymentsForInvoiceQueryVariables) {
    this.emit('paymentsPopupData', params);
  }
  createPaymentForInvoice(params: CreatePaymentForInvoiceMutationVariables) {
    this.emit('createPaymentForInvoice', params);
  }
  deletePaymentForInvoice(params: DeletePaymentForInvoiceMutationVariables) {
    this.emit('deletePaymentForInvoice', params);
  }
  createPaymentForInvoiceFromOverpayments(
    params: CreatePaymentForInvoiceFromOverpaymentsMutationVariables,
  ) {
    this.emit('createPaymentForInvoiceFromOverpayments', params);
  }
  sendReminderOfInvoiceEmail(params: SendReminderOfInvoiceEmailMutationVariables): void {
    this.emit('sendReminderOfInvoiceEmail', params);
  }
  sendReminderOfInvoiceMultipleEmail(
    params: Array<SendReminderOfInvoiceEmailMutationVariables>,
  ): void {
    this.emit('sendReminderOfInvoiceMultipleEmail', params);
  }
  getOpenReceivablesReminder(params: GetOpenReceivablesReminderQueryVariables): void {
    this.emit('getOpenReceivablesReminder', params);
  }
  saveOpenReceivablesReminders(params: SaveOpenReceivablesRemindersMutationVariables): void {
    this.emit('saveOpenReceivablesReminders', params);
  }
}
class SubImpl extends Sub<Actions> {
  openDebtsGridData(): Observable<OpenDebtsGridDataRes> {
    return this.actionListener('openDebtsGridData').pipe(
      switchMap(({ params }) => {
        return gqlClient(openDebtsGridData, params);
      }),
      map((data: OpenDebtsGridDataQuery) => {
        return data.wawiAssist?.listWA_OpenReceivables as OpenDebtsGridDataRes;
      }),
    );
  }
  paymentsPopupData(): Observable<IPaymentsPopupDataRes> {
    return this.actionListener('paymentsPopupData').pipe(
      switchMap(({ params }) => {
        return zip(
          gqlClient(listPaymentsForInvoice, params),
          gqlClient(overpaymentsAmountAvailable, params),
        );
      }),
      map((data: [ListPaymentsForInvoiceQuery, OverpaymentsAmountAvailableQuery]) => {
        return {
          listPayments: data[0]?.wawiAssist?.listWA_PaymentsForInvoice || [],
          overpaymentsAmount: data[1]?.wawiAssist?.getWA_OverpaymentsAmountAvailable || 0,
        } as IPaymentsPopupDataRes;
      }),
    );
  }
  createPaymentForInvoice(): Observable<CreatePaymentForInvoiceRes> {
    return this.actionListener('createPaymentForInvoice').pipe(
      switchMap(({ params }) => {
        return zip(gqlClient(createPaymentForInvoice, params), of(params.paymentProps));
      }),
      map(([paymentForInvoiceRes, rowData]) => {
        const paymentForInvoice = paymentForInvoiceRes?.wawiAssist?.createWA_PaymentForInvoice;
        return { paymentForInvoice, rowData } as CreatePaymentForInvoiceRes;
      }),
    );
  }
  deletePaymentForInvoice(): Observable<DeletePaymentForInvoiceRes> {
    return this.actionListener('deletePaymentForInvoice').pipe(
      switchMap(({ params }) => {
        return gqlClient(deletePaymentForInvoice, params);
      }),
      map((data: DeletePaymentForInvoiceMutation) => {
        return data?.wawiAssist?.deleteWA_PaymentForInvoice as DeletePaymentForInvoiceRes;
      }),
    );
  }
  createPaymentForInvoiceFromOverpayments(): Observable<CreatePaymentForInvoiceFromOverpaymentsRes> {
    return this.actionListener('createPaymentForInvoiceFromOverpayments').pipe(
      switchMap(({ params }) => {
        return gqlClient(createPaymentForInvoiceFromOverpayments, params);
      }),
      map((data: CreatePaymentForInvoiceFromOverpaymentsMutation) => {
        return data?.wawiAssist
          ?.createWA_PaymentForInvoiceFromOverpayments as CreatePaymentForInvoiceFromOverpaymentsRes;
      }),
    );
  }
  overpaymentsAmountAvailable(): Observable<OverpaymentsAmountAvailableRes> {
    return this.actionListener('overpaymentsAmountAvailable').pipe(
      switchMap(({ params }) => {
        return gqlClient(overpaymentsAmountAvailable, params);
      }),
      map((data: OverpaymentsAmountAvailableQuery) => {
        return data.wawiAssist?.getWA_OverpaymentsAmountAvailable as OverpaymentsAmountAvailableRes;
      }),
    );
  }
  sendReminderOfInvoiceEmail(): Observable<SendReminderOfInvoiceEmailRes> {
    return this.actionListener('sendReminderOfInvoiceEmail').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          sendReminderOfInvoiceEmail,
          params,
        ) as Observable<SendReminderOfInvoiceEmailMutation>;
      }),
      map((res) => res.wawiAssist?.sendWA_ReminderOfInvoiceEmail as SendReminderOfInvoiceEmailRes),
      finalize(() => {
        openDebtsService.pub.clearStream();
      }),
    );
  }
  sendReminderOfInvoiceMultipleEmail(): Observable<Array<SendReminderOfInvoiceWithIds>> {
    return this.actionListener('sendReminderOfInvoiceMultipleEmail').pipe(
      switchMap(({ params }) => {
        const emailsRequests: Observable<{
          res: SendReminderOfInvoiceEmailMutation;
          variables: SendReminderOfInvoiceEmailMutationVariables;
        }>[] = params.map((variables: SendReminderOfInvoiceEmailMutationVariables) =>
          gqlClient(sendReminderOfInvoiceEmail, { ...variables }).pipe(
            map((res: SendReminderOfInvoiceEmailMutation) => ({ res, variables })),
          ),
        );
        return forkJoin(emailsRequests).pipe(
          map((responses) => {
            return responses.map(({ res, variables }) => {
              const response = res.wawiAssist
                ?.sendWA_ReminderOfInvoiceEmail as SendReminderOfInvoiceEmailRes;
              const invoiceIds = response?.sentMessages?.length
                ? Array.isArray(variables.invoiceIds)
                  ? variables.invoiceIds
                  : [variables.invoiceIds]
                : [];
              return {
                ...response,
                invoiceIds,
              } as SendReminderOfInvoiceWithIds;
            });
          }),
        );
      }),
      finalize(() => {
        openDebtsService.pub.clearStream();
      }),
    );
  }
  getOpenReceivablesReminder(): Observable<GetOpenReceivablesReminderRes> {
    return this.actionListener('getOpenReceivablesReminder').pipe(
      switchMap(({ params }) => {
        return gqlClient(getOpenReceivablesReminder, params);
      }),
      map((data: GetOpenReceivablesReminderQuery) => {
        return data.wawiAssist?.getWA_OpenReceivablesReminders as GetOpenReceivablesReminderRes;
      }),
      finalize(() => {
        openDebtsService.pub.clearStream();
      }),
    );
  }
  saveOpenReceivablesReminders(): Observable<SaveOpenReceivablesRemindersRes> {
    return this.actionListener('saveOpenReceivablesReminders').pipe(
      switchMap(({ params }) => {
        return zip(
          gqlClient(saveOpenReceivablesReminders, params),
          of({
            count: params.remindersDetails.length,
            invoiceId: params.invoiceId,
          } as Omit<SaveOpenReceivablesRemindersRes, 'status'>),
        );
      }),
      map(([data, details]) => {
        return {
          status: data.wawiAssist?.saveWA_OpenReceivablesReminders,
          ...details,
        } as SaveOpenReceivablesRemindersRes;
      }),
    );
  }
}

class OpenDebtsService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}
export const openDebtsService = new OpenDebtsService();

export type OpenDebtsGridDataRes = NonNullable<
  NonNullable<OpenDebtsGridDataQuery['wawiAssist']>['listWA_OpenReceivables']
>;
export type ListPaymentsForInvoiceRes = NonNullable<
  NonNullable<ListPaymentsForInvoiceQuery['wawiAssist']>['listWA_PaymentsForInvoice']
>;
export type OverpaymentsAmountAvailableRes = NonNullable<
  NonNullable<OverpaymentsAmountAvailableQuery['wawiAssist']>['getWA_OverpaymentsAmountAvailable']
>;
export interface IPaymentsPopupDataRes {
  listPayments: ListPaymentsForInvoiceRes;
  overpaymentsAmount: OverpaymentsAmountAvailableRes;
}
export interface CreatePaymentForInvoiceRes {
  paymentForInvoice: NonNullable<
    NonNullable<CreatePaymentForInvoiceMutation['wawiAssist']>['createWA_PaymentForInvoice']
  >;
  rowData: IDataToCreateRow;
}
export type DeletePaymentForInvoiceRes = NonNullable<
  NonNullable<DeletePaymentForInvoiceMutation['wawiAssist']>['deleteWA_PaymentForInvoice']
>;
export type CreatePaymentForInvoiceFromOverpaymentsRes = NonNullable<
  NonNullable<
    CreatePaymentForInvoiceFromOverpaymentsMutation['wawiAssist']
  >['createWA_PaymentForInvoiceFromOverpayments']
>;
export type SendReminderOfInvoiceEmailRes = NonNullable<
  NonNullable<SendReminderOfInvoiceEmailMutation['wawiAssist']>['sendWA_ReminderOfInvoiceEmail']
>;
export type GetOpenReceivablesReminderRes = NonNullable<
  NonNullable<GetOpenReceivablesReminderQuery['wawiAssist']>['getWA_OpenReceivablesReminders']
>;
export interface SaveOpenReceivablesRemindersRes {
  status: NonNullable<
    NonNullable<
      SaveOpenReceivablesRemindersMutation['wawiAssist']
    >['saveWA_OpenReceivablesReminders']
  >;
  count: number;
  invoiceId: string;
}

export interface SendReminderOfInvoiceWithIds extends SendReminderOfInvoiceEmailRes {
  invoiceIds: string[];
}
