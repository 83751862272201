import { LoaderFunction, Params } from 'react-router-dom';
import { take, zipWith } from 'rxjs';

import { GridSortModel } from '@mui/x-data-grid-premium';
import {
  C_Created_Orders_Filter_Mode,
  C_Order_Positions_Sorting_Kind,
  C_Order_Type,
  Wa_CreatedOrdersGridFilter,
} from '../../../graphql/generatedModel';
import {
  globalSearchParams,
  IOrderFromProductionParams,
  IOrderGlobalSearchParams,
  OrderParams,
} from '../../../shared/components/globalSearch/globalSearchParams.util';
import { dataHelper } from '../../../shared/helpers/data/data.helper';
import { storageHelper } from '../../../shared/helpers/storage';
import { companyConfigService } from '../../../shared/services/companyConfig/companyConfig.service';
import {
  configsData,
  DictCustomersRes,
  DictTextBlocksRes,
} from '../../../shared/services/configsData/configsData.service';
import { additionOptionsSubCreate } from '../create/components/orderDetails/orderDetails.component';
import {
  additionalOptions,
  getOrderTypeDescription,
  IAdditionalOptions,
  initOrderDetailsState,
  IOrderDetailsState,
} from '../edit/states/orderDetails.state';
import {
  commonFields,
  fieldsByFolderName,
  initOrderListState,
  IOrderListState,
} from '../edit/states/orderList.state';
import {
  CreatedOrdersListRes,
  editOrderService,
  IOrderDataRes,
  OrderDetailsDataRes,
} from '../services/editOrder.service';
import { IFilteredProductDataRes, orderService, TaxesByDateRes } from '../services/order.service';
import { responseHandler } from '../../../shared/responseHandler/responseHandler.ts';

export const editOrderLoader: LoaderFunction = async ({
  params,
}: {
  params: Params<keyof OrderParams>;
}): Promise<IEditOrderLoader> => {
  if (additionOptionsSubCreate) additionOptionsSubCreate.unsubscribe();
  let initOrderGridData = {
    initState: structuredClone(initOrderListState),
  };
  let initOrderDetailsData = {
    initState: structuredClone(initOrderDetailsState),
    additionalOptions: structuredClone(additionalOptions),
    linkTextOptions: [],
    customerList: [],
    rowReordering: true,
    deliveryFunctionActive: false,
  } as IEditOrderLoader['initOrderDetailsData'];

  initOrderGridData = await resolveOrderGridData(initOrderGridData, params);
  initOrderDetailsData = await resolveOrderDetailsData(
    initOrderDetailsData,
    initOrderGridData.initState,
  );

  return { initOrderGridData, initOrderDetailsData } as IEditOrderLoader;
};

const resolveOrderGridData = async (
  data: IEditOrderLoader['initOrderGridData'],
  params: OrderParams,
): Promise<IEditOrderLoader['initOrderGridData']> => {
  const { initState } = data || {};
  const filter = initState.filter;
  const nextDeliveryDate = companyConfigService.getCachedConfigs()?.nextDeliveryDate;
  let param: IOrderGlobalSearchParams | undefined;
  let paramFromProduction: IOrderFromProductionParams;
  let dateFrom: string | undefined;
  let dateTo: string | undefined;
  let GSselectedOrder: Pick<IOrderListState['orderList'][number], 'orderId'> | undefined;

  initState.action = 'loader';

  if (
    (paramFromProduction = globalSearchParams(
      'orderFromProduction',
      params,
    ) as IOrderFromProductionParams)
  ) {
    filter.filterBy = C_Created_Orders_Filter_Mode.DELIVERY_NOTES_RANGE;
    filter.withDeleted = false;
    filter.deliveryNotesRange = {
      from: Number(paramFromProduction.entityNum),
      to: Number(paramFromProduction.entityNum),
    };
  }

  if ((param = globalSearchParams('order', params) as IOrderGlobalSearchParams)) {
    filter.filterBy = C_Created_Orders_Filter_Mode.DATE_RANGE;
    dateFrom = param.dateRange.from;
    dateTo = param.dateRange.to;
    filter.withDeleted = param.withDeleted;
    GSselectedOrder = { orderId: param.entityId };
  }

  filter.date = dateFrom || nextDeliveryDate;
  filter.dateTo = dateTo || nextDeliveryDate;
  const queryFilter = Object.entries(filter).reduce<IOrderListState['filter']>(
    (out, [key, value]) => {
      if (
        commonFields.indexOf(key as keyof Wa_CreatedOrdersGridFilter) > -1 ||
        fieldsByFolderName[filter.filterBy].connected.indexOf(
          key as keyof Wa_CreatedOrdersGridFilter,
        ) > -1
      ) {
        out[key as keyof Wa_CreatedOrdersGridFilter] = value;
      }
      return out;
    },
    {} as IOrderListState['filter'],
  );
  let orderList = await editOrderService.globHelpers.streamToPromise(
    editOrderService.sub
      .createdOrdersList()
      .pipe(responseHandler<CreatedOrdersListRes>({ errorReturnType: [] }), take(1)),
    () => editOrderService.pub.createdOrdersList({ filter: queryFilter }),
  );

  /* InitState */
  const sortModel: GridSortModel =
    storageHelper.local.getItem('editOrder.orderList.sortModel') || initState.sortModel;

  if (orderList.length) {
    orderList = dataHelper
      .data(orderList as [])
      .sort({ sortModel })
      .result() as IOrderListState['orderList'];
  }

  const cachedSelectedOrder: IOrderListState['selectedOrder'] =
    GSselectedOrder || storageHelper.session.getItem('editOrder.selectedOrder');
  const foundSelectedRecord =
    cachedSelectedOrder && orderList.find((item) => item?.orderId === cachedSelectedOrder?.orderId);
  const selectedOrder = foundSelectedRecord || orderList[0];
  const allOrderListLength = orderList.length;

  return {
    initState: {
      ...initState,
      filter: {
        ...queryFilter,
        ...(queryFilter?.date ? {} : { date: filter.date }),
        ...(queryFilter?.dateTo ? {} : { dateTo: filter.dateTo }),
      },
      sortModel,
      orderList,
      selectedOrder,
      allOrderListLength,
    },
  };
};

const resolveOrderDetailsData = async (
  data: IEditOrderLoader['initOrderDetailsData'],
  orderListData: IOrderListState,
): Promise<IEditOrderLoader['initOrderDetailsData']> => {
  data.initState.action = 'loader';
  data.initState.orderData = {};
  const selectedOrder = orderListData.selectedOrder;
  if (selectedOrder) {
    const customerId = selectedOrder?.customerId as string;
    const orderId = selectedOrder?.orderId as string;
    const orderType = selectedOrder?.orderType as C_Order_Type;
    const date = selectedOrder?.orderDate;
    const orderTypeDescription = getOrderTypeDescription(selectedOrder);
    const [orderDetailsData, linkTextOptions, productData, taxes, customerList = []] =
      await editOrderService.globHelpers.streamToPromise(
        editOrderService.sub.orderDetailsData().pipe(
          zipWith(
            configsData.sub.dictTextBlocks(),
            orderService.sub.filteredProductData(),
            orderService.sub.taxes(),
            configsData.sub.dictCustomers(),
          ),
          responseHandler<
            [
              OrderDetailsDataRes | undefined,
              DictTextBlocksRes,
              IFilteredProductDataRes,
              TaxesByDateRes,
              DictCustomersRes,
            ]
          >({
            errorReturnType: [
              undefined,
              [],
              {
                productList: [],
              },
              [],
              [],
            ],
          }),
          take(1),
        ),
        () => {
          editOrderService.pub.orderDetailsData({ customerId, orderId });
          configsData.pub.common(['dictTextBlocks']);
          orderService.pub.filteredProductData({ customerId, orderType, date });
          orderService.pub.taxes({
            date: date,
          });
          configsData.pub.common(['dictCustomers']);
        },
      );
    if (
      !orderDetailsData ||
      !Object.keys(orderDetailsData.customerInfo).length ||
      !Object.keys(orderDetailsData.orderData).length
    ) {
      // in case of error
      const emptyState = structuredClone(initOrderDetailsState);
      emptyState.action = 'errorData';
      data.initState = emptyState;
    } else {
      const { customerInfo, orderData } = orderDetailsData;
      const {
        customerNo,
        fullName,
        informationTip,
        deliveryAddress,
        discountKindId,
        isDeliverySplitting,
        deliverySplittingPartsCount,
        transportSectorId,
        transportSector,
        ...contacts
      } = customerInfo;

      data.initState.customerInfo = {
        customerNo,
        fullName,
        informationTip,
        deliveryAddress,
        discountKindId,
        isDeliverySplitting,
        deliverySplittingPartsCount,
        transportSector,
        contacts,
      };

      const orderDataWithWeight = addTotalWeight({ ...orderData, orderTypeDescription } || {});
      data.initState.orderData = orderDataWithWeight;
      data.initState.orderDataBackup = structuredClone(orderDataWithWeight);
      data.initState.noData = false;
      data.rowReordering =
        companyConfigService.getCachedConfigs()?.orderPositionsSortingKindId ===
        C_Order_Positions_Sorting_Kind.OPS3_ORIGINAL;
      data.deliveryFunctionActive =
        !!companyConfigService.getCachedConfigs()?.isShippingFunctionActivated;
    }
    data.customerList = convertCustomerList(customerList);
    data.linkTextOptions = linkTextOptions;
    data.additionalOptions = { productData, taxes };
  } else {
    const [taxes, linkTextOptions, customerList = []] =
      await orderService.globHelpers.streamToPromise(
        orderService.sub.taxes().pipe(
          zipWith(configsData.sub.dictTextBlocks(), configsData.sub.dictCustomers()),
          responseHandler<[TaxesByDateRes, DictTextBlocksRes, DictCustomersRes]>({
            errorReturnType: [[], [], []],
          }),
          take(1),
        ),
        () => {
          configsData.pub.common(['dictTextBlocks']);
          orderService.pub.taxes({
            date: orderListData?.filter?.date,
          });
          configsData.pub.common(['dictCustomers']);
        },
      );
    data.customerList = convertCustomerList(customerList);
    data.linkTextOptions = linkTextOptions;
    data.additionalOptions.taxes = taxes;
  }

  return { ...data };
};

const convertCustomerList = (customerList: DictCustomersRes): ICustomer[] => {
  return customerList
    .map((customer) => ({
      label: `${customer?.customerNo} ${customer?.internalOrFullName}`,
      id: Number(customer?.id),
      isActive: (customer?.isActive || false) as boolean,
    }))
    .sort((a, b) => +b.isActive - +a.isActive || a.id - b.id);
};

export interface ICustomer {
  label: string;
  id: number;
  isActive: boolean;
}

export const getTotalWeight = (data: IOrderDataRes['positions']): number => {
  return (
    data?.reduce((acc, item) => {
      if (
        !item?.virtualPositionId &&
        item?.weight &&
        item?.quantity &&
        !isNaN(item.weight) &&
        !isNaN(item.quantity)
      ) {
        acc += item.weight * item.quantity;
      }
      return acc;
    }, 0) || 0
  );
};

export const addTotalWeight = (
  data: IOrderDetailsState['orderData'],
): IOrderDetailsState['orderData'] => {
  const positions = data?.positions;
  if (positions) {
    const deliveryCost = positions.find((item) => item?.virtualPositionId);
    if (deliveryCost) {
      data.positions = positions.filter((el) => !el?.virtualPositionId);
      data.positions.push({ ...deliveryCost, weight: getTotalWeight(positions) });
    }
  }
  return data;
};

export interface IEditOrderLoader {
  initOrderGridData: {
    initState: IOrderListState;
  };
  initOrderDetailsData: {
    initState: IOrderDetailsState;
    linkTextOptions: DictTextBlocksRes;
    customerList: ICustomer[];
    additionalOptions: IAdditionalOptions;
    rowReordering: boolean;
    deliveryFunctionActive: boolean;
  };
}
