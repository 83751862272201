import { FC, useEffect, useState } from 'react';
import { Content } from '../../../../../shared/components/content/content.component.tsx';
import { useTranslation } from 'react-i18next';
import { DefaultOrderForm } from './components/defaultOrderForm.tsx';
import { DefaultOrderGrid } from './components/defaultOrderGrid/defaultOrderGrid.tsx';
import { useForm } from 'react-hook-form';
import { useLoaderData } from 'react-router-dom';
import { defaultOrderState, IFormData, ISplittingFormData } from './defaultOrder.state.ts';
import { IResolveDefaultOrderTab } from '../../../loaders/defaultOrderTab.resolver.ts';

export const DefaultOrder: FC = () => {
  const { t } = useTranslation();
  const {
    defaultOrderTabData,
    defaultOrderTabOptions,
    customerList = [],
  } = useLoaderData() as IResolveDefaultOrderTab;
  const { productList = [] } = defaultOrderTabOptions;
  const { formData, splittingFormData, ...initData } = defaultOrderTabData;
  const [data, setData] = useState(initData);

  const formMethods = useForm<IFormData>({
    mode: 'onChange',
    defaultValues: formData,
  });

  const splittingFormMethods = useForm<ISplittingFormData>({
    mode: 'onChange',
    defaultValues: { ...splittingFormData },
  });

  useEffect(() => {
    defaultOrderState.pub.init(defaultOrderTabData);
    const defaultOrderStateSub = defaultOrderState.sub
      .state()
      .subscribe(({ formData, splittingFormData, ...data }) => {
        setData(data);
        for (const valuesKey in formData) {
          if (valuesKey && formData) {
            formMethods.resetField(valuesKey as keyof typeof formMethods.getValues, {
              defaultValue: formData[
                valuesKey as keyof typeof formData
              ] as keyof typeof formMethods.getValues,
              keepDirty: false,
            });
          }
        }
        for (const valuesKey in splittingFormData) {
          if (valuesKey && splittingFormData) {
            splittingFormMethods.resetField(
              valuesKey as keyof typeof splittingFormMethods.getValues,
              {
                defaultValue: splittingFormData[
                  valuesKey as keyof typeof splittingFormData
                ] as keyof typeof formMethods.getValues,
                keepDirty: false,
              },
            );
          }
        }
      });
    return () => {
      defaultOrderStateSub.unsubscribe();
    };
  }, []);

  return (
    <>
      {Object.keys(formMethods.getValues()).length ? (
        <>
          <DefaultOrderForm {...{ formMethods, customerList, customerId: data.customerId }} />
          <DefaultOrderGrid formMethods={splittingFormMethods} {...{ data, productList }} />
        </>
      ) : (
        <Content
          type='paper'
          paperProps={{
            variant: 'elevation',
            sx: {
              flexGrow: 1,
              display: 'flex',
              boxShadow: 'none',
              flexDirection: 'column',
              overflow: 'auto',
              height: '100%',
            },
          }}
          emptyData={{
            value: true,
            title: t('common.no_data'),
          }}
        />
      )}
    </>
  );
};
