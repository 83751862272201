import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { GetDictCustomerProfileRes } from '../../../../../../../../../shared/components/dictionary/services/dictionaryAdditional.service';
import { useTranslation } from 'react-i18next';
import { IOptionsForDefaultCustomerSettings } from '../../../customer.tab';
import { styled } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Theme as theme } from '../../../../../../../../../shared/styles/theme/theme';
import { Availability } from './sectors/availability/availablity.component';
import { Conditions } from './sectors/conditions/conditions.component';
import { PrintForms } from './sectors/printForms/printForms.component';

export const DefaultSettingsForm: FC<IDefaultSettingsForm> = ({
  defaultValues,
  setData,
  options,
}) => {
  const { t } = useTranslation();
  const formMethods = useForm<GetDictCustomerProfileRes>({
    mode: 'onChange',
    defaultValues,
  });
  const { watch } = formMethods || {};
  const isError = !('periods' in defaultValues);
  const { periods, periodsWithCustomWeekDays, vacations, weekDays, discountKindId } = defaultValues;
  const initPeriodConditions = isError
    ? null
    : { periods, periodsWithCustomWeekDays, vacations, weekDays };

  useEffect(() => {
    const formStateSub = watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        setData((oldValues) => ({
          ...oldValues,
          [fieldName]: data[fieldName],
        }));
      }
    });

    return () => {
      formStateSub?.unsubscribe?.();
    };
  }, []);

  return (
    <FormProvider {...formMethods}>
      <Accordion sx={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {t('article.availability')}
        </AccordionSummary>
        <AccordionDetails>
          <Availability initPeriodConditions={initPeriodConditions} setData={setData} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {t('customer.conditions')}
        </AccordionSummary>
        <AccordionDetails>
          <Conditions options={options} discountKindId={discountKindId} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          borderBottomRightRadius: 4,
          borderBottomLeftRadius: 4,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {t('settings.print_forms')}
        </AccordionSummary>
        <AccordionDetails>
          <PrintForms />
        </AccordionDetails>
      </Accordion>
    </FormProvider>
  );
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderBottom: 0,
  '&::before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    backgroundColor: theme.palette.lightGrey.light,
    marginBottom: '16px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary sx={{ typography: 'body1' }} {...props} />
))(() => ({
  '& .MuiAccordionSummary-content': {
    margin: '16px 0',
    lineHeight: '32px',
  },
  paddingRight: '28px',
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '16px',
  paddingTop: 0,
}));

export type TSetData = Dispatch<SetStateAction<GetDictCustomerProfileRes>>;

interface IDefaultSettingsForm {
  defaultValues: GetDictCustomerProfileRes;
  setData: TSetData;
  options: IOptionsForDefaultCustomerSettings;
}
