import { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Box, CircularProgress } from '@mui/material';
import styled from '@mui/material/styles/styled';
import {
  SStack,
  SValue,
} from '../../../../../../../../../order/common/orderGridItems/cells/product/product.cell.tsx';

export const ProductCell: FC<IProductCell> = ({ params, loading }) => {
  const { value, id, field } = params;
  return (
    <SStack>
      <SValue>{value}</SValue>
      {loading.includes(`${id}_${field}`) && (
        <SBox>
          <CircularProgress size={16} thickness={5} />
        </SBox>
      )}
    </SStack>
  );
};

interface IProductCell {
  params: GridRenderCellParams;
  loading: string[];
}

const SBox = styled(Box)(() => ({
  display: 'flex',
  padding: '3.5px',
  width: '31px',
  justifyContent: 'center',
}));
