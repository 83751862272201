import * as yup from 'yup';
import { firstValueFrom } from 'rxjs';
import { CustomerNumber, customerService } from '../../../../services/customer.service';
import { responseHandler } from '../../../../../../shared/responseHandler/responseHandler';

export const createValidationSchema = (): yup.AnyObjectSchema => {
  const schema = yup
    .object({
      bank1MandatNo: yup
        .string()
        .nullable()
        .test(
          'bank1MandatNo-server-validation',
          'article.not_unique_number',
          async (value, { parent, options: { context } }): Promise<any> => {
            const dirtyFields = context?.dirtyFieldsState;
            if (!dirtyFields['bank1MandatNo']) {
              return true;
            }
            try {
              customerService.pub.validateCustomerFields({
                context: { setLoading: context?.setBank1MandatLoading },
                fieldsForCheck: { bank1MandatNo: value },
                id: parent.id,
              });

              const result = await firstValueFrom(
                customerService.sub
                  .validateBank1MandatNo()
                  .pipe(responseHandler<CustomerNumber>({ errorReturnType: undefined })),
              );
              switch (result) {
                case CustomerNumber.valid:
                  return true;
                case CustomerNumber.invalid:
                  return false;
                default:
                  return false;
              }
            } catch (error) {
              if (error instanceof Error) {
                throw new Error(`Server validation failed: ${error.message}`);
              }
              throw new Error('An unknown error occurred during server validation');
            }
          },
        ),
      bank2MandatNo: yup
        .string()
        .nullable()
        .test(
          'bank2MandatNo-server-validation',
          'article.not_unique_number',
          async (value, { parent, options: { context } }): Promise<any> => {
            const dirtyFields = context?.dirtyFieldsState;
            if (!dirtyFields['bank2MandatNo']) {
              return true;
            }
            try {
              customerService.pub.validateCustomerFields({
                context: { setLoading: context?.setBank2MandatLoading },
                fieldsForCheck: { bank2MandatNo: value },
                id: parent.id,
              });

              const result = await firstValueFrom(
                customerService.sub
                  .validateBank2MandatNo()
                  .pipe(responseHandler<CustomerNumber>({ errorReturnType: undefined })),
              );
              switch (result) {
                case CustomerNumber.valid:
                  return true;
                case CustomerNumber.invalid:
                  return false;
                default:
                  return false;
              }
            } catch (error) {
              if (error instanceof Error) {
                throw new Error(`Server validation failed: ${error.message}`);
              }
              throw new Error('An unknown error occurred during server validation');
            }
          },
        ),
    })
    .shape({});
  return schema;
};
