import { Pub, Service, Sub } from '../../../services/service.abstract.ts';
import { OptionsObject, SnackbarMessage } from 'notistack';
import { map } from 'rxjs';

type Action = 'show' | 'hide';

class PubSbImpl extends Pub<Action> {
  show(params: ISnackbarOptions) {
    this.emit('show', params);
  }
  hide(id?: ID) {
    this.emit('hide', id);
  }
}

class SubSbImpl extends Sub<Action> {
  show() {
    return this.actionListener('show').pipe(
      map((data) => {
        if (!data.params.anchorOrigin) {
          data.params.anchorOrigin = { vertical: 'top', horizontal: 'right' };
        }
        if (data.params.noAutoHide) {
          data.params.autoHideDuration = null;
        }
        return data;
      }),
    );
  }
  hide() {
    return this.actionListener('hide');
  }
}

class SnackbarService extends Service<Action> {
  pub = new PubSbImpl(this.stream$);
  sub = new SubSbImpl(this.stream$);
}

export const snackbarService = new SnackbarService();

export interface ISnackbarOptions
  extends Pick<
    OptionsObject,
    'type' | 'title' | 'anchorOrigin' | 'view' | 'preventDuplicate' | 'showReload'
  > {
  content: SnackbarMessage;
  noAutoHide?: boolean;
  autoHideDuration?: number;
  id?: ID;
}

type ID = number | string;
