import { Observable, map } from 'rxjs';
import { Pub, Service, Sub } from '../../../services/service.abstract';

export type TransitionActions = 'off' | 'on';
class PubImpl extends Pub<TransitionActions> {
  off(): void {
    this.emit('off');
  }
  on(): void {
    this.emit('on');
  }
}
class SubImpl extends Sub<TransitionActions> {
  off(): Observable<TransitionActions> {
    return this.actionListener('off').pipe(
      map(() => {
        return 'off';
      }),
    );
  }
  on(): Observable<TransitionActions> {
    return this.actionListener('on').pipe(
      map(() => {
        return 'on';
      }),
    );
  }
}
class TransitionService extends Service<TransitionActions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}
export const transitionService = new TransitionService();
