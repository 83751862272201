import { gql } from 'graphql-request';

export const sendEmail = gql`
  mutation sendEmail($params: SendEmailInput!) {
    wawiAssist {
      sendEmail(params: $params) {
        messageId
        timestamp
      }
    }
  }
`;

export const mergePDFs = gql`
  mutation WA_mergePDFs($mergeItems: [WA_MergePDFInput!]!) {
    wawiAssist {
      WA_mergePDFs(mergeItems: $mergeItems) {
        url
        s3Key
      }
    }
  }
`;
