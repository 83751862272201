import { t } from 'i18next';
import { FC } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { FieldsRow, FormNumericField } from '../../../../../../shared/components/form';
import { ArticlePostTabDataRes } from '../../../../services/article.service';
import { postTabState } from '../post.state';

export const PostForm: FC<IPostForm> = ({ formMethods }) => {
  const {
    formState: { errors, dirtyFields },
  } = formMethods;
  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    if (
      !errors[e.target.name as keyof ArticlePostTabDataRes] &&
      !!dirtyFields[e.target.name as keyof ArticlePostTabDataRes]
    ) {
      postTabState.pub.save();
    }
  };
  return (
    <FormProvider {...formMethods}>
      <form noValidate onBlur={onSubmit}>
        <FieldsRow>
          <FormNumericField
            name='shippingWeight'
            label={t('article.weight')}
            controls={false}
            precision={0}
            width='260.5px'
          />
        </FieldsRow>
      </form>
    </FormProvider>
  );
};

interface IPostForm {
  formMethods: UseFormReturn<ArticlePostTabDataRes>;
}
