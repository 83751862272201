import { PeriodConditions } from '../../../../../services/article.service';
import { IBreakOffItem } from './components/rightSide/tabs/breakOff.popup';
import { IPeriodForTable } from './components/rightSide/tabs/period.popup';

export const sortTableByDate = (prevTableData: IPeriodForTable[] | IBreakOffItem[]) => {
  prevTableData.sort((a, b) => {
    const firstDate = new Date(a.fromDate as string);
    const secondDate = new Date(b.fromDate as string);
    const result = firstDate.getTime() - secondDate.getTime();
    return result;
  });
  const sortedResult = structuredClone(prevTableData) as IPeriodForTable[];
  return sortedResult;
};

export const prepareDataForPeriodTable = (
  dataSimple: PeriodConditions['periods'],
  dataCustom: PeriodConditions['periodsWithCustomWeekDays'],
): IPeriodForTable[] => {
  const preparedSimpleData =
    dataSimple?.map(
      (periodData) =>
        ({
          ...periodData,
          isCustom: false,
        } as IPeriodForTable),
    ) || [];

  const preparedCustomData =
    dataCustom?.map((periodData) => {
      const { period, weekDays, ...rest } = periodData;
      return {
        ...rest,
        ...period,
        weekDays,
        isCustom: true,
      } as IPeriodForTable;
    }) || [];

  const preparedData = [...preparedSimpleData, ...preparedCustomData];
  return preparedData || [];
};
