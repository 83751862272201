import { t } from 'i18next';
import { FC, useMemo } from 'react';
import {
  FieldsRow,
  FieldsSection,
  FormNumericField,
  FormSelect,
} from '../../../../../../../shared/components/form';
import { ISelectOption } from '../../../../../../../shared/components/form/fields/select.component';
import { ProductionTabOptionsRes } from '../../../../../services/article.service';

export const ArticleLinkSection: FC<IArticleLinkSection> = ({ dictArticles }) => {
  const doughOptions = useMemo(
    () =>
      dictArticles
        .filter(({ isDough, isActive }) => isDough && isActive)
        .map(({ id, articleNo, description }) => ({
          id,
          label: description,
          itemNo: articleNo,
        })),
    [dictArticles],
  );

  return (
    <FieldsSection marginTop={'31px'} title={t('article.article_link')} titleBoxSx={{ pt: 0 }} nomt>
      <FieldsRow>
        <FormSelect
          datasetattribute='productionRecipeOptions'
          name='doughArticleId'
          label={t('article.dough')}
          filterOptions={filterOptions}
          width='300px'
          options={(doughOptions as []) || []}
        />
        <FormNumericField
          label={t('article.dough_weight_before_baking')}
          controls={false}
          name='doughWeightBeforeBaking'
        />
      </FieldsRow>
    </FieldsSection>
  );
};

const filterOptions = (
  options: ExtendedSelectOption[] = [],
  { inputValue }: { inputValue: string },
): ExtendedSelectOption[] => {
  const filteredOptions = options?.filter(({ label, itemNo }) => {
    const isLableSame = label?.toLowerCase().includes(inputValue?.toLowerCase());
    const isNumberSame = itemNo?.toLowerCase().includes(inputValue?.toLowerCase());
    return isLableSame || isNumberSame;
  });
  return filteredOptions;
};

interface ExtendedSelectOption extends ISelectOption {
  itemNo?: string;
}

interface IArticleLinkSection {
  dictArticles: ProductionTabOptionsRes['dictArticles'];
}
