import { LocalizationHelper } from '../../shared/helpers/localization/localization.helper.ts';

type Keys =
  | ImportIso
  | 'debitor'
  | 'openDebts'
  | 'invoicesDates'
  | 'considerPaymentsUpTo'
  | 'invoiceNo'
  | 'total'
  | 'remainingAmount'
  | 'termOfPayment'
  | 'paymentDate'
  | 'paymentDelay'
  | 'reminderStep'
  | 'overdue'
  | 'paid'
  | 'notOverdue'
  | 'from1To15Days'
  | 'from16To30Days'
  | 'moreThan30Days'
  | 'enterPayment'
  | 'payments'
  | 'useOverpayments'
  | 'accountNo'
  | 'billNo'
  | 'paymentInvoiceNo'
  | 'doYouWantToUseFromOverpayments'
  | 'reminderText'
  | 'print_form'
  | 'allCustomers'
  | 'allInvoicesOfSelectedCustomer'
  | 'onlySelectedInvoice'
  | 'emailMessage'
  | 'noRecordSelected'
  | 'reminder'
  | 'reminders'
  | 'description';
type ImportIso =
  | 'importIso'
  | 'addPaymentsInfo.noData'
  | 'addPaymentsInfo.hint'
  | 'addPaymentsInfo.upload'
  | 'paymentsDetailsInfo.upload'
  | 'paymentsDetailsInfo.report'
  | 'paymentsDetailsInfo.import'
  | 'paymentsDetailsInfo.invoiceNo'
  | 'paymentsDetailsInfo.invoiceDate'
  | 'paymentsDetailsInfo.remainingAmount'
  | 'paymentsDetailsInfo.paymentAmount'
  | 'paymentsDetailsInfo.discountAmount'
  | 'paymentsDetailsInfo.recognizedAmount'
  | 'paymentsDetailsInfo.FIBUAccount'
  | 'paymentsDetailsInfo.status'
  | 'paymentsDetailsInfo.errorMessage'
  | 'paymentsDetailsInfo.subscriberNumber'
  | 'paymentsDetailsInfo.reference';

const t = LocalizationHelper<Keys>('debitor');

export const debitorDe = t({
  debitor: 'Debitor',
  openDebts: 'Offene Debitoren',
  invoicesDates: 'Datum der Rechnungen',
  considerPaymentsUpTo: 'Zahlungen berücksichtigen',
  invoiceNo: 'Rechnung-Nr.',
  total: 'Total',
  remainingAmount: 'Restbetrag',
  termOfPayment: 'Zahlungsziel',
  paymentDate: 'Zahlungs Datum',
  paymentDelay: 'Zahlungs Verzug',
  reminderStep: 'Mahnungs Stufe',
  overdue: 'Überfällig',
  paid: 'Bezahlt',
  notOverdue: 'Nicht Überfällig',
  from1To15Days: '1-15 Tage',
  from16To30Days: '16-30 Tage',
  enterPayment: 'Zahlung verbuchen',
  payments: 'Zahlungen',
  useOverpayments: 'überzahlungen verwenden',
  accountNo: 'Konto-Nr.',
  billNo: 'Beleg-Nr.',
  paymentInvoiceNo: 'Zahlung Rechnung-Nr. {{invoiceNo}}',
  doYouWantToUseFromOverpayments:
    'Wollen Sie {{amountUsed}} aus Überzahlungen verwenden ({{overpaymentsAmount}} verfügbar)?',
  moreThan30Days: '> 30 Tage',
  reminderText: 'Mahnungstext',
  print_form: 'Formular',
  allCustomers: 'Alle Kunde',
  allInvoicesOfSelectedCustomer: 'Alle Rechnungen von ausgewähltem Kunden',
  onlySelectedInvoice: 'Nur ausgewählte Rechnung',
  emailMessage:
    'Sehr geehrte Damen und Herren\n \nUnseren Unterlagen zufolge gibt es für die Rechnung noch kein Zahlungseingang. Hiermit möchten wir Sie daran erinnern, dass oben genannte Rechnung noch nicht beglichen wurde.\n \nBitte begleichen Sie den Betrag demnächst.',
  noRecordSelected: 'Keine Datensätze ausgewählt. Bitte wählen Sie zuerst einen Datensatz aus.',
  reminder: 'Mahnung',
  reminders: 'Mahnungen',
  importIso: 'Import ISO (CAMT.054)',
  description: 'Bezeichnung',
  'addPaymentsInfo.noData': 'Keine Daten vorhanden!',
  'addPaymentsInfo.hint': 'Klicken Sie auf die Schaltfläche, um eine Datei hinzuzufügen',
  'addPaymentsInfo.upload': 'Datei hinzufügen',
  'paymentsDetailsInfo.upload': 'Dateiname',
  'paymentsDetailsInfo.report': 'Formular',
  'paymentsDetailsInfo.import': 'Import',
  'paymentsDetailsInfo.reference': 'Referenznummer',
  'paymentsDetailsInfo.invoiceNo': 'Faktura Nr.',
  'paymentsDetailsInfo.invoiceDate': 'Faktura Datum.',
  'paymentsDetailsInfo.remainingAmount': 'Restbetrag',
  'paymentsDetailsInfo.paymentAmount': 'Zahlbetrag',
  'paymentsDetailsInfo.discountAmount': 'Skonto Betrag',
  'paymentsDetailsInfo.recognizedAmount': 'Verbuchter Betrag',
  'paymentsDetailsInfo.FIBUAccount': 'FIBU-Konto',
  'paymentsDetailsInfo.status': 'Status',
  'paymentsDetailsInfo.errorMessage': 'Fehlermeldung',
  'paymentsDetailsInfo.subscriberNumber': 'Teilnehmernummer',
});

export const debitorEn = t({
  debitor: 'Debitor',
  openDebts: 'Open receivables',
  invoicesDates: 'Invoices dates',
  considerPaymentsUpTo: 'Consider payments up to',
  invoiceNo: 'Invoice No.',
  total: 'Total',
  remainingAmount: 'Remaining amount',
  termOfPayment: 'Terms of payment',
  paymentDate: 'Payment date',
  paymentDelay: 'Payment delay',
  reminderStep: 'Reminders count',
  overdue: 'Overdue',
  paid: 'Paid',
  enterPayment: 'Enter payment',
  payments: 'Payments',
  useOverpayments: 'Use overpayments',
  accountNo: 'Account No.',
  billNo: 'Bill No.',
  paymentInvoiceNo: 'Payment Invoice No. {{invoiceNo}}',
  doYouWantToUseFromOverpayments:
    'Do you want to use {{amountUsed}} from overpayments ({{overpaymentsAmount}} available)?',
  notOverdue: 'Not overdue',
  from1To15Days: '1-15 days',
  from16To30Days: '16-30 days',
  moreThan30Days: '> 30 days',
  reminderText: 'Reminder text',
  print_form: 'Print form',
  allCustomers: 'All customers',
  allInvoicesOfSelectedCustomer: 'All invoices of selected customer',
  onlySelectedInvoice: 'Only selected invoice',
  emailMessage:
    'Dear Ladies and Gentlemen\n \nAccording to our records, we have not yet received a remittance. This is to remind you that above invoice is still unpaid.\n \nPlease send your payment soon.',
  noRecordSelected: 'No records selected. Please select a record first.',
  reminder: 'Reminder',
  reminders: 'Reminders',
  importIso: 'Import ISO (CAMT.054)',
  description: 'Description',
  'addPaymentsInfo.noData': 'No data available!',
  'addPaymentsInfo.hint': 'Click the button to add a file',
  'addPaymentsInfo.upload': 'Add file',
  'paymentsDetailsInfo.upload': 'File name',
  'paymentsDetailsInfo.report': 'Report',
  'paymentsDetailsInfo.import': 'Import',
  'paymentsDetailsInfo.invoiceNo': 'Invoice No.',
  'paymentsDetailsInfo.invoiceDate': 'Invoice date.',
  'paymentsDetailsInfo.remainingAmount': 'Remaining amount',
  'paymentsDetailsInfo.paymentAmount': 'Payment amount',
  'paymentsDetailsInfo.discountAmount': 'Discount amount',
  'paymentsDetailsInfo.recognizedAmount': 'Recognized amount',
  'paymentsDetailsInfo.FIBUAccount': 'FIBU Account',
  'paymentsDetailsInfo.status': 'Status',
  'paymentsDetailsInfo.errorMessage': 'Error message',
  'paymentsDetailsInfo.subscriberNumber': 'Subscriber number',
  'paymentsDetailsInfo.reference': 'Reference',
});
