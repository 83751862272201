import { FC, useEffect, useState } from 'react';
import { Table } from '../../table/table.component.tsx';
import { RowContent } from './rowContent.tsx';
import { Column } from '../../table/components/common.components.tsx';
import { localeFormatterHelper } from '../../../helpers/formatter/localeFormatter.helper.ts';
import { useTranslation } from 'react-i18next';
import { GridRowId } from '@mui/x-data-grid';
import { emailService, EmailTrackingByTypeRes } from '../services/emailTracking.service.ts';
import { IEmailTrackingPopup } from './emailTracking.popup.tsx';
import { viewRows } from './views/mainTable/viewRows.ts';
import { dataHelper } from '../../../helpers/data/data.helper.ts';
import { GridSortModel } from '@mui/x-data-grid-premium';

export const PopupContent: FC<IPopupContent> = ({ params }) => {
  const { t } = useTranslation();
  const defaultSorting: GridSortModel = [{ field: 'timestamp', sort: 'desc' }];
  const [loading, setLoading] = useState(true);
  const [activeRowDetails, setActiveRowDetails] = useState<GridRowId[]>([]);
  const [data, setData] = useState<EmailTrackingByTypeRes>([]);
  useEffect(() => {
    const emailServiceSub = emailService.sub.emailTrackingByType().subscribe((data) => {
      const sortedData = dataHelper
        .data(data)
        .sort({ sortModel: defaultSorting })
        .result() as EmailTrackingByTypeRes;
      setActiveRowDetails(sortedData.map((el) => el.messageId));
      setData(sortedData);
      const timer = setTimeout(() => {
        setLoading(false);
        clearTimeout(timer);
      }, 3);
    });
    if (params) emailService.pub.emailTrackingByType(params);
    return () => {
      emailServiceSub.unsubscribe();
    };
  }, []);

  return (
    <Table
      data={data}
      replacedId='messageId'
      detailRowContent={{
        content: <RowContent />,
        clickableRow: true,
      }}
      onSort={(sortModel) => {
        const sortedData = dataHelper
          .data(data)
          .sort({ sortModel })
          .result() as EmailTrackingByTypeRes;
        setData(sortedData);
      }}
      onDetailPanelExpandedRowIdsChange={setActiveRowDetails}
      detailPanelExpandedRowIds={activeRowDetails}
      rowSelectionModel={activeRowDetails}
      heightOffset={250}
      {...{ loading, defaultSorting, viewRows }}
    >
      <Column
        field='timestamp'
        headerName={t('common.date_time')}
        width={200}
        valueGetter={({ value }) =>
          value
            ? localeFormatterHelper.formatDate(value, {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              })
            : ''
        }
      />
      <Column
        field='senderAddress'
        headerName={t('common.sender_address')}
        valueGetter={({ value }) => value || '-'}
        width={300}
      />
      <Column
        field='receiverAddress'
        headerName={t('common.receiver_address')}
        valueGetter={({ value }) => value || '-'}
        width={300}
      />
      <Column
        field='messageSubject'
        headerName={t('common.subject')}
        valueGetter={({ value }) => value || '-'}
        width={400}
      />
    </Table>
  );
};

interface IPopupContent {
  params: IEmailTrackingPopup['params'];
}
