import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { PopupService } from '../../../../../../../../shared/components/popup/services/popup.service';
import { IPostalWeightPopup } from './postalWeight.popup';
import { Button, Collapse, IconButton, Stack } from '@mui/material';
import { NumericField, Radio } from '../../../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import { Subscription, take } from 'rxjs';
import { Table } from '../../../../../../../../shared/components/table/table.component';
import { Theme } from '../../../../../../../../shared/styles/theme/theme';
import { GridColDef } from '@mui/x-data-grid-premium';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Column } from '../../../../../../../../shared/components/table/components/common.components';
import { NumberEditCell, TPriceRatesForWeight } from '../postSectors/cells/numberEditDict.cell';
import { formatValue } from '../../../../../../../../shared/helpers/utils/utils.helper';
import { dictionaryState } from '../../../../../../../../shared/components/dictionary/states/dictionary.state';
import {
  dictionaryAdditionalService,
  GetDictTransportSectorRes,
} from '../../../../../../../../shared/components/dictionary/services/dictionaryAdditional.service';
import { localeFormatterHelper } from '../../../../../../../../shared/helpers/formatter/localeFormatter.helper.ts';
import { IRadio } from '../../../../../../../../shared/components/form/fields/radio.component.tsx';
import { Content as Wrapper } from '../../../../../../../../shared/components/content/content.component.tsx';
import { useBeforeClosePopup } from '../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import equal from 'fast-deep-equal/react';
import { responseHandler } from '../../../../../../../../shared/responseHandler/responseHandler.ts';

type TShippingRate = 'fixedRate' | 'ratesDependingOnWeight';

export const Content: FC<IContent> = ({ stream }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<GetDictTransportSectorRes>();
  const [dataBackup, setDataBackup] = useState<GetDictTransportSectorRes>();
  const tableData = data?.priceRatesForWeight as TPriceRatesForWeight[];
  const isValid = !tableData?.some(({ invalid }) => invalid);
  const [shippingRate, setShippingRate] = useState<TShippingRate | undefined>();
  const [fixedCostError, setFixedCostError] = useState(false);
  const [selectedWeight, setSelectedWeight] = useState<TWeightRow>();
  const [t] = useTranslation();

  useEffect(() => {
    let weightSub: Subscription;
    const openSub = stream
      .actionListener('open')
      .pipe(take(1))
      .subscribe((v) => {
        const id = v?.values?.id;
        if (id) {
          weightSub = dictionaryAdditionalService.sub
            .getDictTransportSector()
            .pipe(
              responseHandler<GetDictTransportSectorRes>({ errorReturnType: { id: 'errorId' } }),
            )
            .subscribe((data) => {
              setLoading(false);
              const isPriceRateFixed = data.isPriceRateFixed;
              setShippingRate(isPriceRateFixed ? 'fixedRate' : 'ratesDependingOnWeight');
              setData(data);
              setDataBackup(structuredClone(data));
            });
          dictionaryAdditionalService.pub.getDictTransportSector({ id });
        }
      });
    return () => {
      openSub.unsubscribe();
      weightSub && weightSub?.unsubscribe();
    };
  }, []);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'weight',
        headerName: t('settings.weight_up_to'),
        valueFormatter: ({ value }) => (value ? formatValue(value) : ''),
        renderEditCell: (params) => (
          <NumberEditCell {...params} updateFunc={setData as TSetData} noTrailingZeros />
        ),
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'price',
        headerName: t('settings.price'),
        valueFormatter: ({ value }) =>
          value ? localeFormatterHelper.formatNumber(value, { returnZero: false }) : '',
        renderEditCell: (params) => (
          <NumberEditCell
            {...params}
            updateFunc={setData as TSetData}
            noTrailingZeros
            precision={2}
          />
        ),
        align: 'right',
        headerAlign: 'right',
      },
    ],
    [tableData],
  );

  const saveData = () => {
    const { id, priceRatesForWeight, priceRateFixed } = data as GetDictTransportSectorRes;
    if (id && !equal(dataBackup, data)) {
      dictionaryState.pub.updateCell(
        {
          id,
          field: 'virtualField',
          value: {
            ...data,
            priceRatesForWeight: priceRatesForWeight?.map(({ price, weight }) => ({
              price,
              weight,
            })),
            priceRateFixed,
          },
        },
        true,
      );
    }
    stream.emit('close');
  };

  const addRowHandler = () => {
    const id = `new_${Date.now()}`;
    const newRow = { id, price: null, weight: null } as TWeightRow;
    setSelectedWeight(newRow);
    setData(
      (prev) =>
        ({
          ...prev,
          priceRatesForWeight: [...(prev?.priceRatesForWeight || []), newRow],
        } as GetDictTransportSectorRes),
    );
  };

  const deleteRowHandler = () => {
    setData(
      (prev) =>
        ({
          ...prev,
          priceRatesForWeight: (prev?.priceRatesForWeight || [])?.filter((pos, i, arr) => {
            if (pos?.id === selectedWeight?.id) {
              const nextRow = arr?.[i + 1];
              const prevRow = arr?.[i - 1];
              if (i === 0 && arr!.length > 1) {
                setSelectedWeight(nextRow);
              }
              if (i !== 0) {
                setSelectedWeight(prevRow);
              }
              return false;
            } else return true;
          }),
        } as GetDictTransportSectorRes),
    );
  };

  const variantsOfShippingCosts: IRadio[] = useMemo(
    () => [
      { label: t('settings.fixed_shipping_costs'), value: 'fixedRate' },
      { label: t('settings.shipping_costs_by_weight'), value: 'ratesDependingOnWeight' },
    ],
    [],
  );

  const showErrorMessage = (): string => {
    return fixedCostError ? t('common.not_empty') : '';
  };

  const disabledByNumeric =
    shippingRate === 'fixedRate' && (!data?.priceRateFixed || equal(dataBackup, data));

  useBeforeClosePopup({
    stream,
    isDirty: !equal(dataBackup, data),
  });

  return (
    <Wrapper type='box' {...{ loading }} boxProps={{ sx: { minHeight: '275px' } }}>
      <Stack width='335px' p={3}>
        <Stack direction='column'>
          {variantsOfShippingCosts.map(({ label, value }, i) => (
            <Radio
              key={i}
              size='small'
              label={label}
              value={value}
              checked={shippingRate === value}
              onChange={(e) => {
                setData(
                  (prev) =>
                    ({
                      ...prev,
                      isPriceRateFixed: value === 'fixedRate' ? true : false,
                    } as GetDictTransportSectorRes),
                );
                setShippingRate(e.target.value as TShippingRate);
              }}
            />
          ))}
        </Stack>
        <Collapse in={shippingRate === 'ratesDependingOnWeight'}>
          <Stack direction='row' spacing={2} mb={1}>
            <IconButton
              color='primary'
              size='large'
              title={t('common.add')}
              onClick={addRowHandler}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              disabled={!tableData || tableData.length === 0 || !selectedWeight}
              color='primary'
              size='large'
              title={t('common.remove')}
              onClick={deleteRowHandler}
            >
              <RemoveIcon />
            </IconButton>
          </Stack>
          <Table
            heightOffset={450}
            loading={!data}
            data={(tableData || []) as []}
            onRowClick={(e) => setSelectedWeight(e.row)}
            rowSelectionModel={selectedWeight?.id}
            onRowsCountChange={(rows, prevRows, apiRef) => {
              if (rows.length > prevRows.length) {
                const newRow = rows?.[rows.length - 1];
                if (newRow && !newRow?.weight) {
                  apiRef?.current.startCellEditMode({
                    id: newRow.id,
                    field: 'weight',
                  });
                }
              }
            }}
            viewCells={{
              actions: {
                weight: ({ row }) => {
                  if (row.invalid) return 'validate';
                  return '';
                },
                price: ({ row }) => {
                  if (row.invalid) return 'validate';
                  return '';
                },
              },
              styles: [
                {
                  className: 'validate',
                  cellStyles: {
                    backgroundColor: Theme.palette.red.light,
                  },
                },
              ],
            }}
          >
            {
              columns.map((params, i) => (
                <Column
                  key={i}
                  editable={true}
                  sortable={false}
                  resizable={false}
                  flex={1}
                  {...params}
                />
              )) as any
            }
          </Table>
        </Collapse>
        <Collapse in={shippingRate === 'fixedRate'} sx={{ mt: 2 }}>
          <NumericField
            required
            label={t('settings.shipping_cost')}
            value={data?.priceRateFixed}
            onChange={(v) => {
              setFixedCostError(!v);
              setData((prev) => ({ ...prev, priceRateFixed: v } as GetDictTransportSectorRes));
            }}
            error={fixedCostError}
            helperText={showErrorMessage()}
          />
        </Collapse>
        <Stack direction='row' justifyContent='end' mt={6}>
          <Button
            disabled={!isValid || disabledByNumeric}
            onClick={saveData}
            variant='contained'
            sx={{ minWidth: '125px' }}
          >
            {t('common.save')}
          </Button>
        </Stack>
      </Stack>
    </Wrapper>
  );
};

type TWeightRow = NonNullable<GetDictTransportSectorRes['priceRatesForWeight']>[number];

type TSetData = Dispatch<SetStateAction<GetDictTransportSectorRes>>;
export interface IContent {
  stream: PopupService<IPostalWeightPopup>;
}
