import {
  Grow,
  List,
  ListItem,
  Paper,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import LegendIcon from '@mui/icons-material/HelpOutline';
import * as React from 'react';
import { FC } from 'react';
import styled from '@mui/material/styles/styled';
import { SIconButton } from './common.components.tsx';
import CircleIcon from '@mui/icons-material/Brightness1Rounded';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const LegendTooltip: FC<ITooltipContent> = ({ tooltipValues }) => {
  return (
    <STooltipWraper
      TransitionComponent={Grow}
      title={
        <Paper>
          <List>
            {tooltipValues.map((item, i) => (
              <ListItem key={i}>
                {item?.svg ? (
                  <item.svg fontSize='small' sx={{ fill: item.color }} />
                ) : (
                  <SCircleIcon colored={item?.color} />
                )}
                <STypography>{item.title}</STypography>
              </ListItem>
            ))}
          </List>
        </Paper>
      }
      placement='top-start'
    >
      <SIconButton>
        <LegendIcon />
      </SIconButton>
    </STooltipWraper>
  );
};
const STooltipWraper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 0,
    '& svg': {
      marginRight: 8,
    },
    '& li': {
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
}));
const STypography = styled(Typography)(() => ({
  fontSize: 14,
}));
const SCircleIcon = styled(CircleIcon)<{ colored?: string }>(({ colored = 'none', theme }) => ({
  marginRight: 14,
  color: colored,
  stroke: theme.palette.divider,
}));
interface ITooltipContent {
  tooltipValues: ILegendData[];
}
export interface ILegendData {
  color?: string;
  title: string;
  svg?: React.ElementType<SvgIconProps>;
}
