import { take } from 'rxjs';
import { t } from 'i18next';

import { storageHelper } from '../../../../shared/helpers/storage';
import { EditPrintListRes, editPrintListService } from '../../services/editPrintList.service';
import {
  IEditPrintListState,
  initEditPrintListState,
} from '../../editPrintList/states/editPrintList.state';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler';
import { dataHelper } from '../../../../shared/helpers/data/data.helper';

export async function resolveEditPrintListData(
  data: IEditPrintListState,
): Promise<IEditPrintListState> {
  const filter = data.filter;
  const sortModel: IEditPrintListState['sortModel'] =
    storageHelper.local.getItem('editPrintList.editPrintListSortModel') ||
    initEditPrintListState.sortModel;

  let editPrintList: IEditPrintListState['editPrintList'] =
    await editPrintListService.globHelpers.streamToPromise(
      editPrintListService.sub.editPrintList().pipe(
        responseHandler<EditPrintListRes>({
          customErrorHandler() {
            return 'production.print_list_not_loaded';
          },
          errorReturnType: [],
        }),
        take(1),
      ),
      () => editPrintListService.pub.editPrintList({ filter, cache: true }),
    );

  const sessionSelectedPrintList = storageHelper.session.getItem('editPrintList.selectedPrintList');

  if (editPrintList.length) {
    editPrintList = dataHelper
      .data(editPrintList as [])
      .sort({ sortModel: { ...sortModel } })
      .result() as IEditPrintListState['editPrintList'];
  }

  if (sessionSelectedPrintList && sessionSelectedPrintList.id) {
    const foundSelected = editPrintList?.find((item) => item.id === sessionSelectedPrintList.id);
    data.selectedPrintList = foundSelected || editPrintList[0];
  } else {
    data.selectedPrintList = editPrintList[0] as IEditPrintListState['selectedPrintList'];
    storageHelper.session.setItem('editPrintList.selectedPrintList', data.selectedPrintList || {});
  }

  const name = data.selectedPrintList?.name ?? '';
  const fallbackTitle = t('common.new_record');

  data.editPrintListTitle = name ? `${name}` : fallbackTitle;
  data.filter = filter;
  data.editPrintList = editPrintList;
  data.sortModel = sortModel;
  data.allEditPrintListLength = editPrintList.length;

  return data;
}

export interface IEditPrintListLoader {
  initListData: IEditPrintListState;
}
