import { resetTimeInDate } from '../../../../../../../shared/helpers/utils/utils.helper';
import { IWeekDays } from './components/leftSide/leftSide.popup';
import { IBreakOffItem } from './components/rightSide/tabs/breakOff.popup';
import { IPeriodForTable } from './components/rightSide/tabs/period.popup';

export const isBothDatesFromSamePeriod = (props: IBothDatesFromSamePeriod) => {
  const { fromDate, toDate, editPeriod } = props || {};
  let { periodsData } = props || {};
  if (editPeriod && editPeriod?.id) {
    periodsData = periodsData.filter((period) => period.id !== editPeriod?.id);
  }
  if (fromDate > toDate) {
    return false;
  }
  for (let i = 0; i < periodsData?.length; i++) {
    const { fromDate: from, toDate: to } = periodsData[i];
    const isStartCross = isDateCrossPeriod(fromDate, from!, to!);
    const isFinishCross = isDateCrossPeriod(toDate, from!, to!);
    if (isStartCross && isFinishCross) {
      return true;
    }
    const oneCrossButSecondNot =
      (isStartCross && !isFinishCross) || (!isStartCross && isFinishCross);
    if (oneCrossButSecondNot) {
      return false;
    }
    const onEitherSideOfPeriod = fromDate < new Date(to!) && toDate > new Date(to!);
    if (onEitherSideOfPeriod) {
      return false;
    }
  }
  return true;
};

const isDateCrossPeriod = (date: Date, fromDate: string, toDate: string) => {
  const d = resetTimeInDate(date);
  const minDate = resetTimeInDate(new Date(fromDate));
  const maxDate = resetTimeInDate(new Date(toDate));
  maxDate.setHours(23, 59, 59, 999);
  const isDateCross = d >= minDate && d < maxDate;
  return isDateCross;
};

export const transformDateToString = (date: Date) => {
  const day = addZero(date.getDate());
  const month = addZero(date.getMonth() + 1);
  const year = date.getFullYear();

  const fullDate = `${year}-${month}-${day}`;
  return fullDate;
};

export const addZero = (numb: number | string) => {
  numb = Number(numb);
  if (numb < 10) {
    return `0${numb}`;
  } else {
    return numb;
  }
};

export const getInitDate = (periods: IPeriodForTable[] | IBreakOffItem[]) => {
  const initDate = new Date();
  let isDateReady = isDateOutOfPeriods(initDate, periods);
  if (isDateReady) {
    // variant when current date is out of periods
    return transformDateToString(initDate);
  } else {
    // variant when initDate is
    // more for 1 day than one of
    // toDate from periods
    for (let i = 0; i < periods?.length; i++) {
      const { toDate } = periods[i];
      const initDate = resetTimeInDate(new Date(toDate as string));
      initDate.setDate(initDate.getDate() + 1);
      isDateReady = isDateOutOfPeriods(initDate, periods);
      if (isDateReady) {
        return transformDateToString(initDate);
      }
    }
  }
  // variant when we find any date
  // that is out of periods
  while (!isDateReady) {
    initDate.setDate(initDate.getDate() + 1);
    isDateReady = isDateOutOfPeriods(initDate, periods);
    if (isDateReady) {
      return transformDateToString(initDate);
    }
  }
};

export const isDateOutOfPeriods = (
  date: Date,
  periods: IPeriodForTable[] | IBreakOffItem[],
  editPeriod?: IPeriodForTable | IBreakOffItem,
) => {
  if (editPeriod && editPeriod?.id) {
    periods = periods.filter((period) => period.id !== editPeriod?.id);
  }
  for (let i = 0; i < periods?.length; i++) {
    const { fromDate, toDate } = periods[i];
    const isDataCross = isDateCrossPeriod(date, fromDate!, toDate!);
    if (isDataCross) {
      return false;
    }
  }
  return true;
};

export const makeTemporaryId = () => {
  const currentDate = new Date();
  const temporaryId = currentDate.getTime();
  const idLikeString = String(temporaryId);
  return idLikeString;
};

export const InitWeekDays: IWeekDays = {
  isDeliveredSun: true,
  isDeliveredMon: true,
  isDeliveredTue: true,
  isDeliveredWed: true,
  isDeliveredThu: true,
  isDeliveredFri: true,
  isDeliveredSat: true,
};

interface IBothDatesFromSamePeriod {
  fromDate: Date;
  toDate: Date;
  periodsData: IPeriodForTable[] | IBreakOffItem[];
  editPeriod?: IPeriodForTable | IBreakOffItem;
}
