import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import SignOutIco from '@mui/icons-material/ExitToApp';

import {
  companyConfigService,
  TCompanyConfigs,
} from '../../services/companyConfig/companyConfig.service.ts';
import { authService } from '../../services/auth/auth.service.ts';
import { redirectIfProduction } from '../../../routes/helper.routes.ts';

export const Logout = ({ label }: ILogout) => {
  const [userInfo, setUserInfo] = useState<TCompanyConfigs['currentUser']>({
    name: 'Keine Daten',
    company: '',
    role: '',
  });
  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const currentUser = companyConfigService.getCachedConfigs()?.currentUser;
    if (currentUser) {
      setUserInfo({ ...currentUser });
    }
    setOpen(false);
    const subSignOut = authService.sub.signOut().subscribe(() => {
      redirectIfProduction({
        fallbackHandler: () => {
          // Reload page to reset all streams values.
          // App will be redirected to the login page after reload.
          window.location.reload();
        },
      });
    });
    return () => {
      //subUserInfo.unsubscribe();
      subSignOut.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const signOut = () => {
    authService.pub.signOut();
    localStorage.clear();
    sessionStorage.clear();
    const cookies = document.cookie.split(';').map((v) => v.trim());
    for (let i = 0, len = cookies.length; i < len; i++) {
      document.cookie = `${cookies[i]}=;expires=${new Date(0).toUTCString()}`;
    }
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker?.controller?.postMessage({ type: 'LOG_OUT' });
    }
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          width: 236,
          maxWidth: 236,
          transition: '250ms',
          ...(!isOpen && { width: 0 }),
        }}
      >
        <Box sx={{ pl: 2 }}>
          <Typography variant='h6' noWrap>
            {userInfo?.name}
          </Typography>
          <Typography variant='body2' noWrap>
            {userInfo?.company}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          minWidth: (theme) => theme.nav.width,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <IconButton onClick={signOut} size='large' title={label}>
          <SignOutIco />
        </IconButton>
      </Box>
    </>
  );
};

interface ILogout {
  label: string;
}
