import { Column } from '../../../../../../shared/components/table/components/common.components.tsx';
import { SplittingEditCell } from './splittingEdit.cell.tsx';
import { GridRowModel } from '@mui/x-data-grid/models/gridRows';
import { IPosition } from '../../../../services/createOrder.service.ts';
import { GridColumnsInitialState } from '@mui/x-data-grid-premium';

export const generateSplittingColumns = (
  count: number,
  enabled: boolean,
  type: 'create' | 'edit',
) => {
  const result: any = [];
  if (count && enabled) {
    for (let i = 0; i < count; i++) {
      result.push(
        <Column
          key={i}
          disableReorder={true}
          field={`splittingPartsPreset_${i}`}
          headerName={romanNumerals[`${i + 1}`]}
          headerAlign='right'
          align='right'
          width={50}
          sortable={false}
          editable
          valueGetter={(params) => params?.row?.splittingPartsPreset?.[i] || ''}
          renderEditCell={(params) => <SplittingEditCell {...{ params, type }} />}
        />,
      );
    }
  }
  return result;
};

export const clearRowFromVirtualKeys = (row: GridRowModel<IPosition>): GridRowModel<IPosition> => {
  const copyRow = structuredClone(row);
  for (const rowKey in copyRow) {
    if (rowKey.includes('splittingPartsPreset_')) {
      delete copyRow[rowKey as keyof IPosition];
    }
  }
  return copyRow;
};

export const romanNumerals: Record<string, string> = {
  '1': 'I',
  '2': 'II',
  '3': 'III',
  '4': 'IV',
  '5': 'V',
  '6': 'VI',
  '7': 'VII',
  '8': 'VIII',
  '9': 'IX',
  '10': 'X',
};

export const attachSplittingToQuantity = (model?: GridColumnsInitialState) => {
  if (model?.orderedFields) {
    model.orderedFields = model?.orderedFields.filter(
      (el: string) => !el.includes('splittingPartsPreset_'),
    );
    const splittingPartsArray: string[] = Array.from(
      { length: 10 },
      (_, i) => `splittingPartsPreset_${i}`,
    );
    let quantityIdx: number = model.orderedFields.findIndex((el: string) => el === 'quantity');
    model.orderedFields.splice(++quantityIdx, 0, ...splittingPartsArray);
  }
  return model;
};
