import { t } from 'i18next';
import { C_Production_Customer_Sorting_Kind } from '../../../../../../../graphql/generatedModel';

export const tourplanDayOptions = [
  {
    id: '0',
    label: t('production.standard_tour'),
  },
  {
    id: '6',
    label: t('production.sat_tour'),
  },
  {
    id: '7',
    label: t('production.sun_tour'),
  },
];

export const customerIndicatorOptions = [
  { id: '0', label: t('common.customer') },
  { id: '1', label: t('common.customers_list') },
];

export const generateCrosstableEasyModeSplitNoOptions = (excludeZero = false) => {
  const options = [];

  if (!excludeZero) {
    options.push({
      id: '0',
      label: t('common.all'),
    });
  }

  for (let i = 1; i <= 10; i++) {
    options.push({
      id: `${i}`,
      label: `${i}`,
    });
  }

  return options;
};

export const customerSortingKindsOptions: {
  id: C_Production_Customer_Sorting_Kind;
  label: string;
}[] = Object.values(C_Production_Customer_Sorting_Kind).map((item) => ({
  id: item,
  label: t(`enums.${C_Production_Customer_Sorting_Kind[item]}`),
}));
