import { map, take, zipWith } from 'rxjs';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';
import { customerService, CustomerTourDataRes } from '../services/customer.service';
import { ITabState } from '../states/tabState.model';
import {
  configsData,
  DictPostSectorsRes,
  DictTourplansRes,
} from '../../../shared/services/configsData/configsData.service';

export const defaultCustomerTourData: CustomerTourDataRes = {
  id: '',
  tourplanNote: null,
  tourplanId: null,
  tourPreparationArea: null,
  tourDepartureTime: null,
  tourPosition: null,
  tourplanSatId: null,
  tourSatPreparationArea: null,
  tourSatDepartureTime: null,
  tourSatPosition: null,
  tourplanSunId: null,
  tourSunPreparationArea: null,
  tourSunDepartureTime: null,
  tourSunPosition: null,
  isAlwaysInTourplan: false,
  transportSectorId: null,
};

export async function resolveDataForTourTab(data: ITabState): Promise<ITabState> {
  data.defaultValues = data?.customerId
    ? await customerService.globHelpers.streamToPromise(
        customerService.sub.customerTourData().pipe(
          responseHandler<CustomerTourDataRes>({
            errorReturnType: defaultCustomerTourData,
          }),
          take(1),
        ),
        () => {
          customerService.pub.customerTourData({ id: data.customerId! });
        },
      )
    : {};

  return data;
}

export async function resolveTourTabOptions(data: ITabState): Promise<ITabState> {
  data.tourTabOptions = await customerService.globHelpers.streamToPromise(
    configsData.sub.dictTourplans().pipe(
      zipWith(configsData.sub.dictPostSectors()),
      responseHandler<TourTabResponseData>({ errorReturnType: [[], []] }),
      map(([tourplanList, postSectorsList]) => ({
        tourplanList,
        postSectorsList,
      })),
    ),
    () => configsData.pub.common(['dictTourplans', 'dictPostSectors']),
  );
  return data;
}

type TourTabResponseData = [DictTourplansRes, DictPostSectorsRes];
