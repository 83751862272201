/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { GridRowParams } from '@mui/x-data-grid-premium';

import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../shared/components/popup/popup.abstract';
import { TPopupDefaultActions } from '../../../../../../../../shared/components/popup/services/popup.service';
import {
  FieldsRow,
  FormTextField,
  FormSelect,
} from '../../../../../../../../shared/components/form';
import { ISelectOption } from '../../../../../../../../shared/components/form/fields/select.component';
import { useBeforeClosePopup } from '../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import {
  customerIndicatorOptions,
  generateCrosstableEasyModeSplitNoOptions,
} from '../../options/generalTab.options';
import {
  DictCustomersRes,
  DictCustomerListsRes,
} from '../../../../../../../../shared/services/configsData/configsData.service';
import { EditPrintListGeneralTabRes } from '../../../../../../services/editPrintList.service.ts';
import { C_Order_Type } from '../../../../../../../../graphql/generatedModel.ts';

export class CrossTableEditPopup extends Popup<ICrossTableEditPopup> {
  Component: FC<TPopupComponent>;

  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const Wrapper = useMemo(() => this.Wrapper, []);
      const { t } = useTranslation();

      const [values, setValues] = useState<ICrossTableEditPopup['values']>(undefined);

      const basePath = `crosstableConfigurationData.${values?.row?.index || 0}`;
      const {
        control,
        setValue,
        getValues,
        resetField,
        formState: { isDirty },
      } = useFormContext();

      const orderTypesOpts = useMemo(() => {
        return Object.values(C_Order_Type).map((type) => ({
          id: type,
          label: t(`enums.${type}`),
        }));
      }, [t]);

      useBeforeClosePopup({
        stream,
        isDirty,
        handlers: () => {
          if (values?.type === 'create') {
            const updatedData = getValues('crosstableConfigurationData')
              .map(
                ({
                  id,
                  ...restFields
                }: EditPrintListGeneralTabRes['crosstableConfigurationData'][number]) => ({
                  ...restFields,
                }),
              )
              .filter((_: never, index: number) => index !== values!.row.index);
            setValue('crosstableConfigurationData', updatedData, {
              shouldDirty: true,
            });

            control._subjects.values.next({
              type: 'change',
              name: 'crosstableConfigurationData',
              values: { crosstableConfigurationData: updatedData },
            });
          } else {
            const fieldsToReset = [
              'categoryName',
              'customerIndicator',
              'categoryCustomerListId',
              'categoryCustomerId',
              'categoryFilterDeliveryType',
              'categoryFilterSplitNo',
            ];

            fieldsToReset.forEach((field) => {
              resetField(`${basePath}.${field}`);
            });
          }
        },
        deps: [values],
      });

      useEffect(() => {
        stream.actionListener('open').subscribe(({ values }) => {
          if (values) setValues(values);
        });
      }, []);

      return (
        <Wrapper
          stream={stream}
          popupTitle={t('production.cross_table_config_edit')}
          PaperProps={{ sx: { maxWidth: '600px', maxHeight: '500px' } }}
          fullWidth
        >
          <Stack direction='column' justifyContent='space-between'>
            <FieldsRow alignItems='center' spacing={1}>
              <FormTextField
                width='100%'
                label={t('production.group_name')}
                name={`${basePath}.categoryName`}
                autoFocus={'categoryName' === values?.field}
              />
            </FieldsRow>
            <FieldsRow alignItems='center' width='100%' spacing={1}>
              <FormSelect
                name={`${basePath}.customerIndicator`}
                label={t('production.filter_by')}
                options={customerIndicatorOptions}
                width='30%'
                autoFocus={'customerIndicator' === values?.field}
                disableClearable
              />
              <FormSelect
                name={`${basePath}.${
                  Number(getValues(`${basePath}.customerIndicator`))
                    ? 'categoryCustomerListId'
                    : 'categoryCustomerId'
                }`}
                label={t(
                  `production.${
                    Number(getValues(`${basePath}.customerIndicator`))
                      ? 'customer_list'
                      : 'customer'
                  }`,
                )}
                options={
                  (Number(getValues(`${basePath}.customerIndicator`))
                    ? values?.customerListsOptions
                    : values?.customersOptions) as ISelectOption[]
                }
                autoFocus={'categoryCustomerOrListId' === values?.field}
                width='70%'
                {...(Number(getValues(`${basePath}.customerIndicator`))
                  ? {}
                  : { datasetattribute: 'customersOptions' })}
              />
            </FieldsRow>
            <FieldsRow alignItems='center' width='100%' spacing={1}>
              <FormSelect
                name={`${basePath}.categoryFilterDeliveryType`}
                label={t('production.order_type')}
                options={orderTypesOpts}
                autoFocus={'categoryFilterDeliveryType' === values?.field}
                width='50%'
              />
              <FormSelect
                name={`${basePath}.categoryFilterSplitNo`}
                label={t('production.split_no')}
                options={generateCrosstableEasyModeSplitNoOptions(true)}
                autoFocus={'categoryFilterSplitNo' === values?.field}
                width='50%'
              />
            </FieldsRow>
          </Stack>
          <Stack mt={3} direction='row' justifyContent='flex-end' spacing={2}>
            <Button onClick={() => stream.emit('close')} variant='contained' sx={{ width: 200 }}>
              {t('common.save')}
            </Button>
          </Stack>
        </Wrapper>
      );
    };
  }
}

interface ICrossTableEditPopup {
  action: TPopupDefaultActions;
  values?: {
    row: GridRowParams['row'];
    field?: string;
    type: 'edit' | 'create';
    customersOptions?: {
      id: DictCustomersRes[number]['id'];
      label: DictCustomersRes[number]['customerNo'] &
        DictCustomersRes[number]['internalOrFullName'];
      customerNo: DictCustomersRes[number]['customerNo'];
      internalOrFullName: DictCustomersRes[number]['internalOrFullName'];
    }[];
    customerListsOptions?: DictCustomerListsRes;
  };
}
