import { Observable, debounce, interval, map } from 'rxjs';
import { Pub, Service, Sub } from '../service.abstract';

type Actions = 'loading';
class PubImpl extends Pub<Actions> {
  loading(loading: boolean) {
    this.emit('loading', loading);
  }
}
class SubImpl extends Sub<Actions> {
  loading(): Observable<boolean> {
    return this.actionListener('loading').pipe(
      map(({ params }) => params),
      debounce((loading) => (loading ? interval(0) : interval(300))),
    );
  }
}

export class LoadingService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}
