import { appState } from '../shared/services/app/app.state';

export enum SW_MESSAGE_TYPE {
  skipWaitng = 'SKIP_WAITING',
  activated = 'ACTIVATED',
  logOut = 'LOG_OUT',
  logOutFromOtherTab = 'LOG_OUT_FROM_OTHER_TAB',
}

export const appInit = () => {
  // Run app version update handler on page load
  // appVersionHandler(
  //   sessionStorageHelper.get(SessionStoragePaths.appVersion || ''),
  //   process.env.REACT_APP_VERSION || '',
  // );

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.onmessage = (event) => {
      // User confirmed app update or force update was executed. Run app version update handler and reload page.
      if (event.data && event.data.type === SW_MESSAGE_TYPE.activated) {
        // appVersionHandler(process.env.REACT_APP_VERSION || '', event.data.version);
        sessionStorage.clear();
        window.location.reload();
      }

      // User logged out in other browser tab
      if (event.data?.type === SW_MESSAGE_TYPE.logOutFromOtherTab) {
        appState.pub.logOutFromOtherTab();
      }
    };
  }
};

/* const appVersionHandler = (currentVersion: string, newVersion: string): void => {
  const [majorCurrent, minorCurrent] = currentVersion?.split?.('.') || [];
  const currentVersionNum: number = Number(`${majorCurrent}.${minorCurrent}`);
  const [majorNew, minorNew] = newVersion?.split?.('.');
  const newVersionNum: number = Number(`${majorNew}.${minorNew}`);
  if (!isNaN(newVersionNum) && !isNaN(currentVersionNum) && newVersionNum > currentVersionNum) {
    // Clear old app version session storage value
  }
  // Set new app version value to the session storage
}; */
