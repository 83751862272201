import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FieldsSection,
  FormNumericField,
} from '../../../../../../../../../../../../shared/components/form';

export const Payment: FC = () => {
  const { t } = useTranslation();

  return (
    <FieldsSection title={t('customer.payment')}>
      <FieldsSection
        titleProps={{ variant: 'body1' }}
        titleBoxSx={{ pt: 0 }}
        nomt
        title={t('customer.paymentDeadline')}
      >
        <FormNumericField
          width='300px'
          name='paymentPeriod'
          precision={0}
          label={t('customer.termOfPaymentInDays')}
        />
      </FieldsSection>
    </FieldsSection>
  );
};
