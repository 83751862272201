import { useEffect } from 'react';
import { IPopupWrapper } from '../components/popup.component.tsx';
import { IBeforeCloseArgs } from '../services/popup.service.ts';

export const useBeforeClosePopup = (args: IUseBeforeClosePopup) => {
  const { isDirty = false, handlers, stream, deps } = args;
  useEffect(() => {
    stream.emitBeforeClose('beforeClose', { isDirty, ...(handlers && { handlers }) });
  }, [isDirty, ...(deps || [])]);
};

interface IUseBeforeClosePopup {
  isDirty?: boolean;
  handlers?: IBeforeCloseArgs['handlers'];
  stream: IPopupWrapper['stream'];
  deps?: Array<unknown>;
}
