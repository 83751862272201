import { FC } from 'react';
import { styled, Stack, StackProps } from '@mui/material';

export const FieldsRow: FC<StackProps> = ({ children, ...props }) => {
  return (
    <SRow spacing={2} direction='row' mt={3} {...props}>
      {children}
    </SRow>
  );
};

const SRow = styled(Stack)({
  '&:first-of-type': {
    marginTop: 0,
  },
});
