import { Amplify, Auth as aws } from 'aws-amplify';
import { Observable, catchError, filter, from, map, of, switchMap } from 'rxjs';
import { environment } from '../../../environment';
import { ListAllCompaniesQuery } from '../../../graphql/generatedModel';
import { gqlClient } from '../../../graphql/graphqlRequest';
import { Pub, Service, Sub } from '../service.abstract.ts';
import { listAllCompanies } from './gql/auth.gql.ts';
import * as Sentry from '@sentry/react';
import { storageHelper } from '../../helpers/storage/index.ts';

export enum Auth {
  loggedIn = 'loggedIn',
  unassigned = 'unassigned',
  userNotFound = 'userNotFound',
  credentialsInvalid = 'credentialsInvalid',
  credentialsEmpty = 'credentialsEmpty',
}
export enum COGNITO_MULTI_DB_USERS {
  WAWI_RDS = 'wawiepic',
  PROD_RDS = 'wawipromax',
}
type Actions = 'signIn' | 'signOut' | 'setAlternativeGuid' | 'listAllCompanies';
class PubImpl extends Pub<Actions> {
  private credentials?: ICredentials;

  signIn(credentials: ICredentials): void {
    this.credentials = credentials;
    this.emit('signIn', credentials);
  }
  signOut(): void {
    this.emit('signOut');
  }
  listAllCompanies(): void {
    this.emit('listAllCompanies', this.credentials);
  }
}

class SubImpl extends Sub<Actions> {
  idToken(): Observable<string> {
    return from(aws.currentSession()).pipe(map((cus) => cus.getIdToken().getJwtToken()));
  }
  signIn(): Observable<IAuth> {
    return this.actionListener('signIn').pipe(
      switchMap(({ params }) => {
        const { username, password } = params as ICredentials;
        return from(aws.signIn({ username, password })).pipe(
          map(() => {
            return {
              status: Auth.loggedIn,
              isMultiDBUser:
                username === COGNITO_MULTI_DB_USERS.WAWI_RDS ||
                username === COGNITO_MULTI_DB_USERS.PROD_RDS,
            };
          }),
          catchError((error: Error) => {
            switch (error.name) {
              case 'UserNotFoundException':
                return of({ status: Auth.userNotFound });
              case 'NotAuthorizedException':
                return of({ status: Auth.credentialsInvalid });
              case 'InvalidParameterException':
                return of({ status: Auth.credentialsEmpty });
              default:
                return of({ status: Auth.unassigned });
            }
          }),
        );
      }),
    );
  }
  signOut(): Observable<any> {
    return this.actionListener('signOut').pipe(
      switchMap(() => {
        return from(aws.signOut());
      }),
    );
  }
  isLoggedIn(): Observable<IAuth> {
    return from(aws.currentAuthenticatedUser()).pipe(
      map(({ attributes, username }) => {
        const companyGuid = attributes['custom:company'];
        const userEmail = attributes['email'];
        Sentry.setUser({ email: userEmail, username });
        Sentry.setTag('company', companyGuid);
        return { status: Auth.loggedIn };
      }),
      catchError(() => of({ status: Auth.unassigned })),
    );
  }
  hasAccess(): Observable<boolean> {
    return from(aws.currentAuthenticatedUser()).pipe(
      map((v) => {
        if (v?.attributes) {
          const { attributes } = v;
          if (attributes?.['custom:role'] === 'admin') {
            return true;
          }
          const rights = attributes['custom:access_rights'];
          try {
            const { chc_sa, faw } = JSON.parse(rights);
            return chc_sa === 'trusted' || (faw && faw !== 'no');
          } catch (e) {
            return false;
          }
        }
        return false;
      }),
      catchError(() => of(false)),
    );
  }
  listAllCompanies(): Observable<ThsCompanies | undefined> {
    return this.actionListener('listAllCompanies').pipe(
      filter(({ params: { username } }) => {
        return (
          username === COGNITO_MULTI_DB_USERS.WAWI_RDS ||
          username === COGNITO_MULTI_DB_USERS.PROD_RDS
        );
      }),
      switchMap(() => {
        return gqlClient(listAllCompanies) as Observable<ListAllCompaniesQuery>;
      }),
      map((companies: ListAllCompaniesQuery) => {
        return companies?.wawiAssist?.listAllCompanies as ThsCompanies;
      }),
      catchError(() => of(undefined)),
    );
  }
  customerGUID(): Observable<string> {
    return from(aws.currentAuthenticatedUser()).pipe(
      map((user) => {
        let guid = storageHelper.session.getItem('alternativeGuid');
        return guid ? guid : user.attributes['custom:company'];
      }),
    );
  }
  userIdentityId(): Observable<string> {
    return from(aws.currentUserCredentials()).pipe(map((cred) => cred.identityId));
  }
}
class AuthService extends Service<Actions> {
  public pub = new PubImpl(this.stream$);
  public sub = new SubImpl(this.stream$);
  constructor() {
    const appEnv = import.meta.env.VITE_REACT_APP_ENV as keyof typeof environment;
    const env = environment[appEnv];
    Amplify.configure(env.aws);
    super();
  }
}
export const authService = new AuthService();

export interface ICredentials {
  username: string;
  password: string;
  alternativeguid?: string;
}
interface IAuth {
  status: Auth;
  isMultiDBUser?: boolean;
}
export type ThsCompanies = NonNullable<
  NonNullable<ListAllCompaniesQuery['wawiAssist']>['listAllCompanies']
>;
