import { Params } from 'react-router-dom';

export type ModuleName = 'article' | 'customer' | 'order' | 'invoice' | 'orderFromProduction';

export type ArticleParams = Params<'entityId' | 'filter'>;
export type CustomerParams = Params<'entityId' | 'filter'>;
export type OrderParams = Partial<Params<'entityId' | 'withDeleted' | 'dateRange' | 'entityNum'>>;
export type InvoiceParams = Params<'entityId' | 'withDeleted' | 'dateRange'>;

export const globalSearchParams = (
  moduleName: ModuleName,
  params: Params,
): GlobalSearchParamsReturn => {
  if (typeof params !== 'object') {
    return undefined;
  }
  switch (moduleName) {
    case 'article': {
      if (checkParams(params as ArticleParams, ['entityId', 'filter']) === false) {
        return undefined;
      }
      const { entityId, filter } = params as ArticleParams;
      return {
        entityId,
        filter: JSON.parse(filter!),
      } as IArticleGlobalSearchParams;
    }

    case 'customer': {
      if (checkParams(params as CustomerParams, ['entityId', 'filter']) === false) {
        return undefined;
      }
      const { entityId, filter } = params as CustomerParams;
      return {
        entityId,
        filter: JSON.parse(filter!),
      } as ICustomerGlobalSearchParams;
    }

    case 'orderFromProduction': {
      if (checkParams(params as OrderParams, ['entityNum']) === false) {
        return undefined;
      }
      const { entityNum } = params as OrderParams;
      return { entityNum } as IOrderFromProductionParams;
    }

    case 'order': {
      if (checkParams(params as OrderParams, ['entityId', 'withDeleted', 'dateRange']) === false) {
        return undefined;
      }
      const { entityId, withDeleted, dateRange } = params as OrderParams;
      return {
        entityId,
        withDeleted: JSON.parse(withDeleted!.toLowerCase()),
        dateRange: JSON.parse(dateRange!),
      } as IOrderGlobalSearchParams;
    }

    case 'invoice': {
      if (
        checkParams(params as InvoiceParams, ['entityId', 'withDeleted', 'dateRange']) === false
      ) {
        return undefined;
      }
      const { entityId, withDeleted, dateRange } = params as InvoiceParams;
      return {
        entityId,
        withDeleted: JSON.parse(withDeleted!.toLowerCase()),
        dateRange: JSON.parse(dateRange!),
      } as IInvoiceGlobalSearchParams;
    }

    default:
      location.href = '/404';
      return undefined;
  }
};

type GlobalSearchParamsReturn =
  | IArticleGlobalSearchParams
  | ICustomerGlobalSearchParams
  | IOrderGlobalSearchParams
  | IOrderFromProductionParams
  | IInvoiceGlobalSearchParams
  | undefined;

export interface IArticleGlobalSearchParams {
  entityId: string;
  filter: { active: boolean };
}
export interface ICustomerGlobalSearchParams {
  entityId: string;
  filter: { active: boolean };
}
export interface IOrderGlobalSearchParams {
  entityId: string;
  withDeleted: boolean;
  dateRange: { from: string; to: string };
}

export interface IOrderFromProductionParams {
  entityNum: string;
}
export interface IInvoiceGlobalSearchParams {
  entityId: string;
  withDeleted: boolean;
  dateRange: { from: string; to: string };
}
function checkParams<T>(params: T, paramNames: Array<keyof T>): boolean {
  return paramNames.every((name) => {
    return typeof params[name] === 'string' ? true : false;
  });
}
