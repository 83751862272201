import React from 'react';
import { useLocation } from 'react-router-dom';
import ProductionIco from '@mui/icons-material/Factory';
import InvoiceIco from '@mui/icons-material/LocalAtm';
import CustomerIco from '@mui/icons-material/Person';
import DebitorIco from '@mui/icons-material/PendingActions';
import SettingsIco from '@mui/icons-material/Settings';
import OrderIco from '@mui/icons-material/ShoppingCartCheckout';
import ArticleIco from '@mui/icons-material/ViewInAr';
import SearchIcon from '@mui/icons-material/Search';

const PageIcon: React.FC = () => {
  const location = useLocation();

  let IconComponent; // Default icon

  if (location.pathname.startsWith('/customer')) {
    IconComponent = CustomerIco;
  } else if (location.pathname.startsWith('/article')) {
    IconComponent = ArticleIco;
  } else if (location.pathname.startsWith('/order')) {
    IconComponent = OrderIco;
  } else if (location.pathname.startsWith('/production')) {
    IconComponent = ProductionIco;
  } else if (location.pathname.startsWith('/invoice')) {
    IconComponent = InvoiceIco;
  } else if (location.pathname.startsWith('/debitor')) {
    IconComponent = DebitorIco;
  } else if (location.pathname.startsWith('/settings')) {
    IconComponent = SettingsIco;
  } else if (location.pathname.startsWith('/globalSearch')) {
    IconComponent = SearchIcon;
  }

  return IconComponent ? <IconComponent color='primary' /> : null;
};
export default PageIcon;
