/**
 * Abstract StorageHelper class for storage(local | session) implementation
 */
export abstract class StorageHelper<T extends string> {
  protected abstract set_item(key: string, value: any): void;
  setItem(key: T, value: any): void {
    if (value === undefined || typeof value === 'function' || typeof value === 'symbol') {
      throw Error("Storage -> setItem: value can't be undefined,function or symbol");
    }
    this.set_item(key, value);
  }
  protected abstract get_item(key: string): any;
  getItem(key: T): any {
    return this.get_item(key);
  }

  protected abstract remove_item(key: string): void;
  removeItem(key: T): void {
    this.remove_item(key);
  }
  protected abstract clear_all(): void;
  clearAll(): void {
    this.clear_all();
  }
}
