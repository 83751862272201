import React, { FC, useCallback } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { orderDetailsState as orderDetailsStateCreate } from '../../../../create/states/orderDetails/orderDetails.state.ts';
import { orderDetailsState as orderDetailsStateEdit } from '../../../../edit/states/orderDetails.state.ts';
import { TableNumericField } from '../../../../../../shared/components/table/styles/styleFields.ts';
import { clearRowFromVirtualKeys } from '../splitting/additionalFunctions.tsx';
import { useEnterKey } from '../../hooks/useEnterKey.ts';

export const QuantityEditCell: FC<TQuantityEditCell> = ({ api, type, ...params }) => {
  const { setEditCellValue, stopCellEditMode } = api;
  const { value, field, id, formattedValue, row } = params;
  const { handleEnterKey } = useEnterKey({ type, api });

  const updateCell = useCallback(
    (v: number | null) => {
      setEditCellValue({ id, field, value: v });
      if (Number(formattedValue) !== v) {
        const clearedRow = clearRowFromVirtualKeys(row);
        (type === 'create' ? orderDetailsStateCreate : orderDetailsStateEdit).pub.updateCell(
          { ...clearedRow, quantity: v },
          'quantityCell',
        );
      }
    },
    [params],
  );

  const handleKeyDown = (v: React.KeyboardEvent) => {
    if (v.target instanceof HTMLInputElement && v.code === 'Enter') {
      handleEnterKey(field, id);
      const value = v.target.value.replace(/,/g, '.');
      updateCell(Number(value));
      stopCellEditMode({ id, field });
    }
  };
  return (
    <TableNumericField
      onKeyDown={handleKeyDown}
      onBlur={updateCell}
      min={-10000000}
      autoFocus
      precision={3}
      noTrailingZeros
      value={value}
    />
  );
};

type TQuantityEditCell = GridRenderEditCellParams & { type: 'create' | 'edit' };
