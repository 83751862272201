/* todo use for auxiliary queries */

import { Pub, Service, Sub } from '../../../services/service.abstract.ts';
import {
  GetDictDebtReminderQuery,
  GetDictDebtReminderQueryVariables,
  GetDictQuantityDiscountQuery,
  GetDictQuantityDiscountQueryVariables,
  GetDictWa_BankQuery,
  GetDictWa_BankQueryVariables,
  GetDictWa_TextBlockQuery as GetDictTextBlockQuery,
  GetDictWa_TextBlockQueryVariables as GetDictTextBlockQueryVariables,
  GetDictWa_TransportSectorQuery,
  GetDictWa_TransportSectorQueryVariables,
  GetDictWa_CustomerProfileQuery,
  GetDictWa_CustomerProfileQueryVariables,
} from '../../../../graphql/generatedModel.ts';
import { map, Observable, switchMap } from 'rxjs';
import { gqlClient } from '../../../../graphql/graphqlRequest.ts';
import {
  dictSingleBank,
  getDictCustomerProfile,
  getDictDebtReminder,
  getDictQuantityDiscount,
  getDictTextBlock,
  getDictTransportSector,
} from './gql/dictionary.gql.ts';
import { PeriodConditions } from '../../../../pages/customer/services/customer.service.ts';

type Actions =
  | 'getDictTextBlock'
  | 'getDictQuantityDiscount'
  | 'getDictTransportSector'
  | 'getDictCustomerProfile'
  | 'getDictSingleBank'
  | 'getDictDebtReminder';

class PubImpl extends Pub<Actions> {
  getDictTextBlock(params: GetDictTextBlockQueryVariables) {
    this.emit('getDictTextBlock', params);
  }
  getDictTransportSector(params: GetDictWa_TransportSectorQueryVariables) {
    this.emit('getDictTransportSector', params);
  }
  getDictCustomerProfile(params: GetDictWa_CustomerProfileQueryVariables) {
    this.emit('getDictCustomerProfile', params);
  }
  getDictQuantityDiscount(params: GetDictQuantityDiscountQueryVariables) {
    this.emit('getDictQuantityDiscount', params);
  }
  getDictSingleBank(params: GetDictWa_BankQueryVariables) {
    this.emit('getDictSingleBank', params);
  }
  getDictDebtReminder(params: GetDictDebtReminderQueryVariables) {
    this.emit('getDictDebtReminder', params);
  }
}
class SubImpl extends Sub<Actions> {
  getDictTextBlock(): Observable<GetDictTextBlockRes> {
    return this.actionListener('getDictTextBlock').pipe(
      switchMap(({ params }) => {
        return gqlClient(getDictTextBlock, params);
      }),
      map((data: GetDictTextBlockQuery) => {
        return data.wawiAssist?.getDictWA_TextBlock as GetDictTextBlockRes;
      }),
    );
  }
  getDictTransportSector(): Observable<GetDictTransportSectorRes> {
    return this.actionListener('getDictTransportSector').pipe(
      switchMap(({ params }) => {
        return gqlClient(getDictTransportSector, params);
      }),
      map((data: GetDictWa_TransportSectorQuery) => {
        return data.wawiAssist?.getDictWA_TransportSector as GetDictTransportSectorRes;
      }),
    );
  }
  getDictCustomerProfile(): Observable<GetDictCustomerProfileRes> {
    return this.actionListener('getDictCustomerProfile').pipe(
      switchMap(({ params }) => {
        return gqlClient(getDictCustomerProfile, params);
      }),
      map((v: GetDictWa_CustomerProfileQuery) => {
        const {
          customerDeliveryPeriodsSettings,
          isDeliveredFri,
          isDeliveredMon,
          isDeliveredSun,
          isDeliveredThu,
          isDeliveredSat,
          isDeliveredTue,
          isDeliveredWed,
          ...rest
        } = (v.wawiAssist?.getDictWA_CustomerProfile || {}) as GetDictCustomerProfileDataFromServer;

        return {
          ...rest,
          periods: customerDeliveryPeriodsSettings?.customerRegularDeliveryPeriods,
          periodsWithCustomWeekDays:
            customerDeliveryPeriodsSettings?.customerCustomWeekDaysAndDeliveryPeriods?.map(
              ({ id, fromDate, toDate, description, isRepeatYearly, ...weekDays }) => ({
                period: {
                  fromDate,
                  toDate,
                  isRepeatYearly,
                },
                weekDays,
                description,
                id,
              }),
            ),
          vacations: customerDeliveryPeriodsSettings?.customerVacations,
          weekDays: {
            isDeliveredFri,
            isDeliveredMon,
            isDeliveredSun,
            isDeliveredThu,
            isDeliveredSat,
            isDeliveredTue,
            isDeliveredWed,
          },
        };
      }),
    );
  }
  getDictQuantityDiscount(): Observable<GetDictQuantityDiscountQueryRes> {
    return this.actionListener('getDictQuantityDiscount').pipe(
      switchMap(({ params }) => {
        return gqlClient(getDictQuantityDiscount, params);
      }),
      map((data: GetDictQuantityDiscountQuery) => {
        return data.wawiAssist?.getDictWA_QuantityDiscount
          .discountValues as GetDictQuantityDiscountQueryRes;
      }),
    );
  }
  getDictSingleBank(): Observable<DictSingleBankRes> {
    return this.actionListener('getDictSingleBank').pipe(
      switchMap(({ params }) => {
        return gqlClient(dictSingleBank, params);
      }),
      map((data: GetDictWa_BankQuery) => {
        return data.wawiAssist?.getDictWA_Bank as DictSingleBankRes;
      }),
    );
  }
  getDictDebtReminder(): Observable<GetDictDebtReminderRes> {
    return this.actionListener('getDictDebtReminder').pipe(
      switchMap(({ params }) => {
        return gqlClient(getDictDebtReminder, params);
      }),
      map((data: GetDictDebtReminderQuery) => {
        return data.wawiAssist?.getDictWA_DebtReminder as GetDictDebtReminderRes;
      }),
    );
  }
}

class DictionaryAdditionalService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}
export const dictionaryAdditionalService = new DictionaryAdditionalService();

export type GetDictTextBlockRes = NonNullable<
  NonNullable<GetDictTextBlockQuery['wawiAssist']>['getDictWA_TextBlock']
>;
export type GetDictQuantityDiscountQueryRes = NonNullable<
  NonNullable<
    GetDictQuantityDiscountQuery['wawiAssist']
  >['getDictWA_QuantityDiscount']['discountValues']
>;

export type DictSingleBankRes = NonNullable<
  NonNullable<GetDictWa_BankQuery['wawiAssist']>['getDictWA_Bank']
>;
export type GetDictTransportSectorRes = NonNullable<
  NonNullable<GetDictWa_TransportSectorQuery['wawiAssist']>['getDictWA_TransportSector']
>;
export type GetDictCustomerProfileDataFromServer = NonNullable<
  NonNullable<GetDictWa_CustomerProfileQuery['wawiAssist']>['getDictWA_CustomerProfile']
>;
export type GetDictCustomerProfileRes = Omit<
  GetDictCustomerProfileDataFromServer,
  | 'customerDeliveryPeriodsSettings'
  | 'isDeliveredFri'
  | 'isDeliveredMon'
  | 'isDeliveredSun'
  | 'isDeliveredThu'
  | 'isDeliveredSat'
  | 'isDeliveredTue'
  | 'isDeliveredWed'
> &
  PeriodConditions;
export type GetDictDebtReminderRes = NonNullable<
  NonNullable<GetDictDebtReminderQuery['wawiAssist']>['getDictWA_DebtReminder']
>;
