import {
  companyConfigService,
  TCompanyConfigs,
} from '../../services/companyConfig/companyConfig.service.ts';

class FontHelper {
  private readonly config;

  constructor(config: TCompanyConfigs | undefined) {
    this.config = config;
  }

  private parseWindowsColor(color: string): string {
    if (color?.toLowerCase()?.includes('window')) {
      return 'black';
    } else if (color?.startsWith('cl')) {
      return color?.substring(2);
    } else {
      return 'black';
    }
  }

  /* options is an array of values from which the resulting object will be formed (pass nothing if all data is needed) */
  getDefaultFont(options?: TFontOptions): IFont | undefined {
    const uniqueOptions = Array.from(new Set(options));
    if (this.config?.defaultFontOfNoteInOrder) {
      const fontArr = this.config.defaultFontOfNoteInOrder?.split(',');
      return {
        ...((!uniqueOptions.length || uniqueOptions.includes('family')) && { family: fontArr[0] }),
        ...((!uniqueOptions.length || uniqueOptions.includes('fontSize')) && {
          fontSize: Number(fontArr[1]),
        }),
        ...((!uniqueOptions.length || uniqueOptions.includes('attributes')) && {
          attributes: {
            bold: fontArr[2]?.includes('B'),
            italic: fontArr[2]?.includes('I'),
            underline: fontArr[2]?.includes('U'),
          },
        }),
        ...((!uniqueOptions.length || uniqueOptions.includes('color')) && {
          color: this.parseWindowsColor(fontArr[4])?.toLowerCase(),
        }),
      };
    }
  }
}
export const fontHelper = new FontHelper(companyConfigService.getCachedConfigs());

interface IFont {
  family?: string;
  fontSize?: number;
  attributes?: {
    bold: boolean;
    underline: boolean;
    italic: boolean;
  };
  color?: string;
}

type TFontOptions = (keyof IFont)[];
