import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { GeneralForm } from './components/generalForm.component.tsx';
import { TMode } from '../../../../../shared/services/mode/mode.service.ts';
import { generalTabState } from './generalTab.state.ts';

export const GeneralTab: FC<IGeneralTabProps> = ({ mode, loading }) => {
  const { getValues } = useFormContext();
  return (
    <GeneralForm
      loading={loading}
      handleSubmitValue={(v) => {
        generalTabState.pub.save({
          ...v,
          id: getValues()['id'],
        });
      }}
      mode={mode}
    />
  );
};

export interface IGeneralTabProps {
  loading: boolean;
  mode: TMode;
}
