import { Alert, AlertTitle } from '@mui/material';
import { forwardRef, ReactElement, useCallback } from 'react';
import { CustomContentProps, useSnackbar } from 'notistack';
import { AlertProps } from '@mui/material/Alert/Alert';
import { LoadingContent } from './content/loadingContent.component';
import { t } from 'i18next';
import { SIconButton } from '../table/components/common.components';
import RefreshIcon from '@mui/icons-material/Refresh';

interface ISnackbarContentProps extends CustomContentProps {
  showReload?: boolean;
}

const SnackbarComponent = forwardRef<HTMLDivElement, ISnackbarContentProps>(
  ({ id, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    const isLoading = props.type === 'loading';

    const severity = isLoading ? 'info' : (props.type as AlertProps['severity']);

    let content = (
      <>
        {props.title && <AlertTitle>{props.title}</AlertTitle>}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {props.message}
          {props?.showReload && (
            <SIconButton
              sx={{ ml: 1 }}
              onClick={() => window.location.reload()}
              title={t('common.refresh')}
            >
              <RefreshIcon />
            </SIconButton>
          )}
        </div>
      </>
    );

    if (isLoading) {
      content = <LoadingContent text={props.message} />;
    }

    return (
      <Alert
        ref={ref}
        {...(!props?.showReload && { onClose: handleDismiss })}
        severity={severity}
        variant={props.view || 'standard'}
        sx={{ width: '100%', maxWidth: props?.showReload ? 400 : 300, minWidth: 300 }}
      >
        {content}
      </Alert>
    );
  },
);

SnackbarComponent.displayName = 'ReportComplete';

declare module 'notistack' {
  interface OptionsObject {
    title?: string | ReactElement;
    type: AlertProps['severity'] | 'loading';
    view?: AlertProps['variant'];
    showReload?: ISnackbarContentProps['showReload'];
  }
}

export default SnackbarComponent;
