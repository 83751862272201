import { Observable, map, merge } from 'rxjs';
import { Pub, State, Sub } from '../../../../shared/state/state.abstract';
import { storageHelper } from '../../../../shared/helpers/storage';

export const initOverviewState: IOverviewState = {
  action: undefined,
  datePeriod: { fromDate: '', toDate: '' },
  deliveryNoteGroupId: null,
};

class PubImpl extends Pub<IOverviewState> {
  init(params: Partial<IOverviewState>) {
    this.emit('init', params);
  }
  updateDatePeriod(datePeriod: IOverviewState['datePeriod']) {
    storageHelper.session.setItem('overview.datePeriod', datePeriod);
    this.emit('updateDatePeriod', { datePeriod });
  }
  updateDeliverynoteGroupId(deliveryNoteGroupId: IOverviewState['deliveryNoteGroupId']) {
    storageHelper.session.setItem('overview.deliveryNoteGroupId', deliveryNoteGroupId);
    this.emit('updateDeliverynoteGroupId', { deliveryNoteGroupId });
  }
}

class SubImpl extends Sub<IOverviewState> {
  protected actionHandlers(): Observable<IOverviewState> {
    return merge(this.updateState()).pipe(
      map((state) => {
        this.stream$.next({ ...state, action: 'internalUpdate' });
        return state;
      }),
    );
  }

  private updateState(): Observable<IOverviewState> {
    return this.actionListener(['updateDatePeriod', 'updateDeliverynoteGroupId']);
  }
}

class OverviewState extends State<IOverviewState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const overviewState = new OverviewState(initOverviewState);

export interface IOverviewState {
  action: 'init' | 'updateDatePeriod' | 'internalUpdate' | 'updateDeliverynoteGroupId' | undefined;
  datePeriod: {
    fromDate: string;
    toDate: string;
  };
  deliveryNoteGroupId: number | null;
}
