import { FC, useEffect, useMemo, useState } from 'react';
import {
  FieldsRow,
  FieldsSection,
  FormCheckbox,
  FormNumericField,
  FormSelect,
} from '../../../../../../../shared/components/form';
import { ProductionTabOptionsRes } from '../../../../../services/article.service';
import { useFormContext } from 'react-hook-form';
import { productionTabState } from '../../production.state';
import { useTranslation } from 'react-i18next';
import { ISelectOption } from '../../../../../../../shared/components/form/fields/select.component';
import { storageHelper } from '../../../../../../../shared/helpers/storage';
import { resetArticleDeps } from '../../../../../states/articleList.state.ts';

export const GeneralSection: FC<IGeneralSection> = ({
  dictRecipesWithConnectedArticles,
  productionRecipeValidation,
}) => {
  const { t } = useTranslation();
  const { watch, formState, setValue } = useFormContext();
  const [loading, setLoading] = useState<boolean>(false);
  const recipeOptions = useMemo(
    () =>
      dictRecipesWithConnectedArticles.map(({ recipeName, recipeId, recipeNo, ...rest }) => ({
        id: recipeId,
        label: recipeName,
        disabled: rest.connectedArticleId !== null,
        itemNo: recipeNo,
        ...rest,
      })),
    [dictRecipesWithConnectedArticles],
  );

  useEffect(() => {
    setLoading(productionRecipeValidation);
  }, [productionRecipeValidation]);

  useEffect(() => {
    const unsubLoading = productionTabState.sub.loading().subscribe(setLoading);
    return () => unsubLoading.unsubscribe();
  }, []);

  const { isFractionArticle, isDough } = watch() || {};

  return (
    <FieldsSection titleBoxSx={{ pt: 0 }} nomt title={t('article.general')}>
      {!isFractionArticle && (
        <FieldsRow>
          <FormCheckbox
            label={t('article.display_on_production_print_lists')}
            name='isShowOnProductionPrintLists'
          />
        </FieldsRow>
      )}
      <FieldsRow>
        <FormSelect
          error={Boolean(formState.errors['productionRecipeId'])}
          helperText={
            formState.errors['productionRecipeId']
              ? t(String(formState.errors['productionRecipeId'].message))
              : ''
          }
          datasetattribute='productionRecipeOptions'
          getOptionDisabled={(option) => !!option.disabled}
          loading={loading}
          filterOptions={filterOptions}
          onChange={(id) => {
            storageHelper.memory.removeItem('configsData.dictRecipesWithConnectedArticles');
            if (!id) return setValue('articleDoughDataFromRA.intermediateWeight', 0);
          }}
          name='productionRecipeId'
          label={t('article.recipe')}
          width='300px'
          componentsProps={{
            paper: {
              sx: {
                width: 420,
              },
            },
          }}
          options={(recipeOptions as []) || []}
        />
        <FormCheckbox
          label={t('article.dough_item')}
          name='isDough'
          onChange={() => resetArticleDeps()}
        />
        <FormCheckbox
          label={t('article.fermentation_interrupt')}
          name='isFermentationInterrupt'
          onChange={() => resetArticleDeps(true)}
        />
      </FieldsRow>
      {isDough ? (
        <FieldsRow>
          <FormNumericField
            key={isDough}
            controls
            precision={0}
            width='300px'
            label={t('article.kg_pro_charge')}
            name='doughSplittingDough1Amount'
          />
          <FormCheckbox label={t('article.charge_splitting')} name='isDoughSplitting' />
        </FieldsRow>
      ) : (
        <FieldsRow>
          <FormNumericField
            key={isDough}
            name='daysToProduce'
            label={t('article.days_to_produce')}
            precision={0}
            controls={false}
            width='300px'
          />
        </FieldsRow>
      )}
    </FieldsSection>
  );
};

const filterOptions = (
  options: ExtendedSelectOption[] = [],
  { inputValue }: { inputValue: string },
): ExtendedSelectOption[] => {
  const filteredOptions = options?.filter(({ label, itemNo }) => {
    const isLableSame = label?.toLowerCase().includes(inputValue?.toLowerCase());
    const isNumberSame = itemNo?.toLowerCase().includes(inputValue?.toLowerCase());
    return isLableSame || isNumberSame;
  });
  return filteredOptions;
};

interface ExtendedSelectOption extends ISelectOption {
  itemNo?: string;
}

interface IGeneralSection {
  dictRecipesWithConnectedArticles: ProductionTabOptionsRes['dictRecipesWithConnectedArticles'];
  productionRecipeValidation: boolean;
}
