import { gql } from 'graphql-request';

/* QUERIES start */
export const openDebtsGridData = gql`
  query openDebtsGridData(
    $invoicesFromDate: Date!
    $invoicesToDate: Date!
    $paymentsUpToDate: Date!
  ) {
    wawiAssist {
      listWA_OpenReceivables(
        invoicesFromDate: $invoicesFromDate
        invoicesToDate: $invoicesToDate
        paymentsUpToDate: $paymentsUpToDate
      ) {
        invoiceId
        invoiceDate
        invoiceNo
        customerId
        customerNo
        internallOrFullName
        totalAmount
        residualAmount
        paymentDeadline
        fullPaymentDate
        delayInDays
        remindersCount
      }
    }
  }
`;
export const listPaymentsForInvoice = gql`
  query ListPaymentsForInvoice($invoiceId: ID!) {
    wawiAssist {
      listWA_PaymentsForInvoice(invoiceId: $invoiceId) {
        id
        date
        amount
        accountNo
        receiptNo
        description
        isViaOverpayment
      }
    }
  }
`;
export const overpaymentsAmountAvailable = gql`
  query OverpaymentsAmountAvailable($invoiceId: ID!) {
    wawiAssist {
      getWA_OverpaymentsAmountAvailable(invoiceId: $invoiceId)
    }
  }
`;
export const getOpenReceivablesReminder = gql`
  query getOpenReceivablesReminder($invoiceId: ID!) {
    wawiAssist {
      getWA_OpenReceivablesReminders(invoiceId: $invoiceId)
    }
  }
`;
/* QUERIES end */
/* MUTATIONS start */
export const createPaymentForInvoice = gql`
  mutation CreatePaymentForInvoice($paymentProps: WA_PaymentForInvoiceInputs!) {
    wawiAssist {
      createWA_PaymentForInvoice(paymentProps: $paymentProps)
    }
  }
`;
export const deletePaymentForInvoice = gql`
  mutation DeletePaymentForInvoice($paymentId: ID!) {
    wawiAssist {
      deleteWA_PaymentForInvoice(paymentId: $paymentId)
    }
  }
`;
export const createPaymentForInvoiceFromOverpayments = gql`
  mutation CreatePaymentForInvoiceFromOverpayments($invoiceId: ID!) {
    wawiAssist {
      createWA_PaymentForInvoiceFromOverpayments(invoiceId: $invoiceId)
    }
  }
`;
export const sendReminderOfInvoiceEmail = gql`
  mutation SendReminderOfInvoiceEmail(
    $emailParams: WA_SendReminderOfInvoiceEmailInput!
    $invoiceIds: [ID!]!
  ) {
    wawiAssist {
      sendWA_ReminderOfInvoiceEmail(emailParams: $emailParams, invoiceIds: $invoiceIds) {
        sentMessages {
          messageId
        }
        unsentMessages {
          recipient
        }
      }
    }
  }
`;
export const saveOpenReceivablesReminders = gql`
  mutation SaveOpenReceivablesReminders($invoiceId: ID!, $remindersDetails: [DateTime]!) {
    wawiAssist {
      saveWA_OpenReceivablesReminders(invoiceId: $invoiceId, remindersDetails: $remindersDetails)
    }
  }
`;
/* MUTATIONS end */
