import { gql } from 'graphql-request';

/* CUSTOMER and INVOICE queries */

export const dictCustomers = gql`
  query dictWA_Customers {
    wawiAssist {
      dictWA_Customers {
        id
        customerNo
        internalOrFullName
        isActive
      }
    }
  }
`;

export const dictCustomerGroups = gql`
  query dictWA_CustomerGroups {
    wawiAssist {
      dictWA_CustomerGroups {
        id
        label: name
      }
    }
  }
`;
export const dictCustomerLists = gql`
  query dictWA_CustomerLists {
    wawiAssist {
      dictWA_CustomerLists {
        id
        label: name
      }
    }
  }
`;
export const dictTransportSectors = gql`
  query dictWA_TransportSectors {
    wawiAssist {
      dictWA_TransportSectors {
        id
        label: name
      }
    }
  }
`;
export const dictQuantityDiscounts = gql`
  query dictWA_QuantityDiscounts {
    wawiAssist {
      dictWA_QuantityDiscounts {
        id
        label: name
      }
    }
  }
`;
export const dictCustomerProfiles = gql`
  query dictWA_CustomerProfiles {
    wawiAssist {
      dictWA_CustomerProfiles {
        id
        label: name
      }
    }
  }
`;
export const dictTourplans = gql`
  query dictWA_Tourplans {
    wawiAssist {
      dictWA_Tourplans {
        id
        label: name
      }
    }
  }
`;
export const dictStorageLocations = gql`
  query dictWA_StorageLocations {
    wawiAssist {
      dictWA_StorageLocations {
        id
        label: name
      }
    }
  }
`;
/* END OF CUSTOMER, PRODUCTION and INVOICE queries */

/* START OF ORDER queries */

export const listArticlesForSelectInOrderPosition = gql`
  query ListWA_ArticlesForSelectInOrderPosition {
    wawiAssist {
      listWA_ArticlesForSelectInOrderPosition {
        id
        articleNo
        label: description
        vatCode
        quantityPerLot
        saleUnit
        weight
        warningMsgOnSelect
        isDeliveredMon
        isDeliveredTue
        isDeliveredWed
        isDeliveredThu
        isDeliveredFri
        isDeliveredSat
        isDeliveredSun
        specialAssortmentGroups
        isActive
        isDough
        vatCode
        eanCode
      }
    }
  }
`;

/* END OF ORDER queries */

/* REST queries */

export const dictDeliveryNoteGroups = gql`
  query dictWA_DeliveryNoteGroups {
    wawiAssist {
      dictWA_DeliveryNoteGroups {
        id
        label: name
      }
    }
  }
`;

export const dictTextBlocks = gql`
  query dictWA_TextBlocks {
    wawiAssist {
      dictWA_TextBlocks {
        id
        label: description
        isDefaultForInvoice
        isDefaultForOrder
        isDefaultForOffer
      }
    }
  }
`;

export const dictPriceCategories = gql`
  query dictWA_PriceCategories {
    wawiAssist {
      dictWA_PriceCategories {
        id
        label: name
        vat
      }
    }
  }
`;

export const dictArticleLists = gql`
  query dictWA_ArticleLists {
    wawiAssist {
      dictWA_ArticleLists {
        id
        label: name
      }
    }
  }
`;

export const dictAssortmentGroups = gql`
  query dictWA_AssortmentGroups {
    wawiAssist {
      dictWA_AssortimentGroups {
        id
        label: name
      }
    }
  }
`;

export const dictCashAssistProductGroups = gql`
  query dictWA_CashAssistProductGroups {
    wawiAssist {
      dictWA_CashAssistProductGroups {
        id
        label: name
      }
    }
  }
`;

export const dictCashAssistTemplates = gql`
  query dictWA_CashAssistTemplates {
    wawiAssist {
      dictWA_CashAssistTemplates {
        id
        label: name
      }
    }
  }
`;
export const dictDiscountGroups = gql`
  query dictWA_DiscountGroups {
    wawiAssist {
      dictWA_DiscountGroups {
        id
        label: name
      }
    }
  }
`;

export const dictMarketingGroups = gql`
  query dictWA_MarketingGroups {
    wawiAssist {
      dictWA_MarketingGroups {
        id
        label: name
      }
    }
  }
`;

export const dictProductionGroups = gql`
  query dictWA_ProductionGroups {
    wawiAssist {
      dictWA_ProductionGroups {
        id
        label: name
      }
    }
  }
`;

export const dictBanks = gql`
  query dictWA_Banks {
    wawiAssist {
      dictWA_Banks {
        id
        name
        internalName
        label: internalNameOrName
      }
    }
  }
`;

export const dictShippingPoints = gql`
  query dictWA_ShippingPoints {
    wawiAssist {
      dictWA_ShippingPoints {
        id
        label: name
      }
    }
  }
`;

export const dictRecipesWithConnectedArticles = gql`
  query dictWA_RecipesWithConnectedArticles {
    wawiAssist {
      dictWA_RecipesWithConnectedArticles {
        recipeId
        recipeNo
        recipeName
        connectedArticleId
        connectedArticleNo
        connectedArticleName
      }
    }
  }
`;

export const dictArticles = gql`
  query dictWA_Articles {
    wawiAssist {
      dictWA_Articles {
        articleNo
        description
        id
        isDough
        isActive
        isFractionArticle
        isFermentationInterrupt
      }
    }
  }
`;

export const dictDebtReminders = gql`
  query DictWA_DebtReminders {
    wawiAssist {
      dictWA_DebtReminders {
        id
        label: name
      }
    }
  }
`;
export const dictProductionPrintLists = gql`
  query DictWA_ProductionPrintLists {
    wawiAssist {
      dictWA_ProductionPrintLists {
        id
        label: name
        reportId
      }
    }
  }
`;

/* END OF REST queries */
