import { keyframes } from '@mui/material';

export const fadeOut = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;
export const fadeIn = keyframes`
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
`;
