import { articleDe, articleEn } from './pages/article/article.i18n';
import { customerDe, customerEn } from './pages/customer/customer.i18n.ts';
import { productionDe, productionEn } from './pages/production/production.i18n.ts';
import { commonDe, commonEn } from './shared/i18n/common.i18n.ts';
import { enumsDe, enumsEn } from './shared/i18n/enums.i18n.ts';
import { orderDe, orderEn } from './pages/order/order.i18n';
import { invoiceDe, invoiceEn } from './pages/invoice/invoice.i18n.ts';
import { errorsDe, errorsEn } from './shared/i18n/errors.i18n.ts';
import { settingsDe, settingsEn } from './pages/settings/settings.i18n.ts';
import { debitorDe, debitorEn } from './pages/debitor/debitor.i18n.ts';

const de = {
  ...commonDe,
  ...enumsDe,
  ...articleDe,
  ...orderDe,
  ...customerDe,
  ...productionDe,
  ...invoiceDe,
  ...debitorDe,
  ...errorsDe,
  ...settingsDe,
};
const en = {
  ...commonEn,
  ...enumsEn,
  ...articleEn,
  ...orderEn,
  ...customerEn,
  ...productionEn,
  ...invoiceEn,
  ...debitorEn,
  ...errorsEn,
  ...settingsEn,
};

export const i18nResorces = {
  de: { translation: de },
  en: { translation: en },
};
