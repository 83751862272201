import { Observable, Subject, filter, finalize, map, mergeMap, repeat, takeWhile } from 'rxjs';
import { IStream, Pub, Service, Sub } from '../../../../../shared/services/service.abstract';
import { gqlClient } from '../../../../../graphql/graphqlRequest';
import { getTasksStatus } from '../../../services/gql/winReports.gql';
import {
  C_Win_Report_Task_Status,
  GetTasksStatusQuery,
  GetTasksStatusQueryVariables,
} from '../../../../../graphql/generatedModel';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler';

class PubImpl extends Pub<Action> {
  startPolling(tasksId: GetTasksStatusQueryVariables) {
    this.emit('startPolling', tasksId);
  }

  cancelPolling() {
    this.emit('cancelPolling');
  }

  resetStream() {
    this.emit(undefined);
  }
}

class SubImpl extends Sub<Action> {
  cancelPolling: boolean;
  constructor(stream$: Subject<IStream<Action>>) {
    super(stream$);
    this.cancelPolling = false;
  }

  polling(): Observable<GetWinReportsStatusRes> {
    return this.stream$.pipe(
      filter(({ action }) => {
        if (action === 'cancelPolling') {
          this.cancelPolling = true;
        } else if (action === 'startPolling') {
          this.cancelPolling = false;
        }
        return action === 'startPolling';
      }),
      mergeMap(({ params }: { params: GetTasksStatusQueryVariables }) => {
        return gqlClient(getTasksStatus, params).pipe(
          responseHandler<GetTasksStatusQuery>({ errorReturnType: {}, quite: true }),
          map((data: GetTasksStatusQuery) => {
            if (!data?.wawiAssist?.getWinReportsStatus) {
              return (params.tasksId as number[]).map((taskId) => ({
                taskId: taskId,
                status: C_Win_Report_Task_Status.WRTS3_ERROR_BUILDING,
              }));
            }
            return data.wawiAssist.getWinReportsStatus;
          }),
          repeat({ delay: 1000 }),
          // Continue polling untill cancel polling or when status is in progress or is 'not started'
          takeWhile((statuses) => {
            const continuePolling = statuses.some(
              (item) =>
                item?.status === C_Win_Report_Task_Status.WRTS5_IN_PROGRESS ||
                item?.status === C_Win_Report_Task_Status.WRTS1_NOT_STARTED,
            );

            return this.cancelPolling ? false : continuePolling;
          }, true),
        );
      }),
      finalize(() => workerService.pub.resetStream()),
    );
  }
}

class WorkerService extends Service<Action> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const workerService = new WorkerService();

type Action = 'startPolling' | 'cancelPolling' | undefined;

type GetWinReportsStatusRes = NonNullable<
  NonNullable<GetTasksStatusQuery['wawiAssist']>['getWinReportsStatus']
>;
