import { FC, useMemo, useState } from 'react';
import {
  FieldsRow,
  FieldsSection,
  FormDatePicker,
  FormNumericField,
  FormSelect,
  FormTextField,
  SelectMenuWithFooter,
} from '../../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import { NoteButton as IndividualButton } from '../../../../../../../shared/components/form/buttons/noteButton.component.tsx';
import SettingsIcon from '@mui/icons-material/Settings';
import { TabPanel } from '../../../../../../../shared/components/tabs';
import { Box, CircularProgress, InputAdornment, Stack, Tab, Tabs } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { valueHelper } from '../../../../../../../shared/helpers/valueProcessor/value.helper.ts';
import { C_Country, C_Direct_Debit_Banks } from '../../../../../../../graphql/generatedModel.ts';
import { IConditionsTabOptions } from '../../../../../states/tabState.model.ts';
import { ISelectProps } from '../../../../../../../shared/components/form/fields/select.component.tsx';
import { conditionsTabState } from '../../conditions.state.ts';
import { CustomerConditionsRes } from '../../../../../services/customer.service.ts';
import { format } from 'date-fns';
import { ServerValidation } from '../../../../../../../shared/components/serverValidation/serverValidation.component.tsx';
import { IConditionsForm } from '../conditionsForm.component.tsx';

export const PaymentSection: FC<IPayment> = ({
  handleOpenPopup,
  bankOptions,
  countryId,
  bank1MandatLoading,
  bank2MandatLoading,
}) => {
  const { t } = useTranslation();
  const [activeSubTab, setActiveSubTab] = useState<SubTabs>(SubTabs.general);
  const { getValues } = useFormContext<CustomerConditionsRes>();
  const individualPaymentTerms = getValues('individualPaymentTerms');

  const isPaymentDisabled = useMemo(
    () => (individualPaymentTerms && individualPaymentTerms !== '' ? true : false),
    [individualPaymentTerms],
  );

  const directDebitBanksOptions: ISelectProps['options'] = useMemo(
    () => [
      {
        id: C_Direct_Debit_Banks.B1_NO_BANK,
        label: t(`enums.${C_Direct_Debit_Banks.B1_NO_BANK}`),
      },
      {
        id: C_Direct_Debit_Banks.B2_BANK1_WITH_DTA,
        label: t(`enums.${C_Direct_Debit_Banks.B2_BANK1_WITH_DTA}`),
      },
      {
        id: C_Direct_Debit_Banks.B3_BANK2_WITH_DTA,
        label: t(`enums.${C_Direct_Debit_Banks.B3_BANK2_WITH_DTA}`),
      },
      {
        id: C_Direct_Debit_Banks.B4_MANUAL,
        label: t(`enums.${C_Direct_Debit_Banks.B4_MANUAL}`),
      },
    ],
    [t, C_Direct_Debit_Banks],
  );

  const bank1SingatureDate = getValues().bank1MandatSignatureDate;
  const bank2SignatureDate = getValues().bank2MandatSignatureDate;

  return (
    <FieldsSection nomt title={t('customer.payment')}>
      <Tabs value={activeSubTab} onChange={(_, tab) => setActiveSubTab(tab)}>
        <Tab label={t('customer.general')} value={SubTabs.general} />
        <Tab
          label={t('customer.bank')}
          // disabled={countryId !== C_Country.CO2_GERMANY && countryId !== C_Country.CO3_AUSTRIA}
          value={SubTabs.bank}
        />
      </Tabs>
      <Box pt={2}>
        <TabPanel value={SubTabs.general} activeValue={activeSubTab}>
          <FieldsSection
            titleProps={{ variant: 'body1' }}
            titleBoxSx={{ pt: 0 }}
            nomt
            title={t('customer.paymentDeadline')}
          >
            <FieldsRow>
              <FormNumericField
                width='170px'
                name='paymentPeriod'
                noTrailingZeros
                label={t('customer.termOfPaymentInDays')}
                disabled={isPaymentDisabled}
              />
              <IndividualButton
                buttonIcon={SettingsIcon}
                badged={valueHelper.checkValueInHtml(individualPaymentTerms)}
                onClick={handleOpenPopup}
              >
                {t('customer.individual')}
              </IndividualButton>
            </FieldsRow>
          </FieldsSection>
          <FieldsSection
            titleProps={{ variant: 'body1' }}
            titleBoxSx={{ pt: 4 }}
            nomt
            title={t('customer.cashDiscount')}
          >
            <FieldsRow>
              <FormNumericField
                width='145px'
                name='skonto'
                precision={2}
                noTrailingZeros
                max={100}
                label={t('customer.cashDiscountIn')}
              />
              <FormNumericField
                width='145px'
                name='skontoPeriod'
                precision={0}
                label={t('common.days')}
              />
            </FieldsRow>
          </FieldsSection>
          {countryId === C_Country.CO1_SWITZERLAND && (
            <FieldsSection
              titleProps={{ variant: 'body1' }}
              titleBoxSx={{ pt: 4 }}
              nomt
              title={t('customer.bank_abbreviation')}
            >
              <FieldsRow>
                <FormSelect
                  label={t('customer.bank')}
                  width='220px'
                  name='besrBankId'
                  options={(bankOptions as []) || []}
                  PaperComponent={(props) => (
                    <SelectMenuWithFooter {...props} href='/settings/configuration/bank-accounts' />
                  )}
                />
              </FieldsRow>
            </FieldsSection>
          )}
          <FieldsSection
            titleProps={{ variant: 'body1' }}
            titleBoxSx={{ pt: 4 }}
            nomt
            title={t('customer.debtorAccount')}
          >
            <FieldsRow>
              <FormTextField
                width='220px'
                name='debtorAccount'
                label={t('customer.debtorAccount')}
              />
              <FormTextField
                width='220px'
                name='slipReferenceNo'
                label={t('customer.slipReference')}
              />
            </FieldsRow>
          </FieldsSection>
        </TabPanel>
        <TabPanel value={SubTabs.bank} activeValue={activeSubTab}>
          <FormSelect
            name='directDebitBankId'
            disableClearable
            label={t('customer.debit')}
            options={directDebitBanksOptions}
          />
          <Stack direction='row' spacing={7}>
            <FieldsSection
              maxWidth={500}
              minWidth={300}
              width='100%'
              titleProps={{ variant: 'body1' }}
              titleBoxSx={{ pt: 2 }}
              nomt
              title={t('customer.bank') + '1'}
            >
              <FieldsRow>
                <FormTextField width='100%' label={t('customer.bankName')} name='bank1Name' />
              </FieldsRow>
              <FieldsRow>
                <FormTextField width='150px' label={t('customer.bin')} name='bank1BIN' />
                <FormTextField width='100%' label={t('customer.accountNo')} name='bank1AccountNo' />
              </FieldsRow>
              <FieldsRow>
                <FormTextField width='100%' label={t('customer.iban')} name='bank1IBAN' />
              </FieldsRow>
              <FieldsRow>
                <FormTextField width='100%' label={t('customer.bic')} name='bank1BIC' />
              </FieldsRow>
              <FieldsRow>
                <ServerValidation
                  fieldName='bank1MandatNo'
                  allowEmpty={true}
                  handleSubmitValue={(v) => {
                    conditionsTabState.pub.save({ ...v, id: getValues('id') });
                  }}
                  renderItem={({ fieldName, errors, setIsFocused }) => (
                    <FormTextField
                      width='100%'
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      name={fieldName}
                      label={t('customer.mandatNumber')}
                      required
                      error={Boolean(errors[fieldName])}
                      helperText={errors[fieldName] ? t(String(errors[fieldName]!.message)) : ''}
                      InputProps={{
                        endAdornment: bank1MandatLoading && (
                          <InputAdornment position='end'>
                            <CircularProgress size={20} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </FieldsRow>
              <FieldsRow>
                <FormDatePicker
                  width={300}
                  returnEmpty
                  fieldProps={{ label: t('customer.dateOfMandatSignature') }}
                  navigation={false}
                  name='bank1MandatSignatureDate'
                  onBlur={() => {
                    conditionsTabState.pub.save({
                      id: getValues().id,
                      bank1MandatSignatureDate: bank1SingatureDate
                        ? format(getValues().bank1MandatSignatureDate, 'yyyy-MM-dd')
                        : null,
                    });
                  }}
                />
              </FieldsRow>
            </FieldsSection>
            <FieldsSection
              maxWidth={500}
              minWidth={300}
              width='100%'
              titleProps={{ variant: 'body1' }}
              titleBoxSx={{ pt: 2 }}
              nomt
              title={t('customer.bank') + '2'}
            >
              <FieldsRow>
                <FormTextField width='100%' label={t('customer.bankName')} name='bank2Name' />
              </FieldsRow>
              <FieldsRow>
                <FormTextField width='150px' label={t('customer.bin')} name='bank2BIN' />
                <FormTextField width='100%' label={t('customer.accountNo')} name='bank2AccountNo' />
              </FieldsRow>
              <FieldsRow>
                <FormTextField width='100%' label={t('customer.iban')} name='bank2IBAN' />
              </FieldsRow>
              <FieldsRow>
                <FormTextField width='100%' label={t('customer.bic')} name='bank2BIC' />
              </FieldsRow>
              <FieldsRow>
                <ServerValidation
                  fieldName='bank2MandatNo'
                  allowEmpty={true}
                  handleSubmitValue={(v) => {
                    conditionsTabState.pub.save({ ...v, id: getValues('id') });
                  }}
                  renderItem={({ fieldName, errors, setIsFocused }) => (
                    <FormTextField
                      width='100%'
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      name={fieldName}
                      label={t('customer.mandatNumber')}
                      required
                      error={Boolean(errors[fieldName])}
                      helperText={errors[fieldName] ? t(String(errors[fieldName]!.message)) : ''}
                      InputProps={{
                        endAdornment: bank2MandatLoading && (
                          <InputAdornment position='end'>
                            <CircularProgress size={20} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </FieldsRow>
              <FieldsRow>
                <FormDatePicker
                  returnEmpty
                  width={300}
                  fieldProps={{ label: t('customer.dateOfMandatSignature') }}
                  navigation={false}
                  name='bank2MandatSignatureDate'
                  onBlur={() => {
                    conditionsTabState.pub.save({
                      id: getValues().id,
                      bank2MandatSignatureDate: bank2SignatureDate
                        ? format(getValues().bank2MandatSignatureDate, 'yyyy-MM-dd')
                        : null,
                    });
                  }}
                />
              </FieldsRow>
            </FieldsSection>
          </Stack>
        </TabPanel>
      </Box>
    </FieldsSection>
  );
};

enum SubTabs {
  general = 'general',
  bank = 'bank',
}

interface IPayment extends Pick<IConditionsForm, 'bank1MandatLoading' | 'bank2MandatLoading'> {
  handleOpenPopup: () => void;
  countryId: C_Country | undefined;
  bankOptions: IConditionsTabOptions['banks'];
}
