import { FC, ReactNode } from 'react';
import { Box, BoxProps, SxProps, Theme, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { Theme as theme } from '../../../styles/theme/theme';

export const FieldsSection: FC<IFieldsSectionProps> = ({
  children,
  title,
  nomt,
  sx,
  titleBoxSx,
  contentBoxSx,
  titleProps,
  ...props
}) => {
  return (
    <Box
      sx={{
        ...sx,
        ...(!nomt && { mt: 2 }),
      }}
      {...props}
    >
      {typeof title === 'string' ? (
        <Box sx={{ py: 2, ...titleBoxSx }}>
          <Typography
            variant='h6'
            fontWeight={600}
            color={theme.palette.primary.main}
            {...titleProps}
          >
            {title}
          </Typography>
        </Box>
      ) : (
        <>{title}</>
      )}
      <Box sx={{ ...contentBoxSx }}>{children}</Box>
    </Box>
  );
};

interface IFieldsSectionProps extends Omit<BoxProps, 'sx' | 'children' | 'title'> {
  title: string | ReactNode;
  nomt?: boolean;
  children?: ReactNode;
  sx?: SxProps<Theme> | undefined;
  titleBoxSx?: SxProps<Theme>;
  titleProps?: TypographyProps;
  contentBoxSx?: SxProps<Theme>;
}
