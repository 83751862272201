import { FC } from 'react';
import {
  FieldsRow,
  FieldsSection,
  FormNumericField,
} from '../../../../../../../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';

export const CashDiscount: FC = () => {
  const { t } = useTranslation();

  return (
    <FieldsSection
      titleProps={{ variant: 'body1' }}
      titleBoxSx={{ pt: 4 }}
      nomt
      title={t('customer.cashDiscount')}
    >
      <FieldsRow>
        <FormNumericField
          width='142px'
          name='skonto'
          precision={2}
          noTrailingZeros
          returnZero
          max={100}
          label={t('settings.cash_discount_in')}
        />
        <FormNumericField
          width='142px'
          name='skontoPeriod'
          precision={0}
          label={t('common.days')}
        />
      </FieldsRow>
    </FieldsSection>
  );
};
