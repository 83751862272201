import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IPopupWrapper } from '../../../../../../../../../shared/components/popup/components/popup.component.tsx';
import { Button, IconButton, Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Content as Wrapper } from '../../../../../../../../../shared/components/content/content.component.tsx';
import { take, zipWith } from 'rxjs';
import {
  dictionaryAdditionalService,
  GetDictQuantityDiscountQueryRes,
} from '../../../../../../../../../shared/components/dictionary/services/dictionaryAdditional.service.ts';
import { Table } from '../../../../../../../../../shared/components/table/table.component.tsx';
import { Column } from '../../../../../../../../../shared/components/table/components/common.components.tsx';
import {
  configsData,
  DictDiscountGroupsRes,
} from '../../../../../../../../../shared/services/configsData/configsData.service.ts';
import { formatValue } from '../../../../../../../../../shared/helpers/utils/utils.helper.ts';

import {
  IQuantityDiscountDictState,
  quantityDiscountDictState,
} from '../quantityDiscountsDict.state.ts';
import { NumericEditDict } from '../cells/numericEditDict.cell.tsx';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { viewCells } from '../views/viewCells.ts';
import { viewRows } from '../views/viewRows.ts';
import { dictionaryState } from '../../../../../../../../../shared/components/dictionary/states/dictionary.state.ts';
import { localeFormatterHelper } from '../../../../../../../../../shared/helpers/formatter/localeFormatter.helper.ts';
import { useBeforeClosePopup } from '../../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import equal from 'fast-deep-equal/react';
import { responseHandler } from '../../../../../../../../../shared/responseHandler/responseHandler.ts';

export const ContentPopup: FC<IContent> = ({ stream }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [discountValues, setDiscountValues] = useState<GetDictQuantityDiscountQueryRes>([]);
  const [discountValuesBackup, setDiscountValuesBackup] = useState<GetDictQuantityDiscountQueryRes>(
    [],
  );
  const [discountGroups, setDiscountGroups] = useState<Map<string, string>>(new Map());
  const [selectedRow, setSelectedRow] = useState<IQuantityDiscountDictState['selectedPos']>(null);
  const [id, setId] = useState<string>('');

  useEffect(() => {
    const quantityDiscountDictStateSub = quantityDiscountDictState.sub
      .state()
      .subscribe((state) => {
        setDiscountValues(state.quantityDiscountGrid);
        setSelectedRow(state.selectedPos);
      });
    stream
      .actionListener('open')
      .pipe(take(1))
      .subscribe(async ({ id }) => {
        setId(id);
        const [discountGroups, discountValues] =
          await dictionaryAdditionalService.globHelpers.streamToPromise(
            configsData.sub.dictDiscountGroups().pipe(
              zipWith(dictionaryAdditionalService.sub.getDictQuantityDiscount()),
              responseHandler<[DictDiscountGroupsRes, GetDictQuantityDiscountQueryRes]>({
                errorReturnType: [[], []],
              }),
            ),
            () => {
              configsData.pub.common(['dictDiscountGroups'], true);
              dictionaryAdditionalService.pub.getDictQuantityDiscount({ id });
            },
          );
        const preparedDiscountValues = discountValues.map((item, i) => ({
          ...item,
          id: String(Date.now() + i),
        }));
        setDiscountValuesBackup(structuredClone(preparedDiscountValues));
        setDiscountValues(preparedDiscountValues);
        quantityDiscountDictState.pub.init(preparedDiscountValues);
        const discountGroupsObj = discountGroups.reduce((acc: Record<string, string>, item) => {
          acc[String(item.id)] = item.label || '';
          return acc;
        }, {});
        setDiscountGroups(new Map(Object.entries(discountGroupsObj)));
        setLoading(false);
      });
    return () => quantityDiscountDictStateSub.unsubscribe();
  }, []);

  const dynamicColumns = useMemo(() => {
    const columns: any = [];
    const visibleColumns: Record<string, boolean> = {};
    for (let i = 0; i < 20; i++) {
      visibleColumns[`discountOnGroup${i + 1}`] = discountGroups.has(String(i + 1));
      columns.push(
        <Column
          key={i}
          field={`discountOnGroup${i + 1}`}
          headerName={discountGroups.get(String(i + 1))}
          headerAlign='right'
          align='right'
          valueFormatter={({ value }) =>
            typeof value === 'number' ? `${formatValue(value, { precision: 2 })}%` : ''
          }
          renderEditCell={(params) => (
            <NumericEditDict editCellParams={params} numericProps={{ returnZero: true }} />
          )}
          sortable={false}
          disableReorder
          editable
        />,
      );
    }
    return { columns, visibleColumns };
  }, [discountGroups]);

  const checkValid = useCallback(() => {
    if (!discountValues.length) {
      return true;
    } else {
      return discountValues.some((item) => !item.fromQuantity);
    }
  }, [discountValues]);

  const handleConfirm = () => {
    if (!equal(discountValues, discountValuesBackup)) {
      dictionaryState.pub.updateCell({
        id,
        field: 'discountValues',
        value: discountValues.map((item) => {
          const { id: _, ...rest } = item as GetDictQuantityDiscountQueryRes & {
            id: string;
          };
          return rest;
        }),
      });
    }
    stream.emit('close');
  };

  useBeforeClosePopup({
    stream,
    isDirty: !equal(discountValues, discountValuesBackup),
  });

  return (
    <Wrapper type='box' {...{ loading }}>
      <Stack sx={{ opacity: loading ? '0' : '1', transition: '0.3s' }}>
        <Stack direction='row' spacing={2} mb={1}>
          <IconButton
            color='primary'
            size='large'
            title={t('common.add')}
            onClick={() => quantityDiscountDictState.pub.addPosition()}
          >
            <AddIcon />
          </IconButton>
          <IconButton
            disabled={!discountValues.length || discountValues.length === 1 || !selectedRow}
            color='primary'
            size='large'
            title={t('common.remove')}
            onClick={() => quantityDiscountDictState.pub.deletePosition()}
          >
            <RemoveIcon />
          </IconButton>
        </Stack>
        <STable
          columnVisibilityModel={dynamicColumns.visibleColumns}
          data={discountValues}
          heightOffset={300}
          onRowClick={(e) => quantityDiscountDictState.pub.selectPosition(e.row)}
          rowSelectionModel={selectedRow?.id}
          onRowsCountChange={(rows, prevRows, apiRef) => {
            if (rows.length > prevRows.length) {
              const newRow = rows?.[rows.length - 1];
              if (newRow && String(newRow?.id).includes('new')) {
                apiRef?.current.startCellEditMode({
                  id: newRow.id,
                  field: 'fromQuantity',
                });
              }
            }
          }}
          {...{ viewCells, viewRows }}
        >
          <Column
            field='fromQuantity'
            headerName={t('settings.from_invoice_amount')}
            align='right'
            headerAlign='right'
            width={194}
            sortable={false}
            headerClassName='fromQuantity'
            valueFormatter={({ value }) =>
              value ? localeFormatterHelper.formatNumber(value, { returnZero: false }) : ''
            }
            renderEditCell={(params) => <NumericEditDict editCellParams={params} />}
            editable
            disableReorder
          />
          <Column
            field='discountOnAllGroups'
            headerName={t('common.discount')}
            headerClassName='discountOnAllGroups'
            align='right'
            headerAlign='right'
            width={130}
            valueFormatter={({ value }) =>
              typeof value === 'number' ? `${formatValue(value, { precision: 2 })}%` : ''
            }
            renderEditCell={(params) => (
              <NumericEditDict editCellParams={params} numericProps={{ returnZero: true }} />
            )}
            sortable={false}
            editable
            disableReorder
          />
          {dynamicColumns.columns}
        </STable>
        <Stack mt={3} direction='row' justifyContent='flex-end' spacing={2}>
          <Button disabled={checkValid()} onClick={handleConfirm} variant='contained'>
            {t('common.save')}
          </Button>
        </Stack>
      </Stack>
    </Wrapper>
  );
};

const STable = styled(Table)(({ theme }) => ({
  '& .fromQuantity, & .discountOnAllGroups': {
    backgroundColor: theme.palette.orange.light,
  },
}));

interface IContent {
  stream: IPopupWrapper['stream'];
}
