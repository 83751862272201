import * as yup from 'yup';

const isQRIBANValid = (value: string | undefined | null) => {
  if (value === null || value === undefined) {
    return true;
  }
  return value.length >= 21 && value.charAt(4) === '3';
};

const isMemberNoValid = (value: string | undefined) => {
  return !!value && /^\d+$/.test(value) && value.length <= 6;
};

export const createValidationSchema = (): yup.AnyObjectSchema => {
  return yup.object().shape({
    name: yup.string().required('common.not_empty'),
    pcAccount: yup.string().required('common.not_empty'),
    qrIBAN: yup.string().nullable().test('is-valid', 'settings.invalid_qr_iban', isQRIBANValid),
    memberNo: yup
      .string()
      .test('is-valid', 'settings.invalid_member_num', isMemberNoValid)
      .required('common.not_empty'),
  });
};
