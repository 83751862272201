import { firstValueFrom } from 'rxjs';
import * as yup from 'yup';
import { articleService, ValidateArticleFieldsRes } from '../../../../services/article.service';
import { responseHandler } from '../../../../../../shared/responseHandler/responseHandler';

export const createValidationSchema = (customId?: string): yup.AnyObjectSchema => {
  const schema = yup.object({
    articleNo: yup
      .string()
      .required('common.not_empty')
      .max(10, 'article.articleNo_maxLen')
      .test(
        'articleNo-server-validation',
        'article.not_unique_number',
        async (value, { parent, options: { context } }): Promise<boolean> => {
          try {
            if (context!.prevArticleNoRef.current !== value) {
              if (!value) return false;
              context!.prevArticleNoRef.current = value;
              articleService.pub.validateArticleFieldsOnServer({
                context,
                fieldForCheck: { articleNo: value },
                id: customId || parent.id,
              });
              const serverValidationRes = await firstValueFrom(
                articleService.sub.validateArticleFieldsOnServer().pipe(
                  responseHandler<ValidateArticleFieldsRes>({
                    errorReturnType: {
                      articleNo: false,
                      productionRecipeId: false,
                    },
                  }),
                ),
              );
              context!.prevValidationResRef.current = serverValidationRes.articleNo;
              return serverValidationRes.articleNo;
            }
          } catch (error) {
            if (error instanceof Error) {
              throw new Error(`Server validation failed: ${error.message}`);
            }
            throw new Error('An unknown error occurred during server validation');
          }
          return context!.prevValidationResRef.current;
        },
      ),
    description: yup.string().required('common.not_empty').max(64, 'article.description_maxLen'),
  });
  return schema;
};
