import { Box, IconButton, Stack, Typography, styled } from '@mui/material';
import { FC } from 'react';
import { ReactComponent as Logo } from '../../../icons/wawi_logo.svg';
import FullSizeNavIcon from '@mui/icons-material/Menu';
import MinimizeNavIcon from '@mui/icons-material/ChevronLeft';

export const NavHeader: FC<INavHeader> = ({ open, handleNavOpen, handleNavClose }) => {
  return (
    <Stack direction='row' alignItems='center' sx={{ ml: '5px' }}>
      <Box sx={{ minWidth: (theme) => theme.nav.width }}>
        {!open && (
          <IconButton onClick={(evt) => handleNavOpen(evt)} size='large'>
            <FullSizeNavIcon />
          </IconButton>
        )}
      </Box>
      <Stack direction='row' sx={{ width: '100%', ml: '5px' }}>
        <SLogo />
        <Typography variant='body2' sx={{ mx: 1, pt: 0.5 }}>
          <Typography fontWeight={600} component='strong'>
            WaWi
          </Typography>
          Assist
        </Typography>
      </Stack>
      <Box>
        {open && (
          <IconButton onClick={(evt) => handleNavClose(evt)} size='large'>
            <MinimizeNavIcon />
          </IconButton>
        )}
      </Box>
    </Stack>
  );
};
const SLogo = styled(Logo)(({ theme }) => ({
  minWidth: '28px',
  marginLeft: theme.spacing(-1),
}));

interface INavHeader {
  open: boolean;
  handleNavOpen: (evt: MouseEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleNavClose: (
    evt: MouseEvent | TouchEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}
