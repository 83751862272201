import * as yup from 'yup';
import { labelTabState } from '../label.state';

export const createValidationSchema = (): yup.AnyObjectSchema => {
  return yup.object({
    eanCode: yup
      .string()
      .nullable()
      .test({
        name: 'eanCode-validation',
        test: (value, { createError, path }) => {
          const length = value?.length;
          if (!value) {
            // deleting eanHint
            labelTabState.pub.updateEANHint();
            return true;
          }
          // we need only numbers (except P and G on the 7 or more place)
          if (!onlyNumbers(value)) {
            const hasLetterPG = value.indexOf('P') >= 7 || value.indexOf('G') >= 7;
            // deleting eanHint
            labelTabState.pub.updateEANHint();
            if (!hasLetterPG) {
              return createError({ path, message: 'article.eanCode_onlyNumb' });
            }
            const valueWithoutPG = value.replace(/[GP]/gi, '');
            const isClean = onlyNumbers(valueWithoutPG);
            if (!isClean) {
              return createError({ path, message: 'article.eanCode_onlyNumb' });
            }
            if (length !== 13 && length !== 8) {
              return createError({ path, message: 'article.eanCode_must_digits' });
            } else {
              return true;
            }
          } else {
            if (length === 12 || length === 7) {
              // adding eanHint
              const digits = transformStringToReverseNumbArray(value);
              const checksum = calculateChecksum(digits);
              labelTabState.pub.updateEANHint(String(checksum));
            } else {
              // deleting eanHint
              labelTabState.pub.updateEANHint();
            }
            if (length !== 13 && length !== 8) {
              return createError({ path, message: 'article.eanCode_must_digits' });
            }
            if (isValidEAN(value)) {
              return true;
            }
            return createError({ path, message: 'article.eanCode_not_valid' });
          }
        },
      }),
    daysToSell: yup
      .number()
      .nullable()
      .test({
        name: 'daysToSell-validation',
        test: (value, allData) => {
          const { createError, path, parent } = allData;
          const { daysShelfLife, daysToConsume, articleLabelDataFromRA, isStoreInCoolPlace } =
            parent || {};
          const isDataFromRA = articleLabelDataFromRA?.id;

          const noValidationConfition =
            isDataFromRA || (!value && !daysShelfLife && !daysToConsume);
          if (noValidationConfition) {
            return true;
          }
          const maxValue = isStoreInCoolPlace ? daysToConsume : daysShelfLife;
          if (!maxValue && !value) {
            return true;
          }
          if (!maxValue) {
            return true;
          }
          if (Number(value) < maxValue) {
            return true;
          }
          return createError({ path, message: 'common.value_less' });
        },
      }),
  });
};

export const onlyNumbers = (value: string): boolean => {
  return /^\d+$/.test(value);
};

const calculateChecksum = (digits: number[]): number => {
  const checksum = digits.reduce((sum, number, index) => {
    if (index % 2 === 1) {
      return sum + number;
    }
    return number * 3 + sum;
  }, 0);
  return (10 - (checksum % 10)) % 10;
};

const transformStringToReverseNumbArray = (value: string): number[] => {
  const digits = value
    .split('')
    .map((d) => +d)
    .reverse();
  return digits;
};

export const isValidEAN = (ean: string): boolean => {
  const digits = transformStringToReverseNumbArray(ean);
  const sumFromCustomer = digits.shift();
  const checksum = calculateChecksum(digits);
  return sumFromCustomer === checksum;
};
