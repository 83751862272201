import { TypeTable } from '../../../../../shared/components/table/table.component.tsx';
import { Theme } from '../../../../../shared/styles/theme/theme.ts';
import { GridCellParams } from '@mui/x-data-grid/models/params/gridCellParams';

export const viewCells: TypeTable['viewCells'] = {
  actions: {
    price: ({ value, row }) => {
      if (row.virtualPositionId) return '';
      if (value > 0) return `positive ${defineDisabled(row.isChangeable, 'positive-editable')}`;
      return `empty ${defineDisabled(row.isChangeable, 'empty-editable')}`;
    },
    total: ({ value, row }) => {
      if (row.virtualPositionId) return '';
      if (value < 0) return 'negative';
      if (value > 0) return 'positive';
      return 'empty';
    },
    quantity: ({ value, row }) => {
      if (row.virtualPositionId) return '';
      const isNotValidLot =
        typeof row.quantityPerLot === 'number' && Number(value || null) % row.quantityPerLot !== 0;
      const isNotValidRange =
        (row.minQuantity && row.minQuantity > Number(value)) ||
        (row.maxQuantity && Number(value) > row.maxQuantity);
      const validation: string =
        isNotValidLot || isNotValidRange
          ? `validate ${defineDisabled(row.isChangeable, 'validate-editable')}`
          : '';
      if (value < 0)
        return `negative ${defineDisabled(row.isChangeable, 'negative-editable')} ${validation}`;
      return `${defineDisabled(row.isChangeable, 'editable')} ${validation}`;
    },
    description: ({ row }) => {
      if (row.virtualPositionId) return '';
      return defineDisabled(row.isChangeable, 'editable');
    },
    discount: ({ isEditable, row }) => {
      if (row.virtualPositionId) return '';
      return defineDisabled(row.isChangeable && isEditable, 'editable');
    },
    vatCode: ({ row }) => {
      if (row.virtualPositionId) return '';
      return defineDisabled(row.isChangeable, 'editable');
    },
    ...viewSplitting(),
  },
  styles: [
    {
      className: 'editable',
      cellStyles: {
        '&:hover': {
          backgroundColor: Theme.palette.lightGrey.main,
        },
      },
    },
    {
      className: 'positive',
      cellStyles: {
        backgroundColor: Theme.palette.green.light,
      },
    },
    {
      className: 'positive-editable',
      cellStyles: {
        '&:hover': {
          backgroundColor: Theme.palette.green.main,
        },
      },
    },
    {
      className: 'negative',
      cellStyles: {
        backgroundColor: Theme.palette.red.light,
      },
    },
    {
      className: 'negative-editable',
      cellStyles: {
        '&:hover': {
          backgroundColor: Theme.palette.red.main,
        },
      },
    },
    {
      className: 'validate',
      cellStyles: {
        backgroundColor: Theme.palette.darkOrange.light,
      },
    },
    {
      className: 'validate-editable',
      cellStyles: {
        '&:hover': {
          backgroundColor: Theme.palette.darkOrange.main,
        },
      },
    },
    {
      className: 'empty',
      cellStyles: {
        backgroundColor: Theme.palette.yellow.light,
      },
    },
    {
      className: 'empty-editable',
      cellStyles: {
        '&:hover': {
          backgroundColor: Theme.palette.yellow.main,
        },
      },
    },
    {
      className: 'splitting',
      cellStyles: {
        backgroundColor: Theme.palette.orange.light,
      },
    },
    {
      className: 'splitting-editable',
      cellStyles: {
        '&:hover': {
          backgroundColor: Theme.palette.orange.main,
        },
      },
    },
  ],
};

function viewSplitting() {
  const result: { [key in string]: (v: GridCellParams<any, any>) => string } = {};
  for (let i = 0; i < 10; i++) {
    result[`splittingPartsPreset_${i}`] = ({ row }) => {
      if (row.virtualPositionId) return '';
      return `splitting ${defineDisabled(row.isChangeable, 'splitting-editable')}`;
    };
  }
  return result;
}

export function defineDisabled(flag: boolean, className: string) {
  return flag ? className : '';
}
