import { Box, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FieldsRow,
  FieldsSection,
  FormCheckbox,
  FormNumericField,
  FormSelect,
  FormTextEditor,
  FormTextField,
} from '../../../../../../shared/components/form';
import { ISelectOption } from '../../../../../../shared/components/form/fields/select.component';
import { fontHelper } from '../../../../../../shared/helpers/font/font.helper';
import { LabelTabOptionsRes } from '../../../../loaders/labelTab.resolver';
import { ArticleLabelTabDataRes } from '../../../../services/article.service';
import { labelTabState } from '../label.state';

export const LabelForm: FC<ILabelForm> = ({ formMethods, recipes, articleId }) => {
  const { t } = useTranslation();
  const {
    formState: { errors, dirtyFields },
    watch,
    trigger,
  } = formMethods || {};

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    const fieldName = e.target.name as keyof ArticleLabelTabDataRes;
    if (!errors[fieldName] && !!dirtyFields[fieldName]) {
      labelTabState.pub.save();
    }
  };

  const { articleLabelDataFromRA, isStoreInCoolPlace, daysToConsume, daysShelfLife } =
    watch() || {};

  const {
    id,
    isAutoDeclaration,
    isStoreInCoolPlace: isStoreInCoolPlaceRA,
    sellingPoints,
    sellingPointsFreezer,
    declaration,
    autoDeclaration,
  } = (articleLabelDataFromRA as NonNullable<ArticleLabelTabDataRes['articleLabelDataFromRA']>) ||
  {};
  const defaultFontSize = useMemo(() => fontHelper.getDefaultFont(['fontSize']), []);
  const [eanHint, setEanHint] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const declarationName = getDeclarationName({ id, isAutoDeclaration });
  const daysShelfLifeName = id ? 'articleLabelDataFromRA.daysShelfLife' : 'daysShelfLife';
  const daysToConsumeName = id ? 'articleLabelDataFromRA.daysToConsume' : 'daysToConsume';
  const daysToSellName = id ? 'articleLabelDataFromRA.daysToSell' : 'daysToSell';
  const isStoreInCoolPlaceName = id
    ? 'articleLabelDataFromRA.isStoreInCoolPlace'
    : 'isStoreInCoolPlace';
  const storagePlaceName = getStoragePlaceName({ id, sellingPoints });
  const consumersStorageInfoName = id
    ? 'articleLabelDataFromRA.consumersStorageInfo'
    : 'consumersStorageInfo';
  const consumersInformationName = id
    ? 'articleLabelDataFromRA.consumersInformation'
    : 'consumersInformation';

  const isDaysShelfLifeVisible = id ? !isStoreInCoolPlaceRA : !isStoreInCoolPlace;
  const maxValue = isStoreInCoolPlace ? daysToConsume : daysShelfLife;

  const generateStoragePlaceTransformValueLogic = (): GenerateRes => {
    if (sellingPointsFreezer) {
      return (v) => {
        return t(`enums.${v}`);
      };
    }
    return undefined;
  };
  const storagePlaceTransformValueLogic = generateStoragePlaceTransformValueLogic();

  useEffect(() => {
    const unsubEanHint = labelTabState.sub.eanHint().subscribe(setEanHint);
    const unsubLoading = labelTabState.sub.loading().subscribe(setLoading);
    return () => {
      unsubEanHint.unsubscribe();
      unsubLoading.unsubscribe();
    };
  }, []);

  useEffect(() => {
    trigger('daysToSell');
  }, [maxValue]);

  let hint = <></>;
  if (eanHint) {
    hint = (
      <Typography variant='caption' fontSize={16}>
        {t('article.check_digit')}: {eanHint}
      </Typography>
    );
  }

  const declarationKey = declarationName + articleId + declaration + autoDeclaration;

  return (
    <FormProvider {...formMethods}>
      <form noValidate onBlur={onSubmit}>
        <FieldsSection titleBoxSx={{ pt: 0 }} nomt title={t('article.labeling')}>
          <FieldsRow>
            <FormTextField
              name='customLabelDescription'
              label={t('article.alternative_designation_on_label')}
              width='250px'
            />
            <FormTextField name='origin' label={t('article.origin')} width='250px' />
          </FieldsRow>
        </FieldsSection>
        <FieldsSection title={t('article.ean')}>
          <FieldsRow alignItems='baseline'>
            <FormTextField
              name='eanCode'
              error={Boolean(errors['eanCode'])}
              helperText={errors['eanCode'] ? t(String(errors['eanCode'].message)) : ''}
              label={t('article.ean_code')}
              width='250px'
            />
            {hint}
          </FieldsRow>
          <FieldsRow mt={1}>
            <FormCheckbox
              name='isEanCodeManagedByCA'
              label={t('article.ean_code_is_managed_in_cashAssist')}
            />
          </FieldsRow>
        </FieldsSection>
        <FieldsSection title={t('article.declaration')}>
          <FieldsRow>
            <FormSelect
              name='articleLabelDataFromRA.id'
              filterOptions={filterOptions}
              datasetattribute='recipes'
              options={recipes as []}
              onChange={(recipeId) =>
                labelTabState.pub.updateRAData(recipeId as string | undefined)
              }
              label={t('article.link_to_recipeAssist')}
              loading={loading}
              componentsProps={{
                paper: { sx: { width: 420 } },
              }}
            />
          </FieldsRow>
          <FieldsRow direction='column'>
            <Box width='537px'>
              <FormTextEditor
                key={declarationKey}
                name={declarationName}
                defaultFormat={defaultFontSize}
                toolbarItems={['bold']}
                minHeight={178}
                disabled={!!id}
              />
            </Box>
          </FieldsRow>
          <FieldsRow>
            {isDaysShelfLifeVisible ? (
              <FormNumericField
                key={daysShelfLifeName}
                name={daysShelfLifeName}
                label={t('article.shelf_life_in_days')}
                precision={0}
                width='260.5px'
                controls
                disabled={!!id}
              />
            ) : (
              <FormNumericField
                key={daysToConsumeName}
                name={daysToConsumeName}
                label={t('article.consume_by')}
                precision={0}
                width='260.5px'
                controls
                disabled={!!id}
              />
            )}
            <FormNumericField
              key={daysToSellName}
              name={daysToSellName}
              label={t('article.sell_by')}
              precision={0}
              width='260.5px'
              error={Boolean(errors['daysToSell'])}
              helperText={
                errors['daysToSell']
                  ? t(String(errors['daysToSell'].message), { max: maxValue })
                  : ''
              }
              controls
              disabled={!!id}
            />
          </FieldsRow>
          <FieldsRow>
            <FormNumericField
              name='articleLabelDataFromRA.sellingWeight'
              label={t('article.selling_weight_piece')}
              width='260.5px'
              disabled={true}
            />
            <FormNumericField
              name='sellingWeight'
              label={t('article.alternative_sales_weight')}
              precision={0}
              width='260.5px'
            />
          </FieldsRow>
          <FieldsRow>
            <FormCheckbox
              key={isStoreInCoolPlaceName}
              name={isStoreInCoolPlaceName}
              label={t('article.item_must_be_kept_cool')}
              disabled={!!id}
            />
          </FieldsRow>
          <FieldsRow mt={2}>
            <FormTextField
              transformValue={storagePlaceTransformValueLogic}
              key={storagePlaceName}
              name={storagePlaceName}
              label={t('article.storage')}
              multiline
              rows={7}
              sx={{ width: '537px' }}
              disabled={!!id}
            />
          </FieldsRow>
          <FieldsRow mt={2}>
            <FormTextField
              key={consumersStorageInfoName}
              name={consumersStorageInfoName}
              label={t('article.consumers_storage_info')}
              multiline
              rows={7}
              sx={{ width: '537px' }}
              disabled={!!id}
            />
          </FieldsRow>
          <FieldsRow mt={2}>
            <FormTextField
              key={consumersInformationName}
              name={consumersInformationName}
              label={t('article.sales_information_for_customer')}
              multiline
              rows={7}
              sx={{ width: '537px' }}
              disabled={!!id}
            />
          </FieldsRow>
        </FieldsSection>
      </form>
    </FormProvider>
  );
};

const filterOptions = (
  options: ExtendedSelectOption[] = [],
  { inputValue }: { inputValue: string },
): ExtendedSelectOption[] => {
  const filteredOptions = options?.filter(({ label, recipeNo }) => {
    const isLableSame = label?.toLowerCase().includes(inputValue?.toLowerCase());
    const isNumberSame = recipeNo?.toLowerCase().includes(inputValue?.toLowerCase());
    return isLableSame || isNumberSame;
  });
  return filteredOptions;
};

const getDeclarationName = ({ id, isAutoDeclaration }: GetDeclarationProps): string => {
  let declarationName = 'declaration';
  if (id) {
    if (isAutoDeclaration) {
      declarationName = 'articleLabelDataFromRA.autoDeclaration';
    } else {
      declarationName = 'articleLabelDataFromRA.declaration';
    }
  }
  return declarationName;
};

const getStoragePlaceName = ({ id, sellingPoints }: GetStoragePlaceName): string => {
  let storagePlaceName = 'storagePlace';
  if (id) {
    if (sellingPoints) {
      storagePlaceName = 'articleLabelDataFromRA.sellingPoints';
    } else {
      storagePlaceName = 'articleLabelDataFromRA.sellingPointsFreezer';
    }
  }
  return storagePlaceName;
};

type GenerateRes = ((v: string) => string) | undefined;

interface GetStoragePlaceName {
  id: string | null | undefined;
  sellingPoints: string | null | undefined;
}

interface GetDeclarationProps {
  id: string | null | undefined;
  isAutoDeclaration: boolean;
}

interface ExtendedSelectOption extends ISelectOption {
  recipeNo?: string;
}

interface ILabelForm {
  formMethods: UseFormReturn<ArticleLabelTabDataRes>;
  recipes: LabelTabOptionsRes['recipes'];
  articleId: string;
}
