import { FC } from 'react';
import { C_Discount_Kind } from '../../../../../../../../../../../../graphql/generatedModel';
import {
  FieldsRow,
  FormNumericField,
  FormSelect,
  SelectMenuWithFooter,
} from '../../../../../../../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import {
  DictDiscountGroupsRes,
  DictQuantityDiscountsRes,
} from '../../../../../../../../../../../../shared/services/configsData/configsData.service';
import { Stack, Typography } from '@mui/material';

export const ConditionInputs: FC<IConditionInputs> = ({
  discountKindIdValue,
  discountGroups,
  quantityDiscounts,
}) => {
  const { t } = useTranslation();

  if (discountKindIdValue === C_Discount_Kind.DK2_ALL_ARTICLES) {
    return (
      <FieldsRow>
        <FormNumericField
          width='350px'
          name='discountOnGroup1'
          precision={2}
          label={t('customer.discountPercent')}
          noTrailingZeros
          max={999}
        />
      </FieldsRow>
    );
  }
  if (
    discountKindIdValue === C_Discount_Kind.DK3_ARTICLE_GROUPS ||
    discountKindIdValue === C_Discount_Kind.DK4_PRICE_REDUCE
  ) {
    return (
      <FieldsRow>
        <Stack
          display='flex'
          flexDirection='row'
          sx={{
            flexWrap: 'wrap',
          }}
        >
          {discountGroups?.map((group, index) => {
            if (index % 4 === 0) {
              return (
                <Stack
                  key={group?.id || index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    marginTop: index !== 0 ? '24px' : '0',
                  }}
                >
                  {/* here we make HTML structure and CSS in such way that we have 4 inputs in each line */}
                  {discountGroups.slice(index, index + 4).map((item, subIndex) => (
                    <Stack
                      key={index + subIndex}
                      sx={{
                        flexDirection: 'row',
                        boxSizing: 'border-box',
                        paddingRight: subIndex !== 3 ? '16px' : '0',
                        width: subIndex !== 3 ? '283px' : '267px',
                        justifyContent: 'end',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        fontSize={16}
                        fontWeight={400}
                        mr='16px'
                        sx={{ wordBreak: 'break-word' }}
                      >
                        {item.label}
                      </Typography>
                      <FormNumericField
                        name={`discountOnGroup${index + subIndex + 1}`}
                        label={t('customer.discountPercent')}
                        width='103px'
                        noTrailingZeros
                        precision={2}
                        max={999}
                        sx={{ minWidth: '100px' }}
                      />
                    </Stack>
                  ))}
                </Stack>
              );
            }
          })}
        </Stack>
      </FieldsRow>
    );
  }

  if (
    discountKindIdValue === C_Discount_Kind.DK5_QUANTITY_DISCOUNT ||
    discountKindIdValue === C_Discount_Kind.DK6_QUANTITY_DISCOUNT_GROUPS
  ) {
    return (
      <FieldsRow>
        <FormSelect
          label={t('customer.quantityDiscountValue')}
          width='350px'
          name='quantityDiscountSettingId'
          options={(quantityDiscounts as []) || []}
          disableClearable
          PaperComponent={(props) => (
            <SelectMenuWithFooter {...props} href='/settings/configuration/invoice' />
          )}
        />
      </FieldsRow>
    );
  }

  return <></>;
};

interface IConditionInputs {
  discountKindIdValue: C_Discount_Kind | undefined;
  discountGroups: DictDiscountGroupsRes;
  quantityDiscounts: DictQuantityDiscountsRes;
}
