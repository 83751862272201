import { promptPopup } from '../nav.component.tsx';
import { take } from 'rxjs';
import { t } from 'i18next';

export class NavBlocker {
  private isDirty: boolean = false;
  watch(isDirty: boolean) {
    this.isDirty = isDirty;
  }
  controlledNavigate(handlers: () => void) {
    if (!this.isDirty) {
      handlers();
    } else {
      promptPopup.stream.emit('open', {
        message: t('common.changes_you_made_were_not_saved'),
        withWarningIcon: true,
      });
      promptPopup.stream
        .state()
        .pipe(take(2))
        .subscribe(({ action }) => {
          if (action === 'proceed') {
            handlers();
            this.isDirty = false;
          }
        });
    }
  }
}

export const navBlocker = new NavBlocker();
