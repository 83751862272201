import { FC, ReactNode } from 'react';
import styled from '@mui/material/styles/styled';

export const FilterGroup: FC<IFilterGroup> = ({ children, ...props }) => {
  return <SFilterGroup {...props}>{children}</SFilterGroup>;
};

const SFilterGroup = styled('div')<Pick<IFilterGroup, 'marginBottom' | 'spacing'>>(
  ({ marginBottom, spacing }) => ({
    '& > div:nth-of-type(n + 2)': {
      marginTop: spacing || 0,
    },
    marginBottom: marginBottom || 16,
    display: 'flex',
    flexDirection: 'column',
  }),
);

interface IFilterGroup {
  spacing?: number;
  marginBottom?: number;
  children: ReactNode | undefined;
}
