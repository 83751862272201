import { FC, Fragment, ReactNode, useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Box, IconButton, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';
import RefreshIcon from '@mui/icons-material/Refresh';
import InboxIcon from '@mui/icons-material/Inbox';
import { ILegendData, LegendTooltip } from './legendTooltip.component.tsx';
import { t } from 'i18next';
import { localeFormatterHelper } from '../../../helpers/formatter/localeFormatter.helper.ts';
import { grey } from '@mui/material/colors';

export const Column: FC<GridColDef & { view?: View }> = () => <Fragment />;
Column.defaultProps = { view: 'column' };
export const Footer: FC<IFooter> = ({
  children,
  numberRows,
  allNumbersRow,
  titleRows,
  onRefresh,
  legendData,
  height,
}) => {
  const format = (v: number) => localeFormatterHelper.formatNumber(v, { precision: 0 });
  const foundRows = useMemo(() => {
    if (numberRows === allNumbersRow || allNumbersRow === undefined) {
      return format(numberRows || 0);
    }
    return `${format(numberRows || 0)} / ${format(allNumbersRow)}`;
  }, [numberRows, allNumbersRow]);
  return !!children ? (
    children
  ) : (
    <SFooter {...{ height }}>
      <SLeftSideBlock>
        <SNumberRows>
          {titleRows}: {foundRows}
        </SNumberRows>
        {legendData && <LegendTooltip tooltipValues={legendData} />}
      </SLeftSideBlock>
      {typeof onRefresh === 'function' && (
        <SIconButton onClick={onRefresh} title={t('common.refresh')}>
          <RefreshIcon />
        </SIconButton>
      )}
    </SFooter>
  );
};

Footer.defaultProps = { view: 'footer' };
export const SFooter = styled(Box)<{ height?: number }>(({ theme, height }) => ({
  minHeight: height || 52,
  display: 'flex',
  alignItems: 'center',
  padding: '0 10px',
  backgroundColor: theme.palette.grey['100'],
  justifyContent: 'space-between',
  boxShadow: `0 -1px 0px 0px ${theme.palette.grey['300']}`,
  borderBottomRightRadius: '4px',
  borderBottomLeftRadius: '4px',
}));

const SNumberRows = styled('span')(() => ({
  fontWeight: 'bold',
  marginRight: 16,
}));

const SLeftSideBlock = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const SIconButton = styled(IconButton)(() => ({
  padding: 4,
}));

export const EmptyTable: FC<IEmptyTable> = ({ icon, text }) => (
  <SEmptyBox>
    {icon || <InboxIcon />}
    <Typography variant='h5'>{t('common.empty')}</Typography>
    <Typography variant='subtitle2' color={grey['600']}>
      {text || t('common.no_data')}
    </Typography>
  </SEmptyBox>
);

export interface IEmptyTable {
  icon?: ReactNode;
  text?: string;
}

const SEmptyBox = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  '& svg': {
    height: 60,
    width: 'auto',
    color: theme.palette.primary.main,
  },
}));

interface IFooter {
  children?: ReactNode;
  numberRows?: number;
  allNumbersRow?: number;
  titleRows?: string;
  onRefresh?: () => void;
  view?: View;
  legendData?: ILegendData[];
  height?: number;
}

type View = 'column' | 'footer';
