import {
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isEdge,
  browserVersion,
} from 'react-device-detect';

/**
 * These versions were chosen as minimal ones since they begin to use the methods findIndex and structuredClone
 */

const supportedBrowsers: SupportedBrowsers = {
  chrome: { isCurrentBrowser: isChrome, minVersion: 98 },
  firefox: { isCurrentBrowser: isFirefox, minVersion: 94 },
  safari: { isCurrentBrowser: isSafari, minVersion: 15.4 },
  edge: { isCurrentBrowser: isEdge, minVersion: 98 },
  opera: { isCurrentBrowser: isOpera, minVersion: 84 },
};

export const isBrowserOutdated = (): boolean => {
  const currentVersion: number = parseFloat(browserVersion);

  for (const browser in supportedBrowsers) {
    const browserInfo = supportedBrowsers[browser];
    if (browserInfo.isCurrentBrowser && currentVersion < browserInfo.minVersion) {
      return true;
    }
  }

  return false;
};

interface SupportedBrowsers {
  [browser: string]: {
    minVersion: number;
    isCurrentBrowser: boolean;
  };
}
