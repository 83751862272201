import { take } from 'rxjs';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';
import { ArticlePostTabDataRes, articleService } from '../services/article.service';
import { ITabState } from '../states/tabState.model';

export const defaultArticlePostTabData: ArticlePostTabDataRes = {
  id: '',
  shippingWeight: null,
};

export async function resolveDataForPostTab(data: ITabState): Promise<ITabState> {
  data.defaultValues = data?.articleId
    ? await articleService.globHelpers.streamToPromise(
        articleService.sub.articlePostTabData().pipe(
          responseHandler<ArticlePostTabDataRes>({
            errorReturnType: defaultArticlePostTabData,
            customErrorHandler: () => 'article.tab_not_loaded',
          }),
          take(1),
        ),
        () => {
          articleService.pub.articlePostTabData({ id: data.articleId! });
        },
      )
    : {};
  data.defaultValues.id = data.articleId;
  return data;
}
