import { gql } from 'graphql-request';

export const emailTrackingByType = gql`
  query EmailTrackingByType($type: C_EMAIL_TRACKING_DOCTYPE!, $id: ID!) {
    wawiAssist {
      getWA_EmailTrackingByType(type: $type, id: $id) {
        messageId
        senderAddress
        receiverAddress
        messageSubject
        timestamp
        events {
          timestamp
          type
          diagnosticInfo
        }
      }
    }
  }
`;
