import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { i18nResorces as resources } from '../main.i18n';
i18n.use(initReactI18next).init({
  resources,
  lng: 'de', // if you're using a language detector, do not define the lng option
  fallbackLng: 'de',
  debug: true,

  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});
export default i18n;
