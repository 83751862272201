import { FC } from 'react';
import { Stack, Box } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { useTranslation } from 'react-i18next';

import { IProductionTabDataRes } from '../../../states/printJobsDetails.state';
import { Filter } from './filter.component';

const SideListControls: FC<ISideListControlsProps> = ({
  countOrders,
  countOrderPreProduction,
  loading,
  initFilter,
}) => {
  const { t } = useTranslation();

  return (
    <Stack direction='row' marginBottom='16px' alignItems='center'>
      <Box sx={{ marginRight: '16px' }}>
        <Filter initFilter={initFilter} />
      </Box>
      <Stack direction='column'>
        <SCounters variant={countOrders === 0 ? 'warning' : 'primary'}>{`${t(
          'production.orders',
        )}: ${loading ? '-' : countOrders}`}</SCounters>
        <SCounters variant={countOrderPreProduction === 0 ? 'dimmed' : 'primary'}>{`${t(
          'production.pre_production_orders',
        )}: ${loading ? '-' : countOrderPreProduction}`}</SCounters>
      </Stack>
    </Stack>
  );
};
export default SideListControls;

const SCounters = styled('span')<{ variant: 'primary' | 'warning' | 'dimmed' }>(
  ({ theme, variant }) => ({
    color:
      variant === 'warning'
        ? 'var(--warning-main, #ED6C02)'
        : variant === 'dimmed'
        ? 'var(--text-primary, rgba(0, 0, 0, 0.37))'
        : 'var(--text-primary, rgba(0, 0, 0, 0.87))',
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
  }),
);

interface ISideListControlsProps {
  initFilter: IProductionTabDataRes['filter'];
  countOrders: IProductionTabDataRes['countOrders'];
  countOrderPreProduction: IProductionTabDataRes['countOrderPreProduction'];
  loading: boolean;
}
