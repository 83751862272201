import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useLoaderData } from 'react-router-dom';
import { ITabState } from '../../../states/tabState.model';
import { Content } from '../../../../../shared/components/content/content.component';
import { AvailabilityForm } from './components/availabilityForm.component';
import { IAvailabilityTabState, availabilityTabState } from './availability.state';
import { ArticleAvailabilityTabDataRes } from '../../../services/article.service';

export const AvailabilityTab: FC = () => {
  const { defaultValues } = useLoaderData() as IAvailabilityTab;
  const [isArticleIdExist, setIsArticleIdExist] = useState<boolean>(Boolean(defaultValues?.id));
  const { t } = useTranslation();
  const formMethods = useForm({ defaultValues, mode: 'onChange' });

  const {
    formState: { isValid, isDirty },
  } = formMethods;

  useEffect(() => {
    availabilityTabState.pub.form(formMethods);
  }, [isDirty, isValid]);

  useEffect(() => {
    const unsubarticleIdNotExist = availabilityTabState.sub.articleIdNotExist().subscribe(() => {
      setIsArticleIdExist(false);
      formMethods.reset({});
    });
    const unsubAvailabilityState = availabilityTabState.sub
      .state()
      .subscribe(({ defaultValues }: IState) => {
        setIsArticleIdExist(true);
        formMethods.reset(defaultValues);
      });
    return () => {
      unsubarticleIdNotExist.unsubscribe();
      unsubAvailabilityState.unsubscribe();
    };
  }, []);

  return (
    <>
      {isArticleIdExist ? (
        <AvailabilityForm formMethods={formMethods} />
      ) : (
        <Content
          type='paper'
          paperProps={{
            variant: 'elevation',
            sx: {
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              height: '100%',
            },
          }}
          emptyData={{
            value: true,
            title: t('common.no_data'),
          }}
        />
      )}
    </>
  );
};

interface IState extends IAvailabilityTabState {
  defaultValues: ArticleAvailabilityTabDataRes;
}
interface IAvailabilityTab extends ITabState {
  defaultValues: ArticleAvailabilityTabDataRes;
}
