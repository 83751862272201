import { styled } from '@mui/material';

export const SPreviewContainer = styled('div')<{ link: string }>(({ theme, link }) => ({
  height: '80vh',
  display: link ? 'block' : 'flex',
  alignItems: link ? 'normal' : 'center',
  justifyContent: link ? 'normal' : 'center',
  margin: '-16px -24px',
  marginBottom: '-24px',
  backgroundColor: theme.palette.deepGrey.main,
  '& .iframe-container': {
    overflow: 'hidden',
    height: '100%',
    zIndex: '99',
    opacity: '0.9',
    '& iframe': {
      width: '100%',
      height: '100%',
      borderWidth: '0',
    },
  },
}));
