import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { BehaviorSubject, map, merge, Observable, share, switchMap, tap } from 'rxjs';
import { Pub, State, Sub } from '../../../../shared/state/state.abstract.ts';
import {
  C_Availability_In_Procurement_System,
  ListProcurementArticlesQueryVariables,
} from '../../../../graphql/generatedModel.ts';
import {
  ListProcurementArticlesRes,
  stockOfFreezerService,
} from '../../services/stockOfFreezer.service.ts';
import { dataHelper } from '../../../../shared/helpers/data/data.helper.ts';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler.ts';
import { storageHelper } from '../../../../shared/helpers/storage';

export const initStockOfFreezerListState: IStockOfFreezerListState = {
  action: undefined,
  filter: {
    isActive: true,
    availabilityInProcurementKindId: C_Availability_In_Procurement_System.AIPS1_MERCHANDISE_ARTICLE,
    storageLocationId: null,
  },
  search: '',
  sortModel: [{ field: 'articleNo', sort: 'asc' }],
  selectedArticle: undefined,
  articleList: [],
  allArticleListLength: 0,
};

class PubImpl extends Pub<IStockOfFreezerListState> {
  init(params: Partial<IStockOfFreezerListState>) {
    this.emit('init', params);
  }
  selectCustomer(selectedArticle?: IStockOfFreezerListState['selectedArticle']) {
    this.emit('selectArticle', {
      selectedArticle: selectedArticle || this.stream$.getValue().selectedArticle,
    });
  }
  sort(sortModel: IStockOfFreezerListState['sortModel']) {
    this.emit('sort', { sortModel });
  }
  search(search: string) {
    this.emit('search', { search });
  }
  filter(filter: IStockOfFreezerListState['filter']) {
    this.emit('filter', { filter });
  }
  articleList() {
    this.emit('articleList', {});
  }
  updateArticle(article: ListProcurementArticlesRes[number]) {
    this.emit('updateArticle', { params: article });
  }
}

class SubImpl extends Sub<IStockOfFreezerListState> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());

  protected actionHandlers(): Observable<IStockOfFreezerListState> {
    return merge(this.articleList(), this.updateState(), this.updateArticle()).pipe(
      map(({ articleList, allArticleListLength, ...rest }) => {
        let sortedArticleList = [...articleList];
        let selectedArticle = rest.selectedArticle;
        allArticleListLength = articleList.length;
        articleList = dataHelper
          .data(articleList as [])
          .sort({
            sortModel: rest.sortModel,
            callback: (data) => {
              sortedArticleList = data as [];
            },
          })
          .search({ search: rest.search, fields: ['articleNo', 'description'] })
          .result() as IStockOfFreezerListState['articleList'];
        // Check if selectedArticle is in new list. Otherwise, select the first one from the list.
        if (rest.action === 'articleList' || rest.action === 'filter') {
          const foundSelectedRecord =
            articleList.find((item) => item?.id === selectedArticle?.id) || articleList[0];
          selectedArticle = foundSelectedRecord || null;
          rest.action = 'selectArticle';
          storageHelper.session.setItem('stockOfFreezer.selectedArticle', selectedArticle);
        }
        const updatedState = {
          ...rest,
          articleList,
          selectedArticle,
          allArticleListLength,
        };
        this.stream$.next({
          ...updatedState,
          articleList: sortedArticleList,
          action: 'internalUpdate',
        });
        return updatedState;
      }),
      tap(({ action }) => {
        if (action !== 'sort' && action !== 'search') this.loading$.next(false);
      }),
    );
  }
  loading(): Observable<boolean> {
    return this.shareLoading$;
  }
  /* PRIVATE METHODS start */
  private articleList(): Observable<IStockOfFreezerListState> {
    return this.actionListener(['articleList', 'filter']).pipe(
      tap(({ filter }) => {
        this.loading$.next(true);
        stockOfFreezerService.pub.procurementArticlesList({ filter });
      }),
      switchMap((state) => {
        return stockOfFreezerService.sub.procurementArticlesList().pipe(
          responseHandler<ListProcurementArticlesRes>({ errorReturnType: [] }),
          map((articleList) => ({ ...state, articleList })),
        );
      }),
    );
  }

  private updateState(): Observable<IStockOfFreezerListState> {
    return this.actionListener(['search', 'sort', 'selectArticle']);
  }

  private updateArticle(): Observable<IStockOfFreezerListState> {
    return this.actionListener('updateArticle').pipe(
      map((state) => {
        const { params } = state;
        if (params) {
          const itemIdx = state.articleList.findIndex((item) => item.id === params.id);
          if (itemIdx !== -1) {
            const temp = [...state.articleList];
            temp.splice(itemIdx, 1, params);
            state.articleList = temp;
          }
        }
        return state;
      }),
    );
  }
  /* PRIVATE METHODS end */
}

class StockOfFreezerState extends State<IStockOfFreezerListState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const stockOfFreezerListState = new StockOfFreezerState(initStockOfFreezerListState);

export interface IStockOfFreezerListState {
  action:
    | 'init'
    | 'search'
    | 'sort'
    | 'articleList'
    | 'selectArticle'
    | 'filter'
    | 'loader'
    | 'internalUpdate'
    | 'updateArticle'
    | undefined;
  articleList: ListProcurementArticlesRes;
  search: string;
  filter: ListProcurementArticlesQueryVariables['filter'];
  sortModel: GridSortModel;
  selectedArticle: ListProcurementArticlesRes[number] | undefined;
  allArticleListLength: number;
  params?: any;
}
