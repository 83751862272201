import { GridSortModel } from '@mui/x-data-grid-premium';
import { take, zip } from 'rxjs';
import { dataHelper } from '../../../shared/helpers/data/data.helper';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';
import {
  configsData,
  DictArticlesRes,
  DictRecipesWithConnectedArticlesRes,
} from '../../../shared/services/configsData/configsData.service';
import {
  ArticleProductionTabDataRes,
  ProductionTabOptionsRes,
  articleService,
} from '../services/article.service';
import { ITabState } from '../states/tabState.model';

export const defaultArticleProductionTabData: ArticleProductionTabDataRes = {
  id: '',
  daysToProduce: null,
  productionRecipeId: null,
  isDough: false,
  isFermentationInterrupt: false,
  doughWeightBeforeBaking: null,
  doughArticleId: null,
  countPerSheetOfCuttingMachine: null,
  countOfSheetsPerCuttingMachine: null,
  isShowOnProductionPrintLists: false,
  isDoughSplitting: null,
  doughSplittingDough1Amount: null,
  weightOfLiterOfDough: null,
  roundLitersTo: null,
  isFractionArticle: null,
  fractionArticleId: null,
  usedPartOfFractionArticle: null,
  numberOfPartsOfFractionArticle: null,
  isRoundFractionArticleAmount: null,
  willReplacedWithProductionCalc: false,
  isProductionQuantityRounding: false,
  articleProductionCalculation: [],
  articleProductionCalculationDependencies: {},
  articleDoughDataFromRA: {
    id: null,
    intermediateWeight: null,
  },
};

export async function resolveDataForProductionTab(data: ITabState): Promise<ITabState> {
  const dictArticleModel = [{ field: 'articleNo', sort: 'asc' }] as GridSortModel;
  const productionData = data?.articleId
    ? await articleService.globHelpers.streamToPromise(
        articleService.sub.articleProductionTabData().pipe(
          responseHandler<ArticleProductionTabDataRes>({
            errorReturnType: defaultArticleProductionTabData,
            customErrorHandler: () => 'article.tab_not_loaded',
          }),
          take(1),
        ),
        () => {
          articleService.pub.articleProductionTabData({ id: data.articleId! });
        },
      )
    : {};
  const [dictArticles = [], dictRecipesWithConnectedArticles = []] =
    await articleService.globHelpers.streamToPromise(
      zip(configsData.sub.dictArticles(), configsData.sub.dictRecipesWithConnectedArticles()).pipe(
        responseHandler<DictArticlesAndRecipesRes>({
          customErrorHandler: () => 'article.tab_not_loaded',
          errorReturnType: [[], []],
        }),
        take(1),
      ),
      () => {
        configsData.pub.common(['dictArticles', 'dictRecipesWithConnectedArticles']);
      },
    );
  data.defaultValues = productionData;
  data.defaultValues.id = data.articleId;
  data.articleProductionCalculation = (
    productionData as ArticleProductionTabDataRes
  )?.articleProductionCalculation;
  data.productionTabOptions = {
    dictArticles: dataHelper
      .data(dictArticles as [])
      .sort({ sortModel: dictArticleModel })
      .result(),
    dictRecipesWithConnectedArticles: sortDictRecipesWithConnectedArticles(
      dictRecipesWithConnectedArticles,
    ),
  };
  return data;
}

export const sortDictRecipesWithConnectedArticles = (
  dict: ProductionTabOptionsRes['dictRecipesWithConnectedArticles'],
): ProductionTabOptionsRes['dictRecipesWithConnectedArticles'] => {
  const dictRecipesModel = [{ field: 'recipeNo', sort: 'asc' }] as GridSortModel;
  const sortedDict = dataHelper.data(dict).sort({ sortModel: dictRecipesModel }).result();
  return sortedDict as ProductionTabOptionsRes['dictRecipesWithConnectedArticles'];
};

type DictArticlesAndRecipesRes = [DictArticlesRes, DictRecipesWithConnectedArticlesRes];
