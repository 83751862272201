import { FC, useCallback, useState } from 'react';
import { Button, Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  FieldsRow,
  FormCheckbox,
  FormDatePicker,
  FormNumericField,
  FormSelect,
  FormTextField,
} from '../../../../../../../../../shared/components/form';
import { ITabState } from '../../../../../../../states/tabState.model.ts';
import {
  ArticleStdPriceQueryRes,
  customerService,
} from '../../../../../../../services/customer.service.ts';
import { responseHandler } from '../../../../../../../../../shared/responseHandler/responseHandler.ts';
import { take } from 'rxjs';
import { UseFormReturn } from 'react-hook-form';
import { IPopupWrapper } from '../../../../../../../../../shared/components/popup/components/popup.component.tsx';
import { conditionsTabState } from '../../../../conditions.state.ts';
import { DictArticlesOption } from '../../../../../../../loaders/conditionsTab.resolver.ts';
import { TDefaultValues } from './individualPrices.popup.tsx';
import { format } from 'date-fns';
import { ISelectOption } from '../../../../../../../../../shared/components/form/fields/select.component.tsx';
import { useBeforeClosePopup } from '../../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';

export const IndividualPricesForm: FC<IIndividualPricesForm> = ({
  field,
  productListOptions,
  priceCategory,
  formMethods,
  stream,
}) => {
  const { t } = useTranslation();
  const [productLoading, setProductLoading] = useState(false);
  const { errors, isDirty, dirtyFields, isValid } = formMethods.formState;

  const handleClick = useCallback(() => {
    if (isDirty && isValid) {
      const values = formMethods.getValues();
      const { specialPriceTypeEnum, ...data } = values as TDefaultValues;
      data.specialPriceType = specialPriceTypeEnum!;
      if (data.fromDate) data.fromDate = format(data.fromDate, 'yyyy-MM-dd');
      if (data.toDate) data.toDate = format(data.toDate, 'yyyy-MM-dd');
      conditionsTabState.pub.updateSpecialPricesPos(data);
    }
    if (isValid) {
      stream.emit('close');
    } else formMethods.trigger('specialPriceType');
  }, [isDirty, dirtyFields, isValid]);

  const filterOptions = (
    options: ExtendedSelectOption[],
    { inputValue }: { inputValue: string },
  ): ExtendedSelectOption[] =>
    options.filter(
      (option) =>
        ((option?.articleNo ? `${option?.articleNo} ` : '') + (option?.label || ''))
          ?.toLowerCase()
          .includes(inputValue?.toLowerCase()) as boolean,
    );

  useBeforeClosePopup({
    stream,
    isDirty: !!Object.keys(dirtyFields).length,
  });

  return (
    <>
      <Stack spacing={2}>
        <FieldsRow>
          <FormSelect
            disableClearable
            autoFocus={'description' === field}
            width='100%'
            name='articleId'
            label={t('customer.article')}
            options={productListOptions as []}
            loading={productLoading}
            datasetattribute='articleOptions'
            onChange={(v) => {
              customerService.sub
                .articleStdPrice()
                .pipe(
                  responseHandler<ArticleStdPriceQueryRes | null>({ errorReturnType: null }),
                  take(1),
                )
                .subscribe((v) => {
                  const product = productListOptions?.find(
                    (item) => item.id === formMethods.watch('articleId'),
                  ) as DictArticlesOption;
                  if (v) {
                    formMethods.setValue('regularPrice', v);
                  }
                  formMethods.setValue('description', product?.label);
                  formMethods.setValue('articleNo', product?.articleNo);
                  formMethods.setValue('articleId', product?.id);
                  setProductLoading(false);
                });
              customerService.pub.articleStdPrice({
                stdPriceInput: {
                  priceCategory,
                  articleId: String(v),
                },
              });
              setProductLoading(true);
            }}
            getOptionLabel={(params) => {
              const { articleNo, label } = params as ExtendedSelectOption;
              return `${articleNo} ${label}`;
            }}
            {...{ filterOptions }}
          />
          <SFormNumericField
            name='regularPrice'
            width='142px'
            autoFocus={'regularPrice' === field}
            label={t('customer.stdPrice')}
            InputProps={{
              readOnly: true,
            }}
            disabled
          />
        </FieldsRow>
        <FieldsRow>
          <FieldsRow width='50%'>
            <FormNumericField
              name='fromQuantity'
              autoFocus={'fromQuantity' === field}
              precision={3}
              noTrailingZeros
              label={t('customer.fromQuantity')}
              controllerProps={{
                rules: {
                  deps: ['specialPriceType'],
                },
              }}
            />
            <FormNumericField
              autoFocus={'specialPrice' === field}
              name='specialPrice'
              label={t('customer.price')}
              controllerProps={{
                rules: {
                  deps: ['specialPriceType'],
                },
              }}
            />
          </FieldsRow>
          <FieldsRow width='50%'>
            <FormNumericField
              name='specialDiscount'
              autoFocus={'specialDiscount' === field}
              precision={2}
              noTrailingZeros
              returnZero
              InputProps={{
                endAdornment: '%',
              }}
              controllerProps={{
                rules: {
                  deps: ['specialPriceType'],
                },
              }}
              max={100}
              label={t('customer.discount')}
            />
            <FieldsRow width={142} minWidth={142}>
              <FormCheckbox
                name='specialIsNoDiscount'
                inputProps={{
                  autoFocus: 'specialIsNoDiscount' === field,
                }}
                controllerProps={{
                  rules: {
                    deps: ['specialPriceType'],
                  },
                }}
                label={t('customer.noDiscount')}
              />
            </FieldsRow>
          </FieldsRow>
        </FieldsRow>
        <FieldsRow>
          <FieldsRow width='50%'>
            <FormDatePicker
              sx={{ flexGrow: 1 }}
              returnEmpty
              fieldProps={{ label: t('common.from_date'), autoFocus: 'fromDate' === field }}
              navigation={false}
              name='fromDate'
            />
            <FormDatePicker
              sx={{ flexGrow: 1 }}
              returnEmpty
              fieldProps={{ label: t('common.to_date'), autoFocus: 'toDate' === field }}
              navigation={false}
              name='toDate'
            />
          </FieldsRow>
          <FieldsRow width='50%'>
            <FormTextField
              width='100%'
              label={t('customer.comment')}
              name='comment'
              autoFocus={'comment' === field}
            />
          </FieldsRow>
        </FieldsRow>
        <FieldsRow>
          <SFormTextField
            width='100%'
            label={t('customer.kind')}
            name='specialPriceType'
            autoFocus={'specialPriceType' === field}
            {...(errors.specialPriceType && {
              error: true,
              helperText: t(errors['specialPriceType'].message as string),
            })}
            InputProps={{
              readOnly: true,
            }}
            disabled
          />
        </FieldsRow>
      </Stack>
      <Stack marginTop='34px' direction='row' spacing={2} justifyContent='flex-end'>
        <Button disabled={!!Object.keys(errors)?.length} variant='contained' onClick={handleClick}>
          {t('common.save')}
        </Button>
      </Stack>
    </>
  );
};

const SFormTextField = styled(FormTextField)({
  '& .MuiInputBase-root, & input': {
    cursor: 'not-allowed',
  },
});

const SFormNumericField = styled(FormNumericField)({
  '& .MuiInputBase-root, & input': {
    cursor: 'not-allowed',
  },
});

interface IIndividualPricesForm {
  field: string;
  productListOptions: ITabState['conditionsTabOptions']['productList'];
  priceCategory: number;
  customerId: string;
  formMethods: UseFormReturn<ITabState['customerSpecialPrices'][number]>;
  stream: IPopupWrapper['stream'];
}

interface ExtendedSelectOption extends ISelectOption {
  articleNo?: string;
}
