import { FC } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { ITextEditorProps, TextEditor } from '../../textEditor/textEditor.component.tsx';

export const FormTextEditor: FC<TFormTextEditor> = ({
  defaultValue,
  onBlur,
  onChange,
  defaultFormat,
  name,
  controllerProps = {},
  ...editorProps
}) => {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      {...controllerProps}
      render={({
        field: { value, onBlur: onFieldBlur, onChange: onFieldChange, ...fieldProps },
      }) => {
        return (
          <TextEditor
            defaultValue={value}
            onBlur={(e) => {
              onFieldBlur();
              onBlur?.(e);
            }}
            onChange={(e) => {
              onFieldChange(e);
              onChange?.(e);
            }}
            defaultFormat={defaultFormat}
            {...fieldProps}
            {...editorProps}
          />
        );
      }}
    />
  );
};

interface TFormTextEditor extends ITextEditorProps {
  controllerProps?: Omit<ControllerProps, 'render' | 'name'>;
  name: ControllerProps['name'];
}
