/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/rules-of-hooks */
import { TPopupDefaultActions } from '../../../../../../shared/components/popup/services/popup.service.ts';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../shared/components/popup/popup.abstract.tsx';
import { useTranslation } from 'react-i18next';
import { FormCheckbox, FormTextEditor } from '../../../../../../shared/components/form';
import { Box, Button, Stack, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ArticleGeneralTabDataRes } from '../../../../services/article.service.ts';
import { fontHelper } from '../../../../../../shared/helpers/font/font.helper.ts';
import { useBeforeClosePopup } from '../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';

export class NotePopup extends Popup<INotePopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const {
        resetField,
        getValues,
        formState: { isDirty },
      } = useFormContext();
      const { t } = useTranslation();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 756 },
        }));
      }, []);
      const defaultFontSize = useMemo(() => fontHelper.getDefaultFont(['fontSize']), []);
      const [defaultValues, setDefaultValues] = useState<INotePopup['values']>({
        note: null,
        isNotifyOnOrderStage: false,
      });
      useEffect(() => {
        stream.actionListener('open').subscribe((data) => setDefaultValues(data?.values));
      }, []);

      useBeforeClosePopup({
        stream,
        isDirty,
        handlers: () => {
          resetField('note', { defaultValue: defaultValues?.note });
          resetField('isNotifyOnOrderStage', {
            defaultValue: defaultValues?.isNotifyOnOrderStage,
          });
        },
        deps: [defaultValues?.note, defaultValues?.isNotifyOnOrderStage],
      });

      return (
        <SWrapper fullWidth stream={stream} popupTitle={t('common.note')}>
          <FormTextEditor name='note' defaultFormat={defaultFontSize} />
          <Box marginTop={3}>
            <FormCheckbox
              name='isNotifyOnOrderStage'
              label={t('article.warn_user_with_this_note')}
            />
          </Box>
          <Stack marginTop='34px' direction='row' spacing={2} justifyContent='flex-end'>
            <Button
              variant='contained'
              onClick={() => {
                const { note, isNotifyOnOrderStage } = getValues();
                if (
                  defaultValues?.note !== note ||
                  defaultValues?.isNotifyOnOrderStage !== isNotifyOnOrderStage
                ) {
                  stream.emit('save', { values: { note, isNotifyOnOrderStage } });
                }

                stream.emit('close');
              }}
            >
              {t('common.save')}
            </Button>
          </Stack>
        </SWrapper>
      );
    };
  }
}

interface INotePopup {
  action: 'save' | TPopupDefaultActions;
  values?: Pick<ArticleGeneralTabDataRes, 'note' | 'isNotifyOnOrderStage'>;
}
