import { TypeTable } from '../../../../../../../../../shared/components/table/table.component.tsx';
import { viewCells } from './viewCells.ts';

export const viewRows: TypeTable['viewRows'] = {
  action: () => {
    return 'row';
  },
  styles: [
    {
      className: 'row',
      rowStyles: {
        '&.MuiDataGrid-row.Mui-selected': {
          ...(viewCells?.styles &&
            viewCells.styles.reduce((acc: Record<string, any>, el) => {
              if (el.className.includes('editable')) {
                let tag: any = el.className.split('-');
                if (tag.length > 1) {
                  tag = `.${tag[0]}`;
                } else tag = 'div';
                acc[`& > ${tag}`] = Object.getOwnPropertyDescriptor(
                  el.cellStyles,
                  '&:hover',
                )?.value;
              }
              return acc;
            }, {})),
        },
      },
    },
  ],
};
