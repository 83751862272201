/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useMemo } from 'react';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../shared/components/popup/popup.abstract';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { TPopupDefaultActions } from '../../../../../../../shared/components/popup/services/popup.service';
import { IPeriodForTable } from '../deliveryPeriods/components/rightSide/tabs/period.popup';
import { Content } from './content.popup';

export class AddOrEditPeriod extends Popup<IAddOrEditPeriod> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const { t } = useTranslation();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 594 },
          '& > div > div > div': { padding: 0 },
        }));
      }, []);

      return (
        <SWrapper stream={stream} popupTitle={t('article.period')}>
          <Content stream={stream} />
        </SWrapper>
      );
    };
  }
}

export interface IAddOrEditPeriod {
  action: 'save' | TPopupDefaultActions;
  values: {
    period?: IPeriodForTable;
    periodsData: IPeriodForTable[];
  };
}
