import { FC } from 'react';
import { Tabs as MuiTabs, TabsProps, Box } from '@mui/material';

export const Tabs: FC<TabsProps> = ({ children, ...props }) => {
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
      <MuiTabs {...props}>{children}</MuiTabs>
    </Box>
  );
};
