import { firstValueFrom } from 'rxjs';
import * as yup from 'yup';
import { articleService, ValidateArticleFieldsRes } from '../../../../services/article.service';
import { responseHandler } from '../../../../../../shared/responseHandler/responseHandler';

export const createValidationSchema = (): yup.AnyObjectSchema => {
  let lastSelectedArticleId: string;
  let lastSelectedRecipeId: string;
  let isValid = false;
  const schema = yup
    .object({
      productionRecipeId: yup
        .string()
        .nullable()
        .test(
          'productionRecipeId-server-validation',
          'article.article_already_linked',
          async (value, { parent, options: { context } }): Promise<boolean> => {
            if (!value) {
              lastSelectedArticleId = parent?.id;
              isValid = true;
              return true;
            }
            try {
              if (parent?.id !== lastSelectedArticleId || lastSelectedRecipeId !== value) {
                lastSelectedArticleId = parent?.id;
                lastSelectedRecipeId = value as string;
                articleService.pub.validateProductionRecipeIdOnServer({
                  context,
                  fieldForCheck: { productionRecipeId: value },
                  id: parent.id,
                });

                const serverValidationRes = await firstValueFrom(
                  articleService.sub.validateProductionRecipeIdOnServer().pipe(
                    responseHandler<ValidateArticleFieldsRes>({
                      errorReturnType: {
                        articleNo: false,
                        productionRecipeId: false,
                      },
                    }),
                  ),
                );
                isValid = serverValidationRes.productionRecipeId;
              }
              return isValid;
            } catch (error) {
              if (error instanceof Error) {
                throw new Error(`Server validation failed: ${error.message}`);
              }
              throw new Error('An unknown error occurred during server validation');
            }
          },
        ),
    })
    .shape({});
  return schema;
};
