import React, { FC, useCallback } from 'react';
import { companyConfigService } from '../../../../../../shared/services/companyConfig/companyConfig.service.ts';
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { TableNumericField } from '../../../../../../shared/components/table/styles/styleFields.ts';
import { orderDetailsState as orderDetailsStateCreate } from '../../../../create/states/orderDetails/orderDetails.state.ts';
import { orderDetailsState as orderDetailsStateEdit } from '../../../../edit/states/orderDetails.state.ts';
import { clearRowFromVirtualKeys } from '../splitting/additionalFunctions.tsx';

export const PriceEditCell: FC<TPriceEditCell> = ({ api, ...params }) => {
  const precision = companyConfigService.getCachedConfigs()?.decimalPlacesCount || 2;
  const { setEditCellValue, stopCellEditMode } = api;
  const { value, field, id, row, type } = params;

  const updateCell = useCallback(
    (v: number | null) => {
      if (Number(value) !== v) {
        setEditCellValue({ id, field, value: v });
        const clearedRow = clearRowFromVirtualKeys(row);
        (type === 'create' ? orderDetailsStateCreate : orderDetailsStateEdit).pub.updateCell(
          { ...clearedRow, price: v },
          'priceCell',
        );
      }
    },
    [params, precision],
  );
  const handleKeyDown = (v: React.KeyboardEvent) => {
    if (v.target instanceof HTMLInputElement && v.code === 'Enter') {
      const value = v.target.value.replace(/,/g, '.');
      updateCell(Number(value));
      stopCellEditMode({ id, field });
    }
  };

  return (
    <TableNumericField
      onBlur={updateCell}
      onKeyDown={handleKeyDown}
      autoFocus
      {...{ precision, value }}
    />
  );
};

type TPriceEditCell = GridRenderEditCellParams & { type: 'create' | 'edit' };
