import { IOpenDebtsLoader } from '../../loaders/openDeps.loader.ts';
import { Pub, State, Sub } from '../../../../shared/state/state.abstract.ts';
import { BehaviorSubject, filter, map, merge, Observable, share, switchMap, tap } from 'rxjs';
import {
  OpenDebtsGridDataRes,
  openDebtsService,
  SaveOpenReceivablesRemindersRes,
} from '../../services/openDebts.service.ts';
import { format } from 'date-fns';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler.ts';
import { dataHelper } from '../../../../shared/helpers/data/data.helper.ts';

class PubImpl extends Pub<IOpenDebtsState> {
  init(data: Omit<IOpenDebtsState, 'action'>) {
    this.emit('init', data);
  }
  getOpenDebtsGridData(
    args?: Partial<Pick<IOpenDebtsState, 'paymentsUpToDate' | 'invoicesDates'>>,
  ) {
    this.emit('getOpenDebtsGridData', { ...args });
  }
  sort(sortModel: IOpenDebtsState['sortModel']) {
    this.emit('sort', { sortModel });
  }
  selectRow(selectedRow: IOpenDebtsState['selectedRow']) {
    this.emit('selectRow', { selectedRow });
  }
  updateReminderCount(invoicesListIds: Array<string>) {
    this.emit('updateReminderCount', { invoicesListIds });
  }
}

class SubImpl extends Sub<IOpenDebtsState> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());
  protected actionHandlers(): Observable<IOpenDebtsState> {
    return merge(
      this.getOpenDebtsGridData(),
      this.updateReminderCount(),
      this.saveOpenReceivablesReminders(),
      this.updateState(),
    ).pipe(
      map(({ openDebtsGridData, sortModel, action, ...rest }) => {
        if (sortModel && openDebtsGridData.length) {
          openDebtsGridData = dataHelper
            .data(openDebtsGridData as [])
            .sort({ sortModel })
            .result() as IOpenDebtsState['openDebtsGridData'];
        }
        return { openDebtsGridData, sortModel, action, ...rest };
      }),
      tap((state) => {
        this.stream$.next({ ...state, action: 'internalUpdate' });
      }),
    );
  }
  loading(): Observable<boolean> {
    return this.shareLoading$;
  }
  getOpenDebtsGridData(): Observable<IOpenDebtsState> {
    return this.actionListener('getOpenDebtsGridData').pipe(
      switchMap(({ invoicesDates, paymentsUpToDate }) => {
        openDebtsService.pub.openDebtsGridData({
          invoicesFromDate: format(invoicesDates.fromDate, 'yyyy-MM-dd'),
          invoicesToDate: format(invoicesDates.toDate, 'yyyy-MM-dd'),
          paymentsUpToDate: format(paymentsUpToDate, 'yyyy-MM-dd'),
        });
        this.loading$.next(true);
        return openDebtsService.sub
          .openDebtsGridData()
          .pipe(responseHandler<OpenDebtsGridDataRes>({ errorReturnType: [] }));
      }),
      map((openDebtsGridData) => {
        this.loading$.next(false);
        return { ...this.stream$.getValue(), openDebtsGridData, selectedRow: null };
      }),
    );
  }
  private updateReminderCount(): Observable<IOpenDebtsState> {
    return this.actionListener('updateReminderCount').pipe(
      map(({ invoicesListIds }) => {
        const state = this.stream$.getValue();
        const updatedGridData = state.openDebtsGridData.map((debt) =>
          invoicesListIds?.includes(debt.invoiceId) && debt.remindersCount < 3
            ? { ...debt, remindersCount: (debt.remindersCount || 0) + 1 }
            : debt,
        );
        return { ...state, openDebtsGridData: updatedGridData };
      }),
    );
  }
  saveOpenReceivablesReminders(): Observable<IOpenDebtsState> {
    return openDebtsService.sub.saveOpenReceivablesReminders().pipe(
      responseHandler<SaveOpenReceivablesRemindersRes>({
        errorReturnType: {
          status: false,
          count: 0,
          invoiceId: '',
        },
        success: () => 'common.successfully_saved',
      }),
      filter((res) => res.status),
      map((data) => {
        const updatedState = structuredClone(this.stream$.getValue());
        updatedState.openDebtsGridData = updatedState.openDebtsGridData.map((item) => {
          if (item.invoiceId === data.invoiceId) {
            return { ...item, remindersCount: data.count };
          }
          return item;
        });
        return updatedState;
      }),
    );
  }
  private updateState(): Observable<IOpenDebtsState> {
    return this.actionListener(['sort', 'selectRow']);
  }
}

class OpenDebtsState extends State<IOpenDebtsState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const openDebtsState = new OpenDebtsState({
  action: undefined,
  invoicesDates: {
    fromDate: new Date(),
    toDate: new Date(),
  },
  paymentsUpToDate: new Date(),
  printFormId: null,
  reminderTextId: null,
  openDebtsGridData: [],
  selectedRow: null,
  sortModel: null,
  previewId: null,
  sendingId: null,
});

export interface IOpenDebtsState extends Omit<IOpenDebtsLoader, 'reminderTextOptions'> {
  action:
    | 'init'
    | 'sort'
    | 'getOpenDebtsGridData'
    | 'selectRow'
    | 'internalUpdate'
    | 'updateReminderCount'
    | undefined;
  invoicesListIds?: Array<string>;
}
