import * as yup from 'yup';
import { firstValueFrom } from 'rxjs';
import { customerService, ValidateCustomerFieldsRes } from '../../../../services/customer.service';
import { C_Customer_Address_Kind } from '../../../../../../graphql/generatedModel';
import { responseHandler } from '../../../../../../shared/responseHandler/responseHandler';

export const createValidationSchema = (): yup.AnyObjectSchema => {
  const schema = yup.object({
    customerNo: yup
      .string()
      .required('common.not_empty')
      .max(10, 'article.articleNo_maxLen')
      .test(
        'customerNo-server-validation',
        'article.not_unique_number',
        async (value, { options: { context } }): Promise<boolean> => {
          const { customerId } = context || {};
          try {
            if (context!.prevCustomerNoRef.current !== value) {
              if (!value) return false;
              context!.prevCustomerNoRef.current = value;
              customerService.pub.validateCustomerFields({
                fieldsForCheck: { customerNo: value },
                id: customerId,
                context,
              });
              const serverValidationRes = await firstValueFrom(
                customerService.sub.validateCustomerNo().pipe(
                  responseHandler<ValidateCustomerFieldsRes>({
                    errorReturnType: {
                      customerNo: false,
                      bank1MandatNo: false,
                      bank2MandatNo: false,
                    },
                  }),
                ),
              );
              context!.prevValidationResRef.current = serverValidationRes.customerNo;
              return serverValidationRes.customerNo;
            }
          } catch (error) {
            if (error instanceof Error) {
              throw new Error(`Server validation failed: ${error.message}`);
            }
            throw new Error('An unknown error occurred during server validation');
          }
          return context!.prevValidationResRef.current;
        },
      ),
    name: yup
      .string()
      .when(
        ['customerAddressKindId', 'internalName'],
        ([customerAddressKindId, internalName], schema) => {
          if (customerAddressKindId === C_Customer_Address_Kind['CAK3_PRIVATE_PERSON'])
            return schema.required('common.not_empty');
          return internalName ? schema.nullable() : schema.required('common.not_empty');
        },
      ),
    surname: yup.string().when('customerAddressKindId', ([customerAddressKindId], schema) => {
      return customerAddressKindId === C_Customer_Address_Kind['CAK3_PRIVATE_PERSON']
        ? schema.required('common.not_empty')
        : schema.nullable();
    }),
    email: yup.string().nullable().email('customer.enter_valid_email'),
    emailForInvoice: yup.string().nullable().email('customer.enter_valid_email'),
  });
  return schema;
};
