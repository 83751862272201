import { BehaviorSubject, Observable, map, merge, share, switchMap, tap } from 'rxjs';
import { Pub, State, Sub } from '../../../../shared/state/state.abstract';
import { CustomerListRes, createInvoiceService } from '../../services/createInvoice.service';
import { storageHelper } from '../../../../shared/helpers/storage';
import { GridSortModel } from '@mui/x-data-grid-premium';
import { GetCustomerListQueryVariables as filterVariables } from '../../../../graphql/generatedModel';
import { dataHelper } from '../../../../shared/helpers/data/data.helper';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler';

export const initCustomerListState: ICustomerListState = {
  action: undefined,
  customerList: [],
  datePeriod: { fromDate: '', toDate: '' },
  selectedCustomer: null,
  allCustomerListLength: 0,
  sortModel: [{ field: 'customerNo', sort: 'asc' }],
  filter: {
    showEmailService: true,
    showMailDelivery: true,
    customersActiveState: true,
  },
  search: '',
  params: undefined,
};

class PubImpl extends Pub<ICustomerListState> {
  init(params: Partial<ICustomerListState>) {
    this.emit('init', params);
  }
  selectCustomer(selectedCustomer: ICustomerListState['selectedCustomer']) {
    this.emit('selectCustomer', { selectedCustomer });
    storageHelper.session.setItem('createInvoice.selectedCustomer', selectedCustomer);
  }
  getCustomers(datePeriod?: ICustomerListState['datePeriod']) {
    let props = {};
    if (datePeriod) {
      props = { datePeriod };
      storageHelper.session.setItem('createInvoice.datePeriod', datePeriod);
    }
    this.emit('getCustomers', props);
  }
  filter(filter: TFilter) {
    this.emit('filter', { filter });
  }
  search(search: string) {
    this.emit('search', { search });
  }
  sort(sortModel: GridSortModel) {
    this.emit('sort', { sortModel });
    storageHelper.local.setItem('createInvoice.sortModel', sortModel);
  }
  removeSelectedCustomer() {
    this.emit('removeSelectedCustomer', {});
  }
  removeCustomersById(ids: string[]) {
    this.emit('removeCustomersById', { params: ids });
  }
}

class SubImpl extends Sub<ICustomerListState> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());
  protected actionHandlers(): Observable<ICustomerListState> {
    return merge(
      this.updateState(),
      this.getCustomers(),
      this.removeCustomersById(),
      this.removeSelectedCustomer(),
    ).pipe(
      map(({ customerList, search, sortModel, ...rest }) => {
        let sortedList = [...(customerList || [])];
        let selectedCustomer = rest.selectedCustomer;
        let action = rest.action;
        const allCustomerListLength = customerList.length || 0;
        const resolvedList = dataHelper
          .data(customerList as [])
          .sort({
            sortModel,
            callback: (data) => {
              sortedList = data as [];
            },
          })
          .search({ search, fields: ['customerNo', 'internalOrFullName'] })
          .result() as ICustomerListState['customerList'];
        // Check if selectedRecord is in new list. Otherwise, select the first one from the list.
        if (
          rest.action === 'getCustomers' ||
          rest.action === 'filter' ||
          rest.action === 'removeSelectedCustomer' ||
          rest.action === 'removeCustomersById'
        ) {
          const isSelectedCustomerInList = !!customerList.find(
            (item) => item?.customerId === selectedCustomer?.customerId,
          );
          if (!isSelectedCustomerInList) {
            selectedCustomer = customerList[0] || null;
            action = 'selectCustomer';
            storageHelper.session.setItem('createInvoice.selectedCustomer', selectedCustomer);
          }
        }

        const newState = {
          ...rest,
          customerList: resolvedList,
          selectedCustomer,
          action,
          allCustomerListLength,
          sortModel,
          search,
        };
        this.stream$.next({ ...newState, customerList: sortedList, action: 'internalUpdate' });
        return newState;
      }),
      tap(() => this.loading$.next(false)),
    );
  }

  loading(): Observable<boolean> {
    return this.shareLoading$;
  }

  private getCustomers(): Observable<ICustomerListState> {
    return this.actionListener(['getCustomers', 'filter']).pipe(
      tap(() => this.loading$.next(true)),
      tap(({ filter, datePeriod }) =>
        createInvoiceService.pub.customerList({
          filter: {
            ...filter,
            ordersStartDate: datePeriod?.fromDate,
            ordersEndDate: datePeriod?.toDate,
          },
        }),
      ),
      switchMap((state) => {
        return createInvoiceService.sub.customerList().pipe(
          responseHandler<ICustomerListState['customerList']>({
            customErrorHandler() {
              return 'common.error_customer_list_not_loaded';
            },
            errorReturnType: [],
          }),
          map((data) => {
            state.customerList = [...data];
            return state;
          }),
        );
      }),
    );
  }

  private removeSelectedCustomer(): Observable<ICustomerListState> {
    return this.actionListener('removeSelectedCustomer').pipe(
      map((state) => {
        const { customerList, selectedCustomer } = state;
        const newList = customerList.filter(
          (customer) => customer?.customerId !== selectedCustomer?.customerId,
        );
        return {
          ...state,
          customerList: newList,
        };
      }),
    );
  }

  private removeCustomersById(): Observable<ICustomerListState> {
    return this.actionListener('removeCustomersById').pipe(
      map((state) => {
        const { customerList, params } = state;
        const idsForDelete = params as string[];
        const newList = customerList.filter((customer) =>
          idsForDelete.every((id) => id !== customer?.customerId),
        );
        return {
          ...state,
          customerList: newList,
        };
      }),
    );
  }

  private updateState(): Observable<ICustomerListState> {
    return this.actionListener(['selectCustomer', 'sort', 'search']);
  }
}

class CustomerListState extends State<ICustomerListState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const customerListState = new CustomerListState(initCustomerListState);

export interface ISelectOption {
  id: string | number;
  label: string;
}

export interface IInvoiceIntervalOptions {
  active: ISelectOption[];
  inactive: ISelectOption[];
}

export interface ICustomerFilterOptionsRes {
  groupOptions: ISelectOption[];
  invoiceIntervalOptions: IInvoiceIntervalOptions;
}

export type TFilter = Omit<filterVariables['filter'], 'ordersStartDate' | 'ordersEndDate'>;

export interface ICustomerListState {
  action:
    | 'init'
    | 'internalUpdate'
    | 'selectCustomer'
    | 'loader'
    | 'getCustomers'
    | 'filter'
    | 'search'
    | 'removeSelectedCustomer'
    | 'removeCustomersById'
    | 'sort'
    | undefined;
  customerList: CustomerListRes;
  datePeriod: {
    fromDate: string;
    toDate: string;
  };
  selectedCustomer: CustomerListRes[number] | null;
  allCustomerListLength: number;
  filter: TFilter;
  filterOptionsData?: ICustomerFilterOptionsRes;
  sortModel: GridSortModel;
  search: string;
  params: any;
}
