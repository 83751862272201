import { FC } from 'react';

import { ITabState } from '../../../states/tabState.model.ts';
import { TMode } from '../../../../../../shared/services/mode/mode.service.ts';
import { GeneralForm } from './components/generalForm.component.tsx';

export const GeneralTab: FC<Pick<IGeneralTab, 'mode'>> = ({ mode }) => (
  <>
    <GeneralForm mode={mode} />
  </>
);

export interface IGeneralTab extends ITabState {
  mode: TMode;
}
