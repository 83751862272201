import { gql } from 'graphql-request';

export const getCreatedInvoices = gql`
  query listWA_CreatedInvoices($filter: WA_CreatedInvoicesGridFilter!) {
    wawiAssist {
      listWA_CreatedInvoices(filter: $filter) {
        invoiceId
        invoiceDate
        invoiceNo
        invoiceTotal
        isDeleted
        customerId
        customerNo
        internalOrFullName
        isEmailService
        isExportedToPayNet
        isAutoBankDebit
        isExported
        exportStatus
      }
    }
  }
`;

export const getOrderPositionsForCreatedInvoice = gql`
  query getOrderPositionsForCreatedInvoice($orderId: ID!, $isInvoiceDeleted: Boolean!) {
    wawiAssist {
      listWA_OrderPositionsForCreatedInvoice(
        orderId: $orderId
        isInvoiceDeleted: $isInvoiceDeleted
      ) {
        orderPositionId
        articleNo
        articleName
        quantity
        price
        total
        discount
        taxName
      }
    }
  }
`;

export const getOrdersForCreatedInvoice = gql`
  query getOrdersForCreatedInvoice($id: ID!) {
    wawiAssist {
      listWA_OrdersForCreatedInvoice(invoiceId: $id) {
        orderId
        orderDate
        orderNo
        orderTotal
        customerId
        customerNo
        internalOrFullName
      }
    }
  }
`;

export const getInvoiceDeletingInfo = gql`
  query GetWA_InvoiceDeletingInfo($getWaInvoiceDeletingInfoId: ID!) {
    wawiAssist {
      getWA_InvoiceDeletingInfo(id: $getWaInvoiceDeletingInfoId) {
        paymentsToDeleteIds
        attachedOrders {
          id
          orderNo
          date
          total
        }
        deletionPossibility
      }
    }
  }
`;

export const getInvoiceData = gql`
  query getInvoiceData($id: ID!) {
    wawiAssist {
      getInvoiceData(id: $id) {
        id
        isDeleted
        linkText
        note
        datetimeOfLastEmail
        reasonForDeletion
      }
    }
  }
`;

export const deleteInvoice = gql`
  mutation DeleteWA_Invoice($deleteProps: WA_DeleteInvoiceInputs!) {
    wawiAssist {
      deleteWA_Invoice(deleteProps: $deleteProps)
    }
  }
`;
