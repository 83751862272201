import { Pub, Service, Sub } from '../../../shared/services/service.abstract.ts';
import { ListPaymentsQuery, ListPaymentsQueryVariables } from '../../../graphql/generatedModel.ts';
import { map, Observable, switchMap } from 'rxjs';
import { gqlClient } from '../../../graphql/graphqlRequest.ts';
import { listPayments } from './gql/payments.gql.ts';

type Actions = 'listPayments' | undefined;

class PubImpl extends Pub<Actions> {
  listPayments(params: ListPaymentsQueryVariables) {
    this.emit('listPayments', params);
  }
}
class SubImpl extends Sub<Actions> {
  listPayments(): Observable<ListPaymentsRes> {
    return this.actionListener('listPayments').pipe(
      switchMap(({ params }) => {
        return gqlClient(listPayments, params) as Observable<ListPaymentsQuery>;
      }),
      map((data) => {
        return data.wawiAssist?.listWA_Payments as ListPaymentsRes;
      }),
    );
  }
}

class PaymentsService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const paymentsService = new PaymentsService();

export type ListPaymentsRes = NonNullable<
  NonNullable<ListPaymentsQuery['wawiAssist']>['listWA_Payments']
>;
