import { Stack, Typography, Button } from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';
import EmotionIco from '@mui/icons-material/InsertEmoticon';

export const moduleErrorBoundary: FC = () => {
  return (
    <Stack height='100%' justifyContent='center' alignItems='center'>
      <EmotionIco color='primary' sx={{ width: '150px', height: '150px' }} />
      <Typography variant='h4'>{t('errors.unknown')}</Typography>
      <Button
        sx={{ mt: 2 }}
        size='medium'
        variant='contained'
        onClick={() => {
          window.location.href = '/';
        }}
      >
        {t('common.reload')}
      </Button>
    </Stack>
  );
};
