import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Box, Stack, styled, Tab, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { format } from 'date-fns';

import { storageHelper } from '../../../../shared/helpers/storage';
import { Switch as MuiSwitch } from '../../../../shared/components/form';
import { TabPanel, Tabs, TabsContainer } from '../../../../shared/components/tabs';
import { DatePicker } from '../../../../shared/components/datePicker/datePicker.component';
import ProductionTab from './production/production.tab';
import PrintDeliveryNotesTab from './printDeliveryNotes/printDeliveryNotes.tab';
import {
  IDeliveryNotesTabDataRes,
  initPrintJobsDetailsState,
  IPrintJobsDetailsState,
  IProductionTabDataRes,
  printJobsDetailsState,
  PrintJobsTabs,
} from '../states/printJobsDetails.state';
import { Theme } from '../../../../shared/styles/theme/theme';

const { isCADBAvailable, isBakery2bActivated } =
  storageHelper.session.getItem('companyConfig') || {};

const PrintJobsDetails: FC<IPrintJobsDetailsProps> = ({ initPrintJobsDetailsData }) => {
  const params = useParams() as { tab: PrintJobsTabs };
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<PrintJobsTabs>(params.tab);
  const [tabLoading, setTabLoading] = useState<boolean>(false);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [data, setData] = useState<IPrintJobsDetailsState>(
    (initPrintJobsDetailsData as IPrintJobsDetailsState) || initPrintJobsDetailsState,
  );

  const handleActiveTab: THandleActiveTab = (_, tab) => {
    setTabLoading(false);
    navigate(`/production/print-jobs/${tab}`);
  };

  useEffect(() => {
    const unsubPrintJobsDetailsState = printJobsDetailsState.sub.state().subscribe(setData);
    const unsubTabLoading = printJobsDetailsState.sub.tabLoading().subscribe(setTabLoading);
    const unsubListLoading = printJobsDetailsState.sub.listLoading().subscribe(setListLoading);
    return () => {
      unsubPrintJobsDetailsState.unsubscribe();
      unsubTabLoading.unsubscribe;
      unsubListLoading.unsubscribe;
    };
  }, []);

  useEffect(() => {
    const conditionForGetData = data.action !== 'loader';
    if (conditionForGetData) {
      printJobsDetailsState.pub.dataForSelectedTab(params.tab);
    }
    setActiveTab(params.tab);
  }, [params]);

  return (
    <>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <DatePicker
          defaultValue={new Date(data.datePeriod.fromDate)}
          onChange={(currentDate) => {
            const formattedDate = format(currentDate!, 'yyyy-MM-dd');
            printJobsDetailsState.pub.selectDatePeriod(formattedDate);
          }}
          navigation
          width={355}
          weekDay
        />
        <Box sx={{ display: 'flex', gap: '40px' }}>
          {isBakery2bActivated && (
            <SFlexContainer>
              <STypography>{t('production.orders_over_bakery2b')}</STypography>
              <MuiSwitch
                checked={data.statusList.bakery2b}
                size='small'
                label={data.statusList.bakery2b ? t('common.permitted') : t('common.forbidden')}
                onChange={(_, checked) => {
                  printJobsDetailsState.pub.changeStatusBakery2B(checked);
                }}
                color='primary'
                disabled={listLoading}
              />
            </SFlexContainer>
          )}
          {isCADBAvailable && (
            <SFlexContainer>
              <STypography>{t('production.orders_over_cashassist')}</STypography>
              <MuiSwitch
                checked={data.statusList.CAdirect}
                size='small'
                label={data.statusList.CAdirect ? t('common.permitted') : t('common.forbidden')}
                onChange={(_, checked) => {
                  printJobsDetailsState.pub.changeStatusCAdirect(checked);
                }}
                color='primary'
                disabled={listLoading}
              />
            </SFlexContainer>
          )}
        </Box>
      </Stack>
      <Tabs value={activeTab} onChange={handleActiveTab}>
        <Tab label={t('production.production')} value={PrintJobsTabs.production} />
        <Tab
          label={t('production.print_delivery_notes')}
          value={PrintJobsTabs.printDeliveryNotes}
        />
      </Tabs>
      <TabsContainer loading={tabLoading}>
        <TabPanel value={PrintJobsTabs.production} activeValue={activeTab}>
          <ProductionTab
            data={data.dataTabs?.['production'] as IProductionTabDataRes}
            datePeriod={data.datePeriod}
            listLoading={listLoading}
          />
        </TabPanel>
        <TabPanel value={PrintJobsTabs.printDeliveryNotes} activeValue={activeTab}>
          <PrintDeliveryNotesTab
            data={data.dataTabs?.['print-delivery-notes'] as IDeliveryNotesTabDataRes}
            action={data.action}
            datePeriod={data.datePeriod}
            loading={listLoading}
          />
        </TabPanel>
      </TabsContainer>
    </>
  );
};
export default PrintJobsDetails;

const SFlexContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const STypography = styled(Typography)({
  color: Theme.palette.secondary.light,
  fontFamily: Theme.typography.fontFamily,
  fontSize: '16px',
  marginBottom: '8px',
});

type THandleActiveTab = (evt: SyntheticEvent<Element, Event>, tab: PrintJobsTabs) => void;

interface IPrintJobsDetailsProps {
  initPrintJobsDetailsData?: IPrintJobsDetailsState;
}
