import { useLoaderData } from 'react-router-dom';
import { ITabState } from '../../../states/tabState.model';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CustomerAvailabilityRes } from '../../../services/customer.service';
import { availabilityTabState } from './availability.state';
import { AvailabilityForm } from './components/availabilityForm.component';

export const Availability: FC = () => {
  const { defaultValues } = useLoaderData() as ITabState;
  const formMethods = useForm<CustomerAvailabilityRes>({
    mode: 'onChange',
    defaultValues,
  });
  const {
    formState: { isValid, isDirty },
  } = formMethods;

  useEffect(() => {
    availabilityTabState.pub.form(formMethods);
  }, [isDirty, isValid]);

  useEffect(() => {
    const unsubState = availabilityTabState.sub.state().subscribe(({ defaultValues }) => {
      formMethods.reset(defaultValues);
    });
    return () => {
      unsubState.unsubscribe();
    };
  }, []);

  return <AvailabilityForm {...{ formMethods }} />;
};
