import { FC, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../shared/components/popup/popup.abstract';
import { TPopupDefaultActions } from '../../../../../../../shared/components/popup/services/popup.service';
import { MultiEmailContent } from './content.popup';
import { useTranslation } from 'react-i18next';
import { Observable } from 'rxjs';

export class MultiEmailPopup extends Popup<IMultiEmail> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;
    this.Component = () => {
      const [params, setParams] = useState<IMultiEmail['params']>();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 788 },
        }));
      }, []);
      useEffect(() => {
        stream.actionListener('open').subscribe(({ params }) => {
          if (params) setParams(params);
        });
      }, []);
      const { t } = useTranslation();

      return params === undefined ? null : (
        <SWrapper fullWidth stream={stream} popupTitle={t('common.emails')}>
          <MultiEmailContent stream={stream} params={params} />
        </SWrapper>
      );
    };
  }
}

export interface ICustomerAttachmentReport {
  customerId: string;
  url: string;
}
interface IParams {
  pub: () => void;
  sub: () => Observable<Array<ICustomerAttachmentReport>>;
  customerInvoices: Record<string, { customerId: string; invoiceIds: Array<string> }>;
}
export interface IMultiEmail {
  action: TPopupDefaultActions;
  params: IParams;
}
