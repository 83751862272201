import { gql } from 'graphql-request';

export const listPayments = gql`
  query ListPayments($filter: WA_PaymentsGridFilter!) {
    wawiAssist {
      listWA_Payments(filter: $filter) {
        id
        date
        invoiceNo
        customerNo
        internalOrFullName
        amount
        accountNo
        receiptNo
        description
      }
    }
  }
`;
