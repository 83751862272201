/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useMemo } from 'react';
import { Popup, TPopupComponent } from '../popup/popup.abstract';
import { TPopupDefaultActions } from '../popup/services/popup.service';
import { Observable } from 'rxjs';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { Content, ReportRes } from './content.popup';

export class PreviewPopup extends Popup<IPreviewPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const { t } = useTranslation();

      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': {
            maxWidth: 1200,
            maxHeight: '90vh',
          },
          '& > div > div > div': {
            borderBottom: 'none',
          },
        }));
      }, []);

      return (
        <SWrapper fullWidth stream={stream} popupTitle={t('common.preview')}>
          <Content stream={stream} />
        </SWrapper>
      );
    };
  }
}

export interface IPreviewPopup {
  action: TPopupDefaultActions;
  pub: () => void;
  sub: () => Observable<ReportRes>;
}
