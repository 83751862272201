import { PerformGlobalSearchRes } from '../../../pages/globalSearch/services/globalSearch.service';
import { ModuleName } from './globalSearchParams.util';

export const globalSearchBuildUrl = (
  moduleName: ModuleName,
  data:
    | PerformGlobalSearchRes['articles'][number]
    | PerformGlobalSearchRes['customers'][number]
    | PerformGlobalSearchRes['orders'][number]
    | PerformGlobalSearchRes['invoices'][number],
): string => {
  switch (moduleName) {
    case 'article': {
      const { id, isActive } = data as PerformGlobalSearchRes['articles'][number];
      return `/article/general/${id}/{"active":${isActive}}`;
    }

    case 'customer': {
      const { id, isActive } = data as PerformGlobalSearchRes['customers'][number];
      return `/customer/general/${id}/{"active":${isActive}}`;
    }

    case 'order': {
      const { id, isDeleted, date } = data as PerformGlobalSearchRes['orders'][number];
      return `/order/edit/${id}/${isDeleted}/{"from":"${date}","to":"${date}"}`;
    }

    case 'invoice': {
      const { id, isDeleted, date } = data as PerformGlobalSearchRes['invoices'][number];
      return `/invoice/invoices-list/${id}/${isDeleted}/{"from":"${date}","to":"${date}"}`;
    }

    default:
      return '';
  }
};
