import { C_Global_Error_Code } from '../../graphql/generatedModel';
import { LocalizationHelper } from '../helpers/localization/localization.helper';

type Keys = C_Global_Error_Code | 'unknown' | 'unknownWithReload' | 'reload' | '404' | 'toHome';

const t = LocalizationHelper<Keys>('errors');

export const errorsDe = t({
  unknown: 'Etwas ist schief gelaufen!',
  unknownWithReload: 'Etwas ist schiefgelaufen! Bitte versuchen Sie, die Seite neu zu laden.',
  reload: 'Neu laden',
  404: '404 Nicht gefunden',
  toHome: 'Gehen Sie zur Startseite',
  [C_Global_Error_Code.GEC10_INVALID_GQL_DATA]: 'Falsche Datenbankdaten',
  [C_Global_Error_Code.GEC20_NO_DATA]: 'Für Ihre Anfrage liegen keine Daten vor',
  [C_Global_Error_Code.GEC30_BAD_USER_INPUT]: 'Ungültige Daten an Server gesendet',
  [C_Global_Error_Code.GEC40_DELETING_RECORD_IN_USE]: 'Der gelöschte Datensatz wird verwendet',
  [C_Global_Error_Code.GEC41_DELETE_PROHIBITED]: 'Löschen verboten',
  [C_Global_Error_Code.GEC50_DB_IS_IN_UPDATING_PROCCES]: 'Die Datenbank wird gerade aktualisiert',
  [C_Global_Error_Code.GEC51_EMPTY_HISTORY_USER_ID]: 'HistoryUserID Fehler',
});

export const errorsEn = t({
  unknown: 'Something went wrong!',
  unknownWithReload: 'Something went wrong! Please try reloading the page.',
  reload: 'Reload',
  404: '404 Not found',
  toHome: 'Go to homepage',
  [C_Global_Error_Code.GEC10_INVALID_GQL_DATA]: 'Incorrect database data',
  [C_Global_Error_Code.GEC20_NO_DATA]: 'There is no data for your request',
  [C_Global_Error_Code.GEC30_BAD_USER_INPUT]: 'Invalid data sent to server',
  [C_Global_Error_Code.GEC40_DELETING_RECORD_IN_USE]: 'Deleted record is used',
  [C_Global_Error_Code.GEC41_DELETE_PROHIBITED]: 'Delete prohibited',
  [C_Global_Error_Code.GEC50_DB_IS_IN_UPDATING_PROCCES]: 'Database is in updating process',
  [C_Global_Error_Code.GEC51_EMPTY_HISTORY_USER_ID]: 'HistoryUserID error',
});
