/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { GridRowParams } from '@mui/x-data-grid-premium';

import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../shared/components/popup/popup.abstract';
import { TPopupDefaultActions } from '../../../../../../../../shared/components/popup/services/popup.service';
import { FormCheckboxList } from '../../../../../../../../shared/components/form';
import { useBeforeClosePopup } from '../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';

export class TourplansCaregoryFilterPopup extends Popup<ITourplansCaregoryFilterPopup> {
  Component: FC<TPopupComponent>;

  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const Wrapper = useMemo(() => this.Wrapper, []);
      const { t } = useTranslation();

      const [values, setValues] = useState<ITourplansCaregoryFilterPopup['values']>(undefined);
      const name = `tourplanConfigurationData.${values?.index || 0}.categoryFilter`;

      const {
        resetField,
        formState: { isDirty },
      } = useFormContext();

      useBeforeClosePopup({
        stream,
        isDirty,
        handlers: () => resetField(name),
        deps: [values, name],
      });

      useEffect(() => {
        stream.actionListener('open').subscribe(({ values }) => {
          if (values) setValues(values);
        });
      }, []);

      return (
        <Wrapper
          stream={stream}
          popupTitle={t('production.article_list_filter') + ' - ' + values?.row?.categoryName || ''}
          PaperProps={{ sx: { maxWidth: '500px', maxHeight: '800px' } }}
          fullWidth
        >
          <Stack direction='column' justifyContent='space-between'>
            <FormCheckboxList
              withDivider
              name={name}
              height='600px'
              minWidth='450px'
              controlLabel={'<' + t('common.all_lists') + '>'}
            />
          </Stack>
          <Stack mt={3} direction='row' justifyContent='flex-end' spacing={2}>
            <Button onClick={() => stream.emit('close')} variant='contained' sx={{ width: 200 }}>
              {t('common.save')}
            </Button>
          </Stack>
        </Wrapper>
      );
    };
  }
}

interface ITourplansCaregoryFilterPopup {
  action: TPopupDefaultActions;
  values?: {
    row: GridRowParams['row'];
    index: number;
  };
}
