import { FC } from 'react';
import { styled } from '@mui/material';
import FormatColorText from '@mui/icons-material/FormatColorText';
import CheckedIcon from '@mui/icons-material/CheckCircleTwoTone';
import { DropdownButton, useActive, useCommands } from '@remirror/react';
import { useTranslation } from 'react-i18next';

export const ColorPicker: FC<IColorPickerProps> = ({ colors }) => {
  const { setTextColor } = useCommands();
  const { textColor } = useActive();
  const activeColor = colors.find((color) => textColor({ color }));
  const { t } = useTranslation();

  return (
    <DropdownButton
      aria-label={t('common.font_color')}
      icon={<SFontColorIcon iconColor={activeColor} />}
    >
      <SContainer>
        {colors.map((color) => (
          <ColorItem
            key={color}
            color={color}
            active={color === activeColor}
            onClick={() => setTextColor(color)}
          >
            {color === activeColor && <CheckedIcon fontSize='small' />}
          </ColorItem>
        ))}
      </SContainer>
    </DropdownButton>
  );
  // Example of a custom components use case
  /* 
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    return(
        <Tooltip title={title}>
            <>
            <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
                <FormatColorText />
            </IconButton>
            <Popover open={Boolean(anchorEl)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                <SContainer>
                    {colors.map(color => {
                        const isActive = textColor({color})
                        return <ColorItem key={color} color={color} active={isActive} onClick={() => setTextColor(color)} >{isActive && <CheckedIcon fontSize='small' />}</ColorItem>
                    })}
                </SContainer>
            </Popover>
            </>
        </Tooltip>
    ) */
};

const SFontColorIcon = styled(FormatColorText, {
  shouldForwardProp: (prop) => prop !== 'iconColor',
})<{ iconColor?: string }>(({ iconColor }) => ({
  fontSize: '16px',
  color: iconColor || 'inherit',
}));
const SContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  width: '220px',
  padding: '10px',
});
const ColorItem = styled('div')<{ color: string; active?: boolean }>(
  ({ color, active, theme }) => ({
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: color,
    border: `1px solid ${theme.palette.divider}`,
    transition: 'all 0.3s',
    color: 'white',
    ...(!active && { cursor: 'pointer' }),
    ...(active && { transform: 'scale(1.2)' }),
    '&:hover': {
      transform: 'scale(1.2)',
    },
  }),
);

interface IColorPickerProps {
  colors: string[];
  title?: string;
}
