import { take } from 'rxjs';
import { configsData } from '../../../../shared/services/configsData/configsData.service';
import { ITabState } from '../../overview/states/tabState.model';
import { overviewService } from '../../services/overview.service';
import { companyConfigService } from '../../../../shared/services/companyConfig/companyConfig.service';
import { storageHelper } from '../../../../shared/helpers/storage';
import { IOverviewState } from '../../overview/states/overview.state';

export async function resolveCommonDataForTabs(): Promise<ITabState> {
  let datePeriod: IOverviewState['datePeriod'] =
    storageHelper.session.getItem('overview.datePeriod');

  if (!datePeriod) {
    const date = companyConfigService.getCachedConfigs()?.nextDeliveryDate;
    if (date) {
      datePeriod = {
        fromDate: date,
        toDate: date,
      };
    }
  }

  const deliveryNoteGroups = await overviewService.globHelpers.streamToPromise(
    configsData.sub.dictDeliveryNoteGroups().pipe(take(1)),
    () => {
      configsData.pub.common(['dictDeliveryNoteGroups']);
    },
  );

  const deliveryNoteGroupIdFromStorage = storageHelper.session.getItem(
    'overview.deliveryNoteGroupId',
  );
  const deliveryNoteGroupId = deliveryNoteGroupIdFromStorage
    ? Number(deliveryNoteGroupIdFromStorage)
    : null;

  return { defaultValues: {}, deliveryNoteGroups, datePeriod, deliveryNoteGroupId };
}
