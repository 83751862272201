import { FC } from 'react';
/* Icons */
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { localeFormatterHelper } from '../../../../../../shared/helpers/formatter/localeFormatter.helper.ts';
import { SValue } from '../product/product.cell.tsx';

import { IconButton, Stack, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { useTranslation } from 'react-i18next';

export const PriceCell: FC<IPriceCell> = ({ params, errors }) => {
  const { t } = useTranslation();
  const { value, id } = params;

  return (
    <Stack justifyContent='right'>
      {errors.includes(Number(id)) ? (
        <STooltip title={t('order.error_calculating_price')} arrow>
          <SIconButton
            tabIndex={-1}
            color='error'
            onClick={(e) => e.stopPropagation()}
            size='small'
          >
            <ErrorOutlineIcon />
          </SIconButton>
        </STooltip>
      ) : (
        <SValue>
          {localeFormatterHelper.formatNumber(value, {
            returnZero: false,
          })}
        </SValue>
      )}
    </Stack>
  );
};

const SIconButton = styled(IconButton)(() => ({
  padding: 3,
  svg: {
    fontSize: 18,
  },
}));

const STooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

interface IPriceCell {
  params: GridRenderCellParams;
  errors: number[];
}
