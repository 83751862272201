import { TypeTable } from '../../../../../../../../../shared/components/table/table.component';
import { Theme } from '../../../../../../../../../shared/styles/theme/theme.ts';

export const viewCells: TypeTable['viewCells'] = {
  actions: {
    fromQuantity: ({ value }) => {
      if (!value) return 'invalidate invalidate-editable right-divider';
      return 'right-divider';
    },
    discountOnAllGroups: () => 'right-divider',
  },
  styles: [
    {
      className: 'invalidate',
      cellStyles: {
        backgroundColor: Theme.palette.red.light,
      },
    },
    {
      className: 'invalidate-editable',
      cellStyles: {
        '&:hover': {
          backgroundColor: Theme.palette.red.main,
        },
      },
    },
    {
      className: 'right-divider',
      cellStyles: {
        borderRight: `1.5px solid ${Theme.palette.orange.dark}!important`,
      },
    },
  ],
};
