import { IconButton } from '@mui/material';
import { SStack } from '../../../../../../../../order/common/orderGridItems/cells/product/product.cell';
import { SValue } from '../../../../invoice/dictionaries/quantityDiscountsDict/cells/descriptionDict.cell';
import SettingsIco from '@mui/icons-material/Settings';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { dictionaryState } from '../../../../../../../../../shared/components/dictionary/states/dictionary.state';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Popup } from '../../../../../../../../../shared/components/popup/popup.abstract';
import { IDefaultSettingsPopup } from '../defaultSettings.popup';
import { IOptionsForDefaultCustomerSettings } from '../../../customer.tab';

export const NameDictCell: FC<TNameDictCell> = (props) => {
  const { value, row, id, popupStream, optionsForDefaultCustomerSettings } = props;
  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    dictionaryState.pub.selectRow(row);
    popupStream.emit('open', {
      values: {
        id: String(id),
        title: row.name,
        optionsForDefaultCustomerSettings,
      },
    });
  };

  return (
    <SStack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
      <SValue>{value}</SValue>
      <IconButton
        className='button'
        disabled={row.invalid || String(id).includes('new')}
        title={t('settings.post_sectors_one')}
        onClick={handleClick}
        sx={{ p: '3.5px' }}
        size='small'
      >
        <SettingsIco />
      </IconButton>
    </SStack>
  );
};

type TNameDictCell = GridRenderCellParams & {
  popupStream: Popup<IDefaultSettingsPopup>['stream'];
  optionsForDefaultCustomerSettings: IOptionsForDefaultCustomerSettings;
};
