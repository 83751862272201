import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { Popup, TPopupComponent } from '../../../../../../shared/components/popup/popup.abstract';
import { TPopupDefaultActions } from '../../../../../../shared/components/popup/services/popup.service';
import { fontHelper } from '../../../../../../shared/helpers/font/font.helper';
import { CustomerFormsDataRes } from '../../../../services/customer.service';
import { FormTextEditor } from '../../../../../../shared/components/form';
import { useVisibilityGUID } from '../../../../../../shared/hooks/useVisibilityGUID';
import { additionalNoteCompanies } from './formsForm.component';
import { useBeforeClosePopup } from '../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';

export class NotePopup extends Popup<INotePopup> {
  Component: FC<TPopupComponent>;
  type: 'deliveryNote' | 'invoice';
  constructor(params: { type: 'deliveryNote' | 'invoice' }) {
    super();
    const stream = this.innerStream;
    this.type = params.type;

    this.Component = () => {
      const Wrapper = useMemo(() => this.Wrapper, []);
      const [initValues, setInitValues] = useState<INotePopup['values']>({});
      const {
        resetField,
        formState: { isDirty },
      } = useFormContext();
      const { t } = useTranslation();
      const defaultFontSize = useMemo(() => fontHelper.getDefaultFont(['fontSize']), []);
      const fieldName =
        this.type === 'deliveryNote' ? 'defaultNoteInOrder' : 'defaultNoteInInvoice';
      const fieldNameAdditional =
        this.type === 'deliveryNote' ? 'defaultNoteInOrder2' : 'defaultNoteInInvoice2';

      useEffect(() => {
        stream.actionListener('open').subscribe(({ values }) => setInitValues(values));
      }, []);
      const showAdditionalNote = useVisibilityGUID(additionalNoteCompanies);

      const handleSave = () => {
        this.stream.emit('close');
        this.stream.emit('save');
      };

      useBeforeClosePopup({
        stream,
        isDirty,
        handlers: () => {
          resetField(fieldName, { defaultValue: initValues.main });
          resetField(fieldNameAdditional, { defaultValue: initValues.additional });
        },
        deps: [initValues.main, initValues.additional],
      });

      return (
        <Wrapper
          stream={stream}
          popupTitle={t('common.note')}
          PaperProps={{ sx: { maxWidth: '750px' } }}
          fullWidth
        >
          <Typography variant='h5' mb={2}>
            {t(
              `customer.${
                this.type === 'deliveryNote' ? 'delivery_note_comments' : 'invoice_comments'
              }`,
            )}
          </Typography>
          <FormTextEditor name={fieldName} defaultFormat={defaultFontSize} />
          {showAdditionalNote && (
            <>
              <Typography variant='h5' my={2}>
                {t('customer.additional_comments')}
              </Typography>
              <FormTextEditor name={fieldNameAdditional} defaultFormat={defaultFontSize} />
            </>
          )}
          <Stack mt={3} direction='row' justifyContent='flex-end' spacing={2}>
            <Button onClick={handleSave} variant='contained'>
              {t('common.save')}
            </Button>
          </Stack>
        </Wrapper>
      );
    };
  }
}

interface INotePopup {
  action: TPopupDefaultActions | 'save';
  values: {
    main?:
      | CustomerFormsDataRes['defaultNoteInOrder']
      | CustomerFormsDataRes['defaultNoteInInvoice'];
    additional?:
      | CustomerFormsDataRes['defaultNoteInOrder2']
      | CustomerFormsDataRes['defaultNoteInInvoice2'];
  };
}
