import { Badge, Button, Stack, styled } from '@mui/material';
import { FC } from 'react';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { Search } from '../../../../../../../shared/components/table/components/search.component';
import { useTranslation } from 'react-i18next';
import { articleOverviewState } from '../articlesOverview.state';
import { Theme as theme } from '../../../../../../../shared/styles/theme/theme';

export const SideControls: FC<ISideControlsProps> = ({ isActive }) => {
  const { t } = useTranslation();

  const badgeComp = isActive && <SBadge variant='dot' color='primary' />;

  return (
    <Stack marginBottom='16px' gap={2} display='flex' flexDirection='row' alignItems='center'>
      <Search
        sx={{ maxWidth: '400px', marginBottom: 0 }}
        onSearch={(v) => articleOverviewState.pub.search(v)}
      />
      <Button
        variant='outlined'
        color='secondary'
        startIcon={<AutoAwesomeMotionIcon sx={{ fill: 'rgba(0, 0, 0, 0.54)' }} />}
        sx={{
          height: '42px',
          backgroundColor: isActive ? theme.palette.grey['200'] : 'inherit',
          color: theme.palette.black.main,
          fontSize: '15px',
        }}
        onClick={() => articleOverviewState.pub.toggleGroupMode()}
      >
        {t('common.grouping')}
        {badgeComp}
      </Button>
    </Stack>
  );
};

const SBadge = styled(Badge)(() => ({
  position: 'absolute',
  top: 14,
  left: 28,
}));

interface ISideControlsProps {
  isActive: boolean;
}
