import { Pub, State, Sub } from '../../../../../shared/state/state.abstract.ts';
import { ITabState } from '../../../states/tabState.model.ts';
import {
  CustomerListsDataRes,
  customerService,
  SaveCustomerDataRes,
} from '../../../services/customer.service.ts';
import { filter, finalize, map, merge, Observable, switchMap, take, tap } from 'rxjs';
import { customersListState } from '../../../states/customersList.state.ts';
import { customerTabLoadingService } from '../customerTabLoading.service.ts';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler.ts';
import {
  C_Save_Operation_Status,
  SaveCustomerDataMutationVariables,
} from '../../../../../graphql/generatedModel.ts';
import { defaultCustomerListsData } from '../../../loaders/listTab.resolver.ts';

class PubImpl extends Pub<IListsTabState> {
  recordData(dataToSave: IListsTabState['dataToSave']) {
    this.emit('recordData', { dataToSave });
  }
  save() {
    this.emit('save', {});
  }
  clearStream() {
    this.emit(undefined, {});
  }
}

class SubImpl extends Sub<IListsTabState> {
  protected actionHandlers(): Observable<IListsTabState> {
    return merge(this.selectedCustomer(), this.save());
  }
  private save(): Observable<IListsTabState> {
    return this.actionListener('save').pipe(
      tap(({ dataToSave }) => {
        customerService.sub
          .editCustomerData()
          .pipe(
            responseHandler<SaveCustomerDataRes | undefined>({
              success: () => 'customer.customer_saved',
              customErrorHandler: () => 'common.error_chnages_not_saved',
            }),
            filter((v) => v !== undefined),
            take(1),
          )
          .subscribe((res) => {
            if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
              const { updatedGridItem } = res;
              customersListState.pub.updateCustomer(updatedGridItem!);
            }
          });
        const { id, customerListsCheckedState } = dataToSave;
        const checkboxList: CustomerListsDataRes['customerListsCheckedState'] =
          customerListsCheckedState;
        customerService.pub.editCustomerData({
          data: {
            id,
            customerListsCheckedState: checkboxList.map(({ label, checked, id }) => ({
              name: label,
              checked,
              id,
            })),
          },
        } as SaveCustomerDataMutationVariables);
      }),
      finalize(() => listsTabState.pub.clearStream()),
    );
  }
  private selectedCustomer(): Observable<IListsTabState> {
    return customersListState.sub.state().pipe(
      filter(({ action }) => action === 'selectRecord'),
      filter(({ selectedRecord }) => typeof selectedRecord?.id === 'string'),
      switchMap(({ selectedRecord }) => {
        customerTabLoadingService.pub.loading(true);
        const id = selectedRecord!.id;
        const state = this.stream$.getValue();
        state.action = 'list.selectedRecord';
        const details = customerService.sub.customerListsData().pipe(
          responseHandler<CustomerListsDataRes>({ errorReturnType: defaultCustomerListsData }),
          map((data) => {
            state.defaultValues = data;
            customerTabLoadingService.pub.loading(false);
            return state;
          }),
        );
        customerService.pub.customerListsData({ id });
        return details;
      }),
    );
  }
}

class ListsTabState extends State<IListsTabState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const listsTabState = new ListsTabState({
  action: undefined,
  defaultValues: {},
  dataToSave: {},
});

export interface IListsTabState extends Pick<ITabState, 'defaultValues'> {
  action: undefined | 'list.selectedRecord' | 'recordData' | 'save';
  dataToSave: CustomerListsDataRes | Record<string, any>;
}
