import {
  BehaviorSubject,
  filter,
  map,
  merge,
  Observable,
  of,
  share,
  switchMap,
  tap,
  zip,
} from 'rxjs';
import { Pub, State, Sub } from '../../../../../shared/state/state.abstract';
import { orderDetailsState } from './orderDetails.state';
import { customerListState } from '../customerList.state';
import {
  CreateOrderRes,
  createOrderService,
  ListExistingOrdersRes,
} from '../../../services/createOrder.service';
import { C_Order_Error_Code, Wa_CreateOrderInputs } from '../../../../../graphql/generatedModel';
import { createInvoiceService } from '../../../../invoice/services/createInvoice.service';
import { storageHelper } from '../../../../../shared/helpers/storage';
import { formatDeliveryTime } from '../../../../../shared/helpers/utils/utils.helper';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler.ts';

class PubImpl extends Pub<IOrderCreation> {
  initCreateOrder() {
    this.emit('initCreateOrder', {});
  }
  createOrder(creationOptions: IOrderCreation['creationOptions']) {
    this.emit('createOrder', { creationOptions });
  }
  setCreationOption(option: Partial<IOrderCreation['creationSettings']>) {
    const currOptions = this.stream$.getValue().creationSettings;
    const newOptions = { ...currOptions, ...option };
    storageHelper.local.setItem('createOrder.orderCreationSettings', newOptions);
    this.emit('setCreationOption', { creationSettings: newOptions });
  }
  resetState() {
    this.emit('resetState', { ...initOrderCreationState });
  }
}

class SubImpl extends Sub<IOrderCreation> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());

  protected actionHandlers(): Observable<IOrderCreation> {
    return merge(this.initCreateOrder(), this.resetStore(), this.updateState()).pipe(
      map((v) => {
        if (
          v.action !== 'createOrder' &&
          v.action !== 'initCreateOrder' &&
          v.action !== 'setWarning'
        ) {
          v.warnings = undefined;
        }
        return v;
      }),
      tap((state) => {
        if (state.action !== 'resetState') {
          this.stream$.next({ ...state, action: 'internalUpdate' });
        }
      }),
    );
  }

  loading(): Observable<boolean> {
    return this.shareLoading$;
  }

  createdOrder(): Observable<{
    createdOrder: CreateOrderRes | undefined;
    creationSettings: IOrderCreation['creationSettings'];
  }> {
    return this.actionListener('createOrder').pipe(
      tap(() => {
        const isLoading = this.loading$.getValue();
        if (!isLoading) this.loading$.next(true);
      }),
      switchMap(() =>
        zip(customerListState.sub.sharedValues(), orderDetailsState.sub.sharedValues()),
      ),
      tap(([{ customer, date, orderType }, orderDetails]) => {
        const { creationOptions } = this.stream$.getValue();
        const {
          orderData: {
            specialAddress,
            referenceOrderNo,
            deliveryTime,
            linkText,
            note,
            note2,
            positions = [],
          },
        } = orderDetails;
        const formattedPositions =
          orderDetailsState.sub.formatPositionsToMutationArgument(positions);
        const formattedDeliveryTime = formatDeliveryTime(deliveryTime);

        createOrderService.pub.createOrder({
          orderProps: {
            customerId: customer!.customerId,
            date,
            orderType,
            positions: formattedPositions,
            specialAddress,
            referenceOrderNo,
            deliveryTime: formattedDeliveryTime,
            linkText,
            note,
            note2,
            draftOrderIdToDelete: customer?.draftOrderId,
            creationOptions,
          },
        });
      }),
      switchMap(([{ customer, date }]) => {
        return createOrderService.sub.createOrder().pipe(
          responseHandler<CreateOrderRes>({
            customErrorHandler: ({ code }) => {
              this.loading$.next(false);
              const warnings =
                C_Order_Error_Code[code as C_Order_Error_Code] || CreationWarnings['unknown'];
              this.stream$.next({ ...this.stream$.getValue(), action: 'setWarning', warnings });
              return undefined;
            },
            errorReturnType: undefined,
            quite: true,
          }),
          filter((data) => {
            return !!data;
          }),
          tap((createOrderRes) => {
            const {
              creationSettings: { type },
            } = this.stream$.getValue();
            if (type === 'withInvoice' || type === 'payByCash') {
              createInvoiceService.pub.createInvoice({
                customerId: customer!.customerId,
                date,
                ordersStartDate: date,
                ordersEndDate: date,
                manuallySelectedOrdersIds: [createOrderRes.orderId],
                isPaidByCash: type === 'payByCash',
              });
            }
          }),
        );
      }),
      map((createdOrder) => ({
        createdOrder,
        creationSettings: this.stream$.getValue().creationSettings,
      })),
      tap((createdOrder) => {
        if (createdOrder.createdOrder) {
          orderDetailsState.pub.resetOrderDataChanges();
          customerListState.pub.reSelectCustomer();
        }
      }),
      tap(() => this.loading$.next(false)),
    );
  }

  private initCreateOrder(): Observable<IOrderCreation> {
    return this.actionListener('initCreateOrder').pipe(
      switchMap(() => {
        return orderDetailsState.sub.sharedValues();
      }),
      switchMap((orderDetails) => {
        const streamV = this.stream$.getValue();
        const {
          orderData: { positions },
        } = orderDetails;
        const isEmptyPositions = !positions?.some(
          (position) => position?.quantity && position.articleId,
        );
        if (isEmptyPositions) {
          return of({ ...streamV, warnings: C_Order_Error_Code.OEC2_EMPTY_POSITIONS });
        } else {
          return customerListState.sub.sharedValues().pipe(
            tap(() => this.loading$.next(true)),
            tap(({ date, customer }) =>
              createOrderService.pub.listExistingOrders({
                customerId: customer!.customerId,
                onDate: date,
              }),
            ),
            switchMap(() =>
              createOrderService.sub.listExistingOrders().pipe(
                responseHandler<ListExistingOrdersRes>({
                  errorReturnType: [],
                }),
              ),
            ),
            filter((data) => {
              if (!data) this.loading$.next(false);
              return !!data;
            }),
            map((existingOrdersOnDate) => {
              if (existingOrdersOnDate.length) {
                this.loading$.next(false);
                return {
                  ...streamV,
                  warnings: CreationWarnings['existingOrders'],
                  existingOrdersOnDate,
                };
              } else {
                return { ...streamV, warnings: undefined, existingOrdersOnDate: [] };
              }
            }),
          );
        }
      }),
      tap(
        (v) =>
          !v.warnings &&
          this.stream$.next({ ...v, action: 'createOrder' as IOrderCreation['action'] }),
      ),
    );
  }

  private resetStore(): Observable<IOrderCreation> {
    return customerListState.sub.state().pipe(
      // Reset stream value on change customer or navigate back from other module
      filter(({ action }) => action === 'selectCustomer' || action === 'init'),
      map(() => {
        const { creationSettings } = this.stream$.getValue();
        return { ...initOrderCreationState, creationSettings, action: 'internalUpdate' };
      }),
    );
  }

  private updateState(): Observable<IOrderCreation> {
    return this.actionListener(['setCreationOption', 'setWarning', 'resetState']);
  }
}

class OrderCreationState extends State<IOrderCreation> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

const defaultOrderCreationState: IOrderCreation = {
  action: undefined,
  createdOrder: undefined,
  warnings: undefined,
  existingOrdersOnDate: [],
  creationSettings: { type: undefined, previewOnCreated: false },
  creationOptions: {
    ignoreMinMaxProblems: false,
    ignoreQuantityPerLotProblems: false,
    ignoreSplittingProblems: false,
    ignoreDaysToProduce: false,
  },
};
const cachedSettings = storageHelper.local.getItem('createOrder.orderCreationSettings');
if (cachedSettings) {
  defaultOrderCreationState.creationSettings = cachedSettings;
}
export const initOrderCreationState: IOrderCreation = defaultOrderCreationState;
export const orderCreationState = new OrderCreationState(initOrderCreationState);

export enum CreationWarnings {
  'existingOrders' = 'existingOrders',
  'unknown' = 'unknown',
}
export type CreationType = 'withInvoice' | 'payByCash';

export interface IOrderCreation {
  action:
    | 'initCreateOrder'
    | 'createOrder'
    | 'setCreationOption'
    | 'setWarning'
    | 'resetState'
    | 'internalUpdate'
    | undefined;
  createdOrder: CreateOrderRes | undefined;
  warnings: C_Order_Error_Code | CreationWarnings | undefined;
  existingOrdersOnDate: ListExistingOrdersRes;
  creationSettings: {
    type: CreationType | undefined;
    previewOnCreated: boolean;
  };
  creationOptions: Wa_CreateOrderInputs['creationOptions'];
}
