import { map, Observable, of } from 'rxjs';

import {
  GetConfigDataQuery,
  Wa_SaveConfigInput,
  SaveConfigDataMutation,
  C_Save_Operation_Status,
} from '../../../graphql/generatedModel';
import { gqlClient } from '../../../graphql/graphqlRequest';
import { storageHelper } from '../../helpers/storage';
import { sessionstorage } from '../../helpers/storage/session.keys';
import { getCompanyConfig, saveConfigData } from './gql/getConfigs.gql';
import { responseHandler } from '../../responseHandler/responseHandler';

class CompanyConfig {
  private storageKey: sessionstorage = 'companyConfig';
  private storageConfig: TCompanyConfigs | undefined = storageHelper.session.getItem(
    this.storageKey,
  );
  getConfigs(forceRun?: boolean): Observable<TCompanyConfigs> {
    return this.storageConfig && !forceRun
      ? of(this.storageConfig)
      : gqlClient(getCompanyConfig).pipe(
          map((res) => {
            const typedRes = res as GetConfigDataQuery;
            this.storageConfig = typedRes?.wawiAssist?.getConfigData as TCompanyConfigs;
            storageHelper.session.setItem(this.storageKey, this.storageConfig);
            return this.storageConfig;
          }),
          responseHandler<TCompanyConfigs>({
            errorReturnType: undefined,
            customErrorHandler: () => {
              throw new Error('Error in getConfigData request');
            },
          }),
        );
  }
  getCachedConfigs(): TCompanyConfigs | undefined {
    return { ...this.storageConfig! };
  }
  setShowDoughInLiterInProductionLists(v: boolean): void {
    const res = {
      ...this.storageConfig,
      isDoughInLitersInProductionReports: v,
    };
    this.storageConfig = res as TCompanyConfigs;
    storageHelper.session.setItem(this.storageKey, this.storageConfig);
  }
  saveConfigData(params: Wa_SaveConfigInput): Observable<any> {
    return gqlClient(saveConfigData, { dataToSave: params }).pipe(
      responseHandler<TSaveConfigData>({
        success: () => 'settings.configuration_saved',
        customErrorHandler: () => 'settings.configuration_not_saved',
        errorReturnType: {
          status: C_Save_Operation_Status.SOS0_DATA_UNCHANGED,
        },
      }),
    );
  }
}

export const companyConfigService = new CompanyConfig();

export type TCompanyConfigs = NonNullable<
  NonNullable<GetConfigDataQuery['wawiAssist']>['getConfigData']
>;

export type TSaveConfigData = NonNullable<
  NonNullable<SaveConfigDataMutation['wawiAssist']>['saveConfigData']
>;
