import { take } from 'rxjs';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';
import { ArticleAvailabilityTabDataRes, articleService } from '../services/article.service';
import { ITabState } from '../states/tabState.model';

export async function resolveDataForAvailabilityTab(data: ITabState): Promise<ITabState> {
  data.defaultValues = data?.articleId
    ? await articleService.globHelpers.streamToPromise(
        articleService.sub.articleAvailabilityTabData().pipe(
          responseHandler<Partial<ArticleAvailabilityTabDataRes>>({
            errorReturnType: {},
            customErrorHandler: () => 'article.tab_not_loaded',
          }),
          take(1),
        ),
        () => {
          articleService.pub.articleAvailabilityTabData({ id: data.articleId! });
        },
      )
    : {};
  data.defaultValues.id = data.articleId;
  return data;
}
