import { FC, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { ContentLoading } from '../../../../shared/components/loading/loading.component';
import { companyTabLoadingService } from '../companyTabLoading.service';
import { CompanyContent } from './company/companyContent.tsx';
import { TCompanyConfigs } from '../../../../shared/services/companyConfig/companyConfig.service.ts';
import { TabContentContainer } from '../../../../shared/components/tabs/tabsContainer.component.tsx';

const CompanyDetails: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const initTabData = useLoaderData() as TCompanyConfigs;

  useEffect(() => {
    const unsubLoading = companyTabLoadingService.sub.loading().subscribe(setLoading);
    return () => {
      unsubLoading.unsubscribe();
    };
  }, []);

  return (
    <>
      <TabContentContainer>
        <ContentLoading loading={loading}>
          <CompanyContent params={initTabData} />
        </ContentLoading>
      </TabContentContainer>
    </>
  );
};

export default CompanyDetails;
