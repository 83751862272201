import { Observable, filter, finalize, map, merge, switchMap, take, tap } from 'rxjs';

import {
  C_Customer_Address_Kind,
  C_Save_Operation_Status,
  SaveCustomerDataMutationVariables,
} from '../../../../../graphql/generatedModel';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler';
import { Pub, State, Sub } from '../../../../../shared/state/state.abstract';
import {
  CustomerGeneralDataRes,
  customerService,
  SaveCustomerDataRes,
} from '../../../services/customer.service';
import { ITabState } from '../../../states/tabState.model';
import { customersListState } from '../../../states/customersList.state';
import { customerTabLoadingService } from '../customerTabLoading.service';
import { modeService } from '../../../../../shared/services/mode/mode.service';
import { ICreateCustomerValues } from './generalCreate.tab';

export const defaultCreateModeValues: ICreateCustomerValues = {
  isActive: true,
  customerAddressKindId: C_Customer_Address_Kind.CAK1_COMPANY,
  customerNo: null,
  name: null,
  surname: null,
  addition: null,
  city: null,
  salutation: null,
  internalName: null,
  street: null,
  postBox: null,
  zipCode: null,
  contactPerson: null,
  phoneBusiness: null,
  phoneBusiness2: null,
  phonePrivate: null,
  phoneMobile: null,
  fax: null,
  email: null,
  emailForInvoice: null,
  website: null,
  customerGroupId: null,
  deliveryNoteGroupId: null,
  country: null,
  deliveryAddressSalutation: null,
  deliveryAddressName: null,
  deliveryAddressAddition: null,
  deliveryAddressStreet: null,
  deliveryAddressPostBox: null,
  deliveryAddressZipCode: null,
  deliveryAddressCity: null,
  deliveryAddressCountry: null,
};

class PubImpl extends Pub<IGeneralTabState> {
  recordData(dataToSave: IGeneralTabState['dataToSave']) {
    this.emit('recordData', { dataToSave });
  }
  save(dataToSave?: IGeneralTabState['dataToSave']) {
    if (dataToSave) {
      this.emit('save', { dataToSave });
    } else {
      this.emit('save', {});
    }
  }
  clearStream() {
    this.emit(undefined, {});
  }
}
class SubImpl extends Sub<IGeneralTabState> {
  protected actionHandlers(): Observable<IGeneralTabState> {
    return merge(this.selectedCustomer(), this.save());
  }
  private save(): Observable<IGeneralTabState> {
    return this.actionListener('save').pipe(
      filter(({ dataToSave }) => Boolean(Object.keys(dataToSave).length)),
      tap(({ dataToSave }) => {
        customerService.sub
          .editCustomerData()
          .pipe(
            responseHandler<SaveCustomerDataRes | undefined>({
              success: () => 'customer.customer_saved',
              customErrorHandler: () => 'common.error_chnages_not_saved',
            }),
            filter((v) => v !== undefined),
            take(1),
          )
          .subscribe((res) => {
            if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
              const { updatedGridItem } = res;
              customersListState.pub.updateCustomer(updatedGridItem!);
            }
          });

        customerService.pub.editCustomerData({
          data: dataToSave,
        } as SaveCustomerDataMutationVariables);
      }),
      finalize(() => generalTabState.pub.clearStream()),
    );
  }

  private selectedCustomer(): Observable<IGeneralTabState> {
    return customersListState.sub.state().pipe(
      filter(({ action }) => {
        return action === 'selectRecord' || action === 'filter' || action === 'queryList';
      }),
      filter(({ selectedRecord }) => {
        const state = this.stream$.getValue();
        if (!selectedRecord || !selectedRecord?.id) {
          state.defaultValues = {};
        }
        return (
          typeof selectedRecord?.id === 'string' && state.defaultValues.id !== selectedRecord?.id
        );
      }),
      tap(() => modeService.pub.mode('edit')),
      switchMap(({ selectedRecord }) => {
        customerTabLoadingService.pub.loading(true);
        const id = selectedRecord!.id;
        const state = this.stream$.getValue();
        state.action = 'list.selectedRecord';
        const details = customerService.sub.customerGeneralData().pipe(
          responseHandler<CustomerGeneralDataRes>({
            errorReturnType: { ...defaultCreateModeValues, id: '' },
          }),
          map((data) => {
            state.defaultValues = data;
            customerTabLoadingService.pub.loading(false);
            return state;
          }),
        );
        customerService.pub.customerGeneralData({ id });
        return details;
      }),
    );
  }
}

class GeneralTabStateTabState extends State<IGeneralTabState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const generalTabState = new GeneralTabStateTabState({
  action: undefined,
  defaultValues: {},
  dataToSave: {},
});

export interface IGeneralTabState extends Pick<ITabState, 'defaultValues'> {
  action: undefined | 'list.selectedRecord' | 'recordData' | 'save';
  dataToSave: CustomerGeneralDataRes | Record<string, any>;
}
