import { BehaviorSubject, filter, map, Observable, share, Subject, takeUntil } from 'rxjs';

export class PopupService<T extends IPopupModel> {
  private stream$: BehaviorSubject<T>;
  private shared$: Observable<T>;
  private readonly unsubscribe$: Subject<undefined>;

  constructor() {
    this.stream$ = new BehaviorSubject<T>({ action: undefined } as any);
    this.shared$ = this.stream$.pipe(
      map((data) => {
        if (data.action === 'clear') {
          return { action: undefined } as any;
        }
        return data;
      }),
      share({ connector: () => new BehaviorSubject({ ...this.stream$.getValue() }) }),
    );
    this.unsubscribe$ = new Subject();
  }
  actionListener(name: T['action'] | T['action'][]): Observable<T> {
    if (Array.isArray(name)) {
      return this.shared$.pipe(
        takeUntil(this.unsubscribe$),
        filter(({ action }) => name.includes(action)),
      );
    }
    return this.shared$.pipe(
      takeUntil(this.unsubscribe$),
      filter(({ action }) => action === name),
    );
  }
  emit(action: T['action'], value?: Omit<T, 'action'>): void {
    let state = this.stream$.getValue();
    if (value) {
      state = { ...state, ...value };
    }
    state.action = action;
    this.stream$.next(state);
  }
  emitBeforeClose(action: 'beforeClose', beforeClosingArgs: IBeforeCloseArgs): void {
    let state = this.stream$.getValue();
    state.action = action;
    this.stream$.next({ ...state, beforeClosingArgs });
  }
  beforeCloseListener(): Observable<T> {
    return this.shared$.pipe(
      takeUntil(this.unsubscribe$),
      filter(({ action }) => action === 'beforeClose'),
    );
  }
  state(): Observable<T> {
    return this.shared$.pipe(
      takeUntil(this.unsubscribe$),
      filter(({ action }) => action !== undefined && action !== 'beforeClose'),
      map((state) => {
        const copyState = { ...state } as T & { beforeClosingArgs?: IBeforeCloseArgs };
        if (copyState?.beforeClosingArgs) delete copyState.beforeClosingArgs;
        return copyState;
      }),
    );
  }
  unsubscribe(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    if (this.stream$.observed) {
      this.stream$.complete();
    }
  }
}

export type TPopupDefaultActions = 'open' | 'close' | 'clear';
export interface IPopupModel {
  action: string;
}
export interface IBeforeCloseArgs {
  handlers?: () => void;
  isDirty?: boolean;
}
