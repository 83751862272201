import { FC, useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Checkbox } from '../../form';
import { Stack, styled } from '@mui/material';
import { dictionaryState } from '../states/dictionary.state.ts';

export const CheckboxDictCell: FC<GridRenderCellParams> = ({ id, field, value, row }) => {
  const [checked, setChecked] = useState(value);
  return (
    <SStack>
      <Checkbox
        onChange={(_, v) => {
          dictionaryState.pub.updateCell({ id, field, value: v });
          setChecked(v);
        }}
        disabled={row.invalid || String(id).includes('new')}
        {...{ checked }}
      />
    </SStack>
  );
};

const SStack = styled(Stack)({
  '& label': {
    margin: 0,
  },
});
