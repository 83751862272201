import { FC, forwardRef, ReactNode } from 'react';
import { FormControlLabel, RadioProps, Radio as MiuRadio } from '@mui/material';

export const Radio: FC<IRadio> = forwardRef(({ label, value, ...radioProps }, ref) => {
  return <FormControlLabel control={<MiuRadio {...radioProps} />} {...{ label, value, ref }} />;
});

export interface IRadio extends RadioProps {
  label?: ReactNode;
  value: string;
}
