import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  FieldsSection,
  FormCheckbox,
  FormNumericField,
  FormSelect,
} from '../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import { C_Rounding_Method } from '../../../../../../graphql/generatedModel.ts';
import { configurationTabState } from '../../../states/configuration.state.ts';
import { TCompanyConfigs } from '../../../../../../shared/services/companyConfig/companyConfig.service.ts';
import { ISelectProps } from '../../../../../../shared/components/form/fields/select.component.tsx';
import { createValidationSchema } from './invoice.schema.ts';
import { yupResolver } from '@hookform/resolvers/yup';
import { QuantityDiscountPopup } from './dictionaries/quantityDiscountsDict/popup/quantityDiscount.popup.tsx';
import { TextEditCellDict } from '../../../../../../shared/components/dictionary/cells/textEditDict.cell.tsx';
import { DescriptionDictCell } from './dictionaries/quantityDiscountsDict/cells/descriptionDict.cell.tsx';
import { DictionaryPopup } from '../../../../../../shared/components/dictionary/dictionary.popup.tsx';
import { DebtReminderSettingPopup } from './dictionaries/debtRemindersDict/popup/debtReminderSetting.popup.tsx';
import { DescriptionDebtReminder } from './dictionaries/debtRemindersDict/cells/descriptionDebtReminder.cell.tsx';

const validationSchema = createValidationSchema();
export const InvoiceTab: FC<IInvoiceTab> = ({ params }) => {
  const { t } = useTranslation();
  const dictionaryPopup = useMemo(() => new DictionaryPopup(), []);
  const debtReminderSettingPopup = useMemo(() => new DebtReminderSettingPopup(), []);
  const quantityDiscountPopup = useMemo(() => new QuantityDiscountPopup(), []);
  const formMethods = useForm({
    defaultValues: params,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const {
    watch,
    trigger,
    resetField,
    formState: { errors, dirtyFields },
  } = formMethods;
  const priceRoundingOptions: ISelectProps['options'] = useMemo(
    () => [
      {
        id: C_Rounding_Method.RM1_NOT_USED,
        label: t(`enums.${C_Rounding_Method.RM1_NOT_USED}`),
      },
      {
        id: C_Rounding_Method.RM2_TO_0_05,
        label: t(`enums.${C_Rounding_Method.RM2_TO_0_05}`),
      },
      {
        id: C_Rounding_Method.RM3_TO_0_01,
        label: t(`enums.${C_Rounding_Method.RM3_TO_0_01}`),
      },
      {
        id: C_Rounding_Method.RM4_TO_0_001,
        label: t(`enums.${C_Rounding_Method.RM4_TO_0_001}`),
      },
      {
        id: C_Rounding_Method.RM5_TO_0_1,
        label: t(`enums.${C_Rounding_Method.RM5_TO_0_1}`),
      },
    ],
    [t, C_Rounding_Method],
  );

  const triggerValidate = useCallback(() => {
    trigger('euroRate');
    trigger('usdRate');
  }, []);

  useEffect(() => {
    triggerValidate();
    const formStateSub = formMethods.watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        let dataToSave = { [fieldName]: data[fieldName] };
        if (fieldName === 'isUseOtherCurrencies') {
          triggerValidate();
          dataToSave = {
            ...dataToSave,
            ...(data[fieldName] && {
              euroRate: data.euroRate,
              usdRate: data.usdRate,
            }),
          };
        }
        configurationTabState.pub.recordData(dataToSave);
      }
    });
    const configurationTabStateSub = configurationTabState.sub
      .state()
      .subscribe(({ dataToSave }) => {
        for (const valuesKey in dataToSave) {
          if (valuesKey && dataToSave) {
            resetField(valuesKey as keyof TCompanyConfigs, {
              defaultValue: dataToSave[valuesKey as keyof typeof dataToSave],
              keepDirty: false,
              keepError: true,
            });
          }
        }
      });

    return () => {
      formStateSub.unsubscribe();
      configurationTabStateSub.unsubscribe();
      dictionaryPopup.stream.unsubscribe();
      quantityDiscountPopup.stream.unsubscribe();
      debtReminderSettingPopup.stream.unsubscribe();
    };
  }, []);

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    if (!errors[e.target.name] && !!dirtyFields[e.target.name]) {
      configurationTabState.pub.saveData();
    }
  };

  const quantityDiscountsListsHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.quantity_discounts',
        dictionaryType: 'dictQuantityDiscounts',
        validateFields: ['name'],
        columns: [
          {
            field: 'name',
            headerName: t('common.description'),
            renderEditCell: (params) => (
              <TextEditCellDict
                rightButton={{
                  title: t('settings.quantity_discount'),
                  handler: () =>
                    quantityDiscountPopup.stream.emit('open', {
                      id: String(params.id),
                      title: params.row?.name,
                    }),
                }}
                {...params}
              />
            ),
            renderCell: (params) => (
              <DescriptionDictCell popupStream={quantityDiscountPopup.stream} {...params} />
            ),
            editable: true,
          },
        ],
      },
    });
  };

  const debtRemindersHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.reminder_text',
        dictionaryType: 'dictDebtReminders',
        validateFields: ['name'],
        columns: [
          {
            field: 'name',
            headerName: t('common.description'),
            renderEditCell: (params) => (
              <TextEditCellDict
                rightButton={{
                  title: t('settings.reminder_text'),
                  handler: () =>
                    debtReminderSettingPopup.stream.emit('open', {
                      id: String(params.id),
                      title: params.row?.name,
                    }),
                }}
                {...params}
              />
            ),
            renderCell: (params) => (
              <DescriptionDebtReminder popupStream={debtReminderSettingPopup.stream} {...params} />
            ),
            editable: true,
          },
        ],
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form noValidate onBlur={onSubmit}>
        <Stack direction='row' spacing={4} pt={1}>
          <Stack direction='column' spacing={3} minWidth='300px'>
            <FormNumericField
              name='actualInvoiceNo'
              precision={0}
              label={t('settings.actual_invoice_no')}
            />
            <FormSelect
              name='roundingTotalPricesMethodId'
              options={priceRoundingOptions}
              label={t('settings.rounding_total_prices')}
              disableClearable
            />
            {watch('isUseOtherCurrencies') && (
              <>
                <FormNumericField
                  required
                  name='euroRate'
                  precision={4}
                  label={t('settings.euro_rate')}
                  error={Boolean(errors['euroRate'])}
                  helperText={errors['euroRate'] ? t(String(errors['euroRate'].message)) : ''}
                />
                <FormNumericField
                  required
                  name='usdRate'
                  precision={4}
                  label={t('settings.usd_rate')}
                  error={Boolean(errors['usdRate'])}
                  helperText={errors['usdRate'] ? t(String(errors['usdRate'].message)) : ''}
                />
              </>
            )}
          </Stack>
          <Stack direction='column'>
            <FormCheckbox name='isUseOtherCurrencies' label={t('settings.use_another_countries')} />
          </Stack>
        </Stack>
      </form>
      <FieldsSection title={t('settings.related_directories')} nomt>
        <Stack alignItems='start' spacing={1} width='fit-content'>
          <Button
            onClick={quantityDiscountsListsHandler}
            size='medium'
            variant='outlined'
            color='secondary'
            fullWidth
          >
            {t('settings.quantity_discounts_settings')}
          </Button>
          <Button
            onClick={debtRemindersHandler}
            size='medium'
            variant='outlined'
            color='secondary'
            fullWidth
          >
            {t('settings.reminder_texts_settings')}
          </Button>
        </Stack>
      </FieldsSection>
      <dictionaryPopup.Component />
      <quantityDiscountPopup.Component />
      <debtReminderSettingPopup.Component />
    </FormProvider>
  );
};

interface IInvoiceTab {
  params: TCompanyConfigs;
}
