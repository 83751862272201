import { Box, Button, Stack } from '@mui/material';
import { Popup, TPopupComponent } from '../../../../shared/components/popup/popup.abstract.tsx';
import { TPopupDefaultActions } from '../../../../shared/components/popup/services/popup.service.ts';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@mui/material/styles/styled';
import { IProductConfirmationPopup } from './productConfirmation.popup.tsx';

export class ProductMessagePopup extends Popup<IProductMessagePopup> {
  Component: FC<TPopupComponent>;

  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const Wrapper = useMemo(() => this.Wrapper, []);
      const { t } = useTranslation();
      const [message, setMessage] = useState<string>('');
      const [productListed, setProductListed] = useState<boolean>(false);

      useEffect(() => {
        stream.actionListener('open').subscribe(({ values: { value, productListed } }) => {
          if (value?.warningMsgOnSelect) {
            setMessage(value.warningMsgOnSelect.replace(/<style\b[^>]*>([\s\S]*?)<\/style>/gi, ''));
          }
          setProductListed(!!productListed);
        });
      }, []);

      return (
        <Wrapper
          stream={stream}
          popupTitle={t('common.warning')}
          PaperProps={{ sx: { maxWidth: '500px' } }}
          fullWidth
          closureOverlay={false}
          closeBtn={false}
        >
          <SBox dangerouslySetInnerHTML={{ __html: message || '' }} />
          <Stack mt={5} direction='row' justifyContent='center'>
            <Button
              onClick={() => {
                stream.emit('ok');
                if (productListed) stream.emit('close');
              }}
              variant='contained'
            >
              {t('common.ok')}
            </Button>
          </Stack>
        </Wrapper>
      );
    };
  }
}

const SBox = styled(Box)({
  textAlign: 'center',
  paddingTop: 16,
  '& p': {
    margin: 0,
  },
});

export interface IProductMessagePopup extends Omit<IProductConfirmationPopup, 'action'> {
  action: TPopupDefaultActions | 'ok';
}
