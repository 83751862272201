import { styled } from '@mui/material';
import { t } from 'i18next';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FieldsRow,
  FieldsSection,
  FormNumericField,
} from '../../../../../../../shared/components/form';

export const LitersSection: FC = () => {
  const { watch } = useFormContext();
  const articleDoughDataFromRA = watch('articleDoughDataFromRA');
  const isRecipeField = articleDoughDataFromRA?.intermediateWeight > 0;

  const fieldName = useMemo(
    () => (isRecipeField ? 'articleDoughDataFromRA.intermediateWeight' : 'weightOfLiterOfDough'),
    [isRecipeField],
  );

  return (
    <FieldsSection marginTop='31px' title={t('article.liters')} titleBoxSx={{ pt: 0 }} nomt>
      <FieldsRow>
        <SHiddenFormNumericPlug>
          <FormNumericField
            key='articleDoughDataFromRA.intermediateWeight'
            label={t('article.weight_of_liter_of_dough')}
            controls={false}
            noTrailingZeros={true}
            name='articleDoughDataFromRA.intermediateWeight'
            InputProps={{
              readOnly: true,
            }}
          />
          <FormNumericField
            key='weightOfLiterOfDough'
            label={t('article.weight_of_liter_of_dough')}
            controls={false}
            noTrailingZeros={true}
            name='weightOfLiterOfDough'
          />
        </SHiddenFormNumericPlug>
        <SFormNumericFieldWrapper>
          <FormNumericField
            key={fieldName}
            label={t('article.weight_of_liter_of_dough')}
            controls={false}
            noTrailingZeros={true}
            name={fieldName}
            {...(isRecipeField && {
              InputProps: {
                readOnly: true,
              },
            })}
          />
        </SFormNumericFieldWrapper>
        <FormNumericField
          label={t('article.round_to')}
          controls={false}
          noTrailingZeros={true}
          name='roundLitersTo'
        />
      </FieldsRow>
    </FieldsSection>
  );
};

const SHiddenFormNumericPlug = styled('div')(() => ({
  display: 'none',
}));

const SFormNumericFieldWrapper = styled('div')(() => ({
  marginLeft: '0px !important',
}));
