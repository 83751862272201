import { gql } from 'graphql-request';

export const sendEmailWithReport = gql`
  mutation sendEmailWithReport($params: SendEmailWithReportInput!) {
    wawiAssist {
      sendEmailWithReport(params: $params) {
        sentMessages {
          messageId
          timestamp
        }
        unsentMessages {
          recipient
        }
      }
    }
  }
`;

export const getWAReport = gql`
  query getWA_Report($params: WA_GetReportInput!) {
    wawiAssist {
      getWA_Report(params: $params) {
        url
        s3Key
        bucket
      }
    }
  }
`;

export const listWAReports = gql`
  query listWA_Reports($params: WA_ListReportsInput!) {
    wawiAssist {
      listWA_Reports(params: $params) {
        mergedFile {
          url
          s3Key
          bucket
        }
        reports {
          recordId
          url
          s3Key
          bucket
        }
      }
    }
  }
`;
