import { Observable, map, merge } from 'rxjs';
import { Pub, State, Sub } from '../../../state/state.abstract';
import { storageHelper } from '../../../helpers/storage';

export const initSearchState: ISearchState = {
  action: undefined,
  searchString: '',
  searchHistory: [],
};

class PubImpl extends Pub<ISearchState> {
  init(params: Partial<ISearchState>) {
    this.emit('init', params);
  }
  updateSearch(searchString: string) {
    this.emit('updateSearch', { searchString });
  }
  resetSearch() {
    this.emit('resetSearch', { searchString: '' });
  }
  updateHistory(searchString: string) {
    this.emit('updateHistory', { searchString });
  }
}

class SubImpl extends Sub<ISearchState> {
  protected actionHandlers(): Observable<ISearchState> {
    return merge(this.updateSearchState(), this.updateHistory()).pipe(
      map((state) => {
        this.stream$.next({ ...state, action: 'internalUpdate' });
        return state;
      }),
    );
  }

  private updateSearchState(): Observable<ISearchState> {
    return this.actionListener(['init', 'resetSearch', 'updateSearch']);
  }

  private updateHistory(): Observable<ISearchState> {
    return this.actionListener('updateHistory').pipe(
      map((state) => {
        const { searchHistory, searchString } = state;
        const updatedHistory = [
          searchString,
          ...searchHistory.filter((item) => item !== searchString),
        ].slice(0, 10);
        storageHelper.session.setItem('globalSearch.history', updatedHistory);
        return {
          ...state,
          searchHistory: updatedHistory,
        };
      }),
    );
  }
}

class SearchState extends State<ISearchState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const searchState = new SearchState(initSearchState);

export interface ISearchState {
  action: 'init' | 'resetSearch' | 'updateSearch' | 'updateHistory' | 'internalUpdate' | undefined;
  searchString: string;
  searchHistory: string[];
}
