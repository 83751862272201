import { Box, Button, Stack } from '@mui/material';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { prepareDataForPeriodTable, sortTableByDate } from '../../../dataConvertors.popup';
import { Table } from '../../../../../../../../../../shared/components/table/table.component';
import { Column } from '../../../../../../../../../../shared/components/table/components/common.components';
import { localeFormatterHelper } from '../../../../../../../../../../shared/helpers/formatter/localeFormatter.helper';
import { Checkbox } from '../../../../../../../../../../shared/components/form';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { getInitDate, makeTemporaryId } from '../../../common.popup';
import { PeriodConditions } from '../../../../../../../../services/customer.service';

export const Period: FC<IPeriod> = ({
  periods,
  periodsWithCustomWeekDays,
  startAddOrEditPeriod,
  setData,
}) => {
  const [t] = useTranslation();
  const formatDate = (value: string) => localeFormatterHelper.formatDate(value);
  const fullData = prepareDataForPeriodTable(periods, periodsWithCustomWeekDays);
  const sortedData = sortTableByDate(fullData) as IPeriodForTable[];
  const [selectedPeriod, setSelectedPeriod] = useState<IPeriodForTable>();

  const addNewPeriod = () => {
    const initialDate = getInitDate(sortedData) as string;
    startAddOrEditPeriod(
      {
        description: '',
        fromDate: initialDate,
        toDate: initialDate,
        id: makeTemporaryId(),
        isCustom: false,
        isRepeatYearly: false,
        typeForModal: EPeriodTypeForModal.addNew,
      },
      sortedData,
    );
  };

  const deletePeriod = () => {
    if (selectedPeriod) {
      setData((prevData) => {
        const prev = structuredClone(prevData);
        const { isCustom, id } = selectedPeriod;
        if (isCustom) {
          prev.periodsWithCustomWeekDays = prev.periodsWithCustomWeekDays.filter(
            (period) => period.id !== id,
          );
        } else {
          prev.periods = prev.periods.filter((period) => period.id !== id);
        }
        setSelectedPeriod(undefined);
        return prev;
      });
    }
  };

  return (
    <Box sx={{ pb: 3, flexGrow: 1 }}>
      <Stack direction='row' spacing={2} mb={1}>
        <Button size='small' onClick={addNewPeriod}>
          <AddIcon fontSize='small' sx={{ mr: '8px' }} />
          {t('common.add')}
        </Button>

        <Button size='small' disabled={!selectedPeriod?.id} onClick={() => deletePeriod()}>
          <RemoveIcon fontSize='small' sx={{ mr: '8px' }} />
          {t('common.remove')}
        </Button>
      </Stack>
      <Table
        heightOffset={460}
        data={sortedData as []}
        onRowClick={(e) => {
          const newId = e?.id;
          if (newId && newId !== selectedPeriod?.id) {
            setSelectedPeriod(e?.row);
          }
        }}
        rowSelectionModel={selectedPeriod?.id}
      >
        <Column
          field='fromDate'
          headerName={t('common.from_date')}
          width={120}
          sortable={false}
          valueFormatter={({ value }) => (value ? formatDate(value) : '')}
        />
        <Column
          field='toDate'
          headerName={t('common.to_date')}
          width={120}
          sortable={false}
          valueFormatter={({ value }) => (value ? formatDate(value) : '')}
        />
        <Column field='description' headerName={t('common.note')} sortable={false} flex={1} />
        <Column
          field='isCustom'
          headerName={t('article.custom_week_days')}
          width={116}
          maxWidth={116}
          disableReorder
          sortable={false}
          renderCell={({ row }) => {
            const isCustom = row?.isCustom;
            return (
              <Box left='20px' position='relative'>
                <Checkbox size='small' checked={isCustom} />
              </Box>
            );
          }}
        />
        <Column
          field='isRepeatYearly'
          headerName={t('common.yearly')}
          width={116}
          maxWidth={116}
          disableReorder
          sortable={false}
          renderCell={({ row }) => {
            const isRepeatYearly = row?.isRepeatYearly;
            return (
              <Box left='20px' position='relative'>
                <Checkbox size='small' checked={isRepeatYearly} />
              </Box>
            );
          }}
        />
        <Column
          field='id'
          headerName=''
          width={32}
          maxWidth={32}
          disableReorder
          sortable={false}
          renderCell={({ row }) => {
            return (
              <Box
                left='5px'
                position='relative'
                onClick={() =>
                  startAddOrEditPeriod(
                    { ...row, typeForModal: EPeriodTypeForModal.editOld },
                    sortedData,
                  )
                }
              >
                <ModeEditIcon color='action' />
              </Box>
            );
          }}
        />
      </Table>
    </Box>
  );
};

export enum EPeriodTypeForModal {
  addNew = 'addNew',
  editOld = 'editOld',
}

export interface IPeriodForTable {
  description: string;
  fromDate: string | Date | null;
  toDate: string | Date | null;
  id: string;
  isCustom: boolean;
  isRepeatYearly: boolean;
  weekDays?: PeriodConditions['weekDays'];
  typeForModal?: EPeriodTypeForModal;
}

interface IPeriod {
  periods: PeriodConditions['periods'];
  periodsWithCustomWeekDays: PeriodConditions['periodsWithCustomWeekDays'];
  setData: Dispatch<SetStateAction<PeriodConditions>>;
  startAddOrEditPeriod: (period: IPeriodForTable, periodsData: IPeriodForTable[]) => void;
}
