import { Column } from '../../../../../../../../../../../../shared/components/table/components/common.components.tsx';
import { romanNumerals } from '../../../../../../../../../../../order/common/orderGridItems/cells/splitting/additionalFunctions.tsx';
import { SplittingEditCell } from './splittingEdit.cell.tsx';
import { useTranslation } from 'react-i18next';
import { IDefaultOrderState } from '../../../../../../../defaultOrder.state.ts';
import { ExtendedDefaultOrderGridDataPos } from '../../../../../../../../../../services/customer.service.ts';
import { GridColumnsInitialState } from '@mui/x-data-grid-premium';
import { SplittingCell } from './splitting.cell.tsx';
import { IDeliverySplittingState } from '../../../deliverySplitting.state.ts';

export const generateSplittingColumns = (
  count: number,
  daySettings: string,
  calculatedSplitting: IDeliverySplittingState['calculatedSplitting'],
) => {
  const { t } = useTranslation();
  const result: any = [];
  if (count) {
    for (let i = 0; i < count; i++) {
      const romanNumeral = romanNumerals[`${i + 1}`];
      result.push(
        <Column
          key={i}
          disableReorder={true}
          field={`${daySettings}.splittingPartsPreset_${i}`}
          headerName={`${t('customer.part')} ${romanNumeral}`}
          headerAlign='right'
          align='right'
          width={80}
          sortable={false}
          editable
          valueGetter={(params) => params?.row[daySettings]?.splittingPartsPreset?.[i] || ''}
          renderEditCell={(params) => <SplittingEditCell {...{ params }} />}
          renderCell={(params) => <SplittingCell {...{ params, calculatedSplitting }} />}
        />,
      );
    }
  }
  return result;
};

export const clearDataFromVirtualKeys = (data: IDefaultOrderState['defaultOrderGridData']) => {
  return data.map((row) => {
    for (const rowKey in row) {
      if (rowKey.includes('splittingPartsPreset_')) {
        delete row[rowKey as keyof ExtendedDefaultOrderGridDataPos];
      }
    }
    return row;
  });
};

export const attachSplittingsToQuantity = (model?: GridColumnsInitialState) => {
  if (model?.orderedFields) {
    model.orderedFields = model?.orderedFields
      .filter((el: string) => !el.includes('splittingPartsPreset_'))
      .reduce((acc: string[], item) => {
        if (item.includes('quantity')) {
          const [settingsDay] = item.split('.');
          const splittingPartsArray: string[] = Array.from(
            { length: 10 },
            (_, i) => `${settingsDay}.splittingPartsPreset_${i}`,
          );
          acc.push(item, ...splittingPartsArray);
        } else acc.push(item);
        return acc;
      }, []);
  }
  return model;
};
